<mat-slide-toggle class="msg-helper-float-right margin-left"
                  [(ngModel)]="filterValue.isActive"
                  (change)="setFilterView()"
                  [disabled]="disabled"
                  >
</mat-slide-toggle>
<neomp-date-picker class="neomp-slideRangeFilter-DatePicker"
                    [isFilterActive]="filterValue.isActive"
                   (filterValue)="filterValueChanged($event)"
                   [disabled]="disabled"
                   >
</neomp-date-picker>
