import {Injectable} from "@angular/core";
import {Subject} from "rxjs";
import {log} from "./logger.provider";

@Injectable()
export class HttpCancelService {

    private readonly TAG = this.constructor.name;
    private cancelPendingRequests$ = new Subject<void>();

    cancelPendingRequests() {
        log.info(`${this.TAG} -> cancelling all pending http requests...`);
        this.cancelPendingRequests$.next();
        this.cancelPendingRequests$.complete();
        log.info(`${this.TAG} -> ...http requests should be cancelled now`);
    }

    onCancelPendingRequests() {
        return this.cancelPendingRequests$.asObservable();
    }
}
