import {
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output
} from "@angular/core";
import {Location} from "@angular/common";
import {log} from "../../../modules/core/providers/logger.provider";
import {SearchResult} from "../model/search-result";
import {BehaviorSubject, Subscription} from "rxjs";
import {ContextSearchService} from "../services/context-search.service";
import {RegisterProvider} from "../providers/register.provider";
import {NavigationEnd, Router} from "@angular/router";
import {debounceTime} from "rxjs/operators"
import {LoadingService} from "../providers/loading.component";
import {HttpCancelService} from "../providers/HttpCancelService";
import { SearchType } from "../model/enum-searchtype";

@Component({
    selector: "neomp-search-box",
    templateUrl: "./search-box.component.html",
    styleUrls: ["./search-box.component.scss"]
})
export class SearchBoxComponent implements OnInit, OnDestroy {

    private readonly TAG: string = this.constructor.name;
    private readonly DEBOUNCE_TIME: number = 500;

    private searchSubscription: Subscription;
    private searchResetSubscription: Subscription;
    private searchResultSubscription: Subscription;
    private currentUrl = "";

    private inputModelChanged: BehaviorSubject<string> = new BehaviorSubject<string>("");
    private debounceSubscription: Subscription;

    searchTerm: string = null;
    searchInProgress = false;
    searchType: SearchType = SearchType.All;
    
    @Output() results = new EventEmitter<SearchResult>()

    constructor(
        private router: Router,
        private location: Location,
        private registerProvider: RegisterProvider,
        private loadingService: LoadingService,
        private searchService: ContextSearchService,
        private httpCancelService: HttpCancelService) {
    }

    ngOnInit() {
        log.debug(`${this.TAG} -> ngOnInit()`);
        this.debounceSubscription = this.inputModelChanged
            .pipe(
                debounceTime(this.DEBOUNCE_TIME)
            )
            .subscribe((v: string) => {
                this.onSearch(v);
            });

        this.searchResultSubscription = this.searchService.onResults()
            .subscribe(result => this.results.emit(result));

        this.searchResetSubscription = this.searchService.onReset()
            .subscribe(r => this.searchTerm = null);

        this.searchSubscription = this.searchService.onSearchInProgress()
            .subscribe(v => {
                log.debug(`${this.TAG} -> subscription onSearchInProgress() -> v=${JSON.stringify(v)}`)
                this.searchInProgress = v;
                this.loadingService.display(v);
            });

        this.router.events.subscribe(val => {
            if (val instanceof NavigationEnd) {
                log.debug(`${this.TAG} -> detected route change "${this.router.url}"`);
                this.currentUrl = this.router.url;
            }
        })
    }

    ngOnDestroy(): void {
        if (this.debounceSubscription) this.debounceSubscription.unsubscribe();
        if (this.searchResetSubscription) this.searchResetSubscription.unsubscribe();
        if (this.searchSubscription) this.searchSubscription.unsubscribe();
    }

    onSearch(searchTerm: string) {
        log.debug(`${this.TAG} -> onSearch() for term "${searchTerm}"; debounced by ${this.DEBOUNCE_TIME} ms`);
        this.searchService.search(searchTerm, this.searchType);
    }

    endSearch() {
        this.searchService.reset();
        this.httpCancelService.cancelPendingRequests();
        this.location.back();
        return this.searchTerm;
    }

    inputChanged(value: string) {
        if (value != null) {
            const url = `/${this.registerProvider.getPath("SearchResult")}`;
            if (this.router.url !== url) {
                this.router.navigate([url]);
            }
            const oldSearchTerm = this.inputModelChanged.getValue();
            if (oldSearchTerm !== value) {
                this.inputModelChanged.next(value);
            }
        }
    }

    urlIsSearchPage() {
        return `/${this.registerProvider.getPath("SearchResult")}` === this.currentUrl;
    }

    searchTypeChanged(): void {
        this.inputModelChanged.next(this.searchTerm);
    }
    get SearchType(): typeof SearchType {
        return SearchType;
    }
}
