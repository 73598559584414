import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AgentChatComponent } from './chats/agent-chat/agent-chat.component';
import { ChatsComponent } from './chats/chats.component';

const routes: Routes = [
  {
    path: 'chats',
    component: ChatsComponent,
    children: [
      {
        path: ':id',
        component: AgentChatComponent,
      },
    ],
  },
  {
    path: 'archives',
    component: ChatsComponent,
    children: [
      {
        path: ':id',
        component: AgentChatComponent,
      },
    ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LivechatRoutingModule {}
