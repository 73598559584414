import {Component, Output, EventEmitter, Input, Directive, ElementRef, Renderer2,AfterViewInit, HostListener } from "@angular/core";
import {Journal} from "../../model/journal";
import {FormControl} from "@angular/forms";
import {JournalJson} from "../../json/journal.json";
import {Helper} from "../../static/helper";
import {FilterTableComponent} from "./filter-table-component";
import {ActionDialogData} from "../action.dialog.component";
import {KeyValuePair} from "../../model/key-value-pair";
import {ActionDialogService} from "../../providers/action-dialog.service";
import {Status} from "../../model/status";
import {DocumentResourceResult} from "../../model/document-resource-result";
import {DocumentProvider} from "../../providers/document.provider";
import {log} from "../../providers/logger.provider";
import { saveAs } from 'file-saver';
import { SlideRangeFilterValue } from "../../model/slide-range-filter-value";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { UserCommitState } from "app/modules/core/model/user-commit-state";

@Component({
    selector: "neomp-journal-table",
    templateUrl: "./journal-table.component.html",
    styleUrls: ["./journal-table.component.scss"]
})

export class JournalTableComponent extends FilterTableComponent<Journal> {
    private readonly TAG = this.constructor.name;
    @Input() journalData: Map<string, DocumentResourceResult[]> = new Map<string, DocumentResourceResult[]>();
    @Input() isGMOffer: boolean = false;
    @Output() applyAdditionalActionDialogData = new EventEmitter<ActionDialogData>();

    selectedJournalRowIndex;
    isLoading: boolean = false;
    isDialogOpen = false;
    creationDateFilter = new FormControl("");
    typeNameFilter = new FormControl("");
    reasonForChangeFilter = new FormControl("");
    descriptionFieldFilter = new FormControl("");
    customerFilter = new FormControl("");

    filterValues: Journal = new Journal();

    formControlFilters: FormControl[] = [
        null,
        this.creationDateFilter,
        this.typeNameFilter,
        this.reasonForChangeFilter,
        this.descriptionFieldFilter,
        this.customerFilter,
        null,
        null
    ];
    // gm modified
    displayedGMColumns = [
        "status",
        "creationDate",
        "typeName",
        "reasonForChange",
        "descriptionField",
        "customer",
        "document",
        "filter"
    ];
    displayedGMColumnFilters = [
        "statusFilter",
        "creationDateFilter",
        "typeNameFilter",
        "reasonForChangeFilter",
        "descriptionFieldFilter",
        "customerFilter",
        "documentFilter",
        "filterRemove"
    ];
    // default
    displayedColumns = [
        "status",
        "creationDate",
        "typeName",
        "reasonForChange",
        "descriptionField",
        "document",
        "filter"
    ];
    displayedColumnFilters = [
        "statusFilter",
        "creationDateFilter",
        "typeNameFilter",
        "reasonForChangeFilter",
        "descriptionFieldFilter",
        "documentFilter",
        "filterRemove"
    ];

    configKeyValues: KeyValuePair[] = [];

    constructor(public dialogService: ActionDialogService,
                public documentProvider: DocumentProvider,
                public dialog: MatDialog,
                public matSnackBar: MatSnackBar,
                private translateService: TranslateService) {
        super();
    }

    creationDateFilterChanged(event: SlideRangeFilterValue): void {
        this.creationDateFilter.setValue(event.dateRange);
    }

    getClassFromStatus(status: Status): string | undefined {
        if (status.completed === true) {
            return "active-status";
        } else if (status.completed === false) {
            return "inactive-status";
        } else {
            return "warning-status";
        }
    }
 
    journalRowClick(journalRow: Journal) {
        this.selectedJournalRowIndex = journalRow.uuid;
    }

    createFilter(): (data: Journal, filter: string) => boolean {
        return function (data, filter): boolean {
            const filterJson: JournalJson = JSON.parse(filter);
            const searchTerms: Journal = Journal.fromJson(filterJson);

            return Helper.checkDateRange(data.getCreationDate(), Helper.stringToDatePickerFormat(searchTerms.creationDate)) &&
                Helper.checkStringContains(data.typeName, searchTerms.typeName) &&
                Helper.checkStringContains(data.reasonForChange, searchTerms.reasonForChange) &&
                Helper.checkStringContains(data. descriptionField, searchTerms.descriptionField);
        };
    }

    resetFilter() {
        this.filterValues = new Journal();
        super.resetFilter();
    }

    openActionDialog(event: Event, element: Journal) {
        this.isDialogOpen = true;
        event.stopPropagation();        
        element.typeId = this.isGMOffer === true ? '3190' : element.typeId;
        const dialogData: ActionDialogData = {
            transTypeId: element.typeId,
            transName: element.transactionName,
            transSubject: element.transactionName,
            transTitle: element.reasonForChange,
            transMessage: element.descriptionField,
            operationalIdExternal: element.sorKeys["ProcessID"],
            keyValues: [],
            person: null
        };
        if (this.applyAdditionalActionDialogData) {
            this.applyAdditionalActionDialogData.emit(dialogData);
        }
        this.dialogService.openJournal(dialogData, element, this.TAG);
        this.dialog.afterAllClosed.subscribe(() => {
            this.isDialogOpen = false;
        });
    }

    getDocuments(journal: Journal): DocumentResourceResult[] {
        if (this.journalData.has(journal.uuid)) {
            return this.journalData.get(journal.uuid);
        }
        return [];
    }

    download(id: string) {
        if(id){
            this.documentProvider.downloadDocument(id);
        }
        else{
            const translatedMessage = this.translateService.instant('PAGE.CONTAINER.MESSAGES.ERROR-DOCUMENT');
            this.matSnackBar.open(translatedMessage, 'X', UserCommitState.configCheckData);
        }
    }

    downloadFromMyNeo(id: string) {
        if(id){
            this.documentProvider.downloadDocument(id, true);
        }
        else{
            const translatedMessage = this.translateService.instant('PAGE.CONTAINER.MESSAGES.ERROR-DOCUMENT');
            this.matSnackBar.open(translatedMessage, 'X', UserCommitState.configCheckData);
        }
    }

   downloadGMDocument(offerId: string, name: string) {
        this.isLoading = true;
        this.documentProvider.downloadGMDocument(offerId, name).subscribe(docPayload => {
            const byteArray = new Uint8Array(atob(docPayload)
                .split('')
                .map(char => char.charCodeAt(0)));
            let blob = new Blob([byteArray], {type: 'application/octet-stream'});
            let file = new Blob([blob],{type: "application/octet-stream"});
            saveAs(file, name);      
        },
        error => { 
            this.isLoading = false;
        },
        () => {
          // 'onCompleted' callback. 
            this.isLoading = false;
        });
    }

    checkBTType(journal: Journal){
       return journal.sorKeys.hasOwnProperty("GroupeMutuel");
    }

    getOfferId(journal: Journal){
       return journal.sorKeys["GroupeMutuel"];
    }

    getCustomerName(journal: Journal){
       return journal.sorKeys["GroupeMutuelCustomer"];
    }
}
