import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {RegisterProvider} from "../../core/providers/register.provider";
import {environmentVersion} from "../../../../environments/environment.version";
import {HouseholdProvider} from "../../core/providers/household.provider";
import {Household} from "../../core/model/household";
import {LoadingDataComponent} from "../../core/components/loading-data.component";
import {RestProviderActions, RestProviderActionsDummy} from "../../core/providers/rest.provider";
import {KeycloakService} from "keycloak-angular";

import { MatSnackBar } from "@angular/material/snack-bar";
import { MatTableDataSource } from "@angular/material/table";
import {FormControl} from "@angular/forms";
import {SelectedPersonEmitter} from "../../core/model/selectedPersonEmitter";
import {DatePipe} from "@angular/common";
import {UserCommitState} from "../../core/model/user-commit-state";
import {LoadingService} from "../../core/providers/loading.component";
import {Journal} from "../../core/model/journal";
import {ConfigProvider} from "../../core/providers/config.provider";
import {SlideRangeFilterValue} from "../../core/model/slide-range-filter-value";
import * as moment from "moment";

@Component({
    selector: "app-test-page",
    templateUrl: "./test-page.component.html",
    styleUrls: ["./test-page.component.scss"],
    providers: [DatePipe]

})
export class TestPageComponent implements OnInit, RestProviderActions, OnDestroy {

    testData = [
        {
            name: "Frau Johana",
            role: "Customer",
            year: "1987"
        },
        {
            name: "Frau Dell",
            role: "Customer",
            year: "1970"
        },
        {
            name: "Herr Runsda",
            role: "Advisor",
            year: "1989"
        },
        {
            name: "Herr Büschel",
            role: "Advisor",
            year: "1970"
        }
    ];

    testDataSource = new MatTableDataSource();
    columnsToDisplay = ["name", "role", "year"];

    nameFilter = new FormControl("");
    roleFilter = new FormControl("");
    yearFilter = new FormControl("");
    filterValues = {
        name: "",
        role: "",
        year: ""
    };

    @ViewChild("loading") loading: LoadingDataComponent;
    @ViewChild("nodata") noData: LoadingDataComponent;
    @ViewChild("error") error: LoadingDataComponent;

    appVersion = environmentVersion.VERSION;
    token = "";
    date: Date;

    households: Household[] = [];
    journals: Journal[] = [];

    dbTestOk = 0;
    dbTestFail = 0;
    loadingComponent = false;
    filterValue: SlideRangeFilterValue = new SlideRangeFilterValue();

    constructor(public register: RegisterProvider,
                public householdProvider: HouseholdProvider,
                public configProvider: ConfigProvider,
                public keycloak: KeycloakService,
                public datePipe: DatePipe,
                public snackBar: MatSnackBar,
                public loadService: LoadingService) {

        this.testDataSource.data = this.testData;
        this.testDataSource.filterPredicate = this.createFilter();
    }

    ngOnInit() {
        this.loading.onStart();
        this.noData.onNoData();
        this.error.onError(null);
        this.householdProvider.getHouseholds(new RestProviderActionsDummy())
            .subscribe((hhs) => {
                this.households = hhs;
            });

        this.nameFilter.valueChanges.subscribe(name => {
            this.filterValues.name = name;
            this.testDataSource.filter = JSON.stringify(this.filterValues);
        });
        this.roleFilter.valueChanges.subscribe(role => {
            this.filterValues.role = role;
            this.testDataSource.filter = JSON.stringify(this.filterValues);
        })
        this.yearFilter.valueChanges.subscribe(year => {
            this.filterValues.year = year;
            this.testDataSource.filter = JSON.stringify(this.filterValues);
        });
        this.keycloak.getToken().then(tok => this.token = tok);

        this.filterValue.isActive = true;
        this.filterValue.dateRange.end = moment();
        this.filterValue.dateRange.begin = moment().subtract(30, "days");

        this.getElements(this.filterValue);
    }

    createFilter(): (data: any, filter: string) => boolean {
        return function (data, filter): boolean {
            const searchTerms = JSON.parse(filter);
            return data.name.toLowerCase().indexOf(searchTerms.name) !== -1 &&
                data.role.toLowerCase().indexOf(searchTerms.role) !== -1 &&
                data.year.toLowerCase().indexOf(searchTerms.year) !== -1;
        };
    }

    dbTest() {
        this.dbTestOk = 0;
        this.dbTestFail = 0;
        for (let i = 0; i < 1000; i++) {
            this.householdProvider.getHouseholds(this, false)
        }
    }

    onError(error: any): void {
        this.dbTestFail++;
    }

    onNoData(): void {
    }

    onStart(): void {
    }

    onSuccess(): void {
        this.dbTestOk++
    }

    selectedPersonClick(value: SelectedPersonEmitter) {
    }

    openSnackBar() {
        this.snackBar.open("Msg Default", "x", UserCommitState.configDefault);
    }

    openSnackBarGreen() {
        this.snackBar.open("Msg Green", "x", UserCommitState.configSuccess);
    }

    openSnackBarYellow() {
        this.snackBar.open("Msg Yellow", "x", UserCommitState.configCheckData);
    }

    openSnackBarRed() {
        this.snackBar.open("Msg Red", "x", UserCommitState.configError);
    }

    switchLoading() {
        this.loadingComponent = !this.loadingComponent;
        this.loadService.display(this.loadingComponent);
    }

    ngOnDestroy() {
        this.loadService.display(false);
    }

    additionalValidation(callback: (data) => boolean): boolean {
        return false;
    }

    applyValidationCallback(validationCallback: (data: any) => boolean): void {
    }

    getElements(value) {
    }

    onSquareIconEmit(event) {
    }
}
