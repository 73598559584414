<div class="mdc-layout-grid">
  <div class="mdc-layout-grid__inner">

    <!-- <div
      class="mdc-layout-grid__cell--span-2-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone neo-content-center">
      <div class="neo-group-company" (click)="goToEvent(2022)">
        <img src="assets/images/olma.svg" alt="Olma 2022">
      </div>
      <p class="neo-event-title" (click)="goToEvent(2022)">Olma 2022</p>
    </div> -->
    <!-- <div
      class="mdc-layout-grid__cell--span-2-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone neo-content-center">
      <div class="neo-group-company" (click)="goToGlarnerMesseEvent()">
        <img src="assets/images/glarner-messe.jpg" alt="Glarner Messe 2023">
      </div>
      <p class="neo-event-title" (click)="goToGlarnerMesseEvent()">Glarner Messe 2023</p>
    </div> -->
    <!-- <div
      class="mdc-layout-grid__cell--span-2-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone neo-content-center">
      <div class="neo-group-company" (click)="goToEvent(2023)">
        <img src="assets/images/olma.svg" alt="Olma 2023">
      </div>
      <p class="neo-event-title" (click)="goToEvent(2023)">Olma 2023</p>
    </div> -->
    <!-- <div
      class="mdc-layout-grid__cell--span-2-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone neo-content-center">
      <div class="neo-group-company" (click)="goToHigaEvent()">
        <img src="assets/images/higa.png" alt="higa">
      </div>
      <p class="neo-event-title" (click)="goToHigaEvent()">HIGA</p>
    </div> -->
    <!-- <div
      class="mdc-layout-grid__cell--span-2-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone neo-content-center">
      <div class="neo-group-company" (click)="goToBeaEvent()">
        <img src="assets/images/BEA.jpg" alt="higa">
      </div>
      <p class="neo-event-title" (click)="goToBeaEvent()">BEA</p>
    </div> -->
    <!-- <div
      class="mdc-layout-grid__cell--span-2-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone neo-content-center">
      <div class="neo-group-company" (click)="goToOffaEvent(2023)">
        <img src="assets/images/OFFA.jpg" alt="higa">
      </div>
      <p class="neo-event-title" (click)="goToOffaEvent(2023)">OFFA</p>
    </div> -->
    <!-- <div
      class="mdc-layout-grid__cell--span-2-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone neo-content-center">
      <div class="neo-group-company" (click)="goToOffaEvent(2024)">
        <img src="assets/images/OFFA.jpg" alt="higa">
      </div>
      <p class="neo-event-title" (click)="goToOffaEvent(2024)">OFFA 2024</p>
    </div> -->
    <!-- <div
      class="mdc-layout-grid__cell--span-2-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone neo-content-center">
      <div class="neo-group-company" (click)="goToSigaEvent()">
        <img src="assets/images/SIGA.jpg" alt="siga">
      </div>
      <p class="neo-event-title" (click)="goToSigaEvent()">SIGA</p>
    </div> -->
    <!-- <div *ngIf="showSwissIndoorsEvent"
      class="mdc-layout-grid__cell--span-2-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone neo-content-center">
      <div class="neo-group-company" (click)="goToSwissIndoorsEvent()">
        <img src="assets/images/swiss_indoors_logo.svg" alt="swiss_indoors">
      </div>
      <p class="neo-event-title" (click)="goToSwissIndoorsEvent()">Swiss Indoors</p>
    </div> -->
    <div *ngIf="showWigaEvent"
      class="mdc-layout-grid__cell--span-2-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone neo-content-center">
      <div class="neo-group-company" (click)="goToWigaEvent()">
        <img src="assets/images/Wiga.jpg" alt="wiga">
      </div>
      <p class="neo-event-title" (click)="goToWigaEvent()">WIGA</p>
    </div>
    <div *ngIf="showNoraEvent"
      class="mdc-layout-grid__cell--span-2-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone neo-content-center">
      <div class="neo-group-company" (click)="goToNoraEvent()">
        <img src="assets/images/Nora.png" alt="nora">
      </div>
      <p class="neo-event-title" (click)="goToNoraEvent()">NORA</p>
    </div>
    <div
      class="mdc-layout-grid__cell--span-2-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone neo-content-center">
      <div class="neo-group-company" (click)="goToAMAEvent()">
        <img src="assets/images/logo-ama-23.png" alt="AMA 2024">
      </div>
      <p class="neo-event-title" (click)="goToAMAEvent()">AMA 2024</p>
    </div>
    <div
      class="mdc-layout-grid__cell--span-2-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone neo-content-center">
      <div class="neo-group-company" (click)="goToEmPublicViewings()">
        <img src="assets/images/EM-public-viewing.png" alt="EM Public Viewing">
      </div>
      <p class="neo-event-title" (click)="goToEmPublicViewings()">EM Public Viewing</p>
    </div>
    <div
      class="mdc-layout-grid__cell--span-2-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone neo-content-center">
      <div class="neo-group-company" (click)="goToRHEMAEvent()">
        <img class="rhema-logo" src="assets/images/RHEMA.png" alt="RHEMA 2024">
      </div>
      <p class="neo-event-title" (click)="goToRHEMAEvent()">RHEMA 2024</p>
    </div>
    <div
      class="mdc-layout-grid__cell--span-2-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone neo-content-center">
      <div class="neo-group-company" (click)="goToBauenAndWohnenEvent()">
        <img src="assets/images/Bauen-Wohnen.png" alt="Bauen-Wohnen">
      </div>
      <p class="neo-event-title" (click)="goToBauenAndWohnenEvent()">Bauen + Wohnen</p>
    </div>
    <div
      class="mdc-layout-grid__cell--span-2-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone neo-content-center">
      <div class="neo-group-company ml-22" (click)="goToTriathlonDeLausanneEvent()">
        <img src="assets/images/triathlon-logo.jpg" alt="Triathlon de Lausanne">
      </div>
      <p class="neo-event-title" (click)="goToTriathlonDeLausanneEvent()">Triathlon de Lausanne</p>
    </div>
    <div
      class="mdc-layout-grid__cell--span-2-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone neo-content-center">
      <div class="neo-group-company" (click)="goToFiba3x3Event()">
        <img src="assets/images/fiba3x3-logo.png" alt="FIBA 3X3">
      </div>
      <p class="neo-event-title" (click)="goToFiba3x3Event()">FIBA 3X3</p>
    </div>
    <div
      class="mdc-layout-grid__cell--span-2-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone neo-content-center">
      <div class="neo-group-company ml-22" (click)="goToMusicFestivalPromoEvent()">
        <img src="assets/images/music-festival-logo.png" alt="Music Festival Promo">
      </div>
      <p class="neo-event-title ml-10" (click)=" goToMusicFestivalPromoEvent()">Music Festival Promo</p>
    </div>
    <div
      class="mdc-layout-grid__cell--span-2-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone neo-content-center">
      <div class="neo-group-company ml-22" (click)="goToBauenAndModernisierenEvent()">
        <img src="assets/images/Bauen-Modernisieren.jpg" alt="Bauen + Modernisieren">
      </div>
      <p class="neo-event-title" (click)="goToBauenAndModernisierenEvent()">Bauen + Modernisieren</p>
    </div>

    <div
      class="mdc-layout-grid__cell--span-2-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone neo-content-center">
      <div class="neo-group-company" (click)="goToZOMEvent()">
        <img src="assets/images/ZOM.png" alt="ZOM">
      </div>
      <p class="neo-event-title" (click)="goToZOMEvent()">ZOM</p>
    </div>
    <div
    class="mdc-layout-grid__cell--span-2-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone neo-content-center">
    <div class="neo-group-company" (click)="goToOlmaEvent()">
      <img src="assets/images/olma.svg" alt="Olma">
    </div>
    <p class="neo-event-title" (click)="goToOlmaEvent()">OLMA</p>
  </div>

  </div>
</div>
<!-- 
<div class="neo-position">
  <div class="text">
    <div class="neo-group-company" (click)="goToEvent()">
      <img src="assets/images/olma.svg" alt="Olma">
      <p>Olma</p>
    </div>
  </div>


</div> -->