import {Inject, Injectable, LOCALE_ID} from "@angular/core";
import {Observable} from "rxjs";
// import "rxjs-compat/add/observable/of";
import {RestProvider, RestProviderActions} from "./rest.provider";
import {environment} from "../../../../environments/environment";
import {Policy} from "../model/policy";
import {PolicyJson} from "../json/policy.json";
import {DatePickerValue} from "../model/datepicker";
import {formatDate} from "@angular/common";
import {isMoment} from "moment";
import {JournalListJson} from "../json/journal-list.json";
import {JournalList} from "../model/journal-list";
import { map } from "rxjs/operators";

@Injectable()
export class PolicyProvider {
    private readonly CLASS_NAME = this.constructor.name;
    constructor(
        public rest: RestProvider,
        @Inject(LOCALE_ID) private locale: string) {
    }

    public getPolicyByPartnerId(
        partnerId: string,
        restProviderActions: RestProviderActions): Observable<{data: Policy[], totalCount: number}> {
        return this.rest.get<{data: PolicyJson[], totalCount: number}>(
            `${environment.settings.BASE_URL}/api/advisor/${partnerId}/policies`,
            null, true, restProviderActions)
            .pipe(map((res) => {
                return {
                    data: Policy.fromJsons(res.data),
                    totalCount: res.totalCount
                };
            }));
    }

    public getProductBundle(partnerId: string, policyId: string,
                            restProviderActions: RestProviderActions): Observable<Policy> {
        return this.rest.get<PolicyJson>(
            `${environment.settings.BASE_URL}/api/advisor/${partnerId}/policies/${policyId}`,
            null, true, restProviderActions)
            .pipe(map((res) => {
                return Policy.fromJson(res);
            }));
    }

    public getPolicies(
        restProviderActions: RestProviderActions,
        dateRange: DatePickerValue = null,
        page: number,
        size: number,
        filter?: string): Observable<{data: Policy[], totalCount: number}> {
        let queryParams = `?page=${page}&size=${size}&${filter}`;
        if (dateRange) {
            const startDate: Date = isMoment(dateRange.begin) ? dateRange.begin.toDate() : dateRange.begin;
            const endDate: Date = isMoment(dateRange.end) ? dateRange.end.toDate() : dateRange.end;
            queryParams += `&${this.getDateFilterQuery(startDate, endDate)}`;
        }
        return this.rest.get<{data: PolicyJson[], totalCount: number}>(
            `${environment.settings.BASE_URL}/api/advisor/policies${queryParams}`,
            null,true, restProviderActions)
            .pipe(map((res) => {
                return { data: Policy.fromJsons(res.data), totalCount: res.totalCount };
            }));
    }

    public getPoliciesBySearch(
        restProviderActions: RestProviderActions,
        dateRange: DatePickerValue = null,
        page: number,
        size: number,
        searchText: string,
        statusList: string[],
        filter?: string): Observable<{data: Policy[], totalCount: number}> {
        let queryParams = `?page=${page}&size=${size}&searchText=${searchText}&statusList=${JSON.stringify(statusList)}&${filter}`;
        if (dateRange) {
            const startDate: Date = isMoment(dateRange.begin) ? dateRange.begin.toDate() : dateRange.begin;
            const endDate: Date = isMoment(dateRange.end) ? dateRange.end.toDate() : dateRange.end;
            queryParams += `&${this.getDateFilterQuery(startDate, endDate)}`;
        }
        return this.rest.get<{data: PolicyJson[], totalCount: number}>(
            `${environment.settings.BASE_URL}/api/advisor/policies/filtered${queryParams}`,
            null,true, restProviderActions)
            .pipe(map((res) => {
                return { data: Policy.fromJsons(res.data), totalCount: res.totalCount };
            }));
    }

    public getPoliciesWithTransaction(
        restProviderActions: RestProviderActions,
        dateRange: DatePickerValue = null,
        page: number,
        size: number,
        searchText: string,
        statusList: string[],
        filter?: string,
        ): Observable<{data: Policy[], totalCount: number}> {
        let queryParams = `?page=${page}&size=${size}&searchText=${searchText}&statusList=${JSON.stringify(statusList)}${filter}`;
        if(dateRange){
            const startDate: Date = isMoment(dateRange.begin) ? dateRange.begin.toDate() : dateRange.begin;
            const endDate: Date = isMoment(dateRange.end) ? dateRange.end.toDate() : dateRange.end;
            queryParams += `&${this.getDateFilterQuery(startDate, endDate)}`;
        }
        return this.rest.get<{data: PolicyJson[], totalCount: number}>(
            `${environment.settings.BASE_URL}/api/advisor/policies/with-transaction${queryParams}`,
            null,true, restProviderActions)
            .pipe(map((res) => {
                return { data: Policy.fromJsons(res.data), totalCount: res.totalCount };
            }));
    }

    getDateFilterQuery(from: Date, to: Date): string {
        const fromTemp = formatDate(from, "yyyy-MM-dd'T00:00:00.000'", this.locale);
        const toTemp = formatDate(to, "yyyy-MM-dd'T23:59:59.999'", this.locale);
        return `filter={"type":"DateTime","key":"validFrom","from":"${fromTemp}","to":"${toTemp}"}`;
    }
}
