<mat-form-field class="msg-mp-language-combobox">
    <select matNativeControl class="msg-mp-language-combobox-select" #langSelect (change)="changeLanguage(langSelect.value)">
        <option *ngFor="let lang of translate.getLangs()" [selected]="lang === translate.currentLang"
                [value]="lang">{{lang}}</option>
    </select>
</mat-form-field>

<!--
<select matNativeControl #langSelect (change)="changeLanguage(langSelect.value)">
    <option *ngFor="let lang of translate.getLangs()" [selected]="lang === translate.currentLang"
            [value]="lang">{{lang}}</option>
</select>
-->