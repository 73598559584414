<div class="page-chat">
  <div class="chats">
    <div class="header">{{ this.archivable ? 'Chats' : "Archives" }}</div>
    <div class="dropdown-input">
      <input type="text" placeholder="Search..." (input)="onSearch($event)" />
    </div>
    <div class="chats-list">
      <a
        class="chat-card"
        *ngFor="let room of rooms"
        (click)="joinRoom(room);"
        [class.active]="activeRoom === room"
        routerLinkActive="active"
      >
        <div class="img">
          <span *ngIf="room.isTicket" class="notify-badge"><img class="notification-ticket" src="assets/images/Admin view pages/Tickets notification.svg" alt="ticket-notification"></span>
          <span *ngIf="!room.isTicket && room.newMessages" class="notify-badge">{{room.newMessages}}</span>
          <img
            src="assets/images/Admin view pages/Group 4065.svg"
            width="50px"
            alt="profle"
          />
        </div>
        <div class="info">
          <div class="name" [class.new-message-bold]="room.isTicket || room.newMessages">
            {{ room.clientName }}
          </div>
          <div class="email">{{room.clientEmail}}</div>
          <div class="last-message" [class.new-message-bold-last-message]="room.isTicket || room.newMessages">
            {{
              room.status === false
                ? room.clientName + " has left the chat."
                : room.messages.length !== 0
                ? room.messages[room.messages.length - 1].message
                : "No messages"
            }}
          </div>
        </div>
        <div class="message-time" [class.new-message-bold]="room.isTicket || room.newMessages" *ngIf="room.messages[room.messages.length - 1] !== undefined">
          {{ messageChatDate(room.messages[room.messages.length - 1].date) }}
        </div>
      </a>
    </div>
    <!-- <div class="chats-body">
    </div> -->
  </div>
  <div class="agent-chat">
    <!-- <app-agent-chat></app-agent-chat> -->
    <router-outlet></router-outlet>
  </div>
</div>
