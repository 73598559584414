import {StatusJson} from "../json/status.json";

export class Status {
    name: string;
    completed = false;
    source: string;
    updated: string;

    public static fromJsons(response: StatusJson[]): Status[] | undefined {
        if (response) {
            return response.map(key => new Status(key));
        }
    }

    public constructor(value?: StatusJson) {
        if (value) {
            this.name = value.name;
            this.completed = value.completed;
            this.source = value.source;
            this.updated = value.updated;
        }
    }
}
