import { KeycloakOptions, KeycloakService } from "keycloak-angular";
import { environment } from "../../../../environments/environment";
import { KeycloakServiceMock } from "../mocks/keycloak.service.mock";

export function initializer(keycloak: KeycloakService): () => Promise<any> {
    return (): Promise<any> => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                let options: KeycloakOptions = {
                    config: environment.keycloak,
                    initOptions: {
                        onLoad: 'login-required',
                        checkLoginIframe: false
                    },
                    bearerExcludedUrls: ['/assets', '/api/business-transactions', '/api/natural-persons'],
                    bearerPrefix: "Bearer"
                };

                const isImpersonatedUser = localStorage.getItem('impersonated');
                if (isImpersonatedUser) {
                    const impersonatedData = JSON.parse(localStorage.getItem('impersonated') || null);
                    options.initOptions.token = impersonatedData.token;
                    options.initOptions.refreshToken = impersonatedData.refresh_token;
                }
                await keycloak.init(options);
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    };
}

//NOSONAR
export function initializerTests(keycloak: KeycloakServiceMock): () => Promise<any> {
    return (): Promise<any> => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                await keycloak.init({
                    config: environment.keycloak,
                    initOptions: {
                        onLoad: 'login-required',
                        checkLoginIframe: false
                    },
                    bearerExcludedUrls: ['/assets'],
                    bearerPrefix: "Bearer"
                });
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    };
}
