import { ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PaginationConfigs } from 'app/modules/constants/PaginationConstants';
import { LeadQueryParams } from 'app/modules/core/json/leadQueryParams.json';
import { AgenturType } from 'app/modules/core/model/enum-agenturtype';
import { KeyValue } from 'app/modules/core/model/key-value';
import { Person } from 'app/modules/core/model/person';
import { LeadProvider } from 'app/modules/core/providers/lead.provider';
import { RestProviderActions } from 'app/modules/core/providers/rest.provider';
import { format } from 'date-fns';
import { KeycloakService } from 'keycloak-angular';
import moment from 'moment';
import { Observable, Subscription, forkJoin, of } from 'rxjs';
import { catchError, distinctUntilChanged } from 'rxjs/operators';
import { SelectionModel } from '@angular/cdk/collections';
import { LeadsService } from '../../services/leads.service';
import { BusinessTransactionHelper } from 'app/modules/core/static/bt-helper';
import { LeadLog } from 'app/modules/core/model/LeadLog.enum';
import { UserCommitState } from 'app/modules/core/model/user-commit-state';
import { LoadingService } from 'app/modules/core/providers/loading.component';
import { MatSnackBar } from "@angular/material/snack-bar";

const TABS_NAMES = {leadpool:"leadpool",myLeads:"myLeads"};
import { take } from 'rxjs/operators';
import { AdvisorDataService } from 'app/modules/core/services/advisor-data.service';


@Component({
  selector: 'neomp-lead-pool',
  templateUrl: './lead-pool.component.html',
  styleUrls: ['./lead-pool.component.scss']
})
export class LeadPoolComponent 
implements OnInit {
  isHighRole: boolean = false;
  leadsTabGroupCurrentIndex: number = 0;
  dateFrom: any;
  dateTo: any;
  advisorName = "";
  agenturType = "";
  agenturName = "";
  agenturId = "";
  advisorId = "";
  searchText = "";
  categoriesList:string[]=[]
  categoriesSelected= new FormControl("")
  substatusesList: any[] = [];
  substatusSelected = new FormControl("");
  statusesList: string[] = [];
  allStatuses: KeyValue[] = [];
  statusSelected = new FormControl("");
  unassignedLeadsTypes: string[] = [];
  lead_leadCategory = [];
  lead_leadType = [];
  agents: Person[] = [];
  agentSelectedControl = new FormControl("");

  // Filters
  assignLeadFilter = new FormControl("");
  assignedEstimationFilter = new FormControl("");
  statusLeadFilter = new FormControl("");
  leadNameFilter = new FormControl("");
  leadArtFilter = new FormControl("");
  substatusLeadFilter = new FormControl("");
  leadDateFilter = new FormControl("");
  leadCategoryFilter = new FormControl("");
  leadOrtFilter = new FormControl("");
  leadStatusFilter = new FormControl("");
  leadLanguageFilter = new FormControl("");
  leadAdvisorFilter = new FormControl("");
  leadAddressFilter = new FormControl("");
  leadSubstatusFilter = new FormControl("");

  formControlFilters: FormControl[] = [
    this.leadNameFilter,
    this.leadAddressFilter,
    this.leadArtFilter,
    this.leadStatusFilter,
    this.leadSubstatusFilter,
    this.leadDateFilter,
    this.leadCategoryFilter,
    this.leadLanguageFilter,
    this.leadAdvisorFilter,
  ];
  showFilter = false;
  unAssignedLeads: Person[] = [];

  displayedColumns = [
    "select",
    "name",
    "address",
    "art",
    "statusLead",
    "substatusLead",
    "date",
    "category",
    "assignLead",
    "assignedEstimation",
    "filter"
  ];
  displayedColumnFilters = [
    "selectFilter",
    "nameFilter",
    "addressFilter",
    "artFilter",
    "statusLeadFilter",
    "substatusLeadFilter",
    "dateFilter",
    "categoryFilter",
    "assignLeadFilter",
    "assignedEstimationFilter",
    "filterRemove",
  ];

  displayedColumnsMyLeads = [
    "select",
    "name",
    "address",
    "art",
    "statusLead",
    "substatusLead",
    "date",
    "category",
    "assignLead",
    "assignedEstimation",
    "berater",
    "filter"
  ];
  displayedColumnFiltersMyLeads = [
    "selectFilter",
    "nameFilter",
    "addressFilter",
    "artFilter",
    "statusLeadFilter",
    "substatusLeadFilter",
    "dateFilter",
    "categoryFilter",
    "assignLeadFilter",
    "assignedEstimationFilter",
    "beraterFilter",
    "filterRemove",
  ];


  managementView: boolean = false;
  dataSourceLeadpool = new MatTableDataSource([]);
  dataSourceMyLeads = new MatTableDataSource([]);
  subscriptions: Subscription[] = [];
  pageSize = PaginationConfigs.pageSize;
  pageSizeOptions = PaginationConfigs.pageSizeOptions;
  pageIndex = PaginationConfigs.pageIndex;
  @ViewChild("tabGroup") tabGroup: MatTabGroup;
  totalTerminLeadsReminders=0;
  totalMyLeads=0;
  selectedIndexTab = -1;

  selectionLeadpool = new SelectionModel(true, []);
  selectionMyLeads = new SelectionModel(true, []);
  terminTypesSelected= new FormControl("")
  terminCategoriesSelected= new FormControl("")
  agentAssignSelectedControl = new FormControl({ value: '', disabled: true }, Validators.required);
  isLoading = false;
  firstLoad = false;
  showSelect: boolean = false;
  unblockedAgents:any;

  constructor(
    public leadProvider: LeadProvider,
    public translateService: TranslateService,
    private keycloak: KeycloakService,
    private route: ActivatedRoute,
    private router: Router,
    public leadService: LeadsService,
    private cdr: ChangeDetectorRef,
    private leadsService: LeadsService,
    public loadService: LoadingService,    
    public matSnackBar: MatSnackBar,
    private advisorDataService: AdvisorDataService
  ) {
    // super();
    
    
   }

  ngOnChanges(changes: SimpleChanges): void {
    throw new Error('Method not implemented.');
  }

  async ngOnInit(): Promise<void> {
    
    this.keycloak.loadUserProfile().then((res: any) => {
      this.advisorName = res.firstName;
      this.advisorId = res.attributes.advisorid[0];
      this.agenturType = res.attributes.agenturType[0];
      this.agenturName = res.attributes.agenturName[0];
      this.agenturId = res.attributes.agenturID[0];
      this.isHighRole = (this.agenturType === AgenturType.SalesManager || this.agenturType === AgenturType.GeneralAgent || this.agenturType === AgenturType.TeamLead);
      //Remove the Berater column if has not high role
      if(!this.isHighRole){
        this.displayedColumnsMyLeads = this.displayedColumnsMyLeads.filter(column=> column !== "berater" )
      }
    });
    this.leadProvider.getAttributeValues().subscribe(attributeValues => {
      this.categoriesList= attributeValues?.leadCategories || [];
      this.unassignedLeadsTypes= attributeValues?.leadTypes || [];
    });
    this.agents = await this.getAgents().toPromise();
    this.unblockedAgents = this.agents.filter(agent => agent.blocked !== true);

    this.firstLoad = true;
    // this.subscriptions.push(
    this.route.queryParams.pipe(take(1))
    .subscribe((params:LeadQueryParams) => {
      if (params.st) {          
        this.searchFromParams(params, this.firstLoad);
        this.dateFrom = format(new Date(this.route.snapshot.queryParams.dateFrom.toString()), "YYYY-MM-DDT00:00:00.000")
        this.dateTo = format(new Date(this.route.snapshot.queryParams.dateTo.toString()), "YYYY-MM-DDT23:59:00.000")
        this.firstLoad = false;
      } else {
        
        this.selectedIndexTab = 0;
        this.searchLeads();
        this.searchMyLeads();
      }
      this.subscriptions.forEach(sub=>sub.unsubscribe())
    })

  }


  toggleSelect(value: boolean): void {
    this.showSelect = value;
  }

  getSelectedAgent(uuid: string): Person {
  return this.agents.find(x => x.uuid === uuid);
  }
  
  getAgents(): Observable<Person[]> {
    return this.leadProvider.getAgents(false)
  }
  
  clearSearch(){
    
    this.searchText = '';
    this.dateFrom = null;
    this.dateTo = null;
    this.agentSelectedControl.reset();
    this.statusSelected.reset();
    this.substatusSelected.reset();
    this.lead_leadCategory = [];
    this.categoriesSelected.reset();
    this.searchLeads();
  }

  statusesSelected(event) {
    this.substatusesList = [];

    event.value.forEach(status => {
      this.allStatuses.filter(x => x.key === status).map(y => {
        var obj = {};
        obj['title'] = status;
        obj['value'] = y.value.split(',');
        this.substatusesList.push(obj);
      })
    });

  }
  
  changeFilterView(){
    this.showFilter = !this.showFilter;
  }
  
  loadLeads(
    restProviderActions: RestProviderActions,
    page: number,
    size: number,
    filter?: string) {
      this.loadService.display(true);
    this.unAssignedLeads = [];
    const leadpool="&leadpool=true";
    filter = filter != "" ? filter + '&isManagement=false' : 'isManagement=false';
    filter = filter + leadpool;
      this.leadProvider
        .getLeadsAssignedInAgency(restProviderActions, page, size, filter)
        .subscribe((leads: { data: Person[]; totalCount: number }) => {
          this.totalMyLeads = leads.totalCount;
            this.unAssignedLeads = leads.data;
            this.dataSourceMyLeads.data = this.unAssignedLeads;
            this.loadService.display(false);            
        })
  }
  
  assignAgentsToLeads(lead: Person) {
    const leadAgent = this.agents.find(x => x.uuid == lead.lead.termin_berater_shortcut);
    const agentName = leadAgent ? leadAgent.fullName : this.agenturName;
    if (agentName) {
      lead.sorKeys["agent"] = agentName;
      return lead;
    }
    return null;
  }

  searchMyLeads(isSearching = true) {
    var statusSelected = Array.isArray(this.statusSelected.value) ? this.statusSelected.value.join(',') : "";
    var substatusSelected = Array.isArray(this.substatusSelected.value) ? this.substatusSelected.value.join(',') : "";
    var categoriesSelected = Array.isArray(this.categoriesSelected.value) ? this.categoriesSelected.value.join(',') : "";
    const dateFrom = this.dateFrom == undefined || this.dateFrom == "Invalid Date" ? "" : format(new Date(this.dateFrom.toString()), "YYYY-MM-DDT00:00:00.000")
    const dateTo = this.dateTo == undefined || this.dateTo == "Invalid Date" ? "" : format(new Date(this.dateTo.toString()), "YYYY-MM-DDT23:59:00.000")
    let filter = this.agentSelectedControl.value != "" && this.agentSelectedControl.value != undefined ?
      `dateFrom=${dateFrom}&dateTo=${dateTo}&advisor=${this.agentSelectedControl.value}&status=${statusSelected}&lead_substatusList=${substatusSelected}&lead_leadCategory=${categoriesSelected}` :
      `dateFrom=${dateFrom}&dateTo=${dateTo}&status=${statusSelected}&lead_substatusList=${substatusSelected}&lead_leadCategory=${categoriesSelected}`

    if (![null, undefined, ""].includes(this.searchText)) {
      filter += `&text=${this.searchText}`
    }

    this.loadLeads(
      undefined,
      this.pageIndex,
      this.pageSize,
      filter
    );

    const queryParamObj = {
      dateFrom,
      dateTo,
      advisor: this.agentSelectedControl.value,
      status: this.statusSelected.value,
      text: this.searchText,
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      lead_leadCategory:this.categoriesSelected.value,
      st:TABS_NAMES.myLeads

    }
    if(isSearching){
      this.updateQueryParams('/leadpool', queryParamObj.st, queryParamObj);
    }
  }
  searchLeads(isSearching = true) {
    this.loadService.display(true)
    const statusSelected = Array.isArray(this.statusSelected.value) ? this.statusSelected.value.join(',') : "";
    const substatusSelected = Array.isArray(this.substatusSelected.value) ? this.substatusSelected.value.join(',') : "";
    const categoriesSelected = Array.isArray(this.categoriesSelected.value) ? this.categoriesSelected.value.join(',') : "";
    const typesSelected = Array.isArray(this.lead_leadType) ? this.lead_leadType.join(',') : "";
    const filterTypeSelected = typesSelected.length > 0 ? `&lead_leadType=${typesSelected}` : ''
    const dateFrom = this.dateFrom == undefined || this.dateFrom == "Invalid Date" ? "" : format(new Date(this.dateFrom.toString()), "YYYY-MM-DDT00:00:00.000")
    const dateTo = this.dateTo == undefined || this.dateTo == "Invalid Date" ? "" : format(new Date(this.dateTo.toString()), "YYYY-MM-DDT23:59:00.000")
    const leadpool="&leadpool=true";
    let filter = this.agentSelectedControl.value != "" && this.agentSelectedControl.value != undefined ?
      `dateFrom=${dateFrom}&dateTo=${dateTo}&advisor=${this.agentSelectedControl.value}&status=${statusSelected}&lead_substatusList=${substatusSelected}&lead_leadCategory=${categoriesSelected}` :
      `dateFrom=${dateFrom}&dateTo=${dateTo}&status=${statusSelected}&lead_substatusList=${substatusSelected}&lead_leadCategory=${categoriesSelected}`

    if (![null, undefined, ""].includes(this.searchText)) {
      filter += `&text=${this.searchText}`
    }
    filter = filter + leadpool + filterTypeSelected;
    this.leadProvider.getUnassignedLeads(undefined, this.pageIndex, this.pageSize,filter) 
    .subscribe((leads: { data: Person[]; totalCount: number }) => {               
      this.leadService.unassignedLeadPoolCount.next(leads.totalCount);             
    this.dataSourceLeadpool.data = leads.data;
    this.totalTerminLeadsReminders = leads.totalCount;
    this.loadService.display(false)
    })

    const queryParamObj = {
      dateFrom,
      dateTo,
      advisor: this.agentSelectedControl.value,
      status: this.statusSelected.value,
      text: this.searchText,
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      lead_leadCategory:this.categoriesSelected.value,
      st:TABS_NAMES.leadpool

    }

    if(isSearching){
    this.updateQueryParams('/leadpool', queryParamObj.st, queryParamObj);
    this.loadService.display(false)
  }
  }
  
  searchFromParams(params: LeadQueryParams, isFirstLoad:boolean = false) {
    this.dateFrom = params.dateFrom || undefined;
    this.dateTo = params.dateTo || undefined;
    this.searchText = params.text || "";
    this.agentSelectedControl.setValue(params.advisor);
    let statusesList = params.status as any;
    if (typeof params.status === 'string') {
      statusesList = params.status.split(",");
    }
    this.statusSelected.setValue(statusesList);

    let categoriesList = params.lead_leadCategory as any;
    if (typeof params.lead_leadCategory === 'string') {
      categoriesList = params.lead_leadCategory.split(",");
    }
    this.categoriesSelected.setValue(categoriesList);

    this.pageIndex = params.pageIndex || PaginationConfigs.pageIndex;
    this.pageSize = Number(params.pageSize) > 0 ? Number(params.pageSize) : PaginationConfigs.pageSize;
    if(isFirstLoad){
      this.selectedIndexTab = params.st === TABS_NAMES.leadpool ? 0 : 1;
      this.searchLeads();
      this.searchMyLeads(false);//When is the first load, if the first tab is selected, will load also the second tab to show the myLeads green button
    }else{
      if (params.st === TABS_NAMES.leadpool) {
        this.tabGroup.selectedIndex = 0;
        this.selectedIndexTab = 0;
        this.searchLeads();
      }

      if (params.st === TABS_NAMES.myLeads) {
        this.tabGroup.selectedIndex = 1;
        this.selectedIndexTab = 1;
        this.searchMyLeads();
      }
    }

  }


  tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    this.pageIndex = 0;
    this.pageSize = PaginationConfigs.pageSizeOptions[0];

    const queryParamObj = {
      dateFrom: "",
      dateTo: "",
      advisor: "",
      status: '',
      text: "",
      pageIndex: 0,
      pageSize: this.pageSize,
      lead_leadCategory:"",
      lead_terminCategory:"",
      lead_terminType: "",
      st: tabChangeEvent.index === 0 ? TABS_NAMES.leadpool : TABS_NAMES.myLeads
    };

    let st = 'default';

    if (tabChangeEvent.index == 0) {
      this.selectedIndexTab = 0;
      this.searchLeads();
    }
    if (tabChangeEvent.index == 1) {
      this.selectedIndexTab = 1;
      this.searchMyLeads();
    }
    this.searchText = "";
    this.substatusSelected.setValue('');

    // this.updateQueryParams('/leadpool', queryParamObj.st, queryParamObj);
  }

  updateQueryParams(path: string, st: string, queryParams: LeadQueryParams): void {

    this.router.navigate([path], {
      queryParams: {
        st,
        dateFrom: queryParams?.dateFrom || "",
        dateTo: queryParams?.dateTo || "",
        advisor: queryParams?.advisor,
        text: queryParams?.text || this.searchText || "",
        status: queryParams?.status,
        lead_leadCategory:queryParams?.lead_leadCategory || this.categoriesSelected.value,
        lead_terminCategory: queryParams?.lead_terminCategory,
        lead_terminType: queryParams?.lead_terminType,
        pageIndex: queryParams?.pageIndex,
        pageSize: queryParams?.pageSize
      }
    });
  }
  
  dateParse(date: string): Date | string {
    return date ?
      new Date(date) : '';
  }

  leadReminderPaginatorValueChanged(event: PageEvent): void {

    if (event.pageIndex !== this.pageIndex || event.pageSize !== this.pageSize) {
      this.pageIndex = event.pageIndex;
      this.pageSize = event.pageSize;

      const queryParamObj = {
        dateFrom: this.dateFrom == "Invalid Date" ? "" : this.dateFrom,
        dateTo: this.dateTo == "Invalid Date" ? "" : this.dateTo,
        advisor: this.agentSelectedControl.value,
        status: this.statusSelected.value,
        text: this.searchText,
        pageIndex: event.pageIndex,
        pageSize: event.pageSize,
        lead_leadCategory:this.categoriesSelected.value,
        st: TABS_NAMES.leadpool

      };

      this.searchFromParams(queryParamObj)

    }
  }

  myLeadsPaginatorValueChanged(event: PageEvent): void {
    if (event.pageIndex !== this.pageIndex || event.pageSize !== this.pageSize) {
      this.pageIndex = event.pageIndex;
      this.pageSize = event.pageSize;
      const queryParamObj = {
        dateFrom: this.dateFrom == "Invalid Date" ? "" : this.dateFrom,
        dateTo: this.dateTo == "Invalid Date" ? "" : this.dateTo,
        advisor: this.agentSelectedControl.value,
        status: this.statusSelected.value,
        text: this.searchText,
        pageIndex: event.pageIndex,//this.pageIndex,
        pageSize: event.pageSize,//this.pageSize,
        lead_leadCategory:this.categoriesSelected.value,
        st: TABS_NAMES.myLeads
      };

      this.searchFromParams(queryParamObj)
      this.updateQueryParams('/leadpool', this.route.snapshot.queryParams.st, queryParamObj)

    }
  }

  calculateFromNow(datePast: string){
    const now  = moment();
    const ms = moment(now,"DD/MM/YYYY HH:mm:ss").diff(moment(datePast,"DD/MM/YYYY HH:mm:ss"));
    const d = moment.duration(ms);
    
    return isNaN(d.days()) ? 'No date found' : `${d.days()}d ${d.hours()}h`
  }

   // Checkbox selection methods
   isAllLeadpoolsSelected() {
    if(!this.dataSourceLeadpool.data.length || !this.selectionLeadpool.selected.length ) return false;

    const numSelected = this.selectionLeadpool.selected.length;
    const numRows = this.dataSourceLeadpool.data.length;
    const disabledLeads = this.dataSourceLeadpool.data
    .filter((row:Person)=> row.lead.lead_agency !== row.lead.termin_berater_shortcut).length
    return numRows === (numSelected + disabledLeads);
  }

  /**
   * Checks is the SelectAll checkbox should be disabled
   * @returns a boolean telling if the checkbox should be disabled
   */
  isSelectAllLeadPoolDisabled(){    
    return this.dataSourceLeadpool.data.filter((lead:Person)=>lead.lead.lead_agency === lead.lead.termin_berater_shortcut).length === 0;
  }
  isAllMyLeadsSelected() {
    if(!this.dataSourceMyLeads.data.length || !this.selectionMyLeads.selected.length ) return false;
    const numSelected = this.selectionMyLeads.selected.length;
    const numRows = this.dataSourceMyLeads.data.length;
    return numSelected === numRows;
  }

  masterLeadpoolToggle() {
    this.isAllLeadpoolsSelected() ?
      this.selectionLeadpool.clear() :
      this.dataSourceLeadpool.data
      .filter((row:Person)=> row.lead.lead_agency === row.lead.termin_berater_shortcut)
      .forEach(row => this.selectionLeadpool.select(row));
      
      this.selectionLeadpool.selected.length === 0 ?
      this.agentAssignSelectedControl.disable() : this.agentAssignSelectedControl.enable();

      this.cdr.detectChanges(); // Manually trigger change detection

  }
  masterMyLeadsToggle() {
    this.isAllMyLeadsSelected() ?
      this.selectionMyLeads.clear() :
      this.dataSourceMyLeads.data.forEach(row => this.selectionMyLeads.select(row));
      
      this.selectionMyLeads.selected.length === 0 ?
      this.agentAssignSelectedControl.disable() : this.agentAssignSelectedControl.enable();

      this.cdr.detectChanges(); // Manually trigger change detection
  }

  toggleLeadPool(row: any) {
    this.selectionLeadpool.toggle(row);
    this.selectionLeadpool.selected.length === 0 ?
      this.agentAssignSelectedControl.disable() : this.agentAssignSelectedControl.enable();

    this.cdr.detectChanges(); // Manually trigger change detection
  }

  toggleMyLeads(row: any) {
    this.selectionMyLeads.toggle(row);
    this.selectionMyLeads.selected.length === 0 ?
      this.agentAssignSelectedControl.disable() : this.agentAssignSelectedControl.enable();

    this.cdr.detectChanges(); // Manually trigger change detection
  }

  async isLeadAlreadyAssigned(lead:Person){
    var canAssign=false;
    //We're adding the leadpool at the end to allow getting the lead by id only when leadpool is mentioned, otherwise due to the access of agentyType=03 is not allowing getting the lead by Id.
    await this.leadService.getLeadById(`${lead.uuid},leadpool`).toPromise()
    .then(leadReturned=>{
      canAssign = lead.lead.lead_agency === leadReturned.lead.termin_berater_shortcut;
    })
    return canAssign
  }

  async assignAgentToLead(
    lead: Person, 
    agent: {id:string, name:string} = {id: this.advisorId, name: this.advisorName && this.advisorName.split("Neosana Sales AG")[1] ? this.advisorName.split("Neosana Sales AG")[1] : this.advisorName },
    isUnaasigning: boolean = false,
    isGeneralAgent: boolean = false
    ) {
    /**
     * Checks if the lead is already assigned before assign it
     * The return boolean will determine if the lead can be assigned
     */
    const canAssign = isGeneralAgent ? true : await this.isLeadAlreadyAssigned(lead);
    
    /**
     * If the lead can't be assigned (because is already assigned), it will show a snackBar about the error.
     */
    if(!canAssign && !isUnaasigning){
      const translatedMessage = this.translateService.instant('PAGE.LEADS.LEAD.LEAD-ALREADY-ASSIGNED');
      this.matSnackBar.open(translatedMessage, 'X', UserCommitState.configError);
      return;
    }

    const advisorName = agent.name;

    const sorKeys = {
      'advisorId': agent.id,
      'leadId': lead.uuid
    }

    lead.lead.termin_editdate = moment().format('YYYY-MM-DD');
    lead.lead.termin_berater_shortcut = agent.id;

    // if the agent id and agentur Id are the same, that means the leadpool is being unassigned, so we set termin_berater_assigned_time as empty string
    if(agent.id === this.agenturId){
      lead.lead.termin_berater_assigned_time = "";
    }else{
      lead.lead.termin_berater_assigned_time = moment().format('DD/MM/YYYY HH:mm:ss')
    }

    const actionData = BusinessTransactionHelper.createLeadBTActionObject(
      LeadLog.TRANSFER,
      BusinessTransactionHelper.typeId.assignLeadToAgent.from,
      '',
      'Lead TRANSFER => ' + advisorName,
      '',
      agent.id,
      [],
      lead,
      moment().add(5, 'days').format('YYYY-MM-DD'),
      sorKeys,
      advisorName
    );   

    this.leadsService.sendLeadAction(actionData)
      .subscribe((res) => {
        this.searchMyLeads();
        this.selectionLeadpool.clear()
        this.selectionMyLeads.clear();
      }, err => {
        console.log("sendLeadAction error",err)
      })
  }

  unAssignLead(lead:Person, $event){
    $event.stopPropagation();
    this.assignAgentToLead(lead, {id: lead.lead.lead_agency, name: this.agenturName }, true)
  }

  selectionChange($event, lead:Person){
    if(!$event.isUserInput) return;
    const agent = this.agents.find(agent=>agent.uuid === $event.source.value);
    const agentId=agent.uuid
    const agentName = agent.fullName.split("Neosana Sales AG")[1] ? agent.fullName.split("Neosana Sales AG")[1] : agent.fullName;
    this.assignAgentToLead(lead, {id:agentId, name: agentName},undefined, true)
  }

  assignLeadpoolLeadsToAgent(){   
    let requests: Observable<any>[] = [];
    //The agent who will be assigned the leads
    const agent = this.agentAssignSelectedControl.value;
    if (this.selectionLeadpool.selected.length > 0) {
      this.isLoading = true;
      this.loadService.display(true);
      for (let lead of this.selectionLeadpool.selected) {

        const sorKeys = {
          'advisorId': agent.uuid,
          'leadId': lead.uuid
        }

        lead.lead.termin_editdate = moment().format('YYYY-MM-DD');
        lead.lead.termin_berater_shortcut = agent.uuid;
        lead.lead.termin_berater_assigned_time = moment().format('DD/MM/YYYY HH:mm:ss')
        
        const agentName = lead.fullName.split("Neosana Sales AG")[1] ? lead.fullName.split("Neosana Sales AG")[1] : lead.fullName

        const actionData = BusinessTransactionHelper.createLeadBTActionObject(
          LeadLog.TRANSFER,
          BusinessTransactionHelper.typeId.assignLeadToAgent.from,
          '',
          'Lead TRANSFER => ' + agentName,
          '',
          agent.uuid,
          [],
          lead,
          moment().add(5, 'days').format('YYYY-MM-DD'),
          sorKeys,
          this.advisorName
        );

        requests.push(this.leadsService.sendLeadAction(actionData)
          .pipe(
            // do not let your observables die; forkJoin will the not be triggered then.
            catchError(err => of(err))
          ));
      }
      forkJoin(requests).subscribe(results => {
        this.loadService.display(false);
        this.isLoading = false;
        this.agentAssignSelectedControl.reset();
        this.selectionLeadpool.clear();
        this.searchText = null;
        this.searchMyLeads();
      })
    }

  }
  assignLeadsToAgent(toMe:boolean = false): void {
    let requests: Observable<any>[] = [];
    const agent = {uuid: this.advisorId, fullName: this.advisorName };
    if (this.selectionLeadpool.selected.length > 0) {
      this.isLoading = true;
      this.loadService.display(true);
      for (let lead of this.selectionLeadpool.selected) {

        const sorKeys = {
          'advisorId': agent.uuid,
          'leadId': lead.uuid
        }

        lead.lead.termin_editdate = moment().format('YYYY-MM-DD');
        lead.lead.termin_berater_shortcut = agent.uuid;
        lead.lead.termin_berater_assigned_time = moment().format('DD/MM/YYYY HH:mm:ss')

        const actionData = BusinessTransactionHelper.createLeadBTActionObject(
          LeadLog.TRANSFER,
          BusinessTransactionHelper.typeId.assignLeadToAgent.from,
          '',
          'Lead TRANSFER => ' + agent.fullName,
          '',
          agent.uuid,
          [],
          lead,
          moment().add(5, 'days').format('YYYY-MM-DD'),
          sorKeys,
          this.advisorName
        );

        requests.push(this.leadsService.sendLeadAction(actionData)
          .pipe(
            // do not let your observables die; forkJoin will the not be triggered then.
            catchError(err => of(err))
          ));
      }

      forkJoin(requests).subscribe(results => {
        this.loadService.display(false);
        this.isLoading = false;
        this.agentAssignSelectedControl.reset();
        this.selectionLeadpool.clear();
        this.searchText = null;
        this.searchMyLeads();
      })
    }
  }

  unAssignMyLeadsFromAgent(){
    // If you are in the MyLeads and have no selected lead, no need to continue
    if(this.selectionMyLeads.selected.length === 0 && this.selectedIndexTab === 1){ return }

    let requests: Observable<any>[] = [];
    const agent = {uuid:this.agenturId, fullName: this.agenturName}
    
      this.isLoading = true;
      this.loadService.display(true);
      for (let lead of this.selectionMyLeads.selected) {

        const sorKeys = {
          'advisorId': agent.uuid,
          'leadId': lead.uuid
        }

        lead.lead.termin_editdate = moment().format('YYYY-MM-DD');
        lead.lead.termin_berater_shortcut = agent.uuid;

        const actionData = BusinessTransactionHelper.createLeadBTActionObject(
          LeadLog.TRANSFER,
          BusinessTransactionHelper.typeId.assignLeadToAgent.from,
          '',
          'Lead TRANSFER => ' + agent.fullName,
          '',
          agent.uuid,
          [],
          lead,
          moment().add(5, 'days').format('YYYY-MM-DD'),
          sorKeys,
          this.advisorName
        );

        requests.push(this.leadsService.sendLeadAction(actionData)
          .pipe(
            // do not let your observables die; forkJoin will the not be triggered then.
            catchError(err => of(err))
          ));
      }

      forkJoin(requests).subscribe(results => {
        this.loadService.display(false);
        this.isLoading = false;
        this.agentAssignSelectedControl.reset();
        this.selectionMyLeads.clear();
        this.searchText = null;
        this.searchMyLeads();
      })
      

  }

  getAgent(id:string){
return this.agents.find(agent=>agent.uuid === id)
  }

  filterSearch(){
    if(this.selectedIndexTab === 0){
      this.searchLeads();
    }
    else{
      this.searchMyLeads()
    }
  }

  redirectToEdit(element: Person): void {
    if(
      element.lead.lead_agency === element.lead.termin_berater_shortcut
      ||
      element.lead.termin_berater_shortcut === this.advisorId
      ||
      this.agenturType === AgenturType.GeneralAgent
      ){
        this.router.navigate([`/lead/${element.uuid}/edit`]);
      }
        
  }
}
