import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StrapiService } from 'app/modules/shared/services/strapi.service';
import { CancellationManagementBlockedComponent } from '../cancellation-management-blocked/cancellation-management-blocked.component';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class CancellationManagementGuard implements CanActivate {

  constructor(private dialog: MatDialog, private strapiService: StrapiService,
    private translateService: TranslateService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const currentLang = this.translateService.currentLang || this.translateService.defaultLang;
  
    return this.strapiService.getCancellationManagementBlocked(currentLang).pipe(
      map((response: any) => {
        const cancellationManagementData = response?.data;
  
        if (cancellationManagementData?.isVisible) {
          const fontColor = cancellationManagementData.fontColor;
          const displayText = this.formatDisplayText(cancellationManagementData.displayText);
  
          this.openBlockedDialog({
            displayText,
            fontColor,
          });
  
          return false;
        }
  
        return true;
      })
    );
  }

  openBlockedDialog(data: any): void {
    this.dialog.open(CancellationManagementBlockedComponent, {
      panelClass: 'custom-dialog-container',
      width: '550px',
      data: {
        text: data.displayText,
        fontColor: data.fontColor
      }
    });
  }

  private formatDisplayText(displayText: any[]): string {
    return displayText
      .map((block) => block.children.map((child: any) => child.text).join(''))
      .join('\n\n');
  }
}
