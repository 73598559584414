<div class="mdc-layout-grid msg-helper-padding-none">
  <div class="mdc-layout-grid__inner msg-helper-grid-gap-horizontal">
    <div
      class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
      <div
        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-9-tablet mdc-layout-grid__cell--span-12-phone mdc-layout-grid--align-left">
        <div class="neo-create-lead-btn" *ngIf="!isSupplier">
          <button mat-button [routerLink]="['/create-lead']" class="neo-text-btn">
            <img src="/assets/icons/add-lead.svg"><span>{{ 'PAGE.LEADS.LEAD.CREATE-LEAD' | translate }}</span>
          </button>
        </div>
        <mat-tab-group #tabGroup class="neo-border-none neo-label" (selectedTabChange)="tabChanged($event)"
          [selectedIndex]="selectedIndexTab">
          <mat-tab label="{{ 'MENU.LEADS-NAV.LEADS' | translate }}">
            <ng-template mat-tab-label>
              {{ "MENU.LEADS-NAV.LEADS" | translate }}
              <span class="tasks-badge" *ngIf="totalNeuLeads != 0">
                {{ totalNeuLeads }}
              </span>
            </ng-template>
            <div class="mdc-layout-grid msg-helper-padding-none msg-helper-margin-top-10">
              <div class="search-fields-layout-grid">
                <div
                  class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
                  <!-- filters -->
                  <mat-card>
                    <!-- filter fields -->
                    <div class="search-fields-layout-grid" (keyup.enter)="searchLeads()">
                      <mat-form-field class="neomp-full-width">
                        <mat-label>{{ 'PAGE.LEADS.LEAD.TEXT' | translate }}</mat-label>
                        <input matInput [(ngModel)]="searchText" />
                      </mat-form-field>

                      <mat-form-field class="neomp-full-width">
                        <mat-label>{{ 'PAGE.LEADS.LEAD.FROM' | translate }}</mat-label>
                        <input [matDatepicker]="Von" matInput [(ngModel)]="dateFrom" />
                        <mat-datepicker-toggle matSuffix [for]="Von"></mat-datepicker-toggle>
                        <mat-datepicker #Von></mat-datepicker>
                      </mat-form-field>

                      <mat-form-field class="neomp-full-width">
                        <mat-label>{{ 'PAGE.LEADS.LEAD.TO' | translate }}</mat-label>
                        <input [matDatepicker]="Bis" matInput [(ngModel)]="dateTo" />
                        <mat-datepicker-toggle matSuffix [for]="Bis"></mat-datepicker-toggle>
                        <mat-datepicker #Bis></mat-datepicker>
                      </mat-form-field>

                      <div *ngIf="isHighRole">
                        <mat-form-field class="neomp-full-width">
                          <mat-label id="berater-label">{{ 'PAGE.LEADS.LEAD.ADVISOR' | translate }}</mat-label>
                          <mat-select [formControl]="agentSelectedControl">
                            <mat-option [value]="">
                              {{ 'PAGE.LEADS.LEAD.ALL' | translate }}
                            </mat-option>
                            <mat-option *ngFor="let agent of agents" [value]="agent.uuid">
                              {{agent.fullName && agent.fullName.split("Neosana Sales AG")[1] ?
                              agent.fullName.split("Neosana Sales AG")[1] : agent.fullName }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>

                      <mat-form-field class="neomp-full-width">
                        <mat-label>StatusList</mat-label>
                        <mat-select [formControl]="statusSelected" (selectionChange)="statusesSelected($event)"
                          multiple>
                          <mat-option value="Neu">Neu</mat-option>
                          <mat-option *ngFor="let status of statusesList" [value]="status">{{status}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field class="neomp-full-width">
                        <mat-label>Substatus List</mat-label>
                        <mat-select [formControl]="substatusSelected" multiple>
                          <mat-optgroup *ngFor="let status of substatusesList" [label]="status.title"
                            [disabled]="status.disabled">
                            <mat-option *ngFor="let substatus of status.value" [value]="substatus">{{substatus}}
                            </mat-option>
                          </mat-optgroup>
                        </mat-select>
                      </mat-form-field>
                      
                      <mat-form-field class="neomp-full-width">
                        <mat-label>Categories</mat-label>
                        <mat-select [formControl]="categoriesSelected"
                          multiple>
                          <!-- <mat-option value="Neu">Neu</mat-option> -->
                          <mat-option *ngFor="let category of categoriesList" [value]="category">{{category}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <!-- buttons -->
                    <div class="search-fields-layout-grid margin-top-15">
                      <div>
                        <div class="neo-padding-top-10">
                          <button mat-raised-button class="neomp-full-width button-text" color="primary"
                            (click)="searchLeads()">
                            <span class="material-icons">
                              search
                            </span>{{ 'PAGE.LEADS.LEAD.SEARCH' | translate }}
                          </button>
                        </div>
                      </div>
                      <div>
                        <div class="neo-padding-top-10">
                          <button mat-raised-button class="clear-button neomp-full-width button-text" (click)="clearSearch()">
                            <span class="material-icons">clear</span>{{ 'PAGE.LEADS.LEAD.CLEAR' | translate }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </mat-card>
                  <br/>
                  <neomp-container cssClass="msg-mp-container-frameless" headlineText="{{
                      'MENU.LEADS-NAV.LEADS' | translate
                    }}">
                    <neomp-leads-table [tableEntries]="leads" [leads]="leads"></neomp-leads-table>
                    <neomp-loading-data #loadingFamilyTable></neomp-loading-data>
                    <neomp-paginator [length]="totalCountLeads" [pageSize]="pageSize" [pageIndex]="pageIndex"
                      [pageSizeOptions]="pageSizeOptions" (paginatorChange)="leadsPaginatorValueChanged($event)">
                    </neomp-paginator>
                  </neomp-container>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab class="test" label="{{ 'MENU.LEADS-NAV.APPOINTMENT' | translate }}">
            <div class="mdc-layout-grid msg-helper-padding-none msg-helper-margin-top-10">
              <div class="mdc-layout-grid__inner">
                <div
                  class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
                  <neomp-termin *ngIf="tabGroup.selectedIndex === 1" [managementView]="false">
                  </neomp-termin>
                </div>
              </div>
            </div>
          </mat-tab>
          <!-- S3T: Removed as per request 23.05.2022 -> probably wont be needed at all -->
          <!-- <mat-tab
            label="{{
              'PAGE.CONTAINER.CUSTOMERS.CONTAINER.ADDRESS-INTERMEDIARY'
                | translate
            }}"
          >
            <div
              class="mdc-layout-grid msg-helper-padding-none msg-helper-margin-top-10"
            >
              <div class="mdc-layout-grid__inner">
                <div
                  class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone"
                ></div>
              </div>
            </div>
          </mat-tab> -->
          <mat-tab label="{{ 'MENU.LEADS-NAV.CALENDAR' | translate }}">
            <div class="mdc-layout-grid msg-helper-padding-none msg-helper-margin-top-10">
              <div class="mdc-layout-grid__inner">
                <div
                  class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
                  <neomp-termin-calendar-component [appointments]="appointments" [agents]="agents" [unassignedAppointmentsCalendar]="unassignedAppointmentsCalendar">
                  </neomp-termin-calendar-component>
                </div>
              </div>
            </div>
          </mat-tab>

          <!-- reminder -->
          <mat-tab label="{{ 'MENU.LEADS-NAV.REMINDER' | translate }}" *ngIf="!isSupplier">
            <div class="mdc-layout-grid msg-helper-padding-none msg-helper-margin-top-10">
              <div class="mdc-layout-grid__inner">
                <div
                  class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
                  <mat-tab-group class="neo-border-none neo-label" [selectedIndex]="remindersIndex"
                    (selectedIndexChange)="reminderTabChanged($event)">

                    <!-- termin leads reminder -->
                    <mat-tab label="{{ 'PAGE.LEADS.REMINDER.APPOINTMENT-LEADS-REMINDERS' | translate}}">
                      <br>
                      <!-- SEARCH -->
                      <div
                        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
                        <mat-card>
                          <div class="search-fields-layout-grid" (keyup.enter)="getTerminLeadsReminder()">
                              <mat-form-field class="neomp-full-width">
                                <mat-label>{{ 'PAGE.LEADS.LEAD.FROM' | translate }}</mat-label>
                                <input [matDatepicker]="VonLeadReminders" matInput [(ngModel)]="dateFrom" />
                                <mat-datepicker-toggle matSuffix [for]="VonLeadReminders"></mat-datepicker-toggle>
                                <mat-datepicker #VonLeadReminders></mat-datepicker>
                              </mat-form-field>

                              <mat-form-field class="neomp-full-width">
                                <mat-label>{{ 'PAGE.LEADS.LEAD.TO' | translate }}</mat-label>
                                <input [matDatepicker]="BisLeadReminders" matInput [(ngModel)]="dateTo" />
                                <mat-datepicker-toggle matSuffix [for]="BisLeadReminders"></mat-datepicker-toggle>
                                <mat-datepicker #BisLeadReminders></mat-datepicker>
                              </mat-form-field>
                              
                              <div *ngIf="isHighRole">
                                <mat-form-field class="neomp-full-width">
                                  <mat-label id="berater-label">{{ 'PAGE.LEADS.LEAD.ADVISOR' | translate }}</mat-label>
                                  <mat-select [formControl]="agentSelectedControl">
                                    <mat-option [value]="">
                                      {{ 'PAGE.LEADS.LEAD.ALL' | translate }}
                                    </mat-option>
                                    <mat-option *ngFor="let agent of agents" [value]="agent.uuid">
                                      {{agent.fullName && agent.fullName.split("Neosana Sales AG")[1] ?
                                      agent.fullName.split("Neosana Sales AG")[1] : agent.fullName }}
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </div>
                            </div>
                            <!-- buttons -->
                            <div class="search-fields-layout-grid margin-top-15">
                              <div class="neo-padding-top-10">
                                <button mat-raised-button class="neomp-full-width button-text" color="primary"
                                  (click)="getTerminLeadsReminder()">
                                  <span class="material-icons">
                                    search
                                  </span>{{ 'PAGE.LEADS.LEAD.SEARCH' | translate }}
                                </button>
                              </div>
                              <div class="neo-padding-top-10">
                                <button mat-raised-button class="clear-button neomp-full-width button-text" (click)="clearSearch()">
                                  <span class="material-icons">clear</span>{{ 'PAGE.LEADS.LEAD.CLEAR' | translate }}
                                </button>
                              </div>
                            </div>
                        </mat-card>
                      </div>
                      <!-- END -->
                      <br>
                      <neomp-container cssClass="msg-mp-container-frameless "
                        headlineText="{{ 'PAGE.LEADS.REMINDER.APPOINTMENT-LEADS-REMINDERS' | translate}}">
                        <neomp-termin-reminders (journalRowClick)="onLeadReminderClick($event)"
                          (journalDeleteClick)="onDeleteReminder($event)" [journals]="terminLeadsReminders"
                          [isHighRole]="isHighRole"></neomp-termin-reminders>
                      </neomp-container>
                      <neomp-paginator #leadReminderPaginator [length]="totalTerminLeadsReminders" [pageSize]="pageSize"
                        [pageIndex]="pageIndex" [pageSizeOptions]="pageSizeOptions"
                        (paginatorChange)="leadReminderPaginatorValueChanged($event)">
                      </neomp-paginator>
                    </mat-tab>

                    <!-- termin reminder -->
                    <mat-tab label="{{ 'PAGE.LEADS.REMINDER.APPOINTMENT-REMINDERS' | translate}}">
                      <br>
                      <!-- SEARCH -->
                      <div
                        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
                        <mat-card>
                          <div class="search-fields-layout-grid" (keyup.enter)="getTerminReminders()">

                            <mat-form-field class="neomp-full-width">
                              <mat-label>{{ 'PAGE.LEADS.LEAD.FROM' | translate }}</mat-label>
                              <input [matDatepicker]="VonTerminReminders" matInput [(ngModel)]="dateFrom" />
                              <mat-datepicker-toggle matSuffix [for]="VonTerminReminders"></mat-datepicker-toggle>
                              <mat-datepicker #VonTerminReminders></mat-datepicker>
                            </mat-form-field>
                          
                            <mat-form-field class="neomp-full-width">
                              <mat-label>{{ 'PAGE.LEADS.LEAD.TO' | translate }}</mat-label>
                              <input [matDatepicker]="BisTerminReminders" matInput [(ngModel)]="dateTo" />
                              <mat-datepicker-toggle matSuffix [for]="BisTerminReminders"></mat-datepicker-toggle>
                              <mat-datepicker #BisTerminReminders></mat-datepicker>
                            </mat-form-field>

                            <div *ngIf="isHighRole">
                              <mat-form-field class="neomp-full-width">
                                <mat-label id="berater-label">{{ 'PAGE.LEADS.LEAD.ADVISOR' | translate }}</mat-label>
                                <mat-select [formControl]="agentSelectedControl">
                                  <mat-option [value]="">
                                    {{ 'PAGE.LEADS.LEAD.ALL' | translate }}
                                  </mat-option>
                                  <mat-option *ngFor="let agent of agents" [value]="agent.uuid">
                                    {{agent.fullName && agent.fullName.split("Neosana Sales AG")[1] ?
                                    agent.fullName.split("Neosana Sales AG")[1] : agent.fullName }}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                          </div>

                          <!-- buttons -->
                          <div class="search-fields-layout-grid margin-top-15">  
                            <div class="neo-padding-top-10">
                              <button mat-raised-button class="neomp-full-width button-text" color="primary"
                                (click)="getTerminReminders()">
                                <span class="material-icons">
                                  search
                                </span>{{ 'PAGE.LEADS.LEAD.SEARCH' | translate }}
                              </button>
                            </div>
                            <div class="neo-padding-top-10">
                              <button mat-raised-button class="clear-button neomp-full-width button-text" (click)="clearSearch()">
                                <span class="material-icons">clear</span>{{ 'PAGE.LEADS.LEAD.CLEAR' | translate }}
                              </button>
                            </div>
                          </div>  
                        </mat-card>
                      </div>
                      <!-- END -->
                      <br>
                      <neomp-container cssClass="msg-mp-container-frameless"
                        headlineText="{{ 'PAGE.LEADS.REMINDER.APPOINTMENT-REMINDERS' | translate}}">
                        <neomp-termin-reminders (journalRowClick)="onTerminReminderClick($event)"
                          (journalDeleteClick)="onDeleteReminder($event)" [journals]="terminReminders"
                          [isHighRole]="isHighRole"></neomp-termin-reminders>
                      </neomp-container>
                      <neomp-paginator #terminReminderPaginator [length]="totalTerminReminders" [pageSize]="pageSize"
                        [pageIndex]="pageIndex" [pageSizeOptions]="pageSizeOptions"
                        (paginatorChange)="terminRemindersValueChanged($event)">
                      </neomp-paginator>
                    </mat-tab>
                  </mat-tab-group>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>
              {{ 'MENU.LEADS-NAV.TASK' | translate }}
              <span class=" tasks-badge" *ngIf="totalCountTask != 0">
                {{ totalCountTask }}
              </span>
            </ng-template>

            <div class="mdc-layout-grid msg-helper-padding-none msg-helper-margin-top-10">
              <div class="mdc-layout-grid__inner">
                <div
                  class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
                  <neomp-container cssClass="msg-mp-container-frameless"
                    headlineText="{{ 'MENU.LEADS-NAV.TASK' | translate }}">
                    <neomp-task (totalTasksOutput)="getTasks(0,15,'','')"></neomp-task>
                  </neomp-container>
                </div>
              </div>
            </div>
          </mat-tab>
          <!-- <mat-tab disabled >
            <ng-template mat-tab-label>
                <button mat-icon-button [routerLink]="['/create-lead']" >
                  <img src="/assets/icons/add-lead.svg">
                </button>
            </ng-template>
        </mat-tab> -->
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>