import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnDestroy, Output } from "@angular/core";
import { Location } from "@angular/common";
import { environmentVersion } from "../../../../environments/environment.version";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { RegisterProvider } from "../providers/register.provider";
import { KeycloakService } from "keycloak-angular";
import { MatSidenav } from "@angular/material/sidenav";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { Observable, Subscription, timer } from "rxjs";
import { MessagesProvider } from "../providers/messages.provider";
import { RestProviderActionsDummy } from "../providers/rest.provider";
import { CommunicationService } from "../providers/communication.service";
import { SearchResult } from "../model/search-result";
import { log } from "../../../modules/core/providers/logger.provider";
import { environment } from "environments/environment";
import { compressToEncodedURIComponent } from "lz-string"; 
import { Person } from "../model/person";
import { LeadProvider } from "../providers/lead.provider";
import { AgenturType } from "../model/enum-agenturtype";
import { ImpersonateService } from "app/impersonate.service";
import { CommAddrTypes } from "../model/enum-commaddrtype";
import { AdvisorDataService } from "../../core/services/advisor-data.service";



@Component({
    selector: "neomp-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"],
    animations: [
        trigger("slideInOut", [
            state("in", style({
                width: "100%"
            })),
            state("out", style({
                width: "85%"
            })),
            transition("* => out", animate("200ms ease")),
            transition("* => in", animate("200ms ease"))
        ])
    ]
})
export class HeaderComponent implements AfterViewInit, OnDestroy {

    @HostListener('window:beforeunload')
    removeImpersonatedUserTokens() {
        localStorage.removeItem("impersonated");
    }

    private readonly TAG = this.constructor.name;
    private readonly TIMERINTERVAL: number = 1000 * 60 * 15;

    private searchResult: SearchResult = null;
    referralUrl: string;
    @Output()
    toggleNavEmitter = new EventEmitter<boolean>(true);
    @Input()
    isMenuItem: boolean;
    @Input()
    sidenavElement: MatSidenav;
    @Input()
    disableAnimation: boolean;
    maxHeaderWidth;
    notificationCount: number;
    timerSubscription: Subscription;
    communicationSubscription: Subscription;

    appVersion = environmentVersion.VERSION;
    copyText: string;
    matTooltipHideDelay = 0;
    advisorId: string;
    queryParams: any;
    compressedData: string;

    agents: Person[] = [];

    agenturType: string = "";
    advId: string = "";
    agenturId: string = "";
    isHighRole: boolean;


    constructor(public router: Router,
        public translate: TranslateService,
        public registerProvider: RegisterProvider,
        public activatedRoute: ActivatedRoute,
        public location: Location,
        public keycloak: KeycloakService,
        public messageProvider: MessagesProvider,
        public communicationService: CommunicationService,
        public leadProvider: LeadProvider,
        private advisorDataService: AdvisorDataService,
        private impersonationService: ImpersonateService
    ) {

        this.timerSubscription = timer(0, this.TIMERINTERVAL)
            .subscribe(value => this.getUnreadMessages());
        this.communicationSubscription = this.communicationService.getMessage().subscribe(message => {
            if (message.type === "readMessage") {
                this.getUnreadMessages();
            }
        });
    }


    async ngOnInit() {
        this.keycloak.loadUserProfile().then(async (res: any) => {
            this.advId = res.attributes.advisorid;
            this.agenturType = res.attributes.agenturType[0]
            this.agenturId = res.attributes.agenturID[0];

            // isHighRole, used for showing impersonate form field only if user is "SalesManager" (agenturType "05") or "GeneralAgent" (agenturType "01")
            if (this.agenturType === AgenturType.SalesManager || this.agenturType === AgenturType.GeneralAgent) {
                this.isHighRole = true;
                this.agents = await this.getAgents();
                this.agents = this.agents.filter(x => x.uuid != this.agenturId && x.uuid != this.advId);
            }
        }
        )


        this.getAdvisorUid();
        this.translate.onLangChange.subscribe(() => {
            this.copyText = this.translate.instant('HEADER.COPY');
        });
    }
    goToServiceDesk(){
        const serviceDesk = environment.settings.SERVICEDESK;
        window.open(serviceDesk, '_blank');
    }
    goToDashboard(){
        const dashboard = environment.settings.DASHBOARD;
        window.open(dashboard, '_blank');
    }
    goToKnowledgebase(){
        const knowledgebase = environment.settings.KNOWLEDGEBASE;
        window.open(knowledgebase, '_blank');
    }
    compressData(data: any): string {
        return compressToEncodedURIComponent(JSON.stringify(data));
    }
    
    impersonate(agent: Person) {
        const addrIndex = agent.communicationAddresses.findIndex(x => x.commAddrType.key === CommAddrTypes.EMAIL_PRIVATE);
        if (addrIndex > -1) {
            this.impersonationService.tokenExchange(agent.communicationAddresses[addrIndex].address).subscribe((data: {token: string, refresh_token: string}) => {
                localStorage.setItem("impersonated", JSON.stringify(data));
                window.open(window.location.href, '_blank');
            });
        }
    }

    copyMessage() {
        this.queryParams = {
            advId: this.advisorId,
        }
        
        this.compressedData = this.compressData(this.queryParams);
        this.referralUrl = environment.settings.REGISTER_MYNEO + "?query=" + this.compressedData;
        this.matTooltipHideDelay = 500;
        this.copyText = this.translate.instant('HEADER.COPIED');
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = this.referralUrl;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        setTimeout(() => {
            this.copyText = this.translate.instant('HEADER.COPY');
        }, 1000);
        this.matTooltipHideDelay = 0;
    }

    ngOnDestroy(): void {
        this.timerSubscription.unsubscribe();
        this.communicationSubscription.unsubscribe();
    }

    getAgents(): Promise<Person[]> {
        return this.advisorDataService.getAgents();
      }

    getUnreadMessages() {
        this.messageProvider.getUnreadMessages(new RestProviderActionsDummy()).subscribe(messages => {
            this.notificationCount = messages.length;
        });
    }

    ngAfterViewInit(): void {
        this.sidenavElement.openedStart.subscribe((value) => {
            this.maxHeaderWidth = "out";
        });
        this.sidenavElement.closedStart.subscribe((value) => {
            this.maxHeaderWidth = "in";
        });
    }

    toggleNavigation() {
        this.toggleNavEmitter.emit();
    }

    backNavigation() {
        this.location.back();
    }

    logOff() {
        this.keycloak.logout();
    }

    getAdvisorUid() {
        this.keycloak.loadUserProfile().then((res: any) =>
            this.advisorId = res.attributes.advisorid[0]
        )
    }
    getUser() {
        return this.keycloak.getUsername().substring(0, 2);
    }

    onSearchResult(result: SearchResult) {
        log.debug(`${this.TAG} -> onSearchResult() -> retrieved=${JSON.stringify(result)}`);
        this.searchResult = result;
    }
}
