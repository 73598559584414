import {Component, OnInit} from "@angular/core";

@Component({
    selector: "neomp-my-commission-page",
    templateUrl: "./my-commission-page.component.html",
    styleUrls: ["./my-commission-page.component.scss"]
})
export class MyCommissionPageComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
