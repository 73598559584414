import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { RegisterProvider } from "../../core/providers/register.provider";
import { LoadingDataComponent } from "../../core/components/loading-data.component";
import { PolicyProvider } from "../../core/providers/policy.provider";
import { Policy } from "../../core/model/policy";
import { Subscription } from "rxjs";
import { DatePickerValue } from "../../core/model/datepicker";
import { PaginationConfigs } from "app/modules/constants/PaginationConstants";
import { FormControl } from "@angular/forms";
import { ConfigProvider } from "app/modules/core/providers/config.provider";
import { format } from "date-fns";
import { ActionDialogService } from "app/modules/core/providers/action-dialog.service";
@Component({
  selector: 'neomp-cancellation-management',
  templateUrl: './cancellation-management.component.html',
  styleUrls: ['./cancellation-management.component.scss']
})
export class CancellationManagementComponent implements OnInit, OnDestroy {
    pageSize = PaginationConfigs.pageSize;
    pageSizeOptions = PaginationConfigs.pageSizeOptions;
    pageIndex = PaginationConfigs.pageIndex;
    totalCount = 0;

    policies: Policy[] = [];
    timerSubscription: Subscription;
    isInitialized: boolean;
    isDataLoading: boolean;
    searchText: string | null = null;
    dateFrom:any;
    dateTo:any;
    subscriptions: Subscription[] = [];
    status='50';
    statusList=['50'];
    cancellationStatus=["Nicht hochgeladen", "Kündigung hochgeladen", "Kündigung nicht notwendig"];
    cancellationStatusSelected = new FormControl("");
    typeName="KK";
    betreff=["1. KVG", "2. VVG", "3. KVG & VVG", "1. Vorgeburtlich", "2. Zuzug Ausland", "3. Kunde kündigt selber"];
    betreffSelected = new FormControl("");
    cancellationApproval: boolean | null = null;


    @ViewChild("cancellationTables") CancellationManagementTableComponent: LoadingDataComponent;

    constructor(public policyProvider: PolicyProvider,
        public route: ActivatedRoute,
        public router: Router,
        public location: Location,
        private configProvider: ConfigProvider,
        public registerProvider: RegisterProvider,
        public dialogService: ActionDialogService) {
        this.isInitialized = false;
    }

    ngOnInit() {
        this.route.queryParams
            .subscribe((params) => {
                this.searchFromParams(params);
                this.dateFrom = this.dateFrom ? format(new Date(this.route.snapshot.queryParams.dateFrom.toString()), "YYYY-MM-DDT00:00:00.000"):undefined
                this.dateTo =this.dateTo ? format(new Date(this.route.snapshot.queryParams.dateTo.toString()), "YYYY-MM-DDT23:59:00.000"): undefined
            })

        this.dialogService.cancellationActionDone.subscribe(res => {
            if (res) this.search();
        })

    }

    ngAfterViewInit(): void {
        this.isInitialized = true;
    }

    getPoliciesBySearch(
        dateRange: DatePickerValue,
        page: number,
        size: number,
        searchText: string,
        statusList: string[],
        filter?: string,
        ) {
        this.dataLoading = true;
        this.policyProvider.getPoliciesWithTransaction(this.CancellationManagementTableComponent, dateRange, page, size, searchText, statusList, filter)

            .subscribe(res => {
                this.policies = res.data;
                this.totalCount = res.totalCount;
                this.dataLoading = false;
            });
    }

    set dataLoading(dataLoading: boolean) {
        if (this.isInitialized) {
            this.isDataLoading = dataLoading;
        }
    }

    search() {
        const dateFrom = this.dateFrom == undefined || this.dateFrom == "Invalid Date" ? "" : format(new Date(this.dateFrom.toString()), "YYYY-MM-DDT00:00:00.000")
        const dateTo = this.dateTo == undefined || this.dateTo == "Invalid Date" ? "" : format(new Date(this.dateTo.toString()), "YYYY-MM-DDT23:59:00.000")
        let filter = '';
        
        filter = `&filter={"type":"FixedValue","key":"productBundleName","value":"${this.typeName}"} &filter={"for":"BT","type":"FixedValue","key":"typeId","value":{"$in":["591","592","593","594"]},"type.key": "010"}`;

        if (![null, undefined, ""].includes(this.searchText)) {
            filter += `&text=${this.searchText}`
        }

        if (![null, undefined, ""].includes(this.dateTo) || ![null, undefined, ""].includes(this.dateFrom)) {
            filter += `&filter={"type":"DateTime","key":"validFrom","from":"${dateFrom}","to":"${dateTo}"}`
        }
        
        if(this.cancellationStatusSelected.value && this.cancellationStatusSelected.value.length > 0){
            const cancellationSelected = Array.isArray(this.cancellationStatusSelected.value) ? this.cancellationStatusSelected.value : [this.cancellationStatusSelected.value];
            if(cancellationSelected.includes('Nicht hochgeladen')) {
                filter += `&filter={"type":"FixedValue","key":"$or","value":${JSON.stringify([
                    {"cancellations.cancellationStatus": {"$in": cancellationSelected}},
                    {"cancellations.cancellationStatus": {"$not": {"$exists": "true"}}},
                ])}}`;
            } else {
                filter += `&filter={"type":"ArrayCase","key":"cancellations.cancellationStatus","values":${JSON.stringify(cancellationSelected)}}`;
            }
        }

        if (this.betreffSelected.value && this.betreffSelected.value.length > 0) {
            const allBetrefSelected = Array.isArray(this.betreffSelected.value) ? this.betreffSelected.value : [this.betreffSelected.value];
            filter += `&filter={"type":"ArrayCase","key":"cancellations.betreff","values":${JSON.stringify(allBetrefSelected)}}`;
        }
        
        if (this.cancellationApproval===true) {
            filter += `&filter={"type":"FixedValue","key":"cancellations.cancellationApproval","value":${this.cancellationApproval}}`;
        }
        
        this.getPoliciesBySearch(
            null,
            this.pageIndex,
            this.pageSize,
            this.searchText,
            this.statusList,
            filter
        );
    }

    searchFilter() {
        const dateFrom = this.dateFrom == undefined || this.dateFrom == "Invalid Date" ? "" : format(new Date(this.dateFrom.toString()), "YYYY-MM-DDT00:00:00.000")
        const dateTo = this.dateTo == undefined || this.dateTo == "Invalid Date" ? "" : format(new Date(this.dateTo.toString()), "YYYY-MM-DDT23:59:00.000")
        let filter = '';
        filter = `&filter={"type":"FixedValue","key":"productBundleName","value":"${this.typeName}"} &filter={"for":"BT","type":"FixedValue","key":"typeId","value":{"$in":["591","592","593","594"]},"type.key": "010"}`;
        
        if (![null, undefined, ""].includes(this.searchText)) {
            filter += `&text=${this.searchText}`
        }

        if (![null, undefined, ""].includes(this.dateTo) || ![null, undefined, ""].includes(this.dateFrom)) {
            filter += `&filter={"type":"DateTime","key":"validFrom","from":"${dateFrom}","to":"${dateTo}"}`
        }
        
        if(this.cancellationStatusSelected.value && this.cancellationStatusSelected.value.length > 0){
            const cancellationSelected = Array.isArray(this.cancellationStatusSelected.value) ? this.cancellationStatusSelected.value : [this.cancellationStatusSelected.value];
            if(cancellationSelected.includes('Nicht hochgeladen')) {
                filter += `&filter={"type":"FixedValue","key":"$or","value":${JSON.stringify([
                    {"cancellations.cancellationStatus": {"$in": cancellationSelected}},
                    {"cancellations.cancellationStatus": {"$not": {"$exists": "true"}}},
                ])}}`;
            } else {
                filter += `&filter={"type":"ArrayCase","key":"cancellations.cancellationStatus","values":${JSON.stringify(cancellationSelected)}}`;
            }
        }

        if (this.betreffSelected.value && this.betreffSelected.value.length > 0) {
            const allBetrefSelected = Array.isArray(this.betreffSelected.value) ? this.betreffSelected.value : [this.betreffSelected.value];
            filter += `&filter={"type":"ArrayCase","key":"cancellations.betreff","values":${JSON.stringify(allBetrefSelected)}}`;
        }
        
        
        if (this.cancellationApproval===true) {
            filter += `&filter={"type":"FixedValue","key":"cancellations.cancellationApproval","value":${this.cancellationApproval}}`;
        }

        this.router.navigate(["/cancellation-management"], {
            queryParams: {
                text: this.searchText,
                dateFrom:this.dateFrom,
                dateTo:this.dateTo,
                cancellationStatus:JSON.stringify(this.cancellationStatusSelected.value),
                subject:JSON.stringify(this.betreffSelected.value),
                cancellationAproval:this.cancellationApproval,
                pageIndex: PaginationConfigs.pageIndex,
                pageSize: this.pageSize
            }
        })
        this.clearSubscriptions();
    }

    clearSearch() {
        this.searchText = '';
        this.dateFrom = undefined;
        this.dateTo = undefined;
        this.cancellationStatusSelected.reset();
        this.betreffSelected.reset();
        this.cancellationApproval = undefined;
        this.clearSubscriptions();
        this.router.navigate(["/cancellation-management"], {
            queryParams: {
                text: "",
                dateFrom:"",
                dateTo:"",
                cancellationStatus:"",
                subject:"",
                cancellationAproval:null,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize
            }
        })
    }

    searchFromParams(params?): void {
        this.searchText = '';
        this.pageIndex = Number(params.pageIndex) || PaginationConfigs.pageIndex;
        this.pageSize = Number(params.pageSize) > 0 ? Number(params.pageSize) : PaginationConfigs.pageSize;

        if (params.text) {
            this.searchText = params.text;
        }
        if (params.status) {
            this.status=params.status;
        }
        if(params.dateFrom){
            this.dateFrom=params.dateFrom;
        }
        if(params.dateTo){
            this.dateTo=params.dateTo;
        }
        if (params.cancellationStatus) {
            this.cancellationStatusSelected.setValue(JSON.parse(params.cancellationStatus));
        }
        if (params.subject) {
            this.betreffSelected.setValue(JSON.parse(params.subject));
        }
        if (params.cancellationApproval) {
            this.cancellationApproval;
        }
        this.search();
    }

    paginatorValueChanged(event) {
        if (Number(this.pageIndex) === Number(event.pageIndex) && Number(this.pageSize) === Number(event.pageSize)) return;
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
        this.router.navigate(["/cancellation-management"], {
            queryParams: {
                text: this.searchText,
                dateFrom:this.dateFrom,
                dateTo:this.dateTo,
                cancellationStatus:JSON.stringify(this.cancellationStatusSelected.value),
                subject:JSON.stringify(this.betreffSelected.value),
                cancellationAproval:this.cancellationApproval,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize
            }
        })
    }

    clearSubscriptions() {
        this.subscriptions.forEach(s => s.unsubscribe());
        this.subscriptions = [];
    }


    ngOnDestroy(): void {
        if (this.timerSubscription) {
            this.timerSubscription.unsubscribe();
        }
        this.clearSubscriptions();
    }
}
