import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-cancellation-management-blocked',
  templateUrl: './cancellation-management-blocked.component.html',
  styleUrls: ['./cancellation-management-blocked.component.scss']
})
export class CancellationManagementBlockedComponent {
  text: string = '';
  fontColor: string = '';

  constructor(
    public dialogRef: MatDialogRef<CancellationManagementBlockedComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.text = data.text;
    this.fontColor = data.fontColor;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}