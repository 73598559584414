import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LeadsComponent } from "./pages/leads/leads.component";
import { CoreModule } from "../core/core.module";
import { LeadsNavbarComponent } from "./pages/leads/leads-navbar.component";
import { MatTableModule } from "@angular/material/table";
import { LeadsEditComponent } from "./pages/leads/leads-edit.component";
import { LeadsEditDetailsComponent } from "./pages/leads/leads-edit-details.component";
import { LeadsTableComponent } from "../customers/components/leads-table.component";
import { LeadsEditTerminComponent } from './pages/leads/leads-edit-termin.component';
import { LeadsLogComponent } from './pages/leads/leads-log.component';
import { TerminComponent } from './pages/leads/termin.component';
import { TaskComponent } from './pages/leads/task.component';
import { TerminCalendarComponent } from './pages/leads/termin-calendar.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { FormsModule } from '@angular/forms';
import { CalendarHeaderComponent } from './pages/leads/calendar-header.component';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import localeDe from '@angular/common/locales/de';
import { TerminEditComponent } from './pages/leads/termin-edit.component';
import { TermineTableComponent } from "../customers/components/termine-table.component";
import { LeadsManagementComponent } from "./pages/lead-management/leads-management/leads-management.component";
import { LeadActionComponent } from "./pages/dialogs/lead.action.component";
import { LeadStatusDialogComponent } from './pages/dialogs/lead-status-dialog/lead-status-dialog.component';
import { TaskCompleteComponent } from './pages/dialogs/task-complete/task-complete.component';
import { CreateLeadComponent } from './pages/create-lead/create-lead.component';
import { TerminManagementComponent } from "./pages/dialogs/termin-management/termin-management.component";
import { FullCalendarModule } from '@fullcalendar/angular';
import { LeadPoolComponent } from './pages/lead-pool/lead-pool.component';
import { RetentionComponent } from './pages/retention/retention.component';

registerLocaleData(localeFr);
registerLocaleData(localeIt);
registerLocaleData(localeDe);

@NgModule({
  imports: [CommonModule, CoreModule, MatTableModule, 
    CalendarModule.forRoot({
    provide: DateAdapter,
    useFactory: adapterFactory,
  }),
  FormsModule,
  BrowserAnimationsModule,
  FullCalendarModule
],
  declarations: [
    LeadsComponent,
    LeadsNavbarComponent,
    LeadsEditComponent,
    LeadsEditDetailsComponent,
    LeadsTableComponent,
    TermineTableComponent,
    LeadsEditTerminComponent,
    LeadsLogComponent,
    TerminComponent,
    TaskComponent,
    TerminCalendarComponent,
    CalendarHeaderComponent,
    TerminEditComponent,
    LeadsManagementComponent,
    LeadActionComponent,
    LeadStatusDialogComponent,
    TaskCompleteComponent,
    CreateLeadComponent,
    TerminManagementComponent,
    LeadPoolComponent,
    RetentionComponent,
  ],
  entryComponents: [LeadActionComponent, LeadStatusDialogComponent, TaskCompleteComponent],
  exports: [LeadActionComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LeadsModule {}
