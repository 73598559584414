import {SearchResultJson} from "../json/search-result.json";
import {Person} from "./person.js";
import {Policy} from "./policy";
import {Journal} from "./journal.js";
import { SearchType } from "./enum-searchtype";

export class SearchResult {
    customers: Person[] = [];
    policies: Policy[] = [];
    journals: Journal[] = []
    searchType: SearchType;

    public static fromJson(response: SearchResultJson): SearchResult | undefined {
        return new SearchResult(response);
    }

    public constructor(sr?: SearchResultJson) {
        if (sr) {
            this.customers = sr.customers;
            this.policies = sr.policies;
            this.journals = sr.journals;
            this.searchType = sr.searchType;
        }
    }
}
