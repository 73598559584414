import {AfterViewInit, Component, OnInit, ViewChild} from "@angular/core";
import {LoadingDataComponent} from "../../core/components/loading-data.component";
import {JournalList} from "../../core/model/journal-list";
import {JournalProvider} from "../../core/providers/journal.provider";
import {BusinessTransactionHelper} from "../../core/static/bt-helper";
import {SlideRangeFilterValue} from "../../core/model/slide-range-filter-value";
import * as moment from "moment";
import {Observable} from "rxjs";
import { PaginationConfigs } from "app/modules/constants/PaginationConstants";

@Component({
    selector: "neomp-provision-pending-matter",
    templateUrl: "./provision-pending-matter.component.html",
    styleUrls: ["./provision-pending-matter.component.scss"]
})
export class ProvisionPendingMatterComponent implements OnInit, AfterViewInit {

    journalLists: JournalList[] = [];
    pageSize = PaginationConfigs.pageSize;
    pageSizeOptions = PaginationConfigs.pageSizeOptions;
    pageIndex = PaginationConfigs.pageIndex;
    totalCount = 0;
    @ViewChild("loadingDataComponent")
    loadingDataComponent: LoadingDataComponent;
    filterValue: SlideRangeFilterValue = new SlideRangeFilterValue();

    isInitialized: boolean;
    isDataLoading: boolean;

    constructor(public journalProvider: JournalProvider) {
    }

    ngOnInit() {
        this.filterValue.isActive = true;
        this.filterValue.dateRange.end = moment();
        this.filterValue.dateRange.begin = moment().subtract(60, "days");

        this.getElements(this.filterValue);

    }

    ngAfterViewInit(): void {
        this.isInitialized = true;
    }

    getElements(filterValue) {
        this.dataLoading = true;
        const sort = {"status.name": -1, "effectiveDate": -1};

        const journalObservable: Observable<{data: JournalList[], totalCount: number}> = filterValue.isActive ?
            this.journalProvider.getJournalsReferencedByDateRangeWithPagination(
                this.loadingDataComponent,
                BusinessTransactionHelper.typeId.provisionPendingMatter.from,
                BusinessTransactionHelper.typeId.provisionPendingMatter.to,
                filterValue.dateRange,
                this.pageIndex,
                this.pageSize,
                sort) :
            this.journalProvider.getJournalsReferencedWithPagination(
                this.loadingDataComponent,
                BusinessTransactionHelper.typeId.provisionPendingMatter.from,
                BusinessTransactionHelper.typeId.provisionPendingMatter.to,
                this.pageIndex,
                this.pageSize,
                sort);

        journalObservable.subscribe(journalLists => {
            this.journalLists = journalLists.data.reverse();
            this.totalCount = journalLists.totalCount;
            this.dataLoading = false;
        }, error => {
            this.dataLoading = false;
        });
    }

    set dataLoading(dataLoading: boolean) {
        if (this.isInitialized) {
            this.isDataLoading = dataLoading;
        }
    }
    paginatorValueChanged(event) {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
        this.getElements(this.filterValue);
      }
}
