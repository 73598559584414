<div class="mdc-layout-grid msg-helper-padding-none">
    <div class="mdc-layout-grid__inner msg-helper-grid-gap-horizontal">

        <!-- Customer Search Result -->
        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop
                                mdc-layout-grid__cell--span-12-tablet
                                mdc-layout-grid__cell--span-12-phone">
            <neomp-container cssClass="msg-mp-container-frameless" [headlineText]="translateHeader">

                <div class="mb-3" *ngIf="displayTables.customers">
                    <neomp-search-result-table-customer class="datePickerHide"
                        [tableEntries]="customers"></neomp-search-result-table-customer>
                </div>

                <div class="mb-3" *ngIf="displayTables.policies">
                    <neomp-result-table-products [policies]="policies"></neomp-result-table-products>
                </div>

                <div class="mb-3">
                    <neomp-container cssClass="msg-mp-container-frameless"
                        headlineText="{{ 'PAGE.CONTAINER.JOURNALS.CONTAINER.HEADLINE' | translate }}">
                        <neomp-open-issues-table class="datePickerHide"
                            [tableEntries]="journals"></neomp-open-issues-table>
                    </neomp-container>
                </div>


                <!-- TODO AVa should we display a loading table here? -->
                <neomp-loading-data #loadingSearchResultCustomer></neomp-loading-data>
                <div *ngIf="searchInProgress" class="msg-loadingstatus msg-nodata ">
                    <div class="mdi mdi-timer-sand">
                        <span>{{ 'COMMONS.LOADINGSTATUS.LOADING' | translate }}</span>
                    </div>
                </div>
                <div *ngIf="!searchInProgress && (
                     customers.length === 0
                    &&
                     policies.length === 0
                    &&
                     journals.length === 0
                    ) " class="msg-loadingstatus msg-nodata ">
                    <div class="mdi mdi-cancel">
                        <span>{{ 'COMMONS.LOADINGSTATUS.NODATA' | translate }}</span>
                    </div>
                </div>
            </neomp-container>
        </div>

        <!-- TODO AVa ProductBundles -->
        <!-- TODO AVa BusinessTransactions -->
    </div>
</div>