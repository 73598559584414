import { APP_INITIALIZER, NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { AppComponent } from "./app.component";
import { Router, RouterModule } from "@angular/router";
import { ROUTES } from "./app-routing.constants";
import { Title } from "@angular/platform-browser";
import { CoreModule } from "./modules/core/core.module";
import { RegisterProvider } from "./modules/core/providers/register.provider";
import { TestModule } from "./modules/test/test.module";
import { TestPageComponent } from "./modules/test/pages/test-page.component";
import { Logger, msgLogger } from "./modules/core/providers/logger.provider";
import { CustomersModule } from "./modules/customers/customers.module";
import { MyCustomersPageComponent } from "./modules/customers/pages/my-customers-page.component";
import { MyCustomersDetailPageComponent } from "./modules/customers/pages/my-customers-detail-page.component";
import { OpenIssuesModule } from "./modules/openissues/open-issues.module";
import { MyOpenIssuesPageComponent } from "./modules/openissues/pages/my-open-issues-page.component";
import { PolicyDetailPageComponent } from "./modules/customers/pages/policy-detail-page.component";
import { KeycloakAngularModule, KeycloakService } from "keycloak-angular";
import { initializer } from "./modules/core/auth/app-init-keycloak";
import { OffersModule } from "./modules/offers/offers.module";
import { MyOfferPageComponent } from "./modules/offers/pages/my-offer-page.component";
import { CommissionModule } from "./modules/commission/commission.module";
import { MyCommissionPageComponent } from "./modules/commission/pages/my-commission-page.component";
import { MessagesPageComponent } from "./modules/messages/pages/messages-page.component";
import { MessagesModule } from "./modules/messages/messages.module";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { ApplicationsPageComponent } from "./modules/applications/pages/applications-page.component";
import { ApplicationsModule } from "./modules/applications/applications.module";
import { ActionDialogComponent } from "./modules/core/components/action.dialog.component";
import { DatePickerComponent } from "./modules/core/components/date-picker/date-picker.component";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { DevelopModeGuard } from "./modules/core/guards/DevelopModeGuard";
import { SearchResultModule } from "./modules/search/search-result.module";
import { SearchResultPageComponent } from "./modules/search/pages/search-result-page.component";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpErrorInterceptor } from "./modules/core/http/HttpErrorInterceptor";
import { SharedModule } from "./modules/shared/shared.module";
import { DatePipe } from "@angular/common";
import { MessagesDialogComponent } from "./modules/core/components/messages-dialog.component";
import { QRCodeModule } from "angularx-qrcode";
import { InfoModule } from "./modules/info/info.module";
import { InfoComponent } from "./modules/info/components/info.component";
import { LeadsModule } from "./modules/leads/leads.module";
import { LeadsNavbarComponent } from "./modules/leads/pages/leads/leads-navbar.component";
import { LeadsEditComponent } from "./modules/leads/pages/leads/leads-edit.component";
import { ReferralsComponent } from "./modules/referrals/components/referrals.component";
import { ReferralsModule } from "./modules/referrals/referrals.module";
import { TerminEditComponent } from "./modules/leads/pages/leads/termin-edit.component";
import { LeadsEditTerminComponent } from "./modules/leads/pages/leads/leads-edit-termin.component";
import { PendingChangesGuard } from "./modules/core/guards/pending-changes.guard";
import { ConfirmationComponent } from "./modules/core/components/confirmation-component/confirmation.component";
import { LeadsManagementComponent } from "./modules/leads/pages/lead-management/leads-management/leads-management.component";
import { AuthGuard } from "./modules/core/guards/AuthGuard";
import { CreateLeadComponent } from "./modules/leads/pages/create-lead/create-lead.component";
import { EventsModule } from "./modules/events/events.module";
import { EventsComponent } from "./modules/events/pages/events.component";
import { ReferralsTableComponent } from "./modules/customers/components/referrals-table.component";
import { MarkdownModule } from "ngx-markdown";
import {NeoOfferModule} from "neo-offer";
import {BrowserModule} from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ActionDataProvider } from "./modules/core/providers/action-data.provider";
import { ClientGuard } from "./modules/core/guards/ClientGuard";
import { SupplierGuard } from "./modules/core/guards/SupplierGuard";
import { LivechatModule } from "./modules/livechat/support/livechat.module";
import { MortgageComponent } from './modules/mortgage/mortgage/mortgage.component';
import { LeadpoolblockeddialogComponent } from './modules/modals/leadpoolblockeddialog/leadpoolblockeddialog.component';
import { OffersOldModule } from "./modules/offers-old/offers-old.module";
import { AdminGuard } from "./modules/core/guards/AdminGuard";
import { LiveChatGuard } from "./modules/core/guards/LiveChatGuard";
import { environment, LANGUAGES, DEFAULT_LANGUAGE } from "environments/environment";
import { CancellationManagementBlockedComponent } from './modules/modals/cancellation-management-blocked/cancellation-management-blocked.component';

@NgModule({
  declarations: [AppComponent
    ,
    MessagesDialogComponent,
    ConfirmationComponent,
    MyOfferPageComponent,
    MortgageComponent,
    LeadpoolblockeddialogComponent,
    CancellationManagementBlockedComponent
  ],
  imports: [
    BrowserModule,
    CoreModule,
    SharedModule,
    KeycloakAngularModule,
    TestModule,
    CustomersModule,
    OpenIssuesModule,
    OffersModule,
    OffersOldModule,
    CommissionModule,
    MessagesModule,
    LeadsModule,
    ApplicationsModule,
    SearchResultModule,
    MatMomentDateModule,
    InfoModule,
    ReferralsModule,
    MarkdownModule.forRoot(),
    EventsModule,
    QRCodeModule,
    RouterModule.forRoot(ROUTES, {
      useHash: true,
      scrollPositionRestoration: "enabled",
      anchorScrolling: "enabled",
      relativeLinkResolution: 'legacy'
    }),
  NeoOfferModule.forRoot(environment, {LANGUAGES, DEFAULT_LANGUAGE}),
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    LivechatModule
  ],
  entryComponents: [
    TestPageComponent,
    MyCustomersPageComponent,
    MyCustomersDetailPageComponent,
    MyOpenIssuesPageComponent,
    PolicyDetailPageComponent,
    MyOfferPageComponent,
    MyCommissionPageComponent,
    MessagesPageComponent,
    LeadsNavbarComponent,
    LeadsEditComponent,
    TerminEditComponent,
    ApplicationsPageComponent,
    SearchResultPageComponent,
    ActionDialogComponent,
    MessagesDialogComponent,
    DatePickerComponent,
    InfoComponent,
    ReferralsComponent,
    LeadsEditTerminComponent,
    ConfirmationComponent,
    LeadsManagementComponent,
    CreateLeadComponent,
    EventsComponent,
    ReferralsTableComponent
  ],
  providers: [
    Title,
    RegisterProvider,
    Logger,
    PendingChangesGuard,
    DatePipe,
    ClientGuard,
    SupplierGuard,
    AdminGuard,
    LiveChatGuard,
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      multi: true,
      deps: [KeycloakService],
    },
    { provide: MAT_DATE_LOCALE, useValue: "de-DE" },
    DevelopModeGuard,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    ActionDataProvider
  ],
  exports:[
    CoreModule, OffersModule, OffersOldModule, ApplicationsModule,SharedModule,MyOfferPageComponent ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA],
})
export class AppModule {
  constructor(logger: Logger) {
    msgLogger(logger);
  }
}
