import { Injectable } from '@angular/core';
import { GMOfferConstants } from '../constants/gm-offer-constants';
import { Persondata } from '../models/PersonData';
import { SearchParams } from '../models/SearchParams';

@Injectable({
  providedIn: 'root'
})

export class SearchMaskService {
  public static neoYearMinValue = 1900;
  public static neoYearMaxValue = new Date().getFullYear();
  public suchmaskeDaten: SearchParams;
  constructor() { }

  
  public static isJahrgangValide(jahrgang: number): boolean {
    return jahrgang >= SearchMaskService.neoYearMinValue && jahrgang <= SearchMaskService.neoYearMaxValue;
}

public static getAgeForYear(year: number): number {
    return (new Date().getFullYear()) - year;
}

public static getAltersklasseForYear(year: number): number {
    const alter = SearchMaskService.getAgeForYear(year);
    if (alter >= 0 && alter <= 18) {
        return 0;
    } else if (alter >= 19 && alter <= 25) {
        return 1;
    } else if (alter >= 26) {
        return 2;
    } else {
        return -1;
    }
}

public static getAccidentCoverageForYear(year): boolean {
    const alter = SearchMaskService.getAgeForYear(year);
    return (alter >= 0 && alter <= 15);
}

public static getAltersklasse(person: Persondata): number {
    if (person && person.jahrgang !== undefined) {
        const year = SearchMaskService.getAltersklasseForYear(person.jahrgang);
        if (year >= 0) {
            return year;
        } else {
            throw new Error(`Jahrgang ${person.jahrgang} wird nicht unterstützt!`);
        }
    }
}


public static getTranslatedGenderShortcutString(fullGender: string): string {
    switch (fullGender) {
        case GMOfferConstants.GENDER_MALE: {
          return 'VGR.COMMON.SHORTCUT.MALE';
        }
        case GMOfferConstants.GENDER_FEMALE: {
          return 'VGR.COMMON.SHORTCUT.FEMALE';
        }
        case GMOfferConstants.GENDER_UNBORN: {
          return 'VGR.COMMON.UNBORn';
        }
        default: {
            return "?";
        }
    }
}

public updateSearchParameter(data: SearchParams) {
    if (this.suchmaskeDaten === undefined ) {
        this.suchmaskeDaten = data;
    } else {
        this.suchmaskeDaten.tarifJahr = data.tarifJahr;
        this.suchmaskeDaten.nurKVG = data.nurKVG;
        this.suchmaskeDaten.weitereVersichertePersonen = data.weitereVersichertePersonen;
        this.suchmaskeDaten.region = data.region;
        this.suchmaskeDaten.tariftypen = data.tariftypen;
    }
}
}




