<table [dataSource]="dataSource"
       class="msg-helper-width-100 neo-content-wrapper"
       mat-table
       matSort
       multiTemplateDataRows>

    <!-- columns -->
    <ng-container matColumnDef="descriptionField">
        <th *matHeaderCellDef mat-header-cell
            mat-sort-header>{{ 'PAGE.CONTAINER.COMMISSIONS.TABLES.PROVISIONSACCOUNTING.DESCRIPTIONFIELD' | translate }}
        </th>
        <td mat-cell *matCellDef="let element"> {{element.descriptionField}}</td>
    </ng-container>
    <ng-container matColumnDef="creationDate">
        <th *matHeaderCellDef mat-header-cell
            mat-sort-header>{{ 'PAGE.CONTAINER.COMMISSIONS.TABLES.PROVISIONSACCOUNTING.BTCREATED' | translate }}
        </th>
        <td mat-cell *matCellDef="let element"> {{formatDate(element.transaction.creationDate)}}</td>
    </ng-container>
    <ng-container matColumnDef="document">
        <th *matHeaderCellDef mat-header-cell
            mat-sort-header>{{ 'PAGE.CONTAINER.COMMISSIONS.TABLES.PROVISIONSACCOUNTING.DOCUMENT' | translate }}
        </th>
        <td mat-cell *matCellDef="let element" [attr.colspan]="2">
            <span *ngFor="let resource of element.documentResources">
                <button mat-icon-button (click)="!resource.fromMyNeo ? download(resource.id) : downloadFromMyNeo(resource.id)" [title]="resource.title">
                    <div class="neomp-table-column-icon-element mdi {{ documentProvider.getIconByName(resource.title) }}"></div>
                </button>
            </span>
        </td>
    </ng-container>
    <ng-container matColumnDef="filter">
        <th *matHeaderCellDef mat-header-cell
            (click)="changeFilterView()"
            class="mat-header-cell mat-column-filter {{isFilterActive ? 'mat-column-filter-active':''}}">
            <div
                class="neomp-table-column-filter-element mdi {{isFilterActive ? 'mdi-filter':'mdi-filter-outline'}}"></div>
        </th>
        <td mat-cell *matCellDef="let element"></td>
    </ng-container>

    <!-- filters -->
    <ng-container matColumnDef="descriptionFieldFilter">
        <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter" floatLabel="never">
                <input matInput
                       [formControl]="descriptionFieldFilter"
                       placeholder="{{ 'TABLES.FILTERPLACEHOLDER.INVOICE' | translate }}">
            </mat-form-field>
        </th>
    </ng-container>
    <ng-container matColumnDef="creationDateFilter">
        <th mat-header-cell *matHeaderCellDef>
            <neomp-date-picker 
            [setDefaultValues]="false"
            (filterValue)="creationDateFilterChanged($event)"></neomp-date-picker>
        </th>
    </ng-container>
    <ng-container matColumnDef="documentFilter">
        <th mat-header-cell *matHeaderCellDef>
        </th>
    </ng-container>
    <ng-container matColumnDef="filterRemove">
        <th mat-header-cell *matHeaderCellDef (click)="resetFilter()">
            <div class="neomp-table-column-filter-element mdi mdi-close"></div>
        </th>
        <td *matCellDef="let element" mat-cell class="mat-column-filter-action">
            <div class="neomp-table-column-filter-element mdi mdi-plus"
            [disabled]="isDialogOpen"
            (click)="isDialogOpen ? null : openActionDialog($event, element)"></div>
        </td>
    </ng-container>

    <!--Row definitions -->
    <tr *matHeaderRowDef="displayedColumns"
        mat-header-row></tr>
    <tr *matHeaderRowDef="displayedColumnFilters"
        class="neomp-table-row-filter {{showFilter? '': 'neomp-table-row-filter-hide'}}"
        mat-header-row></tr>
    <tr mat-row *matRowDef="let openIssuesRow; columns: displayedValueColumns, let i = dataIndex;"
        class="openIssuesDetail-row container-table-row neomp-table-row-selected reset-cursor"></tr>
</table>
