<div class="mdc-layout-grid msg-helper-padding-none">
  <div class="mdc-layout-grid__inner msg-helper-grid-gap-horizontal">
    <div
      class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
      <div class="mdc-layout-grid msg-helper-padding-none msg-helper-margin-top-10">
        <div class="mdc-layout-grid__inner">
          <div
            class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
            <div class="mdc-layout-grid__cell
            mdc-layout-grid__cell--span-12-desktop
             mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone">
              <neomp-container [isLightContainer]="false" cssClass="msg-mp-container-frameless"
                headlineText="{{ 'MENU.ENTRIES.LEADMANAGEMENT' | translate }}">
                <neomp-loading-data #loading></neomp-loading-data>
              </neomp-container>
            </div>
            <div
              class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
              <mat-card>
                <!-- filter fields -->
                <div class="search-fields-layout-grid" (keyup.enter)="search()">

                    <mat-form-field class="neomp-full-width">
                      <mat-label>{{ 'PAGE.LEADS.LEAD.TEXT' | translate }}</mat-label>
                      <input matInput [(ngModel)]="searchText" />
                    </mat-form-field>
                  
                    <mat-form-field class="neomp-full-width">
                      <mat-label>{{ 'PAGE.LEADS.LEAD.FROM' | translate }}</mat-label>
                      <input [matDatepicker]="From" matInput [(ngModel)]="dateFrom" />
                      <mat-datepicker-toggle matSuffix [for]="From"></mat-datepicker-toggle>
                      <mat-datepicker #From></mat-datepicker>
                    </mat-form-field>
                  
                    <mat-form-field class="neomp-full-width">
                      <mat-label>{{ 'PAGE.LEADS.LEAD.TO' | translate }}</mat-label>
                      <input [matDatepicker]="To" matInput [(ngModel)]="dateTo" />
                      <mat-datepicker-toggle matSuffix [for]="To"></mat-datepicker-toggle>
                      <mat-datepicker #To></mat-datepicker>
                    </mat-form-field>

                  <div *ngIf="isHighRole && leadsTabGroupCurrentIndex === 1">
                    <mat-form-field class="neomp-full-width">
                      <mat-label id="berater-label">{{ 'PAGE.LEADS.LEAD.ADVISOR' | translate }}</mat-label>
                      <mat-select [formControl]="agentSelectedControl">
                        <mat-option [value]="'0'">
                          Leads without Agent
                        </mat-option>
                        <mat-option [value]="">
                          {{ 'PAGE.LEADS.LEAD.ALL' | translate }}
                        </mat-option>
                        <mat-option *ngFor="let agent of agents" [value]="agent.uuid">
                          {{agent.fullName && agent.fullName.split("Neosana Sales AG")[1] ?
                          agent.fullName.split("Neosana Sales AG")[1] : agent.fullName }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div *ngIf="isHighRole && leadsTabGroupCurrentIndex !== 0">
                    <mat-form-field class="neomp-full-width">
                      <mat-label>StatusList</mat-label>
                      <mat-select [formControl]="statusSelected" (selectionChange)="statusesSelected($event)" multiple>
                        <mat-option value="Neu">Neu</mat-option>
                        <mat-option *ngFor="let status of statusesList" [value]="status">{{status}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div *ngIf="isHighRole && leadsTabGroupCurrentIndex !== 0">
                    <mat-form-field class="neomp-full-width">
                      <mat-label>Substatus List</mat-label>
                      <mat-select [formControl]="substatusSelected" multiple>
                        <mat-optgroup *ngFor="let status of substatusesList" [label]="status.title"
                          [disabled]="status.disabled">
                          <mat-option *ngFor="let substatus of status.value" [value]="substatus">{{substatus}}
                          </mat-option>
                        </mat-optgroup>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <!-- Categories -->
                  <div *ngIf="isHighRole">
                    <mat-form-field class="neomp-full-width">
                      <mat-label>Categories</mat-label>
                      <mat-select [(ngModel)]="lead_leadCategory" multiple>
                          <mat-option *ngFor="let category of unassignedLeadsCategories" [value]="category">{{category}}
                          </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <!-- Types -->
                  <div *ngIf="isHighRole">
                    <mat-form-field class="neomp-full-width">
                      <mat-label>Types</mat-label>
                      <mat-select [(ngModel)]="lead_leadType" multiple>
                          <mat-option *ngFor="let type of unassignedLeadsTypes" [value]="type">{{type}}
                          </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                          
                <!-- buttons -->
                <div class="search-fields-layout-grid margin-top-15">
                  <div class="neo-padding-top-10">
                    <button mat-raised-button color="primary" class="neomp-full-width button-text" (click)="search()">
                      <span class="material-icons">
                        search
                      </span>{{ 'PAGE.LEADS.LEAD.SEARCH' | translate }}
                    </button>
                  </div>
                  <div class="neo-padding-top-10">
                    <button mat-raised-button class="clear-button neomp-full-width button-text" (click)="clearSearch()">
                      <span class="material-icons">clear</span>{{ 'PAGE.LEADS.LEAD.CLEAR' | translate }}
                    </button>
                  </div>
                </div>
              </mat-card>
            </div>
            <div
              class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone neo-margin-top">
              <mat-card>
                <div
                  class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-9-tablet mdc-layout-grid__cell--span-12-phone">
                  <div>
                    <h1>{{ 'PAGE.LEADS.LEAD.MULTIASSIGNTITLE' | translate }}</h1>
                  </div>
                  <div class="mdc-layout-grid__inner">
                    <div *ngIf="isHighRole" class="mdc-layout-grid__cell--span-3">
                      <mat-form-field class="neomp-full-width">
                        <mat-label id="berater-label">{{ 'PAGE.LEADS.LEAD.ADVISOR' | translate }}</mat-label>
                        <mat-select [formControl]="agentAssignSelectedControl">
                          <mat-option *ngFor="let agent of agents" [value]="agent">
                            {{agent.fullName && agent.fullName.split("Neosana Sales AG")[1] ?
                            agent.fullName.split("Neosana Sales AG")[1] : agent.fullName }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="mdc-layout-grid__cell--span-2">
                      <div class="neo-padding-top-10">
                        <button mat-raised-button [disabled]="
                              selectedLeads.length === 0 
                              ||
                              agentAssignSelectedControl.invalid
                              ||
                              isLoading
                              " color="primary" (click)="assignLeadsToAgent()">
                          {{ 'DIALOG.BUTTON.SAVE' | translate }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card>
            </div>
            <br />
            <neomp-container cssClass="msg-mp-container-frameless" headlineText="{{
                      'MENU.LEADS-NAV.LEADS' | translate
                    }}" *ngIf="!isHighRole">
            </neomp-container>
            <mat-tab-group class="neo-border-none neo-label" [ngClass]="{'neo-bg-white' : !isHighRole}"
              [selectedIndex]="leadsTabGroupCurrentIndex" (selectedIndexChange)="leadsTabChanged($event)">
              <mat-tab>
                <ng-template mat-tab-label>
                  {{ 'PAGE.LEADS.LEAD.UNASSIGNED-LEADS' | translate }}
                  <span class="unassigned-leads-badge" *ngIf="leadService.unassignedLeadsCount | async as count "> 
                    {{ count }} 
                  </span>
                </ng-template>
                <div class="mdc-layout-grid msg-helper-padding-none msg-helper-margin-top-10">
                  <div class="mdc-layout-grid__inner">
                    <div
                      class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">

                      <neomp-leads-table [managementView]="true" [tableEntries]="unassignedLeads"
                        [leads]="unassignedLeads" (resetData)="resetFunction($event)"
                        (selectedRows)="selectedRows($event)">
                      </neomp-leads-table>
                      <neomp-paginator 
                      [length]="totalCountUnassignedLeads" 
                      [pageSize]="pageSize"
                      [pageIndex]="pageIndex" 
                      [pageSizeOptions]="pageSizeOptions"
                      (paginatorChange)="paginatorUnassignedLeadsValueChanged($event)">
                      </neomp-paginator>
                    </div>
                  </div>
                </div>
              </mat-tab>
              <mat-tab label="{{
                          'MENU.LEADS-NAV.LEADS' | translate 
                        }}">
                <div class="mdc-layout-grid msg-helper-padding-none msg-helper-margin-top-10">
                  <div class="mdc-layout-grid__inner">
                    <div
                      class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
                      <neomp-leads-table [managementView]="true" [tableEntries]="leads" [leads]="leads"
                        [mandat]="mandatesOnly" (resetData)="resetFunction($event)"
                        (selectedRows)="selectedRows($event)"></neomp-leads-table>
                      <neomp-loading-data #loadingFamilyTable></neomp-loading-data>
                      <neomp-paginator 
                      [length]="totalCountLeads" 
                      [pageSize]="pageSize" 
                      [pageIndex]="pageIndex"
                      [pageSizeOptions]="pageSizeOptions"
                      (paginatorChange)="paginatorAssignedLeadsValueChanged($event)">
                      </neomp-paginator>
                    </div>
                  </div>
                </div>
              </mat-tab>
              <mat-tab label="{{
                        'PAGE.LEADS.LEAD.RETURNED-LEADS' | translate 
                      }}">
                <div class="mdc-layout-grid msg-helper-padding-none msg-helper-margin-top-10">
                  <div class="mdc-layout-grid__inner">
                    <div
                      class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
                      <neomp-leads-table [managementView]="true" [tableEntries]="returnedLeads" [leads]="returnedLeads"
                        [mandat]="mandatesOnly" (resetData)="resetFunction($event)"
                        (selectedRows)="selectedRows($event)"></neomp-leads-table>
                      <neomp-loading-data #loadingFamilyTable></neomp-loading-data>
                      <neomp-paginator 
                      [length]="totalReturnedLeads" 
                      [pageSize]="pageSize" 
                      [pageIndex]="pageIndex"
                      [pageSizeOptions]="pageSizeOptions" 
                      (paginatorChange)="paginatorReturnedLeads($event)">
                      </neomp-paginator>
                    </div>
                  </div>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>