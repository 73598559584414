import {ReferenceJson} from "../json/reference.json";
import {SDAKey} from "./sdakey";

export class Reference {
    id: string;
    type: SDAKey = null;

    public static fromJsons(response: ReferenceJson[]): Reference[] | undefined {
        if (response) {
            return response.map(key => new Reference(key));
        }
    }

    public constructor(value?: ReferenceJson) {
        if (value) {
            this.id = value.id;
            this.type = new SDAKey(value.type) || null;
        }
    }
}
