import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { Person } from "../model/person";
import { RestProvider } from "./rest.provider";
import { IUploadMedia } from "app/modules/info/interfaces/info";
@Injectable({providedIn: 'root'})
export class AdvisorService {

    constructor(public rest: RestProvider) {  }

    getAdvisorData(): Promise<Person> {
        return this.rest.get<Person>(`${environment.settings.BASE_URL}/api/advisor/details`, null, true, null)
        .toPromise()
    }

    getAgentNumber(data?: Person): Promise<string> {
        return new Promise((resolve, reject) => {
            if (data) {
                resolve(data.groupeMutuelData.find(x => x.key === 'agentNumber').value);
            } else {
                this.getAdvisorData().then((data: Person) => {
                   resolve(data.groupeMutuelData.find(x => x.key === 'agentNumber').value);
                })
                .catch(err => {
                    reject(err);
                })        
            }
        })
    }
    updateAdvisorMedia(data : IUploadMedia) : Observable<any>{
        return this.rest.post(`${environment.settings.BASE_URL}/api/advisor/upload-image` , data);
    }

    orderVCard(email: string, quantity: string) {
        return this.rest.get(`${environment.settings.VISITCARD_URL}/orderCardsExternal?employee_email=${email}&card_quantity=${quantity}`, null, true, null)
    }
}
