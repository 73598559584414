<!-- <header class="msg-mp-header mdc-layout-grid msg-helper-padding-none"
        [@slideInOut]="{value:  disableAnimation ? null : maxHeaderWidth}"> -->
<header class="msg-mp-header mdc-layout-grid msg-helper-padding-none">

    <div class="mdc-layout-grid__inner
                msg-mp-header-wrapper">

        <!--  Toggle Navigation &  SearchBar -->
        <div class="mdc-layout-grid__cell
                    mdc-layout-grid__cell--span-3-desktop
                    mdc-layout-grid__cell--span-12-tablet
                    mdc-layout-grid__cell--span-12-phone
                    mdc-layout-grid__cell--align-middle
                    mdc-layout-grid--align-middle" style="display: flex;">

            <span class="msg-mp-header-button
                         mdi mdi-menu" (click)="toggleNavigation()" *ngIf="!sidenavElement.opened">
            </span>
            <span class="msg-mp-header-button
                         mdi mdi-chevron-left
                         msg-helper-margin-left-10" (click)="backNavigation()" *ngIf="!isMenuItem">
            </span>
            <neomp-search-box class="msg-mp-search-box {{sidenavElement.opened ? '':'msg-helper-margin-left-10'}}"
                (results)="onSearchResult($event)">
            </neomp-search-box>

        </div>
        <div class="mdc-layout-grid__cell
        mdc-layout-grid__cell--span-4-desktop
        mdc-layout-grid__cell--span-12-tablet
        mdc-layout-grid__cell--span-12-phone
        mdc-layout-grid__cell--align-middle"
        >
            <button mat-stroked-button color="primary" (click)="goToServiceDesk()" class="margin-right-10">{{ 'HEADER.SERVICEDESK' | translate }}</button>
            <button mat-stroked-button color="primary" (click)="goToDashboard()" class="margin-right-10">{{ 'HEADER.DASHBOARD' | translate }}</button>
            <button mat-stroked-button color="primary" (click)="goToKnowledgebase()">{{ 'HEADER.KNOWLEDGEBASE' | translate }}</button>
        </div>
        <div class="mdc-layout-grid__cell
        mdc-layout-grid__cell--span-5-desktop
        mdc-layout-grid__cell--span-12-tablet
        mdc-layout-grid__cell--span-12-phone
        mdc-layout-grid__cell--align-middle
        mdc-layout-grid--align-right
        s-right">
        <div class="mdc-layout-grid__inner
        msg-mp-header-wrapper">
        <!-- Agent Impersonating -->
        <div class="mdc-layout-grid__cell
                    mdc-layout-grid__cell--span-5-desktop
                    mdc-layout-grid__cell--span-5-tablet
                    mdc-layout-grid__cell--span-12-phone
                    mdc-layout-grid__cell--align-middle
                    s-right">
                <div class="neo-impersonate-wrapper">
                <div class="neo-impersonate-title" *ngIf="isHighRole">{{ 'HEADER.IMPERSONATE.TITLE' | translate }}:</div>
                <mat-form-field class="neo-impersonate-form-field" *ngIf="isHighRole">
                    <mat-label>{{ 'HEADER.IMPERSONATE.PLACEHOLDER' | translate }} </mat-label>
                    <mat-select>
                        <mat-option *ngFor="let agent of agents" [value]="agent.uuid" (click)="impersonate(agent)">
                            {{agent.fullName && agent.fullName.split("Neosana Sales AG")[1] ?
                            agent.fullName.split("Neosana Sales AG")[1] : agent.fullName }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <!-- UserName & Avatar -->
        <div class="msg-hide-s
                    mdc-layout-grid__cell
                    mdc-layout-grid__cell--span-4-desktop
                    mdc-layout-grid__cell--span-3-tablet     
                    mdc-layout-grid__cell--span-12-phone
                    mdc-layout-grid__cell--align-middle
                    mdc-layout-grid--align-right
                    alignItems
                    s-right">
            <button mat-button #tooltip="matTooltip" (click)="copyMessage(); tooltip.show(); " [matTooltip]="copyText"
                [matTooltipHideDelay]="matTooltipHideDelay" class="neo-copy-btn">
                <span class="material-icons neo-copy">
                    content_copy
                </span>
            </button>
            <span class=" msg-helper-margin-vertical-auto user-text">{{ 'HEADER.WELCOME' | translate }} </span>
            <span class="msg-helper-margin-vertical-auto user-avatar"> {{getUser()}}</span>
        </div>

        <!-- Ring -->
        <div class="mdc-layout-grid__cell
                    mdc-layout-grid__cell--span-2-desktop
                    mdc-layout-grid__cell--span-2-tablet
                    mdc-layout-grid__cell--span-12-phone
                    mdc-layout-grid__cell--align-middle
                    position-ring
                    s-right">
            <a class="msg-mp-header-button mdi mdi-bell-outline" [routerLink]="['/messages']">
                <span *ngIf="notificationCount > 0" class="notif-wrap">
                    <span class="notif">{{ notificationCount }}</span>
                </span>
            </a>
            <neomp-language-change></neomp-language-change>
        </div>

        <!-- Logout -->
        <div class="mdc-layout-grid__cell
                    mdc-layout-grid__cell--span-1-desktop
                    mdc-layout-grid__cell--span-2-tablet
                    mdc-layout-grid__cell--span-12-phone
                    mdc-layout-grid__cell--align-middle
                    mdc-layout-grid--align-right
                    s-right">
            <svg style="width:24px;height:24px" viewBox="0 0 24 24" (click)="logOff()"
                class="msg-mp-header-button mdi mdi-logout-variant msg-helper-margin-right-25">
                <path fill="currentColor"
                    d="M19,21V19H15V17H19V15L22,18L19,21M13,18C13,18.71 13.15,19.39 13.42,20H2V17C2,14.79 5.58,13 10,13C11,13 11.96,13.09 12.85,13.26C13.68,13.42 14.44,13.64 15.11,13.92C13.83,14.83 13,16.32 13,18M4,17V18H11C11,16.96 11.23,15.97 11.64,15.08L10,15C6.69,15 4,15.9 4,17M10,4A4,4 0 0,1 14,8A4,4 0 0,1 10,12A4,4 0 0,1 6,8A4,4 0 0,1 10,4M10,6A2,2 0 0,0 8,8A2,2 0 0,0 10,10A2,2 0 0,0 12,8A2,2 0 0,0 10,6Z" />
            </svg>
        </div>
        </div>
        </div>
    </div>
</header>