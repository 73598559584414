<table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort>


  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="operationIdExternal"> {{ 'PAGE.LEADS.REMINDER.ID' | translate}} </th>
    <td mat-cell *matCellDef="let element" >{{element.operationIdExternal || element.classification.id }} </td>
  </ng-container>

  <ng-container matColumnDef="task">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'PAGE.LEADS.REMINDER.TASK' | translate}} </th>
    <td mat-cell  *matCellDef="let element">
      <!-- {{element.task}} -->
      Call back
       </td>
  </ng-container>

  <ng-container matColumnDef="comment">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="descriptionField"> {{ 'PAGE.LEADS.REMINDER.COMMENT' | translate}} </th>
    <!-- Decreasing the size of the description to not show the whole description in the table; show '...' when the text is longer than 50 characters -->
    <td mat-cell *matCellDef="let element" > {{element.descriptionField | slice:0:50}} {{element.descriptionField.length> 50 ? '...' : ''}}</td>
  </ng-container>

  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="creationDate"> {{ 'PAGE.LEADS.REMINDER.DATE' | translate}} </th>
    <td mat-cell *matCellDef="let element" > {{ dateParse(element.creationDate) | date: 'dd.MM.yyyy' }} </td>
  </ng-container>

  <!-- "To be done" column -->
  <ng-container matColumnDef="toBeDone">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="creationDate"> {{ 'PAGE.LEADS.REMINDER.TO-BE-DONE-ON' | translate}} </th> 
    <td mat-cell *matCellDef="let element" > {{ dateParse(element.effectiveDate) | date: 'dd.MM.yyyy' }} </td>
  </ng-container>

  <ng-container matColumnDef="benutzer">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="sorKeys.agentName"> {{ 'PAGE.LEADS.REMINDER.USER' | translate}} </th>
    
    <!-- Remove the 'Neosana Sales AG ' from the agent name because is redundant. -->
    <td mat-cell *matCellDef="let element"> 
      {{element.sorKeys.agentName ? element.sorKeys.agentName.replace('Neosana Sales AG ','') : "" }} 
    </td>
  </ng-container>

  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef class="mat-header-cell mat-column-filter mat-column-filter-active">  <div
            class="neomp-table-column-filter-element  mdi mdi-close"></div></th>
    <td mat-cell *matCellDef="let element" class="mat-column-filter-actions" (click)="onDeleteClick($event, element)">
        <div class="neomp-table-column-filter-element mdi mdi-delete" ></div> 
    </td>
  </ng-container> 

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onRowClick(row)"></tr>
  

</table>