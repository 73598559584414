import { Person } from "app/modules/core/model/person";
import { GMOfferConstants } from "../constants/gm-offer-constants";
import { IPersonData } from "../interfaces/IPersonData";
import { SearchMaskService } from "../services/search-mask.service";
import { LeistungFilter } from "./LeistungFilter";


export class Persondata {
    jahrgang: number;
    franchise = -1;
    geschlecht = GMOfferConstants.GENDER_MALE;
    neoRecommendation = true;
    minTarif: string;
    maxTarif: string;
    leistungFilter: Array<LeistungFilter> = [];

    get unfalldeckung(): boolean {
        return this.jahrgang && SearchMaskService.getAltersklasseForYear(this.jahrgang) === 0 && SearchMaskService.getAccidentCoverageForYear(this.jahrgang);
    }

    constructor(person: Person, franchise: number, leistungFilter: LeistungFilter[]) {
        if (person) {
            this.geschlecht = person.gender ? this.getGender(person.gender) : '';
            this.franchise = franchise;
            this.jahrgang = new Date(person.birthdate).getFullYear();
            this.neoRecommendation = true;
            this.leistungFilter = leistungFilter;
        }
    }


    getGender(gender: string): string {
        if (gender.toLowerCase() === 'männlich') {
            return GMOfferConstants.GENDER_MALE;
        } else if (gender.toLowerCase() === 'weiblich') {
            return GMOfferConstants.GENDER_MALE;
        } else {
            return ''
        }
    }
}


