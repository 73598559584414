import {Component} from "@angular/core";

export const PREFIX = "--";

@Component({
    selector: "app-sass-helper",
    template: "<div></div>"
})
export class SassHelperComponent {
    public readProperty(name: string): string {
        const bodyStyles = window.getComputedStyle(document.body);
        return bodyStyles.getPropertyValue(PREFIX + name);
    }
}
