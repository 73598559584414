import {SDAKey} from "./sdakey";
import {RelevantPartnerJson} from "../json/relevant.partner.json";

export class RelevantPartner {
    partnerId: string;
    role: SDAKey = null;

    public static fromJsons(response: RelevantPartnerJson[]): RelevantPartner[] | undefined {
        if (response) {
            return response.map(key => new RelevantPartner(key));
        }
    }

    public constructor(value?: RelevantPartnerJson) {
        if (value) {
            this.partnerId = value.partnerId;
            this.role = new SDAKey(value.role) || null;
        }
    }
}
