import {Component} from "@angular/core";
import {RestProviderActions, ValidationCallback} from "../providers/rest.provider";
import {LoadingService} from "../providers/loading.component";

@Component({
    selector: 'neomp-loading-data',
    templateUrl: './loading-data.component.html',
    styleUrls: ['./loading-data.component.scss']
})
export class LoadingDataComponent implements RestProviderActions {

    showLoader = false;
    showError = false;
    showNodata = false;
    callback: ValidationCallback = null;

    constructor(public loadingService: LoadingService) {}

    onError(error: any): void {
        this.showError = true;
        this.showLoader = false;
        this.showNodata = false;
        this.loadingService.display(false);
    }

    onNoData(): void {
        this.showError = false;
        this.showLoader = false;
        this.showNodata = true;
        this.loadingService.display(false);
    }

    onStart(): void {
        this.showError = false;
        this.showLoader = true;
        this.showNodata = false;
        this.loadingService.display(true);
    }

    onSuccess(): void {
        this.showError = false;
        this.showLoader = false;
        this.showNodata = false;
        this.loadingService.display(false);
    }

    additionalValidation(data: any): boolean {
        return this.callback !== null ? this.callback(data): true;
    }

    applyValidationCallback(validationCallback: ValidationCallback): void {
        this.callback = validationCallback;
    }
}
