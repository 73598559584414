import { Component, EventEmitter, Input, OnInit, Output, AfterViewInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { FileUpload } from "../model/file-upload";
import { ConfigProvider } from "../providers/config.provider";
import Uppy from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import Dropbox from '@uppy/dropbox';
import OneDrive from "@uppy/onedrive";
import Webcam from "@uppy/webcam";
import XHRUpload from '@uppy/xhr-upload'
import { environment } from "environments/environment";

@Component({
  selector: "neomp-upload-file",
  templateUrl: "./upload-file.component.html",
  styleUrls: ["./upload-file.component.scss"],
})
export class UploadFileComponent implements OnInit, AfterViewInit {

  lastInvalids: File[] = [];
  maxSize: number;
  maxCount: number;

  fileUploads: FileUpload[] = [];
  files: File[] = [];
  dataSource: MatTableDataSource<File>;

  @Input()
  isNested = false;

  @Input()
  headlineText = "*N/A*";

  @Input()
  showHeadline = true;
  
  @Input() set allowedFileTypes (res: any) {
    this._allowedFileTypes = res;
    if (this.uppy) {
      this.uppy.setOptions({
        restrictions: {
          maxNumberOfFiles: this.maxCount,
          maxTotalFileSize: this.maxSize,
          allowedFileTypes: this._allowedFileTypes || ['.doc', '.docx', '.xls', '.xlsx', '.txt', '.png', '.jpg', '.jpeg', '.pdf']
        },
      })
      const allowedFileTypesExtentions = this._allowedFileTypes.map((x: string) => x.replace('.', ''));
      if (allowedFileTypesExtentions) {
        this.uppy.getFiles().forEach(file => {
          if (!allowedFileTypesExtentions.includes(file.extension)) {
            this.uppy.removeFile(file.id);
          }
        })
      }
    }
  };

  _allowedFileTypes = null;

  @Output()
  fileReadEmitter = new EventEmitter<FileUpload[]>();

  @Output()
  valid: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  @Input()
  shadow = true;

  uppy: Uppy;

  constructor(public configProvider: ConfigProvider) {
    this.dataSource = new MatTableDataSource(this.files);
  }

  ngOnInit(): void {

    this.configProvider
      .getConfigValue({ defaultTypeId: "uiConfig" })
      .subscribe((value) => {
        const tmpMaxSize =
          value.data != null
            ? value.data.find((value) => value.key === "uploadMaxFileSize")
            : null;
        if (
          tmpMaxSize !== null &&
          tmpMaxSize !== undefined &&
          !isNaN(+tmpMaxSize.value)
        ) {
          this.maxSize = +tmpMaxSize.value * 1024;

        } else {
          this.maxSize = 5 * 1024 * 1024;
        }

        const tmpMaxCount =
          value != null
            ? value.data.find((value) => value.key === "uploadMaxFileCount")
            : null;
        if (
          tmpMaxCount !== null &&
          tmpMaxCount !== undefined &&
          !isNaN(+tmpMaxCount.value)
        ) {
          this.maxCount = +value.data.find(
            (value) => value.key === "uploadMaxFileCount"
          ).value;
        } else {
          this.maxCount = 5;
        }
      });
  }

  ngAfterViewInit(): void {

    this.uppy = new Uppy(
      {
        restrictions: {

          maxNumberOfFiles: this.maxCount,
          maxTotalFileSize: this.maxSize,
          allowedFileTypes: this._allowedFileTypes || ['.doc', '.docx', '.xls', '.xlsx', '.txt', '.png', '.jpg', '.jpeg', '.pdf']

        },
      }
    )
      .use(Dashboard, {
        inline: true,
        target: '#drag-drop-area',
        proudlyDisplayPoweredByUppy: false,
        theme: 'light',
        hideUploadButton: true,
        width: "100%"
      })
      .use(Webcam, {
        target: Dashboard
      })
      .use(Dropbox, {
        target: Dashboard,
        companionUrl: `${environment.settings.COMPANION_URL}/`,
        companionCookiesRule: 'same-origin'
      })
      .use(OneDrive, {
        target: Dashboard,
        companionUrl: `${environment.settings.COMPANION_URL}/`
      })

      .use(XHRUpload, { endpoint: `${environment.settings.COMPANION_URL}/upload` });

    this.uppy.on('file-added', () => {
      this.files = this.uppy.getFiles() as any;
      this.valid.emit(this.files.length >= 1);
    })

    this.uppy.on('file-removed', () => {
      this.files = this.uppy.getFiles() as any;
      this.valid.emit(this.files.length >= 1);
    })

    //Multiple files added event
    this.uppy.on('files-added', () => {
      this.files = this.uppy.getFiles() as any;
        this.valid.emit(this.files.length >= 1);
    })
  }


  changeFiles() {
    if (this.files.length >= 1) {
      this.valid.emit(true);
    }
    while (this.maxCount != null && this.files.length > this.maxCount) {
      this.lastInvalids.push(this.files.pop());
    }
    this.dataSource.data = this.files;
  }

  readFiles() {
    this.uppy.upload()
      .then(res => {
        if (res.successful.length > 0) {
          for (let i = 0; i < res.successful.length; i++) {
            const data = res.successful[i];
            this.fileUploads.push(new FileUpload(data.meta.name, data.response.body.data as string, data.size));
          }
        }
        this.fileReadEmitter.next(this.fileUploads);
      });
  }
  
  hasUploadedFiles(): boolean {
    return this.uppy.getFiles().length > 0;
  }
}
