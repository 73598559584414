import {Component} from "@angular/core";
import {FormControl} from "@angular/forms";
import {Helper} from "../../static/helper";
import {FilterTableComponent} from "./filter-table-component";
import {log} from "../../providers/logger.provider";
import {PolicyJson} from "../../json/policy.json";
import {Policy} from "../../model/policy";
import {RegisterProvider} from "../../providers/register.provider";
import {Router} from "@angular/router";
import {HouseholdProvider} from "../../providers/household.provider";
import {RestProviderActionsDummy} from "../../providers/rest.provider";
import {ActionDialogService} from "../../providers/action-dialog.service";
import {ActionDialogData} from "../action.dialog.component";
import {BusinessTransactionHelper} from "../../static/bt-helper";
import { SlideRangeFilterValue } from "../../model/slide-range-filter-value";
import { MatDialog } from "@angular/material/dialog";

@Component({
    selector: "neomp-applications-table",
    templateUrl: "./applications-table.component.html",
    styleUrls: ["./applications-table.component.scss"]
})

export class ApplicationsTableComponent extends FilterTableComponent<Policy> {
    private readonly TAG = this.constructor.name;

    selectedApplicationsRowIndex;
    isDialogOpen = false;

    leadOrEigenterminConnectionFilter = new FormControl("");
    uuidFilter = new FormControl("");
    validFromFilter = new FormControl("");
    policyStatusFilter = new FormControl("");
    customerNameFilter = new FormControl("");
    birthdateFilter = new FormControl("");
    insurerFilter = new FormControl("");
    lobFilter = new FormControl("");
    cancellationStatusFilter = new FormControl("");

    filterValues: Policy = new Policy();

    displayedColumns = [
        "leadOrEigenterminConnection",
        "status",
        "uuid",
        "customerName",
        "policyStatus",
        "validFrom",
        "insurer",
        "lob",
        "customerBirthday",
        "cancellationStatus",
        "filter"
    ];

    displayedColumnFilters = [
        "leadOrEigenterminConnection",
        "status",
        "uuidFilter",
        "customerNameFilter",
        "policyStatusFilter",
        "validFromFilter",
        "insurerFilter",
        "lobFilter",
        "birthdateFilter",
        "cancellationStatusFilter",
        "filterRemove"
    ];
    formControlFilters: FormControl[] = [
        null,
        this.leadOrEigenterminConnectionFilter,
        this.uuidFilter,
        this.customerNameFilter,
        this.policyStatusFilter,
        this.validFromFilter,
        this.insurerFilter,
        this.lobFilter,
        this.birthdateFilter,
        this.cancellationStatusFilter,
        null
    ];

    constructor(public registerProvider: RegisterProvider,
                public households: HouseholdProvider,
                public router: Router,
                public dialogService: ActionDialogService,
                public dialog: MatDialog) {
        super();
    }

    validFromFilterChanged(event: SlideRangeFilterValue): void {
        this.validFromFilter.setValue(event.dateRange);
    }

    customerBirthdayFilterChanged(event: SlideRangeFilterValue): void {
        this.birthdateFilter.setValue(event.dateRange);
    }

    applicationsRowClick(element: Policy) {
        this.selectedApplicationsRowIndex = element.uuid;
        this.openRow(element);
    }

    resetFilter() {
        this.filterValues = new Policy();
        super.resetFilter();
        this.filterValues.cancellations.cancellationStatus="";
    }

    createFilter(): (data: Policy, filter: string) => boolean {
        return function (data, filter): boolean {

            const policyJson: PolicyJson = JSON.parse(filter);
            const searchTerms: Policy = Policy.fromJson(policyJson);
            const cancellationStatusFilter = searchTerms.cancellationStatus.toLowerCase() === 'nicht hochgeladen' ? '' : searchTerms.cancellationStatus;

            return (Helper.checkStringContains(data.uuid, searchTerms.uuid) &&
            Helper.checkStringContains(data.customerName, searchTerms.customerName) &&
                Helper.checkStringContains(data.policyStatus, searchTerms.policyStatus) &&
                Helper.checkDateRange(new Date(data.validFrom), Helper.stringToDatePickerFormat(searchTerms.validFrom)) &&
                Helper.checkStringContains(data.insurer, searchTerms.insurer) &&
                Helper.checkStringContains(data.lob, searchTerms.lob)) &&
                Helper.checkDateRange(new Date(data.customerBirthday), Helper.stringToDatePickerFormat(searchTerms.customerBirthday)) &&
                Helper.checkStringContains(data.cancellationStatus, cancellationStatusFilter)
            };
        }

    openRow(element: Policy) {
        if (element.partners && element.partners.length > 0) {
            const advisor = element.partners.find(p => p.type.sorValue === "ADVISOR");
            const partner = element.partners.find(p => p.type.sorValue === "CUSTOMER");
            if (advisor && partner) {
                this.households.getCompleteHouseholdByUuid(partner.id, new RestProviderActionsDummy())
                    .subscribe(completeHousehold => {
                        if (completeHousehold !== null) {
                            const baseUrl = `${this.registerProvider.getPath("MyCustomersDetail")}/${partner.id}`;
                            const url = `${baseUrl}/policy/${element.uuid}`;
                            this.router.navigate([url]);
                        } else {
                            log.error(`${this.TAG}: openRow -> policy=${element.uuid} was not found for first partner ${partner.id} in households`);
                        }
                    });
            } else {
                log.error(`${this.TAG}: openRow -> policy is missing either ADVISOR, CUSTOMER or both`);
            }
        }
        log.debug(`${this.TAG}: openRow -> policy=${element.uuid} has no partner (Type=CUSTOMER) set`);
    }

    openActionDialog(event: Event, element: Policy) {
        this.isDialogOpen = true;
        event.stopPropagation();

        const dialogData: ActionDialogData = {
            transTypeId: BusinessTransactionHelper.typeId.productBundle.from,
            transName: "Policy",
            transSubject: "Policy",
            transTitle: "Policy",
            transMessage: "Policy",
            keyValues: [],
            person: null,
            policy: element
        };
        this.dialogService.openByType(dialogData, 
            {
                defaultTypeId: BusinessTransactionHelper.typeId.productBundle.from
            }, this.TAG
        );
        this.dialog.afterAllClosed.subscribe(() => {
            this.isDialogOpen = false;
        });
    }

    onHandleClickForConnection($event, element) {
        if(element.sorKeys.leadOrEigenterminConnection === 'lead'){
            $event.stopPropagation(); 
            this.router.navigate([`/lead/${element.sorKeys.leadId}/edit`]);
        }
    }
}
