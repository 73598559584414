import { Person } from './../model/person';
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RestProvider, RestProviderActions} from "./rest.provider";
import {environment} from "../../../../environments/environment";
import {PersonJson} from "../json/person.json";
import { map } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class AppointmentProvider {
    private readonly CLASS_NAME = this.constructor.name;
    // private expandedHousholdId: string;

    constructor(public rest: RestProvider) {
    }

    public getLazyAppointments(
        restProviderActions: RestProviderActions,
        useCache = true,
        page: number,
        size: number,
        filter?: string): Observable<{data: PersonJson[], totalCount: number}> {
        let queryParams = `?page=${page}&size=${size}&${filter}`;
return this.rest.get<{data: PersonJson[], totalCount: number}>(`${environment.settings.BASE_URL}/api/appointment/getappointments${queryParams}`,
            null, useCache, restProviderActions)
            .pipe(
                map((res) => {
                return {
                    data: Person.fromJsons(res.data),
                    totalCount: res.totalCount
                };
            }));
    }

    public getUnassignedAppointments(
        restProviderActions: RestProviderActions,
        useCache = true,
        page: number,
        size: number,
        filter?: string): Observable<{data: PersonJson[], totalCount: number}> {
        let queryParams = `?page=${page}&size=${size}&${filter}`;
return this.rest.get<{data: PersonJson[], totalCount: number}>(`${environment.settings.BASE_URL}/api/appointment/getunassignedappointments${queryParams}`,
            null, useCache, restProviderActions)
            .pipe(map((res) => {
                return {
                    data: Person.fromJsons(res.data),
                    totalCount: res.totalCount
                };
            }));
    }
    public getReturnedAppointments(
        restProviderActions: RestProviderActions,
        useCache = true,
        page: number,
        size: number,
        filter?: string): Observable<{data: PersonJson[], totalCount: number}> {
        let queryParams = `?page=${page}&size=${size}&${filter}`;
        
        return this.rest.get<{data: PersonJson[], totalCount: number}>(`${environment.settings.BASE_URL}/api/appointment/getreturnedappointments${queryParams}`,
            null, useCache, restProviderActions)
            .pipe(map((res) => {
                return {
                    data: Person.fromJsons(res.data),
                    totalCount: res.totalCount
                };
        }));
    }

    public getAppointments(): Observable<{data: PersonJson[], totalCount: number}> {
return this.rest.get<{data: PersonJson[], totalCount: number}>(`${environment.settings.BASE_URL}/api/appointment/getappointments`,
            null, null)
            .pipe(
             map((res) => {
                return {
                    data: Person.fromJsons(res.data),
                    totalCount: res.totalCount
                };
            }));
    }

    public getAppointmentsForCalendar(): Observable<{data: PersonJson[], totalCount: number}> {
        return this.rest.get<{data: PersonJson[], totalCount: number}>(`${environment.settings.BASE_URL}/api/appointment/getappointments?forCalendar=true`,
                    null, null)
                    .pipe(
                     map((res) => {
                        return {
                            data: Person.fromJsons(res.data),
                            totalCount: res.totalCount
                        };
                    }));
            }

    public getUnassignedAppointmentsForCalendar(): Observable<{ data: PersonJson[], totalCount: number }> {
        return this.rest.get<{ data: PersonJson[], totalCount: number }>(`${environment.settings.BASE_URL}/api/appointment/getunassignedappointments`,
            null, null)
            .pipe(map((res) => {
                return {
                    data: Person.fromJsons(res.data),
                    totalCount: res.totalCount
                };
            }));
    }

    public getAppointmentById(id: string, restProviderActions: RestProviderActions): Observable<Person> {
        const actionUrl = `${environment.settings.BASE_URL}/api/appointment/${id}`;
        return  this.rest.get<Person>(actionUrl, null, true, restProviderActions);
    }

    public getAgents(useCache = true): Observable<Person[]> {
        return  this.rest.get<Person[]>(`${environment.settings.BASE_URL}/api/advisor/agentur-related-advisors`,
        null, useCache)
        .pipe(
         map((res) => {
            return Person.fromJsons(res);
        }));
      }

      public getUnblockedAgents(useCache = true): Observable<Person[]> {
        return  this.rest.get<Person[]>(`${environment.settings.BASE_URL}/api/advisor/agentur-related-advisors?unblockedOnly=true`,
        null, useCache)
        .pipe(
         map((res) => {
            return Person.fromJsons(res);
        }));
      }
      

    public getAttributeValues(): Observable<{ terminCategories: string[], terminTypes: string[]}> {
        const actionUrl = `${environment.settings.BASE_URL}/api/appointment/getattributevalues`;
        return this.rest.get<{ data: { terminCategories: string[], terminTypes: string[] }}>(actionUrl, null, false).pipe(map((res) => {
            return {
                terminCategories: res.data.terminCategories,
                terminTypes: res.data.terminTypes
            };
        }))
      }

}
