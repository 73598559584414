import {
  Component,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  EventEmitter,
} from "@angular/core";
import { LeadProvider } from "../../../../core/providers/lead.provider";
import { ActivatedRoute, Router } from "@angular/router";
import { RegisterProvider } from "../../../../core/providers/register.provider";
import { FilterTableComponent } from "../../../../core/components/tables/filter-table-component";
import { FormControl } from "@angular/forms";
import { Person } from "../../../../core/model/person";
import { CustomersCacheProvider } from "../../../../core/providers/customers-cache.provider";
import { Helper } from "app/modules/core/static/helper";
import { CustomersCache } from "app/modules/core/model/customers-cache";
import { KeycloakService } from "keycloak-angular";
import { BusinessTransactionHelper } from "app/modules/core/static/bt-helper";
import { LeadsService } from "app/modules/leads/services/leads.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import moment from "moment";
import { UserCommitState } from "app/modules/core/model/user-commit-state";
import { ActionDialogService } from "app/modules/core/providers/action-dialog.service";
import { AgenturType } from "app/modules/core/model/enum-agenturtype";
import { PostalAddress } from "app/modules/core/model/postal-address";
import { LeadLog } from "app/modules/core/model/LeadLog.enum";
import { SelectionModel } from "@angular/cdk/collections";
import { MatDialog } from "@angular/material/dialog";
import { AdvisorDataService } from "../../../../core/services/advisor-data.service";

@Component({
  selector: 'neomp-mortgage-table',
  templateUrl: './mortgage-table.component.html',
  styleUrls: ['./mortgage-table.component.scss']
})
export class MortgageTableComponent extends FilterTableComponent<Person>
  implements OnInit, OnChanges {

  private readonly TAG = this.constructor.name;
  @Input()
  leads: Person[] = [];
  @Input()
  managementView: boolean = false;
  agents: Person[] = [];
  isHighRole: boolean = true;
  agenturType: string = "";
  advisorId: string = "";
  agenturName: string = "";
  advisorName: string = "";
  selection = new SelectionModel<Person>(true, []);
  agenturId: string = "";
  @Output()
  selectedRows: EventEmitter<SelectionModel<Person>> = new EventEmitter<SelectionModel<Person>>();


  @Output()
  resetData: EventEmitter<string> = new EventEmitter();
  leadSelectFilter = new FormControl("");
  leadNameFilter = new FormControl("");
  leadAddressFilter = new FormControl("");
  leadStatusFilter = new FormControl("");
  leadSubstatusFilter = new FormControl("");
  leadDateFilter = new FormControl("");
  leadCategoryFilter = new FormControl("");
  leadAdvisorFilter = new FormControl("");
  filterValues: Person = new Person();

  formControlFilters: FormControl[] = [
    this.leadNameFilter,
    this.leadAddressFilter,
    this.leadStatusFilter,
    this.leadSubstatusFilter,
    this.leadDateFilter,
    this.leadCategoryFilter,
    this.leadAdvisorFilter,
  ];

  displayedColumns = [
    "name",
    "city",
    "statusLead",
    "substatusLead",
    "date",
    "category",
    "advisor",
    "filter"
  ];

  displayedColumnFilters = [
    "nameFilter",
    "addressFilter",
    "statusLeadFilter",
    "substatusLeadFilter",
    "dateFilter",
    "categoryFilter",
    "advisorFilter", // Add this line
    "filterRemove"
  ];

  constructor(
    public leadProvider: LeadProvider,
    public router: Router,
    public registerProvider: RegisterProvider,
    public activatedRoute: ActivatedRoute,
    public customersCacheProvider: CustomersCacheProvider,
    public snackBar: MatSnackBar,
    private keycloak: KeycloakService,
    private leadsService: LeadsService,
    private dialogService: ActionDialogService,
    private advisorDataService: AdvisorDataService,
    public dialog: MatDialog
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {

    this.selection.changed.subscribe(
      () => this.selectedRows.emit(this.selection)
    );

    await this.keycloak.loadUserProfile().then((res: any) => {
      this.advisorName = res.firstName;
      this.agenturType = res.attributes.agenturType[0];
      this.agenturName = res.attributes.agenturName[0];
      this.agenturId = res.attributes.agenturID[0];
      this.advisorId = res.attributes.advisorid[0];
      this.isHighRole = (this.agenturType === AgenturType.SalesManager || this.agenturType === AgenturType.GeneralAgent || this.agenturType === AgenturType.TeamLead)
      if (!this.isHighRole) {
        this.displayedColumnFilters = this.displayedColumnFilters.filter(x => x != "advisorFilter");
        this.displayedColumns = this.displayedColumns.filter(x => x != "advisor");
      }
    });
    this.agents = await this.getAgents();
    if (this.agents.length > 0) {
      this.dataSource.data = this.leads.map(lead => this.assignAgentsToLeads(lead));
    }
    else {
      this.dataSource.data = this.leads;
    }

    this.showFilter = false;
    const customersCache: CustomersCache =
      this.customersCacheProvider.getCache();
    if (customersCache != null) {
      this.leadNameFilter.setValue(customersCache.name);
      this.leadAddressFilter.setValue(customersCache.address);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('managementView' in changes) {
      if (changes.managementView.currentValue) {
        this.displayedColumns.unshift('select');
        this.displayedColumnFilters.unshift('leadSelectFilter');
        this.formControlFilters.unshift(this.leadSelectFilter);

      }
    }
    if ("leads" in changes) {
      this.dataSource.data = this.leads;
      this.selection.clear();
    }

  }


  resetFilter() {
    this.filterValues = new Person();
    super.resetFilter();
  }

  createFilter(): (data: Person, filter: string) => boolean {
    return function (innerData, innerFilter): boolean {
      const searchTerms = JSON.parse(innerFilter);
      if (typeof (searchTerms.address) === 'string') {
        searchTerms.address = new PostalAddress({
          postalCode: searchTerms.address,
          city: searchTerms.address
        })
      }

      if (searchTerms.date == undefined) {
        searchTerms.date = "";
      } else {
        searchTerms.date = moment(new Date(searchTerms.date)).format('YYYY-MM-DD');
      }



      return (
        (searchTerms.name !== null
          ? Helper.checkStringContains(
            innerData.fullName,
            searchTerms.name)
          : true) &&
        ((searchTerms.address !== null
          ? Helper.checkStringContains(
            innerData.address.postalCode,
            searchTerms.address.postalCode
          )
          : true) ||
          (searchTerms.address !== null
            ? Helper.checkStringContains(
              innerData.address.city,
              searchTerms.address.city
            )
            : true)) &&
        (searchTerms.art !== null && innerData.lead !== null
          ? Helper.checkStringContains(
            innerData.lead.lead_typ,
            searchTerms.art
          )
          : true) &&
        (searchTerms.statusLead !== null && innerData.lead !== null
          ? Helper.checkStringContains(
            innerData.lead.lead_status,
            searchTerms.statusLead
          )
          : true) &&
        (searchTerms.substatusLead !== null && innerData.lead !== null
          ? Helper.checkStringContains(
            innerData.lead.lead_substatus,
            searchTerms.substatusLead
          )
          : true) &&
        (searchTerms.date !== null && innerData.lead !== null
          ? Helper.checkStringContains(
            innerData.lead.lead_creadate,
            searchTerms.date
          )
          : true) &&
        (searchTerms.category !== null && innerData.lead !== null
          ? Helper.checkStringContains(
            innerData.lead.lead_kategory,
            searchTerms.category
          )
          : true) &&
        (searchTerms.advisor !== null && innerData.lead !== null
          ? Helper.checkStringContains(
            innerData.sorKeys.agent ? innerData.sorKeys.agent.split("Neosana Sales AG")[1] : '',
            searchTerms.advisor
          )
          : true)
      );
    };
  }

  getClassFromStatus(leadUuid: string, servicecenter: boolean) {
    if (servicecenter) {
      return "servicecenter";
    }

    if (leadUuid.startsWith('myneolead-')) {
      return "warning-status"
    }
  }

  assignAgentsToLeads(lead: Person) {
    const leadAgent = this.agents.find(x => x.uuid == lead.lead.termin_berater_shortcut);

    const agentName = leadAgent ? leadAgent.fullName : this.agenturName;
    if (agentName) {
      lead.sorKeys["agent"] = agentName;
      return lead;
    }
    return null;
  }

  getAgents(): Promise<Person[]> {
    if (!this.managementView) {
      return this.advisorDataService.getAgents();
    } else {
      return this.advisorDataService.getUnblockedAgents();
    }
  }

  agentsControlOnClick(event: Event) {
    event.stopPropagation();
  }

  assignAgentToLead(agent: Person, lead: Person) {
    const isAgenturSelected = agent.uuid === lead.lead.lead_agency;
    const sorKeys = {
      'advisorId': agent.uuid,
      'leadId': lead.uuid
    }

    lead.lead.termin_editdate = moment().format('YYYY-MM-DD');
    lead.lead.termin_berater_shortcut = agent.uuid;
    if (isAgenturSelected) {
      lead.lead.leadpool = true;
    }

    const actionData = BusinessTransactionHelper.createLeadBTActionObject(
      LeadLog.TRANSFER,
      BusinessTransactionHelper.typeId.assignLeadToAgent.from,
      '',
      'Lead TRANSFER => ' + agent.fullName,
      '',
      agent.uuid,
      [],
      lead,
      moment().add(5, 'days').format('YYYY-MM-DD'),
      sorKeys,
      this.advisorName
    );

    this.leadsService.sendLeadAction(actionData)
      .subscribe(() => {
        this.snackBar.open("Lead updated successfully", "X", UserCommitState.configSuccess);
        this.resetData.emit('true');
      }, err => {
        this.snackBar.open("Something went wrong!", "X", UserCommitState.configError)
      })
  }

  getSelectedAgent(uuid: string): Person {
    return this.agents.find(x => x.uuid === uuid);
  }

  redirectToEdit(uuid: string, termin: string, servicecenter: boolean): void {
    if (!["", "0", "-1"].includes(termin) && termin != this.agenturId && !uuid.startsWith('myneolead-') && !servicecenter) {
      this.router.navigate([`/lead/${uuid}/edit`]);
    }
  }
  dateParse(date: string): Date | string {
    return date ?
      new Date(date) : '';
  }

}
