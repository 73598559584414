import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";

@Component({
    selector: "square-icon",
    templateUrl: "./square-icon.component.html",
    styleUrls: ["./square-icon.component.scss"]
})
export class SquareIconComponent implements OnInit {
    @Input()
    public tinyRepresentation = false;
    @Input()
    public color: string;
    @Input()
    public headline: string;
    @Input()
    public textContent: string;
    @Input()
    public link: string;
    @Input()
    public linkText: string;
    @Input()
    public showLink = false;
    @Input()
    public elementAsLink = false;
    @Input()
    public centerHeader = false;
    @Input()
    public iconHeadline: string;
    @Input()
    public icon: string;
    @Output()
    public linkEmitter = new EventEmitter<string>(true);

    ngOnInit(): void {
    }

    openLink(event: Event) {
        if (this.elementAsLink === true) {
            event.stopPropagation();
        }
        if (this.elementAsLink && this.link && this.link.length > 0) {
            if (this.link.startsWith("http") || this.link.startsWith("www")) {
                window.open(this.link, "_black");
            } else {
                this.linkEmitter.emit(this.link);
            }
        }
    }

    getColor() {
        if (this.color && this.color.length > 0) {
            return this.color;
        }
        return "--color-primary";
    }
}
