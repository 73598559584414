<div class="neo-content-wrapper">
    <div class="mdc-layout-grid msg-helper-padding-none">
        <div class="mdc-layout-grid__inner msg-helper-grid-gap-horizontal">
            <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop
                            mdc-layout-grid__cell--span-6-tablet
                            mdc-layout-grid__cell--span-6-phone
                            mdc-layout-grid--align-right">
                <neomp-slide-range-filter
                    [disabled]="isDataLoading"
                    [filterValue]="filterValue"
                    (filterChangeEmitter)="getElements($event)">
                </neomp-slide-range-filter>
            </div>
            <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop
                            mdc-layout-grid__cell--span-6-tablet
                            mdc-layout-grid__cell--span-6-phone
                            mdc-layout-grid--align-right">
            </div>
            <div class="mdc-layout-grid__cell
                        mdc-layout-grid__cell--span-6-desktop
                         mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone">
                <neomp-container cssClass="msg-mp-container-frameless"
                                 headlineText="{{ 'PAGE.CONTAINER.OFFERS.CONTAINER.REQUEST' | translate }}">

                    <neomp-created-requests-table [tableEntries]="applications"
                                                  (rowClickEmitter)="journalRowClick($event)">
                    </neomp-created-requests-table>
                    <neomp-loading-data #applicationsTable></neomp-loading-data> 
                  <neomp-paginator
                  [length]="totalCount" 
                  [pageSize]="pageSize" 
                  [pageIndex]="pageIndex"
                  [pageSizeOptions]="pageSizeOptions"  
                  (paginatorChange)="paginatorValueChanged($event)"
                  >  
                  </neomp-paginator>  
                </neomp-container>
            </div>
            <div class="mdc-layout-grid__cell
                        mdc-layout-grid__cell--span-6-desktop
                         mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone">
                <neomp-container cssClass="msg-mp-container-frameless"
                                 headlineText="{{headline}}">
                    <table 
                    matSort
                    matSortActive="effectiveDate"
                    matSortDirection="desc"
                    [dataSource]="specialJournalDataSource" class="msg-helper-width-100" mat-table>
                        <ng-container matColumnDef="descriptionField">
                            <th *matHeaderCellDef mat-header-cell>
                                {{ 'PAGE.OFFER.QUOTATIONS.COMPANY' | translate }}
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.descriptionField}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="offer">
                            <th *matHeaderCellDef mat-header-cell>
                                {{ 'PAGE.OFFER.QUOTATIONS.OFFER' | translate }}
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <div *ngIf="existsDocument(element)" class="mdi mdi-file msg-helper-icon-font-size"
                                     (click)="downloadDocument($event, element)"></div>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="completed">
                            <th *matHeaderCellDef mat-header-cell>
                                {{ 'PAGE.OFFER.QUOTATIONS.STATUS' | translate }}
                            </th>
                            <td mat-cell
                                *matCellDef="let element"> {{(element.status.completed === true ? 'PAGE.APPLICATIONS.TABLE.STATUS.DONE' : 'PAGE.APPLICATIONS.TABLE.STATUS.UNDONE') | translate}}</td>
                        </ng-container>
                        <ng-container matColumnDef="effectiveDate">
                            <th *matHeaderCellDef mat-header-cell>
                                {{ 'PAGE.OFFER.QUOTATIONS.DATE' | translate }}
                            </th>
                            <td mat-cell
                                *matCellDef="let element">{{element.effectiveDate | date: 'dd.MM.yyyy'}}</td>
                        </ng-container>
                        <ng-container matColumnDef="reasonForChange">
                            <th *matHeaderCellDef mat-header-cell>
                                {{ 'PAGE.OFFER.QUOTATIONS.PREMIUM' | translate }}
                            </th>
                            <td mat-cell
                                *matCellDef="let element">{{element.reasonForChange}}</td>
                        </ng-container>
                        <ng-container matColumnDef="send">
                            <th *matHeaderCellDef mat-header-cell></th>
                            <td mat-cell *matCellDef="let element;"
                                class="mat-cell cdk-column-send mat-column-send {{isSendData(element) ? 'mat-column-send-active':''}} ng-star-inserted">
                                <div class="neomp-table-column-filter-element mdi mdi-check"></div>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="filter">
                            <th *matHeaderCellDef mat-header-cell></th>
                            <td mat-cell *matCellDef="let element"  class="mat-column-filter-action">
                                <div class="neomp-table-column-filter-element mdi mdi-plus" [disabled]="isDialogOpen"
                                (click)="isDialogOpen ? null : openActionDialog($event, element)"></div>
                            </td>
                        </ng-container>
                        <tr *matHeaderRowDef="['descriptionField', 'offer', 'completed', 'effectiveDate', 'reasonForChange', 'send', 'filter'];"
                            mat-header-row></tr>
                        <tr *matRowDef="let journalRow; columns: ['descriptionField', 'offer', 'completed', 'effectiveDate', 'reasonForChange', 'send', 'filter'];"
                            (click)="changeCheckbox(journalRow)"
                            class="neomp-table-row-selected container-table-row" mat-row></tr>
                    </table>
                    <div
                        *ngIf="selectedJournalRowIndex != null && specialJournalDataSource.data != null && specialJournalDataSource.data.length < 1"
                        class="msg-loadingstatus msg-nodata">
                        <div class="mdi mdi-cancel">
                            <span>{{ 'COMMONS.LOADINGSTATUS.NODATA' | translate }}</span>
                        </div>
                    </div>
                    <div *ngIf="selectedJournalRowIndex == null"
                         class="msg-loadingstatus msg-nodata">
                        <div class="mdi mdi-cancel">
                            <span>{{ 'PAGE.OFFER.QUOTATIONS.SELECT' | translate }}</span>
                        </div>
                    </div>
                </neomp-container>
            </div>
            <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop
                            mdc-layout-grid__cell--span-12-tablet
                            mdc-layout-grid__cell--span-12-phone mdc-layout-grid--align-right msg-btn-row"
                            [style.visibility]="selectedJournalRowIndex != null && specialJournalDataSource.data != null && specialJournalDataSource.data.length > 0 ? 'visible' : 'hidden'">
                <button color="primary" mat-raised-button disabled
                        (click)="sendOffers()">{{ 'PAGE.OFFER.SEND' | translate }}</button>
            </div>
        </div>
    </div>
</div>
<br/>

<div class="mdc-layout-grid__cell
    mdc-layout-grid__cell--span-6-desktop
    mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone">
    <neomp-container cssClass="msg-mp-container-frameless"
        headlineText="{{ 'PAGE.CUSTOMERDETAIL.JOURNAL.TITLE' | translate }}">

        <neomp-requests-journal-table [tableEntries]="journalData">
        </neomp-requests-journal-table>
        <neomp-loading-data #journalTable></neomp-loading-data> 
        <neomp-paginator
        [length]="journalTotalCount" 
        [pageSize]="pageSizeJournal" 
        [pageIndex]="pageIndexJournal"
        [pageSizeOptions]="pageSizeOptionsJournal"  
        (paginatorChange)="paginatorValueChangedJournal($event)"
        >  
        </neomp-paginator>  
    </neomp-container>
</div>
