import {Person} from "./person";
import {Journal} from "./journal";
import {FileUpload} from "./file-upload";
import {Policy} from "./policy";
import { Driver } from "./driver";
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
    useFactory: () => new ActionData(),
  })
export class ActionData {
    transaction: Journal = null;
    person: Person = null;
    policy: Policy = null;
    documents: FileUpload[] = [];
    policyHolder?: Driver = null;
    completed?: boolean = false;

    constructor(transaction?: Journal, documents?: FileUpload[], person?: Person, policy?: Policy, driver?: Driver, completed?: boolean) {
        this.transaction = transaction;
        this.person = person;
        this.policy = policy;
        this.documents = documents;
        this.policyHolder = driver;
        this.completed = completed;
    }
}
