import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { GMOfferConstants } from '../constants/gm-offer-constants';
import { NeoRegion } from '../models/NeoRegion';
import { StandardBelegungJson } from '../models/StandardBelegung';
import { HttpClient } from '@angular/common/http';
import { SearchParams } from '../models/SearchParams';
import { ITarifResponse, Product } from '../interfaces/ITarifResponse';
import { forkJoin, Observable } from 'rxjs';
import { PolicyProvider } from 'app/modules/core/providers/policy.provider';
import { Policy } from 'app/modules/core/model/policy';
import { RestProviderActionsDummy } from 'app/modules/core/providers/rest.provider';
import { GMOfferData } from '../models/GMOfferData';
import { GMRiskObject } from '../models/GMRiskObject';
import { Person } from 'app/modules/core/model/person';
import { SearchMaskService } from './search-mask.service';
import { GMAddress } from '../models/GMAddress';
import { GMProduct } from '../models/GMProduct';
import { DatePipe } from '@angular/common';
import { BusinessTransactionHelper } from 'app/modules/core/static/bt-helper';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActionDataProvider } from 'app/modules/core/providers/action-data.provider';
import { UserCommitState } from 'app/modules/core/model/user-commit-state';
import { TranslateService } from '@ngx-translate/core';
import { FileUpload } from 'app/modules/core/model/file-upload';
import { switchMap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class GmofferService {

  public regions: NeoRegion[] = [];
  public franchiseData: { [altersklasse: number]: Array<Number>; } = {};
  public standardBelegungData: { [alter: number]: StandardBelegungJson; } = {};
  //  public tariffKinds: { [kind: string]: TarifartDescription } = {};
  //  public franchiseStatistikData: { [alter: number]: FranchiseStatistik; } = [];
  public policies: Policy[] = [];

  constructor(
    private http: HttpClient,
    private policyProvider: PolicyProvider,
    private datepipe: DatePipe,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    public actionDataProvider: ActionDataProvider
  ) {  }

  loadData(): Promise<any> {
    return new Promise((resolve, reject) => {
      Promise.all([this.loadConfigData(), this.loadRegions()])
        .then((values) => {
          resolve(values);
          // all loading done
        }, err => {
          reject(err);
          // console.log(err);
        });
    })
  }

  loadConfigData(): Promise<any> {
    return new Promise((resolve, reject) => {
      const actionUrl = `${environment.settings.VGR_URL}/${GMOfferConstants.URL_PART_CONFIGS}`;
      this.http.get<any>(actionUrl)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          err => { // Error
            reject(err);
          }
        );
    });
  }

  loadRegions(): Promise<any> {
    this.regions = [];
    return new Promise((resolve, reject) => {
      const actionUrl = `${environment.settings.VGR_URL}/${GMOfferConstants.URL_PART_REGIONS}`;
      this.http.get<any>(actionUrl).toPromise()
        .then(res => { // Success
          let counter = 0;
          res.forEach(v => {
            const region = NeoRegion.fromRegion(v);
            region.key = counter.toString();
            this.regions.push(region);
            counter++;
          });
          resolve(this.regions);
        },
          err => { // Error
            reject(err);
          }
        );
    });
  }

  findTarife(data: SearchParams): Observable<ITarifResponse[]> {
    // this.getPolicies('299526');
    return this.http.post<ITarifResponse[]>(`${environment.settings.GM_OFFER_URL}/offer/getvgrproducts`, data);
  }

  sendGMOffer(vgrProducts: GMOfferData, gmProducts: any): Observable<ITarifResponse[]> {
    return this.http.post<any>(`${environment.settings.GM_OFFER_URL}/offer/`, {vgrProducts, gmProducts});
  }

  getPolicies(uuid: string): Observable<any> {
    return this.policyProvider.getPolicyByPartnerId(uuid, new RestProviderActionsDummy());
  }


  prepareRiskObject(person: Person, product: Product, selectedRegion: NeoRegion): GMRiskObject {
    const franchiseType = SearchMaskService
      .getAltersklasseForYear(new Date(person.birthdate)
        .getFullYear());
    // S3t:
    // ...should be franchise of individual person, as per respresentant this should be used?
    // "k" + this.selectedFranchise : "a" + this.selectedFranchise;
    // This is not based on dropdown, this is made based on requested defaults kids:0 and adults:2500 :
    const franchiseTypeFull = franchiseType === 0 ? "k0" : "a2500";

    const gmAddress = new GMAddress(
      "CH",
      selectedRegion.Bfs,
      selectedRegion.PLZ.toString(),
      selectedRegion.Gemeinde
    );

    // let gmCollective = new GMCollective(
    //   "EMPL",
    //   "0",
    //   "00"
    // );

    let gmProduct = [new GMProduct(
      product.noAccidentCoverageCode,
      product[franchiseTypeFull],
      "",
      "",
      "",
      product.shortName,
      "",
      product.productCode
    )];

    let gmRiskObject = new GMRiskObject(
      gmAddress,
      this.datepipe.transform(person.birthdate, 'yyyy-MM-dd'),
      // gmCollective,
      person.firstName,
      person.gender,
      person.name,
      gmProduct
    );

    return gmRiskObject;
  }

  createActionData(selectedPerson: Person, uploadFiles: FileUpload[], offerId: string): void {
    const businessTransaction = BusinessTransactionHelper.createOffer(
      null,
      selectedPerson,
      BusinessTransactionHelper.typeId.offerCreate.from,
      'Groupe Mutuel',
      null
    );
    businessTransaction.transaction.sorKeys['GroupeMutuel'] = offerId;
    businessTransaction.transaction.sorKeys['GroupeMutuelCustomer'] = selectedPerson.fullName;
    this.actionDataProvider
      .sendActionData(
        businessTransaction
      )
      .toPromise()
      .then((value) => {
        this.translateService
          .get("COMMONS.USERCOMMIT.SUCCESS")
          .subscribe((result) => {
            this.snackBar.open(result, "x", UserCommitState.configSuccess);
          });
      })
      .catch((reason) => {
        this.translateService
          .get("COMMONS.USERCOMMIT.ERROR")
          .subscribe((result) => {
            this.snackBar.open(result, "x", UserCommitState.configError);
          });
      });
  }

  getGmProductsAndVariants(people: Person[]): Observable<any> {
    const requests = [];
    const matchRequests = [];
    for (let person of people) {
      requests.push(this.getPolicies(person.uuid));

    };
    return forkJoin(requests).pipe(
      switchMap((res: any) => {
        let i = 0;
        while (i < res.length) {

          const body = {
            personAge: new Date().getFullYear() - new Date(people[i].birthdate).getFullYear(),
            productBundles: res[i].data
          };
          matchRequests.push(this.http.post(`${environment.settings.GM_OFFER_URL}/gm/match`, body));

          i++;
        }
        return forkJoin(matchRequests);
      }));
  }

  getGMProductsForList(person: Person): Observable<any> {
    return this.http.post(`${environment.settings.GM_OFFER_URL}/gm/products`, {
      age: new Date().getFullYear() - new Date(person.birthdate).getFullYear()
    });
  }

  filterProducts(products: any, productsForm: any): Observable<any> {
    return this.http.post(`${environment.settings.GM_OFFER_URL}/gm/filter`, {products, productsForm} );
  }

}
