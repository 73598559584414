<div class="mdc-layout-grid msg-helper-padding-none">
  <div class="mdc-layout-grid__inner msg-helper-grid-gap-horizontal">
    <div
      class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone"
    >
    <div class="neo-name-button-position">
      <div class="neo-checkbutton-position">
        <h3 class="neo-leads-name">
          {{ lead?.fullName }}
        </h3>
        <!-- <mat-checkbox class="example-margin" [disabled]="true">Hat termin</mat-checkbox> -->
      </div>
    </div>
      <div
        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-9-tablet mdc-layout-grid__cell--span-12-phone mdc-layout-grid--align-left"
      >
        <!-- <mat-tab-group class="neo-border-none"> -->
          <!-- <mat-tab label="{{ 'MENU.ENTRIES.LEADS' | translate }}">
            <div
              class="mdc-layout-grid msg-helper-padding-none msg-helper-margin-top-10"
            >
              <div class="mdc-layout-grid__inner">
                <div
                  class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone"
                >
                  <neomp-leads-edit-details
                  [configSettings]="configSettings"
                  [lead]="lead"
                  ></neomp-leads-edit-details>
                </div>
              </div>
            </div>
          </mat-tab> -->
          <!-- <mat-tab class="test" label="Leadblatt"> -->
            <div
              class="mdc-layout-grid msg-helper-padding-none msg-helper-margin-top-10 "
            >
              <div class="mdc-layout-grid__inner">
                <div
                  class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone"
                ><neomp-leads-edit-termin
                [leadView]="true"
                (emitNewLog)="addNewLog($event)" 
                #leadsEditTerminCp></neomp-leads-edit-termin></div>
              </div>
            </div>
            <!-- <div
            class="mdc-layout-grid msg-helper-padding-none msg-helper-margin-top-10"
          >
            <div class="mdc-layout-grid__inner">
              <div
                class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone"
              >
              <neomp-container cssClass="msg-mp-container-frameless"
              headlineText="{{ 'PAGE.CUSTOMERDETAIL.JOURNAL.TITLE' | translate }}">
              <neomp-leads-log 
              [tableEntries]="logs"
              ></neomp-leads-log>
              <neomp-loading-data #loadingLogsTable></neomp-loading-data>
              <neomp-paginator
              [length]="totalCount" 
              [pageSize]="pageSize" 
              [pageIndex]="pageIndex"
              [pageSizeOptions]="pageSizeOptions" 
              (paginatorChange)="paginatorValueChanged($event)"
              >  
              </neomp-paginator>  
              </neomp-container>
            </div>
            </div>
          </div> -->
          <!-- </mat-tab>
          <mat-tab label="Log">
 
          </mat-tab>
        </mat-tab-group> -->
      </div>
    </div>
  </div>
</div>
