<div class="neo-content-wrapper">
    <div class="mdc-layout-grid">
        <div class="mdc-layout-grid__inner">
            <div class="mdc-layout-grid__cell
                        mdc-layout-grid__cell--span-12-desktop
                         mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone">
                <h2 translate>DIES ist die Testseite</h2>
                <p> Bitte legt eure Elemente hier zum Test und zur Demo an:</p>
            </div>

            <div class="mdc-layout-grid__cell
                        mdc-layout-grid__cell--span-12-desktop
                         mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone">
                <button mat-button mat-raised-button color="primary" (click)="openSnackBar()">DefaultSnackbar</button>
                <button mat-button mat-raised-button color="primary" (click)="openSnackBarGreen()">GreenSnack</button>
                <button mat-button mat-raised-button color="primary" (click)="openSnackBarYellow()">YellowSnack</button>
                <button mat-button mat-raised-button color="primary" (click)="openSnackBarRed()">RedSnackbar</button>
                <button mat-button mat-raised-button color="primary" (click)="switchLoading()">StartLoading</button>
            </div>

            <div class="mdc-layout-grid__cell
                        mdc-layout-grid__cell--span-12-desktop
                         mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone">
                <h2>Flooding Database via REST</h2>
                <button (click)="dbTest()" color="primary" mat-raised-button>Start</button>
                Successful requests: {{dbTestOk}}/1000 [Tracked failures={{dbTestFail}}]
            </div>

            <div class="mdc-layout-grid__cell
                        mdc-layout-grid__cell--span-12-desktop
                         mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone">
                <h2>Container Light</h2>
                <neomp-container [isLightContainer]="true" cssClass="msg-mp-container-frameless" headlineText="ACTIVE">
                    <neomp-loading-data #nodata></neomp-loading-data>
                </neomp-container>
            </div>
            <div class="mdc-layout-grid__cell
                        mdc-layout-grid__cell--span-12-desktop
                         mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone">
                <h2>Container</h2>
                <neomp-container [isLightContainer]="false" cssClass="msg-mp-container-frameless" headlineText="ACTIVE">
                    <neomp-loading-data #loading></neomp-loading-data>
                </neomp-container>
            </div>
            <div class="mdc-layout-grid__cell
                        mdc-layout-grid__cell--span-12-desktop
                         mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone">
                <h2>Container Light</h2>
                <neomp-container [isLightContainer]="true" cssClass="msg-mp-container-frameless" headlineText="ACTIVE">
                    <neomp-loading-data #error></neomp-loading-data>
                </neomp-container>
            </div>
            <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop
                         mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone">
                <h2>Icon Test</h2>
                <div>
                    Bob lives in a <span class="mdi mdi-home mdi-color-helper"></span>.
                    <div>
                        <icon path="mdiAccount"></icon>
                    </div>
                </div>
            </div>
            <div class="mdc-layout-grid__cell
                        mdc-layout-grid__cell--span-12-desktop
                         mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone">
                <h2 translate>Households</h2>
                <div *ngFor="let hh of households; let i = index">
                    Household #{{i + 1}} named {{hh.householdName}}
                </div>
            </div>
            <div class="mdc-layout-grid__cell
                        mdc-layout-grid__cell--span-12-desktop
                         mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone">
                Keycloaktoken:
                <textarea readonly>{{token}}</textarea>
            </div>


            <div class="mdc-layout-grid__cell
                        mdc-layout-grid__cell--span-12-desktop
                         mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone">
                <neomp-upload-file headlineText="Test Page File Upload"></neomp-upload-file>
            </div>
            <div>
                <!-- <mat-form-field>
                    <input [satDatepicker]="picker"
                           [value]="date"
                           matInput
                           placeholder="Choose a date">
                    <sat-datepicker #picker [rangeMode]="true"></sat-datepicker>
                    <sat-datepicker-toggle [for]="picker" matSuffix></sat-datepicker-toggle>
                </mat-form-field> -->
            </div>

            <div class="mdc-layout-grid__cell
                        mdc-layout-grid__cell--span-12-desktop
                        mdc-layout-grid__cell--span-8-tablet
                        mdc-layout-grid__cell--span-4-phone">

                <table [dataSource]="testDataSource" mat-table>


                    <!-- Column name -->
                    <ng-container matColumnDef="name">
                        <th *matHeaderCellDef class="header" mat-header-cell>
                            Filter Name
                            <mat-form-field class="filter" floatLabel="never">
                                <mat-label>Search</mat-label>
                                <input [formControl]="nameFilter" matInput>
                            </mat-form-field>
                        </th>
                        <td *matCellDef="let person" mat-cell>{{person.name}}</td>
                    </ng-container>


                    <!-- Column role -->
                    <ng-container matColumnDef="role">
                        <th *matHeaderCellDef class="header" mat-header-cell>
                            Filter Role
                            <mat-form-field class="filter" floatLabel="never">
                                <mat-label>Search</mat-label>
                                <input [formControl]="roleFilter" matInput>
                            </mat-form-field>
                        </th>
                        <td *matCellDef="let person" mat-cell>{{person.role}}</td>
                    </ng-container>


                    <!-- Column year -->
                    <ng-container matColumnDef="year">
                        <th *matHeaderCellDef mat-header-cell>
                            Filter Year
                            <mat-form-field class="filter" floatLabel="never">
                                <mat-label>Search</mat-label>
                                <input [formControl]="yearFilter" matInput>
                            </mat-form-field>
                        </th>
                        <td *matCellDef="let person" mat-cell>{{person.year}}</td>
                    </ng-container>


                    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                    <ng-container matColumnDef="expandedDetail">
                        <td *matCellDef="let testEntryDetail" mat-cell>
                            <div>Expanded HelloWorld</div>
                        </td>
                    </ng-container>

                    <tr *matHeaderRowDef="columnsToDisplay" mat-header-row></tr>
                    <tr *matRowDef="let person; columns: columnsToDisplay"
                        mat-row></tr>
                </table>
            </div>
            <!-- END AVa mat-table filter testing -->

            <div class="mdc-layout-grid__cell
                        mdc-layout-grid__cell--span-12-desktop
                         mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone">
                <neomp-slide-range-filter [filterValue]="filterValue"
                                          (filterChangeEmitter)="getElements($event)"></neomp-slide-range-filter>
            </div>


        </div>

        <!-- square icons used for tools -->
        <div class="mdc-layout-grid__inner">
            <div class="mdc-layout-grid__cell
                        mdc-layout-grid__cell--span-12-desktop
                         mdc-layout-grid__cell--span-8-tablet
                         mdc-layout-grid__cell--span-4-phone">
                <neomp-container
                    headlineText="{{ 'PAGE.CONTAINER.OFFERS.CONTAINER.TOOLS' | translate }}">

                    <div class="mdc-layout-grid__inner">
                        <div class="mdc-layout-grid__cell
                                    mdc-layout-grid__cell--span-4-desktop
                                     mdc-layout-grid__cell--span-4-tablet
                                     mdc-layout-grid__cell--span-4-phone">
                            <square-icon
                                color="--color-primary"
                                icon="assets/icons/tools_calculator_icon.svg"
                                headline="Headline"
                                textContent="Content"
                                link="http://www.google.de"
                                [centerHeader]="true"
                                [showLink]="true"
                                [elementAsLink]="true"
                                (linkEmitter)='onSquareIconEmit($event)'>
                            </square-icon>
                        </div>
                    </div>
                </neomp-container>
            </div>
        </div>
    </div>
</div>



