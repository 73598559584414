import { Component, OnInit, Inject } from "@angular/core";
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { ActionData } from "app/modules/core/model/action-data";
import { Journal } from "app/modules/core/model/journal";
import { KeyValuePair } from "app/modules/core/model/key-value-pair";
import { Person } from "app/modules/core/model/person";
import { Policy } from "app/modules/core/model/policy";
import { UserCommitState } from "app/modules/core/model/user-commit-state";
import { log } from "app/modules/core/providers/logger.provider";

@Component({
    selector: "neomp-lead-dialog",
    templateUrl: "./lead.action.component.html",
    styleUrls: ["./lead.action.component.scss"]
})
export class LeadActionComponent implements OnInit {

    formGroupInputs: FormGroup;
    readonly fcnActions = "actions";
    readonly fcnSubjects = "subjects";
    readonly fcnTitle = "title";
    readonly fcnMessage = "message";
    currentAction = "";
    currentSubject = "";
    currentMessage = "";

    data: {actionData: ActionData, dialogData: { keyValues: KeyValuePair[], typeId: string}} = null;
    subjects: string[] = [];
    keyValuesActionSubjects: { [key: string]: string[] } = {};

    dialogHeader: string = null;
    dialogInputTitleText: string = null;

    constructor(
        public titleService: Title,
        public snackBar: MatSnackBar,
        public formBuilder: FormBuilder,
        public translationService: TranslateService,
        public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) data: {actionData: ActionData, dialogData: { keyValues: KeyValuePair[], typeId: string}}
    ) {
        this.data = data;
        this.applyDialogValues(this.data, this.titleService.getTitle());
    }

    ngOnInit(): void {
        this.initFormValidation();
    }



    cancelDialog() {
        this.closeDialog(null);
    }

    saveBT(): void {
        this.data.actionData.transaction.descriptionField = this.currentMessage;
        this.data.actionData.transaction.reasonForChange = this.currentAction;
        this.data.actionData.transaction.transactionName = this.currentSubject;
        this.closeDialog(this.data.actionData);
    }
    closeDialog(value?: any) {
        this.dialogRef.close(value);
    }

    // uploadedFiles(uploadFiles: FileUpload[]) {
    //     log.debug(`${this.TAG} uploadedFiles() -> fileCount=${uploadFiles.length}`);
    //     this.filesSubject.next(uploadFiles);
    // }

    private applyDialogValues(data: {actionData: ActionData, dialogData: { keyValues: KeyValuePair[], typeId: string}}, contextTitle: string) {
        this.translationService.get(
            "DIALOG.ACTION.HEADER",
            {
                context: contextTitle, transactionId: data.actionData.transaction.typeId, 
                transactionName: data.actionData.transaction.transactionName 
            })
            .subscribe(result => {
                this.dialogHeader = result;
            });

        this.translationService.get(
            "DIALOG.ACTION.INPUT.TEXT.TITLE",
            {transactionTitle: data.actionData.transaction.transactionName })
            .subscribe(result => {
                this.dialogInputTitleText = result;
            });

        this.data.dialogData.keyValues.forEach(v => {
            this.keyValuesActionSubjects[v.key] = v.value.split(",");
        });

        if (this.data.dialogData.keyValues.length === 0) {
            this.translationService.get("COMMONS.LOADINGSTATUS.NODATA")
                .subscribe(result => {
                    this.snackBar.open(result, "x", UserCommitState.configCheckData);
                });
        }
    }

    private initFormValidation() {
        this.formGroupInputs = new FormGroup({
            title: new FormControl(this.fcnTitle, {
                validators: [Validators.required, Validators.maxLength(50)],
                updateOn: "blur"
            }),
            message: new FormControl("", {
                validators: [Validators.required, emptyValidator],
                updateOn: "blur"
            }),
            actions: new FormControl("", {validators: [Validators.required], updateOn: "blur"}),
            subjects: new FormControl("", {validators: [Validators.required], updateOn: "blur"}),
        });
    }

    private validateForm(fg: FormGroup) {
        Object.keys(fg.controls).forEach(field => {
            const control = this.formGroupInputs.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({onlySelf: true});
            } else if (control instanceof FormGroup) {
                this.validateForm(control);
            }
        });
    }

    public static getConfigWithData(data: ActionDialogData): MatDialogConfig<ActionDialogData> {
        const cfg = new MatDialogConfig<ActionDialogData>();
        cfg.autoFocus = true;
        cfg.hasBackdrop = true;
        cfg.panelClass = "neomp-dialog-padding-none";
        cfg.closeOnNavigation = true;
        cfg.data = data;
        return cfg;
    }

    public static getTaskConfigWithData(actionData: ActionData, dialogData: { keyValues: KeyValuePair[], typeId: string}): 
    MatDialogConfig<{actionData: ActionData, dialogData: { keyValues: KeyValuePair[], typeId: string}}> {
        const cfg = new MatDialogConfig<{actionData: ActionData, dialogData: { keyValues: KeyValuePair[], typeId: string}}>();
        cfg.autoFocus = true;
        cfg.hasBackdrop = true;
        cfg.panelClass = "neomp-dialog-padding-none";
        cfg.closeOnNavigation = true;
        cfg.data = {actionData, dialogData};
        return cfg;
    }


    public onSelectAction(event) {
        log.debug(`onSelectAction() -> eventSourceValue=${event.source.value}`);
        if (event.isUserInput) {
            this.currentAction = event.source.value;
            this.subjects = this.keyValuesActionSubjects[this.currentAction];
            if (this.subjects.length === 0) {
                this.translationService.get("COMMONS.LOADINGSTATUS.NODATA")
                    .subscribe(result => {
                        this.snackBar.open(result, "x", UserCommitState.configCheckData);
                    });
                return;
            }
        }
    }

    public onSelectSubject(event) {
        log.debug(`onSelectSubject() -> eventSourceValue=${event.source.value}`);
        if (event.isUserInput) {
            this.currentSubject = event.source.value;
        }
    }
}

function emptyValidator(control: AbstractControl) {
    if (control.value.toString().trim().length === 0) {
        return {isEmpty: true};
    }
    return null;
}


export interface ActionDialogData {
    transTypeId: string;
    transName: string;
    transSubject: string;
    transTitle: string;
    transMessage: string;
    operationalIdExternal?: string;
    person?: Person;
    policy?: Policy;
    journal?: Journal;
    keyValues?: KeyValuePair[];
    groupId?: string;
}