<div class="agent-nav">
  <div class="naving">
    <ul>
      <!-- <li class="nav-linked-item" routerLink="/livechat">
        <a ><img src="assets/images/Admin view pages/home.svg" alt="home"><span class="nav-item-text">Home</span></a>
      </li> -->
      <li class="nav-linked-item"  routerLink="/livechat/chats" (click)="onChats(true)">
        <a><img src="assets/images/Admin view pages/chat.svg" alt="chat"><span class="nav-item-text">Chats</span></a>
      </li>
      <!-- <li class="nav-linked-item" *ngIf="agent ? agent.role.globalPerms : false">
        <a><span class="nav-item-text">Clients</span></a>
      </li> -->
      <li class="nav-linked-item" routerLink="/livechat/archives" (click)="onChats(false)">
        <a ><img src="assets/images/Admin view pages/Archives.svg" alt="archives"><span class="nav-item-text">Archives</span></a>
      </li>
      <!-- <li class="nav-linked-item">
        <a><img src="assets/images/Admin view pages/Tickets.svg" alt="tickets"><span class="nav-item-text">Tickets</span></a>
      </li>
      <li class="nav-linked-item" *ngIf="false">
        <a><span class="nav-item-text">Agents</span></a>
      </li>
      <li class="nav-linked-item">
        <a><img src="assets/images/Admin view pages/Settings.svg" alt="settings"><span class="nav-item-text">Settings</span></a>
      </li>
      <li class="nav-linked-item">
        <a><img src="assets/images/Admin view pages/Help.svg" alt="help"><span class="nav-item-text">Help</span></a>
      </li> -->
    </ul>
  </div>
  <!-- <div class="settings">
    <ul>
      <li class="bottom-nav-link-items" (mouseover)="helpImgLink = 'assets/images/Admin view pages/user hover.svg'" (mouseleave)="helpImgLink = 'assets/images/Admin view pages/user.svg'">
        <a><img [src]="helpImgLink" alt="user"></a>
      </li>
      <li class="bottom-nav-link-items"  (click)="logout()" (mouseover)="logoutLink = 'assets/images/Admin view pages/log out hover.svg'" (mouseleave)="logoutLink = 'assets/images/Admin view pages/log out.svg'">
        <a><img [src]="logoutLink" alt="logout"></a>
      </li>
    </ul>
  </div> -->
</div>
