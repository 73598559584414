<table [dataSource]="dataSource" class="msg-helper-width-100 neo-content-wrapper" mat-table matSort
    multiTemplateDataRows>

    <!-- columns -->
    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
            <div class="div-status" [ngClass]="getClassFromStatus(element.effectiveDate, element.statusName)"></div>
        </td>
    </ng-container>
    <ng-container matColumnDef="policyId">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>{{
            'PAGE.CONTAINER.COMMISSIONS.TABLES.PROVISIONSPENDINGMATTER.POLICYID' | translate }}
        </th>
        <td mat-cell *matCellDef="let element"> {{element.policyId}}</td>
    </ng-container>
    <ng-container matColumnDef="inputChannel">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>{{
            'PAGE.CONTAINER.COMMISSIONS.TABLES.PROVISIONSPENDINGMATTER.INPUTCHANNEL' | translate }}
        </th>
        <td mat-cell *matCellDef="let element"> {{element.inputChannel}}</td>
    </ng-container>
    <ng-container matColumnDef="statusName">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>{{
            'PAGE.CONTAINER.COMMISSIONS.TABLES.PROVISIONSPENDINGMATTER.STATUS' | translate }}
        </th>
        <td mat-cell *matCellDef="let element"> {{element.statusName}}</td>
    </ng-container>
    <ng-container matColumnDef="riskCarrier">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>{{
            'PAGE.CONTAINER.COMMISSIONS.TABLES.PROVISIONSPENDINGMATTER.COMPANY' | translate }}
        </th>
        <td mat-cell *matCellDef="let element"> {{element.riskCarrier}}</td>
    </ng-container>
    <ng-container matColumnDef="sparte">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>{{
            'PAGE.CONTAINER.COMMISSIONS.TABLES.PROVISIONSPENDINGMATTER.POLNAME' | translate }}
        </th>
        <td mat-cell *matCellDef="let element"> {{element.sparte}}</td>
    </ng-container>
    <ng-container matColumnDef="customerName">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>{{
            'PAGE.CONTAINER.COMMISSIONS.TABLES.PROVISIONSPENDINGMATTER.CUSTOMER' | translate }}
        </th>
        <td mat-cell *matCellDef="let element"> {{element.customerName}}</td>
    </ng-container>
    <ng-container matColumnDef="customerBirthday">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>{{
            'PAGE.CONTAINER.COMMISSIONS.TABLES.PROVISIONSPENDINGMATTER.CUSTOMERBIRTH' | translate }}
        </th>
        <td mat-cell *matCellDef="let element"> {{formatDate(element.customerBirthday)}}</td>
    </ng-container>
    <ng-container matColumnDef="reasonForChange">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>{{
            'PAGE.CONTAINER.COMMISSIONS.TABLES.PROVISIONSPENDINGMATTER.REASONFORCHANGE' | translate }}
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.reasonForChange }}</td>
    </ng-container>
    <ng-container matColumnDef="effectiveDate">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>{{
            'PAGE.CONTAINER.COMMISSIONS.TABLES.PROVISIONSPENDINGMATTER.EFFECTIVDATE' | translate }}
        </th>
        <td mat-cell *matCellDef="let element"> {{formatDate(element.effectiveDate)}}</td>
    </ng-container>
    <ng-container matColumnDef="filter">
        <th *matHeaderCellDef mat-header-cell (click)="changeFilterView()"
            class="mat-header-cell mat-column-filter {{isFilterActive ? 'mat-column-filter-active':''}}">
            <div class="neomp-table-column-filter-element  mdi {{isFilterActive ? 'mdi-filter':'mdi-filter-outline'}}">
            </div>
        </th>
        <td mat-cell *matCellDef="let element" class="mat-column-filter-action">
            <div class="neomp-table-column-filter-element mdi mdi-plus" [disabled]="isDialogOpen"
            (click)="isDialogOpen ? null : openActionDialog($event, element)">
            </div>
        </td>
    </ng-container>

    <!-- Filter row definition -->
    <ng-container matColumnDef="statusFilter">
     <th mat-header-cell *matHeaderCellDef></th>
    </ng-container> 
    <ng-container matColumnDef="policyIdFilter">
        <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter" floatLabel="never">
                <input matInput [formControl]="policyIdFilter"
                    placeholder="{{ 'TABLES.FILTERPLACEHOLDER.ID' | translate }}">
            </mat-form-field>
        </th>
    </ng-container>
    <ng-container matColumnDef="inputChannelFilter">
        <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter" floatLabel="never">
                <input matInput [formControl]="inputChannelFilter"
                    placeholder="{{ 'TABLES.FILTERPLACEHOLDER.ACTIVITY' | translate }}">
            </mat-form-field>
        </th>
    </ng-container>
    <ng-container matColumnDef="statusNameFilter">
        <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter" floatLabel="never">
                <input matInput [formControl]="statusNameFilter"
                    placeholder="{{ 'TABLES.FILTERPLACEHOLDER.STATUS' | translate }}">
            </mat-form-field>
        </th>
    </ng-container>
    <ng-container matColumnDef="riskCarrierFilter">
        <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter" floatLabel="never">
                <input matInput [formControl]="riskCarrierFilter"
                    placeholder="{{ 'TABLES.FILTERPLACEHOLDER.COMPANY' | translate }}">
            </mat-form-field>
        </th>
    </ng-container>
    <ng-container matColumnDef="sparteFilter">
        <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter" floatLabel="never">
                <input matInput [formControl]="sparteFilter"
                    placeholder="{{ 'TABLES.FILTERPLACEHOLDER.SECTION' | translate }}">
            </mat-form-field>
        </th>
    </ng-container>
    <ng-container matColumnDef="customerNameFilter">
        <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter" floatLabel="never">
                <input matInput [formControl]="customerNameFilter"
                    placeholder="{{ 'TABLES.FILTERPLACEHOLDER.NAME' | translate }}">
            </mat-form-field>
        </th>
    </ng-container>
    <ng-container matColumnDef="customerBirthdayFilter">
        <th mat-header-cell *matHeaderCellDef>
            <neomp-date-picker  [setDefaultValues]="false" (filterValue)="customerBirthdayFilterChanged($event)"></neomp-date-picker>
        </th>
    </ng-container>
    <ng-container matColumnDef="reasonForChangeFilter">
        <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter" floatLabel="never">
                <input matInput [formControl]="reasonForChangeFilter"
                    placeholder="{{ 'TABLES.FILTERPLACEHOLDER.COMMISSION_AMOUNT' | translate }}">
            </mat-form-field>
        </th>
    </ng-container>
    <ng-container matColumnDef="effectiveDateFilter">
        <th mat-header-cell *matHeaderCellDef>
            <neomp-date-picker [setDefaultValues]="false" (filterValue)="effectiveDateFilterChanged($event)"></neomp-date-picker>
        </th>
    </ng-container>
    <ng-container matColumnDef="filterRemove">
        <th mat-header-cell *matHeaderCellDef (click)="resetFilter()">
            <div class="neomp-table-column-filter-element mdi mdi-close"></div>
        </th>
    </ng-container>
    <!--end of  Filter row definition-->

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let openIssuesDetail" [attr.colspan]="displayedColumns.length"
            class="{{openIssuesDetail === expandedElement ? 'openIssuesDetail-row-detail-content-expanded':''}}">
            <div class="openIssuesDetail-row-expanded-element-without-flex"
                [@detailExpand]="openIssuesDetail === expandedElement ? 'expanded' : 'collapsed'">
                <div class="msg-helper-margin-left-10 msg-helper-margin-top-10">
                    <mat-form-field class="neomp-full-width">
                        <input matInput readonly
                            placeholder="{{'PAGE.CUSTOMERDETAIL.POLICY.DETAILS.GENERALINFO.POLICYNO' | translate }}"
                            value="{{openIssuesDetail.policyNumber}}">
                    </mat-form-field>
                </div>
                <div class="msg-helper-margin-left-10">
                    <mat-form-field class="neomp-full-width">
                        <textarea matInput readonly
                            placeholder="{{'PAGE.CUSTOMERDETAIL.POLICY.DESCRIPTION' | translate }}"
                            value="{{openIssuesDetail.descriptionField}}"></textarea>
                    </mat-form-field>
                </div>
                <div class="msg-helper-margin-left-10 msg-helper-margin-bottom-10">
                    <button color="primary" (click)="toProduct(expandedElement)"
                        [disabled]="isProductButtonDisabled(expandedElement)" mat-raised-button>{{
                        'TABLES.BUTTONS.TO_PRODUCT' | translate }}</button>
                </div>

            </div>
        </td>
    </ng-container>

    <!--Row definitions -->
    <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
    <tr *matHeaderRowDef="displayedColumnFilters"
        class="neomp-table-row-filter {{showFilter? '': 'neomp-table-row-filter-hide'}}" mat-header-row></tr>
    <tr mat-row *matRowDef="let openIssuesRow; columns: displayedColumns, let i = dataIndex;"
        class="openIssuesDetail-row container-table-row"
        [class.openIssuesDetail-row-expanded]="expandedElement === openIssuesRow"
        [class.neomp-table-row-selected]="expandedElement !== openIssuesRow"
        (click)="openIssuesRowClick(openIssuesRow, i)"></tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="openIssuesDetail-row-detail-content"></tr>
</table>