import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LEADS_CONFIGS } from 'app/modules/leads/configs/lead-configs';
import { BusinessTransactionHelper } from '../../static/bt-helper';
import { Person } from '../../model/person';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import { LeadLog } from '../../model/LeadLog.enum';
import { Observable, Subscription, forkJoin, of } from 'rxjs';
import { LeadsService } from 'app/modules/leads/services/leads.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserCommitState } from '../../model/user-commit-state';
import { log } from '../../providers/logger.provider';
import { catchError } from 'rxjs/operators';
import { LoadingService } from '../../providers/loading.component';

@Component({
  selector: 'neomp-appointment-dialog',
  templateUrl: './appointment-dialog.component.html',
  styleUrls: ['./appointment-dialog.component.scss']
})
export class AppointmentDialogComponent implements OnInit {
  @Output() formChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  terminForm: FormGroup = new FormGroup({
    calendar_lang: new FormControl(),
    termin_date: new FormControl("", Validators.required),
    termin_time: new FormControl("", Validators.required),
    termin_agent: new FormControl("" ,Validators.required)
  });

  leadConfigs = LEADS_CONFIGS;
  agents: Person[] = [];
  lead: Person | null = null;
  editFamilyMemberMode = false;
  editTerminMode = true;
  advisorName = "";
  subscriptions: Subscription[] = [];
  agentToAssign: Person = null;
  termineId: string = "";
  isUnassigned: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private leadService: LeadsService,
    public snackBar: MatSnackBar,
    public loadingService: LoadingService,
    private dialogRef: MatDialogRef<AppointmentDialogComponent>,
    private router: Router
    ) { }

  ngOnInit(): void {
    this.isUnassigned = false;
    this.termineId = this.data.lead.uuid;
    this.agents = this.data.agents;
    this.lead = this.data.lead;

    if (this.lead.lead.termin_berater_shortcut === '-1') {
      this.isUnassigned = true;
    }

    this._fillLeadDetails(this.lead);
  }

  onFormChanged() {
    this.formChanged.emit(this.terminForm.dirty);
  }

  save(editModeEvent): void {
    editModeEvent.editSection === 'CalendarAppointment' ? this.editTerminMode = editModeEvent.editMode :
      this.editFamilyMemberMode = editModeEvent.editMode;

    if (this.terminForm.get('termin_agent').dirty && this.terminForm.get('termin_agent').valid) {
      this.assignLeadsToAgent(this.agentToAssign, this.lead);
    }
    if (this.terminForm.get('termin_date').dirty || this.terminForm.get('termin_time').dirty) {
      if (this.terminForm.get('termin_date').valid && this.terminForm.get('termin_time').valid) {
        this.addTermine();
      }
    }
  }

  cancelDialog() {
    this.closeDialog(true);
  }

  closeDialog(value?: any) {
    this.dialogRef.close(value);
  }

  setValue(field, event) {
    this.agentToAssign = event.value;
    const selectedAgent = this.agents.find(agent => agent.uuid === event.value.uuid);
    this.terminForm.patchValue({
      termin_agent: selectedAgent ? selectedAgent : null
    });
  }

  private _fillLeadDetails(person: Person): void {
    this.terminForm.patchValue({
      calendar_lang: '',
      termin_date: person.lead.termin_date,
      termin_time: person.lead.termin_time,
      termin_agent: this.agents.find(agent => agent.uuid === person.lead.termin_berater_shortcut)
    })
  }

  handleNavigateToEdit() {
    this.cancelDialog();
    this.router.navigate([`/termine/${this.termineId}/edit`]);
  }

  addTermine(
    date?: string,
    time?: string
  ): void {
  
    const formValue: { termin_date: string, termin_time: string }
      = this.terminForm.value;
  
    const sorKeys = {
      agentName: this.advisorName,
      category: this.leadConfigs.typeId.TERMIN.TERMIN,
      time: this.lead.lead.termin_time || ""
    };
    let terminDate = "";
    let terminTime = "";
  
    if (date || time) {
      terminDate = date;
      terminTime = time;
      this.lead.lead.termin_time = terminTime;
      this.lead.lead.termin_date = terminDate;
      sorKeys.time = terminTime;
    } else {
      terminTime = formValue.termin_time;
      terminDate = moment(formValue.termin_date).format('YYYY-MM-DD');
      this.lead.lead.termin_time = terminTime;
      this.lead.lead.termin_date = terminDate;
      sorKeys.time = terminTime;
    }

    this.terminForm.patchValue({
      calendar_lang: '',
      termin_date: this.lead.lead.termin_date,
      termin_time: this.lead.lead.termin_time,
      termin_agent: this.agents.find(agent => agent.fullName === this.lead.lead.advisor_name)
    })
    const actionData = BusinessTransactionHelper.createLeadBTActionObject(
      LeadLog.TERMIN,
      this.leadConfigs.typeId.TERMIN.TERMIN,
      '1. Termin',
      `${this.advisorName}, ${this.lead.lead.termin_date} ${this.lead.lead.termin_time}`,
      '',
      this.lead.uuid,
      [],
      this.lead,
      moment(this.lead.lead.termin_date).format('YYYY-MM-DD') || "",
      sorKeys,
      this.advisorName
    );

    this.subscriptions.push(
      this.leadService.sendLeadAction(actionData)
        .subscribe(() => {
          this.snackBar.open("Termine created successfully", "X", UserCommitState.configSuccess);
          this.dialogRef.close({ success: true, leadData: this.lead, isUnassigned: this.isUnassigned });
        }, err => {
          log.error(`Something went wrong while calling POST-> lead/action `, err);
          this.snackBar.open("Something went wrong!", "X", UserCommitState.configError)
        })
    );
  }

  assignLeadsToAgent(agentToAssign?: Person, lead?: Person) {
    let requests: Observable<any>[] = [];
    const agent = agentToAssign ? agentToAssign : this.terminForm.get('termin_agent').value;
    this.loadingService.display(true);

    const sorKeys = {
      'advisorId': agent.uuid,
      'leadId': lead.uuid
    }

    lead.lead.termin_editdate = moment().format('YYYY-MM-DD');
    lead.lead.termin_berater_shortcut = agent.uuid;

    const actionData = BusinessTransactionHelper.createLeadBTActionObject(
      LeadLog.TRANSFER,
      BusinessTransactionHelper.typeId.assignLeadToAgent.from,
      '',
      'Termine TRANSFER => ' + agent.fullName,
      '',
      agent.uuid,
      [],
      lead,
      moment().add(5, 'days').format('YYYY-MM-DD'),
      sorKeys,
      this.advisorName
    );
    requests.push(this.leadService.sendLeadAction(actionData)
      .pipe(
        // do not let your observables die; forkJoin will the not be triggered then.
        catchError(err => of(err))
      ));

    forkJoin(requests).subscribe(results => {
      this.snackBar.open("Lead updated successfully", "X", UserCommitState.configSuccess);
      this.dialogRef.close({ success: true, leadData: this.lead, isUnassigned: this.isUnassigned });
      this.loadingService.display(false);
    }, err => {
      this.snackBar.open("Something went wrong!", "X", UserCommitState.configError)
    })

  }

}
