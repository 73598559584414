<div *ngIf="showLoader" class="msg-loadingstatus msg-nodata ">
    <div class="mdi mdi-timer-sand">
        <span>{{ 'COMMONS.LOADINGSTATUS.LOADING' | translate }}</span>
    </div>
</div>
<div *ngIf="showNodata" class="msg-loadingstatus msg-nodata ">
    <div class="mdi mdi-cancel">
        <span>{{ 'COMMONS.LOADINGSTATUS.NODATA' | translate }}</span>
    </div>
</div>
<div *ngIf="showError" class="msg-loadingstatus msg-loadingerror">
    <div class="mdi mdi-alert-circle-outline">
        <span>{{ 'COMMONS.LOADINGSTATUS.ERROR' | translate }}</span>
    </div>
</div>
