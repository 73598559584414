import { GMRiskObject } from "./GMRiskObject";

export class GMOfferData {
    agentNumber: string;
    riskObjects: Array<GMRiskObject>;
    language: string;
    constructor(agentNumber: string, riskObjects: Array<GMRiskObject>){
        this.agentNumber = agentNumber;
        this.riskObjects = riskObjects;
    }
}