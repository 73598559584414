import { Inject, Injectable, LOCALE_ID } from "@angular/core";
import { RestProvider, RestProviderActions, RestProviderActionsDummy } from "./rest.provider";
import { Observable, of } from "rxjs";
import { environment } from "../../../../environments/environment";
import { JournalJson } from "../json/journal.json";
import { Journal } from "../model/journal";
import { JournalListJson } from "../json/journal-list.json";
import { JournalList } from "../model/journal-list";
import { Helper } from "../static/helper";
import { DatePickerValue } from "../model/datepicker";
import { formatDate } from "@angular/common";
import { isMoment, Moment } from "moment";
import { map } from "rxjs/operators";


@Injectable({
  providedIn: "root",
})
export class JournalProvider {
  private readonly CLASS_NAME = this.constructor.name;

  constructor(
    public rest: RestProvider,
    @Inject(LOCALE_ID) private locale: string
  ) { }

  public getTransactionTypeName() {
    return this.rest.get(
      `${environment.settings.BASE_URL}/api/advisor/distinct-buchung`, null, true, new RestProviderActionsDummy())

  }

  public getJournalsReferencedByDateRangeWithPagination(
    restProviderActions: RestProviderActions,
    typeIdFrom: string | string[] = null,
    typeIdTo: string | string[] = null,
    dateRange: DatePickerValue,
    page: number,
    size: number,
    sort?: any,
    completed?: boolean,
    processID?: string,
    processIDs?: string
  ): Observable<{ data: JournalList[], totalCount: number }> {
    const startDate: Date = isMoment(dateRange.begin)
      ? dateRange.begin.toDate()
      : dateRange.begin;
    const endDate: Date = isMoment(dateRange.end)
      ? dateRange.end.toDate()
      : dateRange.end;

    let queryParams = `?page=${page}&size=${size}`;

    if (completed == true || completed == false) {
      queryParams += `&filter={"type":"FixedValue","key":"status.completed", "value": ${completed}}`;
    }

    if (processID){
      queryParams += `&filter={"type":"FixedValue","key":"operationIdExternal","value":"${processID}"}`
    }

    if (processIDs) {
      const stringifiedProcessIDs = JSON.stringify(processIDs.split(','));
      queryParams += `&filter={"type":"ArrayCase","key":"operationIdExternal","values":${stringifiedProcessIDs}}`;
    }

    // Handle multiple typeId ranges. The from-to pair is determined by the array index
    let typeIdFilter = [];
    if (Array.isArray(typeIdFrom) && Array.isArray(typeIdTo)) {
      var length = Math.max(typeIdFrom.length, typeIdTo.length);
      for (var i = 0; i < length; i++) {
        typeIdFilter.push({"from": typeIdFrom.shift() ?? '', "to": typeIdTo.shift() ?? ''});
      }
    } else {
      typeIdFrom = Array.isArray(typeIdFrom) ? typeIdFrom.shift() ?? '' : typeIdFrom;
      typeIdTo = Array.isArray(typeIdTo) ? typeIdTo.shift() ?? '' : typeIdTo;
    }

    // If there are multiple typeId ranges, use MultiRange
    if (typeIdFilter.length > 0) {
      queryParams += `&filter={"type":"MultiRange","key":"typeId", "values": ${JSON.stringify(typeIdFilter)}}`
    } else if (typeIdFrom !== null || typeIdTo !== null) {
      queryParams += `&filter={"type":"DefaultRange","key":"typeId","from":"${typeIdFrom}","to":"${typeIdTo}"}`;
    }

    if (sort) {
      queryParams += `&sort=${JSON.stringify(sort)}`;
    }

    if (queryParams.length > 0) {
      queryParams += "&";
    }

    queryParams += JSON.stringify(this.getDateFilterQuery(startDate, endDate));
    return this.rest
      .get<{ data: JournalListJson[], totalCount: number }>(
        `${environment.settings.BASE_URL}/api/advisor/transactionsWithReferences${queryParams}`,
        null,
        true,
        restProviderActions
      )
      .pipe(map((res) => {
        return {
          data: JournalList.fromJsons(res.data),
          totalCount: res.totalCount
        };
      }));
  }

  public getFilteredJournalsReferencedByDateRangeWithPagination(
    restProviderActions: RestProviderActions,
    typeIdFrom: string = null,
    typeIdTo: string = null,
    dateRange: DatePickerValue,
    page: number,
    size: number,
    sort?: any,
    completed?: boolean,
    searchText?:string,
    typeName?:string,
    descriptionField?:string,
    companyFilter?: string,
    insuranceFilter?: string,
  ): Observable<{ data: JournalList[], totalCount: number }> {
    const startDate: Date = isMoment(dateRange.begin)
      ? dateRange.begin.toDate()
      : dateRange.begin;
    const endDate: Date = isMoment(dateRange.end)
      ? dateRange.end.toDate()
      : dateRange.end;

    let queryParams = `?page=${page}&size=${size}`;

    if (completed == true || completed == false) {
      queryParams += `&filter={"type":"FixedValue","key":"status.completed", "value": ${completed}}`;
    }
    if (typeIdFrom !== null || typeIdTo !== null) {
      queryParams += `&filter={"type":"DefaultRange","key":"typeId","from":"${typeIdFrom}","to":"${typeIdTo}"}`;
    }

    if(searchText) {
      queryParams += `&filter={"for":"Customer","type":"SearchName","key":"name","value":"${searchText}"}`
    }

    if(typeName) {
      queryParams += `&filter={"for":"BT","type":"RegexValue","key":"typeName","value":"${typeName}"}`
    }

    if(descriptionField) {
      queryParams += `&filter={"for":"BT","type":"RegexValue","key":"descriptionField","value":"${descriptionField}"}`
    }

    if (companyFilter) {
      queryParams += `&filter={"for":"Policy", "type":"RegexValue","key":"products.riskCarrier", "value": "${companyFilter}"}`;
    }

    if (insuranceFilter) {
      queryParams += `&filter={"for":"Policy", "type":"RegexValue","key":"products.lineOfBusiness", "value": "${insuranceFilter}"}`;
    }

    if (sort) {
      queryParams += `&sort=${JSON.stringify(sort)}`;
    }

    if (queryParams.length > 0) {
      queryParams += "&";
    }

    queryParams += JSON.stringify(this.getDateFilterQuery(startDate, endDate));
    return this.rest
      .get<{ data: JournalListJson[], totalCount: number }>(
        `${environment.settings.BASE_URL}/api/advisor/filteredReferencesTransactions${queryParams}`,
        null,
        true,
        restProviderActions
      )
      .pipe(map((res) => {
        return {
          data: JournalList.fromJsons(res.data),
          totalCount: res.totalCount
        };
      }));
  }

  public getJournalsByDateRange(
    restProviderActions: RestProviderActions,
    typeIdFrom: string = null,
    typeIdTo: string = null,
    dateRange: DatePickerValue,
    sort?: any
  ): Observable<Journal[]> {
    let queryParams = "";
    if (typeIdFrom !== null || typeIdTo !== null) {
      queryParams = `?filter={"type":"DefaultRange","key":"typeId","from":"${typeIdFrom}","to":"${typeIdTo}"}`;
    }
    if (queryParams.length > 0) {
      queryParams += "&";
    } else {
      queryParams += "?";
    }
    queryParams += this.getDateFilterQuery(
      dateRange.begin.toDate(),
      dateRange.end.toDate()
    );

    if (sort) {
      queryParams += `&sort=${JSON.stringify(sort)}`;
    }
    return this.rest
      .get<JournalJson[]>(
        `${environment.settings.BASE_URL}/api/advisor/transactions${queryParams}`,
        null,
        true,
        restProviderActions
      )
      .pipe(map((res) => {
        return Journal.fromJsons(res);
      }));
  }

  public getJournalsReferencedWithPagination(
    restProviderActions: RestProviderActions,
    typeIdFrom: string | string[] = null,
    typeIdTo: string | string[] = null,
    page: number,
    size: number,
    sort?: any,
    completed?: boolean,
    processID?: string,
    processIDs?: string
  ): Observable<{ data: JournalList[]; totalCount: number }> {
    let queryParams = `?page=${page}&size=${size}`;

    if (completed == true || completed == false) {
      queryParams += `&filter={"type":"FixedValue","key":"status.completed", "value": ${completed}}`;
    }

    if (processID){
      queryParams += `&filter={"type":"FixedValue","key":"operationIdExternal","value":"${processID}"}`
    }

    if (processIDs) {
      const stringifiedProcessIDs = JSON.stringify(processIDs.split(','));
      queryParams += `&filter={"type":"ArrayCase","key":"operationIdExternal","values":${stringifiedProcessIDs}}`;
    }
    
    // Handle multiple typeId ranges. The from-to pair is determined by the array index
    let typeIdFilter = [];
    if (Array.isArray(typeIdFrom) && Array.isArray(typeIdTo)) {
      var length = Math.max(typeIdFrom.length, typeIdTo.length);
      for (var i = 0; i < length; i++) {
        typeIdFilter.push({"from": typeIdFrom.shift() ?? '', "to": typeIdTo.shift() ?? ''});
      }
    } else {
      typeIdFrom = Array.isArray(typeIdFrom) ? typeIdFrom.shift() ?? '' : typeIdFrom;
      typeIdTo = Array.isArray(typeIdTo) ? typeIdTo.shift() ?? '' : typeIdTo;
    }

    // If there are multiple typeId ranges, use MultiRange
    if (typeIdFilter.length > 0) {
      queryParams += `&filter={"type":"MultiRange","key":"typeId", "values": ${JSON.stringify(typeIdFilter)}}`
    } else if (typeIdFrom !== null || typeIdTo !== null) {
      queryParams += `&filter={"type":"DefaultRange","key":"typeId","from":"${typeIdFrom}","to":"${typeIdTo}"}`;
    }

    if (sort) {
      queryParams += `&sort=${JSON.stringify(sort)}`;
    }
    
    return this.rest
      .get<{ data: JournalListJson[]; totalCount: number }>(
        `${environment.settings.BASE_URL}/api/advisor/transactionsWithReferences${queryParams}`,
        null,
        false,
        restProviderActions
      )
      .pipe(map((res) => {
        return {
          data: JournalList.fromJsons(res.data),
          totalCount: res.totalCount,
        };
      }));
  }

  public getFilteredJournalsReferencedWithPagination(
    restProviderActions: RestProviderActions,
    typeIdFrom: string = null,
    typeIdTo: string = null,
    page: number,
    size: number,
    sort?: any,
    completed?: boolean,
    searchText?:string,
    typeName?:string,
    descriptionField?:string,
    companyFilter?: string,
    insuranceFilter?: string,
  ): Observable<{ data: JournalList[]; totalCount: number }> {
    let queryParams = `?page=${page}&size=${size}`;

    if (completed == true || completed == false) {
      queryParams += `&filter={"type":"FixedValue","key":"status.completed", "value": ${completed}}`;
    }
    
    if (typeIdFrom !== null || typeIdTo !== null) {
      queryParams += `&filter={"type":"DefaultRange","key":"typeId","from":"${typeIdFrom}","to":"${typeIdTo}"}`;
    }

    if(searchText) {
      queryParams += `&filter={"for":"Customer","type":"SearchName","key":"name","value":"${searchText}"}`
    }

    if(typeName){
      queryParams += `&filter={"for":"BT","type":"RegexValue","key":"typeName","value":"${typeName}"}`
    }

    if(descriptionField) {
      queryParams += `&filter={"for":"BT","type":"RegexValue","key":"descriptionField","value":"${descriptionField}"}`
    }

    if (companyFilter) {
      queryParams += `&filter={"for":"Policy", "type":"RegexValue","key":"products.riskCarrier", "value": "${companyFilter}"}`;
    }

    if (insuranceFilter) {
      queryParams += `&filter={"for":"Policy", "type":"RegexValue","key":"products.lineOfBusiness", "value": "${insuranceFilter}"}`;
    }

    if (sort) {
      queryParams += `&sort=${JSON.stringify(sort)}`;
    }
    
    return this.rest
      .get<{ data: JournalListJson[]; totalCount: number }>(
        `${environment.settings.BASE_URL}/api/advisor/filteredReferencesTransactions${queryParams}`,
        null,
        false,
        restProviderActions
      )
      .pipe(map((res) => {
        return {
          data: JournalList.fromJsons(res.data),
          totalCount: res.totalCount,
        };
      }));
  }

  public getJournalsByPartnerId(
    partnerId: string,
    restProviderActions: RestProviderActions,
    typeIdFrom: string = null,
    typeIdTo: string = null,
    sort?: any
  ): Observable<Journal[]> {
    let queryParams = "";
    if (typeIdFrom !== null || typeIdTo !== null) {
      queryParams = `?filter={"type":"DefaultRange","key":"typeId","from":"${typeIdFrom}","to":"${typeIdTo}"}`;
    }

    if (sort) {
      queryParams += `&sort=${JSON.stringify(sort)}`;
    }

    return this.rest
      .get<JournalJson[]>(
        `${environment.settings.BASE_URL}/api/advisor/transactions/partner/${partnerId}${queryParams}`,
        null,
        true,
        restProviderActions
      )
      .pipe(map((res) => {
        return Journal.fromJsons(res);
      }));
  }

  /* TODO AVa: This is a bug, since multiple filters can probably not be applied to a single restful request.
        temporary fix: obtain every business transaction for this partner and filter them afterwards...
        TODO AVa2: Introduce ExcludeRangeFilter...
    public getJournalsByPartnerIdByRanges(partnerId: string,
                                  restProviderActions: RestProviderActions,
                                  allowedTypeIds: FilterTypeId[] = []): Observable<Journal[]> {
        let queryParams = "?";
        for (let i = 0; i < allowedTypeIds.length; i++) {
            const id = allowedTypeIds[i];
            if (i > 0 && i < allowedTypeIds.length) {
                queryParams = queryParams.concat("&");
            }
            queryParams = queryParams
                .concat(`filter={"type":"DefaultRange","key":"typeId","from":"${id.from}","to":"${id.to}"}`);
        }
        return this.rest.get<JournalJson[]>(
            `${environment.settings.BASE_URL}/api/advisor/transactions/partner/${partnerId}${queryParams}`,
            null,
            true,
            restProviderActions).map((res) => {
            return Journal.fromJsons(res);
        });
    }
    */

  public getJournalsByPolicyId(
    policyId: string,
    restProviderActions: RestProviderActions,
    sort?: any
  ): Observable<Journal[]> {
    let queryParams = "";
    if (sort) {
      queryParams += `?sort=${JSON.stringify(sort)}`;
    }
    return this.rest
      .get<JournalJson[]>(
        `${environment.settings.BASE_URL}/api/advisor/policies/${policyId}/transactions${queryParams}`,
        null,
        true,
        restProviderActions
      )
      .pipe(map((res) => {
        return Journal.fromJsons(res);
      }));
  }

  /* TODO AVa: This is a bug, since multiple filters can probably not be applied to a single restful request.
        temporary fix: obtain every business transaction for this partner and filter them afterwards...
        TODO AVa2: Introduce ExcludeRangeFilter...
    public getJournalsByPolicyIdByRanges(policyId: string, restProviderActions: RestProviderActions,
                                         allowedTypeIds: FilterTypeId[] = []): Observable<Journal[]> {
        let queryParams = "?";
        for (let i = 0; i < allowedTypeIds.length; i++) {
            const id = allowedTypeIds[i];
            if (i > 0 && i < allowedTypeIds.length) {
                queryParams = queryParams.concat("&");
            }
            queryParams = queryParams
                .concat(`filter={"type":"DefaultRange","key":"typeId","from":"${id.from}","to":"${id.to}"}`);
        }

        return this.rest.get<JournalJson[]>(
            `${environment.settings.BASE_URL}/api/advisor/policies/${policyId}/transactions${queryParams}`,
            null,
            true,
            restProviderActions).map((res) => {
            return Journal.fromJsons(res);
        });
    }
    */

  public getJournalsReferencedByPartnerId(
    partnerID: string,
    restProviderActions: RestProviderActions,
    typeIdFrom: string = null,
    typeIdTo: string = null
  ): Observable<JournalList[]> {
    if (
      !partnerID ||
      !Helper.checkStringInjection(partnerID) ||
      !Helper.checkStringInjection(typeIdFrom) ||
      !Helper.checkStringInjection(typeIdTo)
    ) {
      return of([]);
    }
    let queryParams = "";
    if (typeIdFrom !== null || typeIdTo !== null) {
      queryParams = `?filter={"type":"DefaultRange","key":"typeId","from":"${typeIdFrom}","to":"${typeIdTo}"}`;
    }
    if (queryParams.length > 0) {
      queryParams += "&";
    } else {
      queryParams += "?";
    }
    queryParams += `filter={"type":"Reference","key":"040","value":"${partnerID}"}`;
    return this.rest
      .get<JournalListJson[]>(
        `${environment.settings.BASE_URL}/api/advisor/transactionsWithReferences${queryParams}`,
        null,
        true,
        restProviderActions
      )
      .pipe(map((res) => {
        return JournalList.fromJsons(res);
      }));
  }

  public getCompaniesAndInsurances() {
    return this.rest.get(
      `${environment.settings.BASE_URL}/api/advisor/distinct-riskCarrier`, null, true)
  }

  getDateFilterQuery(from: Date, to: Date) {
    const fromTemp = formatDate(from, "yyyy-MM-dd'T00:00:00.000'", this.locale);
    const toTemp = formatDate(to, "yyyy-MM-dd'T23:59:59.999'", this.locale);
    return {"type":"DateTime","key":"creationDate","from": fromTemp.toString(),"to":toTemp.toString()};
  }
}
