import {
    AfterViewChecked,
    AfterViewInit,
    Component,
    EventEmitter,
    Input, OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ChangeDetectionStrategy,
    ChangeDetectorRef
} from "@angular/core";
import {ControlValueAccessor, FormControl} from "@angular/forms";
import {SlideRangeFilterValue} from "../model/slide-range-filter-value";
import {DatePickerComponent} from "./date-picker/date-picker.component";
import {Subscription, timer} from "rxjs";
import {DatePickerValue} from "../model/datepicker";
import { CanDisable } from "@angular/material/core";

@Component({
    selector: "neomp-slide-range-filter",
    templateUrl: "./slide-range-filter.component.html",
    styleUrls: ["./slide-range-filter.component.scss"]
})
export class SlideRangeFilterComponent implements CanDisable {

    effectiveDateFilter = new FormControl("");

    @Output()
    filterChangeEmitter = new EventEmitter<SlideRangeFilterValue>();

    @Input("filterValue")
    filterValue: SlideRangeFilterValue = new SlideRangeFilterValue();

    @Input("disabled")
    disabled: boolean;

    constructor() {
    }
    
    filterValueChanged(dateChanged: SlideRangeFilterValue): void {
        this.filterValue = dateChanged;
        this.filterChangeEmitter.emit(dateChanged);
    }

    setFilterView() {
        this.filterChangeEmitter.emit(this.filterValue);
    }
}
