import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[neoTooltip]'
})

export class NeoToolTipDirective {
  domElement: any;
  constructor(private elementRef: ElementRef, 
    private renderer: Renderer2) {
this.domElement = this.elementRef.nativeElement;
const elipsifyme = {
'text-overflow': 'ellipsis',
'overflow': 'hidden',
'white-space': 'nowrap',
};
Object.keys(elipsifyme).forEach(element=> {
this.renderer.setStyle(
this.domElement, `${element}`, elipsifyme[element]
);
});
}
ngAfterViewInit(): void {
  // to see effect try removing below two lines and check if the title is added at the first time rendering.   
  this.renderer.setProperty(this.domElement, 'scrollTop', 1);
  this.isTitleAttribute();
}

@HostListener("window:resize", ["$event.target"])
isTitleAttribute() {
  // to add or remove title attribute on the element when it is changing width.
  (this.domElement.offsetWidth < this.domElement.scrollWidth) ?
    this.renderer.setAttribute(this.domElement, 'title', this.domElement.textContent) :
    this.renderer.removeAttribute(this.domElement, 'title');
}
}
