import {Component, Inject, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {KeyValuePair} from "../model/key-value-pair";
import {BusinessTransactionHelper} from "../static/bt-helper";
import {UploadFileComponent} from "./upload-file.component";
import {FileUpload} from "../model/file-upload";
import {Person} from "../model/person";
import {TranslateService} from "@ngx-translate/core";
import {Subject} from "rxjs";
import {log} from "../../../modules/core/providers/logger.provider";
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Title} from "@angular/platform-browser";
import {UserCommitState} from "../model/user-commit-state";
import { MatSnackBar } from "@angular/material/snack-bar";
import {Policy} from "../model/policy";
import { ConfigProvider } from "../providers/config.provider";

export interface ActionDialogData {
    transTypeId: string;
    transName: string;
    transSubject: string;
    transTitle: string;
    transMessage: string;
    operationalIdExternal?: string;
    person?: Person;
    policy?: Policy;
    keyValues?: KeyValuePair[];
    groupId?: string;
    completed?: boolean;
    showSpecificConfigs?: boolean;
    cancellation_approval?: boolean;
}

const TERMIN_LEAD_ACTIONS=[]

@Component({
    selector: "neomp-action-dialog",
    templateUrl: "./action.dialog.component.html",
    styleUrls: ["./action.dialog.component.scss"]
})
export class ActionDialogComponent implements OnInit, OnDestroy {
    @ViewChild(UploadFileComponent) uploadFile: UploadFileComponent;

    private readonly TAG: string = this.constructor.name;

    // observable for file upload callback
    private readonly filesSubject: Subject<FileUpload[]> = new Subject<FileUpload[]>();

    // form control
    formGroupInputs: FormGroup;
    readonly fcnActions = "actions";
    readonly fcnSubjects = "subjects";
    readonly fcnTitle = "title";
    readonly fcnMessage = "message";

    currentAction = "";
    currentSubject = "";
    currentMessage = "";

    allowedFileTypes: string[] | null = null;
    data: ActionDialogData = null;
    subjects: string[] = [];
    keyValuesActionSubjects: { [key: string]: string[] } = {};
    selectedFiles: FileUpload[] = [];

    dialogHeader: string = null;
    dialogInputTitleText: string = null;

    subjectValidators = [Validators.required, Validators.maxLength(50)];
    terminLeadAction = "";
    terminLeadActionSelected = false;
    actionTypeId = "";
    isProductBundle = false;

    constructor(
        public titleService: Title,
        public snackBar: MatSnackBar,
        public formBuilder: FormBuilder,
        public translationService: TranslateService,
        public dialogRef: MatDialogRef<ActionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data: ActionDialogData,
        private config: ConfigProvider
    ) {
        this.data = data;
        if (this.data.showSpecificConfigs) {
            const specificTypeIds = ['591', '594'];
            if (this.data.transTypeId === BusinessTransactionHelper.typeId.cancellationRange.from) {
                this.isProductBundle = true;
                specificTypeIds.forEach(typeId => {
                    this.config.getConfigValue({
                        typeId: typeId,
                    }).subscribe((value: { selectedType: string; data: KeyValuePair[] }): any => {
                        this.terminLeadAction = value.data[0].key;
                        this.data.keyValues = [...this.data.keyValues, ...value.data];
                    });
                });
    
            }
        }
        else {        
            if (this.data.transTypeId === BusinessTransactionHelper.typeId.productBundle.from) {
                this.isProductBundle = true;
                this.config.getConfigValue({typeId: BusinessTransactionHelper.typeId.offerPolicy.from.toString()}).subscribe((value: { selectedType: string; data: KeyValuePair[] }): any => {
                    this.terminLeadAction = value.data[0].key;
                    this.data.keyValues = [...this.data.keyValues, ...value.data];
                    const subjectActions = value.data[0].value.split(",");
                    const offerPolicyKeys = Object.keys(
                    BusinessTransactionHelper.typeId.offerPolicy
                    );
                    for (let i = 0; i < offerPolicyKeys.length; i++) {
                        TERMIN_LEAD_ACTIONS.push({
                            key: BusinessTransactionHelper.typeId.offerPolicy[offerPolicyKeys[i]],
                            value: subjectActions[i],
                        });
                    }
                });
            }
        }
        this.applyDialogValues(this.data, this.titleService.getTitle());
    }

    ngOnInit(): void {
        this.initFormValidation();
    }

    ngOnDestroy(): void {
        this.filesSubject.unsubscribe();
    }
    
    exitDialogAndBuildActionData() {
        if (this.formGroupInputs.invalid) {
            this.validateForm(this.formGroupInputs);
            log.debug(`${this.TAG} exitDialogAndBuildActionData() -> ignored due to invalid`);
            return;
        }

        this.filesSubject.asObservable().subscribe(files => {
            log.debug(`${this.TAG}: exitDialogAndBuildActionData() -> filesSubject.subscribe -> fileCount=${files.length}`);
            this.selectedFiles = files;

            this.data.transTitle = this.dialogInputTitleText;
            this.data.transMessage = this.currentMessage;
            this.data.transName = this.currentAction;
            this.data.transSubject = this.currentSubject;
            const subjectValue = this.formGroupInputs.controls["subjects"].value;

            const action = BusinessTransactionHelper.createActionObject(
                this.data.transName,
                this.terminLeadActions.map(v=>v.value).includes(subjectValue) ? this.terminLeadActions.find(s=>s.value === subjectValue)?.key : this.data.transTypeId,
                this.data.transSubject,
                this.data.transTitle,
                this.data.transMessage,
                this.data.operationalIdExternal,
                this.selectedFiles,
                this.data.person,
                this.data.policy,
                this.data.groupId,
                this.data.completed
            );
            log.debug(`${this.TAG} exitDialogAndBuildActionData() -> built action -> action=${action}`);
            this.closeDialog(action);
        });
        log.debug(`${this.TAG} exitDialogAndBuildActionData() -> resetting file upload component`);
        this.uploadFile.readFiles();
    }

    cancelDialog() {
        log.debug(`${this.TAG} cancelDialog()`);
        this.closeDialog(false);
    }

    closeDialog(value?: any) {
        log.debug(`${this.TAG} closeDialog() -> value=${value}`);
        this.dialogRef.close(value);
    }

    uploadedFiles(uploadFiles: FileUpload[]) {
        log.debug(`${this.TAG} uploadedFiles() -> fileCount=${uploadFiles.length}`);
        this.filesSubject.next(uploadFiles);
    }

    private applyDialogValues(data: ActionDialogData, contextTitle: string) {
        this.translationService.get(
            "DIALOG.ACTION.HEADER",
            {
                context: contextTitle, transactionId: data.transTypeId, transactionName: data.transName
            })
            .subscribe(result => {
                this.dialogHeader = result;
            });

        this.translationService.get(
            "DIALOG.ACTION.INPUT.TEXT.TITLE",
            {transactionTitle: data.transTitle})
            .subscribe(result => {
                this.dialogInputTitleText = result;
            });

        this.data.keyValues.forEach(v => {
            
            this.keyValuesActionSubjects[v.key] = v.value.split(",");
        });

        if (this.data.keyValues.length === 0) {
            this.translationService.get("COMMONS.LOADINGSTATUS.NODATA")
                .subscribe(result => {
                    this.snackBar.open(result, "x", UserCommitState.configCheckData);
                });
        }
    }

    private initFormValidation() {
        this.formGroupInputs = new FormGroup({
            title: new FormControl(this.fcnTitle, {
                validators: [Validators.required, Validators.maxLength(50)],
                updateOn: "blur"
            }),
            message: new FormControl("", {
                validators: [Validators.required, emptyValidator],
                updateOn: "blur"
            }),
            filesUploaded: new FormControl(false, {
                validators: [],
                updateOn: "blur"
            }),
            actions: new FormControl("", {validators: [Validators.required], updateOn: "blur"}),
            subjects: new FormControl("", {validators: [Validators.required], updateOn: "blur"}),
        });
    }

    private validateForm(fg: FormGroup) {
        Object.keys(fg.controls).forEach(field => {
            const control = this.formGroupInputs.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({onlySelf: true});
            } else if (control instanceof FormGroup) {
                this.validateForm(control);
            }
        });
    }

    public static getConfigWithData(data: ActionDialogData): MatDialogConfig<ActionDialogData> {
        const cfg = new MatDialogConfig<ActionDialogData>();
        cfg.autoFocus = true;
        cfg.hasBackdrop = true;
        cfg.panelClass = "neomp-dialog-padding-none";
        cfg.closeOnNavigation = true;
        cfg.data = data;
        return cfg;
    }

    public onSelectAction(event) {
        log.debug(`onSelectAction() -> eventSourceValue=${event.source.value}`);
        if (event.isUserInput) {
            if(this.isProductBundle) {
                // Get the typeID when chaning the action
                this.config
                .getConfig()
                .subscribe(
                (res: any) => {
                    this.actionTypeId = res.find(r=> r.values?.map(v=>v.key).includes(event.source.value)).key;
                    this.data.transTypeId=this.actionTypeId;
                })
                this.allowedFileTypes = ['561', '562'].includes(this.actionTypeId) ? ['.pdf'] : null;
                this.translationService.get(
                    "DIALOG.ACTION.HEADER",
                    {
                        context: this.titleService.getTitle(), transactionId: this.actionTypeId, transactionName: this.data.transName
                    })
                    .subscribe(result => {
                        this.dialogHeader = result;
                    });

                this.terminLeadActionSelected = event.source.value === this.terminLeadAction;
                //this condition is used to prevent changing placeholder name for message 
                if(this.data.showSpecificConfigs){
                    this.terminLeadActionSelected=false;
                }
                if(this.terminLeadActionSelected){
                    this.formGroupInputs.controls["filesUploaded"].addValidators(Validators.requiredTrue);
                }else{
                    this.formGroupInputs.controls["filesUploaded"].clearValidators();
                }
                if(this.actionTypeId==='590' || this.actionTypeId==='591' || this.actionTypeId==='592' || this.actionTypeId==='593' || ['561', '562'].includes(this.actionTypeId)){
                    this.formGroupInputs.controls["filesUploaded"].addValidators(Validators.requiredTrue);
                } else {
                    this.formGroupInputs.controls["filesUploaded"].clearValidators();
                }
                
                if (this.actionTypeId === '590' || this.actionTypeId==='591' || this.actionTypeId==='592' || this.actionTypeId==='593' || this.actionTypeId === '594') {  
                    this.formGroupInputs.controls["message"].clearValidators();
                } else {
                    this.formGroupInputs.controls["message"].setValidators([Validators.required, emptyValidator]);
                }
                this.formGroupInputs.controls["message"].updateValueAndValidity();
                this.formGroupInputs.controls["filesUploaded"].updateValueAndValidity();
            }
            this.currentAction = event.source.value;
            this.subjects = this.keyValuesActionSubjects[this.currentAction];

            if (this.subjects.length === 0) {
                this.translationService.get("COMMONS.LOADINGSTATUS.NODATA")
                    .subscribe(result => {
                        this.snackBar.open(result, "x", UserCommitState.configCheckData);
                    });
                return;
            }
        }
    }

    public onSelectSubject(event) {
        log.debug(`onSelectSubject() -> eventSourceValue=${event.source.value}`);
        if (event.isUserInput) {
            this.currentSubject = event.source.value;

            if (this.isProductBundle) {
                if(event.source.value==="1. KVG"){
                    this.data.transTypeId = "591";
                }
                if(event.source.value==="2. VVG"){
                    this.data.transTypeId = "592";
                }
                if(event.source.value==="3. KVG & VVG"){
                    this.data.transTypeId = "593";
                }
                
                this.actionTypeId = this.terminLeadActions.find(a=>a.value === event.source.value)?.key || this.data.transTypeId;

                this.allowedFileTypes = ['561', '562'].includes(this.actionTypeId) ? ['.pdf'] : null;
                this.translationService.get(
                    "DIALOG.ACTION.HEADER",
                    {
                        context: this.titleService.getTitle(), transactionId: this.actionTypeId, transactionName: this.data.transName
                    })
                    .subscribe(result => {
                        this.dialogHeader = result;
                    });
            }
        }
    }

    get terminLeadActions(){
        return TERMIN_LEAD_ACTIONS
    }
}

function emptyValidator(control: AbstractControl) {
    if (control.value.toString().trim().length === 0) {
        return {isEmpty: true};
    }
    return null;
}
