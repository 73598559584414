import {ProductJson} from "../json/product.json";
import {BasicProduct} from "./basic-product";

export class Product {
    lineOfBusiness = "";
    name = "";
    shortName = "";
    line = "";
    dateOfStart = "";
    dateOfTermination = "";
    netPremium = "";
    productLine = "";
    riskCarrier = "";
    basicProducts: BasicProduct[] = [];

    public constructor(value?: ProductJson) {
        if (value) {
            this.lineOfBusiness = value.lineOfBusiness || "";
            this.name = value.name || "";
            this.shortName = value.shortName || "";
            this.line = value.line || "";
            this.dateOfStart = value.dateOfStart || "";
            this.dateOfTermination = value.dateOfTermination || "";
            this.netPremium = value.netPremium || "";
            this.productLine = value.productLine || "";
            this.riskCarrier = value.riskCarrier || "";
            this.basicProducts = BasicProduct.fromJsons(value.basicProducts);
        }
    }

    public static fromJson(response: ProductJson): Product {
        return new Product(response);
    }

    public static fromJsons(response: ProductJson[]): Product[] | undefined  {
        if (response) {
            return response.map(product=> new Product(product));
        }
    }
}
