import {AfterViewInit, Component, OnInit, ViewChild} from "@angular/core";
import {DocumentProvider} from "../../core/providers/document.provider";
import {DocumentResourceResult} from "../../core/model/document-resource-result";
import {LoadingDataComponent} from "../../core/components/loading-data.component";
import {PolicyProvider} from "../../core/providers/policy.provider";
import {RestProviderActionsDummy, ValidationCallback} from "../../core/providers/rest.provider";
import {Policy} from "../../core/model/policy";
import {ActivatedRoute, Router} from "@angular/router";
import {HouseholdProvider} from "../../core/providers/household.provider";
import {JournalProvider} from "../../core/providers/journal.provider";
import {Journal} from "../../core/model/journal";
import {Breadcrumb} from "../../core/model/breadcrumb";
import {TranslateService} from "@ngx-translate/core";
import {RegisterProvider} from "../../core/providers/register.provider";
import {Household} from "../../core/model/household";
import {DocumentResourceJson} from "../../core/json/document.resource.json";
import {forkJoin, Observable} from "rxjs";
import {ActionDialogService} from "../../core/providers/action-dialog.service";
import {ActionDialogData} from "../../core/components/action.dialog.component";
import {BusinessTransactionHelper} from "../../core/static/bt-helper";
import {Title} from "@angular/platform-browser";
import {Helper} from "../../core/static/helper";
import {map} from "rxjs/operators";
import {Person} from "app/modules/core/model/person";
import { LoadingService } from "app/modules/core/providers/loading.component";
import { MatDialog } from "@angular/material/dialog";
import { KeycloakService } from "keycloak-angular";
import { AgenturType } from "app/modules/core/model/enum-agenturtype";

@Component({
    selector: "neomp-policy-detail-page",
    templateUrl: "./policy-detail-page.component.html",
    styleUrls: ["./policy-detail-page.component.scss"]
})
export class PolicyDetailPageComponent implements OnInit {
    private readonly TAG = this.constructor.name;

    breadcrumbs: Breadcrumb[] = [];
    journals: Journal[] = [];
    documentResources: DocumentResourceResult[] = [];
    policy: Policy;
    policyId: string;
    partnerId: string;
    selectedPerson: Person = null;
    selectedId: string;
    mandateGiven = false;
    mandateDissolved = false;
    statusActive = false;
    isDialogOpen = false;
    validationCallback: ValidationCallback;
    household: Household;
    offerURL: string;
    agenturType: string = "";
    isClient: Boolean = false;

    @ViewChild("documentTable") documentTableLoadingComponent: LoadingDataComponent;
    @ViewChild("journalTable") journalTableLoadingComponent: LoadingDataComponent;

    journalData: Map<string, DocumentResourceResult[]> = new Map<string, DocumentResourceResult[]>();

    constructor(public documentProvider: DocumentProvider,
                public policyProvider: PolicyProvider,
                public keycloak: KeycloakService,
                public journalProvider: JournalProvider,
                public householdProvider: HouseholdProvider,
                public route: ActivatedRoute,
                public router: Router,
                public translateService: TranslateService,
                public registerProvider: RegisterProvider,
                public dialogService: ActionDialogService,
                public titleService: Title,
                private loadService: LoadingService,
                public dialog: MatDialog) {

        this.validationCallback = (data => {
            // additional check to figure out if we got any documents
            return (data as DocumentResourceJson).results.length > 0;
        });
    }

    async ngOnInit() {
        // this does not work, because the provider does not return the params if they are
        // defined through the path
        // const paramNames = this.registerProvider.getPathParamForKey("MyCustomersPolicyDetail");

        let res = await this.keycloak.loadUserProfile() as any;
        this.agenturType = res.attributes.agenturType[0];
        this.isClient = this.agenturType === AgenturType.Client;

        const policyId = this.route.snapshot.paramMap.get("policyid");
        const representativeUuid = this.route.snapshot.paramMap.get("representativeuuid");
        const selectedUuid = this.route.snapshot.paramMap.get("selecteduuid");

        this.policyId = policyId;
        this.selectedId = selectedUuid
        this.partnerId = this.selectedId || representativeUuid;
        if (!this.partnerId || !this.policyId) {
            this.router.navigateByUrl(this.registerProvider.getPath("MyCustomers")).then();
        }


            this.householdProvider.getCompleteHouseholdByUuid(representativeUuid, new RestProviderActionsDummy()).subscribe(household => {
                this.breadcrumbs.push({
                    label: this.isClient ? "PAGE.CONTAINER.CUSTOMERS.CONTAINER.CLIENTHEADLINE" : "PAGE.CONTAINER.CUSTOMERS.CONTAINER.HEADLINE",
                    url: "/" + this.registerProvider.getPath("MyCustomers")
                });

                this.household = household;
                this.selectedPerson = this.household.getPartnerById(this.selectedId);
                this.breadcrumbs.push({
                    label: `${household.representant.fullName} ${this.selectedPerson ? "- " + this.selectedPerson.fullName : ""}`,
                    url: `/${this.registerProvider.getPath("MyCustomers")}/detail/` +
                        `${household.representant.uuid}${selectedUuid ? "/" + selectedUuid : ""}`
                });

                this.getPolicies();
                this.loadService.dialogClosed.subscribe((val: boolean) => {
                    if(val === true){
                        this.getJournalsExcludeProvisionsAndCustomers();
                    }
                });
                this.getJournalsExcludeProvisionsAndCustomers();
                this.offerURL = this.getUrl("Offers");

            });
            this.getDocuments();
    }

    getPolicies() {

        this.policyProvider.getProductBundle(this.partnerId, this.policyId, new RestProviderActionsDummy())
            .subscribe(policy => {
                this.policy = policy;
                if (this.policy.status && this.policy.status.key === "1") {
                    this.statusActive = true;
                }
                if (this.household.representant.approvals) {
                    const approval = this.household.representant.approvals.find(x => x.reference === this.policyId);
                    if (approval) {
                        this.mandateGiven = approval.approved;
                        this.mandateDissolved = this.mandateGiven === false;
                    }
                }
                this.breadcrumbs.push({label: `${policy.name} ${policy.policyNumber}`});

            });
    }

    getDocuments() {
        this.documentTableLoadingComponent.applyValidationCallback(this.validationCallback);
        this.documentProvider.getDocumentsFromPolicy(this.policyId, this.documentTableLoadingComponent)
        .subscribe(documentResources => {
                this.documentResources = this.documentResources.concat(documentResources)
            });
    }

    getJournalsExcludeProvisionsAndCustomers() {

        const sort = { creationDate: -1 };
        // As desired by stakeholder, we must exclude transactions which are defined as provisions or Customers
        this.journalProvider.getJournalsByPolicyId(this.policyId, this.journalTableLoadingComponent, sort)
            .subscribe(journals => {
                this.journals = journals ? journals : [];
                this.journals = this.journals.filter(j => {
                    // using unary operator for parsing string to number; in case of NaN this will return 0
                    // A3N Deprecated 
                    // const minProvisions = +BusinessTransactionHelper.typeId.provision.from;
                    // const maxProvisions = +BusinessTransactionHelper.typeId.provisionPendingMatter.to;
                    const minCustomer = +BusinessTransactionHelper.typeId.customer.from;
                    const maxCustomer = +BusinessTransactionHelper.typeId.customer.to;
                    const v = +j.typeId;
                    // A3N Deprecated 
                    // if(v >= minProvisions && v <= maxProvisions)
                    //     return false;
                    if(v >= minCustomer && v <= maxCustomer)
                        return false

                    return true
                });

                let journalData: Map<string, any> = new Map<string, any>();
                let subscriptions: Observable<any>[] = [];
                for (let journal of this.journals) {
                    let references = journal.references.filter(reference => reference.type.key === "050");
                    for (let reference of references) {
                        const subscription = this.documentProvider.getDocumentById(
                            reference.id,
                            new RestProviderActionsDummy())
                            .pipe(
                                map(result => ({id: journal.uuid, document: result}))
                            );
                        subscriptions.push(subscription);
                    }

                    // type "MyNeoDocument" refers to a temporary document
                    let tempDocumentReferences = journal.references.filter(reference => reference.type.key === "MyNeoDocument");
                    for (let tempDocumentReference of tempDocumentReferences) {
                        const subscription = this.documentProvider.getDocumentFromMyNeo(
                            tempDocumentReference.id,
                            new RestProviderActionsDummy())
                            .pipe(
                                map(result => ({id: journal.uuid, document: result}))
                            );
                        subscriptions.push(subscription);
                    }
                }

                forkJoin(subscriptions).subscribe(results => {
                    let journalData: Map<string, DocumentResourceResult[]> = new Map<string, DocumentResourceResult[]>();
                    for (let result of results) {
                        if (!journalData.has(result.id)) {
                            journalData.set(result.id, [result.document]);
                        } else {
                            journalData.get(result.id).push(result.document);
                        }
                        
                        // Add temporary documents to documents dataSource
                        if (!this.journalData.has(result.id) && result.document.fromMyNeo) {
                            this.documentResources = this.documentResources.concat(result.document);
                        }
                    }
                    this.journalData = journalData;
                });
            });
    }

    openOfferPage() {
        this.router.navigate([this.offerURL]).then();
    }

    private getUrl(routerName: string) {
        return `/${this.registerProvider.getPath(routerName)}/` +
            `${this.partnerId}/` +
            `${this.partnerId === this.selectedId ? "" : this.partnerId}`;
    }

    openActionDialog(event: Event) {
        this.isDialogOpen = true;
        event.stopPropagation();
        const dialogData: ActionDialogData = {
            transTypeId: BusinessTransactionHelper.typeId.productBundle.from,
            transName: this.titleService.getTitle(),
            transSubject: "",
            transTitle: this.titleService.getTitle(),
            transMessage: "",
            keyValues: [],
            person: null,
            policy: this.policy
        };
        this.dialogService.openByType(dialogData, 
            {
                defaultTypeId: BusinessTransactionHelper.typeId.productBundleRange.from
            }, this.TAG
        );
        this.dialog.afterAllClosed.subscribe(() => {
            this.isDialogOpen = false;
        });
    }

    formatDate(date) {
        return Helper.formatDate(date);
    }

    actionDataCallback(actionDialogData: ActionDialogData) {
        actionDialogData.policy = this.policy;
    }
}
