import { MatSnackBarConfig } from "@angular/material/snack-bar";

export class UserCommitState {
    static configDefault: MatSnackBarConfig = {
        panelClass: ['msg-snack-bar-default'],
        duration: 4000,
        verticalPosition: "top"
    };

    static configSuccess: MatSnackBarConfig = {
        panelClass: ['msg-snack-bar-success'],
        duration: 4000,
        verticalPosition: "top"
    };

    static configCheckData: MatSnackBarConfig = {
        panelClass: ['msg-snack-bar-checkdata'],
        duration: 4000,
        verticalPosition: "top"
    };
    static configError: MatSnackBarConfig = {
        panelClass: ['msg-snack-bar-error'],
        duration: 4000,
        verticalPosition: "top"
    };
}
