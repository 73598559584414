<mat-tab-group>
    <mat-tab label="{{ 'PAGE.CONTAINER.COMMISSIONS.TAB.PROVISIONS' | translate}}">
        <div class="mdc-layout-grid msg-helper-padding-none msg-helper-margin-top-10">
            <div class="mdc-layout-grid__inner">
                <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop
                            mdc-layout-grid__cell--span-12-tablet
                            mdc-layout-grid__cell--span-12-phone">
                    <neomp-provision></neomp-provision>
                </div>
            </div>
        </div>
    </mat-tab>
    <mat-tab label="{{ 'PAGE.CONTAINER.COMMISSIONS.TAB.PROVISIONSACCOUNTING' | translate}}">
        <div class="mdc-layout-grid msg-helper-padding-none msg-helper-margin-top-10">
            <div class="mdc-layout-grid__inner">
                <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop
                            mdc-layout-grid__cell--span-12-tablet
                            mdc-layout-grid__cell--span-12-phone">
                    <neomp-provision-accounting></neomp-provision-accounting>
                </div>
            </div>
        </div>
    </mat-tab>
    <mat-tab label="{{ 'PAGE.CONTAINER.COMMISSIONS.TAB.PROVISIONSPENDINGMATTER' | translate}}">
        <div class="mdc-layout-grid msg-helper-padding-none msg-helper-margin-top-10">
            <div class="mdc-layout-grid__inner">
                <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop
                            mdc-layout-grid__cell--span-12-tablet
                            mdc-layout-grid__cell--span-12-phone">
                    <neomp-provision-pending-matter></neomp-provision-pending-matter>
                </div>
            </div>
        </div>
    </mat-tab>
</mat-tab-group>
