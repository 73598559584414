
<div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop
                            mdc-layout-grid__cell--span-12-tablet
                            mdc-layout-grid__cell--span-12-phone
                            mdc-layout-grid--align-right
                            filter-style">
    <neomp-slide-range-filter
        [disabled]="isDataLoading"
        [filterValue]="filterValue"
        (filterChangeEmitter)="getElements($event)">
    </neomp-slide-range-filter>
</div>
<div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop
                            mdc-layout-grid__cell--span-12-tablet
                            mdc-layout-grid__cell--span-12-phone">
  <neomp-container cssClass="msg-mp-container-frameless"
    headlineText="{{ 'PAGE.CUSTOMERDETAIL.JOURNAL.TITLE' | translate }}">
    <neomp-journal-table [tableEntries]="journals" [isGMOffer]=true></neomp-journal-table>
    <neomp-loading-data #loadingDataComponent></neomp-loading-data>
  <neomp-paginator
  [length]="totalCount" 
  [pageSize]="pageSize" 
  [pageIndex]="pageIndex"
  [pageSizeOptions]="pageSizeOptions" 
  (paginatorChange)="paginatorValueChanged($event)"
  >  
  </neomp-paginator>   
  </neomp-container>
</div>