<neomp-container [isLightContainer]="isNested" [showHeadline]="showHeadline" [headlineText]="headlineText" headlineText
                 cssClass="msg-mp-container-frameless {{shadow ? '' : 'no-shadow'}}">
    <!--TODO SORT and delete-->
    <div *ngIf="files.length > 0">
        <table [dataSource]="dataSource" class="msg-helper-width-100" mat-table>
            <ng-container matColumnDef="name">
                <th *matHeaderCellDef mat-header-cell>{{ 'PAGE.OFFER.DOCUMENTNAME' | translate }}</th>
                <td *matCellDef="let element" mat-cell>{{element.name}}</td>
            </ng-container>
            <ng-container matColumnDef="type">
                <th *matHeaderCellDef mat-header-cell>{{ 'PAGE.OFFER.DOCUMENTTYPE' | translate }}</th>
                <td *matCellDef="let element" mat-cell> {{element.type}}</td>
            </ng-container>
            <ng-container matColumnDef="size">
                <th *matHeaderCellDef mat-header-cell>{{ 'PAGE.OFFER.DOCUMENTSIZE' | translate }}</th>
                <td *matCellDef="let element" mat-cell>{{ element.size / 1024 / 1024 | number:'.2' }} MB</td>
            </ng-container>
            <ng-container matColumnDef="filter">
                <th *matHeaderCellDef mat-header-cell></th>
                <td mat-cell *matCellDef="let element" (click)="removeFile(element)">
                    <div class="neomp-table-column-filter-element mdi mdi-delete"></div>
                </td>
            </ng-container>
            <tr *matHeaderRowDef="['name', 'type', 'size', 'filter'];"
                mat-header-row></tr>
            <tr *matRowDef="let product; columns: ['name', 'type', 'size', 'filter'];"
                class="container-table-row" mat-row></tr>
        </table>
    </div>
    <div *ngIf="lastInvalids != null && lastInvalids.length > 0" class="msg-loadingstatus msg-loadingerror">
        <div class="mdi mdi-alert-circle-outline">
            <span>{{ 'PAGE.OFFER.ERRORMESSAGE' | translate }} | </span>
            <span>{{ 'PAGE.OFFER.MAXFILECOUNT' | translate }} {{maxCount}} | </span>
            <span>{{ 'PAGE.OFFER.MAXFILESIZE' | translate }}{{maxSize / 1024 | number:'1.0-0' }} KB </span>
        </div>
    </div>
    <div
        ngfDrop
        selectable="1"
        multiple="1"
        [(validDrag)]="validComboDrag"
        [(files)]="files"
        [accept]="accept"
        [maxSize]="maxSize"
        [(lastInvalids)]="lastInvalids"
        [(dragFiles)]="dragFiles"
        (filesChange)="changeFiles()"
        class="well my-drop-zone {{files.length == 0 ? 'init-drop-zone' : 'table-drop-zone'}}"
        [class.invalid-drag]="validComboDrag===false"
        [class.valid-drag]="validComboDrag">
            <span class="mdi mdi-cloud-upload msg-helper-justify-items-center
                  {{files.length == 0 ? 'msg-helper-icon-font-size-max justify-component' : 'msg-helper-icon-font-size-min'}}">
            </span>
        <span
            class="{{files.length == 0 ? 'justify-component msg-helper-margin-top-10' : 'msg-helper-margin-vertical-auto msg-helper-margin-left-10'}}">
                {{ 'PAGE.OFFER.UPLOADDECISIONFULL' | translate }}
            </span>
    </div>
    <div class="neomp-button-bar">
        <button
            ngfSelect
            multiple
            [(files)]="files"
            [accept]="accept"
            [maxSize]="maxSize"
            [(lastInvalids)]="lastInvalids"
            (filesChange)="changeFiles()"
            class="neomp-button-bar-buttons" color="secondary"
            mat-raised-button>{{ 'PAGE.OFFER.UPLOADBUTTON' | translate | uppercase }}</button>
    </div>
</neomp-container>