import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { RegisterProvider } from "../../core/providers/register.provider";
import { LoadingDataComponent } from "../../core/components/loading-data.component";
import { PolicyProvider } from "../../core/providers/policy.provider";
import { Policy } from "../../core/model/policy";
import { SlideRangeFilterValue } from "../../core/model/slide-range-filter-value";
import { Observable, Subscription, timer } from "rxjs";
import { Journal } from "../../core/model/journal";
import * as moment from "moment";
import { DatePickerValue } from "../../core/model/datepicker";
import { PaginationConfigs } from "app/modules/constants/PaginationConstants";
import { FormControl } from "@angular/forms";
import { KeyValue } from "app/modules/core/model/key-value";
import { ConfigProvider } from "app/modules/core/providers/config.provider";

@Component({
    selector: "neomp-applications-page",
    templateUrl: "./applications-page.component.html",

    styleUrls: ["./applications-page.component.scss"]
})
export class ApplicationsPageComponent implements OnInit {
    pageSize = PaginationConfigs.pageSize;
    pageSizeOptions = PaginationConfigs.pageSizeOptions;
    pageIndex = PaginationConfigs.pageIndex;
    totalCount = 0;
    private readonly TIMERINTERVAL: number = 1000 * 60 * 15;

    allStatuses: KeyValue[] = [];
    statusesList: KeyValue[] = [];
    policies: Policy[] = [];
    filterValue: SlideRangeFilterValue = new SlideRangeFilterValue();
    timerSubscription: Subscription;
    isInitialized: boolean;
    isDataLoading: boolean;
    searchText: string | null = null;
    statusSelected = new FormControl("");
    subscriptions: Subscription[] = [];

    @ViewChild("applicationsTable") applicationsTableLoadingComponent: LoadingDataComponent;

    constructor(public policyProvider: PolicyProvider,
        public route: ActivatedRoute,
        public router: Router,
        public location: Location,
        private configProvider: ConfigProvider,
        public registerProvider: RegisterProvider) {
        this.isInitialized = false;
        this.timerSubscription = timer(this.TIMERINTERVAL, this.TIMERINTERVAL)
            .subscribe(value =>
                this.getElements(this.isInitialized ? this.filterValue : null));
    }

    ngOnInit() {
        this.configProvider.getApplicationStatuses().subscribe((statuses: KeyValue[]) => {
            this.allStatuses = statuses;
            this.allStatuses.sort((a, b) => {
                return Number(a.key) - Number(b.key)
            })
            this.statusesList = this.allStatuses;
        })

        this.filterValue.isActive = true;
        this.filterValue.dateRange.end = moment();
        this.filterValue.dateRange.begin = moment().subtract(60, "days");

        this.route.queryParams
            .subscribe((params) => {
                this.searchFromParams(params);
            })

    }

    ngAfterViewInit(): void {
        this.isInitialized = true;
    }

    getElements(filterValue) {
        this.filterValue = filterValue;
        this.clearSubscriptions();
        this.subscriptions.push(
        this.route.queryParams
            .subscribe((params) => {
                this.searchFromParams(params);
            })
        )
    }

    getPolicies(
        dateRange: DatePickerValue,
        page: number,
        size: number,
        filter?: string) {
        this.dataLoading = true;
        this.policyProvider.getPolicies(this.applicationsTableLoadingComponent, dateRange, page, size, filter)
            .subscribe(res => {
                this.policies = res.data.map(this.mapLeadOrEigenterminPolicy);
                this.totalCount = res.totalCount;
                this.dataLoading = false;
            });
    }

    getPoliciesBySearch(
        dateRange: DatePickerValue,
        page: number,
        size: number,
        searchText: string,
        statusList: string[],
        filter?: string) {
        this.dataLoading = true;
        this.policyProvider.getPoliciesBySearch(this.applicationsTableLoadingComponent, dateRange, page, size, searchText, statusList, filter)
            .subscribe(res => {
                this.policies = res.data.map(this.mapLeadOrEigenterminPolicy);
                this.totalCount = res.totalCount;
                this.dataLoading = false;
            });
    }

    set dataLoading(dataLoading: boolean) {
        if (this.isInitialized) {
            this.isDataLoading = dataLoading;
        }
    }

    search() {
        var statusSelected = Array.isArray(this.statusSelected.value) ? this.statusSelected.value.join(',') : "";
        let filter = '';
        filter = `status=${statusSelected}`;
        if (![null, undefined, ""].includes(this.searchText)) {
            filter += `&text=${this.searchText}`
        }

        this.getPoliciesBySearch(
            this.filterValue.isActive ? this.filterValue.dateRange : null,
            this.pageIndex,
            this.pageSize,
            this.searchText,
            this.statusSelected.value,
            filter
        );
    }

    searchFilter() {
        var statusSelected = Array.isArray(this.statusSelected.value) ? this.statusSelected.value.join(',') : "";
        let filter = '';
        filter = `status=${statusSelected}`;
        if (![null, undefined, ""].includes(this.searchText)) {
            filter += `&text=${this.searchText}`
        }
        this.router.navigate(["/applications"], {
            queryParams: {
                status: JSON.stringify(this.statusSelected.value),
                text: this.searchText,
                pageIndex: PaginationConfigs.pageIndex,
                pageSize: this.pageSize
            }
        })
        this.clearSubscriptions();
    }

    clearSearch() {
        this.searchText = '';
        this.statusSelected.reset();
        this.clearSubscriptions();
        this.router.navigate(["/applications"], {
            queryParams: {
                status: "",
                text: "",
                pageIndex: this.pageIndex,
                pageSize: this.pageSize
            }
        })
    }

    searchFromParams(params?): void {
        this.searchText = '';
        this.statusSelected.reset();
        this.pageIndex = Number(params.pageIndex) || PaginationConfigs.pageIndex;
        this.pageSize = Number(params.pageSize) > 0 ? Number(params.pageSize) : PaginationConfigs.pageSize;
        if (params.text) {
            this.searchText = params.text;
        }
        if (params.status) {
            this.statusSelected.setValue(JSON.parse(params.status));
        }
        this.search();
    }

    paginatorValueChanged(event) {
        if (Number(this.pageIndex) === Number(event.pageIndex) && Number(this.pageSize) === Number(event.pageSize)) return;
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
        this.router.navigate(["/applications"], {
            queryParams: {
                status: JSON.stringify(this.statusSelected.value),
                text: this.searchText,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize
            }
        })
    }

    clearSubscriptions() {
        this.subscriptions.forEach(s => s.unsubscribe());
        this.subscriptions = [];
    }

    mapLeadOrEigenterminPolicy(policy) {
        if(policy.sorKeys.leadId && (policy.sorKeys.leadId !== '0' && policy.sorKeys.leadId !== "old")){
            policy.sorKeys.leadOrEigenterminConnection = 'lead';
        } else if (policy.sorKeys.ownLead === '1' && (policy.sorKeys.leadId === '0' || policy.sorKeys.leadId === "old")){
            policy.sorKeys.leadOrEigenterminConnection = 'eigentermin';
        }
        else {
            policy.sorKeys.leadOrEigenterminConnection = null;
        }
        return policy;
    }

    ngOnDestroy(): void {
        this.timerSubscription.unsubscribe();
        this.clearSubscriptions();
    }
}
