import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UploadFileComponent } from 'app/modules/core/components/upload-file.component';
import { MatTable } from '@angular/material/table';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LEADS_CONFIGS } from '../../configs/lead-configs';
import { ActivatedRoute, NavigationExtras, ParamMap, Router } from '@angular/router';
import { CommunicationAddress } from 'app/modules/core/model/communication-address';
import { CommAddrTypes } from 'app/modules/core/model/enum-commaddrtype';
import { Person } from 'app/modules/core/model/person';
import { Subscription } from 'rxjs';
import { log } from 'app/modules/core/providers/logger.provider';
import { LeadsService } from '../../services/leads.service';
import { ConfigProvider } from 'app/modules/core/providers/config.provider';
import { KeyValuePair } from 'app/modules/core/model/key-value-pair';
import { ComponentCanDeactivate } from 'app/modules/core/guards/pending-changes.guard';
import { BusinessTransactionHelper } from 'app/modules/core/static/bt-helper';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserCommitState } from 'app/modules/core/model/user-commit-state';
import { LeadDetails } from '../../Interfaces/LeadDetails';
import { SDAKey } from 'app/modules/core/model/sdakey';
import { KeycloakService } from 'keycloak-angular';
import moment from 'moment';
import { Title } from '@angular/platform-browser';
import { Config } from 'app/modules/core/model/config';
import { RemindersList } from '../../Interfaces/RemindersList';
import { LeadLog } from 'app/modules/core/model/LeadLog.enum';
import { AppointmentProvider } from 'app/modules/core/providers/appointment.provider';
import { ActionDialogService } from 'app/modules/core/providers/action-dialog.service';
import { LoadingService } from 'app/modules/core/providers/loading.component';
import { TranslateService } from '@ngx-translate/core';
import { Journal } from 'app/modules/core/model/journal';
import { GMOfferDialogComponent } from 'app/modules/shared/components/gmoffer-dialog/gmoffer-dialog.component';
import { AdvisorService } from 'app/modules/core/providers/advisor.service';
import { MatDialog } from '@angular/material/dialog';
import { KeyValue } from 'app/modules/core/model/key-value';
import { PaginationConfigs } from 'app/modules/constants/PaginationConstants';
import { RestProviderActions } from 'app/modules/core/providers/rest.provider';
import { PageEvent } from '@angular/material/paginator';
import { LoadingDataComponent } from 'app/modules/core/components/loading-data.component';
import { LeadProvider } from 'app/modules/core/providers/lead.provider';
import { AgenturType } from 'app/modules/core/model/enum-agenturtype';
@Component({
  selector: 'neomp-leads-edit-termin',
  templateUrl: './leads-edit-termin.component.html',
  styleUrls: ['./leads-edit-termin.component.scss']
})
export class LeadsEditTerminComponent implements OnInit, ComponentCanDeactivate {

  @Input() leadView = false;
  @Output() emitNewLog = new EventEmitter<any>();
  @ViewChild(UploadFileComponent)
  uploadFile: UploadFileComponent;
  @ViewChild("loadingLogsTable") loadingLogsTable: LoadingDataComponent;

  @ViewChild(MatTable)
  table: MatTable<FamilyMember>;

  subscriptions: Subscription[] = [];
  lead: Person | null = null;
  reminderCategories: KeyValuePair[] = [];
  displayedColumns: string[] = ['name', 'gender', 'birthdate', 'nationality', 'action'];
  familyMembers: FamilyMember[] = [];
  leadConfigs = LEADS_CONFIGS;
  statusesList: KeyValue[] = [];
  editMode = false;
  isDialogOpen = false;
  editTerminMode = false;
  editFamilyMemberMode = false;
  familyMembersContentChanged = false;
  agentNumber = "0";
  genderData: string[] = ["weiblich", "männlich"];
  user: any = null;
  logs: Journal[] = [];
  pageSize = PaginationConfigs.pageSize;
  pageSizeOptions = PaginationConfigs.pageSizeOptions;
  pageIndex = PaginationConfigs.pageIndex;
  totalCount = 0;

  remindersList: RemindersList[] = [];
  notesList: { agentName: string, description: string }[] = [];

  displayReminderInputs = {
    showDateInput: true,
    showTimeInput: true
  };

  leadForm: FormGroup = new FormGroup({
    lead_typ: new FormControl({ value: "" }, [Validators.required]),
    lead_kategory: new FormControl({ value: "" }, [Validators.required]),
    lead_status: new FormControl({ value: "" }),
    lead_substatus: new FormControl({ value: "" }),
    termin_status: new FormControl({ value: "", disabled: true }),
    termin_substatus: new FormControl({ value: "", disabled: true }),
    agent: new FormControl({ value: "" }),
    salutation: new FormControl({ value: "" }),
    gender: new FormControl({ value: "" }),
    preferedLanguage: new FormControl({ value: "" }, [Validators.required]),
    lead_personen: new FormControl({ value: "" }),
    // aktuelle_kk: new FormControl({value: ""}),
    name: new FormControl({ value: "" }, [Validators.required]),
    firstName: new FormControl({ value: "" }, [Validators.required]),
    phone1: new FormControl({ value: "" }),
    phone2: new FormControl({ value: "" }, [Validators.required]), //PHONE-PRIVATE
    lead_source: new FormControl({ value: "" }, [Validators.required]),
    email1: new FormControl({ value: "" }),
    email2: new FormControl({ value: "" }, [Validators.required]),//EMAIL-PRIVATE
    address: new FormControl({ value: "" }, [Validators.required]),
    ort: new FormControl({ value: "" }, [Validators.required]),
    zip: new FormControl({ value: "" }, [Validators.required]),
    city: new FormControl({ value: "" }, [Validators.required]),
    //kanton: new FormControl({ value: "" },),
    birthday: new FormControl({ value: "" }, [Validators.required]),
    lead_extradata: new FormControl({ value: "" }, [Validators.required]),
    termin_id: new FormControl(),
    termin_editdate: new FormControl()
  });

  reminderForm: FormGroup = new FormGroup({
    category: new FormControl("", [Validators.required]),
    date: new FormControl("", [Validators.required]),
    time: new FormControl("", [Validators.required]),
    description: new FormControl("", [Validators.required])
  });

  terminForm: FormGroup = new FormGroup({
    calendar_lang: new FormControl(),
    termin_date: new FormControl("",Validators.required),
    termin_time: new FormControl("",Validators.required),
    termin_agent: new FormControl()
  });

  @HostListener('window:beforeunload')
  canDeactivate() {
    return !this.leadForm.dirty;
  }

  disabledToGoPrevious = false;
  disabledToGoNext = false;
  isSupplier: boolean = false;
  canEdit: boolean = false;
  isLeadRetention: boolean = false;

  terminLeadId:string; // is used to get id for selected lead and to use for task table
  constructor(
    public appointmentProvider: AppointmentProvider,
    private keycloak: KeycloakService,
    private translate: TranslateService,
    private loadService: LoadingService,
    private activatedRoute: ActivatedRoute,
    private leadsService: LeadsService,
    private configProvider: ConfigProvider,
    public dialogService: ActionDialogService,
    public snackBar: MatSnackBar,
    public titleService: Title,
    public dialog: MatDialog,
    public advisorService: AdvisorService,
    private router: Router,    
    public leadProvider: LeadProvider,
    private loadingService: LoadingService,
  ) { }
  async ngOnInit(): Promise<void> {
    let statusesFrom = BusinessTransactionHelper.typeId.terminStatusDialog.from;
    let statusesTo = BusinessTransactionHelper.typeId.terminStatusDialog.to;

    if (this.leadView) {
      statusesFrom = BusinessTransactionHelper.typeId.leadStatus.from;
      statusesTo = BusinessTransactionHelper.typeId.leadStatus.to;
    }
    this.getConfigs(statusesFrom, statusesTo);

    this.advisorService.getAgentNumber().then((agentNumber: string) => {
      this.agentNumber = agentNumber;
    });

    this.user = await this.keycloak.loadUserProfile()
    this.isSupplier = this.user.attributes.agenturType[0] === AgenturType.Supplier;
    this.leadForm.disable();
    this.terminForm.disable();

    this.subscriptions.push(
      this.activatedRoute.paramMap.subscribe((paramMap: ParamMap) => {
        this.terminLeadId=paramMap.get('id');
        this.getAppointmentById(this.terminLeadId);
        this.getLeadLogs(this.terminLeadId, this.loadingLogsTable)
      }));

    this.subscriptions.push(
      this.reminderForm.controls.category.valueChanges.subscribe((category: KeyValuePair) => {
        if (category && category.key === this.leadConfigs.typeId.TERMIN.NOTE) {
          this.displayReminderInputs = {
            showDateInput: false,
            showTimeInput: false
          };
          this.reminderForm.controls.date.disable();
          this.reminderForm.controls.time.disable();
        } else {
          this.resetReminderFormToDefaults();
        }
      }));
  }

  getConfigs(from: string, to: string): void {

    this.configProvider.getConfig().subscribe((config: Config[]) => {

      const typeIds = this.leadConfigs.typeId;
      const rCategories = this.configProvider.filterConfigsByValueRange(config, [typeIds.TERMIN.CALLBACK, typeIds.TERMIN.NOTE]);
      if (rCategories.length > 0) {
        this.reminderCategories = rCategories.map(x => {
          return {
            key: x.key,
            // null check
            value: (x.values && x.values.length > 0) ? x.values[0].value : ''
          }
        });
      }

      if(from === to){
        this.statusesList = config.filter(x => x.key === from)
        .flatMap(y => {
          return y.values.map(v => ({
            id: y.key,
            key: v.key,
            value: v.value
          }));
        });
      }
      else {
        this.statusesList = config.filter(x =>
          Number(x.key) >= Number(from) &&
          Number(x.key) <= Number(to))
          .map(y => {
            return {
              id: y.key,
              key: y.values[0].key,
              value: y.values[0].value
            }
          });
      }
      
    })
  }
  getAppointmentById(id: string): void {
    const queryParams = {
      st: 'termin',
      dateFrom: '',
      dateTo: '',
      text: '',
      pageIndex: '0',
      pageSize: '15'
    };
    const navigationExtras: NavigationExtras = {
      queryParams
    };
    this.subscriptions.push(
      this.appointmentProvider
        .getAppointmentById(id, null)
        .subscribe((lead: Person) => {
          this.lead = lead;
          this.canEdit = this.user.attributes.agenturType[0] === AgenturType.GeneralAgent ? true :
               this.user.attributes.agenturType[0] === AgenturType.Agent ? 
               this.user.attributes.advisorid[0] === this.lead.lead.termin_berater_shortcut :
               this.user.attributes.agenturType[0] === AgenturType.ServiceAgent  ? 
               this.user.attributes.advisorid[0] === this.lead.lead.termin_berater_shortcut :
               false;
          this.isLeadRetention = this.user.attributes.agenturType[0] === AgenturType.Admin && this.lead.lead.lead_kategory.toLowerCase() === "retention" ? true : false;
          this.lead.gender = typeof lead.gender === 'object' && lead.gender ? lead.gender['sdaValue'] : lead.gender;
          this._fillLeadDetails(lead);
          this.getTerminJournals();
          
          let statusesFrom = BusinessTransactionHelper.typeId.terminStatusDialog.from;
          let statusesTo = BusinessTransactionHelper.typeId.terminStatusDialog.to;
          
          // Get configs
          if (this.leadView) {
            statusesFrom = BusinessTransactionHelper.typeId.leadStatus.from;
            statusesTo = BusinessTransactionHelper.typeId.leadStatus.to;
            
            if (lead.lead.lead_kategory.toLowerCase() === "event") {
              statusesTo = BusinessTransactionHelper.typeId.leadEventStatus.to;
            }
          }

          if(this.lead.lead.lead_kategory.toLowerCase() === "retention"){
            statusesFrom = BusinessTransactionHelper.typeId.retention.from;
            statusesTo = BusinessTransactionHelper.typeId.retention.to;
          }

          this.getConfigs(statusesFrom, statusesTo);
          
          const leadIndexInArray =this.leadsService.leadsListToSwipe.leadsIds.findIndex(lead=>lead === this.lead.uuid);
          //Disable the Previous button on teh load if the user selected the first element from the first page
          this.disabledToGoPrevious = leadIndexInArray === 0 && Number(this.leadsService.leadsListToSwipe.page) === 0 ;
          this.disabledToGoNext = leadIndexInArray === this.leadsService.leadsListToSwipe.leadsIds.length - 1 && Number(this.leadsService.leadsListToSwipe.page) === this.leadsService.leadsListToSwipe.pageNumbers[this.leadsService.leadsListToSwipe.pageNumbers.length - 1] ;

        }, (err) => {
          this.router.navigate(['/leads'], navigationExtras)
          log.error(err);
        }));
  }

  private _fillLeadDetails(person: Person): void {
    const businessPhone: CommunicationAddress = person.communicationAddresses.find(x => x.commAddrType.key === CommAddrTypes.PHONE_MOBILE_BUSINESS);
    const phonePrivate: CommunicationAddress = person.communicationAddresses.find(x => x.commAddrType.key === CommAddrTypes.PHONE_MOBILE_PRIVATE);
    const businessEmail: CommunicationAddress = person.communicationAddresses.find(x => x.commAddrType.key === CommAddrTypes.EMAIL_BUSINESS);
    const emailPrivate: CommunicationAddress = person.communicationAddresses.find(x => x.commAddrType.key === CommAddrTypes.EMAIL_PRIVATE);

    this.familyMembers = person.lead.termin_family_members ? JSON.parse(person.lead.termin_family_members) : [];

    this.leadForm.patchValue({
      termin_id: person.uuid,
      termin_editdate: moment(person.lead.termin_editdate).format('YYYY-MM-DD'),
      lead_typ: person.lead.lead_typ || '',
      termin_status: person.lead.termin_status || '',
      termin_substatus: person.lead.termin_substatus || '',
      lead_kategory: person.lead.lead_kategory || '',
      lead_status: person.lead.lead_status || '',
      lead_substatus: person.lead.lead_substatus || '',
      agent: person.lead.lead_agency || '',
      salutation: person.salutation.toLowerCase(),
      gender: person.gender,
      preferedLanguage: person.preferedLanguage || '',
      lead_personen: person.lead.lead_personen || '',
      // aktuelle_kk: leadDetails.aktuelle_kk,
      name: person.name || '',
      firstName: person.firstName || '',
      phone1: businessPhone ? businessPhone.address : "",
      phone2: phonePrivate ? phonePrivate.address : "",
      lead_source: person.lead.lead_source,
      email1: businessEmail ? businessEmail.address : "",
      email2: emailPrivate ? emailPrivate.address : "",
      address: person.address.street || '',
      ort: person.address.city || '',
      zip: person.address.postalCode || '',
      //kanton: '',
      birthday: person.birthdate || '',
      lead_extradata: person.lead.lead_extradata || '',
    });

    this.terminForm.patchValue({
      // TODO fill calendar lang
      calendar_lang: '',
      termin_date: person.lead.termin_date,
      termin_time: person.lead.termin_time,
      termin_agent: person.lead.advisor_name
    })

  }

  addFamilyMember() {
    const newRow: FamilyMember = { name: '', gender: '', birthdate: '', nationality: '', action: '' }
    // check previous member
    const lastIndex = this.familyMembers.length - 1;
    if (lastIndex > -1) {
      if (this.familyMembers[lastIndex].name && this.familyMembers[lastIndex].birthdate) {
        this.familyMembers.push(newRow);
        this.table.renderRows();
      }
    } else {
      this.familyMembers.push(newRow);
      this.table.renderRows();
    }
  }

  deleteFamilyMember(person: FamilyMember): void {
    this.familyMembersContentChanged = true;
    this.familyMembers.splice(this.familyMembers.indexOf(person), 1);
    this.table.renderRows();
  }

  edit(editModeEvent): void {
    
      //Make all controls of PersonalData as touched, so the mat-error will fire.
      if(editModeEvent.editSection === 'PersonalData' && !editModeEvent.editMode) {
        for(let control of Object.keys(this.leadForm.controls)){
          this.leadForm.controls[control].markAsTouched();
        }    
        if(!this.leadForm.valid){
          console.error("Personal data Form not valid")
          //Finds the button to click one more time to reactivate the edit mode and not letting the user end the edit mode
          let el: HTMLElement = document.getElementById("toggleEditMode");
          el.click();
          this.leadForm.enable();
          return
        }
      };

      //Make all controls of Appointment as touched, so the mat-error will fire.
      if(editModeEvent.editSection === 'Appointment' && !editModeEvent.editMode) {
        this.terminForm.enable(); // To allow the terminForm to be valid
        for(let control of Object.keys(this.terminForm.controls)){
          this.terminForm.controls[control].markAsTouched();
        }    
        if(!this.terminForm.valid){
          console.error("Appointment Form not valid")
          //Finds the button to click one more time to reactivate the edit mode and not letting the user end the edit mode
          let terminToggleEditMode = document.querySelectorAll('[id=toggleEditMode]');
          (terminToggleEditMode[1] as HTMLElement).click();
          this.terminForm.enable();
          return;
        }
      };

    editModeEvent.editSection === 'PersonalData' ? this.editMode = editModeEvent.editMode :
      editModeEvent.editSection === 'Appointment' ? this.editTerminMode = editModeEvent.editMode :
        this.editFamilyMemberMode = editModeEvent.editMode;

    if (editModeEvent.editMode) {
      if (editModeEvent.editSection === 'PersonalData') {
        this.leadForm.enable();
      }
      else if (editModeEvent.editSection === 'Appointment') {
        this.terminForm.get('termin_date').enable();
        this.terminForm.get('termin_time').enable();
      }
    } else {
      // Using getRawValue() to access the values of leadForm even when it is disabled
      const leadForm: LeadDetails = this.leadForm.getRawValue();

      this.fillCommunicationAddresses(leadForm);
      this.lead.address.street = leadForm.address;
      this.lead.address.city = leadForm.ort;
      this.lead.address.postalCode = leadForm.zip;
      this.lead.salutation = leadForm.salutation;
      this.lead.gender = leadForm.gender;
      this.lead.lead.lead_personen = leadForm.lead_personen;
      this.lead.name = leadForm.name;
      this.lead.firstName = leadForm.firstName;
      this.lead.birthdate = moment(leadForm.birthday).format("YYYY-MM-DD");
      this.lead.preferedLanguage = leadForm.preferedLanguage;
      this.lead.lead.lead_extradata = leadForm.lead_extradata;
      this.lead.lead.termin_family_members = JSON.stringify(this.familyMembers);
      this.lead.lead.termin_editdate = moment().format('YYYY-MM-DD');
      this.lead.lead.lead_source = leadForm.lead_source;

      if (editModeEvent.editSection === 'PersonalData' || editModeEvent.editSection === 'FamilyTable') {
        this.updateLead();
        this.leadForm.disable();
      }
      else if (editModeEvent.editSection === 'Appointment') {
        this.addTermine();
        this.terminForm.disable();
      }
    }
  }

  cancel(editModeEvent): void {
    editModeEvent.editSection === 'PersonalData' ? this.editMode = editModeEvent.editMode :
      editModeEvent.editSection === 'Appointment' ? this.editTerminMode = editModeEvent.editMode :
        this.editFamilyMemberMode = editModeEvent.editMode;

    if (editModeEvent.editSection === 'PersonalData' || editModeEvent.editSection === 'FamilyTable') {
      this.leadForm.markAsPristine();
      this.leadForm.disable();
      this._fillLeadDetails(this.lead);
    }
    else if (editModeEvent.editSection === 'Appointment') {
      this.leadForm.markAsPristine();
      this.terminForm.disable();
      this._fillLeadDetails(this.lead);
    }
  }

  fillCommunicationAddresses(form: LeadDetails): void {
    // A3N: required by Dugagjin Sejdija bcs fixed mappings are used in CRM
    const indexes = {
      PHONE_MOBILE_BUSINESS: 0,
      PHONE_MOBILE_PRIVATE: 1,
      EMAIL_BUSINESS: 2,
      EMAIL_PRIVATE: 3
    };

    const PHONE_MOBILE_BUSINESS_INDEX = this.lead.communicationAddresses.findIndex(x => x.commAddrType.key === CommAddrTypes.PHONE_MOBILE_BUSINESS)

    if (PHONE_MOBILE_BUSINESS_INDEX > -1) {
      this.lead.communicationAddresses[PHONE_MOBILE_BUSINESS_INDEX].address = form.phone1;
      this.arrayMove(this.lead.communicationAddresses, PHONE_MOBILE_BUSINESS_INDEX, indexes.PHONE_MOBILE_BUSINESS);
    } else {
      this.insert(this.lead.communicationAddresses, indexes.PHONE_MOBILE_BUSINESS, new CommunicationAddress({
        commAddrType: new SDAKey({
          key: CommAddrTypes.PHONE_MOBILE_BUSINESS,
          sdaValue: 'MOBILE_BUSINESS',
          sorValue: 'MOBILE_BUSINESS'
        }),
        address: form.phone1,
        info: null
      }));

    }

    const PHONE_MOBILE_PRIVATE_INDEX = this.lead.communicationAddresses.findIndex(x => x.commAddrType.key === CommAddrTypes.PHONE_MOBILE_PRIVATE)

    if (PHONE_MOBILE_PRIVATE_INDEX > -1) {
      this.lead.communicationAddresses[PHONE_MOBILE_PRIVATE_INDEX].address = form.phone2;
      this.arrayMove(this.lead.communicationAddresses, PHONE_MOBILE_PRIVATE_INDEX, indexes.PHONE_MOBILE_PRIVATE);
    } else {
      this.insert(this.lead.communicationAddresses, indexes.PHONE_MOBILE_PRIVATE, new CommunicationAddress({
        commAddrType: new SDAKey({
          key: CommAddrTypes.PHONE_MOBILE_PRIVATE,
          sdaValue: 'MOBILE_PRIVATE',
          sorValue: 'MOBILE_PRIVATE'
        }),
        address: form.phone2,
        info: null
      }));
    }

    const EMAIL_BUSINESS_INDEX = this.lead.communicationAddresses.findIndex(x => x.commAddrType.key === CommAddrTypes.EMAIL_BUSINESS)

    if (EMAIL_BUSINESS_INDEX > -1) {
      this.lead.communicationAddresses[EMAIL_BUSINESS_INDEX].address = form.email1;
      this.arrayMove(this.lead.communicationAddresses, EMAIL_BUSINESS_INDEX, indexes.EMAIL_BUSINESS);

    } else {

      this.insert(this.lead.communicationAddresses, indexes.EMAIL_BUSINESS, new CommunicationAddress({
        commAddrType: new SDAKey({
          key: CommAddrTypes.EMAIL_BUSINESS,
          sdaValue: 'EMAIL_BUSINESS',
          sorValue: 'EMAIL_BUSINESS'
        }),
        address: form.email1,
        info: null
      }))
    }

    const EMAIL_PRIVATE_INDEX = this.lead.communicationAddresses.findIndex(x => x.commAddrType.key === CommAddrTypes.EMAIL_PRIVATE)

    if (EMAIL_PRIVATE_INDEX > -1) {
      this.lead.communicationAddresses[EMAIL_PRIVATE_INDEX].address = form.email2;
      this.arrayMove(this.lead.communicationAddresses, EMAIL_PRIVATE_INDEX, indexes.EMAIL_PRIVATE);

    } else {
      this.insert(this.lead.communicationAddresses, indexes.EMAIL_PRIVATE, new CommunicationAddress({
        commAddrType: new SDAKey({
          key: CommAddrTypes.EMAIL_PRIVATE,
          sdaValue: 'EMAIL_PRIVATE',
          sorValue: 'EMAIL_PRIVATE'
        }),
        address: form.email2,
        info: null
      }));
    }
  }

  resetReminderFormToDefaults(): void {
    this.displayReminderInputs = {
      showDateInput: true,
      showTimeInput: true
    };
    this.reminderForm.controls.date.enable();
    this.reminderForm.controls.time.enable();
  }

  addTermine(): void {

    const formValue: { termin_date: string, termin_time: string }
      = this.terminForm.value;

    const sorKeys = {
      agentName: this.user.firstName,
      category: this.leadConfigs.typeId.TERMIN.TERMIN,
      time: this.lead.lead.termin_time || ""
    };

    if (this.terminForm.dirty && this.terminForm.valid) { // if the Termin section has been modified by editing it      
      this.lead.lead.termin_time = formValue.termin_time;
      this.lead.lead.termin_date = moment(formValue.termin_date).format('YYYY-MM-DD');
      sorKeys.time = formValue.termin_time;
    }

    this.terminForm.patchValue({
      // TODO fill calendar lang
      calendar_lang: '',
      termin_date: this.lead.lead.termin_date,
      termin_time: this.lead.lead.termin_time,
      termin_agent: this.lead.lead.advisor_name
    })

      const actionData = BusinessTransactionHelper.createLeadBTActionObject(
        LeadLog.TERMIN,
        this.leadConfigs.typeId.TERMIN.TERMIN,
        '1. Termin',
        `${this.user.firstName}, ${this.lead.lead.termin_date} ${this.lead.lead.termin_time}`,
        '',
        this.lead.uuid,
        [],
        this.lead,
        moment(this.lead.lead.termin_date).format('YYYY-MM-DD') || "",
        sorKeys,
        this.user.firstName
      );

      this.subscriptions.push(
        this.leadsService.sendLeadAction(actionData)
          .subscribe(() => {
            this.emitNewLog.emit(actionData.transaction);
            this.snackBar.open("Termine created successfully", "X", UserCommitState.configSuccess);
          }, err => {
            log.error(`Something went wrong while calling POST-> lead/action `, err);
            this.snackBar.open("Something went wrong!", "X", UserCommitState.configError)
          })
      );
  }


  async addReminder(formData?: { category: { key: string, value: string }, date: string, time: string, description: string }): Promise<void> {
    if (this.reminderForm.valid || formData) {
      const formValue = formData ? formData : this.reminderForm.value;

      const sorKeys = {
        agentName: this.user.firstName,
        category: formValue.category.key,
        time: formValue.time || ""
      };

      // A3N: If user selects Termin from dropdown create BT for termin and update lead details within same request
      if (formValue.category.key === this.leadConfigs.typeId.TERMIN.TERMIN) {
        this.lead.lead.termin_time = formValue.time;
        this.lead.lead.termin_date = moment(formValue.date).format('YYYY-MM-DD');

        this.terminForm.patchValue({
          // TODO fill calendar lang
          calendar_lang: '',
          termin_date: this.lead.lead.termin_date,
          termin_time: this.lead.lead.termin_time,
          termin_agent: this.lead.lead.advisor_name
        })
      }
      let leadLogName = LeadLog.REMINDER;
      if (formValue.category.key == "4205") {
        leadLogName = LeadLog.NOTES;
      }

      const actionData = BusinessTransactionHelper.createLeadBTActionObject(
        leadLogName,
        formValue.category.key,
        formValue.category.value,
        `${this.user.firstName}, ${this.lead.lead.termin_date} ${this.lead.lead.termin_time}`,
        formValue.description,
        this.lead.uuid,
        [],
        this.lead,
        moment(formValue.date).format('YYYY-MM-DD') || "",
        sorKeys,
        this.user.firstName
      );

      this.subscriptions.push(
        this.leadsService.sendLeadAction(actionData)
          .subscribe(() => {
            this.emitNewLog.emit(actionData.transaction)
            this.snackBar.open("Reminder created successfully", "X", UserCommitState.configSuccess);
            this.reminderForm.reset();
            this.resetReminderFormToDefaults();
            this.getTerminJournals();
          }, err => {
            log.error(`Something went wrong while calling POST-> lead/action `, err);
            this.snackBar.open("Something went wrong!", "X", UserCommitState.configError)
          })
      );

    }
  }

  // A3N there was delay of several seconds from the moment when action was sent and BT service saves it into DB so we tmp push NEW journals in array
  addJournalTemporary(journal: Journal): void {
    switch (journal.typeId) {
      case this.leadConfigs.typeId.TERMIN.CALLBACK:
        this.remindersList.unshift({
          agentName: this.user.firstName,
          description: journal.descriptionField,
          time: journal.sorKeys.time,
          date: journal.effectiveDate
        });
        break;
      case this.leadConfigs.typeId.TERMIN.NOTE:
        this.notesList.unshift(
          {
            agentName: this.user.firstName,
            description: journal.descriptionField
          }
        );
        break;

      default:
        break;
    }
  }

  getTerminJournals() {
    this.subscriptions.push(
      this.leadsService.getJournalsByPartnerId(
        this.lead.uuid,
        BusinessTransactionHelper.typeId.terminReminders.from,
        BusinessTransactionHelper.typeId.terminReminders.to
      ).subscribe(journals => {
        this.remindersList = journals.filter(x => x.typeId === this.leadConfigs.typeId.TERMIN.CALLBACK).map(x => {
          return {
            agentName: x.sorKeys.agentName,
            description: x.descriptionField,
            time: x.sorKeys.time,
            date: x.effectiveDate
          }
        });

        this.notesList = journals.filter(x => x.typeId === this.leadConfigs.typeId.TERMIN.NOTE).map(x => {
          return {
            agentName: x.sorKeys.agentName,
            description: x.descriptionField
          }
        });
      }));
  }

  setStatusDialog(): void {
    this.isDialogOpen = true;
    const sorKeys = {
      'agentName': this.user.firstName,
      'advisorId': this.user.attributes.advisorid[0],
      'leadId': this.lead.uuid
    };
    this.lead.lead.termin_editdate = moment().format('YYYY-MM-DD');

    const actionData = BusinessTransactionHelper.createLeadBTActionObject(
      this.leadView ? LeadLog.LEAD_S_UPDATE : LeadLog.TERMIN_S_UPDATE,
      this.leadView ? BusinessTransactionHelper.typeId.leadDetailsStatus.from : BusinessTransactionHelper.typeId.terminDetailsStatus.from,
      this.leadView ?
        this.leadForm.value.lead_status :
        this.leadForm.value.termin_status,
      this.leadView ?
        "Lead status update => " + this.leadForm.value.lead_status
        :
        "Termin status update => " + this.leadForm.value.termin_status,
      this.titleService.getTitle(),
      this.lead.uuid,
      [],
      this.lead,
      Date.now().toString(),
      sorKeys,
      this.user.firstName
    );
    const dialogRef = this.dialogService.setStatusDialog(actionData, { keyValues: this.statusesList, typeId: "4501" });

    dialogRef.afterClosed()
      .subscribe(data => {
        this.isDialogOpen = false;

        if (!data) {
          return;
        }
        this.loadService.display(true);
        log.debug(`sending as action data -> ${data}`);

        // Create termin only if typeId is "4113" (Terminiert)
        if (data.transaction.typeId === "4113") {
          this.lead.lead.termin_time = data.transaction.sorKeys.time;
          this.lead.lead.termin_date = moment(data.transaction.sorKeys.date).format('YYYY-MM-DD');
        }

        this.leadsService.sendLeadAction(data)
          .subscribe(value => {
            this.loadService.display(false);
            this.loadService.setDialogClosed(true);
            const reminder = {
              "category": {
                "key": this.leadView ? BusinessTransactionHelper.typeId.leadReminders.from : BusinessTransactionHelper.typeId.terminReminders.from,
                "value": "1. Rückruf"
              },
              "date": data.transaction.sorKeys.date,
              "time": data.transaction.sorKeys.time,
              "description": data.transaction.descriptionField,
            }
            if (this.leadView) {
              this.leadForm.controls.lead_status.setValue(data.transaction.transactionName);
              this.leadForm.controls.lead_substatus.setValue(data.transaction.typeName);
              if (data.transaction.sorKeys.time) {
                this.terminForm.controls.termin_date.setValue(data.transaction.sorKeys.date)
                this.terminForm.controls.termin_time.setValue(data.transaction.sorKeys.time)
                this.edit({editMode:false, editSection: "Appointment"});//this.addReminder(reminder)
              }
            } else {
              this.leadForm.controls.termin_status.setValue(data.transaction.transactionName);
              this.leadForm.controls.termin_substatus.setValue(data.transaction.typeName);
              if (data.transaction.sorKeys.time) {
                this.addReminder(reminder)
              }
            }
            this.snackBar.open(this.translate.instant("COMMONS.USERCOMMIT.SUCCESS"), "X", UserCommitState.configSuccess);
          }, error => {
            this.loadService.display(false);
            this.snackBar.open(this.translate.instant("COMMONS.USERCOMMIT.ERROR"), "X", UserCommitState.configError);
          });
      });
  }

  openGmOfferDialog(): void {

    this.dialog.open(GMOfferDialogComponent, {
      width: '80%',
      minHeight: '80%',
      maxHeight: '90vh',
      panelClass: 'neomp-dialog-padding-none',
      data: {
        agentNumber: this.agentNumber,
        representant: this.lead,
        persons: [this.lead]
      }
    });
  }

  updateLead(): void {
    if (this.leadForm.dirty || this.familyMembersContentChanged) {
      this.familyMembersContentChanged = false;
      const sorKeys = {
        'advisorId': this.user.attributes.advisorid[0],
        'leadId': this.lead.uuid
      }
      this.lead.lead.termin_editdate = moment().format('YYYY-MM-DD');

      const actionData = BusinessTransactionHelper.createLeadBTActionObject(
        LeadLog.LEAD_UPDATE,
        BusinessTransactionHelper.typeId.leadDetailsUpdate.from,
        '',
        'Lead details update',
        '',
        this.user.attributes.advisorid[0],
        [],
        this.lead,
        '',
        sorKeys,
        this.user.firstName
      );
      this.subscriptions.push(
        this.leadsService.sendLeadAction(actionData)
          .subscribe(() => {
            this.leadForm.markAsPristine();
            this.leadForm.disable();
            this.leadForm.controls.termin_status.enable();
            this.snackBar.open("Lead updated successfully set", "X", UserCommitState.configSuccess);
          }, err => {
            log.error(`Something went wrong while calling POST-> lead/action `, err);
            this.snackBar.open("Something went wrong!", "X", UserCommitState.configError)
          })
      );
    }
  }

  getLeadLogs(leadId: string, restProviderActions: RestProviderActions): void {
    this.leadsService.getLeadLogs(restProviderActions, leadId, this.pageIndex, this.pageSize,).toPromise().then((logs) => {
      this.logs = logs.businessTransactions;
      this.totalCount = logs.totalCount
    })
  }

  paginatorValueChanged(event: PageEvent) {
    if (Number(this.pageIndex) === Number(event.pageIndex) && (Number(this.pageSize) === Number(event.pageSize))) return;
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getLeadLogs(this.lead?.uuid, this.loadingLogsTable)
  }

  insert(arr: any, index: number, newItem: any): void {
    index > arr.length - 1 ?
      arr.push(newItem) : arr.splice(index, 0, newItem);
  }

  arrayMove(arr: any, fromIndex: number, toIndex: number): void {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }

  async goToPreviousLead(){
    //check if the id is the first, if yes, don't allow to go back
    //if the id is the first, check if there are previous pages. Otherwise don't let go back
    const leadsListToSwipe = this.leadsService.leadsListToSwipe;

    const currentIndexInArray =leadsListToSwipe.leadsIds.findIndex(leadId=>this.lead.uuid === leadId);
    
    if(currentIndexInArray === 0){
      //In this case, the leads is the first in the list, so there is no Previous in the id list, so check if there is a previous page, if not, don't let user go back
      if(Number(leadsListToSwipe.page) === 0){
        /* This means the page is the first one,
        and there is no previous page, so don't allow to go back
        It shouldn't come here because the button will be disabled, but just to have it in future usage.
        */
       return;
      } else{
        // No, it's not the first page, so we can go to the previous page
        this.leadsService.leadsListToSwipe.page -= 1;
        //load the leads
        await this.loadLeadsAndNavigate("Previous");
        //if you go back to the previous page, you will open the previous page, but will open the last element. Logically if you go back, the previous item in the previous page, its the last element on the previous page.
      }
    } else{
      //Go to previous lead
      this.router.navigate([`/lead/${leadsListToSwipe.leadsIds[currentIndexInArray -1]}/edit`]);
      
      const indexInArrayAfterNavigate = currentIndexInArray - 1;
      this.disabledToGoPrevious = indexInArrayAfterNavigate === 0 && Number(this.leadsService.leadsListToSwipe.page) === 0;
      this.disabledToGoNext = indexInArrayAfterNavigate === this.leadsService.leadsListToSwipe.leadsIds.length - 1 && Number(this.leadsService.leadsListToSwipe.page) === this.leadsService.leadsListToSwipe.pageNumbers.length - 1;
    }
  }
  async goToNextLead(){
    //check if the id is the last, if yes, don't allow to go nest
    const leadsListToSwipe = this.leadsService.leadsListToSwipe;
    //Get the id of the current lead
    const currentIndexInArray =leadsListToSwipe.leadsIds.findIndex(leadId=>this.lead.uuid === leadId);
    
    if(currentIndexInArray === leadsListToSwipe.leadsIds.length - 1){
      
      //In this case, the leads is the last in the list, so there is no Next in the id list, so check if there is a next page, if not, don't let user go next
      if(Number(leadsListToSwipe.page) === leadsListToSwipe.pageNumbers.length - 1){
        /* This means the page is the last one,
        and there is no next page, so don't allow to go next
        It shouldn't come here because the button will be disabled, but just to have it in future usage.
        */
       return;
      } else{
        // No, it's not the last page, so we can go to the next page
        this.leadsService.leadsListToSwipe.page = Number(this.leadsService.leadsListToSwipe.page) + 1;
        //load the leads
        await this.loadLeadsAndNavigate("Next");
      }
    } else{
      //Go to next lead
      this.router.navigate([`/lead/${leadsListToSwipe.leadsIds[currentIndexInArray + 1]}/edit`]);

      const indexInArrayAfterNavigate = currentIndexInArray + 1;
      this.disabledToGoPrevious = indexInArrayAfterNavigate === 0 && Number(this.leadsService.leadsListToSwipe.page) === 0;
      this.disabledToGoNext = indexInArrayAfterNavigate === this.leadsService.leadsListToSwipe.leadsIds.length - 1 && Number(this.leadsService.leadsListToSwipe.page) === this.leadsService.leadsListToSwipe.pageNumbers.length - 1;

    }
    
  }

  async loadLeadsAndNavigate(direction: "Previous" | "Next") {
    const page = this.leadsService.leadsListToSwipe.page;
    const size = this.leadsService.leadsListToSwipe.size;
    const filter =  this.leadsService.leadsListToSwipe.filter != "" || !this.leadsService.leadsListToSwipe.filter.includes('&isManagement=false') ?  this.leadsService.leadsListToSwipe.filter + '&isManagement=false' : 'isManagement=false';
    await this.subscriptions.push(
      this.leadProvider
        .getLazyLeads(undefined, page, size, filter)
        .subscribe((leads: { data: Person[]; totalCount: number }) => {
          if(direction === "Previous"){
            this.leadsService.updateListOfSwipeableLeads(leads.data, page, size, filter, leads.totalCount);
            this.router.navigate([`/lead/${this.leadsService.leadsListToSwipe.leadsIds[this.leadsService.leadsListToSwipe.leadsIds.length -1]}/edit`]);
          }
          if(direction === "Next"){
            this.leadsService.updateListOfSwipeableLeads(leads.data, page, size, filter, leads.totalCount);
            this.router.navigate([`/lead/${this.leadsService.leadsListToSwipe.leadsIds[0]}/edit`]);
          }
        }))
  }

  /**
   * Updates the value of different controls
   * @param control - the control name that needs to be updated
   * @param value - the value that needs to be set
   */
  updateValue(control:string, value:string){
    this.leadForm.controls[control].setValue(value);
    this.leadForm.markAsDirty();
  }


  ngOnDestroy(): void {
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
  }
}

export interface FamilyMember {
  name: string;
  gender: string;
  birthdate: string;
  nationality: string;
  action: string;
}

