import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { UploadFileComponent } from 'app/modules/core/components/upload-file.component';

@Component({
  selector: 'neomp-termin-edit',
  templateUrl: './termin-edit.component.html',
  styleUrls: ['./termin-edit.component.scss']
})
export class TerminEditComponent implements OnInit {
  @ViewChild(UploadFileComponent) uploadFile: UploadFileComponent;
  
  displayedColumns: string[] = ['name','geschiecht','geburtsdatum','nationalitat','action'];
  dataSource:PeriodicElement[] = ELEMENT_DATA;
  @ViewChild(MatTable)
  table: MatTable<PeriodicElement>;
  constructor() { }

  ngOnInit() {
  }
  addData() { 
    const newRow = {name: '', geschiecht: '', geburtsdatum: '',nationalitat: '',action: ''} 
    this.dataSource.push(newRow);
    this.table.renderRows();
  }
  deletePerson(person) {
    this.dataSource.splice(this.dataSource.indexOf(person), 1);
    this.table.renderRows();
  }
}
export interface PeriodicElement {   
  name: string;
  geschiecht: string;
  geburtsdatum: string;
  nationalitat: string;
  action: string;
}
const ELEMENT_DATA: PeriodicElement[] = [
  {name: 'Max', geschiecht: 'Herr', geburtsdatum: '26.04.1988',nationalitat: 'Deutsch',action: '' },
  {name: 'Ana', geschiecht: 'Frau', geburtsdatum: '20.04.1968',nationalitat: 'Deutsch',action: '' },
];
