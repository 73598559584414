import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ImpersonateService {

  constructor(
    private activatedRoute: ActivatedRoute,
    private http : HttpClient ) {}

  public tokenExchange(username: string): Observable<any>  {
    return this.http.post<{access_token: string, token_type: string, session_state: string, scope: string}>(
        `${environment.settings.BASE_URL}/api/keycloak/impersonate`,
        {
            username,
            grantType: 'urn:ietf:params:oauth:grant-type:token-exchange',
        }
    ).pipe(map(response => {return { token: response['access_token'], refresh_token: response['refresh_token'] } }));
}

getTokenQueryParam(): Observable<string | null> {
  return this.activatedRoute.queryParams.pipe(map(params => { return params['token'] }));
}
}
