<table mat-table [dataSource]="dataSourceExpandDetail?.persons" class="msg-helper-width-100">
    <ng-container matColumnDef="status">
        <td *matCellDef="let element" mat-cell>
            <div class="neo-meine-kunden highligt-status"></div>
        </td>
    </ng-container>
    <ng-container matColumnDef="name">
        <td mat-cell
            *matCellDef="let element">{{element.fullName}}</td>
    </ng-container>
    <ng-container matColumnDef="birth">
        <td mat-cell *matCellDef="let element"> {{element.birthdate| date: 'dd.MM.yyyy'}}</td>
    </ng-container>
    <ng-container matColumnDef="gender">
        <td mat-cell *matCellDef="let element">{{element.gender}}</td>
    </ng-container>
    <ng-container matColumnDef="appStatus">
        <td (click)="$event.stopPropagation()" class="copyReferral" mat-cell *matCellDef="let element">
            <div class="neo-width-center">
            <img *ngIf="!element.neoAppInfo.active" src="assets/icons/mobile_off.svg" class="material-icons img-background" title="False" alt="not installed"/>
            <img *ngIf="element.neoAppInfo.active" src="assets/icons/mobile_on.svg" class="material-icons img-background" title="True" alt="app installed"/>
            <img *ngIf="checkAge(element.birthdate)" #tooltip="matTooltip" (click)="copyMessage(element.uuid); tooltip.show(); " 
            [matTooltip]="copyText"
            [matTooltipHideDelay]="matTooltipHideDelay" src="assets/icons/copy_content.svg" class="material-icons img-background copyReferral"  alt="app installed"/>
            <img *ngIf="element.neoAppInfo.active && !element.neoAppInfo.online" src="assets/icons/status_offline.svg" class="material-icons img-background margin-left-10" title="Offline" alt="off line"/>
            <img *ngIf="element.neoAppInfo.active && element.neoAppInfo.online" src="assets/icons/status_online.svg" class="material-icons img-background margin-left-10" title="Online" alt="on line"/>
        </div>
        </td>
    </ng-container>
    <tr  mat-row *matRowDef="let person; columns: displayedColumns;"
        class="neomp-table-row-selected family-table-expanded-detail-member-row
               {{person.uuid === selectedRowIndex ? 'family-table-expanded-detail-member-row-selected':''}}
               container-table-row"
        (click)="personRowClick(person)"></tr>
</table>
