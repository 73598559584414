
<neomp-container cssClass="msg-mp-container-frameless"
headlineText="{{ 'PAGE.REFFERALS.REFERRAL' | translate }}">


  <div class="mat-elevation-z8">
   <neomp-referrals-table [tableEntries]="referrals" [referrals]="referrals" ></neomp-referrals-table>
    <neomp-paginator
    [length]="totalCount" 
    [pageSize]="pageSize" 
    [pageIndex]="pageIndex"
    [pageSizeOptions]="pageSizeOptions" 
    (paginatorChange)="paginatorReferalsValueChanged($event)">
    
    </neomp-paginator>
  </div>
</neomp-container>