export namespace GMOfferConstants {
export const GENDER_MALE = 'Mann';
export const GENDER_FEMALE = 'Frau';
export const GENDER_UNBORN = "Vorgeburt";
export const TARIFF_TYPE_HMO = "HMO";
export const TARIFF_TYPE_HAM = "HAM";
export const TARIFF_TYPE_BASE = "BASE";
export const TARIFF_TYPE_DIV = "DIV";

export const URL_PART_SEARCH = "suche";
export const URL_PART_CONTACTS = "contacts";
export const URL_PART_CONFIGS = "configs";
export const URL_PART_REGIONS = "regionen";
export const URL_PART_DOCTORS = "aerzte";
export const URL_PART_SEARCH_DATA = "suchdaten";
export const URL_PART_FILTER = "filter";
}