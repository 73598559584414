import {Injectable} from "@angular/core";

@Injectable()
export class Logger {
    private static readonly MODULE_MAX_LENGTH = 20;
    private logLevel: LogLevel;

    constructor() {
        this.initLogLevel();
    }

    private _module = "";

    public set module(m: string) {
        while (m.length < Logger.MODULE_MAX_LENGTH) {
            m += " ";
        }
        this._module = m;
    }

    public setLogLevel(level = "NONE") {
        if (level && level.length > 0) {
            this.logLevel = this.convertLoglevel(level);
        } else {
            this.logLevel = LogLevel.all;
        }
    }

    public log(message: any) {
        if (this.logLevel >= LogLevel.info) {
            window.console.log(message);
        }
    }

    public info(message: any, ...obj: any[]) {
        if (this.logLevel >= LogLevel.info) {
            const msg = this.addLogPrefix("INFO ", message);
            this.logOnConsole("info", msg, ...obj);
        }
    }

    public warn(message: any, ...obj: any[]) {
        if (this.logLevel >= LogLevel.warning) {
            const msg = this.addLogPrefix("WARN ", message);
            this.logOnConsole("warn", msg, ...obj);
        }
    }

    public error(message: any, ...obj: any[]) {
        if (this.logLevel >= LogLevel.error) {
            const msg = this.addLogPrefix("ERROR", message);
            this.logOnConsole("error", msg, ...obj);
        }
    }

    public debug(message, ...obj: any[]) {
        if (this.logLevel >= LogLevel.debug) {
            const msg = this.addLogPrefix("DEBUG", message);
            this.logOnConsole("debug", msg, ...obj);
        }
    }

    public createLogger(module: string): Logger {
        const retVal = new Logger();
        retVal.module = module;
        return retVal;
    }

    private convertLoglevel(value: string): LogLevel {
        if (value && value === "INFO") {
            return LogLevel.info;
        } else if (value && value === "ERROR") {
            return LogLevel.error;
        } else if (value && value === "DEBUG") {
            return LogLevel.debug;
        } else if (value && value === "WARNING") {
            return LogLevel.warning;
        } else if (value && value === "NONE") {
            return LogLevel.none;
        } else {
            return LogLevel.all;
        }
    }

    private initLogLevel() {
        this.setLogLevel();
        this.module = "DEFAULT";
    }

    private addLogPrefix(prefix: string, msg: string): string {
        return `${this._module} | ${prefix} | ${msg}`;
    }

    private logOnConsole(where: string, message, ...obj: any[]) {
        // if (obj && obj.length) {
        //     if (where === "debug") {
        //         window.console.debug(message, ...obj);
        //     } else if (where === "info") {
        //         window.console.info(message, ...obj);
        //     } else if (where === "error") {
        //         window.console.error(message, ...obj);
        //     } else if (where === "warn") {
        //         window.console.warn(message, ...obj);
        //     }
        // } else {
        //     if (where === "debug") {
        //         window.console.debug(message);
        //     } else if (where === "info") {
        //         window.console.info(message);
        //     } else if (where === "error") {
        //         window.console.error(message);
        //     } else if (where === "warn") {
        //         window.console.warn(message);
        //     }
        // }
    }
}

export enum LogLevel {
    none = 0,
    error = 1,
    warning = 2,
    info = 3,
    debug = 4,
    all = 5
}

export let log: Logger;

export const msgLogger = (logger: Logger) => {
    if (!log) {
        log = logger;
    }
};
