import {Injectable} from "@angular/core";
import {RestProvider} from "./rest.provider";
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment";
import {ActionData} from "../model/action-data";
import {log} from "./logger.provider";


@Injectable()
export class ActionDataProvider {
    private readonly CLASS_NAME = this.constructor.name;

    constructor(public rest: RestProvider) {
    }

    public sendActionData(actionData: ActionData): Observable<string> {
        if(typeof actionData['policy'] === "undefined"){
            delete actionData['policy'];
        }
        if(typeof actionData['policyHolder'] === "undefined"){
            delete actionData['policyHolder'];
        }
        log.debug(`${this.CLASS_NAME} sendActionData`);
        return this.rest.post(`${environment.settings.BASE_URL}/api/advisor/action`, actionData);
    }

    public sendActionDataWithURL(actionData: ActionData, url: string): Observable<string> {
        log.debug(`${this.CLASS_NAME} sendActionData`);
        return this.rest.post(`${url}`, actionData);
    }

}
