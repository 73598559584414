import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { PaginationConfigs } from 'app/modules/constants/PaginationConstants';
import { LoadingDataComponent } from 'app/modules/core/components/loading-data.component';
import { LeadQueryParams } from 'app/modules/core/json/leadQueryParams.json';
import { FileUpload } from 'app/modules/core/model/file-upload';
import { Person } from 'app/modules/core/model/person';
import { LeadProvider } from 'app/modules/core/providers/lead.provider';
import { LoadingService } from 'app/modules/core/providers/loading.component';
import { RestProviderActions } from 'app/modules/core/providers/rest.provider';
import jsPDF from 'jspdf';
import domtoimage from 'dom-to-image';
import moment from 'moment';
import { BusinessTransactionHelper } from 'app/modules/core/static/bt-helper';
import { ActionDialogService } from 'app/modules/core/providers/action-dialog.service';
import { MatDialog } from '@angular/material/dialog';
import * as uuid from "uuid";
import { LeadDetails } from 'app/modules/leads/Interfaces/LeadDetails';
import { SDAKey } from 'app/modules/core/model/sdakey';
import { KeycloakService } from 'keycloak-angular';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Reference } from 'app/modules/core/model/reference';
import { LeadLog } from 'app/modules/core/model/LeadLog.enum';
import { Subscription } from 'rxjs';
import { LeadsService } from 'app/modules/leads/services/leads.service';
import { UserCommitState } from 'app/modules/core/model/user-commit-state';
import { log } from 'app/modules/core/providers/logger.provider';
import { CommunicationAddress } from 'app/modules/core/model/communication-address';
import { CommAddrTypes } from 'app/modules/core/model/enum-commaddrtype';
import { AgenturType } from 'app/modules/core/model/enum-agenturtype';
import { ConfigProvider } from 'app/modules/core/providers/config.provider';
import { AdvisorDataService } from "app/modules/core/services/advisor-data.service";

@Component({
  selector: 'neomp-mortgage',
  templateUrl: './mortgage.component.html',
  styleUrls: ['./mortgage.component.scss']
})
export class MortgageComponent implements OnInit {
  private readonly TAG = this.constructor.name;

  types = ["Neue Hypothek", "Bestehende Hypothek"];
  selectedType: string = this.types[0];
  objectType = ["Einfamilienhaus", "Zweifamilienhaus", "Eigentumswohnung (StWE)", "Ferienhaus", "Ferienwohnung"]
  selectedObjectType: string = this.objectType[0];

  salutations = ["Herr", "Frau"]
  selectedSalutation: string = this.salutations[0];

  mortgage = new FormGroup({
    mortgageType: new FormControl(this.selectedType, Validators.required),
    salutation: new FormControl(this.selectedSalutation, [Validators.required]),
    name: new FormControl("", [Validators.required]),
    firstName: new FormControl("", [Validators.required]),
    ort: new FormControl("", [Validators.required]),
    address: new FormControl("", [Validators.required]),
    zip: new FormControl("", [Validators.required]),
    email2: new FormControl("", [Validators.required, Validators.email]),
    phone1: new FormControl("",[Validators.required, Validators.pattern('(?:\\d{3}[-.\\s]?){2}\\d{2}|\\d{10}')]),
    phone2: new FormControl("", [Validators.required, Validators.pattern('(?:\\d{3}[-.\\s]?){2}\\d{2}|\\d{10}')])
  });
  mortgageData = this.mortgage.value;

  newMortgage = new FormGroup({
    objectType: new FormControl(this.selectedObjectType, [Validators.required]),
    income: new FormControl("100’000", [Validators.required]),
    valueOfProperty: new FormControl("500’000", [Validators.required]),
    ownFunds: new FormControl("100’000", [Validators.required]),
    zinc: new FormControl(1.2, [Validators.required]),
  });

  newMortgageData = this.newMortgage.value;
  subscriptions: Subscription[] = [];

  lead:Person = new Person()
  user: any = null;

  @Input()
  managementView: boolean = false;
  agents: Person[] = [];
  isHighRole: boolean = true;
  isDialogOpen = false;
  agenturType: string = "";
  advisorId: string = "";
  agenturName: string = "";
  advisorName: string = "";
  agenturId: string = "";

  pageSize = PaginationConfigs.pageSize;
  pageSizeOptions = PaginationConfigs.pageSizeOptions;
  pageIndex = PaginationConfigs.pageIndex;
  totalCount = 0;
  leads: Person[] = [];
  totalCountLeads = 0;
  dateFrom: any;
  dateTo: any;

  unassignedLeadsCategories: string[] = [];
  unassignedLeadsTypes: string[] = [];

  zinsen: number = 1.2;
  zinsenNew: number = 0;
  zinsenTmp = this.zinsen.toString();
  tragbarkeit: number = 0;
  amortisation: number = 0;
  hypothek: number = 0;
  unterhaltNebenkosten: number = 0;

  imageData!: string;
  pdfDocument!: FileUpload;

  routerParams: Params;

  @ViewChild("cancellationTables") MortgageTableComponent: LoadingDataComponent;

  constructor(
    public loadService: LoadingService,
    public leadProvider: LeadProvider,
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: ActionDialogService,
    public dialog: MatDialog,
    private keycloak: KeycloakService,
    public snackBar: MatSnackBar,
    private http: HttpClient,
    private leadsService: LeadsService,
    private configProvider: ConfigProvider,
    private advisorDataService: AdvisorDataService,


  ) {    }
  
  async ngOnInit(): Promise<void> {
    this.user = await this.keycloak.loadUserProfile();

    this.keycloak.loadUserProfile().then((res: any) => {
      this.advisorName = res.firstName;
      this.agenturType = res.attributes.agenturType[0];
      this.agenturName = res.attributes.agenturName[0];
      this.agenturId = res.attributes.agenturID[0];
      this.isHighRole = (this.agenturType === AgenturType.SalesManager || this.agenturType === AgenturType.GeneralAgent || this.agenturType === AgenturType.TeamLead);
    });
    this.leadProvider.getAttributeValues().subscribe(attributeValues => {
      this.unassignedLeadsCategories= attributeValues?.leadCategories || [];
      this.unassignedLeadsTypes= attributeValues?.leadTypes || [];
    });
    this.agents = await this.getAgents();
    this.route.queryParams
    .subscribe((params) => {
      this.searchFromParams(params);
      this.routerParams=params
    })
  this.newMortgage.valueChanges.subscribe(() => {
    this.calculate();
  });
  this.calculate();

  this.keycloak.loadUserProfile().then((res: any) => {
    this.advisorName = res.firstName;
    this.agenturType = res.attributes.agenturType[0];
    this.agenturName = res.attributes.agenturName[0];
    this.isHighRole = (this.agenturType === AgenturType.GeneralAgent || this.agenturType === AgenturType.TeamLead);
  });
  }

  getAgents(): Promise<Person[]> {
    return this.advisorDataService.getUnblockedAgents();
  }

  loadLeads(restProviderActions: RestProviderActions, page: number, size: number, filter?: string) {
    this.loadService.display(true);
    this.leads = [];

    this.leadProvider
      .getLazyLeads(restProviderActions, page, size, filter)
      .subscribe((leads: { data: Person[]; totalCount: number }) => {
        this.totalCountLeads = leads.totalCount;
        this.leads = leads.data;
        this.loadService.display(false);
        if (this.agents.length > 0) {
          this.leads = leads.data.map(lead => this.assignAgentsToLeads(lead));
        }
        else {
          this.leads = leads.data;
        }
        this.loadService.display(false);
      });
  }

  assignAgentsToLeads(lead: Person) {
    const leadAgent = this.agents.find(x => x.uuid == lead.lead.termin_berater_shortcut);
    const agentName = leadAgent ? leadAgent.fullName : this.agenturName;
    if (agentName) {
      lead.sorKeys["agent"] = agentName;
      return lead;
    }
    return null;
  }

  async sendNewMortgage() {
    await this.createPdfFromOffers(["mortgage", "newMortgage"]);
    await this.createLead();
    this.selectedType=this.types[0];
    this.selectedSalutation=this.salutations[0];
  }

  async sendMortgage() {
    await this.createPdfFromOffers(["mortgage"]);
    await this.createLead();
    this.selectedType=this.types[0];
    this.selectedSalutation=this.salutations[0];
  }

  searchFromParams(params?): void {
    this.pageIndex = Number(params.pageIndex) || PaginationConfigs.pageIndex;
    this.pageSize = Number(params.pageSize) > 0 ? Number(params.pageSize) : PaginationConfigs.pageSize;
    this.search();
  }

  search() {
    const filter = 'isHypo=true'
    this.loadLeads(
      this.MortgageTableComponent,
      this.pageIndex,
      this.pageSize,
      filter
    );
  }

  paginatorAssignedLeadsValueChanged(event: PageEvent) {
    if (event.pageIndex != this.pageIndex || event.pageSize != this.pageSize) {
      this.pageIndex = event.pageIndex;
      this.pageSize = event.pageSize;
      const queryParamObj = {
        dateFrom: undefined,
        dateTo: undefined,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
      };
      this.updateQueryParams('/mortgage', this.route.snapshot.queryParams.st, queryParamObj)
    }
  }

  updateQueryParams(path: string, st: string, queryParams: LeadQueryParams): void {
    this.router.navigate([path], {
      queryParams: {
        st,
        pageIndex: queryParams?.pageIndex,
        pageSize: queryParams?.pageSize,
      }
    });
  }

  async createPdfFromOffers(ids: string[]) {
    const pdf = new jsPDF('p', 'mm', 'a4');
    pdf.internal.scaleFactor = 30;
    const excludedElements = ['rmParts'];
    const margin = 10;
    const imageWidth = 190;
    for (let i = 0; i < ids.length; i++) {
      const offer = document.getElementById(ids[i]);
      const options = {
        style: {
          'transform': 'none',
          'transform-origin': 'top left',
          'padding': '0',
          'margin': '0',
          'box-sizing': 'content-box',
          'line-height': '1', // Set a fixed line height
        },
        filter: (element: any) => !excludedElements.includes(element.id),
        quality: 1,
        dpi: 600
      };
      this.imageData = offer !== null ? await domtoimage.toPng(offer, options) : "";
      const imageHeight = offer !== null ? (offer.offsetHeight / offer.offsetWidth) * imageWidth : 0;
      pdf.addImage(this.imageData, 'PNG', margin, margin, imageWidth, imageHeight, undefined, 'FAST');
      if (i !== ids.length - 1) {
        pdf.addPage();
      }
    }
    const date = 'myNeo-' + moment().format('YYYY-MM-DD') + '.pdf';
    const pdfBase64 = `data:application/pdf;base64,${btoa(pdf.output())}`
    this.pdfDocument = new FileUpload(date, pdfBase64, this.imageData.length)
    // // Download pdf file
    // const pdfBlob = pdf.output('blob');
    // const pdfUrl = URL.createObjectURL(pdfBlob);
    // const anchor = document.createElement('a');
    // anchor.download = 'myNeo-' + moment().format('YYYY-MM-DD') + '.pdf';
    // anchor.href = pdfUrl;

    // anchor.click();    
  }

  ZinsenThousand() {
    this.zinsenTmp = this.zinsenTmp.toString()
      .replace(/`/g, "")
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1`");
    this.zinsen = Number(this.zinsenTmp.replace(/`/g, ""));
    return this.zinsenTmp;
  }
  calculateOnChange() {
    if (this.zinsen !== 0) {
      this.calculate();
    }
  }

  calculate() {
    let numIncome = Number(this.newMortgage.get("income").value.replace(/’/g, ''));
    let numOwnFunds = Number(this.newMortgage.get("ownFunds").value.replace(/’/g, ''));
    let numValueOfProperty = Number(this.newMortgage.get("valueOfProperty").value.replace(/’/g, ''));

    this.zinsenNew = this.zinsen / 100;
    this.amortisation =
      (numValueOfProperty - numOwnFunds - numValueOfProperty * 0.6666) / 15 / 12;
    // amortisation cannot be less than 0
    this.amortisation < 0 ? this.amortisation = 0 : this.amortisation;

    this.unterhaltNebenkosten = (numValueOfProperty * 0.01) / 12;
    this.hypothek = numValueOfProperty - numOwnFunds;
    this.tragbarkeit =
      (((this.hypothek * 0.05) / 12 +
        this.unterhaltNebenkosten +
        this.amortisation) /
        (numIncome / 12)) *
      100;

    this.tragbarkeit = parseFloat(this.tragbarkeit.toFixed(2));
  }

  Increase(type, value) {
    if (type === "Zinsen") {
      this.zinsen = Number(
        this.CheckIfValueIsValid(
          type,
          (parseFloat(this.zinsen.toString()) + parseFloat(value)).toFixed(1)
        )
      );
      this.zinsenTmp = this.zinsen.toString();
      this.ZinsenThousand();
    }

    this.calculateOnChange();
  }

  Decrease(type, value) {
    {
      if (type === "Zinsen") {
        this.zinsen = Number(
          this.CheckIfValueIsValid(
            type,
            (parseFloat(this.zinsen.toString()) - parseFloat(value)).toFixed(1)
          )
        );
        this.zinsenTmp = this.zinsen.toString();
        this.ZinsenThousand();
      }
    }
    this.calculateOnChange();
  }

  CheckIfValueIsValid(type, value): Number {
    if (type === "Zinsen") {
      return value < 0.1 ? 0.1 : value < 5 ? value : 5;
    }
    return 0;
  }
  arrayMove(arr: any, fromIndex: number, toIndex: number): void {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }
  insert(arr: any, index: number, newItem: any): void {
    index > arr.length - 1 ?
      arr.push(newItem) : arr.splice(index, 0, newItem);
  }
  
  createLead() {
    const leadForm = this.mortgage.value ;
    const newMortgage = this.newMortgage.value;
    if(this.selectedType === this.types[0]){
      this.fillCommunicationAddresses(leadForm);
      this.lead.leadHypo.hypo_objecttype = newMortgage.objectType.toString();
      this.lead.leadHypo.hypo_income = newMortgage.income.toString();
      this.lead.leadHypo.hypo_propertyvalue = newMortgage.valueOfProperty.toString();
      this.lead.leadHypo.hypo_ownfunds = newMortgage.ownFunds.toString();
      this.lead.leadHypo.hypo_interest = newMortgage.zinc.toString();
      this.lead.leadHypo.hypo_portability=`${this.tragbarkeit}%`.toString();
      this.lead.leadHypo.isHypo= true;
      this.lead.created = moment().format('YYYY-MM-DDTHH:mm:ss.SSSZZ');
      this.lead.updated = moment().format('YYYY-MM-DDTHH:mm:ss.SSSZZ');
      this.lead.salutation = leadForm.salutation;
      this.lead.address.street = leadForm.address;
      this.lead.address.city = leadForm.ort;
      this.lead.address.postalCode = leadForm.zip;
      this.lead.name = leadForm.name;
      this.lead.firstName = leadForm.firstName;
      this.lead.uuid = uuid.v4();
      this.lead.email = leadForm.email2;
      this.lead.lead.lead_creadate = moment().format('YYYY-MM-DDTHH:mm:ss.SSSZZ');
      this.lead.lead.lead_agency = this.user.attributes.agenturID[0];
      this.lead.lead.termin_berater_shortcut = this.user.attributes.advisorid[0];
      this.lead.lead.lead_status = "Neu",
      this.lead.lead.lead_substatus = "Neu",
      this.lead.lead.lead_kategory = "Hypothek"

    }
    else {
      this.fillCommunicationAddresses(leadForm);
      this.lead.leadHypo.hypo_objecttype = "";
      this.lead.leadHypo.hypo_income = "";
      this.lead.leadHypo.hypo_propertyvalue = "";
      this.lead.leadHypo.hypo_ownfunds = "";
      this.lead.leadHypo.hypo_interest = "";
      this.lead.leadHypo.hypo_portability="";
      this.lead.leadHypo.isHypo= true;
      this.lead.created = moment().format('YYYY-MM-DDTHH:mm:ss.SSSZZ');
      this.lead.updated = moment().format('YYYY-MM-DDTHH:mm:ss.SSSZZ');
      this.lead.salutation = leadForm.salutation;
      this.lead.address.street = leadForm.address;
      this.lead.address.city = leadForm.ort;
      this.lead.address.postalCode = leadForm.zip;
      this.lead.name = leadForm.name;
      this.lead.firstName = leadForm.firstName;
      this.lead.uuid = uuid.v4();
      this.lead.email = leadForm.email2;
      this.lead.lead.lead_creadate = moment().format('YYYY-MM-DDTHH:mm:ss.SSSZZ');
      this.lead.lead.lead_agency = this.user.attributes.agenturID[0];
      this.lead.lead.termin_berater_shortcut = this.user.attributes.advisorid[0];
      this.lead.lead.lead_status = "Neu",
      this.lead.lead.lead_substatus = "Neu",
      this.lead.lead.lead_kategory = "Hypothek"
    }

    this.sendCreateLead();
  }

  fillCommunicationAddresses(form: LeadDetails): void {
    const indexes = {
      PHONE_MOBILE_BUSINESS: 0,
      PHONE_MOBILE_PRIVATE: 1,
      EMAIL_BUSINESS: 2,
      EMAIL_PRIVATE: 3
    };

    const PHONE_MOBILE_BUSINESS_INDEX = this.lead.communicationAddresses.findIndex(x => x.commAddrType.key === CommAddrTypes.PHONE_MOBILE_BUSINESS)

    if (PHONE_MOBILE_BUSINESS_INDEX > -1) {
      this.lead.communicationAddresses[PHONE_MOBILE_BUSINESS_INDEX].address = form.phone1;
      this.arrayMove(this.lead.communicationAddresses, PHONE_MOBILE_BUSINESS_INDEX, indexes.PHONE_MOBILE_BUSINESS);
    } else {
      this.insert(this.lead.communicationAddresses, indexes.PHONE_MOBILE_BUSINESS, new CommunicationAddress({
        commAddrType: new SDAKey({
          key: CommAddrTypes.PHONE_MOBILE_BUSINESS,
          sdaValue: 'MOBILE_BUSINESS',
          sorValue: 'MOBILE_BUSINESS'
        }),
        address: form.phone1,
        info: null
      }));

    }

    const PHONE_MOBILE_PRIVATE_INDEX = this.lead.communicationAddresses.findIndex(x => x.commAddrType.key === CommAddrTypes.PHONE_MOBILE_PRIVATE)

    if (PHONE_MOBILE_PRIVATE_INDEX > -1) {
      this.lead.communicationAddresses[PHONE_MOBILE_PRIVATE_INDEX].address = form.phone2;
      this.arrayMove(this.lead.communicationAddresses, PHONE_MOBILE_PRIVATE_INDEX, indexes.PHONE_MOBILE_PRIVATE);
    } else {
      this.insert(this.lead.communicationAddresses, indexes.PHONE_MOBILE_PRIVATE, new CommunicationAddress({
        commAddrType: new SDAKey({
          key: CommAddrTypes.PHONE_MOBILE_PRIVATE,
          sdaValue: 'MOBILE_PRIVATE',
          sorValue: 'MOBILE_PRIVATE'
        }),
        address: form.phone2,
        info: null
      }));
    }

    const EMAIL_BUSINESS_INDEX = this.lead.communicationAddresses.findIndex(x => x.commAddrType.key === CommAddrTypes.EMAIL_BUSINESS)

    if (EMAIL_BUSINESS_INDEX > -1) {
      this.lead.communicationAddresses[EMAIL_BUSINESS_INDEX].address = form.email1;
      this.arrayMove(this.lead.communicationAddresses, EMAIL_BUSINESS_INDEX, indexes.EMAIL_BUSINESS);

    } else {

      this.insert(this.lead.communicationAddresses, indexes.EMAIL_BUSINESS, new CommunicationAddress({
        commAddrType: new SDAKey({
          key: CommAddrTypes.EMAIL_BUSINESS,
          sdaValue: 'EMAIL_BUSINESS',
          sorValue: 'EMAIL_BUSINESS'
        }),
        address: form.email1,
        info: null
      }))
    }

    const EMAIL_PRIVATE_INDEX = this.lead.communicationAddresses.findIndex(x => x.commAddrType.key === CommAddrTypes.EMAIL_PRIVATE)

    if (EMAIL_PRIVATE_INDEX > -1) {
      this.lead.communicationAddresses[EMAIL_PRIVATE_INDEX].address = form.email2;
      this.arrayMove(this.lead.communicationAddresses, EMAIL_PRIVATE_INDEX, indexes.EMAIL_PRIVATE);

    } else {
      this.insert(this.lead.communicationAddresses, indexes.EMAIL_PRIVATE, new CommunicationAddress({
        commAddrType: new SDAKey({
          key: CommAddrTypes.EMAIL_PRIVATE,
          sdaValue: 'EMAIL_PRIVATE',
          sorValue: 'EMAIL_PRIVATE'
        }),
        address: form.email2,
        info: null
      }));
    }
  }
  resetValue(){
    if(this.selectedType === this.types[0]){
      this.mortgage.reset();
      this.selectedType = this.types[0];
      this.selectedSalutation=this.salutations[0];
      this.mortgage.get('mortgageType').setValue(this.selectedType);
      this.mortgage.get('salutation').setValue(this.selectedSalutation);
      this.newMortgage.get('objectType').setValue(this.selectedObjectType);
      this.newMortgage.get('income').setValue(100000);
      this.newMortgage.get('valueOfProperty').setValue(500000);
      this.newMortgage.get('ownFunds').setValue(100000);
      this.newMortgage.get('zinc').setValue(1.2);
    }else{
      this.mortgage.reset();
      this.mortgage.get('salutation').setValue(this.selectedSalutation);
      this.selectedType = this.types[0];
      this.selectedSalutation=this.salutations[0];
    }
  }

  async sendCreateLead() {

    const sorKeys = {
      'advisorId': this.user.attributes.advisorid[0],
      'agenturId': this.user.attributes.agenturID[0]
      
    }

    const referenceAgentur = new Reference();
    referenceAgentur.id = this.user.attributes.agenturID[0]
    referenceAgentur.type = new SDAKey();
    referenceAgentur.type.key = "090";
    referenceAgentur.type.value = "Agentur";

    const referencesBerater = new Reference();
    referencesBerater.id = this.user.attributes.advisorid[0]
    referencesBerater.type = new SDAKey();
    referencesBerater.type.key = "080";
    referencesBerater.type.value = "Berater";

    const classification = new Reference();
    classification.id = this.lead.uuid;
    classification.type = new SDAKey();
    classification.type.key = "040";
    classification.type.value = "Partner";

    const creationDate = moment().format('YYYY-MM-DDTHH:mm:ss.SSSZZ');
    let actionData
    if(this.selectedType === this.types[0]){

    actionData = BusinessTransactionHelper.createLeadBTActionObject(
      LeadLog.CREATE_LEAD,
      BusinessTransactionHelper.typeId.neMortgage.from,
      '#MKP Create new mortgage',
      '#MKP Create new mortgage',
      '',
      this.user.attributes.advisorid[0],
      [this.pdfDocument],
      this.lead,
      '',
      sorKeys,
      this.user.firstName
    );
    } else{
      actionData = BusinessTransactionHelper.createLeadBTActionObject(
        LeadLog.CREATE_LEAD,
        BusinessTransactionHelper.typeId.mortgage.from,
        '#MKP Create mortgage',
        '#MKP Create mortgage',
        '',
        this.user.attributes.advisorid[0],
        [this.pdfDocument],
        this.lead,
        '',
        sorKeys,
        this.user.firstName
      );
    }

    actionData.transaction.references.push(referenceAgentur, referencesBerater);
    actionData.transaction.creationDate = creationDate;
    actionData.transaction.classification = classification;

    this.subscriptions.push(
      this.leadsService.sendLeadAction(actionData)
        .subscribe(() => {
          this.snackBar.open("Lead created successfully set", "X", UserCommitState.configSuccess);
          this.searchFromParams(this.routerParams);
        }, err => {
          log.error(`Something went wrong while calling POST-> lead/action `, err);
          this.snackBar.open("Something went wrong!", "X", UserCommitState.configError)
        })
    );
    await this.resetValue();
    this.selectedType=this.types[0];
    this.selectedSalutation=this.salutations[0];


  }
  
  ngOnDestroy(): void {
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
  }
}
