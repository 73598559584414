import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {from, Observable, of} from "rxjs";
import { Config } from "../model/config";
import {RestProvider, RestProviderActionsDummy} from "./rest.provider";
import {log} from "./logger.provider";
import {environment} from "../../../../environments/environment";
import {KeyValuePair} from "../model/key-value-pair";
import { map } from "rxjs/operators";

@Injectable()
export class ConfigProvider {
    private readonly CLASS_NAME = this.constructor.name;
    private configSettings: Config[] = [];
    private applicationStatuses: KeyValuePair[] = [];

    constructor(public http: HttpClient, public rest: RestProvider) {
    }


    public getConfigValue(filter: {defaultTypeId?: string, from?: string, to?: string, typeId?: string}): Observable<{selectedType: string, data: KeyValuePair[]}> {
        var selectedType = '';
        return this.getConfig().pipe(map(
            config => {
                if (config) {
                    let data;
                    if (filter.typeId) {
                        data = config.filter(x => x.key == filter.typeId);
                        selectedType = filter.typeId;
                        if (data.length == 0) {
                            selectedType = filter.defaultTypeId;
                            data = config.filter(x => x.key == filter.defaultTypeId);
                        }
                    } else {
                        selectedType = filter.defaultTypeId;
                        data = config.filter(x => x.key == filter.defaultTypeId);
                    }
                    return {selectedType, data: (data && data.length > 0 ? data.shift().values : []) };
                }
                return {selectedType, data: []};
            }
        ));
    }

    public getConfig(): Observable<Config[]> {
        if (this.configSettings !== undefined && this.configSettings.length > 0) {
            log.info(`${this.CLASS_NAME} using cached data.`);
            return of(this.configSettings);
        }
        return from(new Promise<Config[]>((resolve, reject) => {
            this.rest.get<Config[]>(`${environment.settings.BASE_URL}/api/configs`, null, true, new RestProviderActionsDummy()).toPromise()
                .then(response => {
                        this.configSettings = response;
                        resolve(response);
                    },
                    error => {
                        reject(error);
                    });
        }));
    }

    public getApplicationStatuses(): Observable<KeyValuePair[]> {
        return from(new Promise<KeyValuePair[]>((resolve, reject) => {
            this.rest.get<KeyValuePair[]>(`${environment.settings.BASE_URL}/api/configs/product-bundles/statuses`, null, true, new RestProviderActionsDummy()).toPromise()
                .then(response => {
                        this.applicationStatuses = response;
                        resolve(response);
                    },
                    error => {
                        reject(error);
                    });
        }));
    }

    public filterConfigsByValue(configs: Config[], filter: {defaultTypeId: string, typeId?: string}): {selectedType: string, data: Config} {
        var selectedType = '';
        var data: Config;
        if (filter.typeId) {
            data = configs.find(x => x.key == filter.typeId);
            selectedType = filter.typeId;
         } else {
                selectedType = filter.defaultTypeId;
                data = configs.find(x => x.key == filter.defaultTypeId);
        }

        return {selectedType, data }
    }

    public filterConfigsByValueRange(configs: Config[], filterKeys: string[]): Config[] {
        return configs.filter(x => filterKeys.includes(x.key));
    }

    public getConfigValueByRange(filter: {defaultTypeId?: string, from?: string, to?: string, typeId?: string}): Observable<{selectedType: string, data: KeyValuePair[]}> {
        var selectedType = "";
        var dataKey = [];
        return this.getConfig().pipe(map(
            config => {
                if (config) {
                    let data;
                    if (filter.typeId) {
                        data = config.filter(x => x.key == filter.typeId);
                        selectedType = filter.typeId;
                        if (data.length == 0) {
                            selectedType = `${filter.from}, ${filter.to}`;
                            data = config.filter(x => Number(x.key) >= Number(filter.from) && Number(x.key) <= Number(filter.to));
                        }
                    } else {
                        selectedType = `${filter.from}, ${filter.to}`;
                        data = config.filter(x => Number(x.key) >= Number(filter.from) && Number(x.key) <= Number(filter.to));
                        data.forEach(x => {
                            x.values.map(y => {
                                dataKey.push({
                                    key: y.key,
                                    value: y.value
                                })
                            })
                        })
                    }
                    return {selectedType, data: (data && data.length > 0 ? dataKey : []) };
                }
                return {selectedType, data: []};
            }
        ));
    }
}
