import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'termin-utils-calendar-header',
  styleUrls: ['./termin-calendar.component.scss'],
  templateUrl: './calendar-header.component.html',
})
export class CalendarHeaderComponent {
  @Input() view: string;

  @Input() viewDate: Date;

  @Input() locale: string;

  @Output() viewChange: EventEmitter<string> = new EventEmitter();

  @Output() viewDateChange: EventEmitter<Date> = new EventEmitter();
}
