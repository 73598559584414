import {Component, ViewChild} from "@angular/core";
import {FormControl} from "@angular/forms";
import {Helper} from "../../static/helper";
import {FilterTableComponent} from "./filter-table-component";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {JournalList} from "../../model/journal-list";
import {JournalListJson} from "../../json/journal-list.json";
import {Router} from "@angular/router";
import {RegisterProvider} from "../../providers/register.provider";
import {ActionDialogData} from "../action.dialog.component";
import {log} from "../../providers/logger.provider";
import {KeyValuePair} from "../../model/key-value-pair";
import {RestProviderActionsDummy} from "../../providers/rest.provider";
import {HouseholdProvider} from "../../providers/household.provider";
import {ActionDialogService} from "../../providers/action-dialog.service";
import {BusinessTransactionHelper} from "../../static/bt-helper";
import {Policy} from "../../model/policy";
import { SlideRangeFilterValue } from "../../model/slide-range-filter-value";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { JournalProvider } from "../../providers/journal.provider";
import { LoadingService } from "../../providers/loading.component";
import { Observable, Subscription } from "rxjs";
import { LoadingDataComponent } from "../loading-data.component";
import moment from "moment";

@Component({
    selector: "neomp-open-issues-table",
    templateUrl: "./open-issues-table.component.html",
    styleUrls: ["./open-issues-table.component.scss"],
    animations: [
        trigger("detailExpand", [
            state("collapsed, void", style({height: "0px", minHeight: "0", display: "none"})),
            state("expanded", style({height: "*"})),
            transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")),
            transition("expanded <=> void", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)"))
        ]),
    ]
})

export class OpenIssuesTableComponent extends FilterTableComponent<JournalList> {
    private readonly TAG = this.constructor.name;

    selectedOpenIssuesRowIndex;
    isDialogOpen = false;
    expandedElement: JournalList | null;

    policyIdFilter = new FormControl("");
    inputChannelFilter = new FormControl("");
    statusNameFilter = new FormControl("");
    riskCarrierFilter = new FormControl("");
    sparteFilter = new FormControl("");
    customerNameFilter = new FormControl("");
    customerBirthdayFilter = new FormControl("");
    effectiveDateFilter = new FormControl("");

    filterValues: JournalList = new JournalList();

    isDataLoading: boolean;  
    isInitialized: boolean;
    completedIssue: boolean = true;
    closedIssuesAll:JournalList[] = []
    repliesTableData: JournalList[] = [];
    @ViewChild("closedIssuesTable") journalsTableLoadingComponent: LoadingDataComponent;
    filterValueForIssuesClosed: SlideRangeFilterValue = new SlideRangeFilterValue();
    subscriptions: Subscription[];

    formControlFilters: FormControl[] = [null,
        this.policyIdFilter,
        this.inputChannelFilter,
        this.statusNameFilter,
        this.riskCarrierFilter,
        this.sparteFilter,
        this.customerNameFilter,
        this.customerBirthdayFilter,
        this.effectiveDateFilter,
        null];

    displayedColumns = [
        "status",
        "policyId",
        "inputChannel",
        "statusName",
        "riskCarrier",
        "sparte",
        "customerName",
        "customerBirthday",
        "effectiveDate",
        "filter"
    ];

    displayedColumnFilters = [
        "statusFilter",
        "policyIdFilter",
        "inputChannelFilter",
        "statusNameFilter",
        "riskCarrierFilter",
        "sparteFilter",
        "customerNameFilter",
        "customerBirthdayFilter",
        "effectiveDateFilter",
        "filterRemove"
    ];


    constructor(public router: Router,
                public registerProvider: RegisterProvider,
                public households: HouseholdProvider,
                public dialogService: ActionDialogService,
                public dialog: MatDialog,
                public matSnackBar: MatSnackBar,
                public journalProvider: JournalProvider,
                private loadingService: LoadingService,) {
        super();
    }

    ngOnInit() {
        this.filterValueForIssuesClosed.isActive = true;
        this.filterValueForIssuesClosed.dateRange.end = moment();
        this.filterValueForIssuesClosed.dateRange.begin = moment().subtract(60, "days"); 
        this.getAllClosedIssues(this.filterValueForIssuesClosed);
    }

    ngAfterViewInit(): void {
        this.isInitialized = true;
    }

    set dataLoading(dataLoading: boolean) {
        if (this.isInitialized) {
            this.isDataLoading = dataLoading;
        }
    }
    
    getAllClosedIssues(filterValue) {
        this.loadingService.display(true);
        this.dataLoading = true;
        const sort = {"status.name": -1, "effectiveDate": -1};
        const journalObservable: Observable<{data: JournalList[], totalCount: number}> =
            filterValue.isActive
                ?
            this.journalProvider.getJournalsReferencedByDateRangeWithPagination(
                this.journalsTableLoadingComponent,
                BusinessTransactionHelper.typeId.pendingMatter.from,
                BusinessTransactionHelper.typeId.pendingMatter.to,
                filterValue.dateRange, undefined, undefined, sort, this.completedIssue)
                :
            this.journalProvider.getJournalsReferencedWithPagination(
                this.journalsTableLoadingComponent,
                BusinessTransactionHelper.typeId.pendingMatter.from,
                BusinessTransactionHelper.typeId.pendingMatter.to,
                undefined,
                undefined,
                sort,
                this.completedIssue
            );
               
             journalObservable.subscribe(journals => {
                this.closedIssuesAll = journals.data;
                this.closedIssuesAll = this.closedIssuesAll.reverse();
                this.dataLoading = false;
                this.loadingService.display(false);
            }, error => {
                this.dataLoading = false;
                this.loadingService.display(false);
            });
    }

    customerBirthdayFilterChanged(event: SlideRangeFilterValue): void {
        this.customerBirthdayFilter.setValue(event.dateRange);
    }

    effectiveDateFilterChanged(event: SlideRangeFilterValue): void {
        this.effectiveDateFilter.setValue(event.dateRange);
    }

    getClassFromStatus(effectivDate, status): string | undefined {
        if(effectivDate === 'Invalid date' && status != 'Abgeschlossen'){
            return 'inactive-status';
        }
        const days = Helper.getdifferenceInDaysfromToday(effectivDate);
        if (status == 'Abgeschlossen') {
            return "completed-status"
        } else {
            if (days <= 1 && status != 'Abgeschlossen') {
                return "inactive-status";
            } else if (days <= 5 && status != 'Abgeschlossen') {
                return "warning-status";
            }
            return "active-status"
        } 
  
    }

    openIssuesRowClick(openIssuesRow: JournalList) {
        if (this.expandedElement === openIssuesRow) {
            this.selectedOpenIssuesRowIndex = -1;
            this.expandedElement = null;
            return;
        } 
            this.selectedOpenIssuesRowIndex = openIssuesRow.transaction.sorKeys.ProcessID;
            this.expandedElement = openIssuesRow;
            this.repliesTableData = this.closedIssuesAll.filter((element) => 
            element.transaction.operationIdExternal === this.selectedOpenIssuesRowIndex
            );
    }      

    createFilter(): (data: JournalList, filter: string) => boolean {
        return function (data, filter): boolean {
            const filterJson: JournalListJson = JSON.parse(filter);
            const searchTerms: JournalList = JournalList.fromJson(filterJson);
            return (Helper.checkStringContains(data.policyId, searchTerms.policyId) &&
                Helper.checkStringContains(data.inputChannel, searchTerms.inputChannel) &&
                Helper.checkStringContains(data.statusName, searchTerms.statusName) &&
                Helper.checkStringContains(data.riskCarrier, searchTerms.riskCarrier) &&
                Helper.checkStringContains(data.sparte, searchTerms.sparte) &&
                Helper.checkStringContains(data.customerName, searchTerms.customerName) &&
                Helper.checkDateRange(new Date(data.customerBirthday), Helper.stringToDatePickerFormat(searchTerms.customerBirthday)) &&
                Helper.checkDateRange(new Date(data.effectiveDate), Helper.stringToDatePickerFormat(searchTerms.effectiveDate)));
        }
    }

    resetFilter() {
        this.filterValues = new JournalList();
        super.resetFilter();
    }

    isProductButtonDisabled(element: JournalList) {
        return element ? (element.policyId === "" || element.customerId === null) : true;
    }

    toProduct(element: JournalList) {
        const policy = element.policies.find(p => p.uuid === element.policyId);
        if (policy && policy.partners && policy.partners.length > 0) {
            const advisor = policy.partners.find(p => p.type.sorValue === "ADVISOR");
            const partner = policy.partners.find(p => p.type.sorValue === "CUSTOMER");
            if (advisor && partner) {
                this.households.getCompleteHouseholdByUuid(partner.id, new RestProviderActionsDummy())
                    .subscribe(completeHousehold => {
                        if (completeHousehold !== null) {
                            const baseUrl = `${this.registerProvider.getPath("MyCustomersDetail")}/${element.customerId}`;
                            const url = `${baseUrl}/policy/${policy.uuid}`;
                            this.router.navigate([url]);
                        } else {
                            log.error(`${this.TAG}: toProduct -> has not found person with ${partner.id} in households`);
                        }
                    });
            } else {
                log.error(`${this.TAG}: toProduct -> element.policy is missing either ADVISOR, CUSTOMER or both`);
            }
        } else {
            log.debug(`${this.TAG}: toProduct -> row has no valid policy set`);
        }
    }

    formatDate(date) {
        return Helper.formatDate(date);
    }

    openActionDialog(event: Event, element: JournalList) {
        this.isDialogOpen = true;
        event.stopPropagation();

        const policy = element.policies.find(p => p.uuid === element.policyId);
        const dialogData: ActionDialogData = {
            transTypeId: BusinessTransactionHelper.typeId.pendingMatter.from,
            transName: "Pendenzen",
            transSubject: "Pendenzen",
            transTitle: "Pendenzen",
            transMessage: "Pendenzen",
            operationalIdExternal: element.transaction.sorKeys["ProcessID"],
            keyValues: [],
            person: null,
            policy: policy
        };
        this.dialogService.openByType(dialogData,
            {
                defaultTypeId: BusinessTransactionHelper.typeId.pendingMatter.from
            }
            , this.TAG
        );
        this.dialog.afterAllClosed.subscribe(() => {
            this.isDialogOpen = false;
        });
        this.subscriptions.push(
            this.dialogService.isSaved.subscribe(data =>{
                if(data){
                    this.getAllClosedIssues(this.filterValueForIssuesClosed)
                }
                this.subscriptions.forEach(subscription=> subscription.unsubscribe());
            })
        );
    }
}
