<table [dataSource]="dataSource" class="msg-helper-width-100"
       mat-table
       matSort>
    <ng-container matColumnDef="status">
        <th *matHeaderCellDef mat-header-cell></th>
        <td *matCellDef="let element" mat-cell></td>
    </ng-container>
    <ng-container matColumnDef="creationDate">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'PAGE.OFFER.CREATED.DATE' | translate }}</th>
        <td *matCellDef="let element" mat-cell> {{element.creationDate | date: 'dd.MM.yyyy'}}</td>
    </ng-container>
    <ng-container matColumnDef="completed">
        <th *matHeaderCellDef mat-header-cell
            mat-sort-header>{{ 'PAGE.OFFER.CREATED.STATUS' | translate }}</th>
        <td mat-cell *matCellDef="let element">
            {{(element.completed === true ? 'PAGE.APPLICATIONS.TABLE.STATUS.DONE' : 'PAGE.APPLICATIONS.TABLE.STATUS.UNDONE') | translate}}
        </td>
    </ng-container>
    <ng-container matColumnDef="descriptionField">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'PAGE.OFFER.CREATED.CUSTOMER' | translate }}</th>
        <td *matCellDef="let element"
            mat-cell>{{descriptionField !== null ? element.descriptionField : 'PAGE.OFFER.CREATED.NOT_SET' | translate}}</td>
    </ng-container>
    <ng-container matColumnDef="reasonForChange">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'PAGE.OFFER.CREATED.LINE_OF_BUSINESS' | translate }}</th>
        <td *matCellDef="let element" mat-cell>{{element.reasonForChange}}</td>
    </ng-container>

    <ng-container matColumnDef="filter">
        <th (click)="changeFilterView()" *matHeaderCellDef class="mat-header-cell mat-column-filter {{isFilterActive ? 'mat-column-filter-active':''}}"
            mat-header-cell>
            <div
                class="neomp-table-column-filter-element  mdi {{isFilterActive ? 'mdi-filter':'mdi-filter-outline'}}"></div>
        </th>
        <td *matCellDef="let element" mat-cell class="mat-column-filter-action disabled">
            <div class="neomp-table-column-filter-element mdi mdi-plus" [disabled]="isDialogOpen"
            (click)="isDialogOpen ? null : null"></div>
        </td>
    </ng-container>

    <!-- Filter row definition -->
    <ng-container matColumnDef="completedFilter">
        <th *matHeaderCellDef mat-header-cell>
            <mat-form-field class="filter" floatLabel="never">
                <input
                    [formControl]="completedFilter"
                    placeholder="{{ 'TABLES.FILTERPLACEHOLDER.STATUS' | translate }}"
                    matInput>
            </mat-form-field>
        </th>
    </ng-container>
    <ng-container matColumnDef="descriptionFieldFilter">
        <th *matHeaderCellDef mat-header-cell>
            <mat-form-field class="filter" floatLabel="never">
                <input
                    [formControl]="descriptionFieldFilter"
                    placeholder="{{ 'TABLES.FILTERPLACEHOLDER.NAME' | translate }}"
                    matInput>
            </mat-form-field>
        </th>
    </ng-container>
    <ng-container matColumnDef="creationDateFilter">
        <th *matHeaderCellDef mat-header-cell>
            <mat-form-field floatLabel="never">
                <input [formControl]="creationDateFilter"
                       [matDatepicker]="creationDatePicker"
                       placeholder="{{ 'TABLES.FILTERPLACEHOLDER.DATE' | translate }}"
                       matInput>
                <mat-datepicker-toggle [for]="creationDatePicker" matSuffix></mat-datepicker-toggle>
                <mat-datepicker #creationDatePicker></mat-datepicker>
            </mat-form-field>
        </th>
    </ng-container>
    <ng-container matColumnDef="reasonForChangeFilter">
        <th *matHeaderCellDef mat-header-cell>
            <mat-form-field class="filter" floatLabel="never">
                <input
                    [formControl]="reasonForChangeFilter"
                    placeholder="{{ 'TABLES.FILTERPLACEHOLDER.SECTION' | translate }}"
                    matInput>
            </mat-form-field>
        </th>
    </ng-container>
    <ng-container matColumnDef="filterRemove">
        <th (click)="resetFilter()" *matHeaderCellDef mat-header-cell>
            <div class="neomp-table-column-filter-element mdi mdi-close"></div>
        </th>
    </ng-container>
    <!--end of  Filter row definition-->


    <tr *matHeaderRowDef="displayedHeaderColumns"
        mat-header-row></tr>

    <tr *matHeaderRowDef="displayedColumnFilters"
        class="neomp-table-row-filter {{showFilter? '': 'neomp-table-row-filter-hide'}}"
        mat-header-row></tr>
    <tr (click)="journalRowClick(journalRow)"
        *matRowDef="let journalRow; columns: displayedColumns"
        class="neomp-table-row-selected container-table-row {{journalRow.uuid === selectedJournalRowIndex ? 'container-table-row-selected':''}}"
        mat-row></tr>
</table>
