import { Injectable } from "@angular/core";
import { RestProvider } from "./rest.provider";
import { environment } from "../../../../environments/environment";
import { saveAs } from "file-saver";

@Injectable({
  providedIn: "root",
})
export class PdfProvider {
  constructor(public rest: RestProvider) {}

  downloadPdf(type: string, data: any) {
    switch (type) {
      case "BVG": {
        const body = {
          title: data.salutation || "",
          address: data.address.street || "",
          email: this.getEmail(data.communicationAddresses),
          plz: data.address.postalCode || "",
          ort: data.address.city || "",
          telefon: this.getMobile(data.communicationAddresses),
        };
        this.download("bvg", body, data.fullName);
        break;
      }

      case "Berufshaftpflicht": {
        const body = {
          telefon: this.getMobile(data.communicationAddresses),
          address: data.address.street || "",
          email: this.getEmail(data.communicationAddresses),
          plz: data.address.postalCode || "",
          ort: data.address.city || "",
        };
        this.download("berufshaftpflicht", body, data.fullName);
        break;
      }

      case "Betriebshaftpflicht": {
        const body = {
          telefon: this.getMobile(data.communicationAddresses),
          address: data.address.street || "",
          email: this.getEmail(data.communicationAddresses),
          plz: data.address.postalCode || "",
          ort: data.address.city || "",
        };
        this.download("betriebshaftpflicht", body, data.fullName);
        break;
      }

      case "Geschäftsversicherung": {
        const body = {
          vorname: data.firstName || "",
          name: data.name || "",
          address: data.address.street || "",
          plz: data.address.postalCode || "",
          ort: data.address.city || "",
          email: this.getEmail(data.communicationAddresses),
          telefon: this.getMobile(data.communicationAddresses),
        };
        this.download("geschaftsversicherung", body, data.fullName);

        break;
      }

      case "HR/HP": {
        const body = {
          title: data.salutation || "",
          vorname: data.firstName || "",
          name: data.name || "",
          address: data.address.street || "",
          birthdate: data.birthdate || "",
          plz: data.address.postalCode || "",
          ort: data.address.city || "",
          nationality: data.nationality || "",
          civilStatus: data.maritalStatus || "",
          email: this.getEmail(data.communicationAddresses),
          telefon: this.getMobile(data.communicationAddresses),
        };
        this.download("hrhp", body, data.fullName);
        break;
      }

      case "Kautionsversicherungen": {
        const body = {
          address: data.address.street || "",
          plz: data.address.postalCode || "",
          ort: data.address.city || "",
          telefon: this.getMobile(data.communicationAddresses),
          email: this.getEmail(data.communicationAddresses),
        };

        this.download("kautionsversicherung", body, data.fullName);
        break;
      }

      case "Lebensversicherung": {
        const body = {
          title: data.salutation || "",
          vorname: data.firstName || "",
          name: data.name || "",
          nationality: data.nationality || "",
          address: data.address.street || "",
          birthdate: data.birthdate || "",
          plz: data.address.postalCode || "",
          ort: data.address.city || "",
          email: this.getEmail(data.communicationAddresses),
          telefon: this.getMobile(data.communicationAddresses),
        };

        this.download("einzellebenversicherung", body, data.fullName);
        break;
      }
      
      case "Mandatsformular": {
        const body = {
          name: data.name || "",
          vorname: data.firstName || "",
          birthdate: data.birthdate || "",
          address: data.address.street || "",
          telefon: this.getMobile(data.communicationAddresses),
          plz: data.address.postalCode || "",
          email: this.getEmail(data.communicationAddresses),
        };

        this.download("mandatsformular", body, data.fullName);
        break;
      }

      case "Motorfahrzeug": {
        const body = {
          title: data.salutation || "",
          address: data.address.street || "",
          plz: data.address.postalCode || "",
          ort: data.address.city || "",
          vorname: data.firstName || "",
          name: data.name || "",
          email: this.getEmail(data.communicationAddresses),
          birthdate: data.birthdate || "",
          civilStatus: data.maritalStatus || "",
          nationality: data.nationality || "",
          telefon: this.getMobile(data.communicationAddresses),
        };

        this.download("motorfahrzeug", body, data.fullName);
        break;
      }

      case "Rechtsschutz": {
        const body = {
          title: data.salutation || "",
          vorname: data.firstName || "",
          name: data.name || "",
          nationality: data.nationality || "",
          address: data.address.street || "",
          birthdate: data.birthdate || "",
          plz: data.address.postalCode || "",
          ort: data.address.city || "",
          civilStatus: data.maritalStatus || "",
          email: this.getEmail(data.communicationAddresses),
          telefon: this.getMobile(data.communicationAddresses),
        };

        this.download("rechtsschutz", body, data.fullName);
        break;
      }

      case "UVG": {
        const body = {
          type: "UVG",
          address: data.address.street || "",
          plz: data.address.postalCode || "",
          ort: data.address.city || "",
        };

        this.download("uvg", body, data.fullName);
        break;
      }

      case "UVG-Z": {
        const body = {
          type: "UVG-Z",
          address: data.address.street || "",
          plz: data.address.postalCode || "",
          ort: data.address.city || "",
        };

        this.download("uvg", body, data.fullName);
        break;
      }
    }
  }

  download(url: string, data: any, pdfName: string) {
    this.rest
      .postBlob(`${environment.settings.MEDIA_URL}/api/${url}`, data)
      .subscribe((res) => {
        var file = new Blob([res], { type: "application/pdf" });
        saveAs(file, pdfName + ".pdf");
      });
  }

  getMobile(communicationAddresses): string {
    const mobile = communicationAddresses.find(
      (x) =>
        x.commAddrType.key != "" &&
        (x.commAddrType.key == "10" || x.commAddrType.key == "31")
    );
    if (mobile) {
      return mobile.address || "";
    }
    return "";
  }
  getEmail(communicationAddresses): string {
    const email = communicationAddresses.find(
      (x) => x.commAddrType.key != "" && x.commAddrType.key == "51"
    );
    if (email) {
      return email.address || "";
    }
    return "";
  }
}
