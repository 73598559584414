<div class="mdc-layout-grid msg-helper-padding-none lead-create-wrapper">
  <div class="mdc-layout-grid__inner">
    <div
      class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
      <div class="mdc-layout-grid__inner">
        <div
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-9-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
          <neomp-container [isLightContainer]="false" cssClass="msg-mp-container-frameless" class="neo-padding-none"
            headlineText="{{ 'PAGE.LEADS.LEAD.ADD-NEW-LEAD' | translate }}">
          </neomp-container>
        </div>
        <div
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone neo-create-lead-button-container">
          <div class="neo-crate-lead-btn">
            <button [disabled]="createLeadForm.invalid" mat-raised-button color="primary" (click)="createLead()" [ngClass]="{'hide-icon': createLeadForm.invalid}">
              <div class="btn-child"><img src="/assets/icons/create_lead.svg"></div>
              <div class="btn-child"><span>{{ 'PAGE.LEADS.LEAD.CREATE-LEAD' | translate }}</span></div>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
      <neomp-container class="neo-display-none">
        <form [formGroup]="createLeadForm">
          <div
            class="mdc-layout-grid mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone msg-helper-padding-none neo-color neo-space-padding">
            <section>
              <div class="mdc-layout-grid__inner">
                <div
                  class="mdc-layout-grid__cell--span-12 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                  <h1>{{'PAGE.LEADS.LEAD.PERSONALIEN' | translate}}</h1>
                </div>
                <div
                  class="mdc-layout-grid__cell--span-3 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                  <mat-form-field appearance="fill" class="neo-leads-input-full-width">
                    <mat-label>Bitte auswählen</mat-label>
                    <mat-select formControlName="lead_typ">
                      <mat-option *ngFor="let type of newLeadType" [value]="type">
                        {{ type }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div
                  class="mdc-layout-grid__cell--span-3 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet"
                  *ngIf="createLeadForm.controls.lead_typ.value === 'Lead / Termin'">
                  <mat-checkbox formControlName="appointment" class="neo-appointment-checkbox"
                    (change)="checkboxValueChange()">
                    {{'PAGE.CONTAINER.CUSTOMERS.CONTAINER.APPOINTMENTS' | translate}}
                  </mat-checkbox>
                </div>
              </div>
            </section>

            <section>
              <div class="mdc-layout-grid__inner">
                <div
                  class="mdc-layout-grid__cell--span-3 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                  <mat-form-field appearance="fill" class="neo-leads-input-full-width">
                    <mat-label>{{'PAGE.LEADS.LEAD.LEAD-EDIT.LANGUAGE' | translate}}</mat-label>
                    <mat-select formControlName="preferedLanguage">
                      <mat-option *ngFor="let lang of leadConfigs.LANGUAGE" [value]="lang.value">
                        {{ lang.value }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div
                  class="mdc-layout-grid__cell--span-9 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                  <mat-form-field class="neo-leads-input-full-width">
                    <mat-label>{{'PAGE.LEADS.LEAD.LEAD-EDIT.SOURCE' | translate}}</mat-label>
                    <textarea matInput formControlName="lead_source" class="textarea"></textarea>
                  </mat-form-field>
                </div>

              </div>
            </section>

            <section>
              <div class="mdc-layout-grid__inner">

                <!-- SALUTATION -->
                <div
                  class="mdc-layout-grid__cell--span-3 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                  <mat-form-field appearance="fill" class="neo-leads-input-full-width">
                    <mat-label>{{'PAGE.LEADS.LEAD.LEAD-EDIT.ANREDE' | translate}}</mat-label>
                    <mat-select formControlName="salutation">
                      <mat-option *ngFor="let s of leadConfigs.SALUTATION" [value]="s.key">
                        {{ s.value }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <!-- NAME -->
                <div
                  class="mdc-layout-grid__cell--span-3 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                  <mat-form-field class="neo-leads-input-full-width">
                    <mat-label>{{'PAGE.LEADS.LEAD.LEAD-EDIT.NAME' | translate}} </mat-label>
                    <input class="font-family" matInput type="text" formControlName="name" />
                  </mat-form-field>
                </div>

                <!-- SURNAME -->
                <div
                  class="mdc-layout-grid__cell--span-3 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                  <mat-form-field class="neo-leads-input-full-width">
                    <mat-label>{{'PAGE.LEADS.LEAD.LEAD-EDIT.SURNAME' | translate}} </mat-label>
                    <input class="font-family" matInput type="text" formControlName="firstName" />
                  </mat-form-field>
                </div>

                <!-- BIRTHDATE -->
                <div
                  class="mdc-layout-grid__cell--span-3 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                  <mat-form-field class="neo-leads-input-full-width">
                    <mat-label>{{'PAGE.LEADS.LEAD.LEAD-EDIT.BIRTHDATE' | translate}}</mat-label>
                    <input [matDatepicker]="birthdate" matInput formControlName="birthday" />
                    <mat-datepicker-toggle matSuffix [for]="birthdate"></mat-datepicker-toggle>
                    <mat-datepicker #birthdate></mat-datepicker>
                  </mat-form-field>
                </div>


              </div>
            </section>

            <section>
              <div class="mdc-layout-grid__inner">
                <!-- STREET -->
                <div
                  class="mdc-layout-grid__cell--span-3 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                  <mat-form-field class="neo-leads-input-full-width">
                    <mat-label>{{'PAGE.LEADS.LEAD.LEAD-EDIT.STR' | translate}}</mat-label>
                    <input class="font-family" matInput type="text" formControlName="address" />
                  </mat-form-field>
                </div>

                <!-- POSTAL CODE -->
                <div
                  class="mdc-layout-grid__cell--span-3 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                  <mat-form-field class="neo-leads-input-full-width">
                    <mat-label>{{'PAGE.LEADS.LEAD.LEAD-EDIT.ZIP' | translate}}</mat-label>
                    <input class="neo-login-input" matInput formControlName="zip"
                    placeholder="{{'PAGE.CUSTOMERDETAIL.CONTACT.POSTALCODE' | translate}}" [matAutocomplete]="auto">
                    
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedRegion($event)"
                      [displayWith]="displayRegionFn.bind(this)">
                      <mat-option *ngFor="let option of filteredOptionsRegionen | async" [value]="option">
                        {{option.PLZ}} - {{option.Ort}} ({{option.Gemeinde}})
                      </mat-option>
                    </mat-autocomplete>

                  </mat-form-field>
                </div>

                <!-- ORT -->
                <div
                  class="mdc-layout-grid__cell--span-3 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                  <mat-form-field class="neo-leads-input-full-width">
                    <mat-label>{{'PAGE.LEADS.LEAD.LEAD-EDIT.CITY' | translate}}</mat-label>
                    <input class="font-family" matInput type="text" formControlName="ort" />
                  </mat-form-field>
                </div>

                <!-- KANTON -->
                <div
                  class="mdc-layout-grid__cell--span-3 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                  <mat-form-field class="neo-leads-input-full-width">
                    <mat-label>{{'PAGE.LEADS.LEAD.LEAD-EDIT.KANTON' | translate}}</mat-label>
                    <input class="font-family" matInput type="text" formControlName="kanton" />
                  </mat-form-field>
                </div>

              </div>
            </section>

            <section>
              <div class="mdc-layout-grid__inner">

                <!-- TELEFON Private -->
                <div
                  class="mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                  <mat-form-field class="neo-leads-input-full-width">
                    <mat-label>{{'PAGE.LEADS.LEAD.LEAD-EDIT.PHONE' | translate}}</mat-label>
                    <input class="font-family" matInput type="text" formControlName="phone2" />
                  </mat-form-field>
                </div>

                <!-- EMAIL Private -->
                <div
                  class="mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                  <mat-form-field class="neo-leads-input-full-width">
                    <mat-label>{{'PAGE.LEADS.LEAD.LEAD-EDIT.EMAIL' | translate}}</mat-label>
                    <input class="font-family" matInput type="text" formControlName="email2" />
                  </mat-form-field>
                </div>
              </div>
            </section>

            <section>
              <div class="mdc-layout-grid__inner">
                <div
                  class="mdc-layout-grid__cell--span-12 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                  <mat-form-field class="neo-leads-input-full-width">
                    <mat-label>{{'PAGE.LEADS.LEAD.LEAD-EDIT.COMMENT' | translate}}</mat-label>
                    <textarea matInput formControlName="lead_extradata" class="textarea"></textarea>
                  </mat-form-field>
                </div>
              </div>
            </section>

            <section *ngIf="createLeadForm.controls.appointment.value === true">
              <div class="mdc-layout-grid__inner">

                <!-- TIME -->
                <div
                  class="mdc-layout-grid__cell--span-3 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                  <mat-form-field class="neo-leads-input-full-width">
                    <mat-label class="neo-login-label">{{'PAGE.LEADS.LEAD.TERMINABLATT.TIME' | translate}}</mat-label>
                    <input type="time" class="font-family" matInput formControlName="termin_time">
                  </mat-form-field>
                </div>

                <!-- DATE -->
                <div
                  class="mdc-layout-grid__cell--span-3 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                  <mat-form-field class="neo-leads-input-full-width">
                    <mat-label>{{'PAGE.LEADS.LEAD.TERMINABLATT.APPOINTMENT-DATE' | translate}}</mat-label>
                    <input [matDatepicker]="appointmentDate" matInput formControlName="termin_date" />
                    <mat-datepicker-toggle matSuffix [for]="appointmentDate"></mat-datepicker-toggle>
                    <mat-datepicker #appointmentDate></mat-datepicker>
                  </mat-form-field>
                </div>

                <!-- PLACE -->
                <div
                  class="mdc-layout-grid__cell--span-3 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                  <mat-form-field class="neo-leads-input-full-width">
                    <mat-label>{{'PAGE.LEADS.LEAD.TERMINABLATT.CITY' | translate}}</mat-label>
                    <input class="font-family" matInput type="text" formControlName="termin_place" />
                  </mat-form-field>
                </div>

              </div>
            </section>

          </div>
        </form>
      </neomp-container>
    </div>

    <div
      class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
      <div class="mdc-layout-grid__inner ">
        <div
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">

          <neomp-container class="neo-display-none">
            <div class="mdc-layout-grid__inner neo-space-padding">
              <div
                class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
                <div class="mdc-layout-grid__inner">
                  <div
                    class="mdc-layout-grid__cell--span-12 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                    <h1>{{'PAGE.LEADS.TERMIN.FAMILYMEMBER' | translate}}</h1>
                  </div>
                </div>
              </div>

              <div
                class="mdc-layout-grid__cell--span-2 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                <mat-form-field class="neomp-full-width">
                  <mat-label>{{'PAGE.CONTAINER.LEADS.TABLES.APPOINTMENTS.PERSONS'| translate}}</mat-label>
                  <input [formControl]="rowNumberControl" min="1" matInput type="number"
                    (focusout)="focusOutFunction($event)"/>
                </mat-form-field>
              </div>

              <div
                class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">

                <table mat-table [dataSource]="familyMembers" multiTemplateDataRows matSort class="shadow">

                  <ng-container matColumnDef="gender" class="gender-container">
                    <th mat-header-cell *matHeaderCellDef class="column-w-20 w-10"> {{ 'PAGE.LEADS.LEAD.TERMINABLATT.GENDER' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <mat-form-field class="neo-form-width" floatLabel="never">
                        <mat-select [(ngModel)]="element.gender" required #select
                          (selectionChange)="familyMembersContentChanged = true" placeholder="{{ 'PAGE.LEADS.LEAD.TERMINABLATT.GENDER' | translate }}"
                          (opened)="s1(select)" (closed)="s2(select)">
                          <mat-option *ngFor="let gender of genderData" [value]="gender">
                            {{ gender }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container class="edit-termin-table-column-width" matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef class="column-w-20"> {{ 'PAGE.LEADS.LEAD.TERMINABLATT.NAME' |
                      translate}}
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <mat-form-field appearance="none">
                        <input type="text" matInput (change)="familyMembersContentChanged = true"
                          [(ngModel)]="element.name" required placeholder="{{
                                'PAGE.LEADS.LEAD.TERMINABLATT.NAME' | translate
                               }}"/>
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="birthdate">
                    <th mat-header-cell *matHeaderCellDef class="column-w-20"> {{
                      'PAGE.LEADS.LEAD.TERMINABLATT.BIRTHDATE'
                      | translate}}</th>
                    <td mat-cell *matCellDef="let element">
                      <mat-form-field appearance="none">
                        <input [matDatepicker]="fMemberbirthdate" (change)="familyMembersContentChanged = true" matInput
                          [(ngModel)]="element.birthdate" required placeholder="{{
                               'PAGE.LEADS.LEAD.TERMINABLATT.BIRTHDATE' | translate  
                                }}" />
                        <mat-datepicker-toggle matSuffix [for]="fMemberbirthdate"></mat-datepicker-toggle>
                        <mat-datepicker #fMemberbirthdate></mat-datepicker>
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="nationality">
                    <th mat-header-cell *matHeaderCellDef class="column-w-20"> {{
                      'PAGE.LEADS.LEAD.TERMINABLATT.NATIONALITY'
                      |
                      translate}}
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <mat-form-field appearance="none">
                        <input type="text" (change)="familyMembersContentChanged = true" matInput
                          [(ngModel)]="element.nationality" placeholder="{{
              'PAGE.LEADS.LEAD.TERMINABLATT.NATIONALITY' | translate 
              }}" required />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef class="column-w-20 pr-10">
                      {{ "PAGE.LEADS.LEAD.TERMINABLATT.ACTION" | translate }}
                    </th>
                    <td mat-cell *matCellDef="let person">
                      <div (click)="deleteFamilyMember(person)"><img src="/assets/icons/remove.svg"></div>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
              </div>
            </div>
          </neomp-container>
        </div>
      </div>
    </div>
  </div>
</div>