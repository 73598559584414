export class GMAddress {
    countryCode: string;
    municipalityNumber: number;
    postalCode: string;
    town: string;
    
    constructor(        
    countryCode: string,
    municipalityNumber: number,
    postalCode: string,
    town: string
    ){        
        this.countryCode = countryCode;
        this.municipalityNumber = municipalityNumber;
        this.postalCode = postalCode;
        this.town = town;
    }
}