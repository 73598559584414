import {Component} from "@angular/core";
import {FormControl} from "@angular/forms";
import {Helper} from "../../../static/helper";
import {FilterTableComponent} from "./../filter-table-component";
import {log} from "../../../providers/logger.provider";
import {PolicyJson} from "../../../json/policy.json";
import {Policy} from "../../../model/policy";
import {RegisterProvider} from "../../../providers/register.provider";
import {Router} from "@angular/router";
import {HouseholdProvider} from "../../../providers/household.provider";
import {RestProviderActionsDummy} from "../../../providers/rest.provider";
import {ActionDialogService} from "../../../providers/action-dialog.service";
import {ActionDialogData} from "../../action.dialog.component";
import {BusinessTransactionHelper} from "../../../static/bt-helper";
import { MatDialog } from "@angular/material/dialog";
import {ActionDataProvider} from "../../../providers/action-data.provider";
import { ActionData } from "../../../model/action-data";
import { DocumentProvider } from "app/modules/core/providers/document.provider";
import { TranslateService } from "@ngx-translate/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { UserCommitState } from "../../../model/user-commit-state";


@Component({
  selector: 'neomp-cancellation-management-table',
  templateUrl: './cancellation-management-table.component.html',
  styleUrls: ['./cancellation-management-table.component.scss'],
})
export class CancellationManagementTableComponent extends FilterTableComponent<Policy>  {
  private readonly TAG = this.constructor.name;

    selectedCancellationRowIndex;
    isDialogOpen = false;
    customerNameFilter = new FormControl("");
    cancellationStatusFilter = new FormControl("");
    cancellationBetreffFilter = new FormControl("");
    filterValues: Policy = new Policy();

  displayedColumns = [
      "customerName",
      "cancellationStatus",
      "cancellationBetreff",
      "cancellationDocumentKVG",
      "cancellationDocumentVVG",
      "cancellationDocumentKVG_VVG",
      "release",
      "filter"
  ];

  displayedColumnFilters = [
    "customerNameFilter",
    "cancellationStatusFilter",
    "cancellationBetreffFilter",
    "cancellationDocumentKVGFilter",
    "cancellationDocumentVVGFilter",
    "cancellationDocumentKVG_VVGFilter",
    "releaseFilter",
    "filterRemove",
  ];
  formControlFilters: FormControl[] = [
    this.customerNameFilter,
    this.cancellationStatusFilter,
    this.cancellationBetreffFilter,
    null
  ];

  constructor(public registerProvider: RegisterProvider,
              public households: HouseholdProvider,
              public router: Router,
              public dialogService: ActionDialogService,
              public dialog: MatDialog,
              public actionDataProvider: ActionDataProvider,
              public actionData: ActionData,
              public documentProvider: DocumentProvider,
              private translateService: TranslateService,
              private matSnackBar: MatSnackBar) {
      super();
  }

  applicationsRowClick(element: Policy) {
      this.selectedCancellationRowIndex = element.uuid;
      this.openRow(element);
  }

  resetFilter() {
      this.filterValues = new Policy();
      super.resetFilter();
  }

  createFilter(): (data: Policy, filter: string) => boolean {
      return function (data, filter): boolean {
        const policyJson: PolicyJson = JSON.parse(filter);
        const searchTerms: Policy = Policy.fromJson(policyJson);
        const cancellationStatusFilter = searchTerms.cancellationStatus.toLowerCase() === 'nicht hochgeladen' ? '' : searchTerms.cancellationStatus;
        return (
            Helper.checkStringContains(data.customerName, searchTerms.customerName) &&
            Helper.checkStringContains(data.cancellationStatus, cancellationStatusFilter) &&
            Helper.checkStringContains(data.cancellationBetreff, searchTerms.cancellationBetreff)
        );
    };
  }

  openRow(element: Policy) {
      if (element.partners && element.partners.length > 0) {
          const advisor = element.partners.find(p => p.type.sorValue === "ADVISOR");
          const partner = element.partners.find(p => p.type.sorValue === "CUSTOMER");
          if (advisor && partner) {
              this.households.getCompleteHouseholdByUuid(partner.id, new RestProviderActionsDummy())
                  .subscribe(completeHousehold => {
                      if (completeHousehold !== null) {
                          const baseUrl = `${this.registerProvider.getPath("MyCustomersDetail")}/${partner.id}`;
                          const url = `${baseUrl}/policy/${element.uuid}`;
                          this.router.navigate([url]);
                      } else {
                          log.error(`${this.TAG}: openRow -> policy=${element.uuid} was not found for first partner ${partner.id} in households`);
                      }
                  });
          } else {
              log.error(`${this.TAG}: openRow -> policy is missing either ADVISOR, CUSTOMER or both`);
          }
      }
      log.debug(`${this.TAG}: openRow -> policy=${element.uuid} has no partner (Type=CUSTOMER) set`);
  }

  openActionDialog(event: Event, element: Policy) {
      this.isDialogOpen = true;
      event.stopPropagation();

      const dialogData: ActionDialogData = {
          transTypeId: BusinessTransactionHelper.typeId.cancellationRange.from,
          transName: "Policy",
          transSubject: "Policy",
          transTitle: "Policy",
          transMessage: "Policy",
          keyValues: [],
          person: null,
          policy: element,
          showSpecificConfigs: true,
          cancellation_approval: false
      };
      this.dialogService.openByType(dialogData, 
          {
              defaultTypeId: BusinessTransactionHelper.typeId.cancellationRange.from
          }, this.TAG
      );
      this.dialog.afterAllClosed.subscribe(() => {
          this.isDialogOpen = false;
      });
  }
    download(id: string) {
        if(id){
            this.documentProvider.downloadDocument(id);
        }
        else{
            const translatedMessage = this.translateService.instant('PAGE.CONTAINER.MESSAGES.ERROR-DOCUMENT');
            this.matSnackBar.open(translatedMessage, 'X', UserCommitState.configCheckData);
        }
    }

    downloadFromMyNeo(id: string) {
        if(id){

            this.documentProvider.downloadDocument(id, true);
        }
        else{
            const translatedMessage = this.translateService.instant('PAGE.CONTAINER.MESSAGES.ERROR-DOCUMENT');
            this.matSnackBar.open(translatedMessage, 'X', UserCommitState.configCheckData);
        }
    }


    getCancellationDocumentData(element: any, columnType: string): any[] {
        const cancellationDocuments = element.cancellationDocuments || [];
        switch (columnType) {
            case 'KVG':
                const kvgDocument = cancellationDocuments.find(doc => doc.typeId === '591');
                return kvgDocument ? [kvgDocument.document] : [];

            case 'VVG':
                const vvgDocument = cancellationDocuments.find(doc => doc.typeId === '592');
                return vvgDocument ? [vvgDocument.document] : [];

            case 'KVG_VVG':
                const kvgVvgDocument = cancellationDocuments.find(doc => doc.typeId === '593');
                return kvgVvgDocument ? [kvgVvgDocument.document] : [];

            default:
                return [];
        }
    }

  cancellationApprovalToggle(element: any) {
    setTimeout(()=> {

        const action = BusinessTransactionHelper.createActionObject(
            "Freigabe Kündigung",
            "595",
            "Freigabe",
            "Re: Policy",
            "",
            null,
            [],
            null,
            element,
            undefined,
            false
        );

        action.transaction.cancellation_approval = element.cancellations.cancellationApproval ?? false;
        this.actionDataProvider.sendActionData(action).subscribe(value => {
            this.dialogService.openSnackbar("COMMONS.USERCOMMIT.SUCCESS", UserCommitState.configSuccess);
        }, error => {
            this.dialogService.openSnackbar("COMMONS.USERCOMMIT.ERROR", UserCommitState.configError);
        });
    }, 10)
  }
}
