import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { PaginationConfigs } from 'app/modules/constants/PaginationConstants';
import { LoadingDataComponent } from 'app/modules/core/components/loading-data.component';
import { LeadQueryParams } from 'app/modules/core/json/leadQueryParams.json';
import { Config } from 'app/modules/core/model/config';
import { AgenturType } from 'app/modules/core/model/enum-agenturtype';
import { LeadLog } from 'app/modules/core/model/LeadLog.enum';
import { Person } from 'app/modules/core/model/person';
import { AppointmentProvider } from 'app/modules/core/providers/appointment.provider';
import { ConfigProvider } from 'app/modules/core/providers/config.provider';
import { LoadingService } from 'app/modules/core/providers/loading.component';
import { RestProviderActions } from 'app/modules/core/providers/rest.provider';
import { format } from 'date-fns';
import { KeycloakService } from 'keycloak-angular';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { BusinessTransactionHelper } from 'app/modules/core/static/bt-helper';
import moment from 'moment';
import { catchError, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { LeadsService } from '../../services/leads.service';
import { KeyValue } from 'app/modules/core/model/key-value';
import { AdvisorDataService } from "app/modules/core/services/advisor-data.service";

@Component({
  selector: 'neomp-termin',
  templateUrl: './termin.component.html',
  styleUrls: ['./termin.component.scss']
})
export class TerminComponent implements OnInit, OnDestroy {
  @ViewChild("loadingFamilyTable") loadingFamilyTable: LoadingDataComponent;
  @Input() managementView: boolean = false;

  pageSize = PaginationConfigs.pageSize;
  pageSizeOptions = PaginationConfigs.pageSizeOptions;
  pageIndex = PaginationConfigs.pageIndex;
  selectedLeads: Person[] = []
  isLoading = false;

  totalCountAppointments: number = 0;
  appointments: Person[] = [];
  unassignedAppointments: Person[] = [];
  totalCountUnassignedAppointments = 0;
  returnedAppointments: Person[] = [];
  totalCountReturnedAppointments = 0;
  agents: Person[] = [];
  allStatuses: KeyValue[] = [];
  statusesList: string[] = [];
  substatusesList: any[] = [];
  statusSelected = new FormControl("");
  substatusSelected = new FormControl("");
  agentSelectedControl = new FormControl("");
  agentAssignSelectedControl = new FormControl({ value: '', disabled: true }, Validators.required);
  advisorName: string = "";
  agenturType: string = "";
  agenturName: string = "";
  agenturId: string = "";
  isHighRole: boolean = true;

  // filter variables
  searchText: string | null = null;
  dateFrom: any;
  dateTo: any;
  selectedTabIndex: number = 0;

  lead_terminCategory = [];
  lead_terminType = [];
  unassignedLeadsCategories: string[] = [];
  unassignedLeadsTypes: string[] = [];
  queryParamsSub: Subscription;

  constructor(public appointmentProvider: AppointmentProvider,
    private keycloak: KeycloakService,
    private configProvider: ConfigProvider,
    public loadService: LoadingService,
    private advisorDataService: AdvisorDataService,
    private router: Router,
    private route: ActivatedRoute,
    private leadService: LeadsService) {
  }

  async ngOnInit(): Promise<void> {
    this.appointmentProvider.getAttributeValues().subscribe(attributeValues => {
      this.unassignedLeadsCategories = attributeValues?.terminCategories || []
      this.unassignedLeadsTypes = attributeValues?.terminTypes || []
    });
    // The "searchFromParams" function call is removed here as it is triggered by queryParams changes 
    // this.searchFromParams(this.route.snapshot.queryParams as LeadQueryParams);
    await this.keycloak.loadUserProfile().then((res: any) => {
      this.advisorName = res.firstName;
      this.agenturType = res.attributes.agenturType[0];
      this.agenturName = res.attributes.agenturName[0];
      this.agenturId = res.attributes.agenturID[0];
      this.isHighRole = (this.agenturType === AgenturType.SalesManager || this.agenturType === AgenturType.GeneralAgent || this.agenturType === AgenturType.TeamLead);
    });
    this.agents = await this.getAgents();
    //We commented the code below in order to make the agentur visible
    // this.agents = this.agents.filter(x => x.uuid != this.agenturId);
    this.queryParamsSub = this.route.queryParams
    .pipe(
        tap((x: LeadQueryParams) => {
          if (!x.st) {
            this.search();
          }
        }),
        filter((x: LeadQueryParams) => x.st === 'termin' || x.st === 'termin-unassigned' || x.st === 'returned-termin'),
        distinctUntilChanged((prev: any, curr: LeadQueryParams) => JSON.stringify(prev) === JSON.stringify(curr))
    ).subscribe((params: LeadQueryParams) => {
        this.searchFromParams(params);
      })
    this.getConfigs();
  }

  searchFromParams(params: LeadQueryParams): void {
    this.searchText = params.text || "";
    this.dateFrom = params.dateFrom === "Invalid Date" ? "" : params.dateFrom; //make the dateFrom to be an empty string is the vallue is Invlaid Date
    this.dateTo = params.dateTo === "Invalid Date" ? "" : params.dateTo; //make the dateTo to be an empty string is the vallue is Invlaid Date
    this.statusSelected.reset();
    this.agentSelectedControl.reset();
    this.pageIndex = params.pageIndex || PaginationConfigs.pageIndex;
    this.pageSize = Number(params.pageSize) > 0 ? Number(params.pageSize) : PaginationConfigs.pageSize;

    this.lead_terminCategory = params.lead_terminCategory as any || [];
    if (typeof params.lead_terminCategory === 'string') {
      this.lead_terminCategory = params.lead_terminCategory.split(",");
    }

    this.lead_terminType = params.lead_terminType as any || [];
    if (typeof params.lead_terminType === 'string') {
      this.lead_terminType = params.lead_terminType.split(",");
    }

    if (params.st == 'termin-unassigned') {
      this.selectedTabIndex = 0;
    }

    if (params.st == 'termin') {
      this.selectedTabIndex = 1;
    }
    if (params.st == 'returned-termin') {
      this.selectedTabIndex = 2;
    }

    let terminStatusesList = params.terminStatus as any;
    if (typeof params.terminStatus === 'string') {
      terminStatusesList = params.terminStatus.split(",");
    }
    this.statusSelected.setValue(terminStatusesList);

    let terminSubstatusesList = params.lead_terminSubstatusList as any;
    if (typeof params.lead_terminSubstatusList === 'string') {
      terminSubstatusesList = params.lead_terminSubstatusList.split(",");
    }
    this.substatusSelected.setValue(terminSubstatusesList);
    
    // Get substatus list for selected status
    this.substatusesList = this.getSubstatuses(this.statusSelected.value);

    if (params.advisor) {
      this.agentSelectedControl.setValue(params.advisor);
    }
    this.search(false);
  }

  loadAppointments(
    restProviderActions: RestProviderActions,
    page: number,
    size: number,
    useCache?: boolean,
    filter?: string) {
    this.appointmentProvider
      .getLazyAppointments(restProviderActions, useCache, page, size, filter)
      .subscribe((leads: { data: Person[]; totalCount: number }) => {
        this.totalCountAppointments = leads.totalCount;
        if (this.agents.length > 0) {
          this.appointments = leads.data.map(lead => this.assignAgentsToLeads(lead));
        }
        else {
          this.appointments = leads.data;
        }
      });
  }
  assignLeadsToAgent() {
    let requests: Observable<any>[] = [];
    const agent = this.agentAssignSelectedControl.value;
    if (this.selectedLeads.length > 0) {
      this.loadService.display(true);
      for (let lead of this.selectedLeads) {

        const sorKeys = {
          'advisorId': agent.uuid,
          'leadId': lead.uuid
        }

        lead.lead.termin_editdate = moment().format('YYYY-MM-DD');
        lead.lead.termin_berater_shortcut = agent.uuid;

        const actionData = BusinessTransactionHelper.createLeadBTActionObject(
          LeadLog.TRANSFER,
          BusinessTransactionHelper.typeId.assignLeadToAgent.from,
          '',
          'Termine TRANSFER => ' + agent.fullName,
          '',
          agent.uuid,
          [],
          lead,
          moment().add(5, 'days').format('YYYY-MM-DD'),
          sorKeys,
          this.advisorName
        );
        requests.push(this.leadService.sendLeadAction(actionData)
          .pipe(
            // do not let your observables die; forkJoin will the not be triggered then.
            catchError(err => of(err))
          ));
      }
      forkJoin(requests).subscribe(results => {
        this.loadService.display(false);
        this.isLoading = false;
        this.agentAssignSelectedControl.reset();
        this.selectedLeads = [];
        this.searchText = null;
        this.search();
      })
    }
  }
  loadUnassignedAppointments(
    restProviderActions: RestProviderActions,
    page: number,
    size: number,
    useCache?: boolean,
    filter?: string) {
    this.appointmentProvider
      .getUnassignedAppointments(restProviderActions, useCache, page, size, filter)
      .subscribe((leads: { data: Person[]; totalCount: number }) => {
        this.totalCountUnassignedAppointments = leads.totalCount;
        this.unassignedAppointments = leads.data;

        if (this.searchText == "" || this.searchText == null) {
          this.leadService.setUnassignedAppointmentsCount(this.totalCountUnassignedAppointments);
          this.leadService.setUnassignedLeadAndAppointmentsCount(this.totalCountUnassignedAppointments + Number(this.leadService.unassignedLeadsCount.value));
        }
      });
  }
  clearSearch() {
    this.searchText = '';
    this.dateFrom = null;
    this.dateTo = null;
    this.agentSelectedControl.reset();
    this.statusSelected.reset();
    this.substatusSelected.reset();
    this.lead_terminCategory = [];
    this.lead_terminType = [];
    this.terminTabChanged(this.selectedTabIndex);
    this.search();
  }
  returnedTermin(
    restProviderActions: RestProviderActions,
    page: number,
    size: number,
    useCache?: boolean,
    filter?: string) {
    this.appointmentProvider
      .getReturnedAppointments(restProviderActions, useCache, page, size, filter)
      .subscribe((leads: { data: Person[]; totalCount: number }) => {
        this.totalCountReturnedAppointments = leads.totalCount;
        this.returnedAppointments = leads.data;
      });
  }

  terminTabChanged(index: number): void {
    if (this.selectedTabIndex != index) {
      this.dateFrom;
      this.dateTo;
      this.searchText;
      this.agentSelectedControl.setValue('');
      this.substatusSelected.setValue('');
      this.statusSelected.setValue('');
      this.selectedTabIndex = index;
      this.pageIndex = 0;
      this.lead_terminCategory=[];
      this.lead_terminType=[];
      this.search();
    }
  }

  selectedRows(selectionModel: SelectionModel<Person>): void {
    this.selectedLeads = selectionModel.selected;
    this.selectedLeads.length === 0 ?
      this.agentAssignSelectedControl.disable() : this.agentAssignSelectedControl.enable();
  }
  assignAgentsToLeads(lead: Person) {
    const leadAgent = this.agents.find(x => x.uuid == lead.lead.termin_berater_shortcut);
    const agentName = leadAgent ? leadAgent.fullName : this.agenturName;
    if (agentName) {
      lead.sorKeys["agent"] = agentName;
      return lead;
    }
    return null;
  }

  getAgents(): Promise<Person[]> {
    if(!this.managementView) {
      return this.advisorDataService.getAgents();
    } else {
      return this.advisorDataService.getUnblockedAgents()
    }
  }

  paginatorValueChanged(event: PageEvent) {
    if (event.pageIndex !== this.pageIndex || event.pageSize !== this.pageSize) {
      this.pageIndex = event.pageIndex;
      this.pageSize = event.pageSize > 0 ? event.pageSize : PaginationConfigs.pageSize;
      this.search();
    }
  }

  getConfigs() {
    this.configProvider.getConfig().subscribe((config: Config[]) => {
      this.allStatuses = config.filter(x =>
        Number(x.key) >= Number("4210") &&
        Number(x.key) <= Number("4211"))
        .map(y => {
          return {
            id: y.key,
            key: y.values[0].key,
            value: y.values[0].value
          }
        });
      this.allStatuses.unshift({
        id: "Neu",
        key: "Neu",
        value: "Verteilt,Nicht verteilt"
      })
      this.statusesList = this.allStatuses.map(x => x.key);

       // Get substatus list for selected status
       this.substatusesList = this.getSubstatuses(this.statusSelected.value);
    })
  }

  statusesSelected(event) {
    this.substatusesList = [];

    event.value.forEach(status => {
      this.substatusesList.push(...this.getSubstatuses(status));
    });
    this.substatusSelected.reset();
  }

  getSubstatuses(status) {
    let substatuses = [];
    let statuses = Array.isArray(status) ? 
      this.allStatuses.filter(x => status.includes(x.key)) :
      this.allStatuses.filter(x => x.key === status);

    statuses.forEach(y => {
      var obj = {};
      obj['title'] = y.key;
      obj['value'] = y.value.split(',');
      substatuses.push(obj);
    });
    return substatuses;
  }

  resetFunction(event) {
    if (event == 'true') {
      this.search();
    }
  }

  search(isSearching=true) {
    var statusSelected = Array.isArray(this.statusSelected.value) ? this.statusSelected.value.join(',') : "";
    var substatusSelected = Array.isArray(this.substatusSelected.value) ? this.substatusSelected.value.join(',') : "";

    const dateFrom = this.dateFrom === '' || this.dateFrom == undefined || this.dateFrom == "Invalid Date" ? "" : format(new Date(this.dateFrom.toString()), "YYYY-MM-DDT00:00:00.000")  //create a date only if the value of this.dateFrom is acceptable
    const dateTo = this.dateFrom === '' || this.dateTo == undefined || this.dateTo == "Invalid Date" ? "" : format(new Date(this.dateTo.toString()), "YYYY-MM-DDT23:59:00.000") //create a date only if the value of this.dateTo is acceptable

    let filter = '';

    const leadRoute = this.managementView ? '/termin-management' : '/leads';

    switch (this.selectedTabIndex) {
      case 0:
        if (this.managementView) {
          filter = `dateFrom=${dateFrom}&dateTo=${dateTo}&status=${statusSelected}&termin_substatusList=${substatusSelected}`;
          if (![null, undefined, ""].includes(this.searchText)) {
            filter += `&text=${this.searchText}`
          }

          if(isSearching){
          this.router.navigate([leadRoute], {
            queryParams: {
              st: 'termin-unassigned',
              dateFrom: dateFrom,
              dateTo: dateTo,
              status: this.statusSelected.value,
              text: this.searchText,
              pageIndex: this.pageIndex,
              pageSize: this.pageSize,
              lead_terminCategory: this.lead_terminCategory?.join(",") || "",
              lead_terminType: this.lead_terminType?.join(",") || ""
            }
          })
        } else {
          this.loadUnassignedAppointments(
            this.loadingFamilyTable,
            this.pageIndex,
            this.pageSize,
            false,
            filter + `&lead_terminType=${this.lead_terminType}&lead_terminCategory=${this.lead_terminCategory}`
          );
        }

        } else {
          if (this.route.snapshot.url[0].path === 'termin-management') {
            this.selectedTabIndex = 1;
          }
        }
        break;
      case 1:
        filter = this.agentSelectedControl.value != "" && this.agentSelectedControl.value != undefined ?
          `dateFrom=${dateFrom}&dateTo=${dateTo}&advisor=${this.agentSelectedControl.value}&status=${statusSelected}&termin_substatusList=${substatusSelected}` :
          `dateFrom=${dateFrom}&dateTo=${dateTo}&status=${statusSelected}&termin_substatusList=${substatusSelected}`

        if (![null, undefined, ""].includes(this.searchText)) {
          filter += `&text=${this.searchText}`
        }

        if(isSearching){
        this.router.navigate([leadRoute], {
          queryParams: {
            st: 'termin',
            dateFrom: dateFrom,
            dateTo: dateTo,
            advisor: this.agentSelectedControl.value,
            terminStatus: this.statusSelected.value,
            lead_terminSubstatusList: this.substatusSelected.value,
            text: this.searchText,
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            lead_terminCategory: this.lead_terminCategory?.join(",") || "",
            lead_terminType: this.lead_terminType?.join(",") || ""
          }
        })
      } else {
        this.loadAppointments(
          this.loadingFamilyTable,
          this.pageIndex,
          this.pageSize,
          false,
          filter + `&lead_terminType=${this.lead_terminType}&lead_terminCategory=${this.lead_terminCategory}`
        );
      }
        break;
      case 2:
        if (this.managementView) {
          filter = `dateFrom=${dateFrom}&dateTo=${dateTo}&status=${statusSelected}&termin_substatusList=${substatusSelected}`;
          if (![null, undefined, ""].includes(this.searchText)) {
            filter += `&text=${this.searchText}`
          }

          if(isSearching){
          this.router.navigate([leadRoute], {
            queryParams: {
              st: 'returned-termin',
              dateFrom: dateFrom,
              dateTo: dateTo,
              terminStatus: this.statusSelected.value,
              lead_terminSubstatusList: this.substatusSelected.value,
              text: this.searchText,
              pageIndex: this.pageIndex,
              pageSize: this.pageSize,
              lead_terminCategory: this.lead_terminCategory?.join(",") || "",
              lead_terminType: this.lead_terminType?.join(",") || ""
            }
          })
        } else {
          this.returnedTermin(
            this.loadingFamilyTable,
            this.pageIndex,
            this.pageSize,
            false,
            filter + `&lead_terminType=${this.lead_terminType}&lead_terminCategory=${this.lead_terminCategory}`
          );
        }

        } else {
          this.selectedTabIndex = 1;
        }
        break;
      default:
        break;
    }
  }
  
  ngOnDestroy(): void {
    this.queryParamsSub.unsubscribe();
  }
}