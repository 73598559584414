import {SDAKey} from "./sdakey";
import {CommunicationAddressJson} from "../json/communication.address.json";

export class CommunicationAddress {
    type: string = null;
    commAddrType: SDAKey = null;
    address: string = null;
    info: string = null;
    telNumber:string="";
    phoneNumber:string="";

    public static fromJsons(response: CommunicationAddressJson[]): CommunicationAddress[] | undefined {
        if (response) {
            return response.map(ca => new CommunicationAddress(ca));
        }
    }

    constructor(data?: CommunicationAddressJson) {
        if (data) {
            this.commAddrType = data.commAddrType ? new SDAKey(data.commAddrType) : null;
            this.info = data.info ? data.info : null;
            this.address = data.address ? data.address : null;
            this.telNumber=data.telNumber || "";
            this.phoneNumber=data.phoneNumber || "";
        }
    }
}
