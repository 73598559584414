import { PolicyJson } from "../json/policy.json";
import { Product } from "./product";
import { SDAKey } from "./sdakey";
import { Reference } from "./reference";
import { Person } from "./person";
import { JournalJson } from "../json/journal.json";
import { Cancellations } from "../json/cancellations.json";
// import {Person} from "./person.js";

export class Policy {

    uuid = "";
    name = "";
    mandateGivenAndValid = null;
    status: SDAKey = null;
    masterPolicyName = "";
    policyNumber = "";
    kvgoldsocietyid = "";
    oldsocietyid = "";
    validFrom = "";
    mandatResponsible = "";
    partners: Reference[] = [];
    persons: Person[] = []
    products: Product[] = [];
    sorKeys: { [key: string]: string } = {};
    transactions: JournalJson[] = [];
    cancellations: Cancellations = {
        cancellationStatus: '',
        cancellationApproval: false,
        betreff: ''
    };
    cancellationDocuments?: any[];
    betreff?: string;


    get typeName(): string {
        if (this.transactions && this.transactions.length > 0) {
            return this.transactions[0].typeName;
        }
        return "";
    }

    get insurer(): string {
        if (this.products && this.products.length > 0) {
            return this.products[0].riskCarrier;
        }
        return "";
    }

    set insurer(val: string) {
        if (this.products.length === 0) {
            this.products.push(new Product());
        }
        this.products[0].riskCarrier = val;
    }

    get lob(): string {
        if (this.products && this.products.length > 0) {
            return this.products[0].lineOfBusiness;
        }
        return "";
    }

    set lob(val: string) {
        if (this.products.length === 0) {
            this.products.push(new Product());
        }
        this.products[0].lineOfBusiness = val;
    }

    /*
        should be refactored
     */
    get customerPosition(): number {
        let pos = 0;
        this.partners.forEach((partner, index) => {
            if (partner.type.key === "040" && partner.type.sorValue === "Customer") {
                pos = index;
            }
        });
        return pos;
    }

    get customerName(): string {
        if (this.persons && this.persons.length > 0) {
            const person = this.persons[this.customerPosition];
            return person.name + (person.firstName.length > 0 ? ", " + person.firstName : "");
        }
        return "";
    }

    set customerName(val: string) {
        if (this.persons.length === 0) {
            this.persons.push(new Person());
        }
        this.persons[0].name = val;
    }

    get customerBirthday(): string {
        if (this.persons && this.persons.length > 0) {
            return this.persons[this.customerPosition].birthdate;
        }
        return "";
    }

    set customerBirthday(val: string) {
        if (this.persons.length === 0) {
            this.persons.push(new Person());
        }
        this.persons[0].birthdate = val;
    }

    get creationDate(): string {
        if (this.products && this.products.length > 0) {
            return this.products[0].dateOfStart;
        }
        return "";
    }

    set creationDate(val: string) {
        if (this.products.length === 0) {
            this.products.push(new Product());
        }
        this.products[0].dateOfStart = val;
    }

    get policyStatus(): string {
        return (this.status ? this.status.value : "");
    }

    set policyStatus(val: string) {
        if (!this.status) {
            this.status = new SDAKey();
        }
        this.status.value = val;
    }

    get policyCRMId(): string {
        return (this.sorKeys ? this.sorKeys["policyCrmID"] : "")
    }

    set policyCRMId(val: string) {
        this.sorKeys["policyCrmID"] = val;
    }

    get cancellationStatus(): string {
        if (this.cancellations && this.cancellations.cancellationStatus.length > 0) {
            return this.cancellations.cancellationStatus;
        }
        return 'Nicht hochgeladen';
    }

    set cancellationStatus(val: string) {
        if (this.cancellations.cancellationStatus.length === 0) {
            this.cancellations.cancellationStatus = '';
        }
        this.cancellations.cancellationStatus = val;
    }

    get cancellationBetreff(): string {
        if (this.cancellations && this.cancellations.betreff.length > 0) {
            return this.cancellations.betreff;
        }
        return '';
    }

    set cancellationBetreff(val: string) {
        if (this.cancellations.betreff.length === 0) {
            this.cancellations.betreff = '';
        }
        this.cancellations.betreff = val;
    }

    public constructor(value?: PolicyJson) {
        if (value) {
            this.uuid = value.uuid || "";
            this.name = value.name || "";
            this.status = value.status ? new SDAKey(value.status) : null;
            this.masterPolicyName = value.masterPolicyName || "";
            this.policyNumber = value.policyNumber || "";
            this.kvgoldsocietyid = value.kvgoldsocietyid || "";
            this.oldsocietyid = value.oldsocietyid || "";
            this.validFrom = value.validFrom || "";
            this.mandatResponsible = value.mandatResponsible || "",
                this.partners = Reference.fromJsons(value.partners);
            this.persons = Person.fromJsons(value.persons);
            this.products = Product.fromJsons(value.products);
            this.sorKeys = value.sorKeys || {};
            this.cancellations = {
                cancellationStatus: value.cancellations?.cancellationStatus || "",
                cancellationApproval: value.cancellations?.cancellationApproval || false,
                betreff: value.cancellations?.betreff || ''
            };
            this.cancellationDocuments = value.cancellationDocuments || [];
            this.transactions = value.transactions || [];

        }
    }

    public static fromJson(response: PolicyJson): Policy {
        return new Policy(response);
    }

    public static fromJsons(response: PolicyJson[]): Policy[] | undefined {
        if (response) {
            return response.map(policy => new Policy(policy));
        }
    }

    getIsMandateDissolved() {
        return this.mandateGivenAndValid === false;
    }
}
