import {Journal} from "../model/journal";
import {JournalList} from "../model/journal-list";
import {Reference} from "../model/reference";

export class JournalHelper {
    public static getSingleReferenceFromJournal(journalRow: Journal | JournalList, referenceKey: string): Reference {
        const references: Reference[] =
            journalRow instanceof Journal ? journalRow.references : journalRow.transaction.references;

        return references.find(value => {
            if (value.type != null && value.type.key === referenceKey) {
                return true;
            }
        });
    }
}
