import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'neomp-termin-leads-reminders',
  templateUrl: './termin-leads-reminders.component.html',
  styleUrls: ['./termin-leads-reminders.component.scss']
})
export class TerminLeadsRemindersComponent implements OnInit {
  displayedColumns: string[] = ['id', 'task', 'comment', 'benutzer'];
  dataSource = ELEMENT_DATA;
    constructor() { }
  
    ngOnInit() {
    }
    
  
  }
  export interface PeriodicElement {
    id: number;
    task: string;
    comment: string;
    benutzer: string;
  }
  const ELEMENT_DATA: PeriodicElement[] = [
    { id: 1, task: 'Call Back', comment: 'Hat gerade bei Swica Unterschrieben', benutzer: 'Arian Rexhepi' },
    { id: 2, task: 'Call Back', comment: 'Hat gerade bei Swica Unterschrieben', benutzer: 'Artan Alija' },
  ];
