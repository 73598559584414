<form [formGroup]="contactDataForm">
  <div class="mdc-layout-grid msg-helper-padding-none">
    <div class="mdc-layout-grid__inner msg-helper-grid-gap-none">
      <div *ngIf="showCompleteForm"
        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone">
        <!-- Name -->
        <mat-form-field [floatLabel]="readonly ? 'never' : 'auto' ">
          <input matInput placeholder="{{ 'PAGE.CUSTOMERDETAIL.CONTACT.NAME' | translate }}" required
            [readonly]="readonly" [value]="person?.name" (change)="changeContactData('name', $event)"
            formControlName="name" />
          <mat-error *ngIf="contactDataForm.controls['name'].hasError('required')">
            {{ "PAGE.CUSTOMERDETAIL.CONTACT.REQUIREDERROR" | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="showCompleteForm"
        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone">
        <!-- FIRSTNAME -->
        <mat-form-field [floatLabel]="readonly ? 'never' : 'auto' ">
          <input matInput placeholder="{{
              'PAGE.CUSTOMERDETAIL.CONTACT.FIRSTNAME' | translate
            }}" required [value]="person?.firstName" [readonly]="readonly"
            (change)="changeContactData('firstName', $event)" formControlName="firstName" />
          <mat-error *ngIf="contactDataForm.controls['firstName'].hasError('required')">
            {{ "PAGE.CUSTOMERDETAIL.CONTACT.REQUIREDERROR" | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="showCompleteForm"
        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone">
        <!-- Birthdate -->
        <mat-form-field [floatLabel]="readonly ? 'never' : 'auto' ">
          <input [matDatepicker]="birthdate" matInput (keydown)="dateInput($event)"
            placeholder="{{ 'PAGE.CUSTOMERDETAIL.CONTACT.BIRTH' | translate }}" required 
            [readonly]="readonly" (dateChange)="changeContactData('birthdate', $event)"
            formControlName="birthdate" />
          <mat-datepicker-toggle matSuffix [for]="birthdate" [disabled]="readonly"></mat-datepicker-toggle>
          <mat-datepicker #birthdate></mat-datepicker>
          <mat-error *ngIf="contactDataForm.controls['birthdate'].hasError('required')">
            {{ "PAGE.CUSTOMERDETAIL.CONTACT.REQUIREDERROR" | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div
        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone">
        <mat-form-field *ngIf="!readonly" [floatLabel]="readonly ? 'never' : 'auto' " class="mat-select-in">
          <mat-label>{{
            "PAGE.CUSTOMERDETAIL.CONTACT.GENDER" | translate
            }}</mat-label>
          <mat-select (selectionChange)="changeContactData('gender', $event)" formControlName="gender" required>
            <mat-option *ngFor="let gender of genderData" [value]="gender">
              {{ gender }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="contactDataForm.controls['gender'].hasError('required')">
            {{ "PAGE.CUSTOMERDETAIL.CONTACT.REQUIREDERROR" | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="readonly" [floatLabel]="readonly ? 'never' : 'auto' ">
          <input matInput placeholder="{{ 'PAGE.CUSTOMERDETAIL.CONTACT.GENDER' | translate }}" [value]="person?.gender"
            readonly />
        </mat-form-field>
      </div>

      <!-- Salutation -->
      <div
        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone">
        <mat-form-field *ngIf="!readonly" [floatLabel]="readonly ? 'never' : 'auto' " class="mat-select-in">
          <mat-label>{{
            "PAGE.CUSTOMERDETAIL.CONTACT.SALUTATION" | translate
            }}</mat-label>
          <mat-select (selectionChange)="changeContactData('salutation', $event)" formControlName="salutation"
            required>
            <mat-option *ngFor="let salutation of salutationData" [value]="salutation">
              {{ salutation }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="contactDataForm.controls['salutation'].hasError('required')">
            {{ "PAGE.CUSTOMERDETAIL.CONTACT.REQUIREDERROR" | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="readonly" [floatLabel]="readonly ? 'never' : 'auto' ">
          <input matInput placeholder="{{
              'PAGE.CUSTOMERDETAIL.CONTACT.SALUTATION' | translate
            }}" [value]="person?.salutation" readonly />
        </mat-form-field>
      </div>

      <!-- MARITALSTATUS -->
      <div
        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone">
       
        <mat-form-field *ngIf="!readonly" [floatLabel]="readonly ? 'never' : 'auto' " class="mat-select-in">
          <mat-label>{{
            "PAGE.CUSTOMERDETAIL.CONTACT.MARITALSTATUS" | translate
            }}</mat-label>
          <mat-select (selectionChange)="changeContactData('maritalStatus', $event)" formControlName="maritalStatus"
            required>
            <mat-option *ngFor="let maritalStatus of maritalStatuses" [value]="maritalStatus">
              {{ maritalStatus }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="contactDataForm.controls['maritalStatus'].hasError('required')">
            {{ "PAGE.CUSTOMERDETAIL.CONTACT.REQUIREDERROR" | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="readonly" [floatLabel]="readonly ? 'never' : 'auto' ">
          <input matInput placeholder="{{
              'PAGE.CUSTOMERDETAIL.CONTACT.MARITALSTATUS' | translate
            }}" [value]="person?.maritalStatus" readonly />
        </mat-form-field>

      </div>

      <!-- NATIONALITY -->
      <div
        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone">
        
        <mat-form-field >
          <mat-label class="neo-login-label">{{'PAGE.CUSTOMERDETAIL.CONTACT.NATIONALITY' | translate}}</mat-label>
          <input class="neo-login-input" matInput [value]="person?.nationality" formControlName="nationality" (change)="updateData('nationality',$event)"
            placeholder="{{'PAGE.CUSTOMERDETAIL.CONTACT.NATIONALITY' | translate}}" [matAutocomplete]="auto"
            (selectionChange)="changeContactData('nationality', $event)">
            
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedNation($event)"
            [displayWith]="displayNationFn.bind(this)">
            <mat-option *ngFor="let option of filteredOptionsNationen | async" [value]="option">
              {{option}}
            </mat-option>
          </mat-autocomplete>
          
          <mat-error *ngIf="contactDataForm.controls['nationality'].hasError('required')">
            {{ "PAGE.CUSTOMERDETAIL.CONTACT.REQUIREDERROR" | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Street -->
      <div
        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone">
        <mat-form-field [floatLabel]="readonly ? 'never' : 'auto' ">
          <input matInput placeholder="{{ 'PAGE.CUSTOMERDETAIL.CONTACT.STREET' | translate }}" required
            [value]="person?.address?.street" [readonly]="readonly"
            (change)="changeContactData('street', $event, 'postalAddress')" formControlName="street" />
          <mat-error *ngIf="contactDataForm.controls['street'].hasError('required')">
            {{ "PAGE.CUSTOMERDETAIL.CONTACT.REQUIREDERROR" | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Zip -->
      <div
        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone">
        <mat-form-field  *ngIf="!readonly">
          <mat-label class="neo-login-label">{{'PAGE.CUSTOMERDETAIL.CONTACT.POSTALCODE' | translate}}</mat-label>
          <input class="neo-login-input" matInput [formControl]="postalCode" name="zip"
            placeholder="{{'PAGE.CUSTOMERDETAIL.CONTACT.POSTALCODE' | translate}}" [matAutocomplete]="auto">

          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedRegion($event)"
            [displayWith]="displayRegionFn.bind(this)">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
              {{option.PLZ}} - {{option.Ort}} ({{option.Gemeinde}})
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

          <mat-form-field *ngIf="readonly" [floatLabel]="readonly ? 'never' : 'auto' ">
            <input matInput placeholder="{{
                'PAGE.CUSTOMERDETAIL.CONTACT.POSTALCODE' | translate
              }}" [value]="person?.address?.postalCode" readonly />
          </mat-form-field>
          
          <mat-error *ngIf="contactDataForm.controls['postalCode'].hasError('required')">
            {{ "PAGE.CUSTOMERDETAIL.CONTACT.REQUIREDERROR" | translate }}
          </mat-error>
      
      </div>

      <!-- City -->
      <div
        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone">
        <mat-form-field [floatLabel]="readonly ? 'never' : 'auto' ">
          <input matInput placeholder="{{ 'PAGE.CUSTOMERDETAIL.CONTACT.CITY' | translate }}" required
            [value]="person?.address?.city" [readonly]="readonly"
            (change)="changeContactData('city', $event, 'postalAddress')" formControlName="city" />
          <mat-error *ngIf="contactDataForm.controls['city'].hasError('required')">
            {{ "PAGE.CUSTOMERDETAIL.CONTACT.REQUIREDERROR" | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      
      <!-- Phone number -->
      <div
        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone">
        <mat-form-field [floatLabel]="readonly ? 'never' : 'auto' ">

          <input matInput placeholder="{{
              'PAGE.CUSTOMERDETAIL.CONTACT.PHONENUMBER' | translate
            }}" 
            formControlName="telNumber" [value]="contactDataForm.controls['telNumber']" [readonly]="readonly" (change)="
              changeContactData(
                getPhoneNumberEnum(),
                $event,
                'communicationAddress'
              )
            " 
            [required]="!contactDataForm.controls['phoneNumber'].value"
            />
            <mat-error *ngIf="contactDataForm.controls['telNumber']?.hasError('required')">
              {{ "PAGE.CUSTOMERDETAIL.CONTACT.REQUIREDERROR" | translate }}
            </mat-error>
        </mat-form-field>
      </div>

      <!-- Mobile Number -->
      <div
        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone">
        <mat-form-field [floatLabel]="readonly ? 'never' : 'auto' ">
          <input matInput placeholder="{{
              'PAGE.CUSTOMERDETAIL.CONTACT.MOBILENUMBER' | translate
            }}" [value]="contactDataForm.controls['phoneNumber']" [ngModelOptions]="{ standalone: true }"
            [readonly]="readonly" (change)="
              changeContactData(
                getMobileNumberEnum(),
                $event,
                'communicationAddress'
              )
            "
            [required]="!contactDataForm.controls['telNumber'].value"
            formControlName="phoneNumber" />
            <mat-error *ngIf="contactDataForm.controls['phoneNumber']?.hasError('required')">
              {{ "PAGE.CUSTOMERDETAIL.CONTACT.REQUIREDERROR" | translate }}
            </mat-error>

          </mat-form-field>
      </div>

      <!-- Email -->
      <div
        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone">
        <mat-form-field [floatLabel]="readonly ? 'never' : 'auto' ">
          <input type="email" matInput placeholder="{{
              'PAGE.CUSTOMERDETAIL.CONTACT.MAILADDRESS' | translate
            }}" [value]="person?.email" [ngModelOptions]="{ standalone: true }"
            [readonly]="readonly" (change)="
              changeContactData(
                getMailEnum(),
                $event,
                'communicationAddress'
              )
            "
            formControlName="email"
            required
            />
            
            <mat-error *ngIf="contactDataForm.controls['email']?.hasError('required')">
              {{ "PAGE.CUSTOMERDETAIL.CONTACT.REQUIREDERROR" | translate }}
            </mat-error>
            <mat-error *ngIf="contactDataForm.controls['email']?.hasError('email')">
              Is not a valid email
            </mat-error>

          </mat-form-field>
      </div>

      <!-- uuid -->
      <div
        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone">
        <mat-form-field [floatLabel]="readonly ? 'never' : 'auto' ">
          <input matInput placeholder="{{ 'PAGE.CUSTOMERDETAIL.CONTACT.UUID' | translate }}" [value]="person?.uuid"
            readonly (change)="changeContactData('uuid', $event)" formControlName="uuid" />
        </mat-form-field>
      </div>

      <!-- Grant -->
      <div
        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone">
        <mat-form-field *ngIf="!readonly" [floatLabel]="readonly ? 'never' : 'auto' " class="mat-select-in">
          <mat-label>{{
            "PAGE.CUSTOMERDETAIL.CONTACT.GRANT" | translate
            }}</mat-label>
          <mat-select (selectionChange)="changeContactData('grantList', $event)" formControlName="grantList">
            <mat-option *ngFor="let item of grantLists" [value]="item.toLowerCase()">{{ item }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="readonly" [floatLabel]="readonly ? 'never' : 'auto' ">
          <input matInput placeholder="{{ 'PAGE.CUSTOMERDETAIL.CONTACT.GRANT' | translate }}"
            [value]="person?.grantList" readonly />
        </mat-form-field>
      </div>
    </div>
  </div>
</form>