import {Component} from "@angular/core";
import {JournalList} from "../../../core/model/journal-list";
import {FormControl} from "@angular/forms";
import {Helper} from "../../../core/static/helper";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {FilterTableComponent} from "../../../core/components/tables/filter-table-component";
import {JournalListJson} from "../../../core/json/journal-list.json";
import {DocumentProvider} from "../../../core/providers/document.provider";
import {ActionDialogService} from "../../../core/providers/action-dialog.service";
import {Policy} from "../../../core/model/policy";
import {ActionDialogData} from "../../../core/components/action.dialog.component";
import {BusinessTransactionHelper} from "../../../core/static/bt-helper";
import {DocumentResourceResult} from "../../../core/model/document-resource-result";
import { SlideRangeFilterValue } from "app/modules/core/model/slide-range-filter-value";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { UserCommitState } from "app/modules/core/model/user-commit-state";

@Component({
    selector: "neomp-provision-accounting-table",
    templateUrl: "./provision-accounting-table.component.html",
    styleUrls: ["./provision-accounting-table.component.scss"],
    animations: [
        trigger("detailExpand", [
            state("collapsed", style({height: "0px", minHeight: "0", display: "none"})),
            state("expanded", style({height: "*"})),
            transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")),
        ]),
    ]
})
export class ProvisionAccountingTableComponent extends FilterTableComponent<JournalList> {

    private readonly TAG = this.constructor.name;
    public filterValues: JournalList = new JournalList();
    isDialogOpen = false;

    descriptionFieldFilter: FormControl = new FormControl("");
    creationDateFilter: FormControl = new FormControl("");
    documentFilter: FormControl = new FormControl("");
 
    public formControlFilters: FormControl[] = [
        this.descriptionFieldFilter,
        this.creationDateFilter,
        this.documentFilter
    ];

    public displayedColumns: string[] = [
        "descriptionField",
        "creationDate",
        "document",
        "filter"
    ];
    public displayedValueColumns: string[] = [
        "descriptionField",
        "creationDate",
        "document"
    ];
    public displayedColumnFilters: string[] = [
        "descriptionFieldFilter",
        "creationDateFilter",
        "documentFilter",
        "filterRemove"
    ];

    constructor(public documentProvider: DocumentProvider, 
        public dialogService: ActionDialogService,
        public dialog: MatDialog,
        public matSnackBar: MatSnackBar,
        private translateService: TranslateService
        ) {
        super();
    }

    creationDateFilterChanged(event: SlideRangeFilterValue): void {
        this.creationDateFilter.setValue(event.dateRange);
    }

    createFilter() {
        return function (data: JournalList, filter: string): boolean {
            const filterJson: JournalListJson = JSON.parse(filter);
            const searchTerms: JournalList = JournalList.fromJson(filterJson);
            return (Helper.checkStringContains(data.descriptionField, searchTerms.descriptionField) &&
                Helper.checkDateRange(new Date(data.creationDate), Helper.stringToDatePickerFormat(searchTerms.creationDate)));
        };
    }

    resetFilter() {
        this.filterValues = new JournalList();
        super.resetFilter();
    }

    formatDate(date) {
        return Helper.formatDate(date);
    }

    download(id: string) {
        if(id){
            this.documentProvider.downloadDocument(id);
        }
        else{
            const translatedMessage = this.translateService.instant('PAGE.CONTAINER.MESSAGES.ERROR-DOCUMENT');
            this.matSnackBar.open(translatedMessage, 'X', UserCommitState.configCheckData);
        }
    }
    
    downloadFromMyNeo(id: string) {
        if(id){
            this.documentProvider.downloadDocument(id, true);
        }
        else{
            const translatedMessage = this.translateService.instant('PAGE.CONTAINER.MESSAGES.ERROR-DOCUMENT');
            this.matSnackBar.open(translatedMessage, 'X', UserCommitState.configCheckData);
        }
    }

    openActionDialog(event: Event, element: JournalList) {
        this.isDialogOpen = true;
        event.stopPropagation();
        const dialogData: ActionDialogData = {
            transTypeId: BusinessTransactionHelper.typeId.provisionAccounting.from,
            transName: "Provision Abrechnung",
            transSubject: "Provision Abrechnung",
            transTitle: "Provision Abrechnung",
            transMessage: "Provision Abrechnung",
            operationalIdExternal: element.transaction.sorKeys["ProcessID"],
            keyValues: [],
            person: null,
            completed: true
        };
        this.dialogService.openByType(dialogData, {defaultTypeId: BusinessTransactionHelper.typeId.provisionAccounting.from}, this.TAG);
        this.dialog.afterAllClosed.subscribe(() => {
            this.isDialogOpen = false;
        });
    }
}
