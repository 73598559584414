<table [dataSource]="dataSource" mat-table matSort multiTemplateDataRows>

      <ng-container matColumnDef="advId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'PAGE.REFFERALS.ID' | translate }} </th>
          <td mat-cell *matCellDef="let element"> {{element.advId}} </td>
       </ng-container>
    
      <!-- Weight Column -->
      <ng-container matColumnDef="uuid">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'PAGE.REFFERALS.UUID' | translate }} </th>
          <td mat-cell *matCellDef="let element"> {{element.uuid}} </td>
      </ng-container>
    
      <!-- Symbol Column -->
      <ng-container matColumnDef="registered">
          <th mat-header-cell *matHeaderCellDef mat-sort-header > {{ 'PAGE.REFFERALS.REGISTERED' | translate }} </th>
          <td [ngClass]="element.registered == true ? 'referals-registered-completed' : 'referals-registered-not-completed'" mat-cell *matCellDef="let element"></td>
      </ng-container>
  
      <!-- Symbol Column -->
      <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'PAGE.REFFERALS.NAME' | translate }} </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>
  
      <!-- Symbol Column -->
      <ng-container matColumnDef="email">
         <th mat-header-cell *matHeaderCellDef  mat-sort-header> Email </th>
          <td mat-cell *matCellDef="let element"> {{element.email}} </td>
      </ng-container>
  
      <!-- Symbol Column -->
      <ng-container matColumnDef="address">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'PAGE.REFFERALS.ADDRESS' | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.address}} </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'PAGE.REFFERALS.PHONENUMBER' | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
      </ng-container>


    <!----------------------------Filter Table------------------------------------>


      <ng-container  matColumnDef="filter">
        <th *matHeaderCellDef mat-header-cell (click)="changeFilterView()"
            class="mat-header-cell mat-column-filter {{isFilterActive ? 'mat-column-filter-active':''}}">
            <div class="neomp-table-column-filter-element  mdi {{isFilterActive ? 'mdi-filter':'mdi-filter-outline'}}"></div>
        </th>
        <td mat-cell *matCellDef="let element" class="mat-column-filter-actions">
            <div class="neomp-table-column-filter-element mdi mdi-plus"></div>
        </td>
      </ng-container>

     <!---------------------------- AdvisorID Filter------------------------------------>

     <ng-container matColumnDef="advIdFilter">
      <th mat-header-cell *matHeaderCellDef>
        <mat-form-field floatLabel="never">
           <input matInput [formControl]="advIdFilter"
           placeholder="{{ 'PAGE.REFFERALS.ID' | translate  }}">
        </mat-form-field>
      </th>
     </ng-container>

     <!---------------------------- ID Filter------------------------------------>

     <ng-container matColumnDef="uuidFilter">
        <th mat-header-cell *matHeaderCellDef>
          <mat-form-field floatLabel="never">
            <input matInput [formControl]="uuidFilter"
             placeholder="{{ 'PAGE.REFFERALS.UUID' | translate  }}">
         </mat-form-field>
        </th>
     </ng-container>

     <!---------------------------- Registered Filter------------------------------------>

    <ng-container matColumnDef="registeredFilter">
      <th mat-header-cell *matHeaderCellDef></th>
    </ng-container>
     <!---------------------------- Name Filter------------------------------------>

    <ng-container matColumnDef="nameFilter">
     <th mat-header-cell *matHeaderCellDef>
         <mat-form-field floatLabel="never">
             <input matInput [formControl]="nameFilter"
                 placeholder="{{ 'PAGE.REFFERALS.NAME' | translate }}">
          </mat-form-field>
        </th>
     </ng-container>
     <!---------------------------- Email Filter------------------------------------>

      <ng-container matColumnDef="emailFilter">
        <th mat-header-cell *matHeaderCellDef>
          <mat-form-field floatLabel="never">
            <input matInput [formControl]="emailFilter"
                placeholder="{{ 'Email' }}">
           </mat-form-field>
         </th>
      </ng-container>
    <!---------------------------- Address Filter------------------------------------>

      <ng-container matColumnDef="addressFilter">
        <th mat-header-cell *matHeaderCellDef>
            <mat-form-field floatLabel="never">
                <input matInput [formControl]="addressFilter"
                    placeholder="{{  'PAGE.REFFERALS.ADDRESS' | translate }}">
            </mat-form-field>
        </th>
      </ng-container>
      <!---------------------------- Phone Filter------------------------------------>

      <ng-container matColumnDef="phoneFilter">
        <th mat-header-cell *matHeaderCellDef>
            <mat-form-field floatLabel="never">
                <input matInput [formControl]="phoneFilter"
                    placeholder="{{  'PAGE.REFFERALS.PHONENUMBER' | translate }}">
            </mat-form-field>
        </th>
      </ng-container>
     <!---------------------------- Remove Filter------------------------------------>

     <ng-container matColumnDef="filterRemove">
         <th mat-header-cell *matHeaderCellDef (click)="resetFilter()">
           <div class="neomp-table-column-filter-element mdi mdi-close"></div>
         </th>
     </ng-container>

     <!---------------------------- End of Filter------------------------------------>
  
  
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  
  
  <tr *matHeaderRowDef="displayedColumnFilters"
  class="neomp-table-row-filter {{showFilter? '': 'neomp-table-row-filter-hide'}}" mat-header-row></tr>

  
</table>  