<div class="mdc-layout-grid msg-helper-padding-none">
    <div class="mdc-layout-grid__inner msg-helper-grid-gap-horizontal">
        <div class="mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone" >
            <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop
            mdc-layout-grid__cell--span-6-tablet
            mdc-layout-grid__cell--span-12-phone msg-btn-row">
                <div class="mdc-layout-grid msg-helper-padding-none msg-helper-margin-top-15">
                    <button color="primary" mat-raised-button (click)="openActionDialog($event)" [disabled]="isDialogOpen">{{
                        'PAGE.CUSTOMERDETAIL.ACTIONS.SENDNEWS' | translate }}</button>
                </div>
            </div>
            <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop
            mdc-layout-grid__cell--span-6-tablet
            mdc-layout-grid__cell--span-12-phone
            mdc-layout-grid--align-right
            neo-range-filter">
                <neomp-slide-range-filter [filterValue]="filterValue" (filterChangeEmitter)="getElements($event)"
                    [disabled]="isDataLoading">
                </neomp-slide-range-filter>
            </div>
        </div>
        <div class="mdc-layout-grid__cell--span-6"></div>
    </div>
    <div class="mdc-layout-grid__inner msg-helper-grid-gap-horizontal">
        <div class="mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
            <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop
            mdc-layout-grid__cell--span-12-tablet
            mdc-layout-grid__cell--span-12-phone">
                <neomp-messages-table (dataRelatedMessages)="getRelatedMessagesEvent($event)" [tableEntries]="messages" [journalData]="journalData"></neomp-messages-table>
                <neomp-loading-data #messagesTable></neomp-loading-data>
            </div>
        </div>
        <div class="mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
            <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop
            mdc-layout-grid__cell--span-12-tablet
            mdc-layout-grid__cell--span-12-phone">
            <neomp-container cssClass="p-20 msg-mp-container-frameless " headlineText="Related messages">
                <neomp-related-messages-table [tableEntries]="relatedMessages" [journalData]="relatedMsgJournalData">
                </neomp-related-messages-table>
                <neomp-loading-data #messagesTable></neomp-loading-data>
            </neomp-container>
        </div>
    </div>
</div>