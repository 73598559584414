import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {CoreModule} from "../core/core.module";
import {MyOfferPageOldComponent} from "./pages/my-offer-page-old.component";
import {OfferWizardOldComponent} from "./components/offer-wizard-old.component";
import {CreatedRequestsOldComponent} from "./components/created-requests-old.component";
import {FilledOfferDocumentsTableOldComponent} from "./components/filled-offer-documents-table-old.component";
import { GmCreatedOffersOldComponent } from "./components/gm-created-offers-old.component";

@NgModule({
    imports: [
        CommonModule,
        CoreModule
    ],
    declarations: [
        MyOfferPageOldComponent,
        OfferWizardOldComponent,
        CreatedRequestsOldComponent,
        FilledOfferDocumentsTableOldComponent,
        GmCreatedOffersOldComponent
    ],
    exports: [
        MyOfferPageOldComponent,
        OfferWizardOldComponent,
        CreatedRequestsOldComponent,
        FilledOfferDocumentsTableOldComponent
    ]
})
export class OffersOldModule {
}
