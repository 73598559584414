<mat-tab-group>
  <mat-tab class="mortgage-tab" label="{{ 'PAGE.MORTGAGE.TAB.CREATE_LEAD' | translate }}">
    <div>
      <div class="mdc-layout-grid msg-helper-padding-none">
        <div class="mdc-layout-grid__inner msg-helper-grid-gap-15 d-flex">
          <div
            class="card mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone ng-star-inserted">

            <mat-card *ngIf="selectedType===types[0]">
              <mat-card-content>
                <form [formGroup]="newMortgage">
                  <div class="mdc-layout-grid msg-helper-padding-none" id="newMortgage">
                    <div class="mdc-layout-grid__inner msg-helper-grid-gap-10">
                      <div
                        class="pb-25 mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-6-phone ng-star-inserted">
                        <mat-label>{{ 'PAGE.MORTGAGE.NEW_MORTGAGE.OBJECT_TYPE' | translate }}</mat-label>
                        <mat-form-field appearance="outline" class="mat-select-in">

                          <mat-select [(ngModel)]="selectedObjectType" name="selectedObjectType"
                            formControlName="objectType">
                            <mat-option *ngFor="let type of objectType" [value]="type">
                              {{ type }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div
                        class=" mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone ng-star-inserted">
                        <div>
                          <mat-label>{{ 'PAGE.MORTGAGE.NEW_MORTGAGE.INCOME' | translate }}</mat-label>
                          <mat-form-field class="input-field" appearance="outline">
                            <input matInput formControlName="income" chf class="example-right-align">
                            <span matSuffix>CHF</span>
                          </mat-form-field>
                        </div>
                      </div>
                      <div
                        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone ng-star-inserted">
                        <mat-label>{{ 'PAGE.MORTGAGE.NEW_MORTGAGE.VALUE_PROPERTY' | translate }}</mat-label>
                        <mat-form-field class="input-field" appearance="outline">
                          <input formControlName="valueOfProperty" chf matInput>
                          <span matSuffix>CHF</span>
                        </mat-form-field>
                      </div>
                      <div
                        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone ng-star-inserted">
                        <mat-label>{{ 'PAGE.MORTGAGE.NEW_MORTGAGE.OWN_FUNDS' | translate }}</mat-label>
                        <mat-form-field class="input-field" appearance="outline">
                          <input formControlName="ownFunds" chf matInput>
                          <span matSuffix>CHF</span>

                        </mat-form-field>
                      </div>
                      <div
                      class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop">
                        <mat-label>{{ 'PAGE.MORTGAGE.NEW_MORTGAGE.INTEREST' | translate }}</mat-label>
                        <div class="d-flex full-width justify-end">
                          <!-- Input field with zinc form control -->
                          <div class="d-flex items-center full-width gap-10">
                            <mat-form-field class="input-field" appearance="outline">
                              <input formControlName="zinc" matInput type="text" min="0" [(ngModel)]="zinsenTmp" (input)="ZinsenThousand(); calculateOnChange()" 
                              (change)="ZinsenThousand(); calculateOnChange()"  required>
                            </mat-form-field>
                          </div>
  
                          <div class=" d-flex mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-4-tablet mdc-layout-grid__cell--span-2-phone">
                            <button (click)="Decrease('Zinsen', 0.1)" class=" neo-button neo-js-button neo-button--fab neo-button--mini-fab ">
                              <div class="neo-minus"></div>
                            </button>
                            <button (click)="Increase('Zinsen', 0.1)" class="neo-button neo-js-button  neo-button--fab neo-button--mini-fab ml-0">
                              <div class="neo-plus"></div>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div
                        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop d-flex full-width justify-end">
                        <mat-slider class="full-width" [min]="0.1" [max]="5" [step]="0.1"
                          value="{{ zinsen }}" (input)=" zinsenTmp = $event.value; ZinsenThousand(); calculateOnChange()" (change)=" ZinsenThousand();  calculateOnChange()">
                        </mat-slider>
                      </div>

                      <div
                        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop d-flex full-width justify-end">
                        <div class="d-flex items-center full-width gap-65 justify-end ">
                          <div class="half-width">
                            <mat-label class="d-flex justify-center ">{{ 'PAGE.MORTGAGE.NEW_MORTGAGE.PORTABILITY' |
                              translate }}</mat-label>
                            <mat-form-field class="input-field" appearance="outline">
                              <input [ngStyle]="{'text-align': 'center'}" matInput readonly [value]="tragbarkeit + ' %' ">
                            </mat-form-field>
                            
                          </div>
                          <!-- Button -->
                          <div>
                            <button class="btn-send" mat-flat-button (click)="sendNewMortgage()"
                              [disabled]="!newMortgage.valid || !mortgage.valid || (tragbarkeit > 40 || tragbarkeit < 0)">
                              {{ "PAGE.MORTGAGE.NEW_MORTGAGE.SEND" | translate }}
                            </button>
                          </div>
                        </div>
                      </div>

                      <div
                      class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop d-flex full-width justify-center">
                      <span *ngIf="tragbarkeit > 40" style="color: red;">{{ "PAGE.MORTGAGE.NEW_MORTGAGE.ERROR" | translate }}</span>
                    </div>
                    </div>
                  </div>
                </form>
              </mat-card-content>
            </mat-card>
          </div>
          <div
            class="card mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone ng-star-inserted">

            <mat-card>
              <mat-card-content>
                <form [formGroup]="mortgage">
                  <div class="mdc-layout-grid msg-helper-padding-none" id="mortgage">
                    <div class="mdc-layout-grid__inner msg-helper-grid-gap-10">

                      <div
                        class="pb-25 mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone ng-star-inserted">
                        <mat-label>{{ 'PAGE.MORTGAGE.MORTGAGE-TYPE' | translate }}</mat-label>
                        <mat-form-field appearance="outline" class="mat-select-in">
                          <mat-select [(ngModel)]="selectedType" name="selectedType" formControlName="mortgageType">
                            <mat-option *ngFor="let type of types" [value]="type">
                              {{ type }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div
                        class=" mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone ng-star-inserted">

                        <div>
                          <mat-label>{{ 'PAGE.MORTGAGE.NEW_MORTGAGE.SALUTATION' | translate }}</mat-label>
                          <mat-form-field appearance="outline" class="mat-select-in">
                            <mat-select [(ngModel)]="selectedSalutation" name="selectedSalutation" formControlName="salutation">
                              <mat-option *ngFor="let salutation of salutations" [value]="salutation">
                                {{ salutation }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                      <div
                        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone ng-star-inserted">
                        <mat-label>{{ 'PAGE.MORTGAGE.NEW_MORTGAGE.FIRST_NAME' | translate }}</mat-label>
                        <mat-form-field class="input-field" appearance="outline">
                          <input formControlName="name" matInput>
                        </mat-form-field>
                      </div>
                      <div
                        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone ng-star-inserted">
                        <mat-label>{{ 'PAGE.MORTGAGE.NEW_MORTGAGE.LAST_NAME' | translate }}</mat-label>
                        <mat-form-field class="input-field" appearance="outline">
                          <input formControlName="firstName" matInput>
                        </mat-form-field>
                      </div>
                      <div
                        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone ng-star-inserted">
                        <mat-label>{{ 'PAGE.MORTGAGE.NEW_MORTGAGE.CITY' | translate }}</mat-label>
                        <mat-form-field class="input-field" appearance="outline">
                          <input formControlName="ort" matInput>
                        </mat-form-field>
                      </div>

                      <div
                        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone ng-star-inserted">
                        <mat-label>{{ 'PAGE.MORTGAGE.NEW_MORTGAGE.STREET' | translate }}</mat-label>
                        <mat-form-field class="input-field" appearance="outline">
                          <input formControlName="address" matInput>
                        </mat-form-field>
                      </div>
                      <div
                        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone ng-star-inserted">
                        <mat-label>{{ 'PAGE.MORTGAGE.NEW_MORTGAGE.ZIP_CODE' | translate }}</mat-label>
                        <mat-form-field class="input-field" appearance="outline">
                          <input formControlName="zip" matInput>
                        </mat-form-field>
                      </div>

                      <div
                        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone ng-star-inserted">
                        <mat-label>{{ 'PAGE.MORTGAGE.NEW_MORTGAGE.EMAIL' | translate }}</mat-label>
                        <mat-form-field class="input-field" appearance="outline">
                          <input formControlName="email2" type="email" matInput>
                        </mat-form-field>
                      </div>
                      <div
                        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone ng-star-inserted">
                        <mat-label>{{ 'PAGE.MORTGAGE.NEW_MORTGAGE.TELEPHONE' | translate }}</mat-label>
                        <mat-form-field class="input-field" appearance="outline">
                          <input formControlName="phone1" matInput>
                        </mat-form-field>
                      </div>
                      <div
                        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone ng-star-inserted">
                        <mat-label>{{ 'PAGE.MORTGAGE.NEW_MORTGAGE.MOBILE' | translate }}</mat-label>
                        <mat-form-field class="input-field" appearance="outline">
                          <input formControlName="phone2" matInput>
                        </mat-form-field>
                      </div>


                      <div *ngIf="selectedType===types[1]"
                        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone ng-star-inserted">
                        <!-- Button -->
                        <div class="d-flex justify-end">
                          <button class="btn-send" mat-flat-button (click)="sendMortgage()"
                            [disabled]="!mortgage.valid">
                            {{ "PAGE.MORTGAGE.NEW_MORTGAGE.SEND" | translate }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </div>
    </div>
  </mat-tab>

  <mat-tab class="mortgage-tab" label="{{'PAGE.MORTGAGE.TAB.LEAD_OVERVIEW' | translate}}">
    <neomp-mortgage-table [tableEntries]="leads" ></neomp-mortgage-table>
    <neomp-loading-data #loadingFamilyTable></neomp-loading-data>
    <neomp-paginator [length]="totalCountLeads" [pageSize]="pageSize" [pageIndex]="pageIndex"
      [pageSizeOptions]="pageSizeOptions" (paginatorChange)="paginatorAssignedLeadsValueChanged($event)">
    </neomp-paginator>
  </mat-tab>
</mat-tab-group>