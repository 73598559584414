import { Person } from './../model/person';
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
// import "rxjs-compat/add/observable/of";
import {RestProvider, RestProviderActions} from "./rest.provider";
import {environment} from "../../../../environments/environment";
import {PersonJson} from "../json/person.json";
import { map } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class LeadProvider {

    constructor(public rest: RestProvider) {
    }

    public getLazyLeads(
        restProviderActions: RestProviderActions,
        page: number,
        size: number,
        filter?: string): Observable<{data: PersonJson[], totalCount: number}> {
        let queryParams = `?page=${page}&size=${size}&${filter}`;
return this.rest.get<{data: PersonJson[], totalCount: number}>(`${environment.settings.BASE_URL}/api/lead/getleads${queryParams}`,
            null, false, restProviderActions)
            .pipe(map((res) => {
                return {
                    data: Person.fromJsons(res.data),
                    totalCount: res.totalCount
                };
            }));
    }

    public getReturnedLeads(
        restProviderActions: RestProviderActions,
        page: number,
        size: number,
        filter?: string): Observable<{data: PersonJson[], totalCount: number}> {
        let queryParams = `?page=${page}&size=${size}&${filter}`;
        return this.rest.get<{data: PersonJson[], totalCount: number}>(`${environment.settings.BASE_URL}/api/lead/returnedleads${queryParams}`,
            null, false, restProviderActions)
            .pipe(map((res) => {
                return {
                    data: Person.fromJsons(res.data),
                    totalCount: res.totalCount
                };
            }));
    }

    public getUnassignedLeads(restProviderActions: RestProviderActions, page: number, size: number, filter?: string): Observable<{ data: PersonJson[], totalCount: number }> {
        let queryParams = `?page=${page}&size=${size}&${filter}`;
        return this.rest.get<{ data: PersonJson[], totalCount: number }>(`${environment.settings.BASE_URL}/api/lead/getunassignedleads${queryParams}`,
            null, false, restProviderActions)
            .pipe(map((res) => {
                return {
                    data: Person.fromJsons(res.data),
                    totalCount: res.totalCount
                };
            }));
    }


    public getLeads(restProviderActions: RestProviderActions, useCache = true, mandatesOnly = false): Observable<Person[]> {
        let queryParams = `?mandateOnly=${mandatesOnly}`;
return this.rest.get<{data: PersonJson[], totalCount: number}>(`${environment.settings.BASE_URL}/api/lead/getleads${queryParams}`,
            null, useCache, restProviderActions)
            .pipe(map((res) => {
                return Person.fromJsons(res.data);
            }));
    }

    public getAgents( useCache = true): Observable<Person[]> {
        return  this.rest.get<Person[]>(`${environment.settings.BASE_URL}/api/advisor/agentur-related-advisors`,
        null, useCache)
        .pipe(map((res) => {
            return Person.fromJsons(res);
        }));
      }

      public getUnblockedAgents( useCache = true): Observable<Person[]> {
        return  this.rest.get<Person[]>(`${environment.settings.BASE_URL}/api/advisor/agentur-related-advisors?unblockedOnly=true`,
        null, useCache)
        .pipe(map((res) => {
            return Person.fromJsons(res);
        }));
      }

      public getAttributeValues(): Observable<{ leadCategories: string[], leadTypes: string[]}> {
        const actionUrl = `${environment.settings.BASE_URL}/api/lead/getattributevalues`;
        return this.rest.get<{ data: { leadCategories: string[], leadTypes: string[] }}>(actionUrl, null, false).pipe(map((res) => {
            return {
                leadCategories: res.data.leadCategories,
                leadTypes: res.data.leadTypes
            };
        }))
      }

    public getLeadsAssignedInAgency(
        restProviderActions: RestProviderActions,
        page: number,
        size: number,
        filter?: string): Observable<{data: PersonJson[], totalCount: number}> {
        let queryParams = `?page=${page}&size=${size}&${filter}`;
        return this.rest.get<{data: PersonJson[], totalCount: number}>(`${environment.settings.BASE_URL}/api/lead/getLeadsAssignedInAgency${queryParams}`, 
            null, false, restProviderActions)
            .pipe(map((res) => {
                return {
                    data: Person.fromJsons(res.data),
                    totalCount: res.totalCount
                };
            }));
    }

}
