export class GMProduct {
    accidentCoverageCode: string;
    annualDeductibleCode: string;
    criteria1Code: string;
    criteria2Code: string;
    delaiCode: string;
    insurerCode: string;
    levelCode: string;
    productCode: string;

    constructor(        
    accidentCoverageCode: string,
    annualDeductibleCode: string,
    criteria1Code: string,
    criteria2Code: string,
    delaiCode: string,
    insurerCode: string,
    levelCode: string,
    productCode: string
    ){        
    this.accidentCoverageCode = accidentCoverageCode;
    this.annualDeductibleCode = annualDeductibleCode;
    this.criteria1Code = criteria1Code;
    this.criteria2Code = criteria2Code;
    this.delaiCode = delaiCode;
    this.insurerCode = insurerCode;
    this.levelCode = levelCode;
    this.productCode = productCode;
    }
}