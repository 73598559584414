import { Component, OnInit, ViewChild } from '@angular/core';
import {Journal} from "../../core/model/journal";
import { JournalProvider } from 'app/modules/core/providers/journal.provider';
import { LoadingDataComponent } from 'app/modules/core/components/loading-data.component';
import { JournalList } from 'app/modules/core/model/journal-list';
import { SlideRangeFilterValue } from 'app/modules/core/model/slide-range-filter-value';
import { PaginationConfigs } from 'app/modules/constants/PaginationConstants';
import moment from 'moment';
import {Observable} from "rxjs";
import { BusinessTransactionHelper } from 'app/modules/core/static/bt-helper';

@Component({
  selector: 'neomp-gm-created-offers',
  templateUrl: './gm-created-offers.component.html',
  styleUrls: ['./gm-created-offers.component.scss']
})
export class GmCreatedOffersComponent implements OnInit {

  @ViewChild("loadingDataComponent")
  loadingDataComponent: LoadingDataComponent;
  journalLists: JournalList[] = [];
  journals: Journal[] = [];
  filterValue: SlideRangeFilterValue = new SlideRangeFilterValue();
  journalData: Map<string, any> = new Map<string, any>();

  isInitialized: boolean;
  isDataLoading: boolean;
  pageSize = PaginationConfigs.pageSize;
  pageSizeOptions = PaginationConfigs.pageSizeOptions;
  pageIndex = PaginationConfigs.pageIndex;
  totalCount = 0;

  
  constructor(public journalProvider: JournalProvider) {
  }

  ngOnInit() {
      this.filterValue.isActive = true;
      this.filterValue.dateRange.end = moment();
      this.filterValue.dateRange.begin = moment().subtract(60, "days");
      
      this.getElements(this.filterValue);
  }

  ngAfterViewInit(): void {
      this.isInitialized = true;
  }

  getElements(filterValue) {
      this.dataLoading = true;
      const sort = { creationDate: -1 };
      const journalObservable: Observable<Journal[]> =
          this.journalProvider.getJournalsByDateRange(
              this.loadingDataComponent,
              BusinessTransactionHelper.typeId.gmOffer.from,
              BusinessTransactionHelper.typeId.gmOffer.to,
              filterValue.dateRange,
              sort)

      journalObservable.subscribe(journalLists => {
          this.journals = journalLists;
          this.totalCount = this.journals.length;
          this.dataLoading = false;
      }, error => {
          this.dataLoading = false;
      });
      
  }

  set dataLoading(dataLoading: boolean) {
      if (this.isInitialized) {
          this.isDataLoading = dataLoading;
      }
  }
  paginatorValueChanged(event) {
      this.pageIndex = event.pageIndex;
      this.pageSize = event.pageSize;
      this.getElements(this.filterValue);
    }

}
