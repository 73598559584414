import {EventEmitter, Injectable} from "@angular/core";
import {ConfigProvider} from "./config.provider";
import {ActionDialogComponent, ActionDialogData} from "../components/action.dialog.component";
import {log} from "./logger.provider";
import { Location } from "@angular/common";
import {UserCommitState} from "../model/user-commit-state";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import {LoadingService} from "./loading.component";
import {ActionDataProvider} from "./action-data.provider";
import {TranslateService} from "@ngx-translate/core";
import {Journal} from "../model/journal";
import {Observable} from "rxjs";
import {KeyValuePair} from "../model/key-value-pair";
import { BusinessTransactionHelper } from "../static/bt-helper";
import { RegisterProvider } from "./register.provider";
import { ActionData } from "../model/action-data";
import { LeadActionComponent } from "app/modules/leads/pages/dialogs/lead.action.component";
import { LeadsService } from "app/modules/leads/services/leads.service";
import { LeadStatusDialogComponent } from "app/modules/leads/pages/dialogs/lead-status-dialog/lead-status-dialog.component";
import { TaskCompleteComponent } from "app/modules/leads/pages/dialogs/task-complete/task-complete.component";

@Injectable({
    providedIn: "root"
})
export class ActionDialogService {
    isUpdated: EventEmitter<boolean> = new EventEmitter();
    isSaved: EventEmitter<boolean> = new EventEmitter();
    cancellationActionDone: EventEmitter<boolean> = new EventEmitter();

    constructor(public actionDataProvider: ActionDataProvider,
                public leadService: LeadsService,
                public configProvider: ConfigProvider,
                public loadService: LoadingService,
                public matDialog: MatDialog,
                public matSnackBar: MatSnackBar,
                public translateService: TranslateService,
                public location: Location,
                public registerProvider: RegisterProvider) {

    }

    openProvision(actionDialogData: ActionDialogData, tag: string) {
        this.configProvider.getConfigValue(
            {
                defaultTypeId: BusinessTransactionHelper.typeId.provision.from, 
                typeId: BusinessTransactionHelper.typeId.provision.from 
            }).subscribe(
            values => {
                actionDialogData.keyValues = values.data;
                this.openDialog(actionDialogData, tag);
             },
            (error) => {
                log.debug(`openActionDialog.getConfigValue -> err=${error}`);
                this.openSnackbar("COMMONS.LOADINGSTATUS.ERROR", UserCommitState.configError);
        });
    }

    openJournal(actionDialogData: ActionDialogData, journal: Journal, tag: string): void {
        const configObservable = this.configProvider.getConfigValue({defaultTypeId: BusinessTransactionHelper.typeId.customer.from, typeId: journal.typeId});
        this.open(configObservable, actionDialogData, tag);
    }

    openByType(actionDialogData: ActionDialogData, filter: {defaultTypeId?: string, from?: string, to?: string, typeId?: string}, tag:string): void {
        var configObservable;
        filter.defaultTypeId != null ? configObservable = this.configProvider.getConfigValue(filter) : configObservable = this.configProvider.getConfigValueByRange(filter);
         this.open(configObservable, actionDialogData, tag);
    }

    private open(configObservable: Observable<{selectedType: string, data: KeyValuePair[]}>, actionDialogData: ActionDialogData, tag: string): void {
        configObservable.subscribe(values => {
            actionDialogData.keyValues = values.data;
            actionDialogData.transTypeId = values.selectedType;
            this.openDialog(actionDialogData, tag);
        }, (error) => {
            log.debug(`openActionDialog.getConfigValue -> err=${error}`);
            this.openSnackbar("COMMONS.LOADINGSTATUS.ERROR", UserCommitState.configError);
        });
    }

    private openDialog(actionDialogData: ActionDialogData, tag: string): void {
        this.loadService.setDialogClosed(false);
        const dialogRef = this.matDialog.open(
            ActionDialogComponent,
            ActionDialogComponent.getConfigWithData(actionDialogData));
        dialogRef.afterClosed()
            .subscribe(data => {
                if (!data) {
                    this.isSaved.emit(false);
                    return;
                }
                this.loadService.display(true);
                log.debug(`sending as action data -> ${data}`);

                this.actionDataProvider.sendActionData(data)
                    .subscribe(value => {
                        if (['590','591','592','593','594','595'].includes(data.transaction.typeId)){
                            this.cancellationActionDone.emit(true);
                        }
                        this.isSaved.emit(true);
                        log.debug(`${tag}: Response on sendActionData ${value}`);
                        this.loadService.display(false);
                        this.loadService.setDialogClosed(true);
                        this.openSnackbar("COMMONS.USERCOMMIT.SUCCESS", UserCommitState.configSuccess);
                    }, error => {
                        log.debug(`${tag}: Response on sendActionData [${error}]`);
                        this.loadService.display(false);
                        this.openSnackbar("COMMONS.USERCOMMIT.ERROR", UserCommitState.configError);
                    });
                    
            });
    }

    private openLeadDialog(actionData: ActionData, dialogData: { keyValues: KeyValuePair[], typeId: string}): void {
        this.loadService.setDialogClosed(false);

        const dialogRef = this.matDialog.open(
            LeadActionComponent,
            LeadActionComponent.getTaskConfigWithData(actionData, dialogData));
            dialogRef.afterClosed()
            .subscribe(data => {
                if (!data) {
                    return;
                }
                this.loadService.display(true);
                log.debug(`sending as action data -> ${data}`);
                if (data.transaction.typeId === BusinessTransactionHelper.typeId.leadNegative.from) {
                    data.person.lead.termin_berater_shortcut = data.person.lead.lead_agency;  
                }
                this.leadService.sendLeadAction(data)
                    .subscribe(value => {
                        this.isUpdated.emit(true);
                        this.loadService.display(false);
                        this.loadService.setDialogClosed(true);
                        this.openSnackbar("COMMONS.USERCOMMIT.SUCCESS", UserCommitState.configSuccess);
                    }, error => {
                        this.loadService.display(false);
                        this.openSnackbar("COMMONS.USERCOMMIT.ERROR", UserCommitState.configError);
                    });
                    
            });
    }

    public openTaskDialog(actionData: ActionData, filter: {defaultTypeId: string, typeId?: string}): void {
        const configObservable = this.configProvider.getConfigValue(filter);
        configObservable.subscribe(values => {
            this.openLeadDialog(actionData, { keyValues: values.data, typeId: values.selectedType});
        }, (error) => {
            log.debug(`openActionDialog.getConfigValue -> err=${error}`);
            this.openSnackbar("COMMONS.LOADINGSTATUS.ERROR", UserCommitState.configError);
        });
    }

    public setStatusDialog(actionData: ActionData, dialogData: { keyValues: KeyValuePair[], typeId: string}): MatDialogRef<LeadStatusDialogComponent> {
        this.loadService.setDialogClosed(false);
       return this.matDialog.open(
            LeadStatusDialogComponent,
            LeadStatusDialogComponent.getTaskConfigWithData(actionData, dialogData));
    }

    public updateTaskCompletedDialog(actionData: ActionData, dialogData: { keyValues: KeyValuePair[], typeId: string}): MatDialogRef<TaskCompleteComponent> {
        this.loadService.setDialogClosed(false);
       return this.matDialog.open(
            TaskCompleteComponent,
            TaskCompleteComponent.getTaskConfigWithData(actionData, dialogData));
    }

    openSnackbar(translationKey: string, config: MatSnackBarConfig<any>): void {
        this.translateService.get(translationKey)
            .subscribe(result => {
                this.matSnackBar.open(result, "x", config);
            });
    }
}
