import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { NachweisComponent } from './components/nachweis/nachweis.component';
// import { FinanceComponent } from './components/finance/finance.component';
// import { KMUComponent } from './components/kmu/kmu.component';
// import { LifeComponent } from './components/life/life.component';
import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {CoreModule} from "../core/core.module";
// import {MyOfferPageComponent} from "./pages/my-offer-page.component";
// import {HouseholdComponent} from './components/household/household.component';
// import {OfferWizardComponent} from "./components/offer-wizard.component";
import {CreatedRequestsComponent} from "./components/created-requests.component";
// import {FilledOfferDocumentsTableComponent} from "./components/filled-offer-documents-table.component";
// import { OfferComponent } from './components/offer/offer.component';
// import { GegenofferComponent } from './components/gegenoffer/gegenoffer.component';
// import { AnpassungComponent } from './components/anpassung/anpassung.component';
// import { MotorVehicleComponent } from "./components/motor-vehicle/motor-vehicle.component";
// import { PolicyHolderComponent } from './components/policy-holder/policy-holder.component';
// import { VehicleDataComponent } from './components/vehicle-data/vehicle-data.component';
// import { TestComponent } from './components/form-builder/form-builder.component';
import { GmCreatedOffersComponent } from './components/gm-created-offers.component';
// import { CreditCardComponent } from './components/finance/credit-card/credit-card.component';
// import { MortgageComponent } from './components/finance/mortgage/mortgage.component';
// import { PersonalLoanComponent } from './components/finance/personal-loan/personal-loan.component';
// import { SmeLoanComponent } from './components/finance/sme-loan/sme-loan.component';
// import {NeoOfferLibraryModule} from "neo-offer";

@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        ReactiveFormsModule,
        HttpClientModule,
        FormsModule
        // NeoOfferLibraryModule        
    ],
    declarations: [
        // MyOfferPageComponent,
        // OfferWizardComponent,
        CreatedRequestsComponent,
        // FilledOfferDocumentsTableComponent,
        // OfferComponent,
        // GegenofferComponent,
        // AnpassungComponent,
        // NachweisComponent,
        // HouseholdComponent,
        // MotorVehicleComponent,
        // LifeComponent,
        // KMUComponent,
        // FinanceComponent,
        // PolicyHolderComponent,
        // VehicleDataComponent,
        // TestComponent,
        GmCreatedOffersComponent,
        // CreditCardComponent,
        // MortgageComponent,
        // PersonalLoanComponent,
        // SmeLoanComponent,
    ],
    exports: [
        // MyOfferPageComponent,
        // OfferWizardComponent,
        CreatedRequestsComponent,
        // FilledOfferDocumentsTableComponent,
        // OfferComponent,
        // GegenofferComponent,
        // AnpassungComponent,
        // NachweisComponent,
        // HouseholdComponent,
        // MotorVehicleComponent,
        // LifeComponent,
        // KMUComponent,
        // FinanceComponent,
        // PolicyHolderComponent,
        // VehicleDataComponent,
        // TestComponent,
        // CreditCardComponent,
        // MortgageComponent,
        // PersonalLoanComponent,
        // SmeLoanComponent,
        // NeoOfferLibraryModule
        GmCreatedOffersComponent,
        // NeoOfferLibraryModule        
    ]
})
export class OffersModule {
}
