export const LEADS_CONFIGS = {
    SALUTATION: [
        {
            key: "herr",
            value: "Herr"
        },
        {
            key: "frau",
            value: "Frau"
        }
    ],
    LANGUAGE: [
        {
            key: "de",
            value: "deutsch"
        },
        {
            key: "it",
            value: "italienisch"
        },
        {
            key: "al",
            value: "albanisch"
        },
        {
            key: "fr",
            value: "französisch"
        },
        {
            key: "en",
            value: "englisch"
        },
        {
            key: "se",
            value: "serbisch"
        },
        {
            key: "po",
            value: "portugisisch"
        },
        {
            key: "sp",
            value: "spanisch"
        },
        {
            key: "tr",
            value: "Türkisch"
        },
        {
            key: "gr",
            value: "Griechisch"
        }
    ],

    typeId: {
        LEADS: {
            CALLBACK: "4102",
            TERMIN: "4103",
            NOTE: "4104"
        },
        TERMIN: {
            TERMIN: "4203",
            CALLBACK: "4204",
            NOTE: "4205"
        }
   
    }

}