import {NgModule, NO_ERRORS_SCHEMA} from "@angular/core";
// import {SatDatepickerModule, SatNativeDateModule} from 'saturn-datepicker';
import {CommonModule} from "@angular/common";
import {TestPageComponent} from "./pages/test-page.component";
import {CoreModule} from "../core/core.module";

@NgModule({
    imports: [
        CommonModule,
        CoreModule
        // SatDatepickerModule,
        // SatNativeDateModule
    ],
    declarations: [TestPageComponent],
    exports: [TestPageComponent],
    schemas:
        [NO_ERRORS_SCHEMA]
})
export class TestModule {
}
