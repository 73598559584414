import { CalendarDateFormatter, DateFormatterParams } from 'angular-calendar';
import { getISOWeek } from 'date-fns';
import { DatePipe, formatDate } from '@angular/common';
import { Injectable } from "@angular/core";

@Injectable()
export class CustomDateFormatter extends CalendarDateFormatter {
  // TODO: add explicit constructor

    public dayViewHour({ date, locale }: DateFormatterParams): string {
        return formatDate(date, 'HH:mm', locale);
      }
    
      public weekViewHour({ date, locale }: DateFormatterParams): string {
        return this.dayViewHour({ date, locale });
      }
}
