import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoComponent } from './components/info.component';

import { QRCodeModule } from "angularx-qrcode";
import { TranslateModule } from '@ngx-translate/core';
import { AngularMaterialModule } from '../core/angularmaterial.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfoLeadLogComponent } from './components/info-visitcards-log/info-visitcards-log.component';
import { CoreModule } from '../core/core.module';
@NgModule({
  imports: [
    CommonModule,
    QRCodeModule,
    TranslateModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
  ],
  declarations: [InfoComponent, InfoLeadLogComponent],
})
export class InfoModule { }
