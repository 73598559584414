import {Component} from "@angular/core";
import {FilterTableComponent} from "../../core/components/tables/filter-table-component";
import {FormControl} from "@angular/forms";
import {JournalList} from "../../core/model/journal-list";
import {DocumentProvider} from "../../core/providers/document.provider";
import {Helper} from "../../core/static/helper";
import {JournalListJson} from "../../core/json/journal-list.json";
import {JournalHelper} from "../../core/static/journal-helper";
import { SlideRangeFilterValue } from "app/modules/core/model/slide-range-filter-value";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { UserCommitState } from "app/modules/core/model/user-commit-state";

@Component({
    selector: "neomp-filled-offer-documents-table-old",
    templateUrl: "./filled-offer-documents-table-old.component.html",
    styleUrls: ["./filled-offer-documents-table-old.component.scss"]
})
export class FilledOfferDocumentsTableOldComponent extends FilterTableComponent<JournalList> {

    reasonForChangeFilter = new FormControl("");
    documentTitleFilter = new FormControl("");
    effectiveDateFilter = new FormControl("");

    formControlFilters: FormControl[] = [
        this.reasonForChangeFilter,
        this.documentTitleFilter,
        this.effectiveDateFilter,
        null
    ];
    displayedColumns: string[] = [
        "reasonForChange",
        "documentTitle",
        "effectiveDate",
        "filter"
    ];
    displayedColumnFilters: string[] = [
        "reasonForChangeFilter",
        "documentTitleFilter",
        "effectiveDateFilter",
        "filterRemove"
    ];
    filterValues: JournalList = new JournalList();

    constructor(
        public documentProvider: DocumentProvider,
        public matSnackBar: MatSnackBar,
        private translateService: TranslateService) {
        super();
    }

    effectiveDateFilterChanged(event: SlideRangeFilterValue): void {
        this.effectiveDateFilter.setValue(event.dateRange);
    }

    createFilter() {
        return function (data, filter): boolean {
            const filterJson: JournalListJson = JSON.parse(filter);
            const searchTerms: JournalList = JournalList.fromJson(filterJson);
            return (Helper.checkStringContains(data.reasonForChange, searchTerms.reasonForChange) &&
                Helper.checkStringContains(data.documentTitle, searchTerms.documentTitle) &&
                Helper.checkDateRange(new Date(data.effectiveDate), Helper.stringToDatePickerFormat(searchTerms.effectiveDate)));
        };
    }

    resetFilter() {
        this.filterValues = new JournalList();
        super.resetFilter();
    }

    formatDate(date) {
        return Helper.formatDate(date);
    }

    existsDocument(journalRow: JournalList): boolean {
        const reference = JournalHelper.getSingleReferenceFromJournal(journalRow, "050");
        return reference !== undefined && reference !== null;
    }

    downloadDocument(event: Event, journalRow: JournalList) {
        event.stopPropagation();
        const id = JournalHelper.getSingleReferenceFromJournal(journalRow, "050").id;
        if(id){
            this.documentProvider.downloadDocument(id);
        }
        else{
            const translatedMessage = this.translateService.instant('PAGE.CONTAINER.MESSAGES.ERROR-DOCUMENT');
            this.matSnackBar.open(translatedMessage, 'X', UserCommitState.configCheckData);
        }
    }
}
