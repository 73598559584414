<div class="mdc-layout-grid msg-helper-padding-none">
    <div class="mdc-layout-grid__inner msg-helper-grid-gap-horizontal">
        <div
            class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone neomp-pb-10">
            <mat-card>
                <div class="search-fields-layout-grid" (keyup.enter)="searchFilter()">
                    <mat-form-field class="neomp-full-width">
                        <mat-label>{{ 'PAGE.LEADS.LEAD.TEXT' | translate }}</mat-label>
                        <input matInput [(ngModel)]="searchText" />
                    </mat-form-field>
                    <mat-form-field class="neomp-full-width">
                        <mat-label>{{ 'PAGE.LEADS.LEAD.FROM' | translate }}</mat-label>
                        <input [matDatepicker]="From" matInput [(ngModel)]="dateFrom" />
                        <mat-datepicker-toggle matSuffix [for]="From"></mat-datepicker-toggle>
                        <mat-datepicker #From></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field class="neomp-full-width">
                        <mat-label>{{ 'PAGE.LEADS.LEAD.TO' | translate }}</mat-label>
                        <input [matDatepicker]="To" matInput [(ngModel)]="dateTo" />
                        <mat-datepicker-toggle matSuffix [for]="To"></mat-datepicker-toggle>
                        <mat-datepicker #To></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field class="neomp-full-width">
                        <mat-label>{{ 'PAGE.CANCELLATION_MANAGEMENT.TABLE.CANCELLATION_STATUS' | translate }}</mat-label>
                        <mat-select [formControl]="cancellationStatusSelected" multiple>
                            <mat-option *ngFor="let status of cancellationStatus" [value]="status"
                                >{{status}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="neomp-full-width">
                        <mat-label>{{ 'PAGE.CANCELLATION_MANAGEMENT.TABLE.SUBJECT' | translate }}</mat-label>
                        <mat-select [formControl]="betreffSelected" multiple>
                            <mat-option *ngFor="let bet of betreff" [value]="bet"
                                >{{bet}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="neomp-full-width checkbox-container">
                        <mat-checkbox [(ngModel)]="cancellationApproval" class="checkbox-container" (change)="searchFilter()">
                            {{ 'PAGE.CANCELLATION_MANAGEMENT.TABLE.RELEASE' | translate }}
                        </mat-checkbox>
                    </div>
                </div>
                <div class="search-fields-layout-grid neo-padding-top-10 margin-top-15">
                    <button mat-raised-button class="neomp-full-width button-text" color="primary"
                        (click)="searchFilter()">
                        <span class="material-icons">
                            search
                        </span>{{ 'PAGE.LEADS.TERMIN.SEARCH' | translate }}
                    </button>
                    <button mat-raised-button class="clear-button neomp-full-width button-text" (click)="clearSearch()">
                        <span class="material-icons">clear</span>{{ 'PAGE.LEADS.LEAD.CLEAR' | translate }}
                    </button>
                </div>
            </mat-card>
        </div>
        <br />
        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop
                            mdc-layout-grid__cell--span-12-tablet
                            mdc-layout-grid__cell--span-12-phone">
            <neomp-container cssClass="msg-mp-container-frameless"
                headlineText="{{ 'PAGE.CONTAINER.APPLICATIONS.CONTAINER.HEADLINE' | translate }}">
                <neomp-cancellation-management-table [tableEntries]="policies"></neomp-cancellation-management-table>
                <neomp-loading-data #cancellationTables></neomp-loading-data>
            </neomp-container>
            <neomp-paginator *ngIf="policies.length>0" [length]="totalCount" [pageSize]="pageSize" [pageIndex]="pageIndex"
                [pageSizeOptions]="pageSizeOptions" (paginatorChange)="paginatorValueChanged($event)">
            </neomp-paginator>
        </div>
    </div>
</div>