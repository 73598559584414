<div class="neo-content-wrapper">
    <div class="mdc-layout-grid msg-helper-padding-none">
        <div class="mdc-layout-grid__inner">
            <div class="mdc-layout-grid__cell
                        mdc-layout-grid__cell--span-6-desktop
                         mdc-layout-grid__cell--span-6-tablet
                         mdc-layout-grid__cell--span-12-phone">

                <div class="mdc-layout-grid__inner">
                    <div class="mdc-layout-grid__cell
                        mdc-layout-grid__cell--span-12-desktop
                         mdc-layout-grid__cell--span-12-tablet
                         mdc-layout-grid__cell--span-12-phone
                         msg-helper-padding-10">

                        <!-- customer block -->
                        <neomp-container headlineText="{{ 'PAGE.CONTAINER.OFFERS.CONTAINER.CUSTOMER' | translate }}">
                            <div class="msg-mp-headerstyle" header>
                                {{ 'PAGE.OFFER.NEWCUSTOMER' | translate }}
                                <mat-slide-toggle (change)="createNewCustomer(checked)" [(ngModel)]="checked">
                                </mat-slide-toggle>
                            </div>
                            <div class="mdc-layout-grid msg-helper-padding-none">
                                <div class="mdc-layout-grid__inner">
                                    <div *ngIf="!checked" class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop
                                        mdc-layout-grid__cell--span-6-tablet
                                        mdc-layout-grid__cell--span-12-phone">
                                        <form class="household-form">
                                            <mat-form-field>
                                                <input class="font-family" [formControl]="houseHoldControl"
                                                    [matAutocomplete]="auto" matInput
                                                    placeholder="{{'PAGE.OFFER.HOUSEHOLD' | translate}}" type="text"
                                                    [required]="!checked">
                                                <mat-autocomplete #auto="matAutocomplete">
                                                    <mat-option (onSelectionChange)="selectHouseHold(household)"
                                                        *ngFor="let household of households | async"
                                                        [value]="household.householdName">
                                                        {{household.householdName}}
                                                    </mat-option>
                                                </mat-autocomplete>
                                                <mat-error class="font-family"
                                                    *ngIf="houseHoldControl.hasError('required')">
                                                    {{ 'PAGE.CUSTOMERDETAIL.CONTACT.REQUIREDERROR' | translate }}
                                                </mat-error>
                                            </mat-form-field>
                                        </form>
                                    </div>
                                    <div *ngIf="!checked" class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop
                                        mdc-layout-grid__cell--span-6-tablet
                                        mdc-layout-grid__cell--span-12-phone">
                                        <mat-form-field>
                                            <mat-label class="font-family">{{'PAGE.OFFER.CUSTOMER' | translate}}
                                            </mat-label>
                                            <mat-select (selectionChange)="updateURL(selectedPerson)"
                                                [(value)]="selectedPerson" [formControl]="personSelectControl"
                                                [required]="selectedPerson">
                                                <mat-option *ngFor="let person of personsData" [value]="person">
                                                    {{person.fullName}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error class="font-family"
                                                *ngIf="personSelectControl.hasError('required')">
                                                {{ 'PAGE.CUSTOMERDETAIL.CONTACT.REQUIREDERROR' | translate }}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop
                                        mdc-layout-grid__cell--span-12-tablet
                                        mdc-layout-grid__cell--span-12-phone">
                                        <h4 class="font-family">Person</h4>
                                        <div class="mdc-layout-grid__cell">
                                            <neomp-contact-data [person]="selectedPerson" [readonly]="!checked"
                                                [showCompleteForm]="true"></neomp-contact-data>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </neomp-container>

                        <!-- tools -->
                        <div class="mdc-layout-grid__cell
                                mdc-layout-grid__cell--span-12-desktop
                                mdc-layout-grid__cell--span-12-tablet
                                mdc-layout-grid__cell--span-12-phone font-family">
                            <neomp-container isLightContainer="true"
                                headlineText="{{ 'PAGE.CONTAINER.OFFERS.CONTAINER.TOOLS' | translate }}">
                                <div class="mdc-layout-grid__inner  msg-helper-justify-items-center" style="padding-bottom: 35px;">
                                    <div class="mdc-layout-grid__cell
                                    mdc-layout-grid__cell--span-3-desktop
                                     mdc-layout-grid__cell--span-12-tablet
                                     mdc-layout-grid__cell--span-12-phone">
                                        <square-icon
                                            icon="assets/icons/tools_vgr_icon.png"
                                            headline="{{ 'PAGE.CONTAINER.OFFERS.SQUAREICONS.HEADLINE.VGR' | translate }}"
                                            linkText="{{ 'PAGE.CONTAINER.OFFERS.SQUAREICONS.LINKTEXT.VGR' | translate }}"
                                            link="https://en.neo.swiss/krankenkassenvergleich" [centerHeader]="true" [showLink]="false" [elementAsLink]="true"
                                            (linkEmitter)='onEmitSquareIconLinkVGR(selectedPerson?.birthdate, (selectedPerson?.gender == "männlich"))'>
                                        </square-icon>
                                    </div>
                                    <div class="mdc-layout-grid__cell
                                        mdc-layout-grid__cell--span-3-desktop
                                         mdc-layout-grid__cell--span-12-tablet
                                         mdc-layout-grid__cell--span-12-phone">
                                        <square-icon icon="assets/icons/tools_bmi_icon.png"
                                            headline="{{ 'PAGE.CONTAINER.OFFERS.SQUAREICONS.HEADLINE.BMI' | translate }}"
                                            linkText="{{ 'PAGE.CONTAINER.OFFERS.SQUAREICONS.LINKTEXT.BMI' | translate }}"
                                            link="https://en.neo.swiss/bmirechner" [centerHeader]="true" [showLink]="false"
                                            [elementAsLink]="true">
                                        </square-icon>
                                    </div>
                                    <div class="mdc-layout-grid__cell
                                            mdc-layout-grid__cell--span-3-desktop
                                             mdc-layout-grid__cell--span-12-tablet
                                             mdc-layout-grid__cell--span-12-phone">
                                        <square-icon
                                            icon="assets/icons/tools_vsr_icon.png"
                                            headline="{{ 'PAGE.CONTAINER.OFFERS.SQUAREICONS.HEADLINE.VSR' | translate }}"
                                            linkText="{{ 'PAGE.CONTAINER.OFFERS.SQUAREICONS.LINKTEXT.VSR' | translate }}"
                                            link="https://neo.swiss/vorsorgerechner"
                                            [centerHeader]="true"
                                            [showLink]="false"
                                            [elementAsLink]="true">
                                        </square-icon>
                                    </div>
                                </div>
                            </neomp-container>
                        </div>
                    </div>
                </div>
            </div>

            <!-- container on the right-->
            <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop
                        mdc-layout-grid__cell--span-6-tablet
                        mdc-layout-grid__cell--span-12-phone">
                <div class="mdc-layout-grid__inner">
                    <div class="mdc-layout-grid__cell
                                mdc-layout-grid__cell--span-12-desktop
                                mdc-layout-grid__cell--span-12-tablet
                                mdc-layout-grid__cell--span-12-phone" style="margin-top: 10px;">
                        <neomp-container cssClass="msg-mp-container-frameless"
                            headlineText="{{ 'PAGE.CONTAINER.OFFERS.CONTAINER.FORM' | translate }}">

                            <div class="neo-padding-15">
                                <div class="mdc-layout-grid__inner">
                                    <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop
                                         mdc-layout-grid__cell--span-12-tablet
                                         mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--align-middle">
                                        <h4 class="font-family">
                                            {{ 'PAGE.CONTAINER.OFFERS.CONTAINER.BRANCH' | translate }}
                                        </h4>
                                        <mat-form-field>
                                            <mat-label class="font-family">
                                                {{ 'PAGE.CONTAINER.OFFERS.CONTAINER.BRANCH' | translate }}</mat-label>
                                            <mat-select [formControl]="branchesControl"
                                                (selectionChange)="selectionChangedBranch($event)">
                                                <mat-option *ngFor="let b of branches | keyvalue" [value]="b.key">
                                                    {{b.key}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error class="font-family" *ngIf="branchesControl.hasError('required')">
                                                {{ 'PAGE.CUSTOMERDETAIL.CONTACT.REQUIREDERROR' | translate }}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop
                                         mdc-layout-grid__cell--span-12-tablet
                                         mdc-layout-grid__cell--span-12-phone
                                         mdc-layout-grid__cell--align-middle neo-text-center">
                                        <button class="neomp-button-bar-buttons" (click)="downloadDocument()" color="primary"
                                            mat-raised-button>{{ 'PAGE.CUSTOMERDETAIL.ACTIONS.DOWNLOADDOC' | translate }}</button>
                                    </div>
                                </div>
                            </div>
                            <neomp-filled-offer-documents-table-old class="datePickerHide" [tableEntries]="filledDocuments">
                            </neomp-filled-offer-documents-table-old>
                            <neomp-loading-data #loadingDataComponent></neomp-loading-data>
                            <neomp-paginator
                            [length]="totalCount" 
                            [pageSize]="pageSize" 
                            [pageIndex]="pageIndex"
                            [pageSizeOptions]="pageSizeOptions" 
                            (paginatorChange)="paginatorValueChanged($event)"
                            >  
                            </neomp-paginator>
                        </neomp-container>
                    </div>

                    <div class="mdc-layout-grid__cell
                                mdc-layout-grid__cell--span-12-desktop
                                mdc-layout-grid__cell--span-12-tablet
                                mdc-layout-grid__cell--span-12-phone
                                        msg-helper-grid-gap-none msg-helper-margin-none
                                        msg-helper-padding-10">
                        <neomp-container cssClass="msg-mp-container-frameless"
                            headlineText="{{ 'PAGE.OFFER.TITLE' | translate }}">

                            <div class="mdc-layout-grid__cell
                                        mdc-layout-grid__cell--span-12-desktop
                                        mdc-layout-grid__cell--span-12-tablet
                                        mdc-layout-grid__cell--span-12-phone">
                                <neomp-upload-file [isNested]="true" [showHeadline]="false"
                                    (fileReadEmitter)="createOffer($event)">
                                </neomp-upload-file>
                            </div>
                            <div class="mdc-layout-grid__inner neo-padding-15">
                                <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop
                            mdc-layout-grid__cell--span-12-tablet
                            mdc-layout-grid__cell--span-12-phone">
                                    <h4 class="font-family">
                                        {{ 'PAGE.CONTAINER.OFFERS.CONTAINER.BRANCH' | translate }}</h4>
                                    <mat-form-field>
                                        <mat-label class="font-family">
                                            {{ 'PAGE.CONTAINER.OFFERS.CONTAINER.BRANCH' | translate }}
                                        </mat-label>
                                        <mat-select [formControl]="branchesControl"
                                            (selectionChange)="selectionChangedBranch($event)">
                                            <mat-option *ngFor="let b of branches | keyvalue" [value]="b.key">
                                                {{b.key}}</mat-option>
                                        </mat-select>
                                        <mat-error class="font-family"
                                            *ngIf="branchesControl.hasError('required')">
                                            {{ 'PAGE.CUSTOMERDETAIL.CONTACT.REQUIREDERROR' | translate }}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop
                            mdc-layout-grid__cell--span-12-tablet
                            mdc-layout-grid__cell--span-12-phone">
                                    <h4 class="font-family">
                                        {{ 'PAGE.CONTAINER.OFFERS.CONTAINER.ORGANISATION' | translate }}</h4>
                                    <mat-form-field>
                                        <mat-label class="font-family">
                                            {{ 'PAGE.CONTAINER.OFFERS.CONTAINER.ORGANISATION' | translate }}
                                        </mat-label>
                                        <mat-select [formControl]="companiesControl" multiple
                                            (selectionChange)="selectionChangedCompany($event)">
                                            <mat-option *ngFor="let company of companies" [value]="company">
                                                {{company}}</mat-option>
                                        </mat-select>
                                        <mat-error class="font-family"
                                            *ngIf="companiesControl.hasError('required')">
                                            {{ 'PAGE.CUSTOMERDETAIL.CONTACT.REQUIREDERROR' | translate }}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="neomp-button-bar">
                                <button class="neomp-button-bar-buttons" color="primary"
                                    [disabled]="loading || (!loading && uploadFile?.files?.length <= 0)"
                                    (click)="startReadFile()" mat-raised-button>{{ 'PAGE.OFFER.ACTION' | translate }}
                                </button>
                            </div>
                        </neomp-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>