import {SDAKeyJson} from "../json/sdakey.json";

export class SDAKey {
    key: string;
    value: string;
    sorValue: string;

    public static fromJsons(response: SDAKeyJson[]): SDAKey[] | undefined {
        if (response) {
            return response.map(key => new SDAKey(key));
        }
    }

    public constructor(value?: SDAKeyJson) {
        if (value) {
            this.key = value.key;
            this.value = value.sdaValue;
            this.sorValue = value.sorValue;
        }
    }

    public static fromKeyValue(key: string, value: string, sorValue: string): SDAKey {
        const sdaKey = new SDAKey();
        sdaKey.key = key;
        sdaKey.value = value;
        sdaKey.sorValue = sorValue;
        return sdaKey;
    }
}
