
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import { CalendarDateFormatter, CalendarEvent, CalendarMonthViewDay, CalendarView, CalendarMonthViewBeforeRenderEvent } from 'angular-calendar';
import { CustomDateFormatter } from './costum-date-formatter.provider';
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { DateAdapter, MatOption } from "@angular/material/core";
import { Person } from 'app/modules/core/model/person';
import { addHours, format, isSameDay, isSameMonth } from 'date-fns';
import { EventColor } from 'calendar-utils';
import { NavigationExtras, Router } from '@angular/router';
import moment from 'moment';
import { KeycloakService } from 'keycloak-angular';
import { AgenturType } from 'app/modules/core/model/enum-agenturtype';
import { AppointmentProvider } from 'app/modules/core/providers/appointment.provider';
import { Observable, Subscription, forkJoin, of } from 'rxjs';
import { Journal } from 'app/modules/core/model/journal';
import { LEADS_CONFIGS } from '../../configs/lead-configs';
import { LeadsService } from '../../services/leads.service';
import { JournalList } from 'app/modules/core/model/journal-list';
import { LoadingService } from 'app/modules/core/providers/loading.component';
import { CalendarOptions, DateSelectArg, EventClickArg, EventApi, LocaleInput, EventInput } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { FullCalendarComponent } from '@fullcalendar/angular';
import itLocale from '@fullcalendar/core/locales/it';
import frLocale from '@fullcalendar/core/locales/fr';
import deLocale from '@fullcalendar/core/locales/de';
import enLocale from '@fullcalendar/core/locales/en-gb';
import { MatSelect } from '@angular/material/select';
import { SelectionModel } from '@angular/cdk/collections';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LoadingDataComponent } from 'app/modules/core/components/loading-data.component';
import { PaginationConfigs } from 'app/modules/constants/PaginationConstants';
import { PageEvent } from '@angular/material/paginator';
import { BusinessTransactionHelper } from 'app/modules/core/static/bt-helper';
import { LeadLog } from 'app/modules/core/model/LeadLog.enum';
import { catchError } from 'rxjs/operators';
import { UserCommitState } from 'app/modules/core/model/user-commit-state';
import { MatSnackBar } from '@angular/material/snack-bar';
import { log } from 'app/modules/core/providers/logger.provider';
import { RestProviderActions } from 'app/modules/core/providers/rest.provider';
import tippy from 'tippy.js';
import { AppointmentDialogComponent } from 'app/modules/core/components/appointment-dialog/appointment-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'neomp-termin-calendar-component',
  templateUrl: './termin-calendar.component.html',
  styleUrls: ['./termin-calendar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter
    }
  ]
})
export class TerminCalendarComponent implements OnInit, OnChanges {
  @ViewChild("calendar", { static: false }) calendarComponent: FullCalendarComponent;
  @ViewChild("loadingFamilyTable") loadingFamilyTable: LoadingDataComponent;
  @ViewChild('allOption') allOption: MatOption;
  @ViewChild('select') select: MatSelect;
  pageSize = PaginationConfigs.pageSize;
  pageSizeOptions = PaginationConfigs.pageSizeOptions;
  pageIndex = PaginationConfigs.pageIndex;

  agentAssignSelectedControl = new FormControl({ value: '', disabled: true }, Validators.required);
  terminForm: FormGroup = new FormGroup({
    calendar_lang: new FormControl(),
    termin_date: new FormControl("",Validators.required),
    termin_time: new FormControl("",Validators.required),
    termin_agent: new FormControl("",Validators.required)
  });

  private readonly TERMIN_LEADS_REMINDERS = LEADS_CONFIGS.typeId.LEADS.CALLBACK;
  private readonly TERMIN_REMINDERS = LEADS_CONFIGS.typeId.TERMIN.CALLBACK;
  leadConfigs = LEADS_CONFIGS;
  subscriptions: Subscription[] = [];

  calendarColors = ['#fabc2a', '#ffcab1', '#f38d68', '#ee6c4d', '#f76f8e', '#f2bac9', '#7fd8be', '#408AB0', '#6E9988', '#519872'];
  @Input() agents: Person[] = [];
  @Input() appointments: Person[] = [];
  @Input() unassignedAppointmentsCalendar: Person[] = [];

  terminLeadsRemindersCalendar : Journal[] = [];
  selectedAgentsEvents: EventInput[] = [];
  agentData: Agent[] = [];
  agentReminders: Agent[] = [];
  currentEvents: EventApi[] = [];
  agentAppointments: Agent[] = [];
  tableAppointments: Person[] = [];
  selectedLeads: Person[] = [];
  agentAppointmentsUnassigned: Agent[];
  selectedAgents: any[] = [];
  lead: Person | null = null;
  agentToAssign: Person[] = [];
  
  viewDate: Date = new Date();
  allOptionSelected: boolean = false;
  activeDayIsOpen: boolean = false;
  calendarVisible: boolean = true;
  managementView: boolean = true;
  calendarView: boolean = true;
  editFamilyMemberMode = false;
  isSupplier: boolean = false;
  isEventDrag: boolean = false;
  isHighRole: boolean = true;
  isMounted: boolean = false;
  allAgentsSelected = false;
  reminderChecked = false;
  editTerminMode = false;
  view: string = 'month';
  neoAgentColor: string = '#ECECEC';
  locale: string;
  advisorId: string = "";
  agenturType: string = "";
  agenturName: string = "";
  termineId: string = "";
  advisorName = "";
  selectedAgent: any = null;
  defaultSelected: any;
  totalCountAppointments: number = 0;
  calendarColorIndex = 0;
  fromDate: string;
  toDate: string;
  calendarLocale: LocaleInput = null;
  calendarOptions: CalendarOptions = {
    locale: this.calendarLocale,
    plugins: [
      interactionPlugin,
      dayGridPlugin,
      timeGridPlugin,
      listPlugin,
    ],
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
    },
    initialView: 'timeGridWeek',
    initialEvents: [],
    weekends: true,
    editable: true,
    selectable: false,
    selectMirror: true,
    dayMaxEvents: true,
    moreLinkClick: "popover",
    scrollTime: '08:00',
    eventMinHeight: 55,
    displayEventEnd: false,
    allDaySlot: false,
    eventDisplay: 'block',
    height: 800,
    defaultTimedEventDuration: "00:00",
    eventTimeFormat: {
      hour: 'numeric',
      minute: '2-digit',
      omitZeroMinute: false
    } as NativeFormatterOptions,
    eventClick: this.handleEventClick.bind(this),
    datesSet: this.handleEventsDateChange.bind(this),
    eventDidMount: this.handleEventTooltip.bind(this),
    eventDrop: this.handleEventChange.bind(this),
  };

  constructor(
    public appointmentProvider: AppointmentProvider,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    public translate: TranslateService, 
    public dateAdapter: DateAdapter<any>,
    private keycloak: KeycloakService,
    private leadService: LeadsService,
    public loadingService: LoadingService,
    public snackBar: MatSnackBar,
    private dialog: MatDialog
    ) {
      this.locale = this.translate.currentLang;
      this.updateCalendarLanguage(this.locale);
    }

  async ngOnInit(): Promise<void> {
  await this.keycloak.loadUserProfile().then((res: any) => { 
    this.advisorName = res.firstName;
    this.advisorId = res.attributes.advisorid[0];
    this.agenturType = res.attributes.agenturType[0];
    this.agenturName = res.attributes.agenturName[0];
    this.isHighRole = (this.agenturType === AgenturType.SalesManager || this.agenturType === AgenturType.GeneralAgent || this.agenturType === AgenturType.TeamLead);
    this.isSupplier = this.agenturType === AgenturType.Supplier;
  });
   
    this.translate.onLangChange.subscribe(async (event: LangChangeEvent) => {
      this.locale = event.lang;
      await this.updateCalendarLanguage(this.locale);
      this.changeDetector.detectChanges();
    });

    this.getDateView()
    this.cancel({editMode: false, editSection: "Appointment"});
  }

  handleEventsDateChange(event: EventApi | any): void {
    if (this.isHighRole) {
      if (this.calendarComponent) {
        this.searchByDate()
      } else {
        setTimeout(() => {
          this.searchByDate()
        }, 500);
      }
    }
  }

  handleEventTooltip(info: any) {
    const tooltipContent = () => {
      const tooltipContainer = document.createElement('div');
      tooltipContainer.classList.add('mat-tooltip-style');

      const content = document.createElement('span');
      let tooltipText = '';
      if (info.backgroundColor !== this.neoAgentColor) {
        if (info.event.extendedProps.agentName) {
          tooltipText += `${info.event.extendedProps.agentName},<br>`;
        }
      }
      if (info.event.extendedProps.fullName) {
        tooltipText += `${info.event.extendedProps.fullName},<br>`;
      }
      if (info.event.extendedProps.postalCode) {
        tooltipText += `${info.event.extendedProps.postalCode},<br>`;
      }
      if (info.event.extendedProps.city) {
        tooltipText += `${info.event.extendedProps.city}<br>`;
      }
    
      content.innerHTML = tooltipText;
      tooltipContainer.appendChild(content);
    
      return tooltipContainer;
    };

    tippy(info.el, {
      content: tooltipContent,
      allowHTML: true,
      placement: 'top-start',
      interactive: true,
      arrow: false,
      animation: 'shift-away-subtle',
      duration: [200, 0],
      onCreate: (instance) => {
        instance.popper.classList.add('mat-tooltip-popper');
      }
    });
  }

  handleEventChange(info: any) {
    this.isEventDrag = true;
    const event = info.event as EventApi;
    this.termineId = info.event.id;
    const startDate = event.start?.toISOString().split('T')[0];
    const startTime = event.start?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
    this.getAppointmentById(this.termineId, () => {
      this.addTermine(() => { }, startDate, startTime);
    })
  }

  async updateCalendarLanguage(lang: string) {
    if (this.calendarComponent) {
      const localeMap = {
        "en": enLocale,
        "de": deLocale,
        "fr": frLocale,
        "it": itLocale
      };
    
      if (localeMap.hasOwnProperty(lang)) {
        const selectedLocale = localeMap[lang];
        this.calendarLocale = selectedLocale;
        this.calendarComponent["calendar"].setOption('locale', selectedLocale);
      }
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.isMounted = true;
      // window.dispatchEvent(new Event('resize'));
    }, 150);
    setTimeout(() => {
      this.updateCalendarLanguage(this.locale);
      // The 'searchDate' function call is removed here as it is invoked within 'handleEventsDateChange'
      // this.searchByDate();
    }, 500);
  }

  searchByDate() {
    let calendarApi;
    if (this.calendarComponent){
      calendarApi = this.calendarComponent.getApi();
    } else {
      setTimeout(() => {
      calendarApi = this.calendarComponent.getApi();
      }, 500);
    }
    let startDate = calendarApi.view.currentStart;
    let endDate = calendarApi.view.currentEnd;
    let formattedStartDate = new Date(startDate).toISOString().split('T')[0];
    let formattedEndDate = new Date(endDate).toISOString().split('T')[0];
    this.fromDate = format(new Date(formattedStartDate.toString()), "YYYY-MM-DDT22:59:00.000")
    this.toDate = format(new Date(formattedEndDate.toString()), "YYYY-MM-DDT22:59:00.000");

    let filter = '';
    filter = `&dateFrom=${this.fromDate}&dateTo=${this.toDate}`;
    this.getLazyAppointments(
      this.loadingFamilyTable,
      this.pageIndex,
      this.pageSize,
      false,
      filter
    );
  }

  addSelectedAgentsEventsToCalendar(): void {
    let calendarApi;
    if (this.calendarComponent){
      calendarApi = this.calendarComponent.getApi();
      calendarApi.removeAllEvents();
    } else {
      setTimeout(() => {
      calendarApi = this.calendarComponent.getApi();
      calendarApi.removeAllEvents();
      }, 500);
    }
    if (calendarApi && this.selectedAgentsEvents.length > 0) {
      calendarApi.addEventSource(this.selectedAgentsEvents);
    }
    this.calendarComponent.getApi().render();
  }
 
  loadAppointments(callback: () => void) {
    this.loadingService.display(true);
    this.appointmentProvider
      .getAppointmentsForCalendar()
      .subscribe((appointments: { data: Person[]; totalCount: number }) => {
        this.totalCountAppointments = appointments.totalCount;
        this.appointments = this.agents.length > 0 ?
          appointments.data.map(lead => this.assignAgentsToLeads(lead)) :
          appointments.data;
        if (this.agents.length > 0 && this.appointments.length > 0 || this.agents.length > 0 && this.unassignedAppointmentsCalendar.length > 0) {
          this.prepareAgentAppointments(this.agents, this.appointments, this.unassignedAppointmentsCalendar);
          if (this.reminderChecked) {
            this.agentData = this.agentReminders;
          } else {
            this.agentData = this.agentAppointments;
          }
          this.initialSelectAllOptions();
        }
        if (callback) {
          callback();
        } else {
          this.loadingService.display(false);
        }
      },
        error => {
          this.loadingService.display(false);
          if (callback) {
            callback();
          }
        }
      );
  }

  getLazyAppointments(
    restProviderActions: RestProviderActions,
    page: number,
    size: number,
    useCache?: boolean,
    filter?: string) {
    this.appointmentProvider
      .getLazyAppointments(restProviderActions, useCache, page, size, filter)
      .subscribe((leads: { data: Person[]; totalCount: number }) => {
        this.totalCountAppointments = leads.totalCount;
        if (this.agents.length > 0) {
          this.tableAppointments = leads.data.map(lead => this.assignAgentsToLeads(lead));
        }
        else {
          this.tableAppointments = leads.data;
        }
      });
  }

  getDateView() {
    const viewDate = moment().toDate();
    const year = moment(viewDate).format('YYYY');
    const month = moment(viewDate).format('MM');
    
    let from = `${year}-${month}-01`;
    let to = moment(viewDate).endOf('month').format('YYYY-MM-DD');
    
    this.getLeadRemindersForCalendar(from, to);
  }

  getLeadRemindersForCalendar(from: any, to: any): void {
      if (this.isSupplier){
        this.leadService.getJournalsRemindersForCalendarForSupplier(
          this.getDateFilterQuery(from, to)// + (this.advisorId ? this.getAgentFilterQuery(this.advisorId) : '')
        ).subscribe((res: { data: JournalList[], totalCount: number }) => {
          this.terminLeadsRemindersCalendar = res.data.map(x => x.transaction);
        })
      }
      else {
        this.leadService.getJournalsRemindersForCalendar(
          this.getDateFilterQuery(from, to)// + (this.advisorId ? this.getAgentFilterQuery(this.advisorId) : '')
        ).subscribe((res: { data: JournalList[], totalCount: number }) => {
          this.terminLeadsRemindersCalendar = res.data.map(x => x.transaction);
        })
      }
  }

  getDateFilterQuery(fromDate: any, toDate: any): string | null {
    const dateFrom = fromDate == undefined ? format(new Date(1900, 0, 0), "YYYY-MM-DD") : format(new Date(fromDate.toString()), "YYYY-MM-DDT00:00:00.000");
    const dateTo = toDate == undefined ? format(new Date(), "YYYY-MM-DD") : format(new Date(toDate.toString()), "YYYY-MM-DDT23:59:00.000")
    return `&filter={"type":"DateTime","key":"creationDate","from":"${dateFrom}","to":"${dateTo}"}`;
  }

  assignAgentsToLeads(lead: Person){
    const leadAgent = this.agents.find(x => x.uuid == lead.lead.termin_berater_shortcut);
    const agentName = leadAgent ? leadAgent.fullName : this.agenturName;
    if(agentName){
      lead.sorKeys["agent"] = agentName;
      return lead;
    }
    return null;
  }

  setValue(field, event) {
    this.agentToAssign = event.value;
    const selectedAgent = this.agents.find(agent => agent.uuid === event.value.uuid);
    this.terminForm.patchValue({
      termin_agent: selectedAgent ? selectedAgent : null
    });
  }
  
  getAgents(): Observable<Person[]>{
    return this.appointmentProvider
      .getAgents(false)
  }

  selectedRows(selectionModel: SelectionModel<Person>): void {
    this.selectedLeads = selectionModel.selected;
    this.selectedLeads.length === 0 ?
      this.agentAssignSelectedControl.disable() : this.agentAssignSelectedControl.enable();
  }

  appointmentId(uuid: string): void {
    this.getAppointmentById(uuid, () => {});
  }

  resetFunction(event) {
    if (event == 'true') {
      this.searchByDate();
      this.loadAppointments(() => {})
    }
  }

  paginatorValueChanged(event: PageEvent) {
    if (event.pageIndex !== this.pageIndex || event.pageSize !== this.pageSize) {
      this.pageIndex = event.pageIndex;
      this.pageSize = event.pageSize > 0 ? event.pageSize : PaginationConfigs.pageSize;
      this.searchByDate();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (this.agents.length > 0 && this.appointments.length > 0 || this.agents.length > 0 && this.unassignedAppointmentsCalendar.length > 0) {
        this.prepareAgentAppointments(this.agents, this.appointments, this.unassignedAppointmentsCalendar);
        if (this.agentAppointments.length > 0 && this.isHighRole || this.agentAppointmentsUnassigned.length > 0 && this.isHighRole) {
          if (this.reminderChecked) {
            this.agentData = this.agentReminders;
          } else {
            this.agentData = this.agentAppointments;
          }
          this.initialSelectAllOptions();
        } else if (this.agentAppointments.length > 0) {
          this.searchByReminderOrAppointment();
        }
        this.loadingService.display(false);
      }
      if (this.terminLeadsRemindersCalendar.length > 0 && this.agents.length > 0 && (this.appointments.length > 0 || this.unassignedAppointmentsCalendar.length > 0)) {
        this.prepareAgentReminders(this.agents, this.terminLeadsRemindersCalendar);
        this.searchByReminderOrAppointment();
        this.loadingService.display(false);
      }
    }
  }

  initialSelectAllOptions() {
    this.selectedAgents = this.agentData.map(agent => agent);
    this.defaultSelected = this.agentData.map(agent => agent);
    this.defaultSelected.push('selectAll');

    if (this.reminderChecked) {
      this.selectedAgentsEvents = [].concat(...this.agentReminders.map(x => x.events));
    } else {
      this.selectedAgentsEvents = [].concat(
        ...this.agentAppointments.map(x => x.events),
        ...this.agentAppointmentsUnassigned.map(x => x.events));
    }
    let calendarApi;
    if (this.calendarComponent) {
      calendarApi = this.calendarComponent.getApi();
      calendarApi.removeAllEvents();
    } else {
      setTimeout(() => {
        calendarApi = this.calendarComponent.getApi();
        calendarApi.removeAllEvents();
      }, 500);
    }
    if (calendarApi && this.selectedAgentsEvents.length > 0) {
      calendarApi.addEventSource(this.selectedAgentsEvents);
    }
    this.calendarComponent.getApi().render();
    this.calendarComponent["calendar"].setOption('eventDisplay', 'block');
  }

  assignLeadsToAgent(agentToAssign?: Person[], lead?: Person) {
    let requests: Observable<any>[] = [];
    const agent =  agentToAssign ? agentToAssign : this.agentAssignSelectedControl.value;
    if (this.selectedLeads.length > 0) {
      this.loadingService.display(true);
      for (let lead of this.selectedLeads) {

        const sorKeys = {
          'advisorId': agent.uuid,
          'leadId': lead.uuid
        }

        lead.lead.termin_editdate = moment().format('YYYY-MM-DD');
        lead.lead.termin_berater_shortcut = agent.uuid;

        const actionData = BusinessTransactionHelper.createLeadBTActionObject(
          LeadLog.TRANSFER,
          BusinessTransactionHelper.typeId.assignLeadToAgent.from,
          '',
          'Termine TRANSFER => ' + agent.fullName,
          '',
          agent.uuid,
          [],
          lead,
          moment().add(5, 'days').format('YYYY-MM-DD'),
          sorKeys,
          this.advisorName
        );
        requests.push(this.leadService.sendLeadAction(actionData)
          .pipe(
            // do not let your observables die; forkJoin will the not be triggered then.
            catchError(err => of(err))
          ));
      }
      forkJoin(requests).subscribe(results => {
        this.agentAssignSelectedControl.reset();
        this.selectedLeads = [];
        this.searchByDate();
        this.loadAppointments(() => {
        this.loadingService.display(false);
        this.searchByReminderOrAppointment();
        });
      })
    } else {
        this.loadingService.display(true);

          const sorKeys = {
            'advisorId': agent.uuid,
            'leadId': lead.uuid
          }
  
          lead.lead.termin_editdate = moment().format('YYYY-MM-DD');
          lead.lead.termin_berater_shortcut = agent.uuid;
  
          const actionData = BusinessTransactionHelper.createLeadBTActionObject(
            LeadLog.TRANSFER,
            BusinessTransactionHelper.typeId.assignLeadToAgent.from,
            '',
            'Termine TRANSFER => ' + agent.fullName,
            '',
            agent.uuid,
            [],
            lead,
            moment().add(5, 'days').format('YYYY-MM-DD'),
            sorKeys,
            this.advisorName
          );
          requests.push(this.leadService.sendLeadAction(actionData)
            .pipe(
              // do not let your observables die; forkJoin will the not be triggered then.
              catchError(err => of(err))
            ));
        
        forkJoin(requests).subscribe(results => {
          this.agentAssignSelectedControl.reset();
          this.selectedLeads = [];
          this.searchByDate();
          this.loadAppointments(() => {
            this.loadingService.display(false);
            this.searchByReminderOrAppointment();
            this.getAppointmentById(this.termineId, () => {})
          });
        })
      
    }
  }

  edit(editModeEvent): void {
    if (editModeEvent.editSection === 'Appointment' && !editModeEvent.editMode) {
      for (let control of Object.keys(this.terminForm.controls)) {
        this.terminForm.controls[control].markAsTouched();
      }
      if (!this.terminForm.valid) {
        console.error("Appointment Form not valid")
        let terminToggleEditMode = document.querySelectorAll('[id=toggleEditMode]');
        (terminToggleEditMode[1] as HTMLElement).click();
        this.terminForm.enable();
        return;
      }
    };

    editModeEvent.editSection === 'Appointment' ? this.editTerminMode = editModeEvent.editMode :
      this.editFamilyMemberMode = editModeEvent.editMode;

    if (editModeEvent.editMode) {
      if (editModeEvent.editSection === 'Appointment') {
        this.terminForm.get('termin_date').enable();
        this.terminForm.get('termin_time').enable();
        this.terminForm.get('termin_agent').enable();
      }
    } else {
      if (editModeEvent.editSection === 'Appointment') {
        this.addTermine(() => {
          if (this.terminForm.get('termin_agent').dirty && this.terminForm.get('termin_agent').touched) {
            this.assignLeadsToAgent(this.agentToAssign, this.lead);
          }
        });
        this.terminForm.disable();
      }
    }
  }

cancel(editModeEvent): void {
    editModeEvent.editSection === 'Appointment' ? this.editTerminMode = editModeEvent.editMode :
      this.editFamilyMemberMode = editModeEvent.editMode;
  if (editModeEvent.editSection === 'Appointment') {
    this.terminForm.disable();
    if (this.lead) {
      this._fillLeadDetails(this.lead);
    }
  }
}

  addTermine(
    callback: () => void,
    date?: string,
    time?: string
  ): void {
    const formValue: { termin_date: string, termin_time: string }
      = this.terminForm.value;

    const sorKeys = {
      agentName: this.advisorName,
      category: this.leadConfigs.typeId.TERMIN.TERMIN,
      time: this.lead.lead.termin_time || ""
    };
    let terminDate = "";
    let terminTime = "";

    if (date || time) {
      terminDate = date;
      terminTime = time;
      this.lead.lead.termin_time = terminTime;
      this.lead.lead.termin_date = terminDate;
      sorKeys.time = terminTime;
    } else {
      if (this.terminForm.dirty && this.terminForm.valid) {
        terminTime = formValue.termin_time;
        terminDate = moment(formValue.termin_date).format('YYYY-MM-DD');
        this.lead.lead.termin_time = terminTime;
        this.lead.lead.termin_date = terminDate;
        sorKeys.time = terminTime;
      }
    }

    this.terminForm.patchValue({
      calendar_lang: '',
      termin_date: this.lead.lead.termin_date,
      termin_time: this.lead.lead.termin_time,
      termin_agent: this.agents.find(agent => agent.fullName === this.lead.lead.advisor_name)
    })

    const actionData = BusinessTransactionHelper.createLeadBTActionObject(
      LeadLog.TERMIN,
      this.leadConfigs.typeId.TERMIN.TERMIN,
      '1. Termin',
      `${this.advisorName}, ${this.lead.lead.termin_date} ${this.lead.lead.termin_time}`,
      '',
      this.lead.uuid,
      [],
      this.lead,
      moment(this.lead.lead.termin_date).format('YYYY-MM-DD') || "",
      sorKeys,
      this.advisorName
    );

    this.subscriptions.push(
      this.leadService.sendLeadAction(actionData)
        .subscribe(() => {
          this.snackBar.open("Termine created successfully", "X", UserCommitState.configSuccess);
          this.searchByDate();
          if (!this.isEventDrag) {
            this.loadAppointments(() => {
              this.searchByReminderOrAppointment();
              if (callback) {
                callback();
              } else {
                this.loadingService.display(false);
              }
            });
          }
        }, err => {
          log.error(`Something went wrong while calling POST-> lead/action `, err);
          this.snackBar.open("Something went wrong!", "X", UserCommitState.configError)
        })
    );
  }

getAppointmentById(id: string, callback: () => void): void {
  this.termineId = id;
  const queryParams = {
    st: 'calendar',
    dateFrom: '',
    dateTo: '',
    text: '',
    pageIndex: '0',
    pageSize: '15'
  };
  const navigationExtras: NavigationExtras = {
    queryParams
  };
  this.subscriptions.push(
    this.appointmentProvider
      .getAppointmentById(id, null)
      .subscribe((lead: Person) => {
        this.lead = lead;
        this.lead.gender = typeof lead.gender === 'object' && lead.gender ? lead.gender['sdaValue'] : lead.gender;
        this._fillLeadDetails(lead);
        if (callback) {
          callback();
        }
      }, (err) => {
        this.router.navigate(['/leads'], navigationExtras)
        log.error(err);
      }));
}

  prepareAgentAppointments(agents: Person[], appointments: Person[], unassignedAppointments: Person[]): void {
    this.agentAppointments = [];
    this.agentAppointmentsUnassigned = [];
    if (!this.isHighRole) {
      const currentAgent = this.agents.find(x => x.uuid == this.advisorId);
      const color = this.getRandomColor();
      var agentEvents = [];
      if (this.isSupplier) {
        agentEvents = appointments;
      } else {
        agentEvents = appointments.filter(x => x.lead.termin_berater_shortcut && x.lead.termin_berater_shortcut === currentAgent.uuid);
      }

      const fullCalendarEvents = agentEvents.map((val, index) => {
        const date = moment(val.lead.termin_date, 'YYYY-MM-DD').format('YYYY-MM-DD')
        const time = moment(val.lead.termin_time, 'HH:mm:ss').format('HH:mm:ss')

        const choosenStartDate = new Date(`${date}T${time}`);
        const choosenEndDate = addHours(new Date(`${date}T${time}`), 0.1);
        let agentName = val.sorKeys.agent && val.sorKeys.agent.split("Neosana Sales AG")[1] ? val.sorKeys.agent.split("Neosana Sales AG")[1] : val.sorKeys.agent

        const title = val.lead.termin_berater_shortcut;

        return {
          id: val.uuid,
          title: title,
          extendedProps: {
            agentName: agentName,
            fullName: val.fullName,
            postalCode: val.address.postalCode,
            city: val.address.city
          },
          allDay: false,
          start: choosenStartDate,
          end: choosenEndDate,
          color: color,
          backgroundColor: color
        }
      });

      this.agentAppointments.push({
        uuid: currentAgent.uuid,
        fullName: currentAgent.fullName,
        checked: true,
        color: color,
        events: fullCalendarEvents
      })
      // this.setSelectedAgent();
    }
    else {
      agents.map(agent => {
        const color = this.getRandomColor();
        const agentEvents = appointments.filter(x => x.lead.termin_berater_shortcut && x.lead.termin_berater_shortcut === agent.uuid);

        const fullCalendarEvents = agentEvents.map((val, index) => {
          const date = moment(val.lead.termin_date, 'YYYY-MM-DD').format('YYYY-MM-DD')
          const time = moment(val.lead.termin_time, 'HH:mm:ss').format('HH:mm:ss')

          const choosenStartDate = new Date(`${date}T${time}`);
          const choosenEndDate = addHours(new Date(`${date}T${time}`), 0.1);
          let agentName = val.sorKeys.agent && val.sorKeys.agent.split("Neosana Sales AG")[1] ? val.sorKeys.agent.split("Neosana Sales AG")[1] : val.sorKeys.agent;
          const title = val.lead.termin_berater_shortcut;

          return {
            id: val.uuid,
            title: title,
            extendedProps: {
              agentName: agentName,
              fullName: val.fullName,
              postalCode: val.address.postalCode,
              city: val.address.city
            },
            allDay: false,
            start: choosenStartDate,
            end: choosenEndDate,
            backgroundColor: color,
            color: color
          }
        });

        this.agentAppointments.push({
          uuid: agent.uuid,
          fullName: agent.fullName,
          checked: false,
          color: color,
          events: fullCalendarEvents
        })
      })

      const currentAgent = this.agents.find(x => x.uuid == this.advisorId);
      const color = this.neoAgentColor;
      const bgColor = "#2d2d2dbf";
      var agentEventsUnassigned = [];
      agentEventsUnassigned = unassignedAppointments;

      const fullCalendarEventsUnassigned = agentEventsUnassigned.map((val, index) => {
        const date = moment(val.lead.termin_date, 'YYYY-MM-DD').format('YYYY-MM-DD')
        const time = moment(val.lead.termin_time, 'HH:mm:ss').format('HH:mm:ss')
        const choosenStartDate = new Date(`${date}T${time}`);
        const choosenEndDate = addHours(new Date(`${date}T${time}`), 0.1);
        let agentName = val.sorKeys.agent && val.sorKeys.agent.split("Neosana Sales AG")[1] ? val.sorKeys.agent.split("Neosana Sales AG")[1] : val.sorKeys.agent

        const title = val.lead.termin_berater_shortcut;

        return {
          id: val.uuid,
          title: title,
          extendedProps: {
            agentName: agentName,
            fullName: val.fullName,
            postalCode: val.address.postalCode,
            city: val.address.city
          },
          allDay: false,
          start: choosenStartDate,
          end: choosenEndDate,
          backgroundColor: color,
          color: bgColor
        }
      });

      this.agentAppointmentsUnassigned.push({
        uuid: currentAgent.uuid,
        fullName: currentAgent.fullName,
        checked: false,
        color: color,
        events: fullCalendarEventsUnassigned
      })
    }
  }

  prepareAgentReminders(agents: Person[], reminders: Journal[]): void {
    this.agentReminders = [];
    if(!this.isHighRole){
      const currentAgent = this.agents.find(x => x.uuid == this.advisorId);
      const agentInAppointment = this.agentAppointments.find(x => x.uuid == this.advisorId);
      const color = this.getRandomColor();
      const agentEvents = reminders.filter(x => 
        x.references.length > 0 && 
        x.references.some(x => x.id === currentAgent.uuid));
        const fullCalendarEvents = agentEvents.map((val, index) => {
          const date = moment(val.effectiveDate, 'YYYY-MM-DD').format('YYYY-MM-DD')
          const time = moment(val.sorKeys['time'], 'HH:mm:ss').format('HH:mm:ss')

          const choosenStartDate = new Date(`${date}T${time}`);
          const choosenEndDate = addHours(new Date(`${date}T${time}`), 0.1);
      
          return {
            id: val.operationIdExternal,
            title: `${val.descriptionField}, ${date}, ${time}`,
            extendedProps: {
              fullName: val.descriptionField,
            },
            allDay: false,
            start: choosenStartDate,
            end: choosenEndDate,
            backgroundColor: color,
            color: color
          }
        });
      this.agentReminders.push({
        uuid: currentAgent.uuid,
        fullName: currentAgent.fullName,
        checked: true,
        color: color,
        events: fullCalendarEvents
      })
      // this.setSelectedAgent();
    }
    else{
    agents.map(agent => {
      const agentInAppointment = this.agentAppointments.find(x => x.uuid == agent.uuid);
      const color = this.getRandomColor();
      const agentEvents = reminders.filter(x => 
        x.references.length > 0 && 
        x.references.some(x => x.id === agent.uuid && x.type.key === '080'));
        const fullCalendarEvents = agentEvents.map((val, index) => {
          const date = moment(val.effectiveDate, 'YYYY-MM-DD').format('YYYY-MM-DD')
          const time = moment(val.sorKeys['time'], 'HH:mm:ss').format('HH:mm:ss')

          const choosenStartDate = new Date(`${date}T${time}`);
          const choosenEndDate = addHours(new Date(`${date}T${time}`), 0.1);
          return {
            id: val.operationIdExternal,
            title: `${val.descriptionField}, ${date}, ${time}`,
            extendedProps: {
              fullName: val.descriptionField,
            },
            allDay: false,
            start: choosenStartDate,
            end: choosenEndDate,
            backgroundColor: color,
            color: color
        }
      })
      
        this.agentReminders.push({
        uuid: agent.uuid,
        fullName: agent.fullName,
        checked: false,
        color: color,
        events: fullCalendarEvents
      })
    })
    }  
  }

  changeDay(date: Date) {
    this.viewDate = date;
    this.view = CalendarView.Week;
  }

  beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }): void {
    body.forEach(cell => {
      const groups: any = {};
      cell.events.forEach((event: CalendarEvent<{ type: string }>) => {
        groups[event.color.primary] = groups[event.color.primary] || [];
        groups[event.color.primary].push(event);
        
      });
      cell['eventGroups'] = Object.entries(groups);
    });
  }

  beforeViewRender($event) {

    const group: any[] = [];
    let i = 0;
    $event.period.events.forEach((event: any) => {
        if (i % 2 === 0) {
          group.push(event);
        }
        i++;
    });

    $event.period["eventGroups"] = Object.entries(group);

  }

  dayClicked({ date, events, }: { date: Date; events: CalendarEvent[];}): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
        this.viewDate = date;
      }
    }
  }
  
toggleAllSelection() {
  this.allOptionSelected = !this.allOptionSelected;

  if (this.allOptionSelected) {
    this.selectedAgents = this.agentData.map(agent => agent);
    this.selectAllOptions();
  } else {
    this.selectedAgents = [];
    this.deselectAllOptions();
  }

  this.handleSelectedAgents();
}

toggleOptionSelection(agent: any) {
  const index = this.selectedAgents.findIndex(selectedAgent => selectedAgent === agent);

  if (index >= 0) {
    this.selectedAgents.splice(index, 1);
  } else {
    this.selectedAgents.push(agent);
  }

  this.allOptionSelected = this.selectedAgents.length === this.agentData.length;
  if (this.allOptionSelected) { 
    this.allOption.select(); 
  } else {
    this.allOption.deselect(); 
  }

  this.handleSelectedAgents();
}

selectAllOptions() {
  this.select.options.forEach((item: MatOption) => {
    item.select();
  });
}

deselectAllOptions() {
  this.select.options.forEach((item: MatOption) => {
    item.deselect();
  });
}

  handleSelectedAgents() {
    this.selectedAgentsEvents = [];
    if (!this.isHighRole) {
      if (this.reminderChecked) {
        this.selectedAgentsEvents = [].concat(...this.agentReminders.map(x => x.events));
      } else {
        this.selectedAgentsEvents = [].concat(
          ...this.agentAppointments.map(x => x.events));
      }
      this.addSelectedAgentsEventsToCalendar();
    } else {
      if (this.allOptionSelected) {
        if (this.reminderChecked) {
          this.selectedAgentsEvents = [].concat(...this.agentReminders.map(x => x.events));
        } else {
          this.selectedAgentsEvents = [].concat(
            ...this.agentAppointments.map(x => x.events),
            ...this.agentAppointmentsUnassigned.map(x => x.events));
        }
      } else {
        if (this.reminderChecked) {
          this.selectedAgents.forEach(selectedAgent => {
            let agentReminders = this.agentReminders;
            const matchingAppointment = agentReminders.filter(reminder => reminder.uuid === selectedAgent.uuid);
            matchingAppointment.forEach(agent => {
              this.selectedAgentsEvents.push(...agent.events);
            });
          });
        } else {
          this.selectedAgents.forEach(selectedAgent => {
            let agentAppointments = this.agentAppointments;
            const matchingAppointment = agentAppointments.filter(appointment => appointment.uuid === selectedAgent.uuid);
            matchingAppointment.forEach(agent => {
              this.selectedAgentsEvents.push(...agent.events);
            });
          });
        }
      }
      this.addSelectedAgentsEventsToCalendar();

      if (this.selectedAgents.length > 0) {
        this.calendarComponent["calendar"].setOption('eventDisplay', 'block');
      } else {
        this.calendarComponent["calendar"].setOption('eventDisplay', 'none');
      }
    }
  }

  setAllAgentsSelected(checked: boolean): void {
    this.selectedAgentsEvents = [];
    this.allAgentsSelected = checked;

    if(this.reminderChecked){        
      this.agentReminders = this.agentReminders.map(x => {
        x.checked = checked;
        if (x.checked) {
          this.selectedAgentsEvents.push(...x.events);
        }
        return x;
      })
    }else{      
      this.agentAppointments = this.agentAppointments.map(x => {
        x.checked = checked;
        if (x.checked) {
          this.selectedAgentsEvents.push(...x.events);
        }
        return x;
      })
    }
  }

  setSelectedAgent(): void {
    this.selectedAgentsEvents = [];
    if(this.reminderChecked){              
      const checkedAgents =  this.agentReminders.filter(x => x.checked);
      checkedAgents.map(y => {
        this.selectedAgentsEvents.push(...y.events);
      })
      this.allAgentsSelected = checkedAgents.length === this.agentReminders.length;
    }else{         
      const checkedAgents =  this.agentAppointments.filter(x => x.checked);
      checkedAgents.map(y => {
        this.selectedAgentsEvents.push(...y.events);
      })
      this.allAgentsSelected = checkedAgents.length === this.agentAppointments.length;
    }
  }
  

   getRandomColor() {
    const color = this.calendarColors[this.calendarColorIndex];
    this.calendarColorIndex = (this.calendarColorIndex + 1) % this.calendarColors.length;
    return color;
  }

  handleEventClick(data: EventClickArg): void {
    this.termineId = data.event.id;
    let appointment = this.tableAppointments.find(appointment => appointment.uuid === this.termineId);
    if (appointment) {
      this.lead = appointment;
      this.openDialog()
    } else {
      this.getAppointmentById(this.termineId, () => {
        this.openDialog()
      });
    }
  }

  openDialog() {
    let dialogRef = null;
    dialogRef = this.dialog.open(AppointmentDialogComponent, {
      panelClass: 'neomp-dialog-padding-none',
      data: { agents: this.agents, lead: this.lead, advisorName: this.advisorName }
    });
    dialogRef.afterClosed().subscribe((result: { success: boolean; leadData: Person, isUnassigned: boolean }) => {
      if (result && result.success === true) {
        if (result.isUnassigned) {
          this.loadUnassignedAppointmentsForCalendar(() => {
            this.calendarComponent.getApi().removeAllEvents();
            this.searchByDate();
            this.loadAppointments(() => {
              if (result.isUnassigned) {
                this.prepareAgentAppointments(this.agents, this.appointments, this.unassignedAppointmentsCalendar);
              }
            });
          })
        } else {
          this.calendarComponent.getApi().removeAllEvents();
          this.searchByDate();
          this.loadAppointments(() => { });
        }
      }
    });
  }

  loadUnassignedAppointmentsForCalendar(callback: () => void) {
    this.loadingService.display(true);

      this.appointmentProvider
      .getUnassignedAppointmentsForCalendar()
      .subscribe((appointments: { data: Person[]; totalCount: number }) => {
        this.totalCountAppointments = appointments.totalCount;
         if(this.agents.length > 0){
          this.unassignedAppointmentsCalendar = appointments.data.map(lead => this.assignAgentsToLeads(lead));
          this.loadingService.display(false);
        }
        else{
          this.unassignedAppointmentsCalendar = appointments.data;
        }

        if (callback) {
          callback();
        } 
      });
  }

  searchByReminderOrAppointment() {
    this.allAgentsSelected = false;
    this.agentData = [];
    this.selectedAgentsEvents = [];
    if (this.reminderChecked) {
      this.agentData = this.agentReminders;
      this.selectedAgents = [];
      if (this.agentReminders) {
        this.handleSelectedAgents();
      }
    } else {
      this.agentData = this.agentAppointments;
      if (this.isHighRole) {
        if (this.defaultSelected.length < 1) {
          this.selectedAgents = [];
        } else if (this.defaultSelected.length > 0) {
          this.selectedAgents = this.agentData.map(agent => agent);
        }
      }
      this.handleSelectedAgents();
    }
  }

  private _fillLeadDetails(person: Person): void {
    this.terminForm.patchValue({
      calendar_lang: '',
      termin_date: person.lead.termin_date,
      termin_time: person.lead.termin_time,
      termin_agent: this.agents.find(agent => agent.fullName === person.lead.advisor_name)
    })
  }

}

export interface Agent {
  uuid: string;
  fullName: string;
  color: string;
  checked: boolean;
  events: EventInput[];
}

export interface NeoCalendarEvent extends CalendarEvent {
  leadId: string;
}

interface NativeFormatterOptions extends Intl.DateTimeFormatOptions {
  omitZeroMinute?: boolean;
}
