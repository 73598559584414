import { Component, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { ActionData } from "app/modules/core/model/action-data";
import { ActionDataProvider } from "app/modules/core/providers/action-data.provider";
import { AdvisorService } from "app/modules/core/providers/advisor.service";
import { UserCommitState } from "../../../modules/core/model/user-commit-state";
import { IUploadMedia } from "../interfaces/info";
import { BusinessTransactionHelper } from "app/modules/core/static/bt-helper";
import { TranslateService } from "@ngx-translate/core";
import { HttpClient } from "@angular/common/http";
import { Config } from "app/modules/core/model/config";
import { LeadsService } from "app/modules/leads/services/leads.service";
import moment from "moment";
import { JournalProvider } from "app/modules/core/providers/journal.provider";
import { LoadingDataComponent } from "app/modules/core/components/loading-data.component";
import { RegisterProvider } from "app/modules/core/providers/register.provider";
import { Journal } from "app/modules/core/model/journal";
import { LoadingService } from "app/modules/core/providers/loading.component";
import { environment } from "environments/environment";
import { StrapiService } from "app/modules/shared/services/strapi.service";
import { compressToEncodedURIComponent, decompressFromEncodedURIComponent } from "lz-string";


const TYPE_ID = "2500";

@Component({
  selector: "neomp-info",
  templateUrl: "./info.component.html",
  styleUrls: ["./info.component.scss"],
})
export class InfoComponent implements OnInit {
  public qrdata: any;
  public elementType: "img" | "url" | "canvas" | "svg" = null;
  public level: "L" | "M" | "Q" | "H";
  public scale: number;
  public width: number;

  copyText: string;
  matTooltipHideDelay = 0;

  vcardForm: FormGroup = new FormGroup({
    vcardQuantity: new FormControl("")
  })

  appointmentLanguages = new FormControl([], [Validators.required]);
  languageOptions: string[] = [];

  cards: Journal[] = [];
  cardsCount: number = 0;

  @ViewChild("journalTable") journalTableLoadingComponent: LoadingDataComponent;

  constructor(
    public advisorService: AdvisorService,
    public actionDataProvider: ActionDataProvider,
    public router: Router,
    public snackBar: MatSnackBar, // private cdr: SimpleChanges
    private translationService: TranslateService,
    private http: HttpClient,
    private strapiService: StrapiService,
    private leadService: LeadsService,
    private journalProvider: JournalProvider,
    public route: ActivatedRoute,
    public registerProvider: RegisterProvider,
    public loadService: LoadingService,
  ) {
    this.elementType = "canvas";
    this.level = "M";
    // this.qrdata = "www.google.com";
    this.scale = 1;
    this.width = 256;
  }
  advisor: any;
  agents: any;
  agent: any;
  phoneNumber: any = '';
  email: any = '';
  fileSizeAllowed = true;
  image: any;
  lzStringData: any = {};
  lzString: string = '';
  appointemntToolLink: string = '';
  ngOnInit() {
    this.getAppointmentToolCms();
    this.appointmentLanguages.valueChanges.subscribe(value => {
      if (value.length > 0) {
        this.lzStringData.appointmentLanguages = value;
        this.lzStringData.url = environment.settings.APPOINTMENT_TOOL_URL;
        this.lzString = compressToEncodedURIComponent(JSON.stringify(this.lzStringData));
        this.appointemntToolLink = `${environment.settings.APPOINTMENT_TOOL_URL}?id=${this.lzString}`;
      } else {
        this.lzStringData.appointmentLanguages = [];
        this.lzString = '';
        this.appointemntToolLink = '';
      }

    })
    this.advisorService.getAdvisorData().then((data) => {
      
      this.advisor = data;
      this.lzStringData.advisorId = this.advisor.uuid,
      this.lzStringData.agenturId = this.advisor.sorKeys.agenturID

      this.advisorInfo();

      this.getJournalsReferencedWithPagination();

      this.qrdata =
        "BEGIN:VCARD\nN:" +
        this.advisor.lastName +
        "\nTEL;CELL:" +
        this.phoneNumber +
        "\nEMAIL:" +
        this.email?.address +
        "\nORG:Neosana AG" +
        "\nEND:VCARD";
    });
    this.getConfAgent();

  }
  uploadMediaToAdvisor() {
    this.advisorService.updateAdvisorMedia(this.image).subscribe(
      (result) => {
        this.advisor.b64Data = this.image.fileAsString;
        this.snackBar.open(
          "Successfully uploaded image",
          "x",
          UserCommitState.configSuccess
        );
      },
      (error) => {
        this.snackBar.open(
          "Failed to uploaded image" + error,
          "x",
          UserCommitState.configError
        );
      }
    );
  }
  getConfAgent(): void {
    this.http.get<Config[]>('assets/data/configuration.json').subscribe(
      config => {
        const agent = config.find(i => i.key === 'neosanaAgent');
        this.agent = {
          address: agent.value.address,
          email: agent.value.email,
          lastName: agent.value.lastName,
          phone: agent.value.phone,

        }
      }
    );
  }

  advisorInfo() {
    this.phoneNumber = this.advisor.communicationAddresses.filter((x) => [31, 10, 30].includes(Number(x.commAddrType.key)))[0]?.address || this.agent.phone;

    this.advisor.communicationAddresses.filter((x) => {
      if (x.commAddrType.key == 51) {
        this.email = x;
      }
      else {
        this.email = {
          "address": x.address
        }
      }
    });
  }

  fileUploaded(event) {
    this.image = {};
    if (this.isFileSizeAllowed(event.target.files[0].size)) {
      this.convertImageToB64(event.target.files[0]).then(
        (fileAsString: string) => {
          this.image = {
            file: event.target.files[0].name,
            size: event.target.files[0].size,
            fileAsString,
          };
          this.uploadMediaToAdvisor();
        }
      );
      this.fileSizeAllowed = true;
    } else {
      this.image = {};
      this.fileSizeAllowed = false;
    }
  }

  convertImageToB64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (reader.result) {
          resolve(reader.result as string);
        } else {
          reject();
        }
      };
    });
  }
  isFileSizeAllowed(size) {
    let isFileSizeAllowed = false;
    if (size < 5242880) {
      isFileSizeAllowed = true;
    }
    return isFileSizeAllowed;
  }

  order(email: string) {
    this.loadService.display(true);
    if (email !== null && this.vcardForm.get('vcardQuantity').value !== "") {
      const dateNow = moment().format('YYYY-MM-DD');
      const actionData: ActionData = BusinessTransactionHelper.createVCardAction(this.vcardForm.get('vcardQuantity').value, this.advisor.lastName, dateNow);
      this.actionDataProvider.sendActionData(actionData).subscribe(res1 => {
        this.advisorService.orderVCard(email, this.vcardForm.get('vcardQuantity').value).subscribe(res => {
          const success = this.translationService.instant("COMMONS.USERCOMMIT.SUCCESS");
          this.snackBar.open(success, "x", UserCommitState.configSuccess);
          this.getJournalsReferencedWithPagination();
        }, err => {
          const error = this.translationService.instant("COMMONS.USERCOMMIT.ERROR");
          this.snackBar.open(error, "X", UserCommitState.configError)
        });
      });
    }
  }


  getAppointmentToolCms(){
    this.strapiService.getAppointmentToolCms().subscribe((res: any) => {
      this.languageOptions = res.data.languages.map(x => x.language);
    })
  } 

  removeLang(language: string) {
    const selectedLanguages = this.appointmentLanguages.value;
    const remaininglangs = selectedLanguages.filter(x => x !== language);

    this.appointmentLanguages.setValue(remaininglangs);
  }

  copyMessage() {
    this.matTooltipHideDelay = 500;
    this.copyText = this.translationService.instant('HEADER.COPIED');
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.appointemntToolLink;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    setTimeout(() => {
        this.copyText = this.translationService.instant('HEADER.COPY_LINK');
    }, 1000);
    this.matTooltipHideDelay = 0;
}

  saveAsImage(parent) {
    // fetches base 64 date from image
    const parentElement = parent.el.nativeElement.querySelector("img").src;

    // converts base 64 encoded image to blobData
    let blobData = this.convertBase64ToBlob(parentElement);

    // saves as image
    // chrome
    const blob = new Blob([blobData], { type: "image/png" });
    const url = window.URL.createObjectURL(blob);
    // window.open(url);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'Appointment Tool QR Code';
    link.click();

  }

  private convertBase64ToBlob(Base64Image: any) {
    // SPLIT INTO TWO PARTS
    const parts = Base64Image.split(';base64,');
    // HOLD THE CONTENT TYPE
    const imageType = parts[0].split(':')[1];
    // DECODE BASE64 STRING
    const decodedData = window.atob(parts[1]);
    // CREATE UNIT8ARRAY OF SIZE SAME AS ROW DATA LENGTH
    const uInt8Array = new Uint8Array(decodedData.length);
    // INSERT ALL CHARACTER CODE INTO UINT8ARRAY
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }
    // RETURN BLOB IMAGE AFTER CONVERSION
    return new Blob([uInt8Array], { type: imageType });
  }


  getJournalsReferencedWithPagination() {
    const sort = { creationDate: -1 };
    this.journalProvider.getJournalsReferencedWithPagination(
      this.journalTableLoadingComponent,
      TYPE_ID,
      TYPE_ID,
      0,
      15,
      sort
    )
      .subscribe(journals => {
        this.cards = journals.data.map(x => x.transaction).sort((a, b) => { return (a.creationDate < b.creationDate) ? 1 : -1 })
        this.cardsCount = journals.totalCount;
        this.loadService.display(false);
      })
  }
}
