import {Component, EventEmitter, Input, Output} from "@angular/core";
import {FormControl} from "@angular/forms";
import {Helper} from "../../static/helper";
import {FilterTableComponent} from "./filter-table-component";
import {JournalList} from "../../model/journal-list";
import {JournalListJson} from "../../json/journal-list.json";
import {ConfigProvider} from "../../providers/config.provider";
import {ActionDataProvider} from "../../providers/action-data.provider";
import { MatSnackBar } from "@angular/material/snack-bar";
import {LoadingService} from "../../providers/loading.component";
import {Policy} from "../../model/policy";
import {ActionDialogData} from "../action.dialog.component";
import {BusinessTransactionHelper} from "../../static/bt-helper";
import {ActionDialogService} from "../../providers/action-dialog.service";
import { MatDialog } from "@angular/material/dialog";

@Component({
    selector: "neomp-created-requests-table",
    templateUrl: "./created-requests-table.component.html",
    styleUrls: ["./created-requests-table.component.scss"]
})

export class CreatedRequestsTableComponent extends FilterTableComponent<JournalList> {
    private readonly TAG = this.constructor.name;

    @Output()
    rowClickEmitter = new EventEmitter<JournalList>();

    @Input()
    isOfferPage: boolean;
    isDialogOpen = false;

    selectedJournalRowIndex;

    effectiveDateFilter = new FormControl("");
    birthdateFilter = new FormControl("");
    descriptionFieldFilter = new FormControl("");
    completedFilter = new FormControl("");
    creationDateFilter = new FormControl("");
    transactionIdFilter = new FormControl("");
    reasonForChangeFilter = new FormControl("");
    filterValues: JournalList = new JournalList();

    displayedHeaderColumns = [
        "status",
        "creationDate",
        "completed",
        "descriptionField",
        "reasonForChange",
        "filter"
    ];
    displayedColumns = [
        "status",
        "creationDate",
        "completed",
        "descriptionField",
        "reasonForChange",
        "filter"
    ];
    displayedColumnFilters = [
        "status",
        "creationDateFilter",
        "completedFilter",
        "descriptionFieldFilter",
        "reasonForChangeFilter",
        "filterRemove"
    ];
    formControlFilters: FormControl[] = [
        null,
        this.creationDateFilter,
        this.completedFilter,
        this.descriptionFieldFilter,
        this.reasonForChangeFilter,
        null
    ];

    constructor(public configProvider: ConfigProvider,
                public actionDataProvider: ActionDataProvider,
                public snackBar: MatSnackBar,
                public loadService: LoadingService,
                public dialogService: ActionDialogService,
                public dialog: MatDialog) {
        super();
    }

    journalRowClick(journalRow: JournalList) {
        this.selectedJournalRowIndex = journalRow.uuid;
        this.rowClickEmitter.next(journalRow);
    }

    resetFilter() {
        this.filterValues = new JournalList();
        super.resetFilter();
    }

    createFilter(): (data: JournalList, filter: string) => boolean {
        return function (data, filter): boolean {
            const searchTerms: JournalList = JournalList.fromJson(JSON.parse(filter) as JournalListJson);
            return (searchTerms.completed != null ? Helper.checkStringContains(data.completed.toString(), searchTerms.completed.toString()) : true) &&
                (searchTerms.creationDate != null ? Helper.checkStringContains(data.creationDate, searchTerms.creationDate) : true) &&
                (searchTerms.descriptionField != null ? Helper.checkStringContains(data.descriptionField, searchTerms.descriptionField) : true) &&
                (searchTerms.reasonForChange != null ? Helper.checkStringContains(data.reasonForChange, searchTerms.reasonForChange) : true);
        }
    }

    openActionDialog(event: Event, element: JournalList) {
        this.isDialogOpen = true;
        event.stopPropagation();
        const policy = element.policies.find(p => p.uuid === element.policyId);
        const dialogData: ActionDialogData = {
            transTypeId: BusinessTransactionHelper.typeId.offer.from,
            transName: "Offer",
            transSubject: "Offer",
            transTitle: "Offer",
            transMessage: "Offer",
            operationalIdExternal: element.transaction.sorKeys["ProcessID"],
            keyValues: [],
            person: null,
            policy: policy
        };
        this.dialogService.openByType(dialogData, 
            {
            defaultTypeId: BusinessTransactionHelper.typeId.offer.from
            }, this.TAG
        );
        this.dialog.afterAllClosed.subscribe(() => {
            this.isDialogOpen = false;
        });
    }
}
