<table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort>
    <ng-container matColumnDef="select" *ngIf="managementView">
        <th mat-header-cell *matHeaderCellDef class="check-filter-w">
            <mat-checkbox class="all-checkbox" (change)="$event ? toggleAllRows() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
            </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
            </mat-checkbox>
        </td>
    </ng-container>
    <ng-container matColumnDef="name">
        <th *matHeaderCellDef mat-header-cell class="sort-arrow" mat-sort-header="fullName">{{ 'PAGE.LEADS.LEAD.NAME' | translate }}</th>
        <td mat-cell *matCellDef="let lead">{{lead.fullName}}</td>
    </ng-container>
    <ng-container matColumnDef="address">
        <th *matHeaderCellDef mat-header-cell mat-sort-header="address.postalCode">{{ 'PAGE.LEADS.LEAD.PLZ-ORT' |
            translate }}</th>
        <td mat-cell *matCellDef="let lead"> {{lead.address.postalCode + ' ' + lead.address.city }}</td>
    </ng-container>
    <ng-container matColumnDef="art">
        <th *matHeaderCellDef mat-header-cell mat-sort-header="lead.lead_typ">{{ 'PAGE.LEADS.LEAD.ART' | translate }}
        </th>
        <td mat-cell *matCellDef="let lead"> {{lead.lead !== null ? lead.lead.lead_typ : ''}}</td>
    </ng-container>
    <ng-container matColumnDef="statusLead">
        <th *matHeaderCellDef mat-header-cell mat-sort-header="lead.lead_status">{{ 'PAGE.LEADS.LEAD.STATUS' | translate
            }}</th>
        <td mat-cell *matCellDef="let lead">
            <mat-chip class="neo-statusNeu-backgroundColor"  *ngIf="lead.lead.lead_status == 'Neu'">
            {{lead.lead !== null ? lead.lead.lead_status : ''}}
            </mat-chip> 
            <span *ngIf="lead.lead.lead_status != 'Neu'">
                {{lead.lead !== null ? lead.lead.lead_status : ''}}
            </span>
    </td>
    </ng-container>
    <ng-container matColumnDef="substatusLead">
        <th *matHeaderCellDef mat-header-cell mat-sort-header="lead.lead_substatus">{{ 'PAGE.LEADS.LEAD.SUBSTATUS' | translate }}
        </th>
        <td mat-cell *matCellDef="let lead"> {{lead.lead !== null ? lead.lead.lead_substatus : ''}}</td>
    </ng-container>
    <!-- <ng-container matColumnDef="completed">
        <th *matHeaderCellDef mat-header-cell
            mat-sort-header>{{ 'PAGE.LEADS.LEAD.COMPLETED' | translate }}</th>
        <td mat-cell *matCellDef="let lead"> {{lead.lead?.termin_stattgefunden == 1 ? 'Ja' : 'Nein'}}</td>
    </ng-container> -->
    <ng-container matColumnDef="date">
        <th *matHeaderCellDef mat-header-cell mat-sort-header="lead.lead_creadate">{{ 'PAGE.LEADS.LEAD.DATE' | translate
            }}</th>
        <td mat-cell *matCellDef="let lead"> {{ dateParse(lead.lead.lead_creadate) | date: 'dd.MM.yyyy' }}</td>
    </ng-container>
    <ng-container matColumnDef="category">
        <th *matHeaderCellDef mat-header-cell mat-sort-header="lead.lead_kategory">{{ 'PAGE.LEADS.LEAD.CATEGORY' |
            translate }}</th>
        <td mat-cell *matCellDef="let lead"> {{lead.lead !== null ? lead.lead.lead_kategory : ''}}</td>
    </ng-container>
    <ng-container matColumnDef="language">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'PAGE.LEADS.LEAD.LANGUAGE' | translate }}</th>
        <td mat-cell *matCellDef="let lead"> {{lead !== null ? lead.preferedLanguage : ''}}</td>
    </ng-container>
    <ng-container *ngIf="isHighRole && !managementView" matColumnDef="advisor">
        <th *matHeaderCellDef mat-header-cell mat-sort-header="sorKeys.agent">{{ 'PAGE.LEADS.LEAD.ADVISOR' | translate
            }}</th>
        <td mat-cell *matCellDef="let data">
            {{data.sorKeys['agent'] && data.sorKeys['agent'].split("Neosana Sales AG")[1] ?
            data.sorKeys['agent'].split("Neosana Sales AG")[1] : data.sorKeys['agent']}}
        </td>
    </ng-container>
    <ng-container *ngIf="isHighRole && managementView" matColumnDef="advisor">
        <th *matHeaderCellDef mat-header-cell mat-sort-header="sorKeys.agent">{{ 'PAGE.LEADS.LEAD.ADVISOR' | translate
            }}</th>
        <td mat-cell *matCellDef="let data" (click)="agentsControlOnClick($event)">
            <mat-form-field class="neomp-full-width">
                <ng-container *ngIf="!getSelectedAgent(data.lead.termin_berater_shortcut)?.blocked; else inputField">
                    <mat-select [value]="getSelectedAgent(data.lead.termin_berater_shortcut)"
                        (selectionChange)="assignAgentToLead($event.value, data)">
                        <mat-option *ngFor="let agent of unblockedAgents" [value]="agent">
                            {{ splitFullName(agent.fullName)}}
                        </mat-option>
                    </mat-select>
                </ng-container>
                <ng-template #inputField>
                    <ng-container *ngIf="showSelect">
                        <mat-select [value]="getSelectedAgent(data.lead.termin_berater_shortcut)"
                        (selectionChange)="assignAgentToLead($event.value, data)" (openedChange)="toggleSelect($event)">
                            <mat-option *ngFor="let agent of unblockedAgents" [disabled]="agent.blocked === true" [value]="agent">
                                {{ splitFullName(agent.fullName)}}
                            </mat-option>
                        </mat-select>
                    </ng-container>
                    <ng-container *ngIf="!showSelect">
                        <mat-select [value]="getSelectedAgent(data.lead.termin_berater_shortcut)" (click)="toggleSelect(true)"
                        (selectionChange)="assignAgentToLead($event.value, data)">
                        <mat-option *ngFor="let agent of agents" [value]="agent">
                            {{ splitFullName(agent.fullName)}}
                        </mat-option>
                    </mat-select>
                    </ng-container>
                </ng-template>
            </mat-form-field>
        </td>
    </ng-container>
    <ng-container matColumnDef="icon">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let lead">
          <div class="div-status" [ngClass]="getClassFromStatus(lead.uuid, lead.lead.edit_servicecenter)" ></div>
        </td>
      </ng-container>
    <ng-container *ngIf="!managementView" matColumnDef="filter">
        <th *matHeaderCellDef mat-header-cell (click)="changeFilterView()"
            class="mat-header-cell mat-column-filter {{isFilterActive ? 'mat-column-filter-active':''}}">
            <div class="neomp-table-column-filter-element  mdi {{isFilterActive ? 'mdi-filter':'mdi-filter-outline'}}">
            </div>
        </th>
        <td mat-cell *matCellDef="let element" [ngClass]="{'mat-column-filter-action': isEventOrRetention(element)}">
            <div *ngIf="isEventOrRetention(element)" class="neomp-table-column-filter-element mdi mdi-plus" [disabled]="isDialogOpen"
                (click)="isDialogOpen ? null : openEventDialog($event, element);"></div>
        </td>
    </ng-container>

    <ng-container *ngIf="managementView" matColumnDef="filter">
        <th *matHeaderCellDef mat-header-cell (click)="changeFilterView()"
            class="mat-header-cell mat-column-filter {{isFilterActive ? 'mat-column-filter-active':''}}">
            <div class="neomp-table-column-filter-element  mdi {{isFilterActive ? 'mdi-filter':'mdi-filter-outline'}}">
            </div>
        </th>
        <td mat-cell *matCellDef="let element" class="mat-column-filter-actions">
            <div class="neomp-table-column-filter-element mdi mdi-plus" [disabled]="isDialogOpen"
            (click)="isDialogOpen ? null : openActionDialog($event, element)">
            </div>
        </td>
    </ng-container>

    <!-- Filterrow elements-->
    <ng-container matColumnDef="leadSelectFilter" *ngIf="managementView">
        <th mat-header-cell *matHeaderCellDef>
        </th>
    </ng-container>

    <ng-container matColumnDef="nameFilter">
        <th mat-header-cell *matHeaderCellDef>
            <mat-form-field floatLabel="never">
                <input matInput [formControl]="leadNameFilter"
                    placeholder="{{ 'TABLES.FILTERPLACEHOLDER.NAME' | translate }}">
            </mat-form-field>
        </th>
    </ng-container>
    <ng-container matColumnDef="address2Filter">
        <th mat-header-cell *matHeaderCellDef>
            <mat-form-field floatLabel="never">
                <input matInput [formControl]="leadAddressFilter"
                    placeholder="{{ 'TABLES.FILTERPLACEHOLDER.POSTCODE_CITY' | translate }}">
            </mat-form-field>
        </th>
    </ng-container>
    <ng-container matColumnDef="artFilter">
        <th mat-header-cell *matHeaderCellDef>
            <mat-form-field floatLabel="never">
                <input matInput [formControl]="leadArtFilter"
                    placeholder="{{ 'TABLES.FILTERPLACEHOLDER.ART' | translate }}">
            </mat-form-field>
        </th>
    </ng-container>
    <ng-container matColumnDef="statusFilter">
        <th mat-header-cell *matHeaderCellDef>
            <mat-form-field floatLabel="never">
                <input matInput [formControl]="leadStatusFilter"
                    placeholder="{{ 'TABLES.FILTERPLACEHOLDER.STATUS' | translate }}">
            </mat-form-field>
        </th>
    </ng-container>

    <ng-container matColumnDef="substatusFilter">
        <th mat-header-cell *matHeaderCellDef>
            <mat-form-field floatLabel="never">
                <input matInput [formControl]="leadSubstatusFilter"
                    placeholder="{{ 'TABLES.FILTERPLACEHOLDER.SUBSTATUS' | translate }}" >
            </mat-form-field>
        </th>
    </ng-container>

    <!-- <ng-container matColumnDef="completedFilter">
        <th mat-header-cell *matHeaderCellDef>
            <mat-form-field floatLabel="never"> -->
    <!-- <input matInput [formControl]="leadCompletedFilter"
                       placeholder="{{ 'TABLES.FILTERPLACEHOLDER.COMPLETED' | translate }}"> -->
    <!-- <mat-label>{{ 'TABLES.FILTERPLACEHOLDER.COMPLETED' | translate }}</mat-label>
                <mat-select [formControl]="leadCompletedFilter">
                    <mat-option *ngFor="let option of completedOptions" [value]="option">
                        {{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </th>
    </ng-container> -->
    <ng-container matColumnDef="dateFilter">
        <th mat-header-cell *matHeaderCellDef>
            <mat-form-field floatLabel="never">
                <input [matDatepicker]="date" matInput [formControl]="leadDateFilter"
                    placeholder="{{ 'TABLES.FILTERPLACEHOLDER.DATE' | translate }}">
                <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
                <mat-datepicker #date></mat-datepicker>
            </mat-form-field>
        </th>
    </ng-container>
    <ng-container matColumnDef="categoryFilter">
        <th mat-header-cell *matHeaderCellDef>
            <mat-form-field floatLabel="never">
                <input matInput [formControl]="leadCategoryFilter"
                    placeholder="{{ 'TABLES.FILTERPLACEHOLDER.CATEGORY' | translate }}">
            </mat-form-field>
        </th>
    </ng-container>
    <ng-container matColumnDef="languageFilter">
        <th mat-header-cell *matHeaderCellDef>
            <mat-form-field floatLabel="never">
                <input matInput [formControl]="leadLanguageFilter"
                    placeholder="{{ 'TABLES.FILTERPLACEHOLDER.LANGUAGE' | translate }}">
            </mat-form-field>
        </th>
    </ng-container>
    <ng-container *ngIf="isHighRole" matColumnDef="advisorFilter">
        <th mat-header-cell *matHeaderCellDef>
            <mat-form-field floatLabel="never">
                <input matInput [formControl]="leadAdvisorFilter"
                    placeholder="{{ 'TABLES.FILTERPLACEHOLDER.ADVISOR' | translate }}">
            </mat-form-field>
        </th>
    </ng-container>
    <ng-container matColumnDef="iconFilter">
        <th mat-header-cell *matHeaderCellDef>
        </th>
      </ng-container>
    <ng-container matColumnDef="filterRemove">
        <th mat-header-cell *matHeaderCellDef (click)="resetFilter()">
            <div class="neomp-table-column-filter-element mdi mdi-close"></div>
        </th>
    </ng-container>


    <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>

    <!-- <tr mat-row *matRowDef="let row; columns: displayedColumns;"
    (click)="selection.toggle(row)"> -->
    <!-- </tr> -->

    <tr *matHeaderRowDef="displayedColumnFilters"
        class="neomp-table-row-filter {{showFilter? '': 'neomp-table-row-filter-hide'}}" mat-header-row></tr>

    <tr mat-row *matRowDef="let element; columns: displayedColumns; let i = dataIndex;"
        class="neomp-table-row-selected element-row container-table-row"
        (click)="redirectToEdit(element.uuid, element.lead.termin_berater_shortcut, element.lead.edit_servicecenter);">
    </tr>


</table>