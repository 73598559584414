import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Observable, of } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { Household } from "../../core/model/household";
import { HouseholdProvider } from "../../core/providers/household.provider";
import { RegisterProvider } from "../../core/providers/register.provider";
import { RestProviderActionsDummy } from "../../core/providers/rest.provider";
import { Person } from "../../core/model/person";
import { UploadFileComponent } from "../../core/components/upload-file.component";
import { ActionDataProvider } from "../../core/providers/action-data.provider";
import { log } from "../../core/providers/logger.provider";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ContactDataComponent } from "../../core/components/contact-data.component";
import { TranslateService } from "@ngx-translate/core";
import { BusinessTransactionHelper } from "../../core/static/bt-helper";
import { FileUpload } from "../../core/model/file-upload";
import { UserCommitState } from "../../core/model/user-commit-state";
import { LoadingService } from "../../core/providers/loading.component";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { JournalProvider } from "../../core/providers/journal.provider";
import { JournalList } from "../../core/model/journal-list";
import { ConfigProvider } from "app/modules/core/providers/config.provider";
import * as uuid from "uuid";
import { compressToEncodedURIComponent } from "lz-string";
import { LoadingDataComponent } from "app/modules/core/components/loading-data.component";
import { PaginationConfigs } from "app/modules/constants/PaginationConstants";
import { PdfProvider } from "app/modules/core/providers/pdf.provider";

@Component({
  selector: "neomp-offer-wizard-old",
  templateUrl: "./offer-wizard-old.component.html",
  styleUrls: ["./offer-wizard-old.component.scss"],
})
export class OfferWizardOldComponent implements OnInit, AfterViewInit {
  private readonly TAG: string = this.constructor.name;

  @ViewChild(UploadFileComponent) uploadFile: UploadFileComponent;
  @ViewChild(ContactDataComponent) contactData: ContactDataComponent;
  @ViewChild("loadingDataComponent")
  loadingDataComponent: LoadingDataComponent;
  pageSize = 5;
  pageSizeOptions = [5, 15, 30, 50];
  pageIndex = PaginationConfigs.pageIndex;
  totalCount = 0;
  checked = false;
  enablePersonSelect = false;
  personForm: FormGroup;
  households: Observable<Household[]> = of([]);
  householdsData: Household[] = [];
  personsData: Person[] = [];
  filledDocuments: JournalList[] = [];
  selectedHousehold: Household;
  selectedPerson: Person;
  representativeUuid = "";
  selectedPersonUuid = "";

  houseHoldControl = new FormControl();
  personSelectControl = new FormControl(
    { value: "", disabled: true },
    Validators.required
  );

  companiesForm: FormGroup;
  companiesControl = new FormControl(
    { value: "", disabled: true },
    Validators.required
  );
  companies: string[] = [];

  branchesForm: FormGroup;
  branchesControl = new FormControl(
    { value: "", disabled: false },
    Validators.required
  );
  branches: { [key: string]: string[] } = {};
  currentBranch = "";
  selectedCompanies = [];

  offerUrl = "";
  loading = false;
  private readonly CLASS_NAME = this.constructor.name;

  constructor(
    public householdProvider: HouseholdProvider,
    public registerProvider: RegisterProvider,
    public actionDataProvider: ActionDataProvider,
    public translateService: TranslateService,
    public route: ActivatedRoute,
    public router: Router,
    public location: Location,
    public snackBar: MatSnackBar,
    public loadService: LoadingService,
    public journalProvider: JournalProvider,
    public configProvider: ConfigProvider,
    public pdfProvider: PdfProvider
  ) {}

  ngOnInit() {
    this.personForm = new FormGroup({
      personSelectControl: this.personSelectControl,
    });
    this.branchesForm = new FormGroup({
      branchesControl: this.branchesControl,
    });
    this.companiesForm = new FormGroup({
      companiesControl: this.companiesControl,
    });

    this.configProvider.getConfigValue({defaultTypeId: "3199"}).subscribe((branches) => {
      branches.data.forEach((v) => {
        this.branches[v.key] = v.value.split(",");
      });
    });
    this.getFilledOfferData();
  }

  getFilledOfferData() {
    this.journalProvider
      .getJournalsReferencedWithPagination(
        this.loadingDataComponent,
        BusinessTransactionHelper.typeId.filledDocuments.from,
        BusinessTransactionHelper.typeId.filledDocuments.to,
        this.pageIndex,
        this.pageSize
      )
      .subscribe((journals) => {
        this.filledDocuments = journals.data;
        this.totalCount = journals.totalCount;
      });
  }

  ngAfterViewInit(): void {
    this.route.paramMap.subscribe((params) => {
      const paramNames = this.registerProvider.getPathParamForKey("Offers");
      if (paramNames !== null && paramNames !== undefined) {
        const paramData1 = params.get(paramNames[0]);
        if (paramData1 && paramData1.length > 0) {
          this.representativeUuid = paramData1;
          this.selectedPersonUuid = paramData1;
          const paramData2 = params.get(paramNames[1]);
          if (paramData2 && paramData2.length > 0) {
            this.selectedPersonUuid = paramData2;
          }
        } else {
          this.router.navigateByUrl(this.registerProvider.getPath("Offers"));
        }
        this.getHouseHolds();
        this.getFilledOfferData();
      }
    });
  }

  getHouseHolds() {
    this.householdProvider
      .getHouseholds(new RestProviderActionsDummy())
      .subscribe((households) => {
        this.householdsData = households;
        this.households = this.houseHoldControl.valueChanges.pipe(
          startWith(""),
          map((value) => this._filter(value))
        );
        if (this.representativeUuid) {
          this.selectHouseHold(
            this.householdsData.find((option) =>
              option.representant.uuid.includes(this.representativeUuid)
            )
          );
          this.houseHoldControl.setValue(this.selectedHousehold.householdName);
          this.selectedPerson = this.selectedHousehold.persons.find(
            (p) => p.uuid === this.selectedPersonUuid
          );
          this.personSelectControl.setValue(this.selectedPerson);
        }
      });
  }

  selectHouseHold(household: Household) {
    this.representativeUuid = household.representant.uuid;
    this.enablePersonSelect = true;
    this.personSelectControl.enable();
    if (household !== this.selectedHousehold) {
      this.personSelectControl.reset();
      this.branchesControl.reset();
      this.companiesControl.reset();
      this.currentBranch = "";
      this.selectedCompanies = [];
      this.companies = [];
      this.selectedPerson = new Person();
      this.selectedHousehold = household;
      this.householdProvider
        .getCompleteHouseholdByUuid(
          this.representativeUuid,
          new RestProviderActionsDummy()
        )
        .subscribe((h) => {
          this.selectedHousehold = h;
          this.personsData = h.persons;
        });
    }
  }

  createNewCustomer(createNewPerson) {
    this.resetOfferWizardUserInputs();
    this.representativeUuid = "";

    this.location.replaceState(`/${this.registerProvider.getPath("Offers")}`);
    this.contactData.resetContactDataForm();
    if (createNewPerson) {
      this.personForm = this.contactData.getContactDataForm();
      this.personSelectControl.disable();
      this.houseHoldControl.disable();
    } else {
      this.personForm = new FormGroup({
        personSelectControl: this.personSelectControl,
      });
      this.houseHoldControl.enable();
    }
    this.selectedPerson = new Person();
    this.selectedPerson.uuid = uuid.v4();
    this.selectedPersonUuid = this.selectedPerson.uuid;
  }

  checkValidationOrderForms() {
    this.branchesControl.markAsTouched();
    this.personSelectControl.markAsTouched();
    this.houseHoldControl.markAsTouched();
    // is new customer?
    if (this.checked) {
      return this.contactData.checkValidation() && this.branchesForm.valid;
    }
    return this.personForm.valid && this.branchesForm.valid;
  }

  checkValidationOfferCreate() {
    this.branchesControl.markAsTouched();
    this.companiesControl.markAsTouched();
    return this.branchesForm.valid && this.companiesForm.valid;
  }

  startReadFile() {
    if (this.checkValidationOfferCreate()) {
      this.uploadFile.readFiles();
    } else {
      this.translateService
        .get("COMMONS.USERCOMMIT.CHECKDATA")
        .subscribe((result) => {
          this.snackBar.open(result, "x", UserCommitState.configCheckData);
        });
    }
  }

  private resetOfferWizardUserInputs() {
    log.debug(`${this.TAG} resetOfferWizardUserInputs()`);
    this.houseHoldControl.reset();
    this.selectedHousehold = null;
    this.personSelectControl.reset();
    this.selectedPerson = null;
    this.representativeUuid = "";
    this.selectedPersonUuid = "";

    this.branchesControl.reset();
    this.branchesForm.reset();
    this.currentBranch = "";
    this.companiesControl.reset();
    this.companiesForm.reset();
    this.selectedCompanies = [];
    this.companies = [];

    this.filledDocuments = [];
  }

  createOffer(uploadFiles: FileUpload[]) {
    this.loadService.display(true);
    this.loading = true;
    this.actionDataProvider
      .sendActionData(
        BusinessTransactionHelper.createOffer(
          uploadFiles,
          this.selectedPerson,
          BusinessTransactionHelper.typeId.offerCreate.from,
          this.currentBranch,
          this.selectedCompanies
        )
      )
      .toPromise()
      .then((value) => {
        log.debug(`${this.CLASS_NAME}: Response on sendActionData ${value}`);
        this.loadService.display(false);
        this.loading = false;
        this.translateService
          .get("COMMONS.USERCOMMIT.SUCCESS")
          .subscribe((result) => {
            this.snackBar.open(result, "x", UserCommitState.configSuccess);
          });
        this.resetOfferWizardUserInputs();
      })
      .catch((reason) => {
        log.debug(`${this.CLASS_NAME}: Response on sendActionData [${reason}]`);
        this.resetOfferWizardUserInputs();
        this.loadService.display(false);
        this.loading = false;
        this.translateService
          .get("COMMONS.USERCOMMIT.ERROR")
          .subscribe((result) => {
            this.snackBar.open(result, "x", UserCommitState.configError);
          });
      });
  }

  downloadDocument() {
    if (this.checkValidationOrderForms()) {
      var data = BusinessTransactionHelper.createOffer(
        [],
        this.selectedPerson,
        BusinessTransactionHelper.typeId.offerFillDocuments.from,
        this.currentBranch,
        this.selectedCompanies
      );
      this.pdfProvider.downloadPdf(this.currentBranch, data.person);
    } else {
      this.translateService
        .get("COMMONS.USERCOMMIT.CHECKDATA")
        .subscribe((result) => {
          this.snackBar.open(result, "x", UserCommitState.configCheckData);
        });
    }
  }

  // orderForms() {
  //     if (this.checkValidationOrderForms()) {
  //         this.loadService.display(true);
  //         this.loading = true;
  //         this.actionDataProvider.sendActionData(
  //             BusinessTransactionHelper.createOffer([],
  //                 this.selectedPerson,
  //                 BusinessTransactionHelper.typeId.offerFillDocuments.from,
  //                 this.currentBranch,
  //                 this.selectedCompanies                )
  //         ).toPromise().then(value => {
  //             log.debug(`${this.CLASS_NAME}: Response on sendActionData ${value}`);
  //             this.loadService.display(false);
  //             this.loading = false;
  //             this.translateService.get("COMMONS.USERCOMMIT.SUCCESS")
  //                 .subscribe(result => {
  //                     this.snackBar.open(result, "x", UserCommitState.configSuccess);
  //                 });
  //             this.resetOfferWizardUserInputs();
  //         }).catch(reason => {
  //             log.debug(`${this.CLASS_NAME}: Response on sendActionData [${reason}]`);
  //             this.loadService.display(false);
  //             this.loading = false;
  //             this.translateService.get("COMMONS.USERCOMMIT.ERROR")
  //                 .subscribe(result => {
  //                     this.snackBar.open(result, "x", UserCommitState.configError);
  //                 });
  //             this.resetOfferWizardUserInputs();
  //         });
  //     } else {
  //         this.translateService.get("COMMONS.USERCOMMIT.CHECKDATA")
  //             .subscribe(result => {
  //                 this.snackBar.open(result, "x", UserCommitState.configCheckData);
  //             });
  //     }
  // }

  updateURL(selectedPerson: Person) {
    this.selectedPersonUuid = selectedPerson.uuid;
    const url = this.getUrl("Offers");
    this.location.replaceState(url);
    this.getFilledOfferData();
  }

  private _filter(value: string): Household[] {
    if (!!value) {
      const filterValue = value.toLowerCase();
      return this.householdsData.filter((option) =>
        option.householdName.toLowerCase().includes(filterValue)
      );
    }
    return [];
  }

  private _filterPerson(value: string): Person[] {
    if (!!value) {
      const filterValue = value.toLowerCase();
      return this.personsData.filter(
        (optionP) =>
          optionP.name.toLowerCase().includes(filterValue) ||
          optionP.firstName.toLowerCase().includes(filterValue)
      );
    }
    return [];
  }

  private getUrl(routerName: string) {
    return `/${this.registerProvider.getPath(routerName)}/${
      this.representativeUuid
    }/${
      this.representativeUuid === this.selectedPersonUuid
        ? ""
        : this.selectedPersonUuid
    }`;
  }

  selectionChangedBranch(event) {
    log.debug(`selectionChangedBranch() -> ${event.value}`);
    this.currentBranch = event.value;
    this.companies = this.branches[this.currentBranch];
    this.selectedCompanies = [];
    this.companiesControl.enable();
  }

  selectionChangedCompany(event) {
    this.selectedCompanies = event.value;
    log.debug(`selectionChangedCompany() -> ${event.value}`);
  }

  static VgrPersonJson = class {
    geschlecht: string;
    jahrgang: number;
  };

  static VgrAppendixDataJson = class {
    hauptPerson: typeof OfferWizardOldComponent.VgrPersonJson.prototype = new OfferWizardOldComponent.VgrPersonJson();
  };

  onEmitSquareIconLinkVGR(
    personBirthdate: string,
    personGenderMale: boolean = true
  ) {
    log.debug(`${this.TAG}; onEmitSquareIconLinkVGR();`);
    let destinationURI = `https://neo.swiss/vgr`;
    if (personBirthdate != undefined && personBirthdate.length > 0) {
      // extract year from date string
      let d = new Date(personBirthdate);
      log.debug(`${this.TAG}; onEmitSquareIconLinkVGR(); passedDate=${d}`);
      let personYearOfBirth = d.getFullYear();
      // map gender to vgr
      log.debug(
        `${this.TAG}; onEmitSquareIconLinkVGR(); isMale=${personGenderMale}`
      );
      let personGender = personGenderMale ? "Mann" : "Frau";
      // generate object that represents uri data
      let vgrAppendixData = new OfferWizardOldComponent.VgrAppendixDataJson();
      vgrAppendixData.hauptPerson.geschlecht = personGender;
      vgrAppendixData.hauptPerson.jahrgang = personYearOfBirth;
      log.debug(`${this.TAG}; vgrAppendixDataObject=${vgrAppendixData}`);
      // compress to encoded uri
      const json = JSON.stringify(vgrAppendixData);
      log.debug(`${this.TAG}; compressed=${json}`);
      const encodedJson = this.generateVgrUriAppendix(json);
      // setup final uri
      destinationURI = `${destinationURI}/${encodedJson}`;
    }
    // open vgr, optional with appendix
    window.open(destinationURI, "_black");
  }

  private generateVgrUriAppendix(s: string) {
    return compressToEncodedURIComponent(s);
  }
  paginatorValueChanged(event) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getFilledOfferData();
  }
}
