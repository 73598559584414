import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {LoadingDataComponent} from "../../core/components/loading-data.component";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {RegisterProvider} from "../../core/providers/register.provider";
import {MessagesProvider} from "../../core/providers/messages.provider";
import {Journal} from "app/modules/core/model/journal";
import {SlideRangeFilterValue} from "../../core/model/slide-range-filter-value";
import {forkJoin, Observable, Subscription, timer, of} from "rxjs";
import {DocumentProvider} from "../../core/providers/document.provider";
import {RestProviderActionsDummy} from "../../core/providers/rest.provider";
import {map, catchError} from "rxjs/operators";
import {DocumentResourceResult} from "app/modules/core/model/document-resource-result";
import * as moment from "moment";
import {ActionDialogService} from "../../core/providers/action-dialog.service";
import {ActionDialogData} from "../../core/components/action.dialog.component";
import {BusinessTransactionHelper} from "../../core/static/bt-helper";
import {TranslateService} from "@ngx-translate/core";
import {Title} from "@angular/platform-browser";
import {HttpErrorResponse} from "@angular/common/http";
import {log} from "app/modules/core/providers/logger.provider";
import { LoadingService } from "app/modules/core/providers/loading.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
    selector: "neomp-messages-page",
    templateUrl: "./messages-page.component.html",
    styleUrls: ["./messages-page.component.scss"]
})
export class MessagesPageComponent implements OnInit, OnDestroy, AfterViewInit {

    private readonly TAG = this.constructor.name;
    private readonly TIMERINTERVAL: number = 1000 * 60 * 15;

    messages: Journal[] = [];
    journalData: Map<string, DocumentResourceResult[]> = new Map<string, DocumentResourceResult[]>();
    @ViewChild("messagesTable") journalTableLoadingComponent: LoadingDataComponent;

    filterValue: SlideRangeFilterValue = new SlideRangeFilterValue();

    timerSubscription: Subscription;

    isInitialized: boolean;
    isDataLoading: boolean;
    isDialogOpen = false;

    relatedMessages: Journal[] = [];
    relatedMsgJournalData:  Map<string, DocumentResourceResult[]> = new Map<string, DocumentResourceResult[]>();


    constructor(public messagesProvider: MessagesProvider,
                public route: ActivatedRoute,
                public router: Router,
                public location: Location,
                public registerProvider: RegisterProvider,
                public documentProvider: DocumentProvider,
                public titleService: Title,
                public dialogService: ActionDialogService,
                private loadService: LoadingService,
                private dialog: MatDialog) {
        this.isInitialized = false;
        this.timerSubscription = timer(this.TIMERINTERVAL, this.TIMERINTERVAL)
            .subscribe(value => this.getMessages());
    }

    ngOnInit() {
        this.loadService.dialogClosed.subscribe((val: boolean) => {
            if(val === true){
                this.getMessages();
            }
        });
        this.filterValue.isActive = true;
        this.filterValue.dateRange.end = moment();
        this.filterValue.dateRange.begin = moment().subtract(60, "days");

        this.getMessages();
        
    }

    ngAfterViewInit(): void {
        this.isInitialized = true;
    }

    getElements(filterValue) {
        this.filterValue = filterValue;
        this.getMessages();
        this.relatedMessages = [];
        delete this.relatedMsgJournalData;
    }

    getMessages(): void {

        this.dataLoading = true;

        let messageObservable: Observable<Journal[]> = this.filterValue.isActive ?
            this.messagesProvider.getMessagesByDateRange(this.journalTableLoadingComponent, this.filterValue.dateRange) :
            this.messagesProvider.getMessages(this.journalTableLoadingComponent);

        messageObservable.subscribe(journals => {
            let journalData: Map<string, any> = new Map<string, any>();
            let subscriptions: Observable<any>[] = [];

            for (let journal of journals) {
                let references = journal.references.filter(reference => reference.type.key === "050");

                for (let reference of references) {
                    const subscription = this.documentProvider.getDocumentById(
                        reference.id,
                        new RestProviderActionsDummy())
                        .pipe(
                            map(result => ({id: journal.uuid, document: result})),
                            // do not let your observables die; forkJoin will the not be triggered then.
                            catchError(err => of(err))
                        );

                    subscriptions.push(subscription);
                }

                // type "MyNeoDocument" refers to a temporary document
                let tempDocumentReferences = journal.references.filter(reference => reference.type.key === "MyNeoDocument");
                for (let tempDocumentReference of tempDocumentReferences) {
                    let documentResource = new DocumentResourceResult()
                    documentResource.id = tempDocumentReference.id;
                    documentResource.fromMyNeo = true;
                    
                    subscriptions.push(of({id: journal.uuid, document: documentResource }));
                }
            }

            this.messages = journals;
            this.dataLoading = false;

            forkJoin(subscriptions).subscribe(results => {
                let journalData: Map<string, DocumentResourceResult[]> = new Map<string, DocumentResourceResult[]>();

                for (let result of results) {
                    if (result instanceof HttpErrorResponse) {
                        // do not let your observables die; forkJoin will the not be triggered.
                        log.error(`${this.TAG}; unexpected response of document request; msg=${result.message}`);
                    } else {
                        if (!journalData.has(result.id)) {
                            journalData.set(result.id, [result.document]);
                        } else {
                            journalData.get(result.id).push(result.document);
                        }
                    }
                }

                this.journalData = journalData;
            });
        }, error => {
            this.dataLoading = false;
        });
    }

    getRelatedMessagesEvent(event: any) {
        this.relatedMessages = [];
        delete this.relatedMsgJournalData;
        this.relatedMessages = event.relatedMessages;
        this.relatedMsgJournalData = event.relatedMsgJournalData;
    }

    set dataLoading(dataLoading: boolean) {
        if (this.isInitialized) {
            this.isDataLoading = dataLoading;
        }
    }

    ngOnDestroy(): void {
        this.timerSubscription.unsubscribe();
    }

    openActionDialog(event: Event) {
        this.isDialogOpen = true;
        event.stopPropagation();
        const dialogData: ActionDialogData = {
            transTypeId: BusinessTransactionHelper.typeId.message.from,
            transName: this.titleService.getTitle(),
            transSubject: "",
            transTitle: this.titleService.getTitle(),
            transMessage: "",
            keyValues: [],
            person: null
        };
        this.dialogService.openByType(dialogData, 
            {
                defaultTypeId: 
                BusinessTransactionHelper.typeId.message.from
            }, this.TAG
        );
        this.dialog.afterAllClosed.subscribe(() => {
            this.isDialogOpen = false;
        });
    }
}
