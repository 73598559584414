import { ILeistungFilter } from "../interfaces/ILeistungFilter";

export class LeistungFilter {
    public key: string;
    public wertung: number;
    public id: number;

    constructor(lf?: ILeistungFilter) {
        if (lf) {
            this.key = lf.key;
            this.wertung = lf.wertung;
        }
    }

    public static fromJson(response: ILeistungFilter[]): LeistungFilter[] | undefined {
        if (response) {
            return response.map(lf => new LeistungFilter(lf));
        }
    }

    public toJson() {
        const temp = new LeistungFilter();
        temp.key = this.key;
        temp.wertung = this.wertung;
        return temp;
    }
}


