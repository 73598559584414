import { Component, OnDestroy, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, RoutesRecognized } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { PaginationConfigs } from "app/modules/constants/PaginationConstants";
import { LoadingDataComponent } from "app/modules/core/components/loading-data.component";
import { AgenturType } from "app/modules/core/model/enum-agenturtype";
import { Household } from "app/modules/core/model/household";
import { Journal } from "app/modules/core/model/journal";
import { JournalList } from "app/modules/core/model/journal-list";
import { Person } from "app/modules/core/model/person";
import { CustomersCacheProvider } from "app/modules/core/providers/customers-cache.provider";
import { HouseholdProvider } from "app/modules/core/providers/household.provider";
import { LeadProvider } from "app/modules/core/providers/lead.provider";
import { LoadingService } from "app/modules/core/providers/loading.component";
import { RestProviderActions } from "app/modules/core/providers/rest.provider";
import * as moment from "moment";
import { format } from "date-fns";
import { KeycloakService } from "keycloak-angular";
import { Observable, Subscription } from "rxjs";
import { LEADS_CONFIGS } from "../../configs/lead-configs";
import { LeadsService } from "../../services/leads.service";
import { AppointmentProvider } from 'app/modules/core/providers/appointment.provider';
import { UserCommitState } from "app/modules/core/model/user-commit-state";
import { ActionData } from "app/modules/core/model/action-data";
import { ConfigProvider } from "app/modules/core/providers/config.provider";
import { Config } from "app/modules/core/model/config";
import { PageEvent } from "@angular/material/paginator";
import { MatTabChangeEvent, MatTabGroup } from "@angular/material/tabs";
import { MatSnackBar } from "@angular/material/snack-bar";
import { LeadQueryParams } from "app/modules/core/json/leadQueryParams.json";
import { distinctUntilChanged } from "rxjs/operators";
import { BusinessTransactionHelper } from "app/modules/core/static/bt-helper";
import { KeyValue } from "app/modules/core/model/key-value";
import { AdvisorDataService } from "app/modules/core/services/advisor-data.service";
import { Helper } from "app/modules/core/static/helper";


@Component({
  selector: "neomp-leads-navbar",
  templateUrl: "./leads-navbar.component.html",
  styleUrls: ["./leads-navbar.component.scss"],
})
export class LeadsNavbarComponent implements OnInit, OnDestroy, AfterViewInit {
  subscriptions: Subscription[] = [];

  private readonly TERMIN_LEADS_REMINDERS = LEADS_CONFIGS.typeId.LEADS.CALLBACK;
  private readonly TERMIN_REMINDERS = LEADS_CONFIGS.typeId.TERMIN.CALLBACK;


  pageSize = PaginationConfigs.pageSize;
  pageSizeOptions = PaginationConfigs.pageSizeOptions;
  pageIndex = PaginationConfigs.pageIndex;
  totalCount = 0;
  totalCountLeads = 0;
  totalCountAppointments = 0;
  totalTerminReminders = 0;
  totalTerminLeadsReminders = 0;
  totalTerminLeadsRemindersCalendar = 0;
  households: Household[] = [];
  selectedLeadTab: PageEvent;
  leads: Person[] = [];
  appointments: Person[] = [];
  unassignedAppointmentsCalendar: Person[] = [];
  leadsWithBT: Person[] = [];
  agents: Person[] = [];
  terminLeadsReminders: Journal[] = [];
  terminReminders: Journal[] = [];
  terminLeadsRemindersCalendar: Journal[] = [];
  allStatuses: KeyValue[] = [];
  statusesList: string[] = [];
  substatusesList: any[] = [];
  statusSelected = new FormControl("");
  substatusSelected = new FormControl("");
  agentSelectedControl = new FormControl("");

  agenturType: string = "";
  agenturName: string = "";
  advisorId: string = "";
  agenturId: string = "";
  isHighRole: boolean = true;
  dateFrom: any;
  dateTo: any;
  searchText: string | null = null;
  remindersIndex: number = 0;
  totalCountTask = 0;
  totalNeuLeads = 0;

  allCategories: KeyValue[] = [];
  categoriesList:string[]=[]
  categoriesSelected= new FormControl("")
  terminStatusSelected= new FormControl("")
  terminSubstatusSelected= new FormControl("")
  terminTypesSelected= new FormControl("")
  terminCategoriesSelected= new FormControl("")
  lead_leadCategory = [];
  completedTasks: boolean;

  isSupplier: boolean = false;
  username: string = "";

  @ViewChild("loadingFamilyTable") loadingFamilyTable: LoadingDataComponent;
  @ViewChild("tabGroup") tabGroup: MatTabGroup;
  selectedIndexTab = -1;

  constructor(
    public appointmentProvider: AppointmentProvider,
    public householdProvider: HouseholdProvider,
    public leadProvider: LeadProvider,
    public leadService: LeadsService,
    private configProvider: ConfigProvider,
    public customersCacheProvider: CustomersCacheProvider,
    public translateService: TranslateService,
    private keycloak: KeycloakService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private leadsService: LeadsService,
    private loadingService: LoadingService,
    private advisorDataService: AdvisorDataService,
    private snackBar: MatSnackBar
  ) { }

  async ngOnInit(): Promise<void> {
    this.keycloak.loadUserProfile().then((res: any) => {
      this.username = res?.username;
      this.agenturType = res.attributes.agenturType[0];
      this.agenturName = res.attributes.agenturName[0];
      this.advisorId = res.attributes.advisorid[0];
      this.agenturId = res.attributes.agenturID[0];
      this.isHighRole = (this.agenturType === AgenturType.SalesManager || this.agenturType === AgenturType.GeneralAgent || this.agenturType === AgenturType.TeamLead);
      this.isSupplier = this.agenturType === AgenturType.Supplier;
      this.getTasks(0, 15, this.getDateFilter(), undefined) // Removed "this.agenturType ||" to not brake the fetch totalCount (Before it was the first line on ngOnInit and it always sent it as undefined (null does not work)) | this is a quick fix
    });
     this.leadProvider.getAttributeValues().subscribe(attributeValues => {
      this.categoriesList= attributeValues?.leadCategories || [];
    });
    this.agents = await this.getAgents();

    this.getConfigs();
  }

  async ngAfterViewInit() {
    //In case the user comes back, remember the old search filters
    await this.route.queryParams
      .pipe(distinctUntilChanged((prev: any, curr: LeadQueryParams) => JSON.stringify(prev) === JSON.stringify(curr)))
      .subscribe((params: any) => {
        if (params.st) {

          this.searchFromParams(params);
          this.dateFrom = format(new Date(this.route.snapshot.queryParams.dateFrom.toString()), "YYYY-MM-DDT00:00:00.000")
          this.dateTo = format(new Date(this.route.snapshot.queryParams.dateTo.toString()), "YYYY-MM-DDT23:59:00.000")
        } else {
          const queryParamObj = {
            dateFrom: this.dateFrom == "Invalid Date" ? "" : this.dateFrom,
            dateTo: this.dateTo == "Invalid Date" ? "" : this.dateTo,
            advisor: this.agentSelectedControl.value,
            status: this.statusSelected.value,
            text: this.searchText,
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            lead_leadCategory:this.categoriesSelected.value
          };
          
          this.updateQueryParams('/leads', 'leads', queryParamObj)
        }
      });
  }


  getDateFilter(): string | null {
    if (this.dateFrom === undefined && this.dateTo === undefined) {
      return null;
    } else {
      const dateFrom = this.dateFrom == undefined ? format(new Date(1900, 0, 0), "YYYY-MM-DDT00:00:00.000") : format(new Date(this.dateFrom.toString()), "YYYY-MM-DDT00:00:00.000");
      const dateTo = this.dateTo == undefined ? format(new Date(), "YYYY-MM-DDT23:59:00.000") : format(new Date(this.dateTo.toString()), "YYYY-MM-DDT23:59:00.000")
      return `filter={"type":"DateTime","key":"creationDate","from":"${dateFrom}","to":"${dateTo}"}`;
    }
  }

  assignAgentsToLeads(lead: Person) {
    const leadAgent = this.agents.find(x => x.uuid == lead.lead.termin_berater_shortcut);
    const agentName = leadAgent ? leadAgent.fullName : this.agenturName;
    if (agentName) {
      lead.sorKeys["agent"] = agentName;
      return lead;
    } else if (this.isSupplier) {
      lead.sorKeys["agent"] = "";
      return lead;
    }
    return null;
  }

  searchFromParams(params: LeadQueryParams) {
    this.dateFrom = params.dateFrom || undefined;
    this.dateTo = params.dateTo || undefined;
    this.searchText = params.text || "";
    this.agentSelectedControl.setValue(params.advisor);
    let statusesList = params.status as any;
    if (typeof params.status === 'string') {
      statusesList = params.status.split(",");
    }
    this.statusSelected.setValue(statusesList);

    let leadSubstatusesList = params.lead_substatusList as any;
    if (typeof params.lead_substatusList === 'string') {
      leadSubstatusesList = params.lead_substatusList.split(",");
    }
    this.substatusSelected.setValue(leadSubstatusesList);
    
    // Get substatus list for selected status
    this.substatusesList = this.getSubstatuses(this.statusSelected.value);

    let categoriesList = params.lead_leadCategory as any;
    if (typeof params.lead_leadCategory === 'string') {
      categoriesList = params.lead_leadCategory.split(",");
    }
    this.categoriesSelected.setValue(categoriesList);

    let terminStatusesList = params.terminStatus as any;
    if (typeof params.terminStatus === 'string') {
      terminStatusesList = params.terminStatus.split(",");
    }
    this.terminStatusSelected.setValue(terminStatusesList);

    let terminSubstatusesList = params.lead_terminSubstatusList as any;
    if (typeof params.lead_terminSubstatusList === 'string') {
      terminSubstatusesList = params.lead_terminSubstatusList.split(",");
    }
    this.terminSubstatusSelected.setValue(terminSubstatusesList);

    let terminTypesList = params.lead_terminType as any;
    if (typeof params.lead_terminType === 'string') {
      terminTypesList = params.lead_terminType.split(",");
    }
    this.terminTypesSelected.setValue(terminTypesList);

    let terminCategoriesList = params.lead_terminCategory as any;
    if (typeof params.lead_terminCategory === 'string') {
      terminCategoriesList = params.lead_terminCategory.split(",");
    }
    this.terminCategoriesSelected.setValue(terminCategoriesList);


    this.pageIndex = params.pageIndex || PaginationConfigs.pageIndex;
    this.pageSize = Number(params.pageSize) > 0 ? Number(params.pageSize) : PaginationConfigs.pageSize;
    // this.lead_leadCategory = params.lead_leadCategory?.split(",") || [];
    if (params.st == 'leads') {
      this.tabGroup.selectedIndex = 0;
      // "true" loads leads from db
      this.searchLeads(true);
    }

    if (params.st == 'termin') {
      this.selectedIndexTab = 1;
    }

    if (params.st == 'calendar') {
      this.tabGroup.selectedIndex = 2;
      this.loadAppointments();
      if (this.agenturType === AgenturType.GeneralAgent) {
        this.loadAppointmentsForCalendar();
      }
    }

    if (params.st == 'lead-reminders') {
      this.tabGroup.selectedIndex = 3;
      this.remindersIndex = 0;
      this.getTerminLeadsReminder(true);
    }

    if (params.st == 'termin-reminders') {
      this.tabGroup.selectedIndex = 3;
      this.remindersIndex = 1;
      this.getTerminReminders(true);
    }

    if (params.st == 'tasks') {
      this.completedTasks= params.completedTasks === "true" || params.completedTasks === true ? true : false;
      // tasks are lazy loaded
      this.tabGroup.selectedIndex = 4;
    }

  }


  reminderTabChanged(index: number): void {
    const queryParamObj = {
      dateFrom: this.dateFrom == "Invalid Date" ? "" : this.dateFrom,
      dateTo: this.dateTo == "Invalid Date" ? "" : this.dateTo,
      advisor: this.agentSelectedControl.value,
      status: this.statusSelected.value,
      text: this.searchText,
      lead_leadCategory:this.categoriesSelected.value,
      pageIndex: 0,
      pageSize: this.pageSize
    };

    const st = index === 0 ? 'lead-reminders' : 'termin-reminders';
    this.updateQueryParams('/leads', st, queryParamObj)

  }


  getAgents(): Promise<Person[]> {
    return this.advisorDataService.getAgents();
  }

  getTasks(page: number, size: number, filters?: string, agentId?: string): void {
    const sort = { "status.name": 1, "effectiveDate": -1 };
    if (this.isSupplier) {
      this.leadService.getJournalsReferencedWithPaginationForSupplier(
        BusinessTransactionHelper.typeId.tasks.from,
        BusinessTransactionHelper.typeId.tasks.to,
        page,
        size,
        sort,
        filters,
        agentId,
        this.completedTasks ?? false
      ).subscribe((res: { data: JournalList[], totalCount: number }) => {
        this.totalCountTask = res.totalCount
      });
    } else {
      this.leadService.getJournalsReferencedWithPagination(
        BusinessTransactionHelper.typeId.tasks.from,
        BusinessTransactionHelper.typeId.tasks.to,
        page,
        size,
        sort,
        filters,
        agentId,
        this.completedTasks ?? false
      ).subscribe((res: { data: JournalList[], totalCount: number }) => {
        this.totalCountTask = res.totalCount
      });
    }
  }

  loadLeads(
    restProviderActions: RestProviderActions,
    page: number,
    size: number,
    filter?: string) {
    this.leads = [];
    filter = filter != "" ? filter + '&isManagement=false' : 'isManagement=false';
    this.subscriptions.push(
      this.leadProvider
        .getLazyLeads(restProviderActions, page, size, filter)
        .subscribe((leads: { data: Person[]; totalCount: number }) => {
          this.totalCountLeads = leads.totalCount;
          this.leadsWithBT = [];
          if (this.agents.length > 0) {
            this.leads = leads.data.map((lead) =>
              this.assignAgentsToLeads(lead)
            );
            this.leadService.unassignedNeuLeadsCount.subscribe((res: any) => {
              this.totalNeuLeads = res;
            });
          } else {
            this.leads = leads.data;
          }

          this.leadService.updateListOfSwipeableLeads(this.leads, page, size, filter, leads.totalCount);

        }))
  }

  loadAppointments() {
    setTimeout(() => {
      // triggered resize to fix Fullcalendar broken UI issue 
      window.dispatchEvent(new Event('resize'));
    }, 100);
    this.loadingService.display(true);
    this.appointmentProvider
      .getAppointmentsForCalendar()
      .subscribe((leads: { data: Person[]; totalCount: number }) => {
        this.totalCountAppointments = leads.totalCount;
        if (this.agents.length > 0) {
          this.appointments = leads.data.map(lead => this.assignAgentsToLeads(lead));
          this.loadingService.display(false);
        }
        else {
          this.appointments = leads.data;
        }
      });
  }

  loadAppointmentsForCalendar() {
    setTimeout(() => {
      // triggered resize to fix Fullcalendar broken UI issue 
      window.dispatchEvent(new Event('resize'));
    }, 100);
    this.loadingService.display(true);

      this.appointmentProvider
      .getUnassignedAppointmentsForCalendar()
      .subscribe((appointments: { data: Person[]; totalCount: number }) => {
        this.totalCountAppointments = appointments.totalCount;
         if(this.agents.length > 0){
          this.unassignedAppointmentsCalendar = appointments.data.map(lead => this.assignAgentsToLeads(lead));
          this.loadingService.display(false);
        }
        else{
          this.unassignedAppointmentsCalendar = appointments.data;
        }
      });
  }

  leadsPaginatorValueChanged(event: PageEvent) {
    if (event.pageIndex !== this.pageIndex || event.pageSize !== this.pageSize) {
      this.pageIndex = event.pageIndex;
      this.pageSize = event.pageSize;
      this.searchLeads();
    }
  }

  terminRemindersValueChanged(event: PageEvent): void {
    if (event.pageIndex !== this.pageIndex || event.pageSize !== this.pageSize) {
      this.pageIndex = event.pageIndex;
      this.pageSize = event.pageSize;

      const queryParamObj = {
        dateFrom: this.dateFrom == "Invalid Date" ? "" : this.dateFrom,
        dateTo: this.dateTo == "Invalid Date" ? "" : this.dateTo,
        advisor: this.agentSelectedControl.value,
        status: this.statusSelected.value,
        text: this.searchText,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        lead_leadCategory:this.categoriesSelected.value
      };

      this.updateQueryParams('/leads', this.route.snapshot.queryParams.st, queryParamObj)
    }
  }

  leadReminderPaginatorValueChanged(event: PageEvent): void {

    if (event.pageIndex !== this.pageIndex || event.pageSize !== this.pageSize) {
      this.pageIndex = event.pageIndex;
      this.pageSize = event.pageSize;

      const queryParamObj = {
        dateFrom: this.dateFrom == "Invalid Date" ? "" : this.dateFrom,
        dateTo: this.dateTo == "Invalid Date" ? "" : this.dateTo,
        advisor: this.agentSelectedControl.value,
        status: this.statusSelected.value,
        text: this.searchText,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        lead_leadCategory:this.categoriesSelected.value

      };

      this.updateQueryParams('/leads', this.route.snapshot.queryParams.st, queryParamObj)

    }
  }

  // This function is called when route parameters change
  searchLeads(loadLeads = false) {
    var isRetention = false;
    var statusSelected = Array.isArray(this.statusSelected.value) ? this.statusSelected.value.join(',') : "";
    var substatusSelected = Array.isArray(this.substatusSelected.value) ? this.substatusSelected.value.join(',') : "";
    var categoriesSelected = Array.isArray(this.categoriesSelected.value) ? this.categoriesSelected.value.join(',') : "";
    const dateFrom = this.dateFrom == undefined || this.dateFrom == "Invalid Date" ? "" : format(new Date(this.dateFrom.toString()), "YYYY-MM-DDT00:00:00.000")
    const dateTo = this.dateTo == undefined || this.dateTo == "Invalid Date" ? "" : format(new Date(this.dateTo.toString()), "YYYY-MM-DDT23:59:00.000")
    let filter = this.agentSelectedControl.value != "" && this.agentSelectedControl.value != undefined ?
      `dateFrom=${dateFrom}&dateTo=${dateTo}&advisor=${this.agentSelectedControl.value}&status=${statusSelected}&lead_substatusList=${substatusSelected}&lead_leadCategory=${categoriesSelected}&isRetention=${isRetention}` :
      `dateFrom=${dateFrom}&dateTo=${dateTo}&status=${statusSelected}&lead_substatusList=${substatusSelected}&lead_leadCategory=${categoriesSelected}&isRetention=${isRetention}`

    if (![null, undefined, ""].includes(this.searchText)) {
      filter += `&text=${this.searchText}`
    }

    // Load leads from db
    if (loadLeads) {
      this.loadLeads(
        this.loadingFamilyTable,
        this.pageIndex,
        this.pageSize,
        filter
      );
    }    

    const queryParamObj = {
      dateFrom,
      dateTo,
      advisor: this.agentSelectedControl.value,
      status: this.statusSelected.value,
      lead_substatusList: this.substatusSelected.value,
      text: this.searchText,
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      lead_leadCategory:this.categoriesSelected.value

    }

    this.updateQueryParams('/leads', this.route.snapshot.queryParams.st, queryParamObj);
  }
  clearSearch() {
    this.searchText = '';
    this.dateFrom = null;
    this.dateTo = null;
    this.agentSelectedControl.reset();
    this.statusSelected.reset();
    this.substatusSelected.reset();
    this.substatusesList = [];
    this.lead_leadCategory = [];
    this.categoriesSelected.reset();
    this.searchLeads();
  }

  getTerminLeadsReminder(loadLeadsReminders?: boolean): void {
    const sort = { "status.name": 1, "effectiveDate": -1 };
    let filter = this.getDateFilterQuery(this.dateFrom, this.dateTo);

    if (this.agentSelectedControl.value != "" && this.agentSelectedControl.value != undefined) {
      filter = this.getDateFilterQuery(this.dateFrom, this.dateTo) + this.getAgentFilterQuery(this.agentSelectedControl.value);
    }

    if (loadLeadsReminders) {
      this.subscriptions.push(
        this.leadService.getJournals(
          this.pageIndex,
          this.pageSize,
          this.TERMIN_LEADS_REMINDERS,
          this.TERMIN_LEADS_REMINDERS,
          sort,
          filter
        ).subscribe((res: { data: Journal[], totalCount: number }) => {
          this.terminLeadsReminders = res.data;
          this.totalTerminLeadsReminders = res.totalCount;
        })
      );
    }

    const queryParamObj = {
      dateFrom: this.dateFrom,
      dateTo: this.dateTo,
      advisor: this.agentSelectedControl.value,
      status: this.statusSelected.value,
      lead_substatusList: this.substatusSelected.value,
      text: this.searchText,
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      lead_leadCategory:this.categoriesSelected.value
    }

    this.updateQueryParams('/leads', this.route.snapshot.queryParams.st, queryParamObj)
  }

  getTerminReminders(loadTerminReminders?: boolean): void {
    let filter = this.getDateFilterQuery(this.dateFrom, this.dateTo);
    if (this.agentSelectedControl.value != "" && this.agentSelectedControl.value != undefined) {
      filter = this.getDateFilterQuery(this.dateFrom, this.dateTo) + this.getAgentFilterQuery(this.agentSelectedControl.value);
    }

    const sort = { "status.name": 1, "effectiveDate": -1 };
    if (loadTerminReminders) {
      this.subscriptions.push(
        this.leadService.getJournals(
          this.pageIndex,
          this.pageSize,
          this.TERMIN_REMINDERS,
          this.TERMIN_REMINDERS,
          sort,
          filter
        ).subscribe((res: { data: Journal[], totalCount: number }) => {
          this.terminReminders = res.data;
          this.totalTerminReminders = res.totalCount;
        })
      );
    }

    const queryParamObj = {
      dateFrom: this.dateFrom,
      dateTo: this.dateTo,
      advisor: this.agentSelectedControl.value,
      status: this.statusSelected.value,
      lead_substatusList: this.substatusSelected.value,
      text: this.searchText,
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      lead_leadCategory:this.categoriesSelected.value
    }

    this.updateQueryParams('/leads', this.route.snapshot.queryParams.st, queryParamObj)
  }

  getLeadRemindersForCalendar(fromDate = moment().add(-2, "days").toDate()): void {
    this.subscriptions.push(
      this.leadService.getJournalsReferenced(
        this.TERMIN_LEADS_REMINDERS,
        this.TERMIN_REMINDERS,
        this.getDateFilterQuery(fromDate, moment().add(7, "days").toDate())// + (this.advisorId ? this.getAgentFilterQuery(this.advisorId) : '')
      ).subscribe((res: { data: JournalList[], totalCount: number }) => {
        this.terminLeadsRemindersCalendar = res.data.map(x => x.transaction);
        this.totalTerminLeadsRemindersCalendar = res.totalCount;
      })
    );
  }

  getConfigs() {
    this.configProvider.getConfig().subscribe((config: Config[]) => {
      this.allStatuses = config.filter(x =>
        Number(x.key) >= Number("4110") &&
        Number(x.key) <= Number("4116"))
        .map(y => {
          return {
            id: y.key,
            key: y.values[0].key,
            value: y.values[0].value
          };
        });
      this.allStatuses.sort((a, b) => {
        return Number(a.id) - Number(b.id)
      })
      this.statusesList = this.allStatuses.map(x => x.key);

      // Get substatus list for selected status
      this.substatusesList = this.getSubstatuses(this.statusSelected.value);
    })
  }

  statusesSelected(event) {
    this.substatusesList = [];

    event.value.forEach(status => {
      this.substatusesList.push(...this.getSubstatuses(status));
    });
    this.substatusSelected.reset();
  }

  getSubstatuses(status) {
    let substatuses = [];
    let statuses = Array.isArray(status) ? 
      this.allStatuses.filter(x => status.includes(x.key)) :
      this.allStatuses.filter(x => x.key === status);

    statuses.forEach(y => {
      var obj = {};
      obj['title'] = status;
      obj['value'] = y.value.split(',');
      substatuses.push(obj);
    });

    return substatuses;
  }

  onLeadReminderClick(journal: Journal): void {
    this.router.navigate([`/lead/${journal.operationIdExternal || journal.classification.id}/edit`]);
  }

  onDeleteReminder(reminder: Journal) {
    if (confirm(this.translate.instant("DIALOG.CONFIRMATION.DELETE") + reminder.descriptionField + " ?")) { // S3T: TODO: proper confirmation dialog.
      this.loadingService.display(true);
      const updateTaskBT: ActionData = new ActionData(new Journal(), [], null, null);
      updateTaskBT.transaction = Journal.fromJson(reminder);
      updateTaskBT.transaction.status.completed = true;
      updateTaskBT.transaction.status.name = "Abgeschlossen";
      updateTaskBT.transaction.status.updated = moment().format('YYYY-MM-DDTHH:mm:ss.SSSZZ');

      this.leadsService.sendLeadAction(updateTaskBT).subscribe(() => {
        this.loadingService.setDialogClosed(true);
        this.snackBar.open(this.translate.instant("COMMONS.USERCOMMIT.SUCCESS"), "X", UserCommitState.configSuccess);

        if (reminder.typeId === this.TERMIN_LEADS_REMINDERS) {
          this.getTerminLeadsReminder();
          this.loadingService.display(false);
        }
        else if (reminder.typeId === this.TERMIN_REMINDERS) {
          this.getTerminReminders();
          this.loadingService.display(false);
        }
      }, err => {
        this.loadingService.display(false);
        this.snackBar.open(this.translate.instant("COMMONS.USERCOMMIT.ERROR"), "X", UserCommitState.configError);
      })
    }
  }

  onTerminReminderClick(journal: Journal): void {
    this.router.navigate([`/termine/${journal.operationIdExternal}/edit`]);
  }

  tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    const queryParamObj = {
      dateFrom: "",
      dateTo: "",
      advisor: "",
      status: '',
      text: "",
      pageIndex: 0,
      pageSize: this.pageSize,
      lead_leadCategory:"",
      completedTasks:this.completedTasks
    };
    
    let st = 'default';

    if (tabChangeEvent.index == 0) {
      st = 'leads';
    }
    if (tabChangeEvent.index == 1) {
      st = 'termin';
      queryParamObj.dateFrom = this.dateFrom;
      queryParamObj.dateTo = this.dateTo;
      queryParamObj.text = this.searchText;
      queryParamObj.advisor = this.agentSelectedControl.value;
      queryParamObj.lead_leadCategory=this.categoriesSelected.value;
      queryParamObj['lead_terminType'] = this.terminTypesSelected.value;
      queryParamObj['lead_terminCategory'] = this.terminCategoriesSelected.value;
      queryParamObj['terminStatus'] = this.terminStatusSelected.value;
      queryParamObj['lead_terminSubstatusList'] = this.terminSubstatusSelected.value;
    }
    if (tabChangeEvent.index == 2) {
      st = 'calendar';
    }
    if (tabChangeEvent.index == 3) {
      st = !this.isSupplier ? this.remindersIndex === 0 ? 'lead-reminders' : 'termin-reminders' : 'tasks';
      if (this.route.snapshot.queryParams.st == st) {
        queryParamObj.dateFrom = this.dateFrom;
        queryParamObj.dateTo = this.dateTo;
        queryParamObj.advisor = this.agentSelectedControl.value;
      }
    }
    if (tabChangeEvent.index == 4) {
      st = 'tasks'
      if (this.route.snapshot.queryParams.st == st) {
        queryParamObj.dateFrom = Helper.isDateValid(this.dateFrom) ? this.dateFrom : "";
        queryParamObj.dateTo = Helper.isDateValid(this.dateTo) ? this.dateTo: "";
        queryParamObj.text = this.searchText;
        queryParamObj.completedTasks=this.completedTasks;
        queryParamObj.advisor = this.agentSelectedControl.value;
        queryParamObj.status = null;
      }
    }
    this.completedTasks=undefined;
    this.searchText = "";
    this.substatusSelected.setValue('');

    this.updateQueryParams('/leads', st, queryParamObj);
  }

  updateQueryParams(path: string, st: string, queryParams: LeadQueryParams): void {

    this.router.navigate([path], {
      queryParams: {
        st,
        dateFrom: queryParams?.dateFrom || "",
        dateTo: queryParams?.dateTo || "",
        advisor: queryParams?.advisor,
        text: queryParams?.text || this.searchText || "",
        status: queryParams?.status,
        lead_substatusList: queryParams?.lead_substatusList,
        lead_leadCategory:queryParams?.lead_leadCategory || this.categoriesSelected.value,
        terminStatus: queryParams?.terminStatus,
        lead_terminSubstatusList: queryParams?.lead_terminSubstatusList,
        lead_terminCategory: queryParams?.lead_terminCategory,
        lead_terminType: queryParams?.lead_terminType,
        completedTasks: queryParams?.completedTasks || this.completedTasks,
        pageIndex: queryParams?.pageIndex,
        pageSize: queryParams?.pageSize
      }
    });
  }

  getDateFilterQuery(fromDate: any, toDate: any): string | null {
    const dateFrom = fromDate == undefined ? format(new Date(1900, 0, 0), "YYYY-MM-DD") : format(new Date(fromDate.toString()), "YYYY-MM-DDT00:00:00.000");
    const dateTo = toDate == undefined ? format(new Date(), "YYYY-MM-DD") : format(new Date(toDate.toString()), "YYYY-MM-DDT23:59:00.000")
    return `&filter={"type":"DateTime","key":"creationDate","from":"${dateFrom}","to":"${dateTo}"}`;
  }

  getAgentFilterQuery(agentID): string | null {
    return `&filter={"type":"FixedValue","key":"references.id","value":"${agentID}"}`;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
  }
}
