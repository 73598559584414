<table [dataSource]="dataSource"
       class="msg-helper-width-100 neo-content-wrapper"
       mat-table
       matSort
       multiTemplateDataRows>

    <!-- columns -->
    <ng-container matColumnDef="policyId">
        <th *matHeaderCellDef mat-header-cell
            mat-sort-header>{{ 'PAGE.CONTAINER.COMMISSIONS.TABLES.PROVISIONS.POLICYID' | translate }}
        </th>
        <td mat-cell *matCellDef="let element"> {{element.policyId}}</td>
    </ng-container>
    <ng-container matColumnDef="creationDate">
        <th *matHeaderCellDef mat-header-cell
            mat-sort-header>{{ 'PAGE.CONTAINER.COMMISSIONS.TABLES.PROVISIONS.CREATIONDATE' | translate }}
        </th>
        <td mat-cell *matCellDef="let element"> {{formatDate(element.creationDate)}}</td>
    </ng-container>
    <ng-container matColumnDef="sparte">
        <th *matHeaderCellDef mat-header-cell
            mat-sort-header>{{ 'PAGE.CONTAINER.COMMISSIONS.TABLES.PROVISIONS.SPARTE' | translate }}
        </th>
        <td mat-cell *matCellDef="let element"> {{element.sparte}}</td>
    </ng-container>
    <ng-container matColumnDef="riskCarrier">
        <th *matHeaderCellDef mat-header-cell
            mat-sort-header>{{ 'PAGE.CONTAINER.COMMISSIONS.TABLES.PROVISIONS.COMPANY' | translate }}
        </th>
        <td mat-cell *matCellDef="let element"> {{element.riskCarrier}}</td>
    </ng-container>
    <ng-container matColumnDef="policyNumber">
        <th *matHeaderCellDef mat-header-cell
            mat-sort-header>{{ 'PAGE.CONTAINER.COMMISSIONS.TABLES.PROVISIONS.POLICYNUMBER' | translate }}
        </th>
        <td mat-cell *matCellDef="let element"> {{element.policyNumber}}</td>
    </ng-container>
    <ng-container matColumnDef="customerName">
        <th *matHeaderCellDef mat-header-cell
            mat-sort-header>{{ 'PAGE.CONTAINER.COMMISSIONS.TABLES.PROVISIONS.CUSTOMER' | translate }}
        </th>
        <td mat-cell *matCellDef="let element"> {{element.customerName}}</td>
    </ng-container>
    <ng-container matColumnDef="customerBirthday">
        <th *matHeaderCellDef mat-header-cell
            mat-sort-header>{{ 'PAGE.CONTAINER.COMMISSIONS.TABLES.PROVISIONS.CUSTOMERBIRTH' | translate }}
        </th>
        <td mat-cell *matCellDef="let element"> {{formatDate(element.customerBirthday)}}</td>
    </ng-container>
    <ng-container matColumnDef="typeName">
        <th *matHeaderCellDef mat-header-cell
            mat-sort-header>{{ 'PAGE.CONTAINER.COMMISSIONS.TABLES.PROVISIONS.TRANSACTIONNAME' | translate }}
        </th>
        <td mat-cell *matCellDef="let element"> {{element.typeName}}</td>
    </ng-container>
    <ng-container matColumnDef="descriptionField">
        <th *matHeaderCellDef mat-header-cell
            mat-sort-header>{{ 'PAGE.CONTAINER.COMMISSIONS.TABLES.PROVISIONS.DESCRIPTIONFIELD' | translate }}
        </th>
        <td mat-cell *matCellDef="let element"> {{element.descriptionField}}</td>
    </ng-container>
    <ng-container matColumnDef="reasonForChange">
        <th *matHeaderCellDef mat-header-cell
            mat-sort-header>{{ 'PAGE.CONTAINER.COMMISSIONS.TABLES.PROVISIONS.REASONFORCHANGE' | translate }}
        </th>
        <td mat-cell *matCellDef="let element"> {{element.reasonForChange}}</td>
    </ng-container>
    <ng-container matColumnDef="filter">
        <th *matHeaderCellDef mat-header-cell
            (click)="changeFilterView()"
            class="mat-header-cell mat-column-filter {{isFilterActive ? 'mat-column-filter-active':''}}">
            <div
                class="neomp-table-column-filter-element mdi {{isFilterActive ? 'mdi-filter':'mdi-filter-outline'}}"></div>
        </th>
        <td *matCellDef="let element" mat-cell class="mat-column-filter-action">
            <div class="neomp-table-column-filter-element mdi mdi-plus"
            [disabled]="isDialogOpen"
            (click)="isDialogOpen ? null : openActionDialog($event, element)"></div>
        </td>
        <td mat-cell *matCellDef="let element"></td>
    </ng-container>

    <!-- filters -->
    <ng-container matColumnDef="policyIdFilter">
        <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter" floatLabel="never">
                <input matInput
                       [formControl]="policyIdFilter"
                       placeholder="{{ 'TABLES.FILTERPLACEHOLDER.ID' | translate }}">
            </mat-form-field>
        </th>
    </ng-container>
    <ng-container matColumnDef="creationDateFilter">
        <th mat-header-cell *matHeaderCellDef>
            <neomp-date-picker [setDefaultValues]="false" (filterValue)="creationDateFilterChanged($event)"></neomp-date-picker>
        </th>
    </ng-container>
    <ng-container matColumnDef="sparteFilter">
        <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter" floatLabel="never">
                <input matInput
                       [formControl]="sparteFilter"
                       placeholder="{{ 'TABLES.FILTERPLACEHOLDER.SECTION' | translate }}">
            </mat-form-field>
        </th>
    </ng-container>
    <ng-container matColumnDef="riskCarrierFilter">
        <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter" floatLabel="never">
                <input matInput
                       [formControl]="riskCarrierFilter"
                       placeholder="{{ 'TABLES.FILTERPLACEHOLDER.COMPANY' | translate }}">
            </mat-form-field>
        </th>
    </ng-container>
    <ng-container matColumnDef="policyNumberFilter">
        <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter" floatLabel="never">
                <input matInput
                       [formControl]="policyNumberFilter"
                       placeholder="{{ 'TABLES.FILTERPLACEHOLDER.CUSTOMER_NUMBER' | translate }}">
            </mat-form-field>
        </th>
    </ng-container>
    <ng-container matColumnDef="customerNameFilter">
        <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter" floatLabel="never">
                <input matInput
                       [formControl]="customerNameFilter"
                       placeholder="{{ 'TABLES.FILTERPLACEHOLDER.NAME' | translate }}">
            </mat-form-field>
        </th>
    </ng-container>
    <ng-container matColumnDef="customerBirthdayFilter">
        <th mat-header-cell *matHeaderCellDef>
            <neomp-date-picker [setDefaultValues]="false" (filterValue)="customerBirthdayFilterChanged($event)"> </neomp-date-picker>
        </th>
    </ng-container>
    <ng-container matColumnDef="typeNameFilter">
        <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter" floatLabel="never">
                <input matInput
                       [formControl]="typeNameFilter"
                       placeholder="{{ 'TABLES.FILTERPLACEHOLDER.TYPE' | translate }}">
            </mat-form-field>
        </th>
    </ng-container>
    <ng-container matColumnDef="descriptionFieldFilter">
        <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter" floatLabel="never">
                <input matInput
                       [formControl]="descriptionFieldFilter"
                       placeholder="{{ 'TABLES.FILTERPLACEHOLDER.DESCRIPTION' | translate }}">
            </mat-form-field>
        </th>
    </ng-container>
    <ng-container matColumnDef="reasonForChangeFilter">
        <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter" floatLabel="never">
                <input matInput
                       [formControl]="reasonForChangeFilter"
                       placeholder="{{ 'TABLES.FILTERPLACEHOLDER.AMOUNT' | translate }}">
            </mat-form-field>
        </th>
    </ng-container>
    <ng-container matColumnDef="filterRemove">
        <th mat-header-cell *matHeaderCellDef (click)="resetFilter()">
            <div class="neomp-table-column-filter-element mdi mdi-close"></div>
        </th>
    </ng-container>

    <!--Row definitions -->
    <tr *matHeaderRowDef="displayedColumns"
        mat-header-row></tr>
    <tr *matHeaderRowDef="displayedColumnFilters"
        class="neomp-table-row-filter {{showFilter? '': 'neomp-table-row-filter-hide'}}"
        mat-header-row></tr>
    <tr mat-row *matRowDef="let openIssuesRow; columns: displayedValueColumns, let i = dataIndex;"
        class="openIssuesDetail-row container-table-row neomp-table-row-selected"></tr>
</table>
