<neomp-container cssClass="msg-mp-container-frameless"
headlineText="{{ 'PAGE.LEADS.TERMIN.APPOINTMENT' | translate }}" (edit)="save($event)" (cancelDialogEvent)="closeDialog()" 
(navigateToEdit)="handleNavigateToEdit()" [formChanged]="terminForm.dirty"
[showActionIcon]="true" [editMode]="true" [editSection]="'CalendarAppointment'">
<form [formGroup]="terminForm">
  <div
    class="mdc-layout-grid mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone msg-helper-grid-gap-none msg-helper-margin-none msg-helper-padding-10 neo-space-padding">
    <section>
      <div class="mdc-layout-grid__inner">
        <div
          class="mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
          <mat-form-field class="neomp-full-width">
            <mat-label class="mat-label-color">{{ 'PAGE.LEADS.LEAD.TERMINABLATT.APPOINTMENT-DATE' | translate}}
            </mat-label>
            <input [matDatepicker]="termin_date" matInput formControlName="termin_date" />
            <mat-datepicker-toggle matSuffix [for]="termin_date"></mat-datepicker-toggle>
            <mat-datepicker #termin_date></mat-datepicker>
            <mat-error *ngIf="terminForm.controls['termin_date'].hasError('required')">{{
              'DIALOG.INPUT.REQUIREDERROR' | translate }}</mat-error>
          </mat-form-field>
        </div>
        <div
          class="mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
          <mat-form-field class="neomp-full-width">
            <mat-label class="mat-label-color">{{ 'PAGE.LEADS.LEAD.TERMINABLATT.TIME' | translate}} </mat-label>
            <input class="font-family" matInput type="time" formControlName="termin_time" />
            <mat-error *ngIf="terminForm.controls['termin_time'].hasError('required')">{{
              'DIALOG.INPUT.REQUIREDERROR' | translate }}</mat-error>
          </mat-form-field>
        </div>
      </div>
    </section>
    <section>
      <div class="mdc-layout-grid__inner">
        <div
          class="mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
          <mat-form-field class="neomp-full-width">
            <mat-label class="mat-label-color">{{ 'PAGE.LEADS.LEAD.TERMINABLATT.VERMITTLER' | translate}}</mat-label>
            <mat-select  (selectionChange)="setValue('termin_agent', $event)" formControlName="termin_agent">
              <mat-option *ngFor="let agent of agents" [value]="agent">
                {{agent.fullName && agent.fullName.split("Neosana Sales AG")[1] ?
                agent.fullName.split("Neosana Sales AG")[1] : agent.fullName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </section>
  </div>
</form>
</neomp-container>
<!-- <div mat-dialog-actions class="neomp-dialog-actions">
  <button mat-button class="neomp-dialog-action-button cancel-action" (click)="cancelDialog()">
      {{ 'DATEPICKER.CLOSE' | translate }}
  </button>
  </div> -->