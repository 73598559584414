import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { Title } from "@angular/platform-browser";
import { Person } from "../../core/model/person";
import { Policy } from "../../core/model/policy";
import { ContextSearchService } from "app/modules/core/services/context-search.service";
import { Subscription } from "rxjs";
import { log } from "../../core/providers/logger.provider";
import { LoadingService } from "../../core/providers/loading.component";
import { JournalList } from "app/modules/core/model/journal-list";
import { SearchType } from "app/modules/core/model/enum-searchtype";

@Component({
    selector: "neomp-search-result-page",
    templateUrl: "./search-result-page.component.html",
    styleUrls: ["./search-result-page.component.scss"]
})
export class SearchResultPageComponent implements OnInit, OnDestroy {

    private readonly TAG = this.constructor.name;

    private searchServiceSubscription: Subscription;
    private searchServiceResultSubscription: Subscription;
    private searchServiceMostRecentTermSubscription: Subscription;

    searchInProgress = false;
    mostRecentTerm: string = null;

    displayTables = {
        customers: true,
        policies: true
    };

    customers: Person[] = [];
    policies: Policy[] = [];
    journals: JournalList[] = [];
    translateHeader: string = "";

    constructor(public route: ActivatedRoute,
        public router: Router,
        public location: Location,
        public titleService: Title,
        public translateService: TranslateService,
        public searchService: ContextSearchService,
        public loadingService: LoadingService) {
    }

    async ngOnInit() {
        this.searchServiceSubscription = this.searchService.onSearchInProgress()
            .subscribe(v => {
                log.debug(`${this.TAG} -> subscription onSearchInProgress() -> v=${JSON.stringify(v)}`)
                if (v) {
                    this.customers = [];
                    this.policies = [];
                    this.journals = [];
                }
                this.searchInProgress = v;
            });

        this.searchServiceResultSubscription = this.searchService.onResults()
            .subscribe(v => {
                log.debug(`${this.TAG} -> subscription onResults() -> v=${JSON.stringify(v)}`)
                if (v) {
                    this.updateView(v.searchType);
                    let d: Person[] = [];
                    v.customers.forEach(c => {
                        d.push(Person.fromJson(c));
                    });
                    this.customers = d;
                    let p: Policy[] = [];
                    v.policies.forEach(x => {
                        p.push(Policy.fromJson(x));
                    })
                    this.policies = p;

                    let j: JournalList[] = [];
                    v.journals.forEach((x: any) => {
                        j.push(JournalList.fromJson(x))
                    })
                    this.journals = j;
                }
            });
        this.searchService.onMostRecentTerm()
            .subscribe(v => this.mostRecentTerm = v);

        this.translateHeader = await this.obtainTranslatedHeader();
    }

    ngOnDestroy(): void {
        if (this.searchServiceSubscription) this.searchServiceSubscription.unsubscribe();
        if (this.searchServiceResultSubscription) this.searchServiceResultSubscription.unsubscribe();
        if (this.searchServiceMostRecentTermSubscription) this.searchServiceMostRecentTermSubscription.unsubscribe();
    }

    async obtainTranslatedHeader(): Promise<string> {
        const header = "PAGE.CONTAINER.SEARCH_RESULT.CUSTOMER.HEADLINE";
        await this.translateService.get(header).toPromise();
        // It loads the translate data now, and you can now safely use instant
        const title = this.translateService.instant(header);
        if (this.mostRecentTerm) {
            return `${title} "${this.mostRecentTerm}"`;
        } else {
            return `${title}`;
        }
    }

    updateView(searchType: SearchType): void {
        switch (searchType) {
            case SearchType.All: {
                this.displayTables = {
                    customers: true,
                    policies: true
                }
                break;
            }
            case SearchType.Product: {
                this.displayTables = {
                    customers: false,
                    policies: true
                }
                break;
            }
        }
    }
}
