<div class="neomp-site-wrapper shadow">

    <table [dataSource]="dataSource" class="msg-helper-width-100" mat-table matSort matSortActive="creationDate"
        matSortDirection="desc" multiTemplateDataRows>
        <ng-container matColumnDef="icon">
            <th *matHeaderCellDef mat-header-cell class="mat-header-cell mat-column-filter">
                <div class="neomp-table-column-icon-element-header mdi mdi-email"></div>
            </th>
            <td mat-cell *matCellDef="let element">
                <svg style="width:24px;height:24px" viewBox="0 0 24 24" *ngIf="element.inputChannel === 'myneo'">
                    <path fill="#0BAF0F"
                        d="M22 5.5H9C7.9 5.5 7 6.4 7 7.5V16.5C7 17.61 7.9 18.5 9 18.5H22C23.11 18.5 24 17.61 24 16.5V7.5C24 6.4 23.11 5.5 22 5.5M22 9.17L15.5 12.5L9 9.17V7.5L15.5 10.81L22 7.5V9.17M5 16.5C5 16.67 5.03 16.83 5.05 17H1C.448 17 0 16.55 0 16S.448 15 1 15H5V16.5M3 7H5.05C5.03 7.17 5 7.33 5 7.5V9H3C2.45 9 2 8.55 2 8S2.45 7 3 7M1 12C1 11.45 1.45 11 2 11H5V13H2C1.45 13 1 12.55 1 12Z" />
                </svg>
                <svg style="width:24px;height:24px" viewBox="0 0 24 24" *ngIf="element.inputChannel === 'crm'"
                    class="neomp-received-message-icon">
                    <path fill="#70C9E6"
                        d="M22 5.5H9C7.9 5.5 7 6.4 7 7.5V16.5C7 17.61 7.9 18.5 9 18.5H22C23.11 18.5 24 17.61 24 16.5V7.5C24 6.4 23.11 5.5 22 5.5M22 9.17L15.5 12.5L9 9.17V7.5L15.5 10.81L22 7.5V9.17M5 16.5C5 16.67 5.03 16.83 5.05 17H1C.448 17 0 16.55 0 16S.448 15 1 15H5V16.5M3 7H5.05C5.03 7.17 5 7.33 5 7.5V9H3C2.45 9 2 8.55 2 8S2.45 7 3 7M1 12C1 11.45 1.45 11 2 11H5V13H2C1.45 13 1 12.55 1 12Z" />
                </svg>
            </td>
        </ng-container>
        <ng-container matColumnDef="creationDate">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'PAGE.CONTAINER.MESSAGES.DATE' | translate }}
            </th>
            <td mat-cell *matCellDef="let element"> {{element.creationDate | date: 'dd.M.yyyy'}}</td>
        </ng-container>
        <ng-container matColumnDef="typeName">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'PAGE.CONTAINER.MESSAGES.TYPE' | translate }}</th>
            <td mat-cell *matCellDef="let element">{{element.typeName}}</td>
        </ng-container>
        <ng-container matColumnDef="reasonForChange">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'PAGE.CONTAINER.MESSAGES.DESCRIPTION' | translate
                }}</th>
            <td mat-cell *matCellDef="let element">{{element.reasonForChange}}</td>
        </ng-container>
        <ng-container matColumnDef="msgKind">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'PAGE.CONTAINER.MESSAGES.STATUS' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
                <mat-icon *ngIf="element.msgKind === msgType.Sent">send</mat-icon>
                <mat-icon *ngIf="element.msgKind === msgType.Received">call_received</mat-icon>
            </td>
        </ng-container>
        <ng-container matColumnDef="document">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'PAGE.CONTAINER.MESSAGES.DOCUMENT' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
                <span *ngFor="let resource of getDocuments(element)" (click)="$event.stopPropagation()">
                    <button mat-icon-button (click)="!resource.fromMyNeo ? download(resource.id) : downloadFromMyNeo(resource.id)" [title]="resource.title">
                        <div
                            class="neomp-table-column-icon-element mdi {{ documentProvider.getIconByName(resource.title) }}">
                        </div>
                    </button>
                </span>
            </td>
        </ng-container>
        <!--<ng-container matColumnDef="descriptionField">
        <th *matHeaderCellDef mat-header-cell
            mat-sort-header>{{ 'PAGE.CONTAINER.MESSAGES.LONGDESCRIPTION' | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{element.descriptionField}}</td>
        </ng-container>-->
        <ng-container matColumnDef="filter">
            <th *matHeaderCellDef mat-header-cell (click)="changeFilterView()"
                class="mat-header-cell mat-column-filter {{isFilterActive ? 'mat-column-filter-active':''}}">
                <div
                    class="neomp-table-column-filter-element  mdi {{isFilterActive ? 'mdi-filter':'mdi-filter-outline'}}">
                </div>
            </th>
            <td mat-cell *matCellDef="let element" class="mat-column-filter-action">
                <div class="neomp-table-column-filter-element mdi mdi-plus" (click)="openActionDialog($event, element)">
                </div>
            </td>
        </ng-container>

        <!-- Filter row definition -->
        <ng-container matColumnDef="iconFilter">
            <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox [formControl]="iconFilter"></mat-checkbox>
            </th>
        </ng-container>
        <ng-container matColumnDef="creationDateFilter">
            <th mat-header-cell *matHeaderCellDef>
                <neomp-date-picker [setDefaultValues]="false" class="datePickerHide" (filterValue)="creationDateFilterChanged($event)"
                    placeholder="{{ 'TABLES.FILTERPLACEHOLDER.DATE' | translate }}">
                </neomp-date-picker>
            </th>
        </ng-container>
        <ng-container matColumnDef="typeNameFilter">
            <th mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter" floatLabel="never">
                    <input matInput [formControl]="typeNameFilter"
                        placeholder="{{ 'TABLES.FILTERPLACEHOLDER.TYPE' | translate }}">
                </mat-form-field>
            </th>
        </ng-container>
        <ng-container matColumnDef="reasonForChangeFilter">
            <th mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter" floatLabel="never">
                    <input matInput [formControl]="reasonForChangeFilter"
                        placeholder="{{ 'TABLES.FILTERPLACEHOLDER.SUBJECT' | translate }}">
                </mat-form-field>
            </th>
        </ng-container>
        <!--<ng-container matColumnDef="descriptionFieldFilter">
        <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter" floatLabel="never">
                <input matInput [formControl]="descriptionFieldFilter">
            </mat-form-field>
        </th>
    </ng-container>-->
        <ng-container matColumnDef="documentFilter">
            <th mat-header-cell *matHeaderCellDef>
            </th>
        </ng-container>
        <ng-container matColumnDef="filterRemove">
            <th mat-header-cell *matHeaderCellDef (click)="resetFilter()">
                <div class="neomp-table-column-filter-element mdi mdi-close"></div>
            </th>
        </ng-container>
        <!--end of  Filter row definition-->

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let journalRow" [attr.colspan]="displayedColumns.length"
                class="{{journalRow === expandedElement ? 'openIssuesDetail-row-detail-content-expanded':''}}">
                <div class="openIssuesDetail-row-expanded-element-with-flex"
                    [@detailExpand]="journalRow === expandedElement ? 'expanded' : 'collapsed'">
                    <div class="openIssuesDetail-row-expanded-element-with-flex-item msg-helper-margin-side-5"
                        style="--flexSize: 5">
                        <mat-form-field class="neomp-full-width">
                            <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                cdkAutosizeMinRows="1" cdkAutosizeMaxRows="75" readonly
                                placeholder="{{'PAGE.CONTAINER.MESSAGES.LONGDESCRIPTION' | translate }}"
                                value="{{journalRow.descriptionField}}"></textarea>
                        </mat-form-field>

                    </div>
                </div>
            </td>

        </ng-container>


        <!--Row definitions -->
        <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
        <tr *matHeaderRowDef="displayedColumnFilters"
            class="neomp-table-row-filter {{showFilter? '': 'neomp-table-row-filter-hide'}}" mat-header-row></tr>
        <tr (click)="journalRowClick(journalRow)" *matRowDef="let journalRow; columns: displayedColumns" class="neomp-table-row-selected container-table-row {{journalRow.uuid === selectedJournalRowIndex ? 'container-table-row-selected':''}}
        {{ !journalRow.status.completed ? 'bold' : '' }}" mat-row></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="openIssuesDetail-row-detail-content"></tr>
    </table>
</div>