import {SDAKey} from "./sdakey";
import {CommAddrTypes} from "./enum-commaddrtype";

export class CommAddrType {
    static PHONE_PRIVATE = SDAKey.fromKeyValue(CommAddrTypes.PHONE_PRIVATE, "Telefonnummer Privat", "Telefonnummer Privat");
    static PHONE_BUSINESS = SDAKey.fromKeyValue(CommAddrTypes.PHONE_BUSINESS, "Telefonnummer Geschäftlich", "Telefonnummer Geschäftlich");
    static PHONE = SDAKey.fromKeyValue(CommAddrTypes.PHONE, "Telefonnummer", "Telefonnummer");
    static PHONE_MOBILE = SDAKey.fromKeyValue(CommAddrTypes.PHONE_MOBILE, "Telefonnummer Mobil", "Telefonnummer Mobil");
    static PHONE_MOBILE_PRIVATE = SDAKey.fromKeyValue(CommAddrTypes.PHONE_MOBILE_PRIVATE, "Telefonnummer Mobil Privat", "Telefonnummer Mobil Privat");
    static PHONE_MOBILE_BUSINESS = SDAKey.fromKeyValue(CommAddrTypes.PHONE_MOBILE_BUSINESS, "Telefonnummer Mobil Geschäftlich", "Telefonnummer Mobil Geschäftlich");
    static FAX = SDAKey.fromKeyValue(CommAddrTypes.FAX, "Telefax", "Telefax");
    static FAX_PRIVATE = SDAKey.fromKeyValue(CommAddrTypes.FAX_PRIVATE, "Telefax Privat", "Telefax Privat");
    static FAX_BUSINESS = SDAKey.fromKeyValue(CommAddrTypes.FAX_BUSINESS, "Telefax Geschäftlich", "Telefax Geschäftlich");
    static EMAIL = SDAKey.fromKeyValue(CommAddrTypes.EMAIL, "E-Mail", "E-Mail");
    static EMAIL_PRIVATE = SDAKey.fromKeyValue(CommAddrTypes.EMAIL_PRIVATE, "E-Mail Privat", "E-Mail Privat");
    static EMAIL_BUSINESS = SDAKey.fromKeyValue(CommAddrTypes.EMAIL_BUSINESS, "E-Mail Geschäftlich", "E-Mail Geschäftlich");
    static EMAIL_SECURE = SDAKey.fromKeyValue(CommAddrTypes.EMAIL_SECURE, "Gesicherte E-Mail", "Gesicherte E-Mail");
    static EMAIL_SECURE_PRIVATE = SDAKey.fromKeyValue(CommAddrTypes.EMAIL_SECURE_PRIVATE, "Gesicherte E-Mail Privat", "Gesicherte E-Mail Privat");
    static EMAIL_SECURE_BUSINESS = SDAKey.fromKeyValue(CommAddrTypes.EMAIL_SECURE_BUSINESS, "Gesicherte E-Mail Geschäftlich", "Gesicherte E-Mail Geschäftlich");
    static WEB = SDAKey.fromKeyValue(CommAddrTypes.WEB, "Webseite", "Webseite");
    static WEB_PRIVATE = SDAKey.fromKeyValue(CommAddrTypes.WEB_PRIVATE, "Webseite Privat", "Webseite Privat");
    static WEB_BUSINESS = SDAKey.fromKeyValue(CommAddrTypes.WEB_BUSINESS, "Webseite Geschäftlich", "Webseite Geschäftlich");
}
