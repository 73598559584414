<!-- Search filters -->
<div class="mdc-layout-grid msg-helper-padding-none">
    <div class="mdc-layout-grid__inner msg-helper-grid-gap-horizontal">
        <div
            class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
            <div class="mdc-layout-grid msg-helper-padding-none msg-helper-margin-top-10">
                <div class="mdc-layout-grid__inner">
                    <div
                        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
                        <div class="mdc-layout-grid__cell
            mdc-layout-grid__cell--span-12-desktop
             mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone">
                            <neomp-container [isLightContainer]="false" cssClass="msg-mp-container-frameless"
                                headlineText="{{ 'MENU.ENTRIES.LEAD-POOL' | translate }}">
                                <neomp-loading-data #loading></neomp-loading-data>
                            </neomp-container>
                        </div>
                        <div class="search-fields-layout-grid">
                            <div
                                class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
                                <mat-card>
                                    <!-- filter fields -->
                                    <div class="search-fields-layout-grid">
                                        <!-- (keyup.enter)="search()" -->
                                        <mat-form-field class="neomp-full-width">
                                            <mat-label>{{ 'PAGE.LEADS.LEAD.TEXT' | translate }}</mat-label>
                                            <input matInput [(ngModel)]="searchText" />
                                        </mat-form-field>

                                        <mat-form-field class="neomp-full-width">
                                            <mat-label>{{ 'PAGE.LEADS.LEAD.FROM' | translate }}</mat-label>
                                            <input [matDatepicker]="From" matInput [(ngModel)]="dateFrom" />
                                            <mat-datepicker-toggle matSuffix [for]="From"></mat-datepicker-toggle>
                                            <mat-datepicker #From></mat-datepicker>
                                        </mat-form-field>

                                        <mat-form-field class="neomp-full-width">
                                            <mat-label>{{ 'PAGE.LEADS.LEAD.TO' | translate }}</mat-label>
                                            <input [matDatepicker]="To" matInput [(ngModel)]="dateTo" />
                                            <mat-datepicker-toggle matSuffix [for]="To"></mat-datepicker-toggle>
                                            <mat-datepicker #To></mat-datepicker>
                                        </mat-form-field>

                                        <div *ngIf="isHighRole && selectedIndexTab === 1">
                                            <mat-form-field class="neomp-full-width">
                                                <mat-label id="berater-label">{{ 'PAGE.LEADS.LEAD.ADVISOR' | translate
                                                    }}</mat-label>
                                                <mat-select [formControl]="agentSelectedControl">
                                                    <mat-option [value]="">
                                                        {{ 'PAGE.LEADS.LEAD.ALL' | translate }}
                                                    </mat-option>
                                                    <mat-option *ngFor="let agent of unblockedAgents" [value]="agent.uuid">
                                                        {{agent.fullName && agent.fullName.split("Neosana Sales AG")[1]
                                                        ?
                                                        agent.fullName.split("Neosana Sales AG")[1] : agent.fullName }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>

                                        <mat-form-field class="neomp-full-width">
                                            <mat-label>StatusList</mat-label>
                                            <mat-select [formControl]="statusSelected"
                                                (selectionChange)="statusesSelected($event)" multiple>
                                                <mat-option value="Neu">Neu</mat-option>
                                                <mat-option *ngFor="let status of statusesList"
                                                    [value]="status">{{status}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>

                                        <mat-form-field class="neomp-full-width">
                                            <mat-label>Substatus List</mat-label>
                                            <mat-select [formControl]="substatusSelected" multiple>
                                                <mat-optgroup *ngFor="let status of substatusesList"
                                                    [label]="status.title" [disabled]="status.disabled">
                                                    <mat-option *ngFor="let substatus of status.value"
                                                        [value]="substatus">{{substatus}}
                                                    </mat-option>
                                                </mat-optgroup>
                                            </mat-select>
                                        </mat-form-field>

                                        <mat-form-field class="neomp-full-width">
                                            <mat-label>Categories</mat-label>
                                            <mat-select [formControl]="categoriesSelected" multiple>
                                                <!-- <mat-option value="Neu">Neu</mat-option> -->
                                                <mat-option *ngFor="let category of categoriesList"
                                                    [value]="category">{{category}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>

                                        <!-- Types -->
                                        <!-- <div *ngIf="isHighRole"> -->
                                        <mat-form-field class="neomp-full-width">
                                            <mat-label>Types</mat-label>
                                            <mat-select [(ngModel)]="lead_leadType" multiple>
                                                <mat-option *ngFor="let type of unassignedLeadsTypes"
                                                    [value]="type">{{type}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <!-- </div> -->
                                    </div>

                                    <!-- buttons -->
                                    <div class="search-fields-layout-grid margin-top-15">
                                        <div class="neo-padding-top-10 neomp-full-width">
                                            <button mat-raised-button color="primary"
                                                class="neomp-full-width button-text" (click)="filterSearch()">
                                                <span class="material-icons">
                                                    search
                                                </span>{{ 'PAGE.LEADS.LEAD.SEARCH' | translate }}
                                            </button>
                                        </div>
                                        <div class="neo-padding-top-10">
                                            <button mat-raised-button class="clear-button neomp-full-width button-text"
                                                (click)="clearSearch()">
                                                <span class="material-icons">clear</span>{{ 'PAGE.LEADS.LEAD.CLEAR' |
                                                translate }}
                                            </button>
                                        </div>
                                    </div>
                                </mat-card>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Assign multiple leads -->
<div class="mdc-layout-grid msg-helper-padding-none">
    <div class="mdc-layout-grid__inner msg-helper-grid-gap-horizontal">
        <div
            class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
            <div class="mdc-layout-grid msg-helper-padding-none msg-helper-margin-top-10">
                <div class="mdc-layout-grid__inner">
                    <div
                        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
                        <div class="search-fields-layout-grid">
                            <div
                                class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
                                <mat-card>
                                    <div class="header"> {{'PAGE.LEADS.LEAD.MULTI-LEADS-ASSIGN' |
                                        translate}}
                                    </div>

                                    <br />


                                    <div class="mdc-layout-grid__inner"
                                        *ngIf="isHighRole; then thenBlock else elseBlock"> </div>
                                    <ng-template #thenBlock>
                                        <!-- Leadpool HighRole -->
                                        <div *ngIf="selectedIndexTab === 0" class="mdc-layout-grid__inner">
                                            <!-- Leadpool HighRole selectedIndexTab={{selectedIndexTab}} -->
                                            <div class="mdc-layout-grid__cell--span-3">
                                                <mat-form-field class="neomp-full-width">
                                                    <mat-label id="berater-label">{{ 'PAGE.LEADS.LEAD.ADVISOR' |
                                                        translate }}</mat-label>
                                                    <mat-select [formControl]="agentAssignSelectedControl">
                                                        <mat-option *ngFor="let agent of agents" [value]="agent">
                                                            {{agent.fullName && agent.fullName.split("Neosana Sales
                                                            AG")[1] ?
                                                            agent.fullName.split("Neosana Sales AG")[1] : agent.fullName
                                                            }}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                            <div class="mdc-layout-grid__cell--span-4">
                                                <div class="neo-padding-top-10">
                                                    <button mat-raised-button [disabled]="
                                                selectionLeadpool.selected.length === 0 
                                                    ||
                                                    agentAssignSelectedControl.invalid
                                                    ||
                                                    isLoading
                                                    " color="primary" (click)="assignLeadpoolLeadsToAgent()">
                                                        {{ 'DIALOG.BUTTON.SAVE' | translate }}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- MyLeads HighRole -->
                                        <div *ngIf="selectedIndexTab===1">
                                            <!-- MyLeads HighRole selectedIndexTab={{selectedIndexTab}} -->

                                            <div
                                                class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
                                                <!-- filter fields -->
                                                <div class="search-fields-layout-grid">
                                                    <button class="neomp-full-width" mat-raised-button [disabled]="
                                                selectionMyLeads.selected.length === 0 
                                                    ||
                                                    isLoading
                                                    " color="primary" (click)="unAssignMyLeadsFromAgent()">
                                                        Leadpool
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>

                                    <ng-template #elseBlock>
                                        <!-- This will assign all the selected leads to that user -->
                                        <div *ngIf="selectedIndexTab===0" class="mdc-layout-grid__inner">
                                            <div
                                                class="mdc-layout-grid__cell--span-2 mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone">
                                                <div class="neo-padding-top-10">
                                                    <button mat-raised-button color="primary"
                                                        class="neomp-full-width button-text"
                                                        (click)="assignLeadsToAgent()"
                                                        [disabled]="!selectionLeadpool.selected.length">
                                                        {{ 'PAGE.LEADS.LEAD.MIR-ZUWEISEN' | translate }}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="selectedIndexTab===1" class="mdc-layout-grid__inner">
                                            <div
                                                class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
                                                <!-- filter fields -->
                                                <div class="search-fields-layout-grid">
                                                    <button class="neomp-full-width" mat-raised-button [disabled]="
                                                selectionMyLeads.selected.length === 0 
                                                    ||
                                                    isLoading
                                                    " color="primary" (click)="unAssignMyLeadsFromAgent()">
                                                        Leadpool
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>


                                </mat-card>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Tabs -->
<mat-tab-group #tabGroup (selectedTabChange)="tabChanged($event)" [selectedIndex]="selectedIndexTab"
    class="msg-helper-margin-top-10">
    <mat-tab label="{{ 'MENU.ENTRIES.LEAD-POOL' | translate}}">
        <div class="mdc-layout-grid msg-helper-padding-none">
            <div class="mdc-layout-grid__inner">
                <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop
                          mdc-layout-grid__cell--span-12-tablet
                          mdc-layout-grid__cell--span-12-phone">

                    <!-- Leadpool -->
                    <table mat-table [dataSource]="dataSourceLeadpool" multiTemplateDataRows matSort
                        class="neomp-full-width">

                        <!-- Checkbox Column -->
                        <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef class="check-filter-w">
                                <mat-checkbox class="all-checkbox" (change)="$event ? masterLeadpoolToggle() : null"
                                    [checked]="isAllLeadpoolsSelected()"
                                    [disabled]="isSelectAllLeadPoolDisabled()"></mat-checkbox>
                            </th>
                            <th mat-cell *matCellDef="let row">
                                <mat-checkbox (click)="$event.stopPropagation()" (change)="toggleLeadPool(row)"
                                    [checked]="selectionLeadpool.isSelected(row)"
                                    [disabled]="row.lead.lead_agency !== row.lead.termin_berater_shortcut"></mat-checkbox>
                            </th>
                        </ng-container>

                        <ng-container matColumnDef="name">
                            <th *matHeaderCellDef mat-header-cell class="sort-arrow" mat-sort-header="fullName">{{
                                'PAGE.LEADS.LEAD.NAME' | translate }}</th>
                            <td mat-cell *matCellDef="let lead">{{lead.fullName}}</td>
                        </ng-container>
                        <ng-container matColumnDef="address">
                            <th *matHeaderCellDef mat-header-cell mat-sort-header="address.postalCode">{{
                                'PAGE.LEADS.LEAD.PLZ-ORT' |
                                translate }}</th>
                            <td mat-cell *matCellDef="let lead"> {{lead.address.postalCode + ' ' + lead.address.city }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="art">
                            <th *matHeaderCellDef mat-header-cell mat-sort-header="lead.lead_typ">{{
                                'PAGE.LEADS.LEAD.ART' | translate }}
                            </th>
                            <td mat-cell *matCellDef="let lead"> {{lead.lead !== null ? lead.lead.lead_typ : ''}}</td>
                        </ng-container>
                        <ng-container matColumnDef="statusLead">
                            <th *matHeaderCellDef mat-header-cell mat-sort-header="lead.lead_status">{{
                                'PAGE.LEADS.LEAD.STATUS' | translate
                                }}</th>
                            <td mat-cell *matCellDef="let lead">
                                <mat-chip class="neo-statusNeu-backgroundColor" *ngIf="lead.lead.lead_status == 'Neu'">
                                    {{lead.lead !== null ? lead.lead.lead_status : ''}}
                                </mat-chip>
                                <span *ngIf="lead.lead.lead_status != 'Neu'">
                                    {{lead.lead !== null ? lead.lead.lead_status : ''}}
                                </span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="substatusLead">
                            <th *matHeaderCellDef mat-header-cell mat-sort-header="lead.lead_substatus">{{
                                'PAGE.LEADS.LEAD.SUBSTATUS' | translate }}
                            </th>
                            <td mat-cell *matCellDef="let lead"> {{lead.lead !== null ? lead.lead.lead_substatus : ''}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="date">
                            <th *matHeaderCellDef mat-header-cell mat-sort-header="lead.lead_creadate">{{
                                'PAGE.LEADS.LEAD.DATE' | translate
                                }}</th>
                            <td mat-cell *matCellDef="let lead"> {{ dateParse(lead.lead.lead_creadate) | date:
                                'dd.MM.yyyy' }}</td>
                        </ng-container>
                        <ng-container matColumnDef="category">
                            <th *matHeaderCellDef mat-header-cell mat-sort-header="lead.lead_kategory">{{
                                'PAGE.LEADS.LEAD.CATEGORY' |
                                translate }}</th>
                            <td mat-cell *matCellDef="let lead"> {{lead.lead !== null ? lead.lead.lead_kategory : ''}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="assignLead">
                            <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'PAGE.LEADS.LEAD.LEADS-ZUWEISEN' |
                                translate }}</th>
                            <td mat-cell *matCellDef="let lead">
                                <!-- lead.lead.lead_agency:{{lead.lead.lead_agency}}<br/>
                                lead.lead.termin_berater_shortcut:{{lead.lead.termin_berater_shortcut}} -->
                                <button *ngIf="lead.lead.lead_agency === lead.lead.termin_berater_shortcut"
                                    mat-raised-button color="primary" class="neomp-full-width button-text"
                                    (click)="$event.stopPropagation();assignAgentToLead(lead)">
                                    {{ 'PAGE.LEADS.LEAD.MIR-ZUWEISEN' | translate }}
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="assignedEstimation">
                            <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'PAGE.LEADS.LEAD.BEARBEITUNG-SEIT'
                                |
                                translate }}</th>
                            <td mat-cell *matCellDef="let lead" class="text-delete align-center-td">
                                <label *ngIf="lead.lead.lead_agency !== lead.lead.termin_berater_shortcut">
                                    {{calculateFromNow(lead.lead.termin_berater_assigned_time)}} </label>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="filter">
                            <th *matHeaderCellDef mat-header-cell (click)="changeFilterView()"
                                class="mat-header-cell mat-column-filter {{isFilterActive ? 'mat-column-filter-active':''}}">
                                <div
                                    class="neomp-table-column-filter-element  mdi {{isFilterActive ? 'mdi-filter':'mdi-filter-outline'}}">
                                </div>
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <div class="neomp-table-column-filter-element mdi mdi-plus" [disabled]="isDialogOpen"
                                    (click)="isDialogOpen ? null : openActionDialog($event, element)">
                                </div>
                            </td>
                            <td class="mat-column-filter-actions"> </td>
                        </ng-container>

                        <!-- Filterrow elements-->
                        <ng-container matColumnDef="leadSelectFilter" *ngIf="managementView">
                            <th mat-header-cell *matHeaderCellDef>
                            </th>
                        </ng-container>

                        <ng-container matColumnDef="selectFilter">
                            <th mat-header-cell *matHeaderCellDef>
                                <!-- Nothing needed -->

                            </th>
                        </ng-container>


                        <ng-container matColumnDef="nameFilter">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-form-field floatLabel="never">
                                    <input matInput [formControl]="leadNameFilter"
                                        placeholder="{{ 'TABLES.FILTERPLACEHOLDER.NAME' | translate }}">
                                </mat-form-field>
                            </th>
                        </ng-container>
                        <ng-container matColumnDef="addressFilter">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-form-field floatLabel="never">
                                    <input matInput [formControl]="leadAddressFilter"
                                        placeholder="{{ 'TABLES.FILTERPLACEHOLDER.ART' | translate }}">
                                </mat-form-field>
                            </th>
                        </ng-container>
                        <ng-container matColumnDef="artFilter">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-form-field floatLabel="never">
                                    <input matInput [formControl]="leadArtFilter"
                                        placeholder="{{ 'TABLES.FILTERPLACEHOLDER.ART' | translate }}">
                                </mat-form-field>
                            </th>
                        </ng-container>
                        <ng-container matColumnDef="statusLeadFilter">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-form-field floatLabel="never">
                                    <input matInput [formControl]="statusLeadFilter"
                                        placeholder="{{ 'TABLES.FILTERPLACEHOLDER.STATUS' | translate }}">
                                </mat-form-field>
                            </th>
                        </ng-container>
                        <ng-container matColumnDef="substatusLeadFilter">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-form-field floatLabel="never">
                                    <input matInput [formControl]="substatusLeadFilter"
                                        placeholder="{{ 'TABLES.FILTERPLACEHOLDER.SUBSTATUS' | translate }}">
                                </mat-form-field>
                            </th>
                        </ng-container>
                        <ng-container matColumnDef="dateFilter">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-form-field floatLabel="never">
                                    <input [matDatepicker]="date" matInput [formControl]="leadDateFilter"
                                        placeholder="{{ 'TABLES.FILTERPLACEHOLDER.DATE' | translate }}">
                                    <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
                                    <mat-datepicker #date></mat-datepicker>
                                </mat-form-field>
                            </th>
                        </ng-container>
                        <ng-container matColumnDef="categoryFilter">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-form-field floatLabel="never">
                                    <input matInput [formControl]="leadCategoryFilter"
                                        placeholder="{{ 'TABLES.FILTERPLACEHOLDER.CATEGORY' | translate }}">
                                </mat-form-field>
                            </th>
                        </ng-container>
                        <ng-container matColumnDef="assignLeadFilter">
                            <th mat-header-cell *matHeaderCellDef>

                            </th>
                        </ng-container>

                        <ng-container matColumnDef="assignedEstimationFilter">
                            <th mat-header-cell *matHeaderCellDef>
                            </th>
                        </ng-container>
                        <ng-container matColumnDef="filterRemove">
                            <th mat-header-cell *matHeaderCellDef (click)="resetFilter()">
                                <div class="neomp-table-column-filter-element mdi mdi-close"></div>
                            </th>
                        </ng-container>


                        <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>

                        <tr *matHeaderRowDef="displayedColumnFilters"
                            class="neomp-table-row-filter {{showFilter? '': 'neomp-table-row-filter-hide'}}"
                            mat-header-row></tr>

                        <tr mat-row *matRowDef="let element; columns: displayedColumns; let i = dataIndex;"
                            class="element-row container-table-row"      
                            (click)="redirectToEdit(element);">
                        </tr>

                    </table>

                    <neomp-paginator #leadReminderPaginator [length]="totalTerminLeadsReminders" [pageSize]="pageSize"
                        [pageIndex]="pageIndex" [pageSizeOptions]="pageSizeOptions"
                        (paginatorChange)="leadReminderPaginatorValueChanged($event)">
                    </neomp-paginator>

                </div>
            </div>
        </div>
    </mat-tab>

    <mat-tab>
        <ng-template mat-tab-label>
            {{ 'MENU.ENTRIES.MEINE-LEADS' | translate}}
            <span class="unassigned-leads-badge">
                {{ totalMyLeads }}
            </span>
        </ng-template>

        <div class="mdc-layout-grid msg-helper-padding-none">
            <div class="mdc-layout-grid__inner">
                <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop
                          mdc-layout-grid__cell--span-12-tablet
                          mdc-layout-grid__cell--span-12-phone">
                    <!-- My assigned leads -->
                    <table mat-table [dataSource]="dataSourceMyLeads" multiTemplateDataRows matSort
                        class="neomp-full-width">

                        <!-- Checkbox Column -->
                        <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef class="check-filter-w">
                                <mat-checkbox class="all-checkbox" (change)="$event ? masterMyLeadsToggle() : null"
                                    [checked]="isAllMyLeadsSelected()"></mat-checkbox>
                            </th>
                            <th mat-cell *matCellDef="let row">
                                <mat-checkbox (click)="$event.stopPropagation()" (change)="toggleMyLeads(row)"
                                    [checked]="selectionMyLeads.isSelected(row)"></mat-checkbox>
                            </th>
                        </ng-container>

                        <ng-container matColumnDef="name">
                            <th *matHeaderCellDef mat-header-cell class="sort-arrow" mat-sort-header="fullName">{{
                                'PAGE.LEADS.LEAD.NAME' | translate }}</th>
                            <td mat-cell *matCellDef="let lead">{{lead.fullName}}</td>
                        </ng-container>
                        <ng-container matColumnDef="address">
                            <th *matHeaderCellDef mat-header-cell mat-sort-header="address.postalCode">{{
                                'PAGE.LEADS.LEAD.PLZ-ORT' |
                                translate }}</th>
                            <td mat-cell *matCellDef="let lead"> {{lead.address.postalCode + ' ' + lead.address.city }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="art">
                            <th *matHeaderCellDef mat-header-cell mat-sort-header="lead.lead_typ">{{
                                'PAGE.LEADS.LEAD.ART' | translate }}
                            </th>
                            <td mat-cell *matCellDef="let lead"> {{lead.lead !== null ? lead.lead.lead_typ : ''}}</td>
                        </ng-container>
                        <ng-container matColumnDef="statusLead">
                            <th *matHeaderCellDef mat-header-cell mat-sort-header="lead.lead_status">{{
                                'PAGE.LEADS.LEAD.STATUS' | translate
                                }}</th>
                            <td mat-cell *matCellDef="let lead">
                                <mat-chip class="neo-statusNeu-backgroundColor" *ngIf="lead.lead.lead_status == 'Neu'">
                                    {{lead.lead !== null ? lead.lead.lead_status : ''}}
                                </mat-chip>
                                <span *ngIf="lead.lead.lead_status != 'Neu'">
                                    {{lead.lead !== null ? lead.lead.lead_status : ''}}
                                </span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="substatusLead">
                            <th *matHeaderCellDef mat-header-cell mat-sort-header="lead.lead_substatus">{{
                                'PAGE.LEADS.LEAD.SUBSTATUS' | translate }}
                            </th>
                            <td mat-cell *matCellDef="let lead"> {{lead.lead !== null ? lead.lead.lead_substatus : ''}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="date">
                            <th *matHeaderCellDef mat-header-cell mat-sort-header="lead.lead_creadate">{{
                                'PAGE.LEADS.LEAD.DATE' | translate
                                }}</th>
                            <td mat-cell *matCellDef="let lead"> {{ dateParse(lead.lead.lead_creadate) | date:
                                'dd.MM.yyyy' }}</td>
                        </ng-container>
                        <ng-container matColumnDef="category">
                            <th *matHeaderCellDef mat-header-cell mat-sort-header="lead.lead_kategory">{{
                                'PAGE.LEADS.LEAD.CATEGORY' |
                                translate }}</th>
                            <td mat-cell *matCellDef="let lead"> {{lead.lead !== null ? lead.lead.lead_kategory : ''}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="assignLead">
                            <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'PAGE.LEADS.LEAD.LEADS-ZUWEISEN' |
                                translate }}</th>
                            <td mat-cell *matCellDef="let lead">
                                <button mat-raised-button color="primary" class="neomp-full-width button-text"
                                    (click)="unAssignLead(lead, $event)">
                                    Leadpool
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="assignedEstimation">
                            <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'PAGE.LEADS.LEAD.BEARBEITUNG-SEIT'
                                | translate }}</th>
                            <td mat-cell *matCellDef="let lead" class="text-delete align-center-td">
                                {{calculateFromNow(lead.lead.termin_berater_assigned_time || "")}}</td>
                        </ng-container>
                        <ng-container matColumnDef="berater">
                            <th *matHeaderCellDef mat-header-cell mat-sort-header style="width: 150px;">{{ 'PAGE.LEADS.LEAD.ADVISOR' |
                                translate }}</th>
                            <td mat-cell *matCellDef="let row" style="width: 150px;">
                                <mat-form-field class="neomp-full-width" (click)="$event.stopPropagation()">
                                    <mat-label id="berater-label">{{ 'PAGE.LEADS.LEAD.ADVISOR' | translate
                                        }}</mat-label>
                                        <ng-container *ngIf="!getSelectedAgent(row.lead.termin_berater_shortcut)?.blocked; else inputField">
                                    <mat-select [(ngModel)]="row.lead.termin_berater_shortcut">
                                        <mat-option [value]="">
                                            {{ 'PAGE.LEADS.LEAD.ALL' | translate }}
                                        </mat-option>
                                        <mat-option *ngFor="let agent of unblockedAgents" [value]="agent.uuid"
                                            (onSelectionChange)="selectionChange($event,row)">
                                            {{agent.fullName && agent.fullName.split("Neosana Sales AG")[1] ?
                                            agent.fullName.split("Neosana Sales AG")[1] : agent.fullName }}
                                        </mat-option>
                                    </mat-select>
                                        </ng-container>

                                        <ng-template #inputField>
                                            <ng-container *ngIf="showSelect">
                                                <mat-select [(ngModel)]="row.lead.termin_berater_shortcut"
                                                (onSelectionChange)="assignAgentToLead($event.value, row)" (openedChange)="toggleSelect($event)">
                                                  <mat-option *ngFor="let agent of unblockedAgents" [disabled]="agent.blocked === true" [value]="agent">
                                                  {{agent.fullName && agent.fullName.split("Neosana Sales AG")[1] ? agent.fullName.split("Neosana Sales AG")[1] : agent.fullName }}
                                                  </mat-option>
                                                </mat-select>

                                            </ng-container>
                                            <ng-container *ngIf="!showSelect">
                                                <mat-select [(ngModel)]="row.lead.termin_berater_shortcut" (click)="toggleSelect(true)"
                                                    (onSelectionChange)="assignAgentToLead($event.value, row)">
                                                    <mat-option *ngFor="let agent of agents" [value]="agent.uuid">
                                                        {{agent.fullName && agent.fullName.split("Neosana Sales AG")[1] ? agent.fullName.split("Neosana Sales AG")[1] :
                                                        agent.fullName }}
                                                    </mat-option>
                                                </mat-select>
                                            </ng-container>
                                        </ng-template>
                                </mat-form-field>

                            </td>

                        </ng-container>


                        <ng-container matColumnDef="filter">
                            <th *matHeaderCellDef mat-header-cell (click)="changeFilterView()"
                                class="mat-header-cell mat-column-filter {{isFilterActive ? 'mat-column-filter-active':''}}">
                                <div
                                    class="neomp-table-column-filter-element  mdi {{isFilterActive ? 'mdi-filter':'mdi-filter-outline'}}">
                                </div>
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <div class="neomp-table-column-filter-element mdi mdi-plus" [disabled]="isDialogOpen"
                                    (click)="isDialogOpen ? null : openActionDialog($event, element)">
                                </div>
                            </td>
                            <td class="mat-column-filter-actions"> </td>
                        </ng-container>

                        <!-- Filterrow elements-->


                        <ng-container matColumnDef="selectFilter">
                            <th mat-header-cell *matHeaderCellDef>
                                <!-- Nothing needed -->

                            </th>
                        </ng-container>
                        <!-- <ng-container matColumnDef="leadSelectFilter" *ngIf="managementView">
                            <th mat-header-cell *matHeaderCellDef>
                            </th>
                        </ng-container> -->

                        <ng-container matColumnDef="nameFilter">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-form-field floatLabel="never">
                                    <input matInput [formControl]="leadNameFilter"
                                        placeholder="{{ 'TABLES.FILTERPLACEHOLDER.NAME' | translate }}">
                                </mat-form-field>
                            </th>
                        </ng-container>

                        <ng-container matColumnDef="addressFilter">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-form-field floatLabel="never">
                                    <input matInput [formControl]="leadAddressFilter"
                                        placeholder="{{ 'TABLES.FILTERPLACEHOLDER.NAME' | translate }}">
                                </mat-form-field>
                            </th>
                        </ng-container>


                        <ng-container matColumnDef="artFilter">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-form-field floatLabel="never">
                                    <input matInput [formControl]="leadArtFilter"
                                        placeholder="{{ 'TABLES.FILTERPLACEHOLDER.ART' | translate }}">
                                </mat-form-field>
                            </th>
                        </ng-container>
                        <ng-container matColumnDef="statusLeadFilter">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-form-field floatLabel="never">
                                    <input matInput [formControl]="statusLeadFilter"
                                        placeholder="{{ 'TABLES.FILTERPLACEHOLDER.STATUS' | translate }}">
                                </mat-form-field>
                            </th>
                        </ng-container>
                        <ng-container matColumnDef="substatusLeadFilter">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-form-field floatLabel="never">
                                    <input matInput [formControl]="substatusLeadFilter"
                                        placeholder="{{ 'TABLES.FILTERPLACEHOLDER.SUBSTATUS' | translate }}">
                                </mat-form-field>
                            </th>
                        </ng-container>
                        <ng-container matColumnDef="dateFilter">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-form-field floatLabel="never">
                                    <input [matDatepicker]="date" matInput [formControl]="leadDateFilter"
                                        placeholder="{{ 'TABLES.FILTERPLACEHOLDER.DATE' | translate }}">
                                    <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
                                    <mat-datepicker #date></mat-datepicker>
                                </mat-form-field>
                            </th>
                        </ng-container>
                        <ng-container matColumnDef="categoryFilter">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-form-field floatLabel="never">
                                    <input matInput [formControl]="leadCategoryFilter"
                                        placeholder="{{ 'TABLES.FILTERPLACEHOLDER.CATEGORY' | translate }}">
                                </mat-form-field>
                            </th>
                        </ng-container>

                        <ng-container matColumnDef="assignLeadFilter">
                            <th mat-header-cell *matHeaderCellDef>
                            </th>
                        </ng-container>

                        <ng-container matColumnDef="assignedEstimationFilter">
                            <th mat-header-cell *matHeaderCellDef>
                            </th>
                        </ng-container>

                        <ng-container matColumnDef="beraterFilter">
                            <th mat-header-cell *matHeaderCellDef>
                            </th>
                        </ng-container>
                        
                        <ng-container matColumnDef="filterRemove">
                            <th mat-header-cell *matHeaderCellDef (click)="resetFilter()">
                                <div class="neomp-table-column-filter-element mdi mdi-close"></div>
                            </th>
                        </ng-container>

                        <tr *matHeaderRowDef="displayedColumnsMyLeads" mat-header-row></tr>

                        <tr *matHeaderRowDef="displayedColumnFiltersMyLeads"
                            class="neomp-table-row-filter {{showFilter? '': 'neomp-table-row-filter-hide'}}"
                            mat-header-row></tr>

                        <tr mat-row *matRowDef="let element; columns: displayedColumnsMyLeads; let i = dataIndex;"
                            class="element-row container-table-row"      
                            (click)="redirectToEdit(element);">
                        </tr>

                    </table>

                    <neomp-paginator #leadReminderPaginator [length]="totalMyLeads" [pageSize]="pageSize"
                        [pageIndex]="pageIndex" [pageSizeOptions]="pageSizeOptions"
                        (paginatorChange)="myLeadsPaginatorValueChanged($event)">
                    </neomp-paginator>

                </div>
            </div>
        </div>
    </mat-tab>

</mat-tab-group>