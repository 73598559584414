import {isDevMode, Injectable} from "@angular/core";
import {Router, CanActivate} from "@angular/router";
import {environment} from "../../../../environments/environment";

@Injectable()
export class DevelopModeGuard implements CanActivate {

    constructor(public router: Router) {}

    canActivate(): boolean {
        return !environment.settings.production;
    }
}
