import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { Person } from "../model/person";
import { LeadProvider } from "../providers/lead.provider";
import { map, catchError, finalize } from 'rxjs/operators';

@Injectable()
export class AdvisorDataService {

    private allAgents: { agents: Person[]; promise: Promise<Person[]>; promisePending: boolean; } = {
        agents: [],
        promise: null,
        promisePending: false
    };

    private unblockedAgents: { agents: Person[]; promise: Promise<Person[]>; promisePending: boolean; } = {
        agents: [],
        promise: null,
        promisePending: false
    };

    constructor(public leadProvider: LeadProvider) {
    }

    getAgents(useCache: boolean = true): Promise<Person[]> {
        if (useCache) {
            return this.handleAgentsData();
        } else {
            // fetch agents from DB
            return this.fetchAgents().toPromise()
        }
    }

    getUnblockedAgents(useCache: boolean = true): Promise<Person[]> {
        if (useCache) {
            return this.handleAgentsData(true);
        } else {
            // fetch agents from DB
            return this.fetchAgents(true).toPromise()
        }
    }

    private async handleAgentsData(unblocked?: boolean): Promise<Person[]> {
        let agentData = unblocked ? this.unblockedAgents : this.allAgents;
        
        if (agentData.agents.length <= 0 && !agentData.promisePending){
            agentData.promisePending = true;
            agentData.promise = this.fetchAgents(unblocked).pipe(map((res) => {
                agentData.agents = res;
                return agentData.agents
            }),
            catchError (() => {
                agentData.agents = [];
                return of(agentData.agents)
            }),
            finalize(() => agentData.promisePending = false)
            ).toPromise();
        }
        return agentData.promise
    }

    private fetchAgents(unblocked?: boolean): Observable<Person[]> {
        if (unblocked) {
            return this.leadProvider.getUnblockedAgents(false)
        }
        return this.leadProvider.getAgents(false)
    }

}