<div class="mdc-layout-grid">
  <div class="mdc-layout-grid__inner">
    <div class="mdc-layout-grid__cell--span-12">
      <h1 class="neo-user-profile-title">{{ 'PAGE.USER_PROFILE.USER_PROFILE' | translate }}</h1>
    </div>
    <div
      class="mdc-layout-grid__cell--span-2-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone neo-bottom-space">
      <div class="image-advisor-position">
        <img *ngIf="advisor?.b64Data == null" src="assets/images/logo.jpg" class="neo-image-profile" />

        <img *ngIf="advisor?.b64Data != null" [src]="advisor?.b64Data" class="neo-image-profile" />

        <label class="btn btn-primary">
          <span class="material-icons neo-icon-circle neo-icon">edit</span><input type="file"
            (change)="fileUploaded($event)" style="display: none" name="image" />
        </label>
      </div>
      <div class="advisor-name">
        {{ advisor?.lastName }}
      </div>
    </div>
    <div
      class="tesst mdc-layout-grid__cell--span-7-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
      <div class="mdc-layout-grid neo-padding-0">
        <div class="mdc-layout-grid__inner">
          <div class="mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--span-12-phone neo-space-line">
            <div>
              <p class="neo-person-info">
                <span class="material-icons neo-icons neo-icons-circle">place</span>
                {{ 'PAGE.USER_PROFILE.CITY' | translate }}
              </p>
            </div>
            <div>
              <p class="info">{{advisor?.address.city}}</p>
            </div>
            <div>
              <hr />
            </div>
          </div>
          <div class="mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--span-12-phone neo-space-line">
            <div>
              <p class="neo-person-info">
                <span class="material-icons neo-icons neo-icons-circle">near_me</span>
                {{ 'PAGE.USER_PROFILE.STREET' | translate }}
              </p>
            </div>
            <div>
              <p class="info">{{advisor?.address.street}}</p>
            </div>
            <div>
              <hr />
            </div>
          </div>
          <div class="mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--span-12-phone neo-space-line">
            <div>
              <p class="neo-person-info">
                <span class="material-icons neo-icons neo-icons-circle">markunread_mailbox</span>
                {{ 'PAGE.USER_PROFILE.POSTALCODE' | translate }}
              </p>
            </div>
            <div>
              <p class="info">{{advisor?.address.postalCode}}</p>
            </div>
            <div>
              <hr />
            </div>
          </div>
          <div class="mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--span-12-phone neo-space-line">
            <div>
              <p class="neo-person-info">
                <span class="material-icons neo-icons neo-icons-circle">call</span>
                {{ 'PAGE.USER_PROFILE.PHONENUMBER' | translate }}
              </p>
            </div>
            <div class="info">
              {{phoneNumber}}
            </div>
            <div>
              <hr />
            </div>
          </div>
          <div class="mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--span-12-phone neo-space-line">
            <div>
              <p class="neo-person-info">
                <span class="material-icons neo-icons neo-icons-circle">email
                </span>
                {{ 'PAGE.USER_PROFILE.EMAIL' | translate }}
              </p>
            </div>
            <div class="info">
              {{email?.address === undefined ? agent?.email : email?.address}}
            </div>
            <div>
              <hr />
            </div>
          </div>

          <div class="mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--span-12-phone neo-space-line">
            <div>
              <p class="neo-person-info">
                <span class="material-icons neo-icons neo-icons-circle">fingerprint</span>
                {{ 'PAGE.USER_PROFILE.UUID' | translate }}
              </p>
            </div>
            <div class="info">
              {{ advisor?.uuid }}
            </div>
            <div>
              <hr />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="test mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone neo-space-line neo-padding-10">
      <div class="qrcodeImage" *ngIf="advisor">
        <qrcode [level]="level" [qrdata]="qrdata" [size]="139" class="icon"></qrcode>
      </div>
      <br />
    </div>
  </div>
</div>
<div class="appointment-tool-section">
  <div class="neo-user-profile-title">
    {{"PAGE.USER_PROFILE.APPOITNMENT_TOOL" | translate}}
  </div>
  <div class="input">
    <mat-form-field class="mat-form-field-parent-width margin-top-bottom">
      <mat-select matSelect placeholder="{{ 'PAGE.USER_PROFILE.LANGUAGES' | translate }}" [formControl]="appointmentLanguages" required multiple>
          <mat-option *ngFor="let language of languageOptions" [value]="language">
              {{language}}
          </mat-option>

      </mat-select>
      <mat-error *ngIf="appointmentLanguages.hasError('required')">
          {{ 'DIALOG.INPUT.REQUIREDERROR' | translate }}
      </mat-error>
  </mat-form-field>
  </div>
  <div class="selected-languages">
    <div class="language" *ngFor="let language of appointmentLanguages.value">
      <div class="lang-text">{{language}}</div>
      <div class="close-mark" (click)="removeLang(language)"><img src="assets/icons/close_FILL0_wght400_GRAD0_opsz24.svg" alt="close"></div>
    </div>
  </div>
  <div class="download-image" *ngIf="appointemntToolLink !== ''">
    <div class="qr-code">
      <qrcode [level]="level" #appointmentToolQrCode [qrdata]="appointemntToolLink" [size]="150" class="icon"></qrcode>
    </div>
    <div class="content-copy">
      <button mat-button #tooltip="matTooltip" (click)="copyMessage(); tooltip.show(); " [matTooltip]="copyText" [matTooltipHideDelay]="matTooltipHideDelay" class="neo-copy-btn">
        <span class="material-icons neo-copy">
            content_copy
        </span>
      </button>
    </div>
  </div>
</div>
<!-- <div class="mdc-layout-grid visitkarten-section">
  <div class="mdc-layout-grid__inner">
    <div class="mdc-layout-grid__cell--span-12">
      <h1 class="neo-user-profile-title">{{ 'PAGE.USER_PROFILE.BUSINESS_CARD_TITLE' | translate }}</h1>
    </div>
    <div
      class="mdc-layout-grid__cell--span-2-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone neo-bottom-space hide-sm">
    </div>
    <div
      class="tesst mdc-layout-grid__cell--span-7-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
      <div>
        <p class="neo-person-info">
          <span class="material-icons neo-icons neo-icons-circle">
            markunread_mailbox
          </span>
          {{ 'PAGE.USER_PROFILE.QUANTITY' | translate }}
        </p>
        <form [formGroup]="vcardForm">
          <div class="vc-quantity">
            <mat-radio-group formControlName="vcardQuantity" aria-label="Select an option">
              <mat-radio-button value="100">100</mat-radio-button>
              <mat-radio-button value="200">200</mat-radio-button>
              <mat-radio-button value="500">500</mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="vc-quantity">
            <button mat-raised-button class="neomp-full-width" color="primary" (click)="order(email?.address)">
              {{ 'PAGE.USER_PROFILE.ORDER' | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>


<neomp-info-visitcards-log [tableEntries]="cards" [totalCount]="cardsCount"></neomp-info-visitcards-log> -->