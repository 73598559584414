import {Reference} from "./reference";
import {DocumentResourceResultJson} from "../json/document.resource.result.json";
import {Helper} from "../static/helper";
import {RelevantPartner} from "./relevant-partner";

export class DocumentResourceResult {
    public id = "";
    public title = "";
    public category = "";
    public type = "";
    public date = "";
    public businessTransactionId = "";
    public relevantPartners: RelevantPartner[] = [];
    public classifications: Reference[] = [];
    public fromMyNeo : boolean = false;

    public static fromJson(response: DocumentResourceResultJson): DocumentResourceResult {
        const result = new DocumentResourceResult();
        result.id = response.id || "";
        result.title = response.title || "";
        result.category = response.category || "";
        result.type = response.type || "";
        result.date = response.date || "";
        result.relevantPartners = RelevantPartner.fromJsons(response.relevantPartners);
        result.classifications = Reference.fromJsons(response.classifications);
        result.businessTransactionId = response.businessTransactionId || "";
        result.fromMyNeo = response.fromMyNeo || false;
        return result;
    }

    public static fromJsons(response: DocumentResourceResultJson[]): DocumentResourceResult[] | undefined {
        if (response) {
            return response.map(document => {
                return DocumentResourceResult.fromJson(document)
            });
        }
    }

    getDate(){
        return Helper.formatDate(new Date(this.date))
    }

    getDateType(){
        return new Date(this.date)
    }

}
