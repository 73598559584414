import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Journal } from '../../model/journal';

@Component({
  selector: 'neomp-termin-reminders',
  templateUrl: './termin-reminders.component.html',
  styleUrls: ['./termin-reminders.component.scss']
})
export class TerminRemindersComponent implements OnChanges {

  @Input() journals: Journal[] = [];
  @Output() journalRowClick: EventEmitter<Journal> = new EventEmitter();
  @Output() journalDeleteClick: EventEmitter<Journal> = new EventEmitter();
  //List of columns to be shown
  displayedColumns: string[] = ['id', 'task', 'comment', 'date', 'toBeDone','benutzer', 'action'];
  isHighRole: boolean = false;
  @ViewChild(MatSort)
  set sort(value: MatSort) {
    this.dataSource.sort = value;
  }
  dataSource = new MatTableDataSource();
  constructor(
    ) {
  }
  onRowClick(journal: Journal): void {
    this.journalRowClick.emit(journal);
  }

  getProperty = (obj, path) => (
    path.split('.').reduce((o, p) => o && o[p], obj)
)

onDeleteClick(event: Event, reminder: Journal){
  event.stopPropagation();
  this.journalDeleteClick.emit(reminder);
}

dateParse(date: string): Date | string {
  // Return date if it's valid, otherwise return empty string
  let parsedDate = new Date(date);
  return !isNaN(+parsedDate) ? parsedDate : "";
}
  ngOnChanges(changes: SimpleChanges): void {
    if(changes){
      this.dataSource.data = this.journals;
      this.dataSource.sortingDataAccessor = (obj, property) => this.getProperty(obj, property);
    }
  }
  }