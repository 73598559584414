<table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort>
  <!-- S3T: left commented as an example for the future if it is needed to be re-added. -->
  <!-- <ng-container matColumnDef="isLead">
      <th *matHeaderCellDef mat-header-cell
          mat-sort-header>{{ 'PAGE.CONTAINER.LEADS.TABLES.APPOINTMENTS.ISLEAD' | translate }}</th>
      <td mat-cell *matCellDef="let data">{{data.lead?.termin_stattgefunden ? 'Ja' : 'Nein'}}</td>
  </ng-container>
  <ng-container matColumnDef="status">
      <th *matHeaderCellDef mat-header-cell
          mat-sort-header>{{ 'PAGE.CONTAINER.LEADS.TABLES.APPOINTMENTS.STATUS' | translate }}</th>
      <td mat-cell *matCellDef="let data">
        <div class="neo-position-status">
           <p class="neo m">M</p> 
          <p class="neo s">S</p> 
          <p class="neo b">{{data.lead?.termin_status}}</p> 
        </div>
      </td>
  </ng-container> -->
  <!-- <ng-container matColumnDef="id">
      <th *matHeaderCellDef mat-header-cell
          mat-sort-header>{{ 'PAGE.CONTAINER.LEADS.TABLES.APPOINTMENTS.ID' | translate }}</th>
      <td mat-cell *matCellDef="let data"> {{data.lead !== null ? lead.lead.termin_id : ''}}</td>
  </ng-container> -->
  <ng-container matColumnDef="select" *ngIf="managementView">
    <th mat-header-cell *matHeaderCellDef class="check-filter-w">
        <mat-checkbox class="all-checkbox" (change)="$event ? toggleAllRows() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
        </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
        </mat-checkbox>
    </td>
</ng-container>
  <ng-container matColumnDef="appointmentInfo">
      <th *matHeaderCellDef mat-header-cell
          mat-sort-header="fullName">{{ 'PAGE.LEADS.TERMIN.APPOINTMENT-INFO' | translate }}</th>
      <td mat-cell *matCellDef="let data"> {{data ? (data.fullName + ", " + data?.address?.postalCode  + " " + data?.address?.street) : ''}}</td>
  </ng-container>

  <ng-container matColumnDef="appointmentDate">
    <th *matHeaderCellDef mat-header-cell
        mat-sort-header="lead.termin_date">{{ 'PAGE.LEADS.TERMIN.APPOINTMENT-DATE' | translate }}</th>
    <td mat-cell *matCellDef="let data"> {{(data.lead?.termin_date | date:'dd.MM.yyyy')}}</td>
  </ng-container>
   
  <ng-container matColumnDef="hour">
    <th *matHeaderCellDef mat-header-cell
        mat-sort-header="lead.termin_time">{{ 'PAGE.LEADS.TERMIN.HOUR' | translate }}</th>
    <td mat-cell *matCellDef="let data"> {{(data.lead?.termin_time)}}</td>
</ng-container>
<ng-container matColumnDef="type">
    <th *matHeaderCellDef mat-header-cell
        mat-sort-header="lead.termin_type">{{ 'PAGE.LEADS.TERMIN.TYPE' | translate }}</th>
    <td mat-cell *matCellDef="let data"> {{(data.lead?.termin_type)}}</td>
</ng-container>

<ng-container matColumnDef="statusTermine">
      <th *matHeaderCellDef mat-header-cell
          mat-sort-header="lead.termin_status">{{ 'PAGE.LEADS.TERMIN.STATUS' | translate }}</th>
      <td mat-cell *matCellDef="let data"> {{data.lead && data.lead.termin_status != null  ? data.lead.termin_status : ''}}</td>
</ng-container>
<ng-container matColumnDef="substatusTermine">
    <th *matHeaderCellDef mat-header-cell
        mat-sort-header="lead.termin_substatus">{{ 'PAGE.LEADS.LEAD.SUBSTATUS' | translate }}</th>
    <td mat-cell *matCellDef="let data"> {{data.lead && data.lead.termin_substatus != null  ? data.lead.termin_substatus : ''}}</td>
</ng-container>
<ng-container matColumnDef="category">
    <th *matHeaderCellDef mat-header-cell
        mat-sort-header="lead.termin_kategory">{{ 'PAGE.LEADS.TERMIN.CATEGORY' | translate }}</th>
    <td mat-cell *matCellDef="let data"> {{(data.lead?.termin_kategory)}}</td>
</ng-container>
<ng-container matColumnDef="art">
    <th *matHeaderCellDef mat-header-cell
        mat-sort-header="lead.lead_typ">{{ 'PAGE.LEADS.LEAD.ART' | translate }}</th>
    <td mat-cell *matCellDef="let data"> {{(data.lead?.lead_typ)}}</td>
  </ng-container>
  <ng-container matColumnDef="language">
      <th *matHeaderCellDef mat-header-cell
          mat-sort-header>{{ 'PAGE.LEADS.TERMIN.LANGUAGE' | translate }}</th>
      <td mat-cell *matCellDef="let data"> {{data ? data.preferedLanguage : ''}}</td>
  </ng-container>

  <ng-container *ngIf="isHighRole && !managementView" matColumnDef="advisor">
    <th *matHeaderCellDef mat-header-cell
        mat-sort-header="sorKeys.agent">{{ 'PAGE.LEADS.TERMIN.ADVISOR' | translate }}</th>
    <td mat-cell 
    *matCellDef="let data" >
        {{data.sorKeys['agent'] && data.sorKeys['agent'].split("Neosana Sales AG")[1] ? data.sorKeys['agent'].split("Neosana Sales AG")[1] : data.sorKeys['agent']}}
    </td>
</ng-container>
<ng-container *ngIf="isHighRole && managementView" matColumnDef="advisor">
    <th *matHeaderCellDef mat-header-cell>{{ 'PAGE.CONTAINER.LEADS.TABLES.APPOINTMENTS.ADVISOR' | translate }}</th>
    <td mat-cell 
    *matCellDef="let data" 
    (click)="agentsControlOnClick($event)">
          <mat-form-field class="neomp-full-width">   
            <mat-select [value]="getSelectedAgent(data.lead.termin_berater_shortcut)" (selectionChange)="assignAgentToLead($event.value, data)">
                <mat-option *ngFor="let agent of agents" [value]="agent">
                    {{agent.fullName && agent.fullName.split("Neosana Sales AG")[1] ? agent.fullName.split("Neosana Sales AG")[1] : agent.fullName }}
                </mat-option>
              </mat-select>
          </mat-form-field>
    </td>
</ng-container>
<ng-container matColumnDef="icon">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let data">
      <div class="div-status" [ngClass]="getClassFromStatus(data.uuid)" ></div>
    </td>
  </ng-container>
<ng-container *ngIf="!managementView" matColumnDef="filter">
    <th *matHeaderCellDef mat-header-cell (click)="changeFilterView()"
        class="mat-header-cell mat-column-filter {{isFilterActive ? 'mat-column-filter-active':''}}">
        <div
            class="neomp-table-column-filter-element  mdi {{isFilterActive ? 'mdi-filter':'mdi-filter-outline'}}"></div>
    </th>
    <td mat-cell *matCellDef="let element">
    </td>
</ng-container>

<ng-container *ngIf="managementView" matColumnDef="filter">
    <th *matHeaderCellDef mat-header-cell (click)="changeFilterView()"
        class="mat-header-cell mat-column-filter {{isFilterActive ? 'mat-column-filter-active':''}}">
        <div
            class="neomp-table-column-filter-element  mdi {{isFilterActive ? 'mdi-filter':'mdi-filter-outline'}}"></div>
    </th>
    <td mat-cell *matCellDef="let element" class="mat-column-filter-actions">
        <div class="neomp-table-column-filter-element mdi mdi-plus" [disabled]="isDialogOpen"
        (click)="isDialogOpen ? null : openActionDialog($event, element)"></div>
    </td>
</ng-container>
  
  <!-- filter section -->
<ng-container matColumnDef="termineSelectFilter" *ngIf="managementView">
    <th mat-header-cell *matHeaderCellDef>
    </th>
</ng-container>
<ng-container matColumnDef="appointmentInfoFilter">
    <th mat-header-cell *matHeaderCellDef>
        <mat-form-field floatLabel="never">
            <input matInput [formControl]="appointmentInfoFilterFC"
                placeholder="{{ 'TABLES.FILTERPLACEHOLDER.APPOINTMENTINFO' | translate }}">
        </mat-form-field>
    </th>
</ng-container>

<ng-container matColumnDef="appointmentDateFilter">
    <th mat-header-cell *matHeaderCellDef>
        <mat-form-field floatLabel="never">
            <input [matDatepicker]="date" matInput [formControl]="appointmentDateFilterFC"
                placeholder="{{ 'TABLES.FILTERPLACEHOLDER.APPOINTMENTDATE' | translate }}">
            <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
            <mat-datepicker #date></mat-datepicker>
        </mat-form-field>
    </th>
</ng-container>

<ng-container matColumnDef="hourFilter">
    <th mat-header-cell *matHeaderCellDef>
        <mat-form-field floatLabel="never">
            <input matInput [formControl]="hourFilterFC"
                placeholder="{{ 'TABLES.FILTERPLACEHOLDER.HOUR' | translate }}">
        </mat-form-field>
    </th>
</ng-container>
<ng-container matColumnDef="typeFilter">
    <th mat-header-cell *matHeaderCellDef>
        <mat-form-field floatLabel="never">
            <input matInput [formControl]="typeFilterFC"
                placeholder="{{ 'TABLES.FILTERPLACEHOLDER.TYPE' | translate }}">
        </mat-form-field>
    </th>
</ng-container>
<ng-container matColumnDef="statusFilter">
    <th mat-header-cell *matHeaderCellDef>
        <mat-form-field floatLabel="never">
            <input matInput [formControl]="statusFilterFC"
                placeholder="{{ 'TABLES.FILTERPLACEHOLDER.STATUS' | translate }}">
        </mat-form-field>
    </th>
</ng-container>
<ng-container matColumnDef="substatusFilter">
    <th mat-header-cell *matHeaderCellDef>
        <mat-form-field floatLabel="never">
            <input matInput [formControl]="substatusFilterFC"
                placeholder="{{ 'TABLES.FILTERPLACEHOLDER.SUBSTATUS' | translate }}">
        </mat-form-field>
    </th>
</ng-container>
<ng-container matColumnDef="categoryFilter">
    <th mat-header-cell *matHeaderCellDef>
        <mat-form-field floatLabel="never">
            <input matInput [formControl]="categoryFilterFC"
                placeholder="{{ 'TABLES.FILTERPLACEHOLDER.CATEGORY' | translate }}">
        </mat-form-field>
    </th>
</ng-container>
<ng-container matColumnDef="artFilter">
    <th mat-header-cell *matHeaderCellDef>
        <mat-form-field floatLabel="never">
            <input matInput [formControl]="artFilterFC" placeholder="{{ 'TABLES.FILTERPLACEHOLDER.ART' | translate }}">
        </mat-form-field>
    </th>
</ng-container>
<ng-container matColumnDef="languageFilter">
    <th mat-header-cell *matHeaderCellDef>
        <mat-form-field floatLabel="never">
            <input matInput [formControl]="languageFilterFC"
                placeholder="{{ 'TABLES.FILTERPLACEHOLDER.LANGUAGE' | translate }}">
        </mat-form-field>
    </th>
</ng-container>
<ng-container *ngIf="isHighRole" matColumnDef="advisorFilter">
    <th mat-header-cell *matHeaderCellDef>
        <mat-form-field floatLabel="never">
            <input matInput [formControl]="advisorFilterFC"
                placeholder="{{ 'TABLES.FILTERPLACEHOLDER.ADVISOR' | translate }}">
        </mat-form-field>
    </th>
</ng-container>
<ng-container matColumnDef="iconFilter">
    <th mat-header-cell *matHeaderCellDef>
    </th>
</ng-container>
<ng-container matColumnDef="filterRemove">
    <th mat-header-cell *matHeaderCellDef (click)="resetFilter()">
        <div class="neomp-table-column-filter-element mdi mdi-close"></div>
    </th>
</ng-container>

  // 'isLead', 'status', 'id', 'currentHealthInsurance','conclusion', 'feed'
  <tr *matHeaderRowDef="displayedColumns"
      mat-header-row></tr>

  <tr *matHeaderRowDef="displayedColumnFilters"
      class="neomp-table-row-filter {{showFilter? '': 'neomp-table-row-filter-hide'}}"
      mat-header-row></tr>

      // 'isLead', 'status', 'id', 'currentHealthInsurance','conclusion', 'feed'
  <tr mat-row *matRowDef="let element; columns: displayedColumns; let i = dataIndex;"
      class="family-table-row container-table-row"      
      (click)="redirectToEdit(element.uuid);">>
  </tr>
</table>
