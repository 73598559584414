<div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop
                            mdc-layout-grid__cell--span-12-tablet
                            mdc-layout-grid__cell--span-12-phone
                            mdc-layout-grid--align-right
                            filter-style">
    <neomp-slide-range-filter
        [disabled]="isDataLoading"
        [filterValue]="filterValue"
        (filterChangeEmitter)="getElements($event)">
    </neomp-slide-range-filter>
</div>
<mat-card>
  <div class="search-fields-layout-grid" (keydown.enter)="search()">
        <mat-form-field class="neomp-full-width">
            <mat-label>{{ 'PAGE.LEADS.LEAD.TEXT' | translate }}</mat-label>
            <input matInput [(ngModel)]="searchText" />
        </mat-form-field>
        <mat-form-field class="neomp-full-width">
            <mat-label>{{ 'PAGE.CONTAINER.COMMISSIONS.TABLES.PROVISIONS.TRANSACTIONNAME' | translate }}</mat-label>
            <mat-select  multiple [formControl]="transactionsNameSelected">
                <mat-option *ngFor="let typeName of allTransactionsName"
                    [value]="typeName">{{typeName}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="neomp-full-width">
            <mat-label>{{ 'PAGE.CONTAINER.COMMISSIONS.TABLES.PROVISIONS.DESCRIPTIONFIELD' | translate }}</mat-label>
            <input matInput [(ngModel)]="searchDescription" />
        </mat-form-field>
  </div>
  <div class="search-fields-layout-grid neo-padding-top-10 margin-top-15">
     <button mat-raised-button class="neomp-full-width button-text" color="primary"
          (click)="search()">
          <span class="material-icons">
              search
          </span>{{ 'PAGE.LEADS.TERMIN.SEARCH' | translate }}
      </button>
      <button mat-raised-button class="clear-button neomp-full-width button-text" (click)="clearSearch()">
          <span class="material-icons">clear</span>{{ 'PAGE.LEADS.LEAD.CLEAR' | translate }}
      </button>
  </div>
</mat-card>
<br/>
<div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop
                            mdc-layout-grid__cell--span-12-tablet
                            mdc-layout-grid__cell--span-12-phone">
    <neomp-container cssClass="msg-mp-container-frameless"
                     headlineText="{{ 'PAGE.CONTAINER.COMMISSIONS.HEADERS.TABLEPROVISIONS' | translate }}">
        <neomp-provision-table [tableEntries]="journalLists"></neomp-provision-table>
        <neomp-loading-data #loadingDataComponent></neomp-loading-data> 
      <neomp-paginator
      [length]="totalCount" 
      [pageSize]="pageSize" 
      [pageIndex]="pageIndex" 
      (paginatorChange)="paginatorValueChanged($event)"
      >  
      </neomp-paginator> 
    </neomp-container>
</div>
