import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { Journal } from "../../model/journal";
import { FormControl } from "@angular/forms";
import { JournalJson } from "../../json/journal.json";
import { Helper } from "../../static/helper";
import { FilterTableComponent } from "./filter-table-component";
import { ActionDialogData } from "../action.dialog.component";
import { KeyValuePair } from "../../model/key-value-pair";
import { ConfigProvider } from "../../providers/config.provider";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { MessagesProvider } from "../../providers/messages.provider";
import { CommunicationService } from "../../providers/communication.service";
import { forkJoin, Observable, of, Subscription } from "rxjs";
import { ServiceTimer } from "../../providers/service-timer";
import { RestProvider, RestProviderActionsDummy } from "../../providers/rest.provider";
import { DocumentResourceResult } from "../../model/document-resource-result";
import { ActionDialogService } from "../../providers/action-dialog.service";
import { DocumentProvider } from "../../providers/document.provider";
import { BusinessTransactionHelper } from "../../static/bt-helper";
import { LoadingDataComponent } from "../loading-data.component";
import { catchError, map } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { log } from "../../providers/logger.provider";
import { MessageType } from "../../model/enum-messagetype";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MessagesDialogComponent } from "../messages-dialog.component";
import { TranslateService } from "@ngx-translate/core";
import { UserCommitState } from "../../model/user-commit-state";
import { SlideRangeFilterValue } from "../../model/slide-range-filter-value";

@Component({
    selector: "neomp-messages-table",
    templateUrl: "./messages-table.component.html",
    styleUrls: ["./messages-table.component.scss"],
    animations: [
        trigger("detailExpand", [
            state("collapsed, void", style({ height: "0px", minHeight: "0", display: "none" })),
            state("expanded", style({ height: "*" })),
            transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")),
            transition("expanded <=> void", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)"))
        ]),
    ]
})

export class MessagesTableComponent extends FilterTableComponent<Journal> implements OnInit, OnDestroy {
    private readonly TAG = this.constructor.name;

    selectedJournalRowIndex;
    isDialogOpen = false;
    relatedMessages: Journal[] = [];
    expandedElement: Journal | null;

    iconFilter = new FormControl("");
    creationDateFilter = new FormControl("");
    typeNameFilter = new FormControl("");
    reasonForChangeFilter = new FormControl("");

    filterValues: Journal = new Journal();

    formControlFilters: FormControl[] = [
        this.iconFilter,
        this.creationDateFilter,
        this.typeNameFilter,
        this.reasonForChangeFilter,
        null,
        null
    ];

    displayedColumns = [
        "icon",
        "creationDate",
        "typeName",
        "reasonForChange",
        "document",
        "msgKind",
        "filter",
        "viewRelatedMsg"
    ];

    displayedColumnFilters = [
        "iconFilter",
        "creationDateFilter",
        "typeNameFilter",
        "reasonForChangeFilter",
        "documentFilter",
        "filterRemove"
    ];

    configKeyValues: KeyValuePair[] = [];

    timer: ServiceTimer;
    serviceTimerSubscription: Subscription;
    callback;

    @ViewChild("messagesTable") journalTableLoadingComponent: LoadingDataComponent;
    @Input()
    journalData: Map<string, DocumentResourceResult[]> = new Map<string, DocumentResourceResult[]>();

    @Output() dataRelatedMessages = new EventEmitter<{ relatedMessages: JournalJson[], relatedMsgJournalData: any }>();

    relatedMsgJournalData: Map<string, DocumentResourceResult[]> = new Map<string, DocumentResourceResult[]>();
    constructor(
        private dialog: MatDialog,
        private translateService: TranslateService,
        private matSnackBar: MatSnackBar,
        public configProvider: ConfigProvider,
        public messagesProvider: MessagesProvider,
        public communicationService: CommunicationService,
        public dialogService: ActionDialogService,
        public documentProvider: DocumentProvider) {
        super();

        this.configProvider.getConfigValue({ defaultTypeId: "uiConfig" })
            .subscribe(value => {
                const readMessageDelay = value.data !== null ? value.data.find(value => value.key === "readMessageDelay") : null;
                let messageDelay: number;
                if (!readMessageDelay) {
                    messageDelay = 100;
                } else {
                    messageDelay = +readMessageDelay.value;
                }

                this.timer = new ServiceTimer(messageDelay, -1);
                this.serviceTimerSubscription = this.timer.tick().subscribe(() => {
                    if (this.callback) {
                        this.callback();
                    }
                });
                this.timer.stop();
            });
    }

    ngOnInit(): void {
        this.communicationService.getMessage().subscribe((data: { type: string, text: string }) => {
            if (data.type === "readMessage" && data.text) {
                const index = this.dataSource.data.findIndex(x => x.sorKeys.groupId === data.text);
                if (index > -1) {
                    this.dataSource.data[index].unseenMessagesCount = this.dataSource.data[index].unseenMessagesCount - 1;
                }
            }
        });
    }

    creationDateFilterChanged(event: SlideRangeFilterValue): void {
        this.creationDateFilter.setValue(event.dateRange);
    }

    getDocuments(journal: Journal): DocumentResourceResult[] {
        if (this.journalData.has(journal.uuid)) {
            return this.journalData.get(journal.uuid);
        }
        return [];
    }

    journalRowClick(journalRow: Journal) {
        if (this.expandedElement === journalRow) {
            this.selectedJournalRowIndex = -1;
            this.expandedElement = null;
            this.relatedMessages = [];
            this.timer.stop();
            return;
        }
        this.selectedJournalRowIndex = journalRow.uuid;
        this.expandedElement = journalRow;

        if (!journalRow.status.completed) {
            this.timer.stop();
            this.callback = () => {
                return this.markMessagesAsRead(journalRow);
            }
            this.timer.start();
        }
    }

    markMessagesAsRead(journal: Journal) {
        this.messagesProvider.markMessagesAsRead(new RestProviderActionsDummy(), [journal.uuid])
            .subscribe(result => {
                journal.status.completed = true;
                this.communicationService.sendMessage("readMessage");
            });
    }

    createFilter(): (data: Journal, filter: string) => boolean {
        return function (data, filter): boolean {
            const filterJsonAny = JSON.parse(filter);
            const filterJson: JournalJson = JSON.parse(filter);
            const searchTerms: Journal = Journal.fromJson(filterJson);

            return Helper.checkDateRange(data.getCreationDate(), Helper.stringToDatePickerFormat(searchTerms.creationDate)) &&
                Helper.checkStringContains(data.typeName, searchTerms.typeName) &&
                Helper.checkStringContains(data.reasonForChange, searchTerms.reasonForChange) &&
                Helper.checkStringContains(data.descriptionField, searchTerms.descriptionField) &&
                (!filterJsonAny.icon || (filterJsonAny.icon && !data.status.completed));
        };
    }

    resetFilter() {
        this.filterValues = new Journal();
        super.resetFilter();
    }

    ngOnDestroy(): void {
        if (this.serviceTimerSubscription) {
            this.serviceTimerSubscription.unsubscribe();
        }
    }

    download(id: string) {
        if(id){
            this.documentProvider.downloadDocument(id);
        }
        else{
            const translatedMessage = this.translateService.instant('PAGE.CONTAINER.MESSAGES.ERROR-DOCUMENT');
            this.matSnackBar.open(translatedMessage, 'X', UserCommitState.configCheckData);
        }
    }

    downloadFromMyNeo(id: string) {
        if(id){

            this.documentProvider.downloadDocument(id, true);
        }
        else{
            const translatedMessage = this.translateService.instant('PAGE.CONTAINER.MESSAGES.ERROR-DOCUMENT');
            this.matSnackBar.open(translatedMessage, 'X', UserCommitState.configCheckData);
        }
    }

    getRelatedMessages(journal: Journal): void {

        let subscriptions: Observable<any>[] = [];
        for (let message of journal.relatedMessages) {
            let references = message.references.filter(reference => reference.type.key === "050");

            for (let reference of references) {
                const subscription = this.documentProvider.getDocumentById(
                    reference.id,
                    new RestProviderActionsDummy())
                    .pipe(
                        map(result => ({ id: message.uuid, document: result })),
                        // do not let your observables die; forkJoin will the not be triggered then.
                        catchError(err => of(err))
                    );

                subscriptions.push(subscription);
            }

            // type "MyNeoDocument" refers to a temporary document
            let tempDocumentReferences = message.references.filter(reference => reference.type.key === "MyNeoDocument");
            for (let tempDocumentReference of tempDocumentReferences) {
                let documentResource = new DocumentResourceResult()
                documentResource.id = tempDocumentReference.id;
                documentResource.fromMyNeo = true;
                
                subscriptions.push(of({id: message.uuid, document: documentResource }));
            }

        }



        forkJoin(subscriptions).subscribe(results => {
            let journalData: Map<string, DocumentResourceResult[]> = new Map<string, DocumentResourceResult[]>();

            for (let result of results) {
                if (result instanceof HttpErrorResponse) {
                    // do not let your observables die; forkJoin will the not be triggered.
                    log.error(`${this.TAG}; unexpected response of document request; msg=${result.message}`);
                } else {
                    if (!journalData.has(result.id)) {
                        journalData.set(result.id, [result.document]);
                    } else {
                        journalData.get(result.id).push(result.document);
                    }
                }
            }
            this.relatedMsgJournalData = journalData;
        });

        if (journal.relatedMessages.length > 0) {
            // open related messages
            const data = {
                relatedMessages: journal.relatedMessages,
                relatedMsgJournalData: this.relatedMsgJournalData
            }
            this.dataRelatedMessages.emit(data);

            // this.dialog.open(MessagesDialogComponent,
            //     {
            //         width: '80%',
            //         maxHeight: '90vh',
            //         panelClass: 'neomp-dialog-padding-none',
            //         data: { 
            //             relatedMessages: journal.relatedMessages,
            //             relatedMsgJournalData: this.relatedMsgJournalData
            //         }
            //     });
        } else {
            const data = {
                relatedMessages: [],
                relatedMsgJournalData: []
            }
            this.dataRelatedMessages.emit(data);
        }
    }
    openActionDialog(event: Event, element: Journal) {
        this.isDialogOpen = true;
        event.stopPropagation();
        const dialogData: ActionDialogData = {
            transTypeId: element.typeId,
            transName: "Nachricht",
            transSubject: "Nachricht",
            transTitle: "Nachricht",
            transMessage: "Nachricht",
            operationalIdExternal: element.sorKeys["ProcessID"],
            keyValues: [],
            person: null,
            groupId: element.sorKeys["groupId"] || ""
        };
        this.dialogService.openByType(dialogData,
            {
                defaultTypeId: BusinessTransactionHelper.typeId.message.from,
                typeId: element.typeId
            },
            this.TAG
        );
        this.dialog.afterAllClosed.subscribe(() => {
            this.isDialogOpen = false;
        });
    }

    public get msgType(): typeof MessageType {
        return MessageType;
    }

}
