import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ConfirmationComponent } from '../components/confirmation-component/confirmation.component';
import { MatDialog } from '@angular/material/dialog';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

export const CanDeactivateState = {
  defendAgainstBrowserBackButton: true
};

@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
  constructor(private matDialog: MatDialog) {}

  canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
    return component.canDeactivate() || this.matDialog.open<ConfirmationComponent, void, boolean>(ConfirmationComponent, {
        disableClose: true,
      }).afterClosed().pipe(
        tap(confirmed => {
          if (!confirmed && CanDeactivateState.defendAgainstBrowserBackButton) {
            history.pushState(null, '', '');
          }
        })
      );
  }
}

