import {Injectable} from "@angular/core";

export class Config {
    public key: string;
    public value: any;
    public param: string[] = [];
    public anchors: string[] = [];
    public data: any;
}

@Injectable()
export class RegisterProvider {
    private readonly componentRegister: Config[] = [];
    private readonly pathRegister: Config[] = [];

    constructor() {
        this.componentRegister = [];
        this.pathRegister = [];
    }

    public register(name: string, path: string, component: any, routerParameter: string[], routerAnchors: string[], data: any) {
        const newComponent: Config = new Config();
        newComponent.key = name;
        newComponent.value = component;
        this.componentRegister.push(newComponent);
        const newPath: Config = new Config();
        newPath.key = name;
        newPath.value = path;
        newPath.param = routerParameter;
        newPath.anchors = routerAnchors;
        newPath.data = data;
        this.pathRegister.push(newPath);
    }

    public getComponent(name: string): any {
        const entry = this.componentRegister.filter((item) => (item.key === name));
        return typeof entry === "undefined" || entry.length === 0 ? null : entry.shift().value;
    }
    
    public setPath(name: string, newPath: string): any {
        const entry = this.pathRegister.filter((item) => (item.key === name));
        if (!(typeof entry === "undefined" || entry.length === 0)) {
            entry.shift().value = newPath;
        } 
    }
    public getPath(name: string): any {
        const entry = this.pathRegister.filter((item) => (item.key === name));
        return typeof entry === "undefined" || entry.length === 0 ? null : entry.shift().value;
    }

    public getPathAnchor(pathName, anchorName: string): any {
        const entry = this.pathRegister.filter((item) => (
                item.key === pathName && (
                    item.anchors && (item.anchors.indexOf(anchorName, 0) >= 0))
            )
        );
        const element = (typeof entry === "undefined" || entry.length === 0 ? null : entry.shift());
        if (element && element.anchors && element.anchors.indexOf(anchorName, 0) >= 0) {
            return `#/${element.value}#${element.anchors.find(anchor => anchor === anchorName)}`;
        }
        return null;
    }

    public getPathParamForKey(name: string): any {
        const entry = this.pathRegister.filter((item) => (item.key === name));
        return typeof entry === "undefined" || entry.length === 0 ? null : entry.shift().param;
    }

    public getPathDataForValue(path: string): any {
        const entry = this.pathRegister.filter((item) => (item.value === path.substr(1)));
        return typeof entry === "undefined" || entry.length === 0 ? null : entry.shift().data;
    }
}
