import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
// import "rxjs-compat/add/observable/of";
import {RestProvider, RestProviderActionsDummy} from "./rest.provider";
import {environment} from "../../../../environments/environment";
import {ServiceVersion} from "../json/service-version";

@Injectable()
export class VersionProvider {
    private readonly CLASS_NAME = this.constructor.name;

    constructor(public http: HttpClient, public rest: RestProvider) {
    }

    public getVersion(): Observable<ServiceVersion> {
        return this.rest.get<ServiceVersion>(`${environment.settings.BASE_URL}/api/version/complete`, null, true, new RestProviderActionsDummy());
    }
}
