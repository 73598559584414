import { JournalJson } from "../json/journal.json";
import { Reference } from "./reference";
import { Status } from "./status";
import { Helper } from "../static/helper";
import { MessageType } from "./enum-messagetype";

export class Journal {
    uuid = "";
    reasonForChange = "";
    typeId = "";
    descriptionField = "";
    typeName = "";
    transactionId = "";
    transactionName = "";
    inputChannel = "";
    creationDate = "";
    creationDateOnly = "";
    effectiveDate = "";
    ordererName = "";
    ordererIdExternal = "";
    ordererIdInternal = "";
    operationIdExternal = "";
    status: Status = new Status();
    references: Reference[] = [];
    classification: Reference = null;
    msgKind: MessageType;
    sorKeys: { [key: string]: string } = {};
    offer_data: { [key: string]: string } = {};
    relatedMessages: Array<JournalJson> = [];
    unseenMessagesCount?: number;
    cancellation_approval?: boolean;

    public static fromJson(response: JournalJson): Journal {
        const result = new Journal();
        result.uuid = response.uuid || "";
        result.reasonForChange = response.reasonForChange || "";
        result.typeId = response.typeId || "";
        result.typeName = response.typeName || "";
        result.descriptionField = response.descriptionField || "";
        result.transactionId = response.transactionId || "";
        result.transactionName = response.transactionName || "";
        result.inputChannel = response.inputChannel || "";
        result.status = new Status(response.status);
        result.references = Reference.fromJsons(response.references);
        result.classification = new Reference(response.classification);
        result.creationDate = response.creationDate || "";
        result.creationDateOnly = Helper.formatDate(new Date(response.creationDate)) || "";
        result.effectiveDate = response.effectiveDate || "";
        result.ordererName = response.ordererName || "";
        result.ordererIdExternal = response.ordererIdExternal || "";
        result.ordererIdInternal = response.ordererIdInternal || "";
        result.operationIdExternal = response.operationIdExternal || "";
        result.sorKeys = response.sorKeys || {};
        result.msgKind = response.msgKind;
        result.relatedMessages = response.relatedMessages;
        result.unseenMessagesCount = response.unseenMessagesCount;
        if (response.cancellation_approval) {
            result.cancellation_approval = response.cancellation_approval;
        }
        return result;
    }

    public static fromJsons(response: JournalJson[]): Journal[] | undefined {
        if (response) {
            return response.map(journal => Journal.fromJson(journal));
        }
    }

    getEffectivDateOnly() {
        return Helper.formatDate(new Date(this.effectiveDate))
    }

    getEffectivDate() {
        return new Date(this.effectiveDate)
    }
    getCreationDate() {
        return new Date(this.creationDate)
    }
    getCreationDateOnly() {
        return Helper.formatDate(new Date(this.creationDate))
    }
}
