<div class="mdc-layout-grid msg-helper-padding-none msg-helper-float padding-top-50-small-size">
    <div class="mdc-layout-grid__inner">

      <div
        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
        <div class="neo-create-lead-btn">
            <button mat-button [routerLink]="['/create-lead']" class="neo-text-btn">
              <img src="/assets/icons/add-lead.svg"><span>{{ 'PAGE.LEADS.LEAD.CREATE-LEAD' | translate }}</span>
            </button>
          </div>
        <mat-tab-group #tabGroup class="neo-border-none neo-label">
          <mat-tab label="Overview">
            <ng-template mat-tab-label>
                {{ 'MENU.LEADS-NAV.OVERVIEW' | translate }}
                <span class=" retention-badge" *ngIf="totalCountRetentionLeads != 0">
                  {{ totalCountRetentionLeads }}
                </span>
              </ng-template>
            <div class="mdc-layout-grid msg-helper-padding-none msg-helper-margin-top-10">
                <div class="search-fields-layout-grid">
                  <div
                    class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
                    <!-- filters -->
                    <mat-card>
                      <!-- filter fields -->
                      <div class="search-fields-layout-grid" >
                        <mat-form-field class="neomp-full-width">
                            <mat-label>{{ 'PAGE.LEADS.LEAD.TEXT' | translate }}</mat-label>
                            <input matInput [(ngModel)]="searchText" />
                          </mat-form-field>
  
                        <mat-form-field class="neomp-full-width">
                            <mat-label>{{ 'PAGE.LEADS.LEAD.FROM' | translate }}</mat-label>
                            <input [matDatepicker]="Von" matInput [(ngModel)]="dateFrom" />
                            <mat-datepicker-toggle matSuffix [for]="Von"></mat-datepicker-toggle>
                            <mat-datepicker #Von></mat-datepicker>
                          </mat-form-field>
  
                          <mat-form-field class="neomp-full-width">
                            <mat-label>{{ 'PAGE.LEADS.LEAD.TO' | translate }}</mat-label>
                            <input [matDatepicker]="Bis" matInput [(ngModel)]="dateTo" />
                            <mat-datepicker-toggle matSuffix [for]="Bis"></mat-datepicker-toggle>
                            <mat-datepicker #Bis></mat-datepicker>
                          </mat-form-field>
  
                          <mat-form-field class="neomp-full-width">
                            <mat-label>StatusList</mat-label>
                            <mat-select [formControl]="statusSelected" (selectionChange)="statusesSelected($event)"
                              multiple>
                              <mat-option value="Neu">Neu</mat-option>
                              <mat-option *ngFor="let status of statusesList" [value]="status">{{status}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
  
                          <mat-form-field class="neomp-full-width">
                            <mat-label>Substatus List</mat-label>
                            <mat-select [formControl]="substatusSelected" multiple>
                              <mat-optgroup *ngFor="let status of substatusesList" [label]="status.title"
                                [disabled]="status.disabled">
                                <mat-option *ngFor="let substatus of status.value" [value]="substatus">{{substatus}}
                                </mat-option>
                              </mat-optgroup>
                            </mat-select>
                          </mat-form-field>
                
                      </div>
                      <!-- buttons -->
                      <div class="search-fields-layout-grid margin-top-15">
                        <div>
                          <div class="neo-padding-top-10">
                            <button mat-raised-button class="neomp-full-width button-text" color="primary"
                             (click)="searchLeads()">
                              <span class="material-icons">
                                search
                              </span>{{ 'PAGE.LEADS.LEAD.SEARCH' | translate }}
                            </button>
                          </div>
                        </div>
                        <div>
                          <div class="neo-padding-top-10">
                            <button mat-raised-button class="clear-button neomp-full-width button-text" (click)="clearSearch()">
                              <span class="material-icons">clear</span>{{ 'PAGE.LEADS.LEAD.CLEAR' | translate }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </mat-card>
                    <br/>
                    <neomp-container cssClass="msg-mp-container-frameless" headlineText="{{
                        'MENU.LEADS-NAV.RETENTION-LEADS' | translate
                      }}">
                      <neomp-leads-table [isRetention]="isRetention" [tableEntries]="retentionLeads" [leads]="retentionLeads"></neomp-leads-table>
                      <neomp-loading-data #loadingFamilyTable></neomp-loading-data>
                      <neomp-paginator [length]="totalCountRetentionLeads" [pageSize]="pageSize" [pageIndex]="pageIndex"
                        [pageSizeOptions]="pageSizeOptions" (paginatorChange)="retentionLeadsPaginatorValueChanged($event)">
                      </neomp-paginator>
                    </neomp-container>
                  </div>
                </div>
              </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>