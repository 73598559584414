import { LeadProvider } from './../../core/providers/lead.provider';
import { Component, OnInit, ViewChild } from "@angular/core";
import { HouseholdProvider } from "../../core/providers/household.provider";
import { LoadingDataComponent } from "../../core/components/loading-data.component";
import { Household } from "../../core/model/household";
import { CustomersCacheProvider } from "../../core/providers/customers-cache.provider";
import { Breadcrumb } from "../../core/model/breadcrumb";
import { TranslateService } from "@ngx-translate/core";
import { RestProviderActions } from "app/modules/core/providers/rest.provider";
import { PaginationConfigs } from "../../constants/PaginationConstants";
import { Person } from 'app/modules/core/model/person';
import { KeycloakService } from 'keycloak-angular';
import { AgenturType } from 'app/modules/core/model/enum-agenturtype';
@Component({
    selector: "neomp-my-customers-page",
    templateUrl: "./my-customers-page.component.html",
    styleUrls: ["./my-customers-page.component.scss"]
})
export class MyCustomersPageComponent implements OnInit {
    pageSize = PaginationConfigs.pageSize;
    pageSizeOptions = PaginationConfigs.pageSizeOptions;
    pageIndex = PaginationConfigs.pageIndex;
    totalCount = 0;
    totalCountLeads = 0;
    breadcrumbs: Breadcrumb[] = [];
    mandatesOnly = false;
    households: Household[] = [];
    leads: Person[] = [];

    @ViewChild("loadingFamilyTable") loadingFamilyTable: LoadingDataComponent;

    agenturType: string = "";
    isClient: Boolean = false;

    constructor(
        public householdProvider: HouseholdProvider,
        public leadProvider: LeadProvider,
        public customersCacheProvider: CustomersCacheProvider,
        public keycloak: KeycloakService,
        public translateService: TranslateService) {
        ;
    }

    ngOnInit(): void {
        this.keycloak.loadUserProfile().then(async (res: any) => {
            this.agenturType = res.attributes.agenturType[0];
            this.isClient = this.agenturType === AgenturType.Client;           
            
            this.breadcrumbs.push({ label: this.isClient ? "PAGE.CONTAINER.CUSTOMERS.CONTAINER.CLIENTHEADLINE" : "PAGE.CONTAINER.CUSTOMERS.CONTAINER.HEADLINE" });
        });

        if (this.customersCacheProvider.getCache() != null) {
            this.mandatesOnly = this.customersCacheProvider.getCache().mandat;
        }

        this.getHouseholds(this.loadingFamilyTable, this.pageIndex, this.pageSize, false, this.mandatesOnly);
    }

    getHouseholds(restProviderActions: RestProviderActions, page: number, size: number, useCache?: boolean, mandatesOnly?: boolean) {
        this.households = [];
        this.householdProvider.getLazyHouseholds(restProviderActions, useCache, mandatesOnly, page, size)
            .subscribe((households: { data: Household[], totalCount: number }) => {
                this.households = households.data;
                this.totalCount = households.totalCount;
            });
    }
    loadLeads(restProviderActions: RestProviderActions, page: number, size: number) {
        this.leads = [];
        this.leadProvider.getLazyLeads(restProviderActions, page, size)
            .subscribe((leads: { data: Person[], totalCount: number }) => {
                this.leads = leads.data;
                this.totalCountLeads = leads.totalCount;
            });
    }
    householdsToggle() {
        this.getHouseholds(this.loadingFamilyTable, this.pageIndex, this.pageSize, false, this.mandatesOnly);
    }
    paginatorValueChanged(event) {
        if (Number(this.pageIndex) === Number(event.pageIndex) && Number(this.pageSize) === Number(event.pageSize)) return;
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
        this.getHouseholds(this.loadingFamilyTable, this.pageIndex, this.pageSize, false, this.mandatesOnly);
        this.loadLeads(this.loadingFamilyTable, this.pageIndex, this.pageSize);
    }

}
