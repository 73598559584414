import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Person } from "../model/person";
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { CommunicationAddress } from "../model/communication-address";
import { CommAddrTypes } from "../model/enum-commaddrtype";
import { CommAddrType } from "../model/commaddrtype";
import { log } from "../providers/logger.provider";
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from "@angular/material/core";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import moment from "moment";
import { Observable } from "rxjs";
import { debounceTime, startWith, map } from "rxjs/operators";
import { environment } from "environments/environment";
import { HttpClient } from "@angular/common/http";

export const MY_FORMATS = {
  parse: {
    dateInput: "DD.MM.YYYY",
  },
  display: {
    dateInput: "DD.MM.YYYY",
    dateA11yLabel: "L",
  },
};

export interface Region {
  PLZ: number;
  Ort: string;
  Kanton: string;
  Bfs: number;
  Region: number;
  Gemeinde: string;
}

export function forbiddenObjectValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return typeof control.value !== 'object' || control.value === null ? { forbiddenObject: true } : null;
  }
}

@Component({
  selector: "neomp-contact-data",
  templateUrl: "./contact-data.component.html",
  styleUrls: ["./contact-data.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class ContactDataComponent implements OnInit, OnChanges {
  private readonly TAG = this.constructor.name;

  @Input()
  person: Person;
  @Input()
  readonly = true;
  @Input()
  showCompleteForm = false;

  @Output()
  valid: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  genderData: string[] = ["weiblich", "männlich"];
  salutationData: string[] = ["Herr", "Frau"];
  maritalStatuses: string[] = ["Ledig", "Verheiratet", "Geschieden", "Verwitwet"]


  defaultContactDataForm:FormGroup=new FormGroup({
    name: new FormControl("", Validators.required),
    firstName: new FormControl("", Validators.required),
    birthdate: new FormControl("", Validators.required),
    gender: new FormControl("", Validators.required),
    salutation: new FormControl("", Validators.required),
    maritalStatus: new FormControl("", Validators.required),
    street: new FormControl("", Validators.required),
    postalCode: new FormControl("", Validators.required),
    nationality: new FormControl("", [Validators.required, forbiddenObjectValidator()]),
    city: new FormControl("", Validators.required),
    uuid: new FormControl("", Validators.required),
    grantList: new FormControl(""),
    telNumber: new FormControl("",Validators.required),
    phoneNumber: new FormControl("",Validators.required),
    email: new FormControl("",[Validators.required, Validators.email])
  });
  
  contactDataForm: FormGroup=this.defaultContactDataForm;
  grantLists: string[] = ["C", "B", "A", "L", "F"];
  registerForm: FormGroup;


  mandatoryFields = [
    "name",
    "firstName",
    "birthdate",
    "gender",
    "salutation",
    "nationality",
    "maritalStatus",
    "street",
    "postalCode",
    "city",
    "uuid",
    "grantList",
    "telNumber",
    "phoneNumber",
    "email"
  ];
  filteredOptions: Observable<Region[]>;
  filteredOptionsNationen: Observable<String[]>;

  postalCode: FormControl = new FormControl("", [Validators.required, forbiddenObjectValidator()]);

  optionsNationen: string[] = [];
  options: Region[] = [];

  private readonly MIN_REGION_COMPLETER_LENGTH = 3;
  displayRegionFn(region: Region) {
    if (!region) return '';
    return `${region.PLZ}`;
  }
  displayNationFn(nation: string) {
    if (!nation) return '';
    return `${nation}`;
  }
  selectedRegion(ev: any): any {
    this.contactDataForm.controls.postalCode.setValue(ev.option.value.PLZ)
    this.contactDataForm.controls.city.setValue(ev.option.value.Ort + " (" + ev.option.value.Gemeinde + ")")
    this.postalCode.setValue(ev.option.value)
    this.person.address["postalCode"] = ev.option.value.PLZ.toString();
    this.person.address["city"] = ev.option.value.Ort + " (" + ev.option.value.Gemeinde + ")";
  }
  selectedNation(ev: any): any {   
    this.contactDataForm.controls.nationality.setValue(ev.option.value)
    this.person["nationality"] = ev.option.value;
  }

  constructor(private http: HttpClient) {}

  ngOnInit() {
    this.contactDataForm = new FormGroup({});
    if (!this.readonly) {
      this.contactDataForm = this.defaultContactDataForm;
    } else {
      this.mandatoryFields.forEach((value) =>
        this.contactDataForm.addControl(value, new FormControl())
      );
    }
    this.contactDataForm.valueChanges.subscribe((x) => {
      this.valid.emit(this.contactDataForm.valid);
    });

    // Nations
    this.http.get<string[]>(`${environment.settings.API_NATIONS}`).subscribe((res: string[]) => {
      const mappedNationsName=res.map((n:any)=>n.name.common)
      this.optionsNationen = mappedNationsName;
    })
    this.filteredOptionsNationen = this.contactDataForm.controls["nationality"].valueChanges.pipe(
      map((value: string) => this._filterNations(value || '')),
    )
    this.filteredOptionsNationen.subscribe(f=>{})

    // Regionen
    this.http.get<Region[]>(`${environment.settings.VGR_URL}/regionen`).subscribe((res: Region[]) => {
      this.options = res;
    })
    this.filteredOptions = this.postalCode.valueChanges.pipe(
      debounceTime(200),
      startWith(''),
      map((value: string) => this._filter(value || '')),
    )    
    this.filteredOptions.subscribe(f=>{})
  }

  private _filter(value: string): Region[] {
    const filterValue = value.toString();
    if (value !== "" && filterValue.length >= this.MIN_REGION_COMPLETER_LENGTH)
      return this.options.filter(option => option.PLZ.toString().includes(filterValue));
    else return [];
  }
  private _filterNations(value: string): string[] {
    const filterValue = value.toString();
    if (value !== "" && filterValue.length >= this.MIN_REGION_COMPLETER_LENGTH)
      return this.optionsNationen.filter(option => option.toLowerCase().includes(filterValue));
    else return [];
  }
  ngOnChanges(changes: SimpleChanges): void {
    if ("person" in changes) {
      if (changes.person.currentValue) {
        this.contactDataForm.patchValue({
          name: changes.person.currentValue.name,
          firstName: changes.person.currentValue.firstName,
          birthdate: changes.person.currentValue.birthdate,
          gender: changes.person.currentValue.gender,
          salutation: changes.person.currentValue.salutation,
          maritalStatus: changes.person.currentValue.maritalStatus,
          street: changes.person.currentValue.address.street,
          postalCode: changes.person.currentValue.address.postalCode,
          nationality: changes.person.currentValue.nationality,
          city: changes.person.currentValue.address.city,
          telNumber: changes.person.currentValue.telNumber,
          phoneNumber: changes.person.currentValue.phoneNumber,
          email: changes.person.currentValue.email,

        });
        if (changes.person.currentValue.uuid)
          this.contactDataForm.markAllAsTouched();
      }
    }
  }

  getContactDataForm(): FormGroup {
    return this.contactDataForm;
  }

  findFromCommunicationAddress(type: string) {
    if (!!this.person && !!this.person.communicationAddresses) {
      const communicationAddress = this.person.communicationAddresses.find(
        (address) =>
          address.commAddrType != null && address.commAddrType.key === type
      );
      if (!!communicationAddress) return communicationAddress.address;
    }
    return "";
  }

  resetContactDataForm() {
    this.contactDataForm.reset();
  }

  dateInput(event){
    const regex = new RegExp(/[^a-zA-Z!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/g);
    if(!regex.test(event.key)){
      return false;
    }else{
      return true
    }
  }

  changeContactData(field, event, type?) {
    // If telNumber has Content, remove validator from the phoneNumber...
    if(typeof this.contactDataForm.controls["telNumber"].value !== 'undefined'){
      this.contactDataForm.get("phoneNumber").clearValidators();
    }else{
      this.contactDataForm.get("phoneNumber").addValidators(Validators.required);
    }
    //...and viceversa
    if(typeof this.contactDataForm.controls["phoneNumber"].value !== 'undefined'){
      this.contactDataForm.get("telNumber").clearValidators();
    }else{
      this.contactDataForm.get("telNumber").addValidators(Validators.required);
    }

    if (field === "birthdate"){
    const newValue = event.target.value;
    const dateFormatted = moment(new Date(newValue),'YYYY/MM/DD');

      this.contactDataForm.controls[field].setValue(dateFormatted);
      this.person[field] = dateFormatted.utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
      return;
    }
    
    if (type === "communicationAddress" && this.person.communicationAddresses) {
      const communicationAddressIndex =
        this.person.communicationAddresses.findIndex(
          (address) =>
            address.commAddrType != null && address.commAddrType.key === type
        );
      if (communicationAddressIndex >= 0) {
        this.person.communicationAddresses.splice(communicationAddressIndex, 1);
      }
      const communicationAddress = new CommunicationAddress();
      switch (field) {
        case CommAddrTypes.PHONE_PRIVATE:{
          communicationAddress.commAddrType = CommAddrType.PHONE_PRIVATE;
          communicationAddress.address = event.target.value;
          break;}
        case CommAddrTypes.PHONE_MOBILE_PRIVATE:{
          communicationAddress.commAddrType = CommAddrType.PHONE_MOBILE_PRIVATE;
          communicationAddress.address = event.target.value;
          break;}
        case CommAddrTypes.EMAIL_PRIVATE:
          communicationAddress.commAddrType = CommAddrType.EMAIL_PRIVATE;
          communicationAddress.address = event.target.value;
        default:
          break;
      }

      const typedCommAddr = this.person.communicationAddresses.find(
        (addr) => addr.commAddrType === communicationAddress.commAddrType
      );
      if (typedCommAddr) {
        log.debug(
          `${this.TAG}; changeContactData() -> communication address type already exists, overriding...`
        );
        typedCommAddr.commAddrType = communicationAddress.commAddrType;
        typedCommAddr.address = communicationAddress.address;
        typedCommAddr.info = communicationAddress.info;
        typedCommAddr.type = communicationAddress.type;
        typedCommAddr.telNumber = communicationAddress.telNumber;
      } else {
        log.debug(
          `${this.TAG}; changeContactData() -> adding new created communication address...`
        );
        this.person.communicationAddresses.push(communicationAddress);
      }
    } else if (type === "postalAddress") {
      this.person.address[field] = event.target.value;
    } else {
      switch (field) {
        case "gender":
        case "grantList":
        case "salutation":
        case "maritalStatus":
          this.person[field] = event.value;
          break;
        default:{
          this.person[field] = event.target.value;
        }
      }
    }
  }

  checkValidation() {
    this.mandatoryFields.forEach((value) =>
      this.contactDataForm.controls[value].markAsTouched()
    );
    return this.contactDataForm.valid;
  }

  getPhoneNumberEnum(): string {
    return CommAddrTypes.PHONE_PRIVATE;
  }

  getMobileNumberEnum(): string {
    return CommAddrTypes.PHONE_MOBILE_PRIVATE;
  }

  getMailEnum(): string {
    return CommAddrTypes.EMAIL_PRIVATE;
  }

  updateData(attribute, e){
    this.person[attribute] = e.target.value
  }
}
