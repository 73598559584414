<div class="mdc-layout-grid msg-helper-padding-none">
    <div class="mdc-layout-grid__inner msg-helper-grid-gap-horizontal">
        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop
                                mdc-layout-grid__cell--span-12-tablet
                                mdc-layout-grid__cell--span-12-phone
                                mdc-layout-grid--align-right">
            <neomp-slide-range-filter [filterValue]="filterValue" (filterChangeEmitter)="getElements($event)"
                [disabled]="isDataLoading">
            </neomp-slide-range-filter>
        </div>
        <div
            class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone neomp-pb-10">
            <mat-card>
                <div class="search-fields-layout-grid" (keyup.enter)="searchFilter()">
                    <mat-form-field class="neomp-full-width">
                        <mat-label>{{ 'PAGE.LEADS.LEAD.TEXT' | translate }}</mat-label>
                        <input matInput [(ngModel)]="searchText" />
                    </mat-form-field>
                    <mat-form-field class="neomp-full-width">
                        <mat-label>Status List</mat-label>
                        <mat-select [formControl]="statusSelected" multiple>
                            <mat-option *ngFor="let status of statusesList"
                                [value]="status.key">{{status.value}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="search-fields-layout-grid neo-padding-top-10 margin-top-15">
                    <button mat-raised-button class="neomp-full-width button-text" color="primary"
                        (click)="searchFilter()">
                        <span class="material-icons">
                            search
                        </span>{{ 'PAGE.LEADS.TERMIN.SEARCH' | translate }}
                    </button>
                    <button mat-raised-button class="clear-button neomp-full-width button-text" (click)="clearSearch()">
                        <span class="material-icons">clear</span>{{ 'PAGE.LEADS.LEAD.CLEAR' | translate }}
                    </button>
                </div>
            </mat-card>
        </div>
        <br />
        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop
                            mdc-layout-grid__cell--span-12-tablet
                            mdc-layout-grid__cell--span-12-phone">
            <neomp-container cssClass="msg-mp-container-frameless"
                headlineText="{{ 'PAGE.CONTAINER.APPLICATIONS.CONTAINER.HEADLINE' | translate }}">
                <neomp-applications-table [tableEntries]="policies"></neomp-applications-table>
                <neomp-loading-data #applicationsTable></neomp-loading-data>
            </neomp-container>
            <neomp-paginator *ngIf="policies.length>0" [length]="totalCount" [pageSize]="pageSize" [pageIndex]="pageIndex"
                [pageSizeOptions]="pageSizeOptions" (paginatorChange)="paginatorValueChanged($event)">
            </neomp-paginator>
        </div>
    </div>
</div>