<mat-sidenav-container [autosize]="true" [hasBackdrop]="false" class="msg-mp-sidenav ">
    <mat-sidenav #sidenav [disableClose]="false" [mode]="mobileQuery.matches ? 'over' : 'side'"
        [opened]="!mobileQuery.matches" class="msg-mp-sidenav-menu overflow-hidden"
        ngClass="{{mobileQuery.matches?'msg-mp-sidenav-menu-tab-smartphone':'msg-mp-sidenav-menu-desktop'}}">
        <div class="msg-mp-sidenav-menu-header">
            <h1>{{contentPageTitel | uppercase}}</h1>
            <svg (click)="toggleNavigation()" style="width:24px;height:24px" viewBox="0 0 24 24">
                <path style="color:#BCCAD1" fill="currentColor"
                    d="M5,13L9,17L7.6,18.42L1.18,12L7.6,5.58L9,7L5,11H21V13H5M21,6V8H11V6H21M21,16V18H11V16H21Z" />
            </svg>
        </div>
        <div class="msg-mp-sidenav-menu-content ">
            <ul class="msg-mp-sidenav-menu-content-wrapper">
                <li *ngFor="let item of menuItems" (click)="CloseSidenavOnMobile()" [ngClass]="{'disabled': (isAgentBlocked && item.path === 'leadpool')}"
                    class="msg-mp-sidenav-menu-content-item{{item.color}}{{item.isActive ? '-msg-mp-sidenav-menu-content-item-active' : ''}}"
                    routerLink="{{item.path}}">{{ item.menuLabel | translate}}
                    <!-- Unasigned leads count badge ( displayed only when lead-management menu item is visible )-->
                    <span class="lead-management-badge"
                        *ngIf="item.path === 'lead-management' && leadService.unassignedLeadsCount | async as count ">
                        {{ count }}
                    </span>
                    <span class="appointment-management-badge"
                        *ngIf="item.path === 'termin-management' && leadService.unassignedAppointmentsCount | async as count ">
                        {{ count }}
                    </span>
                    <span class="appointment-management-badge"
                        *ngIf="item.path === 'leadpool' && leadService.unassignedLeadPoolCount | async as count ">
                        {{ count }}
                    </span>
                    <span class="appointment-management-badge"
                        *ngIf="item.path === 'livechat' && livechatService.onlineRooms$ | async as onlineRoomsCount ">
                        {{ onlineRoomsCount }}
                    </span>
                    <span class="appointment-management-badge appointment-management-badge-red"
                        *ngIf="item.path === 'livechat' && livechatService.newMessages$ | async as count ">
                        {{ count }}
                    </span>
                </li>
            </ul>
        </div>
    </mat-sidenav>
    <mat-sidenav-content>
        <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
        <neomp-header (toggleNavEmitter)="toggleNavigation()" [isMenuItem]="getActiveItemInMenu()"
            [sidenavElement]="sidenav" [disableAnimation]="mobileQuery.matches">
        </neomp-header>
        <div class=" {{animationText ? 'text-overflow' : 'neo-text'}}" *ngIf="showAlert[translate.currentLang]"
            [style.backgroundColor]="bgColor">
            <markdown class="neo-markdown {{fontColor}}" [data]="showAlert[translate.currentLang]"></markdown>
        </div>
        <main class="msg-mp-main mdc-layout-grid">
            <div class="mdc-layout-grid__inner msg-helper-grid-gap-none">
                <div *ngIf="!hasBreadcrumb" class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop
                            mdc-layout-grid__cell--span-12-tablet
                            mdc-layout-grid__cell--span-12-phone">
                </div>
                <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop
                            mdc-layout-grid__cell--span-12-tablet
                            mdc-layout-grid__cell--span-12-phone">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </main>
    </mat-sidenav-content>
</mat-sidenav-container>