<table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort class="family-table">
    <ng-container matColumnDef="householdName">
        <th *matHeaderCellDef mat-header-cell
            mat-sort-header>{{ 'PAGE.CONTAINER.CUSTOMERS.CONTAINER.NAME' | translate }}</th>
        <td mat-cell *matCellDef="let household">{{household.householdName}}</td>
    </ng-container>
    <ng-container matColumnDef="householdAddress">
        <th  *matHeaderCellDef mat-header-cell
            mat-sort-header>{{ 'PAGE.CONTAINER.CUSTOMERS.CONTAINER.ZIPCODE' | translate }}</th>
        <td mat-cell *matCellDef="let household"> {{household.householdAddress}}</td>
    </ng-container>
    <ng-container matColumnDef="householdAppStatus">
        <th *matHeaderCellDef mat-header-cell
            mat-sort-header>{{ 'PAGE.CONTAINER.CUSTOMERS.CONTAINER.APPSTATUS' | translate }}</th>
        <td (click)="$event.stopPropagation()" mat-cell *matCellDef="let household">
            <img *ngIf="!household.householdAppStatus" src="assets/icons/mobile_off.svg" class="material-icons img-background" title="False" alt="not installed"/>
            <img *ngIf="household.householdAppStatus" src="assets/icons/mobile_on.svg" class="material-icons img-background" title="True" alt="app installed"/>
            <img  #tooltip="matTooltip" (click)="copyMessage(household.representant.uuid); tooltip.show(); " 
            [matTooltip]="copyText"
            [matTooltipHideDelay]="matTooltipHideDelay"  src="assets/icons/copy_content.svg" class="material-icons img-background copyReferral"  alt="app installed"/>
            <img *ngIf="household.householdAppStatus && !household.householdAppStatusLoggedIn" src="assets/icons/status_offline.svg" class="material-icons img-background margin-left-10" title="Offline" alt="off line"/>
            <img *ngIf="household.householdAppStatus && household.householdAppStatusLoggedIn" src="assets/icons/status_online.svg" class="material-icons img-background margin-left-10" title="Online" alt="on line"/>
        </td>
    </ng-container>
    <ng-container matColumnDef="icon">
        <td mat-cell *matCellDef="let element" >
                <div class="mdi {{expandedElement === element ? 'mdi-chevron-down' : 'mdi-chevron-up'}} msg-helper-icon-font-size"></div>
        </td>
    </ng-container>
    <ng-container matColumnDef="filter">
        <th *matHeaderCellDef mat-header-cell (click)="changeFilterView()"
            class="mat-header-cell mat-column-filter {{isFilterActive ? 'mat-column-filter-active':''}}">
            <div
                class="neomp-table-column-filter-element  mdi {{isFilterActive ? 'mdi-filter':'mdi-filter-outline'}}"></div>
        </th>
        <td mat-cell *matCellDef="let element">
            <div class="neomp-table-column-filter-element mdi mdi-plus"></div>
        </td>
    </ng-container>

    <!-- Filterrow elements-->
    <ng-container matColumnDef="householdNameFilter">
        <th mat-header-cell *matHeaderCellDef>
            <mat-form-field floatLabel="never">
                <input matInput [formControl]="householdNameFilter"
                       placeholder="{{ 'TABLES.FILTERPLACEHOLDER.NAME' | translate }}">
            </mat-form-field>
        </th>
    </ng-container>
    <ng-container matColumnDef="householdAddressFilter">
        <th mat-header-cell *matHeaderCellDef>
            <mat-form-field floatLabel="never">
                <input matInput [formControl]="householdAddressFilter"
                       placeholder="{{ 'TABLES.FILTERPLACEHOLDER.POSTCODE_CITY' | translate }}">
            </mat-form-field>
        </th>
    </ng-container>
    <ng-container matColumnDef="householdAppStatusFilter">
        <th mat-header-cell *matHeaderCellDef>
            <mat-form-field floatLabel="never">
                <input matInput [formControl]="householdAppStatusFilter"
                       placeholder="{{ 'PAGE.CONTAINER.CUSTOMERS.CONTAINER.APPSTATUS' | translate }}">
            </mat-form-field>
        </th>
    </ng-container>
    <ng-container matColumnDef="filterRemove">
        <th mat-header-cell *matHeaderCellDef (click)="resetFilter()">
            <div class="neomp-table-column-filter-element mdi mdi-close"></div>
        </th>
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let householdDetail" [attr.colspan]="3"
            class="{{householdDetail === expandedElement ? 'family-table-row-detail-content-expanded':''}} pl-0">
            <div
                class="family-table-row-expanded-element-without-flex"
                [@detailExpand]="householdDetail === expandedElement ? 'expanded' : 'collapsed'">
                <neomp-loading-data #loadingExpandedFamilyTable></neomp-loading-data>
            </div>
            <div
                class="family-table-row-expanded-element-with-flex"
                [@detailExpand]="householdDetail === expandedElement ? 'expanded' : 'collapsed'">
                <neomp-family-table-expand-detail
                    *ngIf="houseHoldeCompletlyLoaded && expandedElement" class="msg-helper-width-100"
                    [dataSourceExpandDetail]="expandedElement" (selectedPersonEmitter)="selectedPersonClick($event)"
                    [showStatus]="true">
                </neomp-family-table-expand-detail>
            </div>
        </td>
    </ng-container>

    <tr *matHeaderRowDef="['householdName', 'householdAddress','householdAppStatus','filter'];"
        mat-header-row></tr>

    <tr *matHeaderRowDef="['householdNameFilter', 'householdAddressFilter','householdAppStatusFilter', 'filterRemove'];"
        class="neomp-table-row-filter {{showFilter? '': 'neomp-table-row-filter-hide'}}"
        mat-header-row></tr>

    <tr mat-row *matRowDef="let element; columns: ['householdName', 'householdAddress','householdAppStatus','icon']; let i = dataIndex;"
        class="family-table-row"
        [class.family-table-row-expanded]="expandedElement === element"
        [class.neomp-table-row-selected]="expandedElement != element"
        (click)="expandRow(element, i);">
    </tr>

    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="family-table-row-detail-content"></tr>
</table>
