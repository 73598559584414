import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CoreModule } from "../core/core.module";
import { MyOpenIssuesPageComponent } from "./pages/my-open-issues-page.component";
import { FormsModule } from "@angular/forms";

@NgModule({
    declarations: [MyOpenIssuesPageComponent],
    imports: [
      CommonModule,
      CoreModule,
      FormsModule
    ],
    exports: [MyOpenIssuesPageComponent]
})

export class OpenIssuesModule { }
