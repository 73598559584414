<div class="mdc-layout-grid msg-helper-padding-none neomp-action-dialog">

  <!-- dialog header -->
  <div mat-dialog-title class="neomp-dialog-title"> Groupe Mutuel Offerte erstellten </div>

  <!-- dialog content -->
  <div class="mdc-layout-grid neo-tab-custom">
    <div class="mdc-layout-grid__inner">

      <div mat-dialog-content
        class="neo-tab-custom1 mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
        <mat-tab-group class="demo-tab-group" (selectedTabChange)="tabChanged($event)" [selectedIndex]="selectedIndex">
          <mat-tab *ngFor="let person of data.persons" label="{{person.firstName}}">
            <div class="mdc-layout-grid msg-helper-padding-none msg-helper-margin-top-10">
              <div class="mdc-layout-grid__inner">
                <!-- Left panel KVG -->
                <div
                  class="neo-custom-option-font  mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-12-phone">
                  <h2 class="neomp-offer-title">KVG</h2>

                  <div class="mdc-layout-grid neo-padding-0 neo-font-size-14">
                    <div class="mdc-layout-grid__inner">
                      <!-- Whole family toggle -->
                      <div *ngIf="data.persons.length > 1"
                        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
                        <div class="msg-mp-headerstyle" header>
                          {{ "PAGE.OFFER.GM.WHOLEFAMILY" | translate }}
                          <mat-slide-toggle class="neo-slide-color" (change)="onFamilySlideChange()"
                            [(ngModel)]="wholeFamilyChecked"> </mat-slide-toggle>
                        </div>
                      </div>
                      <!-- Customers list dropdown -->
                      <div
                        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-6-phone">
                        <mat-form-field>
                          <mat-label class="font-family">{{ "PAGE.OFFER.CUSTOMER" | translate }} </mat-label>
                          <mat-select (selectionChange)="onSelectedPersonChange($event.value)"
                            [value]="selectedPerson?.uuid">
                            <mat-option class="neo-custom-option-font" *ngFor="let person of data.persons;"
                              [value]="person.uuid">{{ person.fullName ? person.fullName : person.firstName}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <!-- Place autocomplete -->
                      <div
                        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-6-phone">
                        <neomp-neo-input-completer [minLength]="3" [neoPlaceholder]="'PLZ'"
                          [neoAutoValue]="selectedRegion" [neoCompleterItems]="regions"
                          (neoValueEmitter)="changeRegion($event)">
                        </neomp-neo-input-completer>
                      </div>




                      <!-- Franchise select dropdown -->
                      <div
                        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-6-phone">
                        <mat-form-field>
                          <mat-label class="font-family"> {{ "PAGE.OFFER.GM.FRANCHISE" | translate }} </mat-label>
                          <mat-select (selectionChange)="onFranchiseChange(selectedFranchise)"
                            [(value)]="selectedFranchise">
                            <mat-option class="neo-custom-option-font" *ngFor="let f of franchises" [value]="f"> {{ f }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>

                      <!-- Franchise select dropdown -->
                      <!-- <div
                class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-6-phone">
                <mat-form-field *ngFor="let person of getFamilyMembersOnly(); let i=index;">
                  <mat-label class="font-family"> {{ person.firstName }} </mat-label>
                  <mat-select (selectionChange)="familyMemberFranchiseChange(selectedFranchise)"  [value]="getFranchise(person.birthdate)">
                    <mat-option class="neo-custom-option-font" *ngFor="let f of getFranchisesForPerson(person.birthdate)" [value]="f"> {{ f }} </mat-option>
                  </mat-select>
                </mat-form-field>
              </div> -->

                      <!-- Tarif type dropdown -->
                      <div
                        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-6-phone">
                        <mat-form-field>
                          <mat-label class="font-family">{{ "PAGE.OFFER.GM.MODEL" | translate }} </mat-label>
                          <mat-select (selectionChange)="onTarifChange(selectedTarif)" [(value)]="selectedTarif">
                            <mat-option class="neo-custom-option-font" *ngFor="let tarfifType of tarifTypes"
                              [value]="tarfifType"> {{ tarfifType }} </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <!-- VGR product list -->
                      <div
                        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone"
                        *ngIf="vgrTarifResponseData.length > 0">
                        <form [formGroup]="productsForm" >
                          <div formArrayName="vgrProducts">
                            <mat-radio-group [formControl]="vgrProducts.controls[getFormSelectedIndex()]['controls']['selectedProduct']">
                              <mat-radio-button class="product-checkbox"
                                *ngFor="let product of vgrProducts.controls[getFormSelectedIndex()].value.options;" [value]="product" 
                                [checked]="isVgrProdChecked(vgrProducts.controls[getFormSelectedIndex()]['controls']['selectedProduct'], product.name)">
                                {{formatProduct(product)}}
                              </mat-radio-button>
                            </mat-radio-group>
                          </div>
                        </form>
                    
                      </div>

                      <!-- Get Offers Button -->
                      <!-- <div
            class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
            <button mat-button class="neomp-dialog-action-button cancel-action" (click)="findTarife()"> {{ "Get
              Offers" | translate }} </button>
          </div>
           -->

          </div>                 
           </div>
                  </div>
                <!-- Right panel Products -->
                <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop
                                      mdc-layout-grid__cell--span-6-tablet
                                      mdc-layout-grid__cell--span-12-phone">
                  <h2 class="neomp-offer-title">{{'PAGE.CUSTOMERDETAIL.POLICY.DETAILS.PRODUCTS.NAME' | translate}}</h2>
                  <form [formGroup]="productsForm" class="neo-no-padding">
                    <div *ngIf="gmProducts?.controls?.length > 0" formArrayName="gmProducts">
                      <div *ngFor="let fG of gmProducts.controls[getFormSelectedIndex()]['controls']; let i=index;">
                        <form [formGroup]="fG">
                          <div class="mdc-layout-grid">
                            <div class="mdc-layout-grid__inner">
                              <!-- Category checkbox -->
                              <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop
                                              mdc-layout-grid__cell--span-6-tablet
                                              mdc-layout-grid__cell--span-6-phone neo-align-center">

                                <mat-checkbox color="primary" formControlName="category" class="a msg-data-label"
                                  *ngIf="gmProducts.controls[getFormSelectedIndex()].value[i].categoryName"
                                  (change)="filterProducts(i)">
                                  {{gmProducts.controls[getFormSelectedIndex()].value[i].categoryName}}
                                </mat-checkbox>

                              </div>
                              <!-- Category variants -->
                              <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop
                                              mdc-layout-grid__cell--span-6-tablet
                                              mdc-layout-grid__cell--span-6-phone">
                                <mat-form-field
                                  ngClass="{{gmProducts.controls[getFormSelectedIndex()].value[i].allVariants.length > 0 ?'showOptions' : 'hiddenOptions'}}">
                                  <mat-label class="font-family">{{ "Variante" | translate }} </mat-label>
                                  <mat-select formControlName="selectedVariant" (selectionChange)="variantChanged(i)">
                                    <mat-option class="neo-custom-option-font"
                                      *ngFor="let variant of gmProducts.controls[getFormSelectedIndex()].value[i].allVariants"
                                      [value]="variant?.levelCode">
                                      <span
                                        *ngIf="variant.description && variant.description[selectedLang]">{{variant.description[selectedLang]}}
                                      </span>
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </form>


                </div>
              </div>
            </div>
          </mat-tab>

        </mat-tab-group>
      </div>
    </div>
    <!-- dialog actions -->
    <div mat-dialog-actions class="neomp-dialog-actions">
      <button mat-button role="button" class="neomp-dialog-action-button cancel-action" mat-dialog-close><label
          class="neo-font-text cursor-pointer">{{ "DIALOG.BUTTON.CANCEL" |
          translate }}</label> </button>
      <button mat-button class="neomp-dialog-action-button save-action" 
        (click)="sendOffer()" [disabled]="sendOfferDisabled()"
        ><label class="neo-font-text cursor-pointer">{{ "DIALOG.BUTTON.SAVE" |
          translate }}</label>
        <label class="neo-font-text cursor-pointer" *ngIf="selectedPerson?.firstName && !wholeFamilyChecked">
          "{{selectedPerson?.firstName}}"</label>
        <label class="neo-font-text cursor-pointer" *ngIf="wholeFamilyChecked"> {{ "DIALOG.ALLE" |
          translate }}</label>
      </button>
    </div>

  </div>
</div>

<div class="overlay" *ngIf="isDataLoading">
  <div class="center">
    <mat-progress-spinner class="mat-progress-spinner-overlay" class="example-margin" mode="indeterminate">
    </mat-progress-spinner>
  </div>
</div>