import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReferralsComponent } from './components/referrals.component';
import { MatTableModule } from '@angular/material/table'  
import { CoreModule } from '../core/core.module';
import { TranslateModule } from '@ngx-translate/core';
import { ReferralsTableComponent } from "../customers/components/referrals-table.component";

@NgModule({
    declarations: [ReferralsComponent,ReferralsTableComponent],
    imports: [
        CommonModule,
        MatTableModule,
        CoreModule,
        TranslateModule,
    ]
})
export class ReferralsModule { }
