<div>

    <table [dataSource]="dataSource" class="msg-helper-width-100 neo-content-wrapper" mat-table matSort
        multiTemplateDataRows>

        <!-- columns -->
        <ng-container matColumnDef="creationDate">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>{{
                'PAGE.CONTAINER.INFO.DATE' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
                <span>{{element.creationDate | date:'dd.MM.YYYY'}}</span>
            </td>
        </ng-container>
        <ng-container matColumnDef="ordererName">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>{{
                'PAGE.CONTAINER.INFO.REQUESTERNAME' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
                <span>{{element.ordererName}}</span>
            </td>
        </ng-container>
        <ng-container matColumnDef="sorKeys">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>{{
                'PAGE.CONTAINER.INFO.QUANTITY' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
                <span>{{element.sorKeys.quantity}}</span>
            </td>
        </ng-container>
        <ng-container matColumnDef="filter">
            <th *matHeaderCellDef mat-header-cell (click)="changeFilterView()"
                class="mat-header-cell mat-column-filter {{isFilterActive ? 'mat-column-filter-active':''}}">
                <div
                    class="neomp-table-column-filter-element mdi {{isFilterActive ? 'mdi-filter':'mdi-filter-outline'}}">
                </div>
            </th>
            <td mat-cell *matCellDef="let element"></td>
        </ng-container>

        <!-- Filter row definition -->
        <ng-container matColumnDef="creationDateFilter">
            <th mat-header-cell *matHeaderCellDef>
                <neomp-date-picker class="datePickerHide" (filterValue)="creationDateFilterChanged($event)">
                </neomp-date-picker>
            </th>
        </ng-container>
        <ng-container matColumnDef="ordererNameFilter">
            <th mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter" floatLabel="never">
                    <input matInput [formControl]="ordererNameFilter"
                        placeholder="{{ 'PAGE.CONTAINER.INFO.REQUESTERNAME' | translate }}">
                </mat-form-field>
            </th>
        </ng-container>
        <ng-container matColumnDef="sorKeysFilter">
            <th mat-header-cell *matHeaderCellDef>
                <mat-form-field class="filter" floatLabel="never">
                    <input matInput [formControl]="sorKeysFilter"
                        placeholder="{{ 'PAGE.CONTAINER.INFO.QUANTITY' | translate }}">
                </mat-form-field>
            </th>
        </ng-container>
        <ng-container matColumnDef="statusFilter">
            <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox class="filter-checkbox" [formControl]="statusFilter" name="statusCheckbox"></mat-checkbox>
            </th>
        </ng-container>
        <ng-container matColumnDef="filterRemove">
            <th mat-header-cell *matHeaderCellDef (click)="resetFilter()">
                <div class="neomp-table-column-filter-element mdi mdi-close"></div>
            </th>
        </ng-container>

        <!--Row definitions -->
        <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
        <tr *matHeaderRowDef="displayedColumnFilters"
            class="neomp-table-row-filter {{showFilter? '': 'neomp-table-row-filter-hide'}}" mat-header-row></tr>
        <tr mat-row *matRowDef="let openIssuesRow; columns: displayedColumns, let i = dataIndex;"
            class="openIssuesDetail-row container-table-row neomp-table-row-selected reset-cursor"></tr>

    </table>

    <neomp-paginator [length]="totalCount" [pageSize]="pageSize" [pageIndex]="pageIndex"
        [pageSizeOptions]="pageSizeOptions" (paginatorChange)="paginatorValueChanged($event)">
    </neomp-paginator>
</div>