import {FormControl} from "@angular/forms";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Input, OnInit, ViewChild, Directive } from "@angular/core";
import { Subscription } from "rxjs";


@Directive()
export abstract class FilterTableComponent<T> implements OnInit {

    displayedColumns: string[] = [];
    displayedColumnFilters: string[] = [];
    formControlFilters: FormControl[] = [];
    filterValues: T;
    @ViewChild(MatSort) sort: MatSort;
    dataSource: MatTableDataSource<T>;
    isFilterActive = false;
    showFilter = false;
    subscriptions: Subscription[] = [];

    constructor() {
        this.dataSource = new MatTableDataSource(this._tableEntries);
        this.dataSource.filterPredicate = this.createFilter();
    }

    _tableEntries: T[] = [];

    @Input()
    set tableEntries(value: T[]) {
        this._tableEntries = value;
        this.reloadTableData(this._tableEntries);
        this.initFilter();

    }

    abstract createFilter();

    ngOnInit() {
        this.reloadTableData(this._tableEntries);
    }

    getProperty = (obj, path) => (
        path.split('.').reduce((o, p) => o && o[p], obj)
    )

    reloadTableData(value) {
        this.dataSource.data = value;
        this.dataSource.sortingDataAccessor = (obj, property) => this.getProperty(obj, property);
        this.dataSource.sort = this.sort;
    }

    initFilter() {
        this.subscriptions.map((s: Subscription) => s.unsubscribe());

        for (let i = 0; i < this.formControlFilters.length; i++) {
            if (!this.formControlFilters[i]) {
                continue;
            }
            this.subscriptions.push(
            this.formControlFilters[i].valueChanges.subscribe(value => {
                this.filterValues[this.displayedColumns[i]] = value;
                this.dataSource.filter = JSON.stringify(this.filterValues);
                this.isFilterActive = true;
            }));
        }
    }

    changeFilterView() {
        this.showFilter = !this.showFilter;
    }

    resetFilter() {
        for (let i = 0; i < this.formControlFilters.length; i++) {
            if (this.formControlFilters[i]) {
                this.formControlFilters[i].reset();
            }
        }
        this.createFilter();
        this.isFilterActive = false;
    }
}

