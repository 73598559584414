import {LeadJson} from "../json/lead.json";

export class Lead {
    lead_agency = "";
    lead_extradata = "";
    lead_kategory = "";
    lead_personen = "";
    lead_source = "";
    lead_typ = "";
    lead_status = "";
    lead_creadate = "";
    lead_substatus = "";
    termin_agency = "";
    termin_berater_shortcut = "";
    leadpool= false;
    termin_berater_id = "";
    termin_date = "";
    termin_editdate = "";
    termin_id = "";
    termin_leadid = "";
    termin_stattgefunden = "";
    termin_status = "";
    termin_time = "";
    termin_place = "";
    termin_family_members = "";
    termin_substatus = ""
    termin_kategory = "";
    termin_type = "";
    termin_source_agency = "";
    advisor_name = "";
    edit_servicecenter = false;
    lead_supplier = "";
    termin_berater_assigned_time = "";

    constructor(data?: LeadJson) {
        if (data) {
            this.lead_agency = data.lead_agency;
            this.lead_extradata = data.lead_extradata;
            this.lead_kategory = data.lead_kategory;
            this.lead_personen = data.lead_personen;
            this.lead_source = data.lead_source;
            this.lead_typ = data.lead_typ;
            this.lead_status= data.lead_status;
            this.lead_creadate = data.lead_creadate || "";
            this.lead_substatus = data.lead_substatus;
            this.termin_agency = data.termin_agency;
            this.termin_berater_shortcut = data.termin_berater_shortcut;
            this.leadpool = data.leadpool;
            this.termin_berater_id = data.termin_berater_id;
            this.termin_date = data.termin_date || "";
            this.termin_editdate = data.termin_editdate || "";
            this.termin_id = data.termin_id || "";
            this.termin_leadid = data.termin_leadid || "";
            this.termin_stattgefunden = data.termin_stattgefunden || "";
            this.termin_status = data.termin_status || "";
            this.termin_time = data.termin_time || "";
            this.termin_place = data.termin_place || "";
            this.termin_family_members = data.termin_family_members || "";
            this.termin_substatus = data.termin_substatus;
            this.advisor_name = data.advisor_name || "";
            this.termin_kategory = data.termin_kategory;
            this.termin_type = data.termin_type;
            this.termin_source_agency = data.termin_source_agency || "";
            this.edit_servicecenter = data.edit_servicecenter;
            this.lead_supplier = data.lead_supplier || "";
            this.termin_berater_assigned_time = data.termin_berater_assigned_time;
        }
    }
}
