import {AfterViewInit, Component, OnInit, ViewChild} from "@angular/core";
import {JournalProvider} from "../../core/providers/journal.provider";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {RegisterProvider} from "../../core/providers/register.provider";
import {LoadingDataComponent} from "../../core/components/loading-data.component";
import {JournalList} from "../../core/model/journal-list";
import {SlideRangeFilterValue} from "../../core/model/slide-range-filter-value";
import * as moment from "moment";
import {Observable} from "rxjs";
import {BusinessTransactionHelper} from "../../core/static/bt-helper";
import { PaginationConfigs } from "app/modules/constants/PaginationConstants";
import { PageEvent } from "@angular/material/paginator";
import { LoadingService } from "app/modules/core/providers/loading.component";
import { MatTabChangeEvent } from "@angular/material/tabs";

@Component({
    selector: "neomp-my-journals-page",
    templateUrl: "./my-open-issues-page.component.html",
    styleUrls: ["./my-open-issues-page.component.scss"]
})
export class MyOpenIssuesPageComponent implements OnInit, AfterViewInit {
    public TAG: string = this.constructor.name;

    closedissues: JournalList[] = [];
    openissues: JournalList[] = [];

    isInitialized: boolean;
    isDataLoading: boolean;  
    completedIssue: boolean = true;
    notCompleteIssue: boolean = false;
    searchText: string | null = null;
    selectedCompany: string | null = null;
    selectedInsurance: string | null = null;
    insuranceTypes: string[];
    companies: string[];
    selectedCompanies:string='';
    selectedInsurances:string='';
    
    pageIndex = PaginationConfigs.pageIndex;
    pageSize = PaginationConfigs.pageSize;
    pageSizeOptions = PaginationConfigs.pageSizeOptions;
    totalCountOpenIssues = 0;
    totalCountClosedIssues = 0;
    tabIndex = 0;
    @ViewChild("openIssuesTable") journalTableLoadingComponent: LoadingDataComponent;
    @ViewChild("closedIssuesTable") journalsTableLoadingComponent: LoadingDataComponent;

    filterValueForIssuesOpen: SlideRangeFilterValue = new SlideRangeFilterValue();
    filterValueForIssuesClosed: SlideRangeFilterValue = new SlideRangeFilterValue();

    constructor(public journalProvider: JournalProvider,
                public route: ActivatedRoute,
                public router: Router,
                public location: Location,
                public registerProvider: RegisterProvider,
                private loadingService: LoadingService,) {

    }

    ngOnInit() {
        this.getCompaniesAndInsurances();
        this.filterValueForIssuesOpen.isActive = true;
        this.filterValueForIssuesOpen.dateRange.end = moment();
        this.filterValueForIssuesOpen.dateRange.begin = moment().subtract(60, "days"); 
        this.filterValueForIssuesClosed.isActive = true;
        this.filterValueForIssuesClosed.dateRange.end = moment();
        this.filterValueForIssuesClosed.dateRange.begin = moment().subtract(60, "days");  
        this.route.queryParams
        .subscribe((params) => {
            this.searchFromParams(params);
        })
    }

    searchFromParams(params?): void {

        this.pageIndex = Number(params.pageIndex) || PaginationConfigs.pageIndex;
        this.pageSize = Number(params.pageSize) > 0 ? Number(params.pageSize) : PaginationConfigs.pageSize;
        if (params.text) {
            this.searchText = params.text;
        }

        if (params.company) {
            this.selectedCompany = params.company;
        }

        if (params.insurance) {
            this.selectedInsurance = params.insurance;
        }
        this.search();
    }

    ngAfterViewInit(): void {
        this.isInitialized = true;
    }

    onCompanySelected(event: any) {
        this.selectedCompany = event.value;
    }
    
    onInsuranceSelected(event: any) {
        this.selectedInsurance = event.value;
    }

    getOpenIssues(filterValue) {
        this.loadingService.display(true);
        this.dataLoading = true;
        const sort = {"status.name": -1, "effectiveDate": -1};

        if((this.searchText || this.selectedCompany || this.selectedInsurance)){
            if (Array.isArray(this.selectedCompany)) {
                this.selectedCompanies = this.selectedCompany.join('|');
            }
            if (Array.isArray(this.selectedInsurance)) {
                this.selectedInsurances = this.selectedInsurance.join('|');
            }
            const journalObservable: Observable<{data: JournalList[], totalCount: number}> = filterValue.isActive ?
            this.journalProvider.getFilteredJournalsReferencedByDateRangeWithPagination(
                this.journalTableLoadingComponent,
                BusinessTransactionHelper.typeId.pendingMatter.from,
                BusinessTransactionHelper.typeId.pendingMatter.to,
                filterValue.dateRange, 
                this.pageIndex, 
                this.pageSize, 
                sort,
                this.notCompleteIssue,
                this.searchText,
                null,
                null,
                this.selectedCompanies,
                this.selectedInsurances
                ) :
            this.journalProvider.getFilteredJournalsReferencedWithPagination(
                this.journalTableLoadingComponent,
                BusinessTransactionHelper.typeId.pendingMatter.from,
                BusinessTransactionHelper.typeId.pendingMatter.to,
                this.pageIndex, 
                this.pageSize, 
                sort,
                this.notCompleteIssue,
                this.searchText,
                null,
                null,
                this.selectedCompanies,
                this.selectedInsurances);
        journalObservable.subscribe(journals => {
            this.openissues = journals.data;
                this.openissues = this.openissues.reverse();
                this.totalCountOpenIssues = journals.totalCount;
                this.dataLoading = false;
                this.loadingService.display(false);
                this.dataLoading = false;
        }, error => {
                this.dataLoading = false;
                this.loadingService.display(false);
        });
        }
        else{
            const journalObservable: Observable<{data: JournalList[], totalCount: number}> =
                filterValue.isActive
                    ?
                this.journalProvider.getJournalsReferencedByDateRangeWithPagination(
                    this.journalTableLoadingComponent,
                    BusinessTransactionHelper.typeId.pendingMatter.from,
                    BusinessTransactionHelper.typeId.pendingMatter.to,
                    filterValue.dateRange, 
                    this.pageIndex, 
                    this.pageSize, 
                    sort,
                    this.notCompleteIssue
                    )
                    :
                this.journalProvider.getJournalsReferencedWithPagination(
                    this.journalTableLoadingComponent,
                    BusinessTransactionHelper.typeId.pendingMatter.from,
                    BusinessTransactionHelper.typeId.pendingMatter.to,
                    this.pageIndex,
                    this.pageSize,
                    sort,
                    this.notCompleteIssue
                );
    
            journalObservable.subscribe(journals => {
                this.openissues = journals.data;
                this.openissues = this.openissues.reverse();
                this.totalCountOpenIssues = journals.totalCount;
                this.dataLoading = false;
                this.loadingService.display(false);
            }, error => {
                this.dataLoading = false;
                this.loadingService.display(false);
            });
        }
    }

    getClosedIssues(filterValue) {
        this.loadingService.display(true);
        this.dataLoading = true;
        const sort = {"status.name": -1, "effectiveDate": -1};
        if((this.searchText || this.selectedCompany || this.selectedInsurance)){
            if (Array.isArray(this.selectedCompany)) {
                this.selectedCompanies = this.selectedCompany.join('|');
            }
            if (Array.isArray(this.selectedInsurance)) {
                this.selectedInsurances = this.selectedInsurance.join('|');
            }
            const journalObservable: Observable<{data: JournalList[], totalCount: number}> = filterValue.isActive ?
            this.journalProvider.getFilteredJournalsReferencedByDateRangeWithPagination(
                this.journalTableLoadingComponent,
                BusinessTransactionHelper.typeId.pendingMatter.from,
                BusinessTransactionHelper.typeId.pendingMatter.to,
                filterValue.dateRange, 
                this.pageIndex, 
                this.pageSize, 
                sort,
                this.completedIssue,
                this.searchText,
                null,
                null,
                this.selectedCompanies,
                this.selectedInsurances
                ) :
            this.journalProvider.getFilteredJournalsReferencedWithPagination(
                this.journalTableLoadingComponent,
                BusinessTransactionHelper.typeId.pendingMatter.from,
                BusinessTransactionHelper.typeId.pendingMatter.to,
                this.pageIndex, 
                this.pageSize, 
                sort,
                this.completedIssue,
                this.searchText,
                null,
                null,
                this.selectedCompanies,
                this.selectedInsurances);
        journalObservable.subscribe(journals => {
                this.closedissues = journals.data;
                this.closedissues = this.closedissues.reverse();
                this.totalCountClosedIssues = journals.totalCount;
                this.dataLoading = false;
                this.loadingService.display(false);
        }, error => {
                this.dataLoading = false;
                this.loadingService.display(false);
        });
        }
        else{
            const journalObservable: Observable<{data: JournalList[], totalCount: number}> =
                filterValue.isActive
                    ?
                this.journalProvider.getJournalsReferencedByDateRangeWithPagination(
                    this.journalsTableLoadingComponent,
                    BusinessTransactionHelper.typeId.pendingMatter.from,
                    BusinessTransactionHelper.typeId.pendingMatter.to,
                    filterValue.dateRange,
                    this.pageIndex,
                    this.pageSize,
                    sort,
                    this.completedIssue)
                    :
                this.journalProvider.getJournalsReferencedWithPagination(
                    this.journalsTableLoadingComponent,
                    BusinessTransactionHelper.typeId.pendingMatter.from,
                    BusinessTransactionHelper.typeId.pendingMatter.to,
                    this.pageIndex,
                    this.pageSize,
                    sort,
                    this.completedIssue
                );
    
                journalObservable.subscribe(journals => {
                    this.closedissues = journals.data;
                    this.closedissues = this.closedissues.reverse();
                    this.totalCountClosedIssues = journals.totalCount;
                    this.dataLoading = false;
                    this.loadingService.display(false);
                }, error => {
                    this.dataLoading = false;
                    this.loadingService.display(false);
                });

        }




    }

    getCompaniesAndInsurances() {
        this.journalProvider.getCompaniesAndInsurances().subscribe({
          next: (res) => {
            this.insuranceTypes = res['distinctProductBundleName'];
            this.companies = res['distinctRiskCarriers'];
          },
          error: (error) => {
            console.error(error)          
        }
        });
      }
    
    search() {
        if (this.tabIndex === 0) {
            this.getOpenIssues(this.filterValueForIssuesOpen);
        } else {
            this.getClosedIssues(this.filterValueForIssuesClosed)
        }
    }

    searchFilter() {
        this.router.navigate(["/myjournals"], {
            queryParams: {
                text: this.searchText,
                company: this.selectedCompany,
                insurance: this.selectedInsurance,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize
            }
        })
    }

    clearSearch() {
        this.selectedCompanies='';
        this.selectedInsurances='';
        this.searchText = '';
        this.selectedCompany = null;
        this.selectedInsurance = '';
        this.router.navigate(["/myjournals"], {
            queryParams: {
                text: "",
                company: "",
                insurance: "",
                pageIndex: this.pageIndex,
                pageSize: this.pageSize
            }
        })
    }

    set dataLoading(dataLoading: boolean) {
        if (this.isInitialized) {
            this.isDataLoading = dataLoading;
        }
    }
    paginatorOpenIssuesValueChanged(event: PageEvent) {  
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
        this.router.navigate(["/myjournals"], {
            queryParams: {
                text: this.searchText,
                company: this.selectedCompany,
                insurance: this.selectedInsurance,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize
            }
        })
    }

    paginatorClosedIssuesValueChanged(event: PageEvent) {  
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
        this.router.navigate(["/myjournals"], {
            queryParams: {
                text: this.searchText,
                company: this.selectedCompany,
                insurance: this.selectedInsurance,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize
            }
        })
    }

    
  tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    this.tabIndex = tabChangeEvent.index;
    this.pageIndex = 0
    if(this.tabIndex === 0){
        this.getOpenIssues(this.filterValueForIssuesOpen);
    }else{
        this.getClosedIssues(this.filterValueForIssuesClosed)
    }
  }
}