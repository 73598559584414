export enum LeadLog {
    TRANSFER = "TRANSFER",
    CREATE_LEAD = "CREATE LEAD",
    LEAD_UPDATE = "LEAD DATA UPDATE",
    TERMIN_UPDATE = "TERMIN DATA UPDATE",
    LEAD_S_UPDATE = "LEAD STATUS UPDATE",
    TERMIN_S_UPDATE = "TERMIN STATUS UPDATE",
    NOTES = "NOTES",
    REMINDER = "REMINDER",
    TERMIN = "TERMIN"
}