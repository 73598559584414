import { INeoRegion } from "../interfaces/INeoRegion";

export class NeoRegion {
    public Region: number;
    public PLZ: number;
    public Kanton: string;
    public Ort: string;
    public Gemeinde: string;
    public key: string;
    public Bfs?: number;

    constructor(zip?: number,
                kanton?: string,
                regionszahl?: number,
                ort?: string,
                gemeinde?: string,
                Bfs?: number) {
        this.Region = regionszahl;
        this.PLZ = zip;
        this.Kanton = kanton;
        this.Ort = ort;
        this.Gemeinde = gemeinde;
        this.Bfs = Bfs;
    }

    public static fromJson(region: INeoRegion): NeoRegion {
        const result = new NeoRegion();
        result.Gemeinde = region.Gemeinde;
        result.Kanton = region.Kanton;
        result.PLZ = region.PLZ;
        result.Ort = region.Ort;
        result.Region = region.Region;
        result.Bfs = region.Bfs;
        return result;
    }

    public static fromRegion(v: INeoRegion) {
        return new NeoRegion(v.PLZ, v.Kanton, v.Region, v.Ort, v.Gemeinde, v.Bfs);
    }

    public toJson(): INeoRegion {
        const result = new NeoRegion();
        result.Gemeinde = this.Gemeinde;
        result.Kanton = this.Kanton;
        result.Ort = this.Ort;
        result.PLZ = this.PLZ;
        result.Bfs = this.Bfs;
        result.Region = this.Region;
        return result;
    }

    get value(): string {
        return `${this.PLZ.toString()} - ${this.Ort} (${this.Gemeinde})`;
    }

    public toString(): string {
        return this.value;
    }
}

