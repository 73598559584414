<div class="mdc-layout-grid msg-helper-padding-none neomp-action-dialog">
    <!-- dialog header -->
    <div mat-dialog-title class="neomp-dialog-title">{{dialogHeader}}</div>

    <!-- dialog content -->
    <div mat-dialog-content class="mdc-layout-grid__inner neomp-dialog-content" [formGroup]="formGroupInputs">

        <!-- Action -->
        <div class="mdc-layout-grid__cell
                                        mdc-layout-grid__cell--span-6-desktop
                                         mdc-layout-grid__cell--span-6-tablet
                                         mdc-layout-grid__cell--span-12-phone">
            <mat-form-field class="mat-form-field-parent-width margin-top-bottom">
                <mat-select matSelect placeholder="{{ 'DIALOG.PLACEHOLDER.ACTIONS.ACTION' | translate }}"
                            [readonly]="true"
                            [formControlName]="fcnActions" required>
                    <mat-option *ngFor="let action of keyValuesActionSubjects | keyvalue"
                                [value]="action.key"
                                (onSelectionChange)="onSelectAction($event)">
                        {{action.key}}
                    </mat-option>

                </mat-select>
                <mat-error *ngIf="formGroupInputs.controls['actions'].hasError('required')">
                    {{ 'DIALOG.INPUT.REQUIREDERROR' | translate }}
                </mat-error>
            </mat-form-field>
        </div>

        <!-- Subject -->
        <div class="mdc-layout-grid__cell
                                        mdc-layout-grid__cell--span-6-desktop
                                         mdc-layout-grid__cell--span-6-tablet
                                         mdc-layout-grid__cell--span-12-phone">
            <mat-form-field class="mat-form-field-parent-width margin-top-bottom">
                <mat-select matSelect placeholder="{{ 'DIALOG.PLACEHOLDER.ACTIONS.SUBJECT' | translate }}"
                            [formControlName]="fcnSubjects"
                            [readonly]="true"
                            required>
                    <mat-option *ngFor="let subject of subjects"
                                [value]="subject"
                                (onSelectionChange)="onSelectSubject($event)">
                        {{subject}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="formGroupInputs.controls['subjects'].hasError('required')">
                    {{ 'DIALOG.INPUT.REQUIREDERROR' | translate }}
                </mat-error>
            </mat-form-field>
        </div>

        <!-- Title -->
        <div class="mdc-layout-grid__cell
                                        mdc-layout-grid__cell--span-12-desktop
                                         mdc-layout-grid__cell--span-12-tablet
                                         mdc-layout-grid__cell--span-12-phone">
            <mat-form-field class="mat-form-field-parent-width margin-top-bottom">
                <input matInput placeholder="{{ 'DIALOG.PLACEHOLDER.ACTIONS.TITLE' | translate }}"
                       [(ngModel)]="dialogInputTitleText"
                       [required]="true"
                       [formControlName]="fcnTitle">
                <mat-error *ngIf="formGroupInputs.controls['title'].hasError('required')">
                    {{ 'DIALOG.INPUT.REQUIREDERROR' | translate }}
                </mat-error>
                <mat-error *ngIf="formGroupInputs.controls['title'].hasError('maxlength')">
                    {{ 'DIALOG.INPUT.MAXLENGTHERROR' | translate }}
                </mat-error>
            </mat-form-field>
        </div>

        <!-- Message -->
        <div class="mdc-layout-grid__cell
                                            mdc-layout-grid__cell--span-12-desktop
                                             mdc-layout-grid__cell--span-12-tablet
                                             mdc-layout-grid__cell--span-12-phone">
            <mat-form-field class="mat-form-field-parent-width margin-top-bottom">
                        <textarea matInput class="textarea-dialog-height"
                                  placeholder="{{ 'DIALOG.PLACEHOLDER.ACTIONS.MESSAGE' | translate }}"
                                  [required]="true"
                                  [formControlName]="fcnMessage"
                                  [(ngModel)]="currentMessage">
                        </textarea>
                <mat-error *ngIf="formGroupInputs.controls['message'].hasError('required')">
                    {{ 'DIALOG.INPUT.REQUIREDERROR' | translate }}
                </mat-error>
            </mat-form-field>
        </div>
    </div>

    <!-- dialog actions -->
    <div mat-dialog-actions class="neomp-dialog-actions">
        <button mat-button class="neomp-dialog-action-button cancel-action" (click)="cancelDialog()">
            {{ 'DIALOG.BUTTON.CANCEL' | translate }}
        </button>
        <button mat-button class="neomp-dialog-action-button save-action" (click)="saveBT()">
            {{ 'DIALOG.BUTTON.SAVE' | translate }}
        </button>
    </div>
</div>
