<mat-form-field floatLabel="never">
    <!-- prefix icon -->
    <span matPrefix [hidden]="!searchTerm || (!urlIsSearchPage() && searchTerm)">
        <button (click)="endSearch()" mat-icon-button>
            <mat-icon>arrow_back_ios</mat-icon>
        </button>
    </span>

    <!-- search input -->
    <input #searchInput type="search"
           placeholder="{{ 'HEADER.SEARCHBOX.PLACEHOLDER' | translate }}"
           [(ngModel)]="searchTerm"
           [disabled]="searchInProgress"
           (keyup.enter)="inputChanged(searchTerm)"
           matInput/>

    <!-- suffix icon -->
    <span matSuffix>
        <button (click)="inputChanged(searchTerm)" mat-icon-button>
            <mat-icon>search</mat-icon>
        </button>
    </span>

    <span matSuffix>
        <button  mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon aria-label="Search option">
                keyboard_arrow_down
            </mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <mat-radio-group
            (change)="searchTypeChanged()"
            aria-labelledby="neo-radio-group-label"
            class="neomp-action-dialog  neo-radio-group"
            [(ngModel)]="searchType"
            >
            <mat-radio-button class="neo-radio-button"  [value]="SearchType.All">
                {{ 'HEADER.SEARCHBOX.ALL' | translate }}
            </mat-radio-button>

            <mat-radio-button class="neo-radio-button"  [value]="SearchType.Product">
                {{ 'HEADER.SEARCHBOX.PRODUCT' | translate }}
            </mat-radio-button>

          </mat-radio-group>
        </mat-menu>
    </span>
</mat-form-field>
