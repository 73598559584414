import {LeadHypoJson} from "../json/leadHypo.json";

export class LeadHypo {
    hypo_objecttype=""
    hypo_income=""
    hypo_propertyvalue=""
    hypo_ownfunds=""
    hypo_interest=""
    hypo_portability=""
    isHypo = false;

    constructor(data?: LeadHypoJson) {
        if (data) {
            this.hypo_objecttype = data.hypo_objecttype;
            this.hypo_income= data.hypo_income;
            this.hypo_propertyvalue = data.hypo_propertyvalue;
            this.hypo_ownfunds = data.hypo_ownfunds;
            this.hypo_interest = data.hypo_interest;
            this.hypo_portability = data.hypo_portability;
            this.isHypo = data.isHypo;
        }
    }
}
