import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterTableComponent } from 'app/modules/core/components/tables/filter-table-component';
import { Person } from 'app/modules/core/model/person';
import { AppointmentProvider } from 'app/modules/core/providers/appointment.provider';
import { RegisterProvider } from 'app/modules/core/providers/register.provider';
import { Observable } from "rxjs";
import { Helper } from 'app/modules/core/static/helper';
import { KeycloakService } from 'keycloak-angular';
import { LeadsService } from "app/modules/leads/services/leads.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import moment from "moment";
import { UserCommitState } from "app/modules/core/model/user-commit-state";
import { ActionDialogData } from "app/modules/core/components/action.dialog.component";
import { ActionDialogService } from "app/modules/core/providers/action-dialog.service";
import { BusinessTransactionHelper } from 'app/modules/core/static/bt-helper';
import { AgenturType } from 'app/modules/core/model/enum-agenturtype';
import { LeadLog } from 'app/modules/core/model/LeadLog.enum';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'neomp-termine-table',
  templateUrl: './termine-table.component.html',
  styleUrls: ['./termine-table.component.scss']
})
export class TermineTableComponent  extends FilterTableComponent<Person>
implements OnInit, OnChanges, OnDestroy
{
  private readonly TAG = this.constructor.name;
@Input()
leads: Person[] = [];
@Input()
managementView: boolean = false;
@Input()
calendarView: boolean = false;
@Input()
agents: Person[] = [];
selection = new SelectionModel<Person>(true, []);
@Output()
selectedRows: EventEmitter<SelectionModel<Person>> = new EventEmitter<SelectionModel<Person>>();

@Output()
appointmentId: EventEmitter<string> = new EventEmitter();

@Output()
resetData: EventEmitter<string> = new EventEmitter();

leadCompletlyLoaded = false;
isDialogOpen = false;

completedOptions = ["Ja", "Nein"];

filterValues: Person = new Person();

isHighRole: boolean = true;
agenturType: string = "";
agenturName: string = "";
advisorName: string = "";
agenturId: string = "";

termineSelectFilter = new FormControl("");
appointmentInfoFilterFC = new FormControl("");
appointmentDateFilterFC = new FormControl("");
hourFilterFC = new FormControl("");
typeFilterFC = new FormControl("");
statusFilterFC = new FormControl("");
substatusFilterFC = new FormControl("");
categoryFilterFC = new FormControl("");
artFilterFC = new FormControl("");
languageFilterFC = new FormControl("");
advisorFilterFC = new FormControl("");


formControlFilters: FormControl[] = [
  this.appointmentInfoFilterFC,
  this.appointmentDateFilterFC,
  this.hourFilterFC,
  this.typeFilterFC,
  this.statusFilterFC,
  this.substatusFilterFC,
  this.categoryFilterFC,
  this.artFilterFC,
  this.languageFilterFC,
  this.advisorFilterFC,
];


displayedColumns = [
  "appointmentInfo",
  "appointmentDate",
  "hour",
  "type",
  "statusTermine",
  "substatusTermine",
  "category",
  "art",
  "language",
  "advisor",
  'icon',
  "filter"
];
displayedColumnsWithoutFilter = [
  "appointmentInfo",
  "appointmentDate",
  "hour",
  "type",
  "statusTermine",
  "substatusTermine",
  "category",
  "art",
  "language",
  "advisor",
  'iconFilter'
];

displayedColumnFilters = [
  "appointmentInfoFilter",
  "appointmentDateFilter",
  "hourFilter",
  "typeFilter",
  "statusFilter",
  "substatusFilter",
  "categoryFilter",
  "artFilter",
  "languageFilter",
  "advisorFilter",
  'iconFilter',
  "filterRemove",
];
 /** Whether the number of selected elements matches the total number of rows. */
 isAllSelected() {
  const numSelected = this.selection.selected.length;
  const numRows = this.dataSource.data.length;
  return numSelected === numRows;
}

/** Selects all rows if they are not all selected; otherwise clear selection. */
toggleAllRows() {
  if (this.isAllSelected()) {
    this.selection.clear();
    return;
  }

  this.selection.select(...this.dataSource.data);
}

/** The label for the checkbox on the passed row */
checkboxLabel(row?: any): string {
  if (!row) {
    return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
  } else {
  return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }
}

constructor(
  public appointmentProvider: AppointmentProvider,
  public router: Router,
  public registerProvider: RegisterProvider,
  public activatedRoute: ActivatedRoute,   
  public snackBar: MatSnackBar,
  private keycloak: KeycloakService,
  private leadsService: LeadsService,
  private dialogService: ActionDialogService,
  public dialog: MatDialog
) {
  super();
}

async ngOnInit(): Promise<void> {
  this.selection.changed.subscribe(
    () => this.selectedRows.emit(this.selection)
  );
  await this.keycloak.loadUserProfile().then((res: any) => { 
    this.advisorName = res.firstName;
    this.agenturType = res.attributes.agenturType[0];
    this.agenturName = res.attributes.agenturName[0];
    this.agenturId = res.attributes.agenturID[0];
    this.isHighRole = (this.agenturType === AgenturType.SalesManager || this.agenturType === AgenturType.GeneralAgent || this.agenturType === AgenturType.TeamLead)
    if(!this.isHighRole){
      this.displayedColumnFilters = this.displayedColumnFilters.filter(x => x != "advisorFilter");
      this.displayedColumns = this.displayedColumns.filter(x => x != "advisor");
      this.displayedColumnsWithoutFilter = this.displayedColumnsWithoutFilter.filter(x => x != "advisor");
    }
    if (this.calendarView && this.managementView) {
      this.displayedColumns = this.displayedColumns.filter(col =>
        col != "type" && col != "statusTermine" && col != "substatusTermine" && col != "category" && col != 'art' && col != "language" && col != 'icon' && col != "filter"
      );
    }
    
    if (this.calendarView && this.managementView) {
      this.displayedColumnsWithoutFilter = this.displayedColumnsWithoutFilter.filter(col =>
        col != "type" && col != "statusTermine" && col != "substatusTermine" && col != "category" && col != 'art' && col != "language" && col != 'iconFilter'
      );
    }

    if (this.calendarView && this.managementView) {
      this.displayedColumnFilters = this.displayedColumnFilters.filter(col =>
        col != "typeFilter" && col != "statusFilter" && col != "substatusFilter" && col != "categoryFilter" && col != 'artFilter' && col != "languageFilter" && col != 'iconFilter' && col != "filterRemove"
      );
    }
  });

  this.showFilter = false;
}

ngOnChanges(changes: SimpleChanges): void {
  if ('managementView' in changes) {
    if (changes.managementView.currentValue) {
      this.displayedColumns.unshift('select'); 
      this.displayedColumnFilters.unshift('termineSelectFilter');
      this.formControlFilters.unshift(this.termineSelectFilter);
    }
  }
  if ("leads" in changes) {
    this.dataSource.data = this.leads.sort((a, b) => new Date(b.lead.termin_date).getTime() - new Date(a.lead.termin_date).getTime());
  }
  

}

resetFilter() {
  this.filterValues = new Person();
  super.resetFilter();
}

createFilter(): (data: Person, filter: string) => boolean {
  return function (innerData, innerFilter): boolean {
    const searchTerms : Termin = JSON.parse(innerFilter);
    if(searchTerms.appointmentDate == undefined){
      searchTerms.appointmentDate = "";
    } else {
      searchTerms.appointmentDate = moment(new Date(searchTerms.appointmentDate)).format('YYYY-MM-DD');
    }
    
    return (
      (
        (searchTerms.appointmentInfo !== null
          ? Helper.checkStringContains(
            innerData.fullName + ", " + innerData.address.postalCode + " " + innerData.address.street || "",
            searchTerms.appointmentInfo
          )
          : true))
      &&
      (searchTerms.appointmentDate !== null && innerData.lead !== null
        ? Helper.checkStringContains(
          innerData.lead.termin_date,
          searchTerms.appointmentDate
        )
        : true)
      &&
      (searchTerms.hour !== null && innerData.lead !== null
        ? Helper.checkStringContains(
          innerData.lead.termin_time,
          searchTerms.hour
        )
        : true)
      &&
      (searchTerms.type !== null && innerData.lead !== null
        ? Helper.checkStringContains(
          innerData.lead.termin_type,
          searchTerms.type
        )
        : true)
      &&
      (searchTerms.statusTermine !== null && innerData.lead !== null
        ? Helper.checkStringContains(
          innerData.lead.termin_status,
          searchTerms.statusTermine
        )
        : true)
      &&
      (searchTerms.substatusTermine !== null && innerData.lead !== null
        ? Helper.checkStringContains(
          innerData.lead.termin_substatus,
          searchTerms.substatusTermine
        )
        : true)
      &&
      (searchTerms.category !== null && innerData.lead !== null
        ? Helper.checkStringContains(
          innerData.lead.termin_kategory,
          searchTerms.category
        )
        : true) 
      &&
      (searchTerms.art !== null && innerData.lead !== null
        ? Helper.checkStringContains(
          innerData.lead.lead_typ,
          searchTerms.art
        )
        : true)
      &&
      (searchTerms.language !== null && innerData.lead !== null
        ? Helper.checkStringContains(
          // innerData,
          "DE",
          searchTerms.language
        )
        : true)
      &&
      (searchTerms.advisor !== null && innerData.lead !== null
        ? Helper.checkStringContains(
          // innerData.lead.,
          innerData.sorKeys.agent,
          searchTerms.advisor
        )
        : true)
    );
  };
}

getClassFromStatus(leadUuid: string){
  if (leadUuid.startsWith('myneolead-')){
    return "warning-status"
  }
}

getAgents(): Observable<Person[]>{
  return this.appointmentProvider
    .getAgents(false)
}

agentsControlOnClick(event: Event){
  event.stopPropagation();
}

assignAgentToLead(agent: Person, lead: Person){
    const sorKeys = {
      'advisorId': agent.uuid,
      'leadId': lead.uuid
    }

    lead.lead.termin_editdate = moment().format('YYYY-MM-DD');
    lead.lead.termin_berater_shortcut = agent.uuid;
    
    const actionData = BusinessTransactionHelper.createLeadBTActionObject(
      LeadLog.TRANSFER,
      BusinessTransactionHelper.typeId.assignLeadToAgent.from,
      '',
      'Termin TRANSFER => ' + agent.fullName,
      '',
      agent.uuid,
      [],
      lead,
      moment().add(5, 'days').format('YYYY-MM-DD'),
      sorKeys,
      this.advisorName
    );

      this.leadsService.sendLeadAction(actionData)
        .subscribe(() => {
          this.snackBar.open("Lead updated successfully", "X", UserCommitState.configSuccess);
          this.resetData.emit("true");
        }, err => {
          this.snackBar.open("Something went wrong!", "X", UserCommitState.configError)
        })
}


openActionDialog(event: Event, element: Person) {
  this.isDialogOpen = true;
  event.stopPropagation();
const dialogData: ActionDialogData = {
      transTypeId: BusinessTransactionHelper.typeId.leadNegative.from, // 4502
      transName: "Lead Task Negative",
      transSubject: "Lead Task Negative",
      transTitle: "Lead Task Negative",
      transMessage: "Lead Task Negative",
      operationalIdExternal: element.sorKeys["ProcessID"],
      keyValues: [],
      person: element
  };
  this.dialogService.openByType(dialogData,
      {
          defaultTypeId: BusinessTransactionHelper.typeId.leadNegative.from
      }
      , this.TAG);
      this.dialogService.isUpdated.subscribe(x=>{
        this.dataSource.data.splice(this.dataSource.data.indexOf(element), 1);
        this.dataSource.data = this.leads 
      })
      this.dialog.afterAllClosed.subscribe(() => {
        this.isDialogOpen = false;
    });
}

getSelectedAgent(uuid: string): Person {
  return this.agents.find(x => x.uuid === uuid);
}
  redirectToEdit(uuid: string): void {
    if (this.calendarView) {
      this.appointmentId.emit(uuid)
    } else {
      if (!uuid.startsWith('myneolead-')) {
        this.router.navigate([`/termine/${uuid}/edit`]);
      }
    }
  }
ngOnDestroy() {
}
}

export interface Termin {
  appointmentInfo: string;
  appointmentDate: string;
  hour: string;
  type: string;
  statusTermine: string;
  substatusTermine: string;
  category: string;
  art: string;
  language: string;
  advisor: string;
  filter: string;
}
