import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { FileUpload } from '../model/file-upload';
import { ConfigProvider } from '../providers/config.provider';

@Component({
  selector: 'neomp-upload-document',
  templateUrl: './upload-document.component.html',
  styleUrls: ['./upload-document.component.scss']
})
export class UploadDocumentComponent implements OnInit {
  accept = "*/*";
  lastFileAt: Date;
  dragFiles: any;
  validComboDrag: any;
  lastInvalids: File[] = [];
  maxSize: number;
  baseDropValid: any;
  maxCount: number;

  fileUploads: FileUpload[] = [];
  files: File[] = [];
  dataSource: MatTableDataSource<File>;

  @Input()
  isNested = false;

  @Input()
  headlineText = "*N/A*";

  @Input()
  showHeadline = true;

  @Output()
  fileReadEmitter = new EventEmitter<FileUpload[]>();

  @Output()
  valid: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  @Input()
  shadow = true;

  constructor(public configProvider: ConfigProvider) {
    this.dataSource = new MatTableDataSource(this.files);
  }

  ngOnInit(): void {
    this.configProvider
      .getConfigValue({ defaultTypeId: "uiConfig" })
      .subscribe((value) => {
        const tmpMaxSize =
          value.data != null
            ? value.data.find((value) => value.key === "uploadMaxFileSize")
            : null;
        if (
          tmpMaxSize !== null &&
          tmpMaxSize !== undefined &&
          !isNaN(+tmpMaxSize.value)
        ) {
          this.maxSize = +tmpMaxSize.value * 1024;
        } else {
          this.maxSize = 5 * 1024 * 1024;
        }

        const tmpMaxCount =
          value != null
            ? value.data.find((value) => value.key === "uploadMaxFileCount")
            : null;
        if (
          tmpMaxCount !== null &&
          tmpMaxCount !== undefined &&
          !isNaN(+tmpMaxCount.value)
        ) {
          this.maxCount = +value.data.find(
            (value) => value.key === "uploadMaxFileCount"
          ).value;
        } else {
          this.maxCount = 5;
        }
      });
  }

  changeFiles() {
    if (this.files.length >= 1) {
      this.valid.emit(true);
    }
    // this.valid.emit(this.files.length >= 1);
    while (this.maxCount != null && this.files.length > this.maxCount) {
      this.lastInvalids.push(this.files.pop());
    }
    this.dataSource.data = this.files;
  }

  readFiles() {
    if (this.files.length < 1) {
      this.fileReadEmitter.next(this.fileUploads);
    } else {
      this.files.forEach((file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = (e) => {
          this.fileUploads.push(
            new FileUpload(file.name, reader.result as string, file.size)
          );
          if (this.allFilesLoaded()) {
            this.fileReadEmitter.next(this.fileUploads);
          }
        };
      });
    }
  }

  resetFiles() {
    this.fileUploads = [];
  }

  resetComponent() {
    this.files = [];
    this.fileUploads = [];
  }

  allFilesLoaded() {
    return this.files.length === this.fileUploads.length;
  }

  removeFile(element) {
    this.files.splice(
      this.files.findIndex((value) => value === element),
      1
    );
    this.dataSource.data = this.files;
    if (this.files.length == 0) {
      this.valid.emit(false);
    }
  }
}