<table [dataSource]="dataSource" class="msg-helper-width-100"
       mat-table
       matSort
       matSortActive="validFrom"
       matSortDirection="desc">
    <ng-container matColumnDef="status">
        <th *matHeaderCellDef mat-header-cell></th>
        <td *matCellDef="let element" mat-cell></td>
    </ng-container>
    <ng-container matColumnDef="leadOrEigenterminConnection">
        <th *matHeaderCellDef mat-header-cell></th>
        <td *matCellDef="let element" mat-cell style="padding: 7px" (click)="onHandleClickForConnection($event, element)">
            <button *ngIf="element.sorKeys.leadOrEigenterminConnection" [class.connection]="element.sorKeys.leadOrEigenterminConnection" [class.connection-width]="element.sorKeys.leadOrEigenterminConnection" [class.connection-lead]="element.sorKeys.leadOrEigenterminConnection === 'lead'" [class.connection-eigentermin]="element.sorKeys.leadOrEigenterminConnection === 'eigentermin'">                
                {{element.sorKeys.leadOrEigenterminConnection === 'lead' ? "Lead" : element.sorKeys.leadOrEigenterminConnection === 'eigentermin' ? "Eigentermin" : ""}}
            </button>
        </td>
    </ng-container>
    <ng-container matColumnDef="uuid">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'PAGE.APPLICATIONS.TABLE.ID' | translate }}</th>
        <td *matCellDef="let element" mat-cell> {{element.uuid}}</td>
    </ng-container>
    <ng-container matColumnDef="customerName">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'PAGE.APPLICATIONS.TABLE.CUSTOMER' | translate }}</th>
        <td *matCellDef="let element"
            mat-cell>{{element.customerName != null ? element.customerName : 'PAGE.APPLICATIONS.TABLE.NOT_SET' | translate}}</td>
    </ng-container>
    <ng-container matColumnDef="policyStatus">
        <th *matHeaderCellDef mat-header-cell
            mat-sort-header>{{ 'PAGE.APPLICATIONS.TABLE.STATUS.TITLE' | translate }}</th>
        <td *matCellDef="let element" mat-cell> {{element.policyStatus}}</td>
    </ng-container>
    <ng-container matColumnDef="validFrom">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'PAGE.APPLICATIONS.TABLE.DATE' | translate }}</th>
        <td *matCellDef="let element" mat-cell> {{element.validFrom | date: 'dd.MM.yyyy'}}</td>
    </ng-container>
    <ng-container matColumnDef="insurer">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'PAGE.APPLICATIONS.TABLE.INSURER' | translate }}</th>
        <td *matCellDef="let element" mat-cell>{{element.insurer}}</td>
    </ng-container>
    <ng-container matColumnDef="lob">
        <th *matHeaderCellDef mat-header-cell
            mat-sort-header>{{ 'PAGE.APPLICATIONS.TABLE.LINE_OF_BUSINESS' | translate }}</th>
        <td *matCellDef="let element" mat-cell>{{element.lob}}</td>
    </ng-container>
    <ng-container matColumnDef="customerBirthday">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'PAGE.APPLICATIONS.TABLE.BIRTHDAY' | translate }}</th>
        <td *matCellDef="let element"
            mat-cell>{{element.customerName != null ? (element.customerBirthday | date: 'dd.MM.yyyy') : ('PAGE.APPLICATIONS.TABLE.NOT_SET' | translate)}}</td>
    </ng-container>
    <ng-container matColumnDef="cancellationStatus">
        <th *matHeaderCellDef mat-header-cell
            mat-sort-header>{{ 'PAGE.APPLICATIONS.TABLE.CANCELLATION_STATUS' | translate }}</th>
        <td *matCellDef="let element" mat-cell >{{element.cancellationStatus}}</td>
    </ng-container>

    <ng-container matColumnDef="filter">
        <th (click)="changeFilterView()" *matHeaderCellDef
            class="mat-header-cell mat-column-filter {{isFilterActive ? 'mat-column-filter-active':''}}"
            mat-header-cell>
            <div
                class="neomp-table-column-filter-element  mdi {{isFilterActive ? 'mdi-filter':'mdi-filter-outline'}}"></div>
        </th>
        <td *matCellDef="let element" mat-cell class="mat-column-filter-action">
            <div class="neomp-table-column-filter-element mdi mdi-plus" [disabled]="isDialogOpen"
            (click)="isDialogOpen ? null : openActionDialog($event, element)"></div>
        </td>
    </ng-container>

    <!-- Filter row definition -->
    <ng-container matColumnDef="uuidFilter">
        <th *matHeaderCellDef mat-header-cell>
            <mat-form-field class="filter" floatLabel="never">
                <input
                    [formControl]="uuidFilter"
                    placeholder="{{ 'TABLES.FILTERPLACEHOLDER.ID' | translate }}"
                    matInput>
            </mat-form-field>
        </th>
    </ng-container>

    <ng-container matColumnDef="customerNameFilter">
        <th *matHeaderCellDef mat-header-cell>
            <mat-form-field class="filter" floatLabel="never">
                <input
                    [formControl]="customerNameFilter"
                    placeholder="{{ 'TABLES.FILTERPLACEHOLDER.NAME' | translate }}"
                    matInput>
            </mat-form-field>
        </th>
    </ng-container>
    <ng-container matColumnDef="policyStatusFilter">
        <th *matHeaderCellDef mat-header-cell>
            <mat-form-field class="filter" floatLabel="never">
                <input
                    [formControl]="policyStatusFilter"
                    placeholder="{{ 'TABLES.FILTERPLACEHOLDER.STATUS' | translate }}"
                    matInput>
            </mat-form-field>
        </th>
    </ng-container>
    <ng-container matColumnDef="validFromFilter">
        <th *matHeaderCellDef mat-header-cell>
            <neomp-date-picker [setDefaultValues]="false" (filterValue)="validFromFilterChanged($event)"></neomp-date-picker>
        </th>
    </ng-container>
    
    <ng-container matColumnDef="insurerFilter">
        <th *matHeaderCellDef mat-header-cell>
            <mat-form-field class="filter" floatLabel="never">
                <input
                    [formControl]="insurerFilter"
                    placeholder="{{ 'TABLES.FILTERPLACEHOLDER.COMPANY' | translate }}"
                    matInput>
            </mat-form-field>
        </th>
    </ng-container>
    <ng-container matColumnDef="lobFilter">
        <th *matHeaderCellDef mat-header-cell>
            <mat-form-field class="filter" floatLabel="never">
                <input
                    [formControl]="lobFilter"
                    placeholder="{{ 'TABLES.FILTERPLACEHOLDER.SECTION' | translate }}"
                    matInput>
            </mat-form-field>
        </th>
    </ng-container>
    <ng-container matColumnDef="birthdateFilter">
        <th *matHeaderCellDef mat-header-cell>
            <neomp-date-picker [setDefaultValues]="false" (filterValue)="customerBirthdayFilterChanged($event)"></neomp-date-picker>
        </th>
    </ng-container>
    <ng-container matColumnDef="cancellationStatusFilter">
        <th *matHeaderCellDef mat-header-cell>
            <mat-form-field class="filter" floatLabel="never">
                <input
                    [formControl]="cancellationStatusFilter"
                    placeholder="{{ 'TABLES.FILTERPLACEHOLDER.CANCELLATION_STATUS' | translate }}"
                    matInput>
            </mat-form-field>
        </th>
    </ng-container>
    <ng-container matColumnDef="filterRemove">
        <th (click)="resetFilter()" *matHeaderCellDef mat-header-cell>
            <div class="neomp-table-column-filter-element mdi mdi-close"></div>
        </th>
    </ng-container>
    <!--end of  Filter row definition-->


    <tr *matHeaderRowDef="displayedColumns"
        mat-header-row></tr>

    <tr *matHeaderRowDef="displayedColumnFilters"
        class="neomp-table-row-filter {{showFilter? '': 'neomp-table-row-filter-hide'}}"
        mat-header-row></tr>
    <tr (click)="applicationsRowClick(applicationRow)"
        *matRowDef="let applicationRow; columns: displayedColumns"
        class="neomp-table-row-selected container-table-row {{applicationRow.uuid === selectedApplicationsRowIndex ? 'container-table-row-selected':''}}"
        mat-row></tr>
</table>
