import {Injectable, OnInit} from "@angular/core";
import {Router, CanActivate} from "@angular/router";
import { KeycloakService } from "keycloak-angular";
import { AgenturType } from "../model/enum-agenturtype";
import { Person } from "../model/person";

@Injectable()
export class LiveChatGuard implements CanActivate {
    agents: Person[] = [];

    liveChatAgent: string = "";
    constructor(public router: Router,  private keycloak: KeycloakService) {}
  
    
        async canActivate(): Promise<boolean> {
            await this.keycloak.loadUserProfile().then((res: any) => { 
              this.liveChatAgent = res.attributes.livechatAgent ? res.attributes.livechatAgent[0] : false;
            });
            return this.liveChatAgent === 'true'
        }
}
