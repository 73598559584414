import { SDAKey } from "./sdakey";
import { PostalAddress } from "./postal-address";
import { CommunicationAddress } from "./communication-address";
import { PersonJson } from "../json/person.json";
import { Approval } from "./approval";
import { Helper } from "app/modules/core/static/helper";
import { Lead } from "./lead";
import { LeadHypo } from "./leadHypo";
import { KeyValuePair } from "./key-value-pair";
import { Journal } from "./journal";

export class Person {
  uuid = "";
  created = "";
  updated = "";
  firstName = "";
  name = "";
  gender = "";
  grantList = "";
  nationality = "";
  maritalStatus = "";
  salutation = "";
  birthdate = "";
  address: PostalAddress = new PostalAddress();
  communicationAddresses: CommunicationAddress[] = [];
  approvals: Approval[] = [];
  partnerRole;
  preferedLanguage = "";
  sorKeys: { [key: string]: string } = {};
  groupeMutuelData: KeyValuePair[] = [];
  lead: Lead = new Lead();
  neoAppInfo: NeoAppInfo = new NeoAppInfo();
  email = "";
  leadHypo: LeadHypo = new LeadHypo();
  blocked: boolean;
  // partnerRelations?: object[] = []

  public static fromJson(response: PersonJson): Person {
    const result = new Person();
    result.uuid = response.uuid || "";
    result.created = response.created || "";
    result.updated = response.updated || "";
    result.name = response.name || "";
    result.firstName = response.firstName || "";
    result.gender = response.gender || "";
    result.grantList = response.grantList || "";
    result.nationality = response.nationality || "";
    result.maritalStatus = response.maritalStatus || "";
    result.salutation = response.salutation || "";
    result.birthdate = response.birthdate || "";
    result.address = response.address
      ? new PostalAddress(response.address)
      : null;
    result.communicationAddresses = response.communicationAddresses
      ? CommunicationAddress.fromJsons(response.communicationAddresses)
      : null;
    result.approvals = response.approvals
      ? Approval.fromJsons(response.approvals)
      : null;
    result.partnerRole = new SDAKey(response.partnerRole);
    result.preferedLanguage = response.preferedLanguage || "";
    result.sorKeys = response.sorKeys || {};
    result.lead = response.lead ? new Lead(response.lead) : null;
    result.neoAppInfo = response.neoAppInfo;
    result.groupeMutuelData = response.groupeMutuelData;
    result.email = response.email;
    result.leadHypo = new LeadHypo(response.leadHypo);
    result.blocked = response.blocked || null;
    return result;
  }

  public static toRequest(person: Person, alpha: boolean = false): Object {
    const str = JSON.stringify(person);
    const result = JSON.parse(str);
    result.postalAddress = result.address;
    result.created = Helper.stringToDatePickerFormat(new Date());
    if (alpha) {
      result.sorKey = {
        updated: Helper.stringToDatePickerFormat(new Date()),
        sorResourceKeys: [],
        otherIdentifiers: [],
        sorKeyElements: [
          {
            key: "alpha",
            value: "True",
          },
        ],
      };
    }
    return result;
  }

  public static fromJsons(response: PersonJson[]): Person[] | undefined {
    if (response) {
      return response.map((person) => Person.fromJson(person));
    }
  }

  get fullName(): string {
    return (this.firstName.length > 0 ? this.firstName + " " : "") + this.name;
  }

  /*
    // TODO AVa required?
    set fullName(v: string) {
        let s: string[] = [];
        s = v.split(" ");
        this.firstName = "";
        this.name = "";
        if (s.length >= 2) {
            this.firstName = s[0]
            this.name = s[1];
        } else if (s.length > 0) {
            this.name = s[0];
        }
    }
    */

  get nestedAddressPostalCode(): string {
    let address = "";
    if (this.address) {
      address =
        this.address.postalCode.length > 0 ? this.address.postalCode : "";
    }
    return address;
  }

  set nestedAddressPostalCode(v: string) {
    if (this.address) {
      this.address.postalCode = v;
    }
  }

  get nestedAddressCity(): string {
    let address = "";
    if (this.address) {
      address = this.address.city.length > 0 ? this.address.city : "";
    }
    return address;
  }

  set nestedAddressCity(v: string) {
    if (this.address) {
      this.address.city = v;
    }
  }
}

export class NeoAppInfo {
  active = false;
  lastLoggedIn = "";
  fcmToken = "";
  online=false;
  constructor(data?: NeoAppInfoJson) {
    if (data) {
      this.active = data.active;
      this.lastLoggedIn = data.lastLoggedIn;
      this.fcmToken = data.fcmToken;
      this.online=data.online;
    }
  }
}
export interface NeoAppInfoJson {
  active: boolean;
  lastLoggedIn: string;
  fcmToken: string;
  online:boolean;
}
