<table [dataSource]="dataSource" class="msg-helper-width-100" mat-table matSort>
    <ng-container matColumnDef="uuid">
        <th *matHeaderCellDef mat-header-cell
            mat-sort-header>{{ 'PAGE.CONTAINER.SEARCH_RESULT.CUSTOMER.CONTAINER.ID' | translate }}</th>
        <td mat-cell *matCellDef="let customer">{{customer.uuid}}</td>
    </ng-container>
    <ng-container matColumnDef="firstName">
        <th *matHeaderCellDef mat-header-cell
            mat-sort-header>{{ 'PAGE.CONTAINER.SEARCH_RESULT.CUSTOMER.CONTAINER.FIRSTNAME' | translate }}</th>
        <td mat-cell *matCellDef="let customer">{{customer.firstName}}</td>
    </ng-container>
    <ng-container matColumnDef="name">
        <th *matHeaderCellDef mat-header-cell
            mat-sort-header>{{ 'PAGE.CONTAINER.SEARCH_RESULT.CUSTOMER.CONTAINER.LASTNAME' | translate }}</th>
        <td mat-cell *matCellDef="let customer">{{customer.name}}</td>
    </ng-container>
    <ng-container matColumnDef="birthdate">
        <th *matHeaderCellDef mat-header-cell
            mat-sort-header>{{ 'PAGE.CONTAINER.SEARCH_RESULT.CUSTOMER.CONTAINER.BIRTHDATE' | translate }}</th>
        <td mat-cell *matCellDef="let customer">{{formatDate(customer.birthdate)}}</td>
    </ng-container>
    <ng-container matColumnDef="nestedAddressPostalCode">
        <th *matHeaderCellDef mat-header-cell
            mat-sort-header>{{ 'PAGE.CONTAINER.SEARCH_RESULT.CUSTOMER.CONTAINER.POSTALCODE' | translate }}</th>
        <td mat-cell *matCellDef="let customer">{{customer.nestedAddressPostalCode}}</td>
    </ng-container>
    <ng-container matColumnDef="nestedAddressCity">
        <th *matHeaderCellDef mat-header-cell
            mat-sort-header>{{ 'PAGE.CONTAINER.SEARCH_RESULT.CUSTOMER.CONTAINER.CITY' | translate }}</th>
        <td mat-cell *matCellDef="let customer">{{customer.nestedAddressCity}}</td>
    </ng-container>
    <ng-container matColumnDef="updated">
        <th *matHeaderCellDef mat-header-cell
            mat-sort-header>{{ 'PAGE.CONTAINER.SEARCH_RESULT.CUSTOMER.CONTAINER.UPDATED' | translate }}</th>
        <td mat-cell *matCellDef="let customer" [colSpan]="2">{{formatDate(customer.updated)}}</td>
    </ng-container>
    <ng-container matColumnDef="filter">
        <th (click)="changeFilterView()" *matHeaderCellDef
            class="mat-header-cell mat-column-filter {{isFilterActive ? 'mat-column-filter-active':''}}"
            mat-header-cell>
            <div
                class="neomp-table-column-filter-element  mdi {{isFilterActive ? 'mdi-filter':'mdi-filter-outline'}}"></div>
        </th>
        <td [hidden]="true" mat-cell *matCellDef="let element">
            <div class="neomp-table-column-filter-element mdi mdi-plus"></div>
        </td>
    </ng-container>

    <!-- Filter row definition -->
    <ng-container matColumnDef="uuidFilter">
        <th *matHeaderCellDef mat-header-cell>
            <mat-form-field class="filter" floatLabel="never">
                <input
                    [formControl]="uuidFilter"
                    placeholder="{{ 'TABLES.FILTERPLACEHOLDER.ID' | translate }}"
                    matInput>
            </mat-form-field>
        </th>
    </ng-container>
    <ng-container matColumnDef="firstNameFilter">
        <th *matHeaderCellDef mat-header-cell>
            <mat-form-field class="filter" floatLabel="never">
                <input
                    [formControl]="firstNameFilter"
                    placeholder="{{ 'TABLES.FILTERPLACEHOLDER.NAME' | translate }}"
                    matInput>
            </mat-form-field>
        </th>
    </ng-container>
    <ng-container matColumnDef="nameFilter">
        <th *matHeaderCellDef mat-header-cell>
            <mat-form-field class="filter" floatLabel="never">
                <input
                    [formControl]="nameFilter"
                    placeholder="{{ 'TABLES.FILTERPLACEHOLDER.NAME' | translate }}"
                    matInput>
            </mat-form-field>
        </th>
    </ng-container>
    <ng-container matColumnDef="birthdateFilter">
        <th mat-header-cell *matHeaderCellDef>
            <neomp-date-picker [setDefaultValues]="false" (filterValue)="customerBirthdayFilterChanged($event)"></neomp-date-picker>
        </th>
    </ng-container>
    <ng-container matColumnDef="nestedAddressPostalCodeFilter">
        <th *matHeaderCellDef mat-header-cell>
            <mat-form-field class="filter" floatLabel="never">
                <input
                    [formControl]="nestedAddressPostalCodeFilter"
                    placeholder="{{ 'TABLES.FILTERPLACEHOLDER.POSTALCODE' | translate }}"
                    matInput>
            </mat-form-field>
        </th>
    </ng-container>
    <ng-container matColumnDef="nestedAddressCityFilter">
        <th *matHeaderCellDef mat-header-cell>
            <mat-form-field class="filter" floatLabel="never">
                <input
                    [formControl]="nestedAddressCityFilter"
                    placeholder="{{ 'TABLES.FILTERPLACEHOLDER.CITY' | translate }}"
                    matInput>
            </mat-form-field>
        </th>
    </ng-container>
    <ng-container matColumnDef="updatedFilter">
        <th mat-header-cell *matHeaderCellDef>
            <neomp-date-picker [setDefaultValues]="false" (filterValue)="updatedFilterChanged($event)"></neomp-date-picker>
        </th>
    </ng-container>
    <ng-container matColumnDef="filterRemove">
        <th (click)="resetFilter()" *matHeaderCellDef mat-header-cell>
            <div class="neomp-table-column-filter-element mdi mdi-close"></div>
        </th>
    </ng-container>
    <!--end of  Filter row definition-->

    <tr *matHeaderRowDef="displayedColumns"
        mat-header-row></tr>
    <tr *matHeaderRowDef="displayedColumnFilters"
        class="neomp-table-row-filter {{showFilter? '': 'neomp-table-row-filter-hide'}}"
        mat-header-row></tr>
    <tr (click)="rowClick(row)"
        *matRowDef="let row; columns: displayedColumns"
        class="neomp-table-row-selected container-table-row {{row.uuid === selectedApplicationsRowIndex ? 'container-table-row-selected':''}}"
        mat-row></tr>
</table>
