import { Component, EventEmitter, Input, OnInit, OnDestroy, Output } from "@angular/core";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { ActivatedRoute, Router } from "@angular/router";
import { FormControl } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SelectionModel } from "@angular/cdk/collections";
import { forkJoin, Observable, Subscription } from "rxjs";
import { Helper } from "app/modules/core/static/helper";
import { BusinessTransactionHelper } from "app/modules/core/static/bt-helper";
import { ActionDialogService } from "app/modules/core/providers/action-dialog.service";
import { KeycloakService } from "keycloak-angular";
import { LeadsService } from "../../services/leads.service";
import { LoadingService } from "app/modules/core/providers/loading.component";
import { LeadProvider } from "app/modules/core/providers/lead.provider";
import { TranslateService } from "@ngx-translate/core";
import { FilterTableComponent } from "app/modules/core/components/tables/filter-table-component";
import { ActionData } from "app/modules/core/model/action-data";
import { UserCommitState } from "app/modules/core/model/user-commit-state";
import { PaginationConfigs } from "app/modules/constants/PaginationConstants";
import { KeyValuePair } from "app/modules/core/model/key-value-pair";
import { JournalList } from "app/modules/core/model/journal-list";
import { AgenturType } from "app/modules/core/model/enum-agenturtype";
import { PolicyJson } from "app/modules/core/json/policy.json";
import { JournalListJson } from "app/modules/core/json/journal-list.json";
import { Journal } from "app/modules/core/model/journal";
import { Person } from 'app/modules/core/model/person';
import { Policy } from "app/modules/core/model/policy";
import { format } from "date-fns";
import * as uuid from "uuid";
import moment from "moment";
import { PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { LeadQueryParams } from "app/modules/core/json/leadQueryParams.json";
import { distinctUntilChanged, filter } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { AdvisorDataService } from "app/modules/core/services/advisor-data.service";

@Component({
  selector: 'neomp-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.scss'],
  animations: [
    trigger("detailExpand", [
      state("collapsed, void", style({ height: "0px", minHeight: "0", display: "none" })),
      state("expanded", style({ height: "*" })),
      transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")),
      transition("expanded <=> void", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)"))
    ]),
  ]
})
export class TaskComponent extends FilterTableComponent<JournalList> implements OnInit, OnDestroy {
  // the Output decorator is added to inform the parent component that holds the number of tasks to update with the new total count of tasks; In this way the green circle will update the number of tasks whenever the number of tasks is changes
  @Output() totalTasksOutput = new EventEmitter<number>();
  @Input() terminLeadId; //this input is from leads-edit-termin component, is used to implemet the task table to this component based on lead id
  pageSize = PaginationConfigs.pageSize;
  pageSizeOptions = PaginationConfigs.pageSizeOptions;
  pageIndex = PaginationConfigs.pageIndex;
  totalTasks = 0;
  isDialogOpen = false;
  completedTasks = false;
  completedTasksVisible = false;

  advisorId: string = "";
  agenturType: string = "";
  isHighRole: boolean = true;
  agents: Person[] = [];

  selection = new SelectionModel<IProductBundleTable>(true, []);
  pBDisplayColumns: string[] = ['select', 'id', 'customer', 'birthdate', 'gender', 'art', 'pbstatus'];
  productsList = new MatTableDataSource<IProductBundleTable>();
  productsListComplete = [];

  selectedOpenIssuesRowIndex;
  expandedElement: JournalListJson | null;

  agentSelectedControl = new FormControl(null);

  dateFrom: any;
  dateTo: any;
  //Search variable that stores the test to be searched when a row is clicked.
  searchText: string = "";

  iconFilter = new FormControl("");
  nameFilter = new FormControl("");
  plzFilter = new FormControl("");
  categoryFilter = new FormControl("");
  statusNameFilter = new FormControl("");
  statusLeadFilter = new FormControl("");
  substatusLeadFilter = new FormControl("");
  effectiveDateFilter = new FormControl("");
  advisorFilter = new FormControl("");
  filterRemove = new FormControl("");

  filterValues = new JournalList({
    transaction: new Journal(),
    transactions: [],
    persons: [new Person()],
    policies: [],
    documentResources: []
  });

  formControlFilters: FormControl[] = [
    this.iconFilter,
    this.nameFilter,
    this.plzFilter,
    this.categoryFilter,
    this.statusNameFilter,
    this.statusLeadFilter,
    this.substatusLeadFilter,
    this.effectiveDateFilter,
    this.advisorFilter,
    this.filterRemove,
    null];


  displayedColumns = ['icon', 'customerName', 'customerPLZ', 'category', 'statusName', 'statusLead', 'substatusLead', 'effectiveDate', 'agentName', 'filter'];
  displayedColumnFilters = ['iconFilter', 'nameFilter', 'plzFilter', 'categoryFilter', 'statusNameFilter', 'statusLeadFilter', 'substatusLeadFilter', 'effectiveDateFilter', 'advisorFilter', 'filterRemove'];
  isSupplier: boolean = false;
  taskSearchText: string = "";
  isSearchButtonClicked: boolean = false;
  queryParamsSub: Subscription;
  
  constructor(
    private translate: TranslateService,
    private dialogService: ActionDialogService,
    private leadsService: LeadsService,
    private loadingService: LoadingService,
    private leadProvider: LeadProvider,
    private advisorDataService: AdvisorDataService,
    private router: Router,
    private route: ActivatedRoute,
    private keycloak: KeycloakService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {
    super();
    this.filterValues.completed = null;
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    } else {
      return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
    }
  }
  async ngOnInit(): Promise<void> {
    this.showFilter = false;
    super.initFilter();
    this.keycloak.loadUserProfile().then(async (res: any) => {
      this.advisorId = res.attributes.advisorid[0];
      this.agenturType = res.attributes.agenturType[0];
      this.isHighRole = (this.agenturType === AgenturType.SalesManager || this.agenturType === AgenturType.GeneralAgent || this.agenturType === AgenturType.TeamLead);
      this.isSupplier = this.agenturType === AgenturType.Supplier;

      if (!this.isHighRole) {
        this.displayedColumnFilters = this.displayedColumnFilters.filter(x => x != "advisorFilter");
        this.displayedColumns = this.displayedColumns.filter(x => x != "agentName");
      } else {
        this.agents = await this.getAgents();
      }
      this.queryParamsSub = this.route.queryParams
        .pipe(filter((x: LeadQueryParams) => x.st === 'tasks'))
        .pipe(distinctUntilChanged((prev: any, curr: LeadQueryParams) => JSON.stringify(prev) === JSON.stringify(curr)))
        .subscribe((params: any) => {
          this.searchFromParams(params);         
        });
    });

  }

  ngOnChanges(changes){
    /**checks if terminLeadId has value to update the task table at leads-edit-termin component */
    if("terminLeadId" in changes && changes.terminLeadId.currentValue !== changes.terminLeadId.previousValue){
      this.terminLeadId= changes?.terminLeadId['currentValue']
      this.getTasks(this.pageIndex, this.pageSize, this.getDateFilterQuery(), this.agentSelectedControl.value || undefined, this.terminLeadId)
    }
  }

  searchFromParams(params: LeadQueryParams) {
    if (params.st === 'tasks') {
      this.taskSearchText = params.text || "";
      this.completedTasks= params.completedTasks === "true" || params.completedTasks === true ? true : false;
      this.dateFrom = !Helper.isDateValid(params.dateFrom) ? undefined : params.dateFrom;
      this.dateTo = !Helper.isDateValid(params.dateTo) ? undefined : params.dateTo;
      this.agentSelectedControl.setValue(params.advisor);
      if (this.taskSearchText || this.dateFrom || this.dateTo || params.advisor || this.completedTasks) {
        this.isSearchButtonClicked = true;
      }
      this.pageIndex = params.pageIndex || PaginationConfigs.pageIndex;
      this.pageSize = Number(params.pageSize) > 0 ? Number(params.pageSize) : PaginationConfigs.pageSize;
      this.getTasks(this.pageIndex, this.pageSize, this.getDateFilterQuery(), this.agentSelectedControl.value || undefined, this.terminLeadId)
    }
  }

  elementRowClick(personRow: JournalListJson) {
    if (this.expandedElement === personRow) {
      this.selectedOpenIssuesRowIndex = -1;
      this.expandedElement = null;
      return;
    }
    this.selectedOpenIssuesRowIndex = personRow.persons[0].uuid;
    this.expandedElement = personRow;
    const advisorRef = personRow.transaction.references.find(x => x.type.key === "080" && !["", "0", "-1"].includes(x.id));
    this.getUnassignedProducts(this.selectedOpenIssuesRowIndex, Person.fromJson(personRow.persons[0]), advisorRef.id);
  }

  getUnassignedProducts(uuid: string, lead: Person, agentId: string): void {
    if (this.productsList.data.length) return;
    this.loadingService.display(true);
    this.productsList.data = [];
    this.leadsService.getUnassignedProducts(agentId, uuid)
      .subscribe(
        (policies: PolicyJson[]) => {
          this.productsList.data = policies.map(x => {
            const person = JSON.parse(x.sorKeys.person);
            return {
              uuid: person && person.uuid ? person.uuid : '',
              customerFullName: `${person && person.firstName ? person.firstName : ''} ${person && person.name ? person.name : ''}`,
              birthdate: person && person.birthdate ? person.birthdate : '',
              gender: person && person.gender && person.gender.sdaValue ? person.gender.sdaValue : '',
              pbstatus: x && x.status.sdaValue ? x.status.sdaValue : '',
              art: x && x.name ? x.name : '',
              policy: x
            }
          });
          // saving all items in another array to be used to filter them later
          this.productsListComplete = this.productsList.data;
          this.loadingService.display(false);
        },
        (err) => {
          this.loadingService.display(false);
          this.snackBar.open(this.translate.instant("COMMONS.USERCOMMIT.ERROR"), "X", UserCommitState.configError);
        }
      )
  }

  createFilter(): (data: JournalList, filter: string) => boolean {
    return function (innerData, innerFilter): boolean {
      const searchTerms = JSON.parse(innerFilter);
      if (!searchTerms.transaction.effectiveDate) {
        searchTerms.transaction.effectiveDate = "";
      } else {
        searchTerms.transaction.effectiveDate = moment(new Date(searchTerms.transaction.effectiveDate)).format('YYYY-MM-DD');
      }

      return (
        (searchTerms.persons[0].name
          ? Helper.checkStringContains(
            innerData.customerName,
            searchTerms.persons[0].name)
          : true) &&
        (searchTerms.persons[0].address.postalCode
          ? Helper.checkStringContains(
            innerData.customerPLZ,
            searchTerms.persons[0].address.postalCode
          )
          : true) &&
        (searchTerms.persons[0].lead.lead_status
          ? Helper.checkStringContains(
            innerData.statusLead,
            searchTerms.persons[0].lead.lead_status
          )
          : true) &&
        (searchTerms.persons[0].lead.lead_substatus
          ? Helper.checkStringContains(
            innerData.substatusLead,
            searchTerms.persons[0].lead.lead_substatus
          )
          : true) &&
        (searchTerms.statusName !== null
          ? Helper.checkStringContains(
            innerData.statusName,
            searchTerms.transaction.status.name
          )
          : true) &&
        (searchTerms.persons[0].lead.lead_kategory
          ? Helper.checkStringContains(
            innerData.category,
            searchTerms.persons[0].lead.lead_kategory
          )
          : true) &&
        (searchTerms.transaction.effectiveDate
          ? Helper.checkStringContains(
            innerData.effectiveDate,
            searchTerms.transaction.effectiveDate
          )
          : true)
        &&
        (searchTerms.transaction.sorKeys['agent']
          ? Helper.checkStringContains(
            innerData.agentName,
            searchTerms.transaction.sorKeys['agent']
          )
          : true)
      );
    };
  }

  resetFilter() {
    this.filterValues = new JournalList({ transaction: new Journal(), transactions: [], persons: [new Person()], policies: [], documentResources: [] });
    super.resetFilter();
  }
  clearSearch() {
    this.isSearchButtonClicked=false;
    this.taskSearchText='';
    this.dateFrom = undefined;
    this.dateTo = undefined;
    this.agentSelectedControl.reset();
    this.completedTasks = false;
    this.search(false);
  }
  changeFilterView() {
    this.showFilter = !this.showFilter;
  }

  getAgents(): Promise<Person[]> {
    return this.advisorDataService.getAgents();
  }

  toLead(element: JournalListJson) {
    if (element.persons.length > 0) {
      this.router.navigate([`/lead/${element.persons[0].uuid}/edit`]);
      this.productsList.data=[];
    }
  }

  linkLead(element: JournalList) {
    if (element.uuid) {
      const requests = [];
      for (let selectedRows of this.selection.selected) {
        const actionData: ActionData = new ActionData(new Journal(), [], new Person(), new Policy());
        actionData.transaction.typeId = BusinessTransactionHelper.typeId.productLeadLink.from;
        actionData.transaction.typeName = "#MKP Link lead with product";
        actionData.transaction.transactionName = "Link lead with product";
        actionData.transaction.uuid = uuid.v4();
        actionData.transaction.inputChannel = "myneo";
        actionData.transaction.ordererIdExternal = this.advisorId;
        actionData.person = element.persons[0];
        actionData.policy = Policy.fromJson(selectedRows.policy);
        requests.push(this.leadsService.sendLeadAction(actionData));
      }
      this.loadingService.display(true);
    forkJoin(requests).subscribe(
      () => {
          this.productsList.data=[];
          this.loadingService.display(false);
          this.selection.clear();
          this.selectedOpenIssuesRowIndex = -1;
          this.expandedElement = null;
          this.snackBar.open(this.translate.instant("COMMONS.USERCOMMIT.SUCCESS"), "X", UserCommitState.configSuccess);
        },
        err => {
          this.snackBar.open(this.translate.instant("COMMONS.USERCOMMIT.ERROR"), "X", UserCommitState.configError);
          this.loadingService.display(false);
        }
        )
    }
  }

  formatDate(date) {
    return Helper.formatDate(date);
  }

  openActionDialog(event: Event, journalList: JournalListJson) {
    this.isDialogOpen = true;
    event.stopPropagation();

    this.markTaskAsCompleted(journalList);

    this.dialog.afterAllClosed.subscribe(() => {
      this.isDialogOpen = false;
    })
  }

  getClassFromStatus(effectivDate, status): string | undefined {
    if (effectivDate === 'Invalid date' && status != 'Abgeschlossen') {
      return 'inactive-status';
    }
    const days = Helper.getdifferenceInDaysfromToday(effectivDate);
    if (status == 'Abgeschlossen') {
      return "completed-status"
    } else {
      if (days <= 1 && status != 'Abgeschlossen') {
        return "inactive-status";
      } else if (days <= 5 && status != 'Abgeschlossen') {
        return "warning-status";
      }
      return "active-status"
    }
  }

  taskTablePaginatorValueChanged(event: PageEvent) {
    if (this.pageIndex !== event.pageIndex || this.pageSize !== event.pageSize) {
      this.pageIndex = event.pageIndex;
      this.pageSize = event.pageSize;
      if(!this.terminLeadId){

      let formattedDateFrom = (![null, undefined, ""].includes(this.dateFrom)) ? format(new Date(this.dateFrom.toString()), "YYYY-MM-DDT00:00:00.000") : "";
      let formattedDateTo = (![null, undefined, ""].includes(this.dateTo)) ? format(new Date(this.dateTo.toString()), "YYYY-MM-DDT23:59:00.000") : "";

      const dateFrom = Helper.isDateValid(formattedDateFrom) ? formattedDateFrom : "";
      const dateTo = Helper.isDateValid(formattedDateTo) ? formattedDateTo : "";
      
        this.router.navigate(['/leads'], {
          queryParams: {
            st: 'tasks',
            text:this.taskSearchText,
            dateFrom: dateFrom,
            dateTo: dateTo,
            advisor: this.agentSelectedControl.value,
            completedTasks: this.completedTasks,
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
          }
        })
      }
      else {
        this.getTasks(this.pageIndex, this.pageSize, this.getDateFilterQuery(), this.agentSelectedControl.value || undefined, this.terminLeadId);
      }
    }
  }

  getTasks(page: number, size: number, filters?: string, agentId?: string, terminLeadId?: string): void {
    this.loadingService.display(true);
    const sort = { "status.name": 1, "effectiveDate": 1 };
      if(this.isSearchButtonClicked ){
        this.leadsService.getFilteredJournalsReferencedWithPagination(
          BusinessTransactionHelper.typeId.tasks.from,
          BusinessTransactionHelper.typeId.tasks.to,
          page,
          size,
          sort,
          filters,
          agentId,
          this.completedTasks,
          true,
          terminLeadId,
          this.taskSearchText
        )
          .subscribe((res: { data: JournalList[], totalCount: number }) => {
            this.completedTasksVisible = this.completedTasks;
            this.reloadTableData(res.data);
            this.dataSource.filterPredicate = this.createFilter();
            this.totalTasks = res.totalCount;
            this.loadingService.display(false);
          });
      }
      else{
        if (this.isSupplier) {
          this.leadsService.getJournalsReferencedWithPaginationForSupplier(
            BusinessTransactionHelper.typeId.tasks.from,
            BusinessTransactionHelper.typeId.tasks.to,
            page,
            size,
            sort,
            filters,
            agentId,
            this.completedTasks,
            true,
            terminLeadId
          )
            .subscribe((res: { data: JournalList[], totalCount: number }) => {
              this.completedTasksVisible = this.completedTasks;
              this.reloadTableData(res.data);
              this.dataSource.filterPredicate = this.createFilter();
              this.totalTasks = res.totalCount;
              this.loadingService.display(false);
            });
        } else {
          this.leadsService.getJournalsReferencedWithPagination(
            BusinessTransactionHelper.typeId.tasks.from,
            BusinessTransactionHelper.typeId.tasks.to,
            page,
            size,
            sort,
            filters,
            agentId,
            this.completedTasks,
            true,
            terminLeadId
          )
            .subscribe((res: { data: JournalList[], totalCount: number }) => {
              this.completedTasksVisible = this.completedTasks;
              this.reloadTableData(res.data);
              this.dataSource.filterPredicate = this.createFilter();
              this.totalTasks = res.totalCount;
              this.loadingService.display(false);
            });
        }

    }
  }

  search(isClicked:boolean=true): void {
    this.isSearchButtonClicked=isClicked;
    if(!this.terminLeadId){
    const dateFrom = this.dateFrom == undefined ? "" : format(new Date(this.dateFrom.toString()), "YYYY-MM-DDT00:00:00.000");
    const dateTo = this.dateTo == undefined ? "" : format(new Date(this.dateTo.toString()), "YYYY-MM-DDT23:59:00.000");
      this.router.navigate(['/leads'], {
        queryParams: {
          st: 'tasks',
          text:this.taskSearchText,
          dateFrom: dateFrom,
          dateTo: dateTo,
          advisor: this.agentSelectedControl.value,
          completedTasks: this.completedTasks,
          pageIndex: this.pageIndex,
          pageSize: this.pageSize,
        }
      })
      // The "getTasks" function call is removed here as it is triggered by queryParams changes 
      // this.getTasks(this.pageIndex, this.pageSize, this.getDateFilterQuery(), this.agentSelectedControl.value || undefined, this.terminLeadId);
    }
    else{
      this.getTasks(this.pageIndex, this.pageSize, this.getDateFilterQuery(), this.agentSelectedControl.value || undefined, this.terminLeadId);
    }
  }

  markTaskAsCompleted(journalList: JournalListJson): void {

    const keyValues = [new KeyValuePair(BusinessTransactionHelper.typeId.completeTask.from, "Aufgabe schliessen")];

    const completeTaskAction: ActionData = new ActionData(new Journal(), [], null, null);

    completeTaskAction.person = Person.fromJson(journalList.persons[0]);
    completeTaskAction.transaction = Journal.fromJson(journalList.transaction);
    completeTaskAction.transaction.typeId = BusinessTransactionHelper.typeId.completeTask.from;
    completeTaskAction.transaction.typeName = "#MKP Task complete";
    completeTaskAction.transaction.transactionName = "Task complete";
    completeTaskAction.transaction.inputChannel = "myneo";
    completeTaskAction.transaction.creationDate = moment().format('YYYY-MM-DDTHH:mm:ss.SSSZZ');
    completeTaskAction.transaction.reasonForChange = "Lead Schliessen";
    // completeTaskAction.transaction.uuid = journalList.transaction.uuid;
    completeTaskAction.transaction.ordererIdExternal = this.advisorId;
    completeTaskAction.transaction.status.completed = true;
    completeTaskAction.transaction.status.name = "Abgeschlossen";
    completeTaskAction.transaction.status.updated = moment().format('YYYY-MM-DDTHH:mm:ss.SSSZZ');
    // assign descriptionField
    completeTaskAction.transaction.descriptionField = completeTaskAction.transaction.descriptionField;

    const dialogRef = this.dialogService.updateTaskCompletedDialog(completeTaskAction, { keyValues, typeId: "4503" });

    dialogRef.afterClosed().subscribe((data: ActionData | null) => {

      if (!data) {
        return;
      }

      this.loadingService.display(true);
      this.leadsService.sendLeadAction(completeTaskAction)
        .subscribe(() => {
          this.loadingService.display(false);
          this.loadingService.setDialogClosed(true);
          this.snackBar.open(this.translate.instant("COMMONS.USERCOMMIT.SUCCESS"), "X", UserCommitState.configSuccess);
          this.search();
          // the emit below will tell the Output decorator to send to the parent component the new value of the total task count.
          this.totalTasksOutput.emit(0);
          this.productsList.data=[];
        }, error => {
          this.loadingService.display(false);
          this.snackBar.open(this.translate.instant("COMMONS.USERCOMMIT.ERROR"), "X", UserCommitState.configError);
        });
    })


  }

  getDateFilterQuery(): string | null {
    if (this.dateFrom === undefined && this.dateTo === undefined) {
      return null;
    } else {
      let formattedDateFrom = (![null, undefined, ""].includes(this.dateFrom)) ? format(new Date(this.dateFrom.toString()), "YYYY-MM-DDT00:00:00.000") : "";
      let formattedDateTo = (![null, undefined, ""].includes(this.dateTo)) ? format(new Date(this.dateTo.toString()), "YYYY-MM-DDT23:59:00.000") : "";
      
      const dateFrom = this.dateFrom === undefined ? format(new Date(1900, 0, 0), "YYYY-MM-DDT00:00:00.000") : Helper.isDateValid(formattedDateFrom) ? formattedDateFrom : "";
      const dateTo = this.dateTo === undefined ? format(new Date(), "YYYY-MM-DDT23:59:00.000") : Helper.isDateValid(formattedDateTo) ? formattedDateTo : "";

      return `filter={"type":"DateTime","key":"effectiveDate","from":"${dateFrom}","to":"${dateTo}"}`;
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.productsList.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.productsList.data);
  }

  filterProductsTable() {
    if (!this.searchText.length) {
      //Show all the list when no filter is made
      this.productsList.data = this.productsListComplete;
    } else {
      //filtering the list; using the toLowerCase to make it case non-sensitive
      this.productsList.data = this.productsListComplete.filter(p => p.customerFullName.toLowerCase().includes(this.searchText.toLowerCase()));
    }
  }

  clearFilterProductsTable() {
    this.searchText = "";//clear the filter text
    this.filterProductsTable();//search according to the empty text (it will show all the records)
  }

  ngOnDestroy(): void {
    this.queryParamsSub.unsubscribe();
  }
}

export interface IProductBundleTable {
  uuid: string;
  customerFullName: string;
  birthdate: string;
  gender: string;
  art: string;
  pbstatus: string;
  policy: PolicyJson;
}