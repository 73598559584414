import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {retry, catchError} from "rxjs/operators";
import {log} from "../providers/logger.provider";
import {environment} from "../../../../environments/environment";
import { Injectable } from "@angular/core";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    private readonly TAG = this.constructor.name;

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req)
            .pipe(
                // by default, try it once again before firing an erro
                // retry(1),
                catchError((error: HttpErrorResponse) => {
                    let errorMessage = "";
                    if (error.error instanceof ErrorEvent) {
                        // client-side error
                        log.error(`${this.TAG}; detected client-side-exception handled by global registered ErrorInterceptor.`);
                        errorMessage = `Error: ${error.error.message}`;
                    } else {
                        // server-side error
                        log.error(`${this.TAG}; detected server-side-exception handled by global registered ErrorInterceptor.`);
                        errorMessage = `Error-Code: ${error.status}; Msg=${error.message}`;
                    }
                    if (!environment.settings.production) {
                        window.alert(errorMessage);
                    }
                    return throwError(errorMessage);
                })
            );
    }
}
