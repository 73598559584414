<div class="{{elementAsLink === true ? 'msg-isquare msg-isquare-link' : 'msg-isquare'}} msg-isquare{{getColor()}}"
     (click)="openLink($event)">
    <div *ngIf="tinyRepresentation; else default">
        <div class="msg-isquare-tiny msg-isquare-wrapper">
            <div *ngIf="icon" class="msg-isquare-wrapper-img">
                <img [src]="icon" width="100%">
            </div>
        </div>
        <div *ngIf="iconHeadline" class="msg-isquare-tiny-headline-wrapper">
            <img [src]="iconHeadline"/>
        </div>
    </div>

    <ng-template #default>
        <div *ngIf="iconHeadline" class="msg-isquare-headline-wrapper">
            <img [src]="iconHeadline"/>
        </div>

        <div class="msg-isquare-wrapper">
            <div *ngIf="icon" class="msg-isquare-wrapper-img">
                <img [src]="icon" width="100%">
            </div>
        </div>

        <h4 class="msg-helper-text-center" *ngIf="headline && headline.length > 0">{{headline}}</h4>
        <p *ngIf="textContent && textContent.length > 0">
            {{textContent}}
        </p>
        <a *ngIf="showLink === true" (click)="openLink($event)"> {{linkText}} </a>

    </ng-template>
</div>
