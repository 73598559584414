import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PaginationConfigs } from 'app/modules/constants/PaginationConstants';
import { AdvisorService } from 'app/modules/core/providers/advisor.service';
import { ReferralsService } from '../services/referrals.service';
import { Person } from 'app/modules/core/model/person';
import { ReferralsTableComponent } from 'app/modules/customers/components/referrals-table.component';
@Component({
  selector: 'app-referrals',
  templateUrl: './referrals.component.html',
  styleUrls: ['./referrals.component.css']
})
export class ReferralsComponent  implements OnInit {



  @ViewChild(MatSort) sort: MatSort;
  pageSizeOptions = PaginationConfigs.pageSizeOptions;
  pageSize = PaginationConfigs.pageSize;
  pageIndex = PaginationConfigs.pageIndex;
  totalCount: number;
  unassignedReferrals: Person[] = [];
  referrals: any[] = [];



  constructor(private referralService: ReferralsService, private advisorService: AdvisorService) {   }
  
 
 
      ngOnInit() {
        this.getReferals(this.pageIndex, this.pageSize);
      }
    

      getReferals(page: number, size: number) {
        this.referrals = [];
        this.referralService.getReferals(page, size).subscribe(res => {
        this.referrals = res.data;
        this.totalCount = res.totalCount;
         })
    }

     paginatorReferalsValueChanged(event){
         this.pageIndex = event.pageIndex;
         this.pageSize = event.pageSize;
         this.getReferals(this.pageIndex, this.pageSize);
     }
 
    
}
