import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {CoreModule} from "../core/core.module";
import {MyCommissionPageComponent} from "./pages/my-commission-page.component";
import {ProvisionComponent} from "./components/provision.component";
import {ProvisionAccountingComponent} from "./components/provision-accounting.component";
import {ProvisionPendingMatterComponent} from "./components/provision-pending-matter.component";
import {ProvisionTableComponent} from "./components/tables/provision-table.component";
import {ProvisionAccountingTableComponent} from "./components/tables/provision-accounting-table.component";
import {ProvisionPendingMatterTableComponent} from "./components/tables/provision-pending-matter-table.component";

@NgModule({
    declarations: [
        MyCommissionPageComponent,
        ProvisionComponent,
        ProvisionAccountingComponent,
        ProvisionPendingMatterComponent,
        ProvisionTableComponent,
        ProvisionAccountingTableComponent,
        ProvisionPendingMatterTableComponent],
    imports: [
        CommonModule,
        CoreModule
    ],
    exports: [MyCommissionPageComponent]
})
export class CommissionModule {
}
