<div class="msg-mp-container {{cssClass}}">
    <div
        class="msg-mp-container-header msg-mp-container-flex {{ !isLightContainer ? 'msg-mp-container-headerbasic' : 'msg-mp-container-headerlight'}}">
        <div *ngIf="showHeadline" class="msg-helper-margin-left-10"> {{headlineText}} </div>
        <div class="action-buttons-container">
            <div class="msg-mp-container-edit pr-1" *ngIf="editMode && editSection !== 'CalendarAppointment'" (click)="cancelEditMode()">
                <p class="icon edit">Cancel</p>
            </div>
            <div class="msg-mp-container-edit pr-1" *ngIf="editMode && editSection === 'CalendarAppointment'" (click)="onNavigateToEdit()">
                <p class="icon edit">{{ 'PAGE.CONTAINER.CALENDARS.CONTAINER.TO-DETAILS' | translate }}</p>
            </div>    
            <div class="msg-mp-container-edit pr-1" *ngIf="editMode && editSection === 'CalendarAppointment'" (click)="cancelDialog()">
                <p class="icon edit">{{ 'DIALOG.BUTTON.CANCEL' | translate }}</p>
            </div>
            <div class="msg-mp-container-edit pr-1" *ngIf="showActionIcon && editSection !== 'CalendarAppointment'" (click)="toggleEditMode()" id="toggleEditMode">
                <p class="icon  {{editMode ? 'save' : 'edit'}}">{{editMode ? 'Save' : 'Edit'}}<img
                        src="./assets/icons/{{editMode ? 'done.svg' : 'edit.svg'}}"
                        alt="{{editMode ? 'Save' : 'Edit'}}"></p>
                <!-- <mat-icon class="icon edit" *ngIf="!editMode">edit_square</mat-icon> -->
                <!-- <p class="icon edit" *ngIf="!editMode">Edit <img src="./assets/icons/edit.svg" alt="edit"></p>  -->
            </div>

            <div class="msg-mp-container-edit pr-1" *ngIf="showActionIcon && editSection === 'CalendarAppointment'" (click)="toggleEditMode()" id="toggleEditMode">
                <p [disabled]="!formChanged" class="icon save" [ngClass]="{ 'disabled': !formChanged }">{{ 'DIALOG.BUTTON.SAVE' | translate }}<img
                        src="./assets/icons/{{formChanged ? 'done.svg' : 'done-disabled.svg'}}"
                        alt="Save"></p>
            </div>
        </div>
        <ng-content select="[header]"></ng-content>
    </div>
    <div class="msg-mp-container-content">
        <ng-content></ng-content>
    </div>
</div>