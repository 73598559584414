import {Moment} from "moment";

export class DatePickerValue {
    begin: Moment;
    end: Moment;

    constructor(begin?: Moment, end?: Moment) {
        this.begin = begin;
        this.end = end;
    }
}
