import {
  Component,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  EventEmitter,
} from "@angular/core";
import { LeadProvider } from "../../core/providers/lead.provider";
import { ActivatedRoute, Router } from "@angular/router";
import { RegisterProvider } from "../../core/providers/register.provider";
import { FilterTableComponent } from "../../core/components/tables/filter-table-component";
import { FormControl } from "@angular/forms";
import { Person } from "../../core/model/person";
import { CustomersCacheProvider } from "../../core/providers/customers-cache.provider";
import { Helper } from "app/modules/core/static/helper";
import { CustomersCache } from "app/modules/core/model/customers-cache";
import { KeycloakService } from "keycloak-angular";
import { Observable } from "rxjs";
import { BusinessTransactionHelper } from "app/modules/core/static/bt-helper";
import { LeadsService } from "app/modules/leads/services/leads.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import moment from "moment";
import { UserCommitState } from "app/modules/core/model/user-commit-state";
import { ActionDialogData } from "app/modules/core/components/action.dialog.component";
import { ActionDialogService } from "app/modules/core/providers/action-dialog.service";
import { AgenturType } from "app/modules/core/model/enum-agenturtype";
import { PostalAddress } from "app/modules/core/model/postal-address";
import { LeadLog } from "app/modules/core/model/LeadLog.enum";
import { SelectionModel } from "@angular/cdk/collections";
import { ActionData } from "app/modules/core/model/action-data";
import { Journal } from "app/modules/core/model/journal";
import * as uuid from "uuid";
import { MatDialog } from "@angular/material/dialog";
import { AdvisorDataService } from "../../core/services/advisor-data.service";
import { Title } from '@angular/platform-browser';
import { ConfigProvider } from 'app/modules/core/providers/config.provider';
import { Config } from "app/modules/core/model/config";
import { KeyValue } from "app/modules/core/model/key-value";
import { LEADS_CONFIGS } from "app/modules/leads/configs/lead-configs";
import { TranslateService } from "@ngx-translate/core";
import { LoadingService } from "app/modules/core/providers/loading.component";

@Component({
  selector: "neomp-leads-table",
  templateUrl: "./leads-table.component.html",
  styleUrls: ["./leads-table.component.scss"],
})
export class LeadsTableComponent
  extends FilterTableComponent<Person>
  implements OnInit, OnChanges {
  private readonly TAG = this.constructor.name;
  @Input()
  leads: Person[] = [];
  @Input()
  managementView: boolean = false;
  @Input() isRetention: boolean;
  agents: Person[] = [];
  isHighRole: boolean = true;
  isDialogOpen = false;
  agenturType: string = "";
  advisorId: string = "";
  agenturName: string = "";
  advisorName: string = "";
  selection = new SelectionModel<Person>(true, []);
  agenturId: string = "";
  unblockedAgents:any;
  blockedAgents:any;
  showSelect: boolean = false;
  statusesList: KeyValue[] = [];
  leadConfigs = LEADS_CONFIGS;
  @Output()
  selectedRows: EventEmitter<SelectionModel<Person>> = new EventEmitter<SelectionModel<Person>>();


  @Output()
  resetData: EventEmitter<string> = new EventEmitter();
  leadSelectFilter = new FormControl("");
  leadNameFilter = new FormControl("");
  leadAddressFilter = new FormControl("");
  leadArtFilter = new FormControl("");
  leadStatusFilter = new FormControl("");
  leadSubstatusFilter = new FormControl("");
  leadDateFilter = new FormControl("");
  leadCategoryFilter = new FormControl("");
  leadLanguageFilter = new FormControl("");
  leadAdvisorFilter = new FormControl("");
  leadAppStatus = new FormControl("");
  filterValues: Person = new Person();

  formControlFilters: FormControl[] = [
    this.leadNameFilter,
    this.leadAddressFilter,
    this.leadArtFilter,
    this.leadStatusFilter,
    this.leadSubstatusFilter,
    this.leadDateFilter,
    this.leadCategoryFilter,
    this.leadLanguageFilter,
    this.leadAdvisorFilter,
  ];
  displayedColumns = [
    "name",
    "address",
    "art",
    "statusLead",
    "substatusLead",
    "date",
    "category",
    "language",
    "advisor",
    'icon',
    "filter"
  ];
  displayedColumnFilters = [
    "nameFilter",
    "address2Filter",
    "artFilter",
    "statusFilter",
    "substatusFilter",
    "dateFilter",
    "categoryFilter",
    "languageFilter",
    "advisorFilter",
    'iconFilter',
    "filterRemove",
  ];
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    } else {
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
    }
  }

  constructor(
    public leadProvider: LeadProvider,
    public router: Router,
    public registerProvider: RegisterProvider,
    public activatedRoute: ActivatedRoute,
    public customersCacheProvider: CustomersCacheProvider,
    public snackBar: MatSnackBar,
    private keycloak: KeycloakService,
    private leadsService: LeadsService,
    private dialogService: ActionDialogService,
    private advisorDataService: AdvisorDataService,
    public titleService: Title,
    private configProvider: ConfigProvider,
    private translate: TranslateService,
    private loadService: LoadingService,
    public dialog: MatDialog
  ) {
    super();
  }


toggleSelect(value: boolean): void {
  this.showSelect = value;
}

  async ngOnInit(): Promise<void> {

    this.selection.changed.subscribe(
      () => this.selectedRows.emit(this.selection)
    );

    let statusesFrom = BusinessTransactionHelper.typeId.leadStatus.from;
    let statusesTo = BusinessTransactionHelper.typeId.leadEventStatus.to;
    let retentionStatusesFrom = BusinessTransactionHelper.typeId.retention.from;
    let retentionStatusesTo = BusinessTransactionHelper.typeId.retention.to;

    if(this.isRetention){
      this.getConfigs(retentionStatusesFrom, retentionStatusesTo)
    }
    else{
      this.getConfigs(statusesFrom, statusesTo);
    }
    
    await this.keycloak.loadUserProfile().then((res: any) => {
      this.advisorName = res.firstName;
      this.agenturType = res.attributes.agenturType[0];
      this.agenturName = res.attributes.agenturName[0];
      this.agenturId = res.attributes.agenturID[0];
      this.advisorId = res.attributes.advisorid[0];
      this.isHighRole = (this.agenturType === AgenturType.SalesManager || this.agenturType === AgenturType.GeneralAgent || this.agenturType === AgenturType.TeamLead)
      if (!this.isHighRole) {
        this.displayedColumnFilters = this.displayedColumnFilters.filter(x => x != "advisorFilter");
        this.displayedColumns = this.displayedColumns.filter(x => x != "advisor");
      }
    });
    this.agents = await this.getAgents();
    this.blockedAgents = this.agents.filter(agent => agent.blocked === true);
    this.unblockedAgents = this.agents.filter(agent => agent.blocked !== true);
    // this.agents = this.agents.filter(x => x.uuid != this.agenturId);
    if (this.agents.length > 0) {
      this.dataSource.data = this.leads.map(lead => this.assignAgentsToLeads(lead));
    }
    else {
      this.dataSource.data = this.leads;
    }

    this.showFilter = false;
    const customersCache: CustomersCache =
      this.customersCacheProvider.getCache();
    if (customersCache != null) {
      this.leadNameFilter.setValue(customersCache.name);
      this.leadAddressFilter.setValue(customersCache.address);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('managementView' in changes) {
      if (changes.managementView.currentValue) {
        this.displayedColumns.unshift('select');
        this.displayedColumnFilters.unshift('leadSelectFilter');
        this.formControlFilters.unshift(this.leadSelectFilter);

      }
    }
    if ("leads" in changes) {
      this.dataSource.data = this.leads;
      this.selection.clear();
    }

  }


  resetFilter() {
    this.filterValues = new Person();
    super.resetFilter();
  }

  createFilter(): (data: Person, filter: string) => boolean {
    return function (innerData, innerFilter): boolean {
      const searchTerms = JSON.parse(innerFilter);
      if (typeof (searchTerms.address) === 'string') {
        searchTerms.address = new PostalAddress({
          postalCode: searchTerms.address,
          city: searchTerms.address
        })
      }

      if (searchTerms.date == undefined) {
        searchTerms.date = "";
      } else {
        searchTerms.date = moment(new Date(searchTerms.date)).format('YYYY-MM-DD');
      }



      return (
        (searchTerms.name !== null
          ? Helper.checkStringContains(
            innerData.fullName,
            searchTerms.name)
          : true) &&
        ((searchTerms.address !== null
          ? Helper.checkStringContains(
            innerData.address.postalCode,
            searchTerms.address.postalCode
          )
          : true) ||
          (searchTerms.address !== null
            ? Helper.checkStringContains(
              innerData.address.city,
              searchTerms.address.city
            )
            : true)) &&
        (searchTerms.art !== null && innerData.lead !== null
          ? Helper.checkStringContains(
            innerData.lead.lead_typ,
            searchTerms.art
          )
          : true) &&
        (searchTerms.statusLead !== null && innerData.lead !== null
          ? Helper.checkStringContains(
            innerData.lead.lead_status,
            searchTerms.statusLead
          )
          : true) &&
          (searchTerms.substatusLead !== null && innerData.lead !== null
            ? Helper.checkStringContains(
              innerData.lead.lead_substatus,
              searchTerms.substatusLead
            )
            : true) &&
        // (searchTerms.date !== null && innerData.lead !== null
        //   ? Helper.checkStringContains(
        //     innerData.lead.termin_stattgefunden ? "Ja" : "Nein",
        //     searchTerms.completed
        //   )
        //   : true) &&
        (searchTerms.date !== null && innerData.lead !== null
          ? Helper.checkStringContains(
            innerData.lead.lead_creadate,
            searchTerms.date
          )
          : true) &&
        (searchTerms.category !== null && innerData.lead !== null
          ? Helper.checkStringContains(
            innerData.lead.lead_kategory,
            searchTerms.category
          )
          : true) &&
        (searchTerms.advisor !== null && innerData.lead !== null
          ? Helper.checkStringContains(
            innerData.sorKeys.agent ? innerData.sorKeys.agent.split("Neosana Sales AG")[1] : '',
            searchTerms.advisor
          )
          : true)
      );
    };
  }

  getClassFromStatus(leadUuid: string, servicecenter: boolean){
    if(servicecenter){
      return "servicecenter";
    }
    
    if (leadUuid.startsWith('myneolead-')){
      return "warning-status"
    }
  }
  
  assignAgentsToLeads(lead: Person) {
    const leadAgent = this.agents.find(x => x.uuid == lead.lead.termin_berater_shortcut);

    const agentName = leadAgent ? leadAgent.fullName : this.agenturName;
    if (agentName) {
      lead.sorKeys["agent"] = agentName;
      return lead;
    }
    return null;
  }

  getAgents(): Promise<Person[]> {
      return this.advisorDataService.getAgents(); 
  }

  agentsControlOnClick(event: Event) {
    event.stopPropagation();
  }

  assignAgentToLead(agent: Person, lead: Person) {
    const isAgenturSelected = agent.uuid === lead.lead.lead_agency;
    const sorKeys = {
      'advisorId': agent.uuid,
      'leadId': lead.uuid
    }

    lead.lead.termin_editdate = moment().format('YYYY-MM-DD');
    lead.lead.termin_berater_shortcut = agent.uuid;
    if(isAgenturSelected){
      lead.lead.leadpool = true;
    }

    const actionData = BusinessTransactionHelper.createLeadBTActionObject(
      LeadLog.TRANSFER,
      BusinessTransactionHelper.typeId.assignLeadToAgent.from,
      '',
      'Lead TRANSFER => ' + agent.fullName,
      '',
      agent.uuid,
      [],
      lead,
      moment().add(5, 'days').format('YYYY-MM-DD'),
      sorKeys,
      this.advisorName
    );

    this.leadsService.sendLeadAction(actionData)
      .subscribe(() => {
        this.snackBar.open("Lead updated successfully", "X", UserCommitState.configSuccess);
        this.resetData.emit('true');
      }, err => {
        this.snackBar.open("Something went wrong!", "X", UserCommitState.configError)
      })
  }


  openActionDialog(event: Event, element: Person) {
    this.isDialogOpen = true;
    event.stopPropagation();
    const actionData: ActionData = new ActionData();
    actionData.person = Person.fromJson(element);
    actionData.transaction = new Journal();

    actionData.transaction.inputChannel = "myneo"; 
    actionData.transaction.transactionId = uuid.v4();
    actionData.transaction.typeName = "Lead Task Negative";  
    actionData.transaction.ordererIdExternal = this.advisorId;
    actionData.transaction.transactionName = "Lead Task Negative";
    actionData.transaction.operationIdExternal = element.sorKeys["ProcessID"],  
    actionData.transaction.typeId = element.lead.lead_kategory.toLowerCase() === "retention" ? BusinessTransactionHelper.typeId.retention.from : BusinessTransactionHelper.typeId.leadNegative.from, // 4502
    actionData.transaction.creationDate = moment().format('YYYY-MM-DDTHH:mm:ss.SSSZZ');
    const sorKeys = {
      "ProcessID": actionData.transaction.transactionId
    }
    actionData.transaction.sorKeys = sorKeys;

    this.dialogService.openTaskDialog(actionData,
      {
        defaultTypeId: element.lead.lead_kategory.toLowerCase() === 'retention' ? BusinessTransactionHelper.typeId.retention.from : BusinessTransactionHelper.typeId.leadNegative.from
      });
      this.dialogService.isUpdated.subscribe(x=>{
        this.dataSource.data.splice(this.dataSource.data.indexOf(element), 1);
        this.dataSource.data = this.leads 
      })

      this.dialog.afterAllClosed.subscribe(() => {
        this.isDialogOpen = false;
    });
  }

  openEventDialog(event: Event, element: Person) {
    this.isDialogOpen = true;
    event.stopPropagation();
    this.isDialogOpen = true;
    const sorKeys = {
      'agentName': this.advisorName,
      'advisorId': this.advisorId,
      'leadId': element.uuid
    };
    element.lead.termin_editdate = moment().format('YYYY-MM-DD');

    const actionData = BusinessTransactionHelper.createLeadBTActionObject(
      LeadLog.LEAD_S_UPDATE,
      BusinessTransactionHelper.typeId.leadEventStatus.from,
      element.lead.lead_status,
      element.lead.lead_status,
      this.titleService.getTitle(),
      element.uuid,
      [],
      element,
      Date.now().toString(),
      sorKeys,
      this.advisorName
    );
    const dialogRef = this.dialogService.setStatusDialog(actionData, { keyValues: this.statusesList, typeId: "4501" });

    dialogRef.afterClosed()
    .subscribe(data => {
      const isTerminiert = data.transaction.typeId === "4113";
      this.isDialogOpen = false;

      if (!data) {
        return;
      }
      this.loadService.display(true);
  
      // Create termin only if typeId is "4113" (Terminiert)
      if (isTerminiert) {
        element.lead.termin_time = data.transaction.sorKeys.time;
        element.lead.termin_date = moment(data.transaction.sorKeys.date).format('YYYY-MM-DD');
      }

      this.leadsService.sendLeadAction(data)
      .subscribe(_value => {
        this.loadService.display(false);
        this.loadService.setDialogClosed(true);
        
        if (data.transaction.sorKeys.time && isTerminiert) {
          this.addTermine(element);
        }
      this.snackBar.open(this.translate.instant("COMMONS.USERCOMMIT.SUCCESS"), "X", UserCommitState.configSuccess);
      }, _error => {
        this.loadService.display(false);
        this.snackBar.open(this.translate.instant("COMMONS.USERCOMMIT.ERROR"), "X", UserCommitState.configError);
      });
      });

    this.dialog.afterAllClosed.subscribe(() => {
      this.isDialogOpen = false;
    });
  }

  addTermine(lead: Person): void {
    const sorKeys = {
      agentName: this.advisorName,
      category: this.leadConfigs.typeId.TERMIN.TERMIN,
      time: lead.lead.termin_time || ""
    };

    const actionData = BusinessTransactionHelper.createLeadBTActionObject(
      LeadLog.TERMIN,
      this.leadConfigs.typeId.TERMIN.TERMIN,
      '1. Termin',
      `${this.advisorName}, ${lead.lead.termin_date} ${lead.lead.termin_time}`,
      '',
      lead.uuid,
      [],
      lead,
      moment(lead.lead.termin_date).format('YYYY-MM-DD') || "",
      sorKeys,
      this.advisorName
    );

    this.subscriptions.push(
      this.leadsService.sendLeadAction(actionData)
        .subscribe(() => {
          this.snackBar.open("Termine created successfully", "X", UserCommitState.configSuccess);
        }, _err => {
          this.snackBar.open("Something went wrong!", "X", UserCommitState.configError)
        })
    );
  }
  
  getConfigs(from: string, to: string): void {
    this.configProvider.getConfig().subscribe((config: Config[]) => {
      if(from === to){
        this.statusesList = config.filter(x => x.key === from)
        .flatMap(y => {
          return y.values.map(v => ({
            id: y.key,
            key: v.key,
            value: v.value
          }));
        });
      }
      else {
        this.statusesList = config.filter(x =>
          Number(x.key) >= Number(from) &&
          Number(x.key) <= Number(to))
          .map(y => {
            return {
              id: y.key,
              key: y.values[0].key,
              value: y.values[0].value
            }
          });        
      }      
    })
  }

  isEventOrRetention(person: Person) {
    const category = person.lead.lead_kategory.toLowerCase();
    return category === "event" || category === "retention";
  }

  getSelectedAgent(uuid: string): Person {
  return this.agents.find(x => x.uuid === uuid);
  }

  redirectToEdit(uuid: string, termin: string, servicecenter: boolean): void {
    if (!["", "0", "-1"].includes(termin) && termin != this.agenturId && !uuid.startsWith('myneolead-') && !servicecenter) {
      this.router.navigate([`/lead/${uuid}/edit`]);
    }

    
 }
dateParse(date: string): Date | string {
    return date ?
      new Date(date) : '';
  }
  
splitFullName(fullName: string): string {
    const regex = /Neosana Sales AG|Neosana Services GMBH/i;
    const parts = fullName.split(regex);
    return parts.length > 1 ? parts[1].trim() : fullName;
}

}
