<div class="neo-content-wrapper">
    <div class="mdc-layout-grid msg-helper-padding-none">
        <div class="mdc-layout-grid__inner msg-helper-grid-gap-horizontal">
            <div class="mdc-layout-grid__cell
                        mdc-layout-grid__cell--span-12-desktop
                         mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
                <neomp-container cssClass="msg-mp-container-frameless"
                    headlineText="{{ 'PAGE.CONTAINER.MESSAGES.HEADLINE' | translate }}">
                    <table [dataSource]="dataSource" class="msg-helper-width-100" mat-table matSort
                        matSortActive="creationDate" matSortDirection="desc" multiTemplateDataRows>
                        <ng-container matColumnDef="icon">
                            <th *matHeaderCellDef mat-header-cell class="mat-header-cell mat-column-filter">
                                <div class="neomp-table-column-icon-element-header mdi mdi-email"></div>
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <div *ngIf="element.status?.completed"
                                    class="neomp-table-column-icon-element mdi mdi-email-open"></div>
                                <div *ngIf="!element.status?.completed"
                                    class="neomp-table-column-icon-element mdi mdi-email"></div>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="creationDate">
                            <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'PAGE.CONTAINER.MESSAGES.DATE' |
                                translate }}
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.creationDateOnly}}</td>
                        </ng-container>
                        <ng-container matColumnDef="typeName">
                            <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'PAGE.CONTAINER.MESSAGES.TYPE' |
                                translate }}</th>
                            <td mat-cell *matCellDef="let element">{{element.typeName}}</td>
                        </ng-container>
                        <ng-container matColumnDef="reasonForChange">
                            <th *matHeaderCellDef mat-header-cell mat-sort-header>{{
                                'PAGE.CONTAINER.MESSAGES.DESCRIPTION' | translate }}</th>
                            <td mat-cell *matCellDef="let element">{{element.reasonForChange}}</td>
                        </ng-container>
                        <ng-container matColumnDef="msgKind">
                            <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'PAGE.CONTAINER.MESSAGES.STATUS' |
                                translate }}</th>
                            <td mat-cell *matCellDef="let element">
                                <mat-icon *ngIf="element.msgKind === msgType.Sent">send</mat-icon>
                                <mat-icon *ngIf="element.msgKind === msgType.Received">call_received</mat-icon>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="document">
                            <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'PAGE.CONTAINER.MESSAGES.DOCUMENT'
                                | translate }}
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <span *ngFor="let resource of getDocuments(element)" (click)="$event.stopPropagation()">
                                    <button mat-icon-button (click)="!resource.fromMyNeo ? download(resource.id) : downloadFromMyNeo(resource.id)" [title]="resource.title">
                                        <div
                                            class="neomp-table-column-icon-element mdi {{ documentProvider.getIconByName(resource.title) }}">
                                        </div>
                                    </button>
                                </span>
                            </td>
                        </ng-container>
                        <!--<ng-container matColumnDef="descriptionField">
                                  <th *matHeaderCellDef mat-header-cell
                                      mat-sort-header>{{ 'PAGE.CONTAINER.MESSAGES.LONGDESCRIPTION' | translate }}</th>
                                  <td mat-cell *matCellDef="let element"> {{element.descriptionField}}</td>
                              </ng-container>-->
                        <ng-container matColumnDef="filter">
                            <th *matHeaderCellDef mat-header-cell (click)="changeFilterView()"
                                class="mat-header-cell mat-column-filter {{isFilterActive ? 'mat-column-filter-active':''}}">
                                <div
                                    class="neomp-table-column-filter-element  mdi {{isFilterActive ? 'mdi-filter':'mdi-filter-outline'}}">
                                </div>
                            </th>
                            <td mat-cell *matCellDef="let element" class="mat-column-filter-action">
                                <div class="neomp-table-column-filter-element mdi mdi-plus" [disabled]="isDialogOpen"
                                    (click)="isDialogOpen ? null : openActionDialog($event, element)"></div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="viewRelatedMsg">
                            <th *matHeaderCellDef mat-header-cell class="mat-header-cell mat-column-filter">
                                <div class="neomp-table-column-icon-element-header mdi mdi-email center"></div>
                            </th>
                            <td mat-cell *matCellDef="let element" class="mat-column-filter"
                                [ngClass]="element.relatedMessages.length > 0 ? 'has-messages' : '' "
                                (click)="$event.preventDefault(); $event.stopPropagation(); getRelatedMessages(element)">
                                <span class="material-icons eye-icon center">
                                    visibility
                                </span>
                                <span class="total-messages" *ngIf="element.unseenMessagesCount > 0"> {{
                                    element.unseenMessagesCount }}</span>
                            </td>
                        </ng-container>

                        <!-- Filter row definition -->
                        <ng-container matColumnDef="iconFilter">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-checkbox class="filter-checkbox" [formControl]="iconFilter"></mat-checkbox>
                            </th>
                        </ng-container>
                        <ng-container matColumnDef="creationDateFilter">
                            <th mat-header-cell *matHeaderCellDef>
                                <neomp-date-picker [setDefaultValues]="false" class="datePickerHide"
                                    (filterValue)="creationDateFilterChanged($event)"
                                    placeholder="{{ 'TABLES.FILTERPLACEHOLDER.DATE' | translate }}">
                                </neomp-date-picker>
                            </th>
                        </ng-container>
                        <ng-container matColumnDef="typeNameFilter">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-form-field class="filter" floatLabel="never">
                                    <input matInput [formControl]="typeNameFilter"
                                        placeholder="{{ 'TABLES.FILTERPLACEHOLDER.TYPE' | translate }}">
                                </mat-form-field>
                            </th>
                        </ng-container>
                        <ng-container matColumnDef="reasonForChangeFilter">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-form-field class="filter" floatLabel="never">
                                    <input matInput [formControl]="reasonForChangeFilter"
                                        placeholder="{{ 'TABLES.FILTERPLACEHOLDER.SUBJECT' | translate }}">
                                </mat-form-field>
                            </th>
                        </ng-container>
                        <!--<ng-container matColumnDef="descriptionFieldFilter">
                                  <th mat-header-cell *matHeaderCellDef>
                                      <mat-form-field class="filter" floatLabel="never">
                                          <input matInput [formControl]="descriptionFieldFilter">
                                      </mat-form-field>
                                  </th>
                              </ng-container>-->
                        <ng-container matColumnDef="documentFilter">
                            <th mat-header-cell *matHeaderCellDef>
                            </th>
                        </ng-container>
                        <ng-container matColumnDef="filterRemove">
                            <th mat-header-cell *matHeaderCellDef (click)="resetFilter()">
                                <div class="neomp-table-column-filter-element mdi mdi-close"></div>
                            </th>
                        </ng-container>
                        <!--end of  Filter row definition-->

                        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                        <ng-container matColumnDef="expandedDetail">
                            <td mat-cell *matCellDef="let journalRow" [attr.colspan]="displayedColumns.length"
                                class="{{journalRow === expandedElement ? 'openIssuesDetail-row-detail-content-expanded':''}}">
                                <div class="openIssuesDetail-row-expanded-element-with-flex"
                                    [@detailExpand]="journalRow === expandedElement ? 'expanded' : 'collapsed'">
                                    <div class="openIssuesDetail-row-expanded-element-with-flex-item msg-helper-margin-side-5"
                                        style="--flexSize: 5">
                                        <mat-form-field class="neomp-full-width">
                                            <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                                cdkAutosizeMinRows="1" cdkAutosizeMaxRows="75" readonly
                                                placeholder="{{'PAGE.CONTAINER.MESSAGES.LONGDESCRIPTION' | translate }}"
                                                value="{{journalRow.descriptionField}}"></textarea>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </td>

                        </ng-container>


                        <!--Row definitions -->
                        <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
                        <tr *matHeaderRowDef="displayedColumnFilters"
                            class="neomp-table-row-filter {{showFilter? '': 'neomp-table-row-filter-hide'}}"
                            mat-header-row></tr>
                        <tr (click)="journalRowClick(journalRow); journalRow.relatedMessages.length > 0; getRelatedMessages(journalRow)"
                            *matRowDef="let journalRow; columns: displayedColumns" class="neomp-table-row-selected container-table-row {{journalRow.uuid === selectedJournalRowIndex ? 'container-table-row-selected':''}}                
                                  {{ !journalRow.status.completed ? 'bold' : '' }}" mat-row></tr>
                        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']"
                            class="openIssuesDetail-row-detail-content"></tr>
                    </table>


                </neomp-container>
            </div>


        </div>
    </div>
</div>