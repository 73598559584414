<div class="mdc-layout-grid msg-helper-padding-none msg-helper-float padding-top-50-small-size">
  <div class="mdc-layout-grid__inner">
    <div
      class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
      <mat-slide-toggle class="msg-helper-float-right" [(ngModel)]="mandatesOnly" (change)="householdsToggle()">{{
        "PAGE.CUSTOMER.SWITCHABLE.MANDATES" | translate }}</mat-slide-toggle>
    </div>
    <div
      class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
      <mat-tab-group mat-align-tabs="start">
        <mat-tab label="{{ isClient ?
            ('PAGE.CONTAINER.CUSTOMERS.CONTAINER.CLIENTHEADLINE' | translate)
            :
            ('PAGE.CONTAINER.CUSTOMERS.CONTAINER.HEADLINE' | translate)
          }}">
          <neomp-container cssClass="msg-mp-container-frameless" headlineText="{{ isClient ?
            ('PAGE.CONTAINER.CUSTOMERS.CONTAINER.CLIENTHEADLINE' | translate)
            :
            ('PAGE.CONTAINER.CUSTOMERS.CONTAINER.HEADLINE' | translate)
          }}">
            <neomp-family-table [tableEntries]="households" [households]="households"
              [mandat]="mandatesOnly"></neomp-family-table>
            <neomp-loading-data #loadingFamilyTable></neomp-loading-data>
            <neomp-paginator [length]="totalCount" [pageSize]="pageSize" [pageIndex]="pageIndex"
              [pageSizeOptions]="pageSizeOptions" (paginatorChange)="paginatorValueChanged($event)">
            </neomp-paginator>
          </neomp-container>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>