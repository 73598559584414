import {addDays, areRangesOverlapping, differenceInDays, format} from "date-fns";
import {DatePickerValue} from "../model/datepicker";
import * as deLocale from "date-fns/locale/de/index.js";
import moment, {isMoment} from "moment";


export class Helper {
    public static formatDatepicker(date: any): string {
        const temp = format(date, "YYYY" +
            " dddd, MMM DD", {locale: deLocale});
        if (temp === "Invalid Date") {
            return "";
        }
        return temp;
    }

    public static formatDate(date: Date): string {
        const temp = format(date, "DD.MM.YYYY");
        if (temp === "Invalid Date") {
            return "";
        }
        return temp;
    }

    public static stringToDatePickerFormat(date: any): DatePickerValue {
        if (date === null || date === "") {
            return new DatePickerValue();
        }
        return date as DatePickerValue;
    }

    public static getdifferenceInDaysfromToday(effectivDate: Date) {
        const today = Date.now();
        return differenceInDays(effectivDate, today);
    }

    public static checkDateRange(contentDate: Date, dateRange: DatePickerValue): boolean {
        const start = isMoment(dateRange.begin) ? dateRange.begin.toDate() : dateRange.begin;
        const end = isMoment(dateRange.end) ? dateRange.end.toDate() : dateRange.end;
        if (dateRange != null && dateRange.begin != null) {
            return areRangesOverlapping(start, addDays(end, 1), contentDate, contentDate);
        }
        return true;
    }

    // TODO: to be checked with real data!
    public static checkStringInjection(checkString): boolean {
        const regEx = RegExp("^[a-zA-Z0-9_-]*$");
        return regEx.test(checkString);
    }

    public static checkStringContains(contentString, searchString): boolean {
        if (searchString === null || searchString === undefined) {
            searchString = "";
        }
        if (contentString === null || contentString === undefined) {
            contentString = "";
        }
        if (!String.prototype.includes) {
            return contentString.toLowerCase().indexOf(searchString.toLowerCase()) !== -1;
        } else {
            return contentString.toLowerCase().includes(searchString.toLowerCase());
        }
    }

    public static isDateValid(date: string): boolean {
        return moment(new Date(date)).isValid();
      }
}
