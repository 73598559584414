import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { HouseholdProvider } from "../../core/providers/household.provider";
import { SelectedPersonEmitter } from "../../core/model/selectedPersonEmitter";
import { ActivatedRoute, Router } from "@angular/router";
import { RegisterProvider } from "../../core/providers/register.provider";
import { LoadingDataComponent } from "../../core/components/loading-data.component";
import { FilterTableComponent } from "../../core/components/tables/filter-table-component";
import { FormControl } from "@angular/forms";
import { Helper } from "../../core/static/helper";
import { Household } from "../../core/model/household";
import { HouseholdJson } from "../../core/json/household.json";
import { Person } from "../../core/model/person";
import { CustomersCache } from "../../core/model/customers-cache";
import { CustomersCacheProvider } from "../../core/providers/customers-cache.provider";
import { RestProviderActionsDummy } from "app/modules/core/providers/rest.provider";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "environments/environment";
import { KeycloakService } from "keycloak-angular";
import { HttpParams } from "@angular/common/http";
import { compressToEncodedURIComponent, decompressFromEncodedURIComponent } from "lz-string";


@Component({
    selector: "neomp-family-table",
    templateUrl: "./family-table.component.html",
    styleUrls: ["./family-table.component.scss"],
    animations: [
        trigger("detailExpand", [
            state("collapsed, void", style({ height: "0px", minHeight: "0", display: "none" })),
            state("expanded", style({ height: "*" })),
            transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")),
            transition("expanded <=> void", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)"))
        ]),
    ],
})
export class FamilyTableComponent extends FilterTableComponent<Household> implements OnInit, OnDestroy {
    @Input()
    households: Household[] = [];
    expandedElement: Household | null;
    houseHoldeCompletlyLoaded = false;
    @Input()
    mandat: boolean;

    householdNameFilter = new FormControl("");
    householdAddressFilter = new FormControl("");
    householdAppStatusFilter = new FormControl("");
    filterValues: Household = new Household();
    copyText: string;
    referralUrl: string;
    matTooltipHideDelay = 0;
    advisorId: string;
    queryParams: any;
    compressedData: string;

    @ViewChild("loadingExpandedFamilyTable") loadingExpandedFamilyTable: LoadingDataComponent;

    formControlFilters: FormControl[] = [null, this.householdNameFilter, this.householdAddressFilter, this.householdAppStatusFilter, null];
    displayedColumns = ["", "householdName", "householdAddress", "householdAppStatus", "icon"];
    displayedColumnFilters = ["", "householdNameFilter", "householdAddressFilter", "householdAppStatusFilter", "filterRemove"];


    constructor(public householdProvider: HouseholdProvider,
        public router: Router,
        public translate: TranslateService,
        public registerProvider: RegisterProvider,
        public activatedRoute: ActivatedRoute,
        public keycloak: KeycloakService,
        public customersCacheProvider: CustomersCacheProvider) {
        super();
    }

    static checkFamilyMembers(persons: Person[], searchString: string): boolean {
        let person = null;
        person = persons.find(pers =>
            pers.name.toLowerCase().indexOf(searchString.toLowerCase()) !== -1 ||
            pers.firstName.toLowerCase().indexOf(searchString.toLowerCase()) !== -1)
        return person != null
    }

    ngOnInit() {
        super.ngOnInit();
        this.keycloak.loadUserProfile().then((res: any) =>
            this.advisorId = res.attributes.advisorid[0]
        )
        this.translate.onLangChange.subscribe(() => {
            this.copyText = this.translate.instant('HEADER.COPY');
        });
        if (this.householdProvider.getHouseholdExpanded()) {
            const expandedHouseholdIndex = this.households.findIndex(
                (household) => household.representant.uuid === this.householdProvider.getHouseholdExpanded());
            if (expandedHouseholdIndex >= 0) {
                this.loadPersons(this.households[expandedHouseholdIndex], true)
            }
        }
        this.showFilter = true;
        const customersCache: CustomersCache = this.customersCacheProvider.getCache();
        if (customersCache != null) {
            this.householdNameFilter.setValue(customersCache.name);
            this.householdAddressFilter.setValue(customersCache.address);
            this.householdAppStatusFilter.setValue(customersCache.appStatus);
        }
    }

    expandRow(element, dataIndex) {
        this.expandedElement = this.expandedElement === element ? null : element;
        if (this.expandedElement) {
            this.loadPersons(this.expandedElement);
        }
    }

    compressData(data: any): string {
        return compressToEncodedURIComponent(JSON.stringify(data));
    }


    copyMessage(val: string) {
        this.queryParams = {
            advId: this.advisorId,
            uuid: val,
        }

        this.compressedData = this.compressData(this.queryParams);
        this.referralUrl = environment.settings.REGISTER_MYNEO + "?query=" + this.compressedData;
        this.matTooltipHideDelay = 500;
        this.copyText = this.translate.instant('HEADER.COPIED');
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = this.referralUrl;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        setTimeout(() => {
            this.copyText = this.translate.instant('HEADER.COPY');
        }, 1000);
        this.matTooltipHideDelay = 0;
    }

    loadPersons(household: Household, duringStart = false) {
        this.houseHoldeCompletlyLoaded = false;
        const householdIndex = this.households.findIndex(h => h.representant.uuid === household.representant.uuid);
        if (this.households[householdIndex]) {
            this.householdProvider.getCompleteHouseholdByUuid(
                this.households[householdIndex].representant.uuid,
                duringStart ? new RestProviderActionsDummy() : this.loadingExpandedFamilyTable)
                .subscribe(household => {
                    this.expandedElement = household;
                    this.households[householdIndex] = household;
                    this.dataSource.data = this.households;
                    this.houseHoldeCompletlyLoaded = true;
                });
        }
    }

    selectedPersonClick(value: SelectedPersonEmitter) {
        //save date in cache
        const customersCache: CustomersCache = new CustomersCache(this.mandat, this.householdNameFilter.value, this.householdAddressFilter.value, this.householdAppStatusFilter.value);
        this.customersCacheProvider.setCache(customersCache);
        this.householdProvider.sendExpandedHousehold(this.expandedElement.representant.uuid);

        const url = `${this.registerProvider.getPath("MyCustomersDetail")
            }/${value.representativeUuid
            }/${(value.representativeUuid === value.selectedPersonUuid ? "" : (value.selectedPersonUuid))
            }`;
        this.router.navigateByUrl(url);
    }

    resetFilter() {
        this.filterValues = new Household();
        super.resetFilter();
    }

    createFilter(): (data: Household, filter: string) => boolean {
        return function (innerData, innerFilter): boolean {
            const searchTerms: Household = Household.fromJson(JSON.parse(innerFilter) as HouseholdJson);
            return (
                (searchTerms.householdName !== null ?
                    Helper.checkStringContains(innerData.householdName, searchTerms.householdName) : true)
                ||
                (searchTerms.householdName !== null ?
                    FamilyTableComponent.checkFamilyMembers(innerData.persons, searchTerms.householdName) : true)
            ) &&
                (searchTerms.householdAddress !== null ?
                    Helper.checkStringContains(innerData.householdAddress, searchTerms.householdAddress) : true)
                &&
                (searchTerms.householdAppStatus !== false ?
                    Helper.checkStringContains(innerData.representant.neoAppInfo.active.toString(), searchTerms.householdAppStatus) : true)

        }
    }

    ngOnDestroy() {
        //save date in cache
        const customersCache: CustomersCache = new CustomersCache(this.mandat, this.householdNameFilter.value, this.householdAddressFilter.value, this.householdAppStatusFilter.value);
        this.customersCacheProvider.setCache(customersCache);
        if (this.expandedElement != null) {
            this.householdProvider.sendExpandedHousehold(this.expandedElement.representant.uuid);
        }
    }
}
