import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReferralsService {

constructor(private http : HttpClient) { }

  getReferals(
    page: number,
    size: number,) : Observable<any>{
      let queryParams = `?page=${page}&size=${size}`;
    return this.http.get<any>(`${environment.settings.BASE_URL}/api/referals${queryParams}`)
  }

}
