import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { PaginationConfigs } from "app/modules/constants/PaginationConstants";
import { LoadingDataComponent } from "app/modules/core/components/loading-data.component";
import { AgenturType } from 'app/modules/core/model/enum-agenturtype';
import { LeadLog } from 'app/modules/core/model/LeadLog.enum';
import { Person } from "app/modules/core/model/person";
import { SlideRangeFilterValue } from "app/modules/core/model/slide-range-filter-value";
import { HouseholdProvider } from "app/modules/core/providers/household.provider";
import { LeadProvider } from "app/modules/core/providers/lead.provider";
import { LoadingService } from 'app/modules/core/providers/loading.component';
import { RestProviderActions } from "app/modules/core/providers/rest.provider";
import { BusinessTransactionHelper } from 'app/modules/core/static/bt-helper';
import { LeadsService } from 'app/modules/leads/services/leads.service';
import { format } from "date-fns";
import { KeycloakService } from "keycloak-angular";
import { forkJoin, Observable, of } from "rxjs";
import { catchError, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import moment from 'moment';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfigProvider } from 'app/modules/core/providers/config.provider';
import { Config } from 'app/modules/core/model/config';
import { PageEvent } from '@angular/material/paginator';
import { LeadQueryParams } from 'app/modules/core/json/leadQueryParams.json';
import { KeyValue } from 'app/modules/core/model/key-value';
import { AdvisorDataService } from "app/modules/core/services/advisor-data.service";

@Component({
  selector: 'neomp-leads-management',
  templateUrl: './leads-management.component.html',
  styleUrls: ['./leads-management.component.scss']
})
export class LeadsManagementComponent implements OnInit {

  filterValue: SlideRangeFilterValue = new SlideRangeFilterValue();
  pageSize = PaginationConfigs.pageSize;
  pageSizeOptions = PaginationConfigs.pageSizeOptions;
  pageIndex = PaginationConfigs.pageIndex;
  totalCount = 0;
  totalReturnedLeads = 0;
  mandatesOnly = false;
  advisorName: string = "";
  leads: Person[] = [];
  returnedLeads: Person[] = []
  totalCountLeads = 0;
  allStatuses: KeyValue[] = [];
  statusesList: string[] = [];
  substatusesList: any[] = [];

  agenturId: string = "";
  unassignedLeads: Person[] = [];
  totalCountUnassignedLeads = 0;

  agents: Person[] = [];
  agentSelectedControl = new FormControl(
    ""
  );
  statusSelected = new FormControl(
    ""
  );
  substatusSelected = new FormControl("");
  isLoading = false;
  agentAssignSelectedControl = new FormControl({ value: '', disabled: true }, Validators.required);
  agenturType: string = "";
  agenturName: string = "";
  isHighRole: boolean = true;
  dateFrom: any;
  dateTo: any;
  searchText: string | null = null;

  leadsTabGroupCurrentIndex: number = 0;
  selectedLeads: Person[] = [];

  unassignedLeadsCategories: string[] = [];
  unassignedLeadsTypes: string[] = [];
  lead_leadCategory = [];
  lead_leadType = [];
  
  @ViewChild("loadingFamilyTable") loadingFamilyTable: LoadingDataComponent;

  constructor(
    public loadService: LoadingService,
    public householdProvider: HouseholdProvider,
    public leadProvider: LeadProvider,
    private configProvider: ConfigProvider,
    public translateService: TranslateService,
    private keycloak: KeycloakService,
    private leadsService: LeadsService,
    private advisorDataService: AdvisorDataService,
    private router: Router,
    private route: ActivatedRoute,
    private leadService: LeadsService
  ) { }

  async ngOnInit(): Promise<void> {
    this.fillSearchInputsByQueryParams(this.route.snapshot.queryParams as LeadQueryParams);
    this.search();
    this.keycloak.loadUserProfile().then((res: any) => {
      this.advisorName = res.firstName;
      this.agenturType = res.attributes.agenturType[0];
      this.agenturName = res.attributes.agenturName[0];
      this.agenturId = res.attributes.agenturID[0];
      this.isHighRole = (this.agenturType === AgenturType.SalesManager || this.agenturType === AgenturType.GeneralAgent || this.agenturType === AgenturType.TeamLead);
    });
    this.leadProvider.getAttributeValues().subscribe(attributeValues => {
      this.unassignedLeadsCategories= attributeValues?.leadCategories || [];
      this.unassignedLeadsTypes= attributeValues?.leadTypes || [];
    });
    this.agents = await this.getAgents();
    //We commented the code below in order to make the agentur visible
    // this.agents = this.agents.filter(x => x.uuid != this.agenturId);
    this.route.queryParams
      .pipe(tap((x: LeadQueryParams) => {
        if (!x.st) {
          const queryParamObj = {
            dateFrom: this.dateFrom || "",
            dateTo: this.dateTo || "",
            advisor: this.agentSelectedControl.value,
            status: this.statusSelected.value,
            lead_substatusList: this.substatusSelected.value,
            text: this.searchText,
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            lead_leadType: this.lead_leadType?.join(",") || "",
            lead_leadCategory: this.lead_leadCategory?.join(",") || ""
          };
          this.updateQueryParams('/lead-management', 'unassigned-leads', queryParamObj)
        }
      }),
      filter((x: LeadQueryParams) => x.st === 'unassigned-leads' || x.st === 'assigned-leads' || x.st === 'returned-leads' ),
      distinctUntilChanged((prev: any, curr: LeadQueryParams) => JSON.stringify(prev) === JSON.stringify(curr))
      ).subscribe((params: LeadQueryParams) => {
          this.fillSearchInputsByQueryParams(params);
          this.search(true);
      })

    this.keycloak.loadUserProfile().then((res: any) => {
      this.advisorName = res.firstName;
      this.agenturType = res.attributes.agenturType[0];
      this.agenturName = res.attributes.agenturName[0];
      this.isHighRole = (this.agenturType === AgenturType.GeneralAgent || this.agenturType === AgenturType.TeamLead);
    });
    this.getConfigs();
  }

  getConfigs() {
    this.configProvider.getConfig().subscribe((config: Config[]) => {
      this.allStatuses = config.filter(x =>
        Number(x.key) >= Number("4110") &&
        Number(x.key) <= Number("4116"))
        .map(y => {
          return {
            id: y.key,
            key: y.values[0].key,
            value: y.values[0].value
          };
        });  
      this.allStatuses.sort((a,b)=> {
        return Number(a.id) - Number(b.id)
      })
      this.statusesList = this.allStatuses.map(x => x.key);
      
      // Get substatus list for selected status
      this.substatusesList = this.getSubstatuses(this.statusSelected.value);
    })
  }

  statusesSelected(event) {
    this.substatusesList = [];

    event.value.forEach(status => {
      this.substatusesList.push(...this.getSubstatuses(status));
    });
    this.substatusSelected.reset();
  }

  getSubstatuses(status) {
    let substatuses = [];
    let statuses = Array.isArray(status) ? 
      this.allStatuses.filter(x => status.includes(x.key)) :
      this.allStatuses.filter(x => x.key === status);
      
    statuses.forEach(y => {
      var obj = {};
      obj['title'] = status;
      obj['value'] = y.value.split(',');
      substatuses.push(obj);
    });

    return substatuses;
  }
  //Deprecated
  // assignAgentsToLeads(lead: Person) {
  //   const leadAgent = this.agents.find(x => x.uuid == lead.lead.termin_berater_shortcut);

  //   const agentName = leadAgent ? leadAgent.fullName : this.agenturName;
  //   if (agentName) {
  //     lead.sorKeys["agent"] = agentName;
  //     return lead;
  //   }
  //   return null;
  // }

  getAgents(): Promise<Person[]> {
    return this.advisorDataService.getUnblockedAgents();
  }

  loadLeads(restProviderActions: RestProviderActions, page: number, size: number, filter?: string) {
    this.loadService.display(true);
    this.leads = [];

    this.leadProvider
      .getLazyLeads(restProviderActions, page, size, filter)
      .subscribe((leads: { data: Person[]; totalCount: number }) => {
        this.totalCountLeads = leads.totalCount;
        this.leads = leads.data;
        if (this.agents.length > 0) {
          this.leads = leads.data.map(lead => this.assignAgentsToLeads(lead));
        }
        else {
          this.leads = leads.data;
        }
        this.loadService.display(false);
      });
  }
  
   assignAgentsToLeads(lead: Person) {
    const leadAgent = this.agents.find(x => x.uuid == lead.lead.termin_berater_shortcut);
    const agentName = leadAgent ? leadAgent.fullName : this.agenturName;
    if (agentName) {
      lead.sorKeys["agent"] = agentName;
      return lead;
    }
    return null;
  }

  returnedLead(restProviderActions: RestProviderActions, page: number, size: number, filter?: string) {
    this.loadService.display(true);
    this.leads = [];
    this.returnedLeads = [];
    this.leadProvider
      .getReturnedLeads(restProviderActions, page, size, filter)
      .subscribe((leads: { data: Person[]; totalCount: number }) => {
        this.totalReturnedLeads = leads.totalCount;
        this.returnedLeads = leads.data
        this.loadService.display(false);
      });
  }

  loadUnassignedLeads(restProviderActions: RestProviderActions, page: number, size: number, filter?: string): void {
    this.loadService.display(true);
    this.unassignedLeads = [];

    this.leadProvider.getUnassignedLeads(restProviderActions, page, size, filter)
      .subscribe((leads: { data: Person[]; totalCount: number }) => {
        this.totalCountUnassignedLeads = leads.totalCount;
        this.unassignedLeads = leads.data;
        if (this.searchText == "" || this.searchText == null) {
          this.leadsService.setUnassignedLeadsCount(this.totalCountUnassignedLeads);
          this.leadsService.setUnassignedLeadAndAppointmentsCount(this.totalCountUnassignedLeads + Number(this.leadService.unassignedAppointmentsCount.value));
        }

        this.loadService.display(false);
      });
  }

  paginatorReturnedLeads(event: PageEvent) {
    if (event.pageIndex != this.pageIndex || event.pageSize != this.pageSize) {
      this.pageIndex = event.pageIndex;
      this.pageSize = event.pageSize;
      const queryParamObj = {
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        status: this.statusSelected.value,
        lead_substatusList: this.substatusSelected.value,
        text: this.searchText,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        lead_leadType: this.lead_leadType?.join(",") || "",
        lead_leadCategory: this.lead_leadCategory?.join(",") || ""
      };
      this.updateQueryParams('/lead-management', this.route.snapshot.queryParams.st, queryParamObj)
    }
  }

  paginatorUnassignedLeadsValueChanged(event: PageEvent) {
    if (event.pageIndex != this.pageIndex || event.pageSize != this.pageSize) {
      this.pageIndex = event.pageIndex;
      this.pageSize = event.pageSize;
      const queryParamObj = {
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        text: this.searchText,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        lead_leadType: this.lead_leadType?.join(",") || "",
        lead_leadCategory: this.lead_leadCategory?.join(",") || ""
      };
      this.updateQueryParams('/lead-management', 'unassigned-leads', queryParamObj)
    }
  }

  paginatorAssignedLeadsValueChanged(event: PageEvent) {
    if (event.pageIndex != this.pageIndex || event.pageSize != this.pageSize) {
      this.pageIndex = event.pageIndex;
      this.pageSize = event.pageSize;
      const queryParamObj = {
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        advisor: this.agentSelectedControl.value,
        status: this.statusSelected.value,
        lead_substatusList: this.substatusSelected.value,
        text: this.searchText,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        lead_leadType: this.lead_leadType?.join(",") || "",
        lead_leadCategory: this.lead_leadCategory?.join(",") || ""
      };
      this.updateQueryParams('/lead-management', this.route.snapshot.queryParams.st, queryParamObj)
    }
  }

  fillSearchInputsByQueryParams(params: LeadQueryParams): void {
    this.pageIndex = params.pageIndex || PaginationConfigs.pageIndex;
    this.pageSize = Number(params.pageSize) > 0 ? Number(params.pageSize) : PaginationConfigs.pageSize;
    this.lead_leadType = params.lead_leadType?.split(",") || [];
    this.lead_leadCategory = params.lead_leadCategory?.split(",") || [];

    if (params.dateFrom) {
      this.dateFrom = params.dateFrom;
    }

    if (params.dateTo) {
      this.dateTo = params.dateTo;
    }

    if (params.text) {
      this.searchText = params.text;
    }

    if (params.status) {
      let statusesList = params.status as any;
      if (typeof params.status === 'string') {
        statusesList = params.status.split(",");
      }
      this.statusSelected.setValue(statusesList);
    }
    if (params.lead_substatusList) {
      let substatusesList = params.lead_substatusList as any;
      if (typeof params.lead_substatusList === 'string') {
        substatusesList = params.lead_substatusList.split(",");
      }
      this.substatusSelected.setValue(substatusesList);
    }
    if (params.advisor) {
      this.agentSelectedControl.setValue(params.advisor);
    }

    if (params.st == 'unassigned-leads') {
      this.leadsTabGroupCurrentIndex = 0;
    }

    if (params.st == 'assigned-leads') {
      this.leadsTabGroupCurrentIndex = 1;
    }
    
    if (params.st == 'returned-leads') {
      this.leadsTabGroupCurrentIndex = 2;
    }
  }

  search(loadLeads?: boolean): void {
    var statusSelected = Array.isArray(this.statusSelected.value) ? this.statusSelected.value.join(',') : "";
    var substatusSelected = Array.isArray(this.substatusSelected.value) ? this.substatusSelected.value.join(',') : "";
    const dateFrom = this.dateFrom == undefined ? "" : format(new Date(this.dateFrom.toString()), "YYYY-MM-DDT00:00:00.000");
    const dateTo = this.dateTo == undefined ? "" : format(new Date(this.dateTo.toString()), "YYYY-MM-DDT23:59:00.000");
    let filter = '';

    const queryParamObj = {
      dateFrom,
      dateTo,
      advisor: this.agentSelectedControl.value,
      status: this.statusSelected.value,
      lead_substatusList: this.substatusSelected.value,
      text: this.searchText,
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      lead_leadType: this.lead_leadType?.join(",") || "",
      lead_leadCategory: this.lead_leadCategory?.join(",") || ""
    }

    this.updateQueryParams('/lead-management', this.route.snapshot.queryParams.st, queryParamObj);

    if (loadLeads) {
    // search is handled only for leads, appointments search is handled in child component `neomp-termin`
      switch (this.leadsTabGroupCurrentIndex) {
        case 0:
          filter = `dateFrom=${dateFrom}&dateTo=${dateTo}&status=${statusSelected}&lead_substatusList=${substatusSelected}`;
          if (![null, undefined, ""].includes(this.searchText)) {
            filter += `&text=${this.searchText}`
          }

          this.loadUnassignedLeads(
            this.loadingFamilyTable,
            this.pageIndex,
            this.pageSize,
            filter + `&lead_leadType=${this.lead_leadType}&lead_leadCategory=${this.lead_leadCategory}`
          );

          break;

        case 1:

          filter = this.agentSelectedControl.value != "" && this.agentSelectedControl.value != undefined ?
            `dateFrom=${dateFrom}&dateTo=${dateTo}&advisor=${this.agentSelectedControl.value}&status=${statusSelected}&lead_substatusList=${substatusSelected}` :
            `dateFrom=${dateFrom}&dateTo=${dateTo}&status=${statusSelected}&lead_substatusList=${substatusSelected}`
          if (![null, undefined, ""].includes(this.searchText)) {
            filter += `&text=${this.searchText}`
          }

          this.loadLeads(
            this.loadingFamilyTable,
            this.pageIndex,
            this.pageSize,
            filter + `&lead_leadType=${this.lead_leadType}&lead_leadCategory=${this.lead_leadCategory}&isManagement=true`
          );
          
          break;
        case 2:
          filter = `dateFrom=${dateFrom || ''}&dateTo=${dateTo || ''}&status=${statusSelected}&lead_substatusList=${substatusSelected}`

          if (![null, undefined, ""].includes(this.searchText)) {
            filter += `&text=${this.searchText}`
          }

          this.returnedLead(
            this.loadingFamilyTable,
            this.pageIndex,
            this.pageSize,
            filter + `&lead_leadType=${this.lead_leadType}&lead_leadCategory=${this.lead_leadCategory}`
          );
          
          break;
        default:
          break;
      
      }
    }
  }
  clearSearch() {
    this.searchText = '';
    this.dateFrom = null;
    this.dateTo = null;
    this.agentSelectedControl.reset();
    this.statusSelected.reset();
    this.substatusSelected.reset();
    this.substatusesList = [];
    this.lead_leadCategory = [];
    this.lead_leadType = [];
    this.search();
  }
  //recall this.search() from leads-table component
  resetFunction(event) {
    if (event == 'true') {
      this.search();
    }
  }

  selectedRows(selectionModel: SelectionModel<Person>): void {
    this.selectedLeads = selectionModel.selected;
    this.selectedLeads.length === 0 ?
      this.agentAssignSelectedControl.disable() : this.agentAssignSelectedControl.enable();
  }

  assignLeadsToAgent(): void {
    let requests: Observable<any>[] = [];
    const agent = this.agentAssignSelectedControl.value;
    if (this.selectedLeads.length > 0) {
      this.isLoading = true;
      this.loadService.display(true);
      for (let lead of this.selectedLeads) {

        const sorKeys = {
          'advisorId': agent.uuid,
          'leadId': lead.uuid
        }

        lead.lead.termin_editdate = moment().format('YYYY-MM-DD');
        lead.lead.termin_berater_shortcut = agent.uuid;
        if(lead.lead.lead_agency === lead.lead.termin_berater_shortcut){
          lead.lead.leadpool = true;
        }
        const actionData = BusinessTransactionHelper.createLeadBTActionObject(
          LeadLog.TRANSFER,
          BusinessTransactionHelper.typeId.assignLeadToAgent.from,
          '',
          'Lead TRANSFER => ' + agent.fullName,
          '',
          agent.uuid,
          [],
          lead,
          moment().add(5, 'days').format('YYYY-MM-DD'),
          sorKeys,
          this.advisorName
        );

        requests.push(this.leadsService.sendLeadAction(actionData)
          .pipe(
            // do not let your observables die; forkJoin will the not be triggered then.
            catchError(err => of(err))
          ));
      }

      forkJoin(requests).subscribe(results => {
        this.loadService.display(false);
        this.isLoading = false;
        this.agentAssignSelectedControl.reset();
        this.selectedLeads = [];
        this.searchText = null;
        this.search();
      })
    }
  }

  leadsTabChanged = (index: number): void => {
    let st;
    if (index == 0) {
      st = 'unassigned-leads'
    }
    if (index == 1) {
      st = 'assigned-leads'
    }
    if (index == 2) {
      st = 'returned-leads'
    }
    const queryParamObj = {
      dateFrom: this.dateFrom,
      dateTo: this.dateTo,
      advisor: this.agentSelectedControl.value,
      status: this.statusSelected.value,
      lead_substatusList: this.substatusSelected.value,
      text: this.searchText,
      pageIndex: 0,
      pageSize: this.pageSize,
      lead_leadType: "",
      lead_leadCategory: ""
    };
    this.updateQueryParams('/lead-management', st, queryParamObj)
  }

  updateQueryParams(path: string, st: string, queryParams: LeadQueryParams): void {
    this.router.navigate([path], {
      queryParams: {
        st,
        dateFrom: queryParams?.dateFrom || "",
        dateTo: queryParams?.dateTo || "",
        advisor: queryParams?.advisor,
        text: queryParams?.text,
        status: queryParams?.status,
        lead_substatusList: queryParams?.lead_substatusList,
        pageIndex: queryParams?.pageIndex,
        pageSize: queryParams?.pageSize,
        lead_leadType: queryParams?.lead_leadType,
        lead_leadCategory: queryParams?.lead_leadCategory
      }
    });
  }

}