import {Injectable} from "@angular/core";
import {RestProvider, RestProviderActions} from "./rest.provider";
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment";
import {DocumentResourceJson} from "../json/document.resource.json";
import {DocumentResourceResult} from "../model/document-resource-result";
import {DocumentResourceResultJson} from "../json/document.resource.result.json";
import {DocumentPayload} from "../model/document-payload";
import {log} from "../../core/providers/logger.provider";
import { saveAs } from 'file-saver';
import { map } from "rxjs/operators";

@Injectable({
    providedIn: "root"
})
export class DocumentProvider {
    private readonly CLASS_NAME = this.constructor.name;

    constructor(public rest: RestProvider) {
    }

    public getDocumentsFromPolicy(policyId: string, restProviderActions: RestProviderActions): Observable<DocumentResourceResult[]> {
        return this.rest.get<DocumentResourceJson>(
            `${environment.settings.BASE_URL}/api/advisor/policy/${policyId}/documents`,
            null, false, restProviderActions
        ).pipe(map((res) => {
            return DocumentResourceResult.fromJsons(res.results);
        }));
    }

    public getDocumentById(documentId: string, restProviderActions: RestProviderActions): Observable<DocumentResourceResult> {
        return this.rest.get<DocumentResourceResultJson>(
            `${environment.settings.BASE_URL}/api/advisor/document/${documentId}`,
            null, false, restProviderActions
        ).pipe(map((res) => {
            return DocumentResourceResult.fromJson(res);
        }));
    }

    public downloadDocument(documentId: string, fromMyNeo: boolean = false): void {
        let url = !fromMyNeo 
        ? `${environment.settings.BASE_URL}/api/advisor/document/${documentId}/download`
        : `${environment.settings.BASE_URL}/api/advisor/tempDocument/${documentId}/download`

        this.rest.download(url)
            .subscribe(docPayload => {
                log.info(`downloadDocument() -> encoded base64 content=${docPayload.content}`);
                const byteArray = new Uint8Array(atob(docPayload.content)
                    .split('')
                    .map(char => char.charCodeAt(0)));
                let blob = new Blob([byteArray], {type: 'application/octet-stream'});
                let file = new Blob([blob],{type: "application/octet-stream"});
                saveAs(file, docPayload.name);
            });
    }

    public getDocumentFromMyNeo(documentId: string, restProviderActions: RestProviderActions): Observable<DocumentResourceResult> {
        return this.rest.get<DocumentResourceResultJson>(
            `${environment.settings.BASE_URL}/api/advisor/tempDocument/${documentId}/details`,
            null, false, restProviderActions
        ).pipe(map((res) => {
            return DocumentResourceResult.fromJson(res);
        }));
    }

    public downloadGMDocument(offerId: string, name: string): Observable<any> {
        return this.rest.gmDownload(`${environment.settings.GM_OFFER_URL}/gmdto/getDocument`, offerId);             
    }

    public getIconByName(name: string) {
        if (name.toLowerCase().endsWith(".pdf")) {
            return "mdi-file-pdf-box";
        }
        if (name.toLowerCase().endsWith(".xls") ||
            name.toLowerCase().endsWith(".xlsx")) {
            return "mdi-file-excel-box";
        }
        return "mdi-file-download";
    }
}
