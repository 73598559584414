import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { PaginationConfigs } from 'app/modules/constants/PaginationConstants';
import { AdvisorService } from 'app/modules/core/providers/advisor.service';
import { ReferralsService } from '../../referrals/services/referrals.service';
import { FormControl } from '@angular/forms';
import { FilterTableComponent } from 'app/modules/core/components/tables/filter-table-component';
import { Helper } from 'app/modules/core/static/helper';


@Component({
  selector: 'neomp-referrals-table',
  templateUrl: './referrals-table.component.html',
  styleUrls: ['./referrals-table.component.scss']
})
export class ReferralsTableComponent extends FilterTableComponent<any> implements OnInit {

  @Input()
  referrals: any[] = [];

  @ViewChild(MatSort) sort: MatSort;
  pageSizeOptions = PaginationConfigs.pageSizeOptions;
  pageSize = PaginationConfigs.pageSize;
  pageIndex = PaginationConfigs.pageIndex;
  totalCount: number;
  dataSource: any;
  showFilter = false;
  


  advIdFilter = new FormControl("");
  uuidFilter = new FormControl("");
  registeredFilter = new FormControl({value:"", disabled:true});
  nameFilter = new FormControl("");
  emailFilter = new FormControl("");
  addressFilter = new FormControl("");
  phoneFilter = new FormControl("");

  filterValues: any = {};

  formControlFilters: FormControl[] = [
    this.advIdFilter,
    this.uuidFilter,
    this.registeredFilter,
    this.nameFilter,
    this.emailFilter,
    this.addressFilter,
    this.phoneFilter,
    null
  ];

  displayedColumns: string[] = ['advId', 'uuid', 'registered', 'name', 'email', 'address', 'phone', 'filter'];
  displayedColumnFilters: string[] = ['advIdFilter', 'uuidFilter', 'registeredFilter', 'nameFilter', 'emailFilter', 'addressFilter', 'phoneFilter', 'filterRemove'];

  constructor(private referralService: ReferralsService, private advisorService: AdvisorService) { super(); }

  ngOnInit() {
    this.getReferals(this.pageIndex, this.pageSize);
  }

  createFilter(): (data: any, filter: string) => boolean {
    return function (innerData, innerFilter): boolean {
      const searchTerms = JSON.parse(innerFilter);


      return (
        (searchTerms.advId !== null
          ? Helper.checkStringContains(
            innerData.advId,
            searchTerms.advId)
          : true) &&
        ((searchTerms.uuid !== null
          ? Helper.checkStringContains(
            innerData.uuid,
            searchTerms.uuid
          )
          : true) &&
        (searchTerms.name !== null
            ? Helper.checkStringContains(
              innerData.name,
              searchTerms.name
            )
            : true) &&
        (searchTerms.email !== null
            ? Helper.checkStringContains(
              innerData.email,
              searchTerms.email
            )
            : true)) && 
        (searchTerms.address !== null
             ? Helper.checkStringContains(
              innerData.address,
              searchTerms.address
              )
           : true))
        };
   }
 getReferals(page: number, size: number) {
    this.dataSource.data = this.referrals;

  }
 changeFilterView() {
    this.showFilter = !this.showFilter;
  }

 resetFilter() {
    this.filterValues = {};
    super.resetFilter();
 }


}
