import { Component } from "@angular/core";
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { forwardRef } from '@angular/core';

@Component({
    selector: "neomp-my-offer-page",
    templateUrl: "./my-offer-page.component.html",
    styleUrls: ["./my-offer-page.component.scss"],
    providers: [
        { 
          provide: NG_VALUE_ACCESSOR,
          multi: true,
          useExisting: forwardRef(() => MyOfferPageComponent),
        }
      ]
})
export class MyOfferPageComponent {
    selectedTabIndex = 0;
    selectedSubTabIndex = 0;

    tabChanged(event) {
        this.selectedTabIndex = event.index;
    }
    subTabChanged(event) {
        this.selectedSubTabIndex = event.index;
    }
}
