import {DocumentPayloadJson} from "../json/document-payload.json";

export class DocumentPayload {
    name: string = null;
    lastmodified: string = null;
    createdate: string = null;
    size: number = null;
    content: string = null
    encoding: string = null

    public static fromJsons(response: DocumentPayloadJson[]): DocumentPayload[] | undefined {
        if (response) {

            return response.map(docPayload => DocumentPayload.fromJson(docPayload));
        }
    }

    public static fromJson(res: DocumentPayloadJson): DocumentPayload {
        const result = new DocumentPayload();
        result.name = res.name ? res.name : null;
        result.lastmodified = res.lastmodified ? res.lastmodified : null;
        result.createdate = res.createdate ? res.createdate : null;
        result.size = res.size ? res.size : null;
        result.content = res.content ? res.content : null;
        result.encoding = res.encoding ? res.encoding : null;
        return result;
    }
}
