import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { IAgent } from '../../interfaces/Iagent';
import { IRoom } from '../../interfaces/Iroom';
import { LivechatService } from '../livechat.service';

@Component({
  selector: 'app-chats',
  templateUrl: './chats.component.html',
  styleUrls: ['./chats.component.scss'],
})
export class ChatsComponent implements OnInit {
  allRecivedRooms: IRoom[] | null = new Array;
  rooms: IRoom[] | null = new Array;
  agent: IAgent | null = null;
  activeRoom: any = null;
  archivable: boolean = true;

  constructor(
    private supportS: LivechatService,
    private router: Router,
    private route: ActivatedRoute
  ) {}
  ngOnInit() {
    if(this.route.routeConfig?.path === 'archives'){
      this.supportS.archivable.next(false);
    } else {
      this.supportS.archivable.next(true);
    }

    this.supportS.archivable.subscribe(value => { this.archivable = value });
    this.supportS.agent$.subscribe((value) => { this.agent = value })
    this.supportS.rooms$.subscribe((value) => {
      if(value !== null){
        let rooms = value!.filter(room => room.status === this.archivable);
        this.allRecivedRooms = rooms!;
        this.rooms = rooms!;
      }
    })
  }

  joinRoom(room: any) {
      this.router.navigate([`livechat/${room.status ? 'chats' : 'archives'}`, (room.roomId ? room.roomId : room._id)]);
      this.activeRoom = room;
      room.newMessages = 0;
      this.supportS.rooms$.next(this.supportS.rooms$.value);

  }

  onSearch(event: any){
    let searchValue: string = event.target.value;
    let filteredRooms = this.allRecivedRooms?.filter(room => room.clientName.toLowerCase().includes(searchValue.toLowerCase()))!;
    this.rooms = [...filteredRooms];
  }

  messageChatDate(date: Date){
    let time = new Date(date).getTime();
    let millisecondDay = 1000 * 60 * 60 * 24;
    let past24 = new Date().getTime() - millisecondDay;
    let yesterday = new Date().getTime() - (millisecondDay * 2);
    let lastWeek = new Date().getTime() - (millisecondDay * 7);

    if(time > past24) {return moment(time).format('HH:mm')}
    else if (time < past24 && time > yesterday) {return 'Yesterday'}
    else if (time < yesterday && time > lastWeek) {return moment(time).format('dddd')}
    else {return moment(time).format('L')}
  }
}
