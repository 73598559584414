import {Inject, Injectable, LOCALE_ID} from "@angular/core";
import {RestProvider, RestProviderActions} from "./rest.provider";
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment";
import {JournalJson} from "../json/journal.json";
import {Journal} from "../model/journal";
import {DatePickerValue} from "../model/datepicker";
import {formatDate} from "@angular/common";
import {isMoment} from "moment";
import { map } from "rxjs/operators";

@Injectable({
    providedIn: "root"
})
export class MessagesProvider {
    private readonly CLASS_NAME = this.constructor.name;

    constructor(public rest: RestProvider, @Inject(LOCALE_ID) private locale: string) {
    }

    public getMessages(restProviderActions: RestProviderActions): Observable<Journal[]> {
        return this.rest.get<JournalJson[]>(
            `${environment.settings.BASE_URL}/api/advisor/messages`,
            null,
            true,
            restProviderActions).pipe(map((res) => {
            return Journal.fromJsons(res);
        }));
    }

    public getMessagesByDateRange(restProviderActions: RestProviderActions,
                                  dateRange: DatePickerValue): Observable<Journal[]> {
        const from = formatDate(dateRange.begin.toDate(), "yyyy-MM-dd'T00:00:00.000'", this.locale);
        const to = formatDate(dateRange.end.toDate(), "yyyy-MM-dd'T23:59:59.999'", this.locale);
        const queryParams = `?filter={"type":"DateTime","key":"creationDate","from":"${from}","to":"${to}"}`;
        return this.rest.get<JournalJson[]>(
            `${environment.settings.BASE_URL}/api/advisor/messages${queryParams}`,
            null,
            true,
            restProviderActions)
            .pipe(map((res) => {
                return Journal.fromJsons(res);
            }));
    }

    public getUnreadMessages(restProviderActions: RestProviderActions): Observable<Journal[]> {
        return this.rest.get<JournalJson[]>(
            `${environment.settings.BASE_URL}/api/advisor/messages-unread`,
            null,
            true,
            restProviderActions)
            .pipe(map((res) => {
                return Journal.fromJsons(res);
            }));
    }

    public markMessagesAsRead(restProviderActions: RestProviderActions, ids: string[]): Observable<string> {
        return this.rest.post(`${environment.settings.BASE_URL}/api/advisor/messages-read`, ids);
    }

    public getRelatedMessagesByProcessId(restProviderActions: RestProviderActions, processID: string): Observable<Journal[]> {
        return this.rest.get(`${environment.settings.BASE_URL}/api/advisor/messages/${processID}`, null, false, restProviderActions);
    }
}
