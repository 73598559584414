<table [dataSource]="dataSource" class="msg-helper-width-100 custom-table" mat-table matSort matSortDirection="desc">

    <ng-container matColumnDef="name">
        <th *matHeaderCellDef mat-header-cell class="sort-arrow" mat-sort-header="fullName">{{ 'PAGE.LEADS.LEAD.NAME' |
            translate }}</th>
        <td mat-cell *matCellDef="let lead">{{lead.fullName}}</td>
    </ng-container>
    <ng-container matColumnDef="city">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'PAGE.LEADS.LEAD.PLZ-ORT' | translate }}</th>
        <td *matCellDef="let lead" mat-cell> {{lead.address.postalCode + ' ' + lead.address.city }}</td>
    </ng-container>
    <ng-container matColumnDef="statusLead">
        <th *matHeaderCellDef mat-header-cell mat-sort-header="lead.lead_status">{{ 'PAGE.LEADS.LEAD.STATUS' | translate
            }}</th>
        <td mat-cell *matCellDef="let lead">
            {{lead.lead !== null ? lead.lead.lead_status : ''}}
        </td>
    </ng-container>
    <ng-container matColumnDef="substatusLead">
        <th *matHeaderCellDef mat-header-cell mat-sort-header="lead.lead_substatus">{{ 'PAGE.LEADS.LEAD.SUBSTATUS' |
            translate }}
        </th>
        <td mat-cell *matCellDef="let lead"> {{lead.lead !== null ? lead.lead.lead_substatus : ''}}</td>
    </ng-container>

    <ng-container matColumnDef="date">
        <th *matHeaderCellDef mat-header-cell mat-sort-header="lead.lead_creadate">{{ 'PAGE.LEADS.LEAD.DATE' | translate
            }}</th>
        <td mat-cell *matCellDef="let lead"> {{ dateParse(lead.lead.lead_creadate) | date: 'dd.MM.yyyy' }}</td>
    </ng-container>
    <ng-container matColumnDef="category">
        <th *matHeaderCellDef mat-header-cell mat-sort-header="lead.lead_kategory">{{ 'PAGE.LEADS.LEAD.CATEGORY' |
            translate }}</th>
        <td mat-cell *matCellDef="let lead"> {{lead.lead !== null ? lead.lead.lead_kategory : ''}}</td>
    </ng-container>

    <ng-container *ngIf="isHighRole && !managementView" matColumnDef="advisor">
        <th *matHeaderCellDef mat-header-cell mat-sort-header="sorKeys.agent">{{ 'PAGE.LEADS.LEAD.ADVISOR' | translate
            }}</th>
        <td mat-cell *matCellDef="let data">
            {{data.sorKeys['agent'] && data.sorKeys['agent'].split("Neosana Sales AG")[1] ?
            data.sorKeys['agent'].split("Neosana Sales AG")[1] : data.sorKeys['agent']}}
        </td>
    </ng-container>
    <ng-container *ngIf="isHighRole && managementView" matColumnDef="advisor">
        <th *matHeaderCellDef mat-header-cell mat-sort-header="sorKeys.agent">{{ 'PAGE.LEADS.LEAD.ADVISOR' | translate
            }}</th>
        <td mat-cell *matCellDef="let data" (click)="agentsControlOnClick($event)">
            <mat-form-field class="neomp-full-width">
                <mat-select [value]="getSelectedAgent(data.lead.termin_berater_shortcut)"
                    (selectionChange)="assignAgentToLead($event.value, data)">
                    <mat-option *ngFor="let agent of agents" [value]="agent">
                        {{agent.fullName && agent.fullName.split("Neosana Sales AG")[1] ? agent.fullName.split("Neosana Sales AG")[1] : agent.fullName }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </td>
    </ng-container>

    <ng-container matColumnDef="filter">
        <th *matHeaderCellDef mat-header-cell (click)="changeFilterView()"
            class="mat-header-cell mat-column-filter {{isFilterActive ? 'mat-column-filter-active':''}}">
            <div class="neomp-table-column-filter-element  mdi {{isFilterActive ? 'mdi-filter':'mdi-filter-outline'}}">
            </div>
        </th>
        <td mat-cell *matCellDef="let element">
        </td>
    </ng-container>

    <!-- Filterrow elements-->
    <ng-container matColumnDef="leadSelectFilter" *ngIf="managementView">
        <th mat-header-cell *matHeaderCellDef>
        </th>
    </ng-container>

    <ng-container matColumnDef="nameFilter">
        <th mat-header-cell *matHeaderCellDef>
            <mat-form-field floatLabel="never">
                <input matInput [formControl]="leadNameFilter"
                    placeholder="{{ 'TABLES.FILTERPLACEHOLDER.NAME' | translate }}">
            </mat-form-field>
        </th>
    </ng-container>
    <ng-container matColumnDef="addressFilter">
        <th mat-header-cell *matHeaderCellDef>
            <mat-form-field floatLabel="never">
                <input matInput [formControl]="leadAddressFilter"
                    placeholder="{{ 'TABLES.FILTERPLACEHOLDER.POSTCODE_CITY' | translate }}">
            </mat-form-field>
        </th>
    </ng-container>

    <ng-container matColumnDef="statusLeadFilter">
        <th mat-header-cell *matHeaderCellDef>
            <mat-form-field floatLabel="never">
                <input matInput [formControl]="leadStatusFilter"
                    placeholder="{{ 'TABLES.FILTERPLACEHOLDER.STATUS' | translate }}">
            </mat-form-field>
        </th>
    </ng-container>

    <ng-container matColumnDef="substatusLeadFilter">
        <th mat-header-cell *matHeaderCellDef>
            <mat-form-field floatLabel="never">
                <input matInput [formControl]="leadSubstatusFilter"
                    placeholder="{{ 'TABLES.FILTERPLACEHOLDER.SUBSTATUS' | translate }}">
            </mat-form-field>
        </th>
    </ng-container>
    <ng-container matColumnDef="dateFilter">
        <th mat-header-cell *matHeaderCellDef>
            <mat-form-field floatLabel="never">
                <input [matDatepicker]="date" matInput [formControl]="leadDateFilter"
                    placeholder="{{ 'TABLES.FILTERPLACEHOLDER.DATE' | translate }}">
                <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
                <mat-datepicker #date></mat-datepicker>
            </mat-form-field>
        </th>
    </ng-container>
    <ng-container matColumnDef="categoryFilter">
        <th mat-header-cell *matHeaderCellDef>
            <mat-form-field floatLabel="never">
                <input matInput [formControl]="leadCategoryFilter"
                    placeholder="{{ 'TABLES.FILTERPLACEHOLDER.CATEGORY' | translate }}">
            </mat-form-field>
        </th>
    </ng-container>

    <ng-container *ngIf="isHighRole" matColumnDef="advisorFilter">
        <th mat-header-cell *matHeaderCellDef>
            <mat-form-field floatLabel="never">
                <input matInput [formControl]="leadAdvisorFilter"
                    placeholder="{{ 'TABLES.FILTERPLACEHOLDER.ADVISOR' | translate }}">
            </mat-form-field>
        </th>
    </ng-container>
    <ng-container matColumnDef="filterRemove">
        <th mat-header-cell *matHeaderCellDef (click)="resetFilter()">
            <div class="neomp-table-column-filter-element mdi mdi-close"></div>
        </th>
    </ng-container>

    <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>


    <tr *matHeaderRowDef="displayedColumnFilters"
        class="neomp-table-row-filter {{showFilter? '': 'neomp-table-row-filter-hide'}}" mat-header-row></tr>

    <tr mat-row *matRowDef="let element; columns: displayedColumns; let i = dataIndex;"
        class="element-row container-table-row"
        (click)="redirectToEdit(element.uuid, element.lead.termin_berater_shortcut, element.lead.edit_servicecenter);">
    </tr>
</table>