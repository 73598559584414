import {Injectable} from "@angular/core";
import {Router, CanActivate} from "@angular/router";
import { KeycloakService } from "keycloak-angular";
import { AgenturType } from "../model/enum-agenturtype";
import { Person } from "../model/person";

@Injectable()
export class SupplierGuard implements CanActivate {
    agents: Person[] = [];

    agenturType: string = "";
    agenturName: string = "";
    constructor(public router: Router,  private keycloak: KeycloakService) {}
    
    async canActivate(): Promise<boolean> {
        await this.keycloak.loadUserProfile().then((res: any) => { 
            this.agenturType = res.attributes.agenturType[0];
            this.agenturName = res.attributes.agenturName[0];                
        });
        
        return !(this.agenturType === AgenturType.Supplier)
    }
}
