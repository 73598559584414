<neomp-container 
[isLightContainer]="isNested" 
[showHeadline]="showHeadline" 
[headlineText]="headlineText" 
headlineText
cssClass="msg-mp-container-frameless {{ shadow ? '' : 'no-shadow' }}">
  
  <div *ngIf="lastInvalids != null && lastInvalids.length > 0" class="msg-loadingstatus msg-loadingerror">
    <div class="mdi mdi-alert-circle-outline">
      <span>{{ "PAGE.OFFER.ERRORMESSAGE" | translate }} | </span>
      <span>{{ "PAGE.OFFER.MAXFILECOUNT" | translate }} {{ maxCount }} | </span>
      <span>{{ "PAGE.OFFER.MAXFILESIZE" | translate
        }}{{ maxSize / 1024 | number: "1.0-0" }} KB
      </span>
    </div>
  </div>

  <!-- Uppy uploader -->
  <div id="drag-drop-area"></div>

</neomp-container>