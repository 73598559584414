export class CustomersCache {
    mandat: boolean;
    name: string;
    address: string;
    appStatus: string;

    constructor(mandat: boolean, name: string, address: string, appStatus: string) {
        this.mandat = mandat;
        this.name = name;
        this.address = address;
        this.appStatus = appStatus;
    }
}
