export enum SubStatuses {
    KONTAKTVERSUCH = ' 2. Kontaktversuch',
    IN_BEHANDLUNG = '1. In Behandlung',
    CALL_BACK = '1. Callback',
    OFFERTEN = ' 2. Offerte',
    MJV = ' 3. MJV',
    OFFEN = " 9. Offen",
    VOR_ORT = "1. Vor Ort",
    ONLINE = "2. Online",
    TELEFONISCH = "3. Telefonisch"
}