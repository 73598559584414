import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { NeoInputCompleterItem } from '../../models/NeoInputCompleterItem';

@Component({
  selector: 'neomp-neo-input-completer',
  templateUrl: './neo-input-completer.component.html',
  styleUrls: ['./neo-input-completer.component.scss']
})
export class NeoInputCompleterComponent implements OnInit {
  
  @Output() neoValueEmitter = new EventEmitter<NeoInputCompleterItem>(true);
  @Output() neoValueIsValid = new EventEmitter<boolean>(true);

  @Input() neoValue: NeoInputCompleterItem;
  @Input() neoLabel: string;
  @Input() neoPlaceholder: any;
  @Input() neoRequired = false;
  @Input() neoShowRequired = false;
  @Input() neoErrorTooltipText: string;
  @Input() minLength = 4;
  @Input() neoCompleterItems: NeoInputCompleterItem[];

  @Input()
  set neoShowError(doShowError: boolean) {
      this.showErrorCss = doShowError;
  }
  @Input()
  set neoAutoValue(v: NeoInputCompleterItem) {
    if (v !== null) {
      this.neoValue = v;
      this.setValue();
    }
  }

  showErrorCss = false;
  myControl = new FormControl();
  filteredOptions: Observable<NeoInputCompleterItem[]>;

  constructor() { }

  ngOnInit() {
    this.filteredOptions = this.myControl.valueChanges.pipe(debounceTime(400))
    .pipe(startWith(""), map(value => this._filter(value)));
    this.myControl.valueChanges.pipe(debounceTime(400)).subscribe(data => {
    if (data === undefined || data === "" || typeof data === "string") {
        this.showErrorCss = true;
        this.neoValueEmitter.emit(undefined);
    } else {
        this.neoValueEmitter.emit(data);
        this.showErrorCss = false;
    }
});

  }

  setValue() {
    this.myControl.setValue(this.neoValue);
    this.neoValueEmitter.emit(this.neoValue);
}
private _filter(value: string): NeoInputCompleterItem[] {
  if (value !== undefined) {
      const filterValue = value.toString().toLowerCase();
      if (this.neoCompleterItems && filterValue && filterValue.length >= this.minLength) {
          return this.neoCompleterItems.filter(option => option.value.toLowerCase().includes(filterValue));
      }
  }
  return [];
}

public neoIsValid(): boolean {
  if (this.neoRequired && this.neoValue && this.neoValue.value && this.neoValue.value.length > 0) {
      return true;
  } else if (this.neoRequired) {
      return false;
  }
  return true;
}



}
