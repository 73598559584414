import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { CommunicationAddress } from 'app/modules/core/model/communication-address';
import { Config } from 'app/modules/core/model/config';
import { CommAddrTypes } from 'app/modules/core/model/enum-commaddrtype';
import { Journal } from 'app/modules/core/model/journal';
import { KeyValuePair } from 'app/modules/core/model/key-value-pair';
import { LeadLog } from 'app/modules/core/model/LeadLog.enum';
import { Person } from 'app/modules/core/model/person';
import { UserCommitState } from 'app/modules/core/model/user-commit-state';
import { ActionDialogService } from 'app/modules/core/providers/action-dialog.service';
import { ConfigProvider } from 'app/modules/core/providers/config.provider';
import { LoadingService } from 'app/modules/core/providers/loading.component';
import { log } from 'app/modules/core/providers/logger.provider';
import { BusinessTransactionHelper } from 'app/modules/core/static/bt-helper';
import { KeycloakService } from 'keycloak-angular';
import moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { LEADS_CONFIGS } from '../../configs/lead-configs';
import { RemindersList } from '../../Interfaces/RemindersList';
import { LeadsService } from '../../services/leads.service';

@Component({
  selector: 'neomp-leads-edit-details',
  templateUrl: './leads-edit-details.component.html',
  styleUrls: ['./leads-edit-details.component.scss']
})
export class LeadsEditDetailsComponent implements OnInit, OnChanges, OnDestroy {
  private readonly TAG = this.constructor.name;
  @Input() lead: Person;
  @Input() configSettings: Config[] = [];

  user: any;

  displayReminderInputs = {
    showDateInput: true,
    showTimeInput: true
  };

  leadConfigs = LEADS_CONFIGS; 
  // regions: NeoInputCompleterItem[] = []; 
  
  filteredSources: Observable<any[]>;
  filteredCategories: Observable<any[]>;
  filteredStatuses: Observable<any[]>;
  filteredAgents: Observable<any[]>;
  reminderCategories: KeyValuePair[] = [];
  leadStatuses:  KeyValuePair[] = [];
  remindersList: RemindersList[] = [];
  terminLeadsReminder: RemindersList[] = [];
  notesList: {agentName: string, description: string}[] = [];

  subscriptions: Subscription[] = [];
  // A3N DEPRECATED
  // filteredRegions: Observable<NeoRegion[]>;

  leadForm: FormGroup = new FormGroup({
    lead_typ: new FormControl({value: "", disabled: true}, [Validators.required]),
    lead_kategory: new FormControl({value: "", disabled: true}, [Validators.required]),
    lead_status: new FormControl({value: "", disabled: true}),
    agent: new FormControl({value: "", disabled: true}),
    salutation: new FormControl({value: "", disabled: true}),
    language: new FormControl({value: "", disabled: true}, [Validators.required]),
    lead_personen: new FormControl({value: "", disabled: true}),
    // aktuelle_kk: new FormControl({value: "", disabled: true}),
    name: new FormControl({value: "", disabled: true}, [Validators.required]),
    firstName: new FormControl({value: "", disabled: true}, [Validators.required]),
    phone1: new FormControl({value: "", disabled: true}),
    phone2: new FormControl({value: "", disabled: true}),
    email1: new FormControl({value: "", disabled: true}),  
    email2: new FormControl({value: "", disabled: true}),
    address: new FormControl({value: "", disabled: true}),
    ort: new FormControl({value: "", disabled: true}, [Validators.required]),
    zip: new FormControl({value: "", disabled: true}, [Validators.required]),
    city: new FormControl({value: "", disabled: true}, [Validators.required]),
    kanton: new FormControl({value: "", disabled: true},),
    birthday: new FormControl({value: "", disabled: true},),
    lead_extradata: new FormControl({value: "", disabled: true},)
  });

  reminderForm: FormGroup = new FormGroup({
    category: new FormControl("", [Validators.required ]),
    date: new FormControl("", [Validators.required ]),
    time: new FormControl("", [Validators.required ]),
    description: new FormControl("", [Validators.required ])
  });

  data: any;

  constructor(
    private snackBar: MatSnackBar,
    private loadService: LoadingService,
    private leadsService: LeadsService,
    private keycloak: KeycloakService,
    private configProvider: ConfigProvider,
    private translate: TranslateService,
    public titleService: Title,
    public dialogService: ActionDialogService
  ) { }

  async ngOnInit(): Promise<void> {
    // A3N DEPRECATED
    // this.loadRegions();

    this.user = await this.keycloak.loadUserProfile();
    
   // A3N this method was used for development purpose  
    // this.leadsService.getDummyData().subscribe((res) => {
    //   this.data = res;

    //  this.filteredSources = this.leadForm.controls.lead_typ.valueChanges.pipe(
    //     startWith(''),
    //     map(value => this._filter(this.data.Response.source, value))
    //   );

    //   this.filteredCategories = this.leadForm.controls.lead_kategory.valueChanges.pipe(
    //     startWith(''),
    //     map(value => this._filter(this.data.Response.sourceCategory, value))
    //   );
      
      // this.filteredStatuses = this.leadForm.controls.lead_status.valueChanges.pipe(
      //   startWith(''),
      //   map(value => this._filter(this.data.Response.status, value))
      // );
      
    //   this.filteredAgents = this.leadForm.controls.agent.valueChanges.pipe(
    //     startWith(''),
    //     map(value => this._filter(this.data.Response.vermittler, value))
    //   );
      

    // }, err => {
    //   console.log(err);
    // });

    this.subscriptions.push(
    this.reminderForm.controls.category.valueChanges.subscribe((category: KeyValuePair) => {
      if (category && category.key === this.leadConfigs.typeId.LEADS.NOTE ) {
        this.displayReminderInputs = {
          showDateInput: false,
          showTimeInput: false
        };
        this.reminderForm.controls.date.disable();
        this.reminderForm.controls.time.disable();
      } else {
        this.resetReminderFormToDefaults();
      }
    }));
  }

  setStatusDialog(): void {
    const sorKeys = {
      agentName: this.user.firstName
    };
    this.lead.lead.termin_editdate = moment().format('YYYY-MM-DD');

    const actionData =  BusinessTransactionHelper.createLeadBTActionObject(
      LeadLog.LEAD_S_UPDATE,
      BusinessTransactionHelper.typeId.leadDetailsStatus.from,
      this.leadForm.value.lead_status,
      "Lead status update => " + this.leadForm.value.lead_status,
      this.titleService.getTitle(),
      this.lead.uuid,
      [],
      this.lead,
      Date.now().toString(),
      sorKeys,
      this.user.firstName
    );
    const dialogRef = this.dialogService.setStatusDialog(actionData, {keyValues: this.leadStatuses, typeId: "4501"});

    dialogRef.afterClosed()
    .subscribe(data => {
        if (!data) {
            return;
        }
        this.loadService.display(true);
        log.debug(`sending as action data -> ${data}`);

        this.leadsService.sendLeadAction(data)
            .subscribe(value => {
                this.loadService.display(false);
                this.loadService.setDialogClosed(true);
                this.leadForm.controls.lead_status.setValue(data.transaction.typeName);
                this.snackBar.open( this.translate.instant("COMMONS.USERCOMMIT.SUCCESS"), "X", UserCommitState.configSuccess);
            }, error => {
                this.loadService.display(false);
                this.snackBar.open(this.translate.instant("COMMONS.USERCOMMIT.ERROR"), "X", UserCommitState.configError);
            });   
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('lead' in changes && changes.lead.currentValue) {
      this._fillLeadDetails(changes.lead.currentValue);
      this.getLeadJournals();
    }
    if ('configSettings' in changes && changes.configSettings.currentValue) {
      this.setConfigs(changes.configSettings.currentValue);
    }
  }

  setConfigs(configSettings: Config[]): void {

    this.leadStatuses = configSettings.filter(x => 
    Number(x.key) >= Number(BusinessTransactionHelper.typeId.leadStatus.from) && 
    Number(x.key) <= Number(BusinessTransactionHelper.typeId.leadStatus.to))
    .map(y => { return {
      key: y.key,
      value: y.values[0].value
    }});

    // A3N deprecated
    // const leadS = this.configProvider.filterConfigsByValueRange(configSettings, { defaultTypeId: BusinessTransactionHelper.typeId.leadDetailsStatus.from });

    // if (leadS.data && leadS.data.values.length > 0) {
    //   this.leadStatuses = leadS.data.values[0].value.split(",");
    // }

    const typeIds = this.leadConfigs.typeId;
    const rCategories = this.configProvider.filterConfigsByValueRange(configSettings, [ typeIds.LEADS.CALLBACK, typeIds.LEADS.TERMIN, typeIds.LEADS.NOTE ]);
    if (rCategories.length > 0) {
      this.reminderCategories = rCategories.map(x => {
        return {
          key: x.key,
          value: x.values[0].value
        }
      }).reverse();
    }
  }
  // A3N DEPRECATED

  // loadRegions(): void {
  //   this.leadsService.loadRegions().subscribe((regions: NeoRegion[]) => {
  //     this.regions = regions;

  //     this.filteredRegions = this.leadForm.controls.zip.valueChanges.pipe(
  //       debounceTime(300),
  //       startWith(''),
  //       map(value => this._filter(regions, value, 'PLZ'))
  //     ) as any;
  //   })
  // }
  
  // getOptionText(neoRegion: NeoRegion): string {
  //   return neoRegion ? neoRegion.PLZ + ' ' + neoRegion.Kanton : '';
  // }
  
  // onRegionChange(event: any): void {

  //   this.leadForm.patchValue({
  //     ort: event.option.value.Ort,
  //     kanton: event.option.value.Kanton
  //   });
    
  // }
  
  // submitLeadForm(): void {
  //   console.log(this.leadForm.getRawValue());
  // }
 
  private _fillLeadDetails(person: Person): void {

    const businessPhone: CommunicationAddress = person.communicationAddresses.find(x => x.commAddrType.key === CommAddrTypes.PHONE_MOBILE_BUSINESS);
    const phonePrivate: CommunicationAddress = person.communicationAddresses.find(x => x.commAddrType.key === CommAddrTypes.PHONE_MOBILE_PRIVATE);
    const businessEmail: CommunicationAddress = person.communicationAddresses.find(x => x.commAddrType.key === CommAddrTypes.EMAIL_BUSINESS);
    const emailPrivate: CommunicationAddress = person.communicationAddresses.find(x => x.commAddrType.key === CommAddrTypes.EMAIL_PRIVATE);

    this.leadForm.patchValue({
      lead_typ: person.lead.lead_typ || '',
      lead_kategory: person.lead.lead_kategory || '',
      lead_status: person.lead.lead_status || '',
      agent: person.lead.lead_agency || '',
      salutation: person.salutation.toLowerCase(),
      language: '',
      lead_personen: person.lead.lead_personen || '',
      // aktuelle_kk: leadDetails.aktuelle_kk,
      name: person.name || '',
      firstName: person.firstName || '',
      phone1: businessPhone ? businessPhone.address : "",
      phone2: phonePrivate ? phonePrivate.address : "",
      email1: businessEmail ? businessEmail.address : "",
      email2: emailPrivate ? emailPrivate.address : "",
      address: person.address.street || '',
      ort: person.address.city || '',
      zip: person.address.postalCode || '',
      kanton: '',
      birthday: person.birthdate || '',
      lead_extradata: person.lead.lead_extradata || '',
    });
    
      
    // S3T: Might be added later on...
    // if(this.lead.lead.lead_status != ""){
    //   this.leadForm.controls.lead_status.disable();
    // }
  }

  private _filter(data: any[], value: string, filterField = 'name'): string[] {
    const filterValue = value.toString().toLowerCase();
    return data.filter(x => x[filterField].toString().toLowerCase().includes(filterValue));
  }

  async addReminder(): Promise<void> {
    if (this.reminderForm.valid) {
      const formValue: { category: KeyValuePair, date: string, time: string, description: string } 
      = this.reminderForm.value;

      const sorKeys = {
        agentName: this.user.firstName,
        category: formValue.category.value,
        time: formValue.time || ""
      };
      const actionData =  BusinessTransactionHelper.createLeadBTActionObject(
        formValue.category.value,
        formValue.category.key,
        formValue.category.value,
        LeadLog.TERMIN,
        formValue.description,
        this.lead.uuid,
        [],
        this.lead,
        moment(formValue.date).format('YYYY-MM-DD') || "",
        sorKeys,
        this.user.firstName
      );

      this.subscriptions.push(
      this.leadsService.sendLeadAction(actionData)
      .subscribe(() => {
        this.snackBar.open("Reminder created successfully", "X", UserCommitState.configSuccess);
        this.reminderForm.reset();
        this.resetReminderFormToDefaults();
        this.addJournalTemporary(actionData.transaction);
      }, err => {
        log.error(`Something went wrong while calling POST-> lead/action `, err);
        this.snackBar.open("Something went wrong!", "X", UserCommitState.configError)
      })
      );

    }
  }

  // A3N there was delay of several seconds from the moment when action was sent and BT service saves it into DB so we tmp push NEW journals in array
  addJournalTemporary(journal: Journal): void {

    switch (journal.typeId) {
      case this.leadConfigs.typeId.LEADS.CALLBACK:
        this.remindersList.unshift({
          agentName: this.user.firstName,
          description: journal.descriptionField,
          time: journal.sorKeys.time,
          date: journal.effectiveDate
        });
        break;
      case this.leadConfigs.typeId.LEADS.TERMIN:
        this.terminLeadsReminder.unshift(
          {
            agentName: this.user.firstName,
            description: journal.descriptionField,
            time: journal.sorKeys.time,
            date: journal.effectiveDate
          }
        );
        break;

      case this.leadConfigs.typeId.LEADS.NOTE:
        this.notesList.unshift(
          {
            agentName: this.user.firstName,
            description: journal.descriptionField
          }
        );
        break;

      default:
        break;
    }
  }

  getLeadJournals() {
    this.subscriptions.push(
    this.leadsService.getJournalsByPartnerId(
        this.lead.uuid,
        BusinessTransactionHelper.typeId.leadReminders.from,
        BusinessTransactionHelper.typeId.leadReminders.to
    ).subscribe(journals => {
      
      this.remindersList = journals.filter(x => x.typeId === this.leadConfigs.typeId.LEADS.CALLBACK).map(x => {
        return {
          agentName: x.sorKeys.agentName,
          description: x.descriptionField,
          time: x.sorKeys.time,
          date: x.effectiveDate
        }
      });

      this.terminLeadsReminder = journals.filter(x => x.typeId === this.leadConfigs.typeId.LEADS.TERMIN).map(x => {
        return {
          agentName: x.sorKeys.agentName,
          description: x.descriptionField,
          time: x.sorKeys.time,
          date: x.effectiveDate
        }
      });

      this.notesList = journals.filter(x => x.typeId === this.leadConfigs.typeId.LEADS.NOTE).map(x => {
        return {
          agentName: x.sorKeys.agentName,
          description: x.descriptionField
        }
      });
    }));
}

  resetReminderFormToDefaults(): void {
    this.displayReminderInputs = {
      showDateInput: true,
      showTimeInput: true
    };
    this.reminderForm.controls.date.enable();
    this.reminderForm.controls.time.enable();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
  }
}
