<mat-form-field [formGroup]="dateForm" floatLabel="never" appearance="fill" class="neo-range filter">

    <mat-datepicker-toggle matPrefix [for]="neoDatePicker" class="icon-hide">
        <mat-icon matDatepickerToggleIcon>
            <svg viewBox="0 0 24 24" style="width:24px;height:24px;padding-bottom:12px;" focusable="false"
                class="mat-datepicker-toggle-default-icon ng-star-inserted">
                <path style="color:#00254D" fill="currentColor"
                    d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z">
                </path>
            </svg>
        </mat-icon>
    </mat-datepicker-toggle>

    <mat-date-range-input class="neo-range-input-date" [rangePicker]="neoDatePicker"
        (click)="neoDatePicker.opened = true" [disabled]="disabled">
        <input class="neo-range-input" formControlName="begin" matStartDate placeholder="tt.mm.yyyy">
        <input class="neo-range-input" formControlName="end" matEndDate placeholder="tt.mm.yyyy">
    </mat-date-range-input>
    <mat-date-range-picker (closed)="pickerClosed()" #neoDatePicker>
    </mat-date-range-picker>
</mat-form-field>