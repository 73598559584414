import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { PaginationConfigs } from 'app/modules/constants/PaginationConstants';
import { LoadingDataComponent } from 'app/modules/core/components/loading-data.component';
import { Config } from 'app/modules/core/model/config';
import { Journal } from 'app/modules/core/model/journal';
import { Person } from 'app/modules/core/model/person';
import { ConfigProvider } from 'app/modules/core/providers/config.provider';
import { log } from 'app/modules/core/providers/logger.provider';
import { RestProviderActions } from 'app/modules/core/providers/rest.provider';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { LeadDetails } from '../../Interfaces/LeadDetails';
import { LeadsService } from '../../services/leads.service';
import { LeadsEditTerminComponent } from './leads-edit-termin.component';

@Component({
  selector: 'neomp-leads-edit',
  templateUrl: './leads-edit.component.html',
  styleUrls: ['./leads-edit.component.scss']
})
export class LeadsEditComponent implements OnInit, OnDestroy {
  @ViewChild('leadsEditTerminCp') leadsEditTerminCp: LeadsEditTerminComponent;
  @ViewChild("loadingLogsTable") loadingLogsTable: LoadingDataComponent;
  subscriptions: Subscription[] = [];
  lead: Person | null = null;
  leadDetails: LeadDetails;
  configSettings: Config[] = [];
  logs: Journal[] = [];
  pageSize = PaginationConfigs.pageSize;
  pageSizeOptions = PaginationConfigs.pageSizeOptions;
  pageIndex = PaginationConfigs.pageIndex;
  totalCount = 0;

  constructor(
    private activatedRoute: ActivatedRoute,
    private leadsService: LeadsService,
    private configProvider: ConfigProvider,
    private router: Router,
  ) { }

  @HostListener('window:beforeunload')
  canDeactivate(): boolean {
    return this.leadsEditTerminCp.canDeactivate();
  }

  ngOnInit() {
    this.getConfigs();
    this.subscriptions.push(
      this.activatedRoute.paramMap.subscribe((paramMap: ParamMap) => {
        this.getLeadById(paramMap.get('id'));
      }));
  }

  getConfigs(): void {
    this.subscriptions.push(
      this.configProvider.getConfig().subscribe((configs: []) => {
        this.configSettings = configs;
      }));
  }

  getLeadById(id: string): void {
    this.subscriptions.push(
      this.leadsService.getLeadById(id)
        .subscribe((lead: Person) => {
          this.lead = Person.fromJson(lead);
        }, (err) => {
          this.router.navigate(['/leads'])
          log.error(err);
        }));
  }
  addNewLog(event: Journal) {
    const newLog = event;
    newLog.creationDate = moment().format('YYYY-MM-DDTHH:mm:ss.SSSZZ')
    this.logs = [newLog, ...this.logs];
    this.totalCount++;
  }
  getLeadLogs(leadId: string, restProviderActions: RestProviderActions): void {
    this.subscriptions.push(
      this.leadsService.getLeadLogs(restProviderActions, leadId, this.pageIndex, this.pageSize,)
        .subscribe((logs) => {
          this.logs = logs.businessTransactions;
          this.totalCount = logs.totalCount
        })
    )
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
  }
}
