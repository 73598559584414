<ng-template matTabContent></ng-template>
<div
  class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
  <mat-card>
    <div (keyup.enter)="search()"
      class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-9-tablet mdc-layout-grid__cell--span-12-phone">

      <!-- search fields -->
      <div class="search-fields-layout-grid">
        <mat-checkbox [(ngModel)]="completedTasks" (change)="search()">{{'PAGE.LEADS.TASK.COMPLETED' | translate}}</mat-checkbox>
      </div>
      <div class="search-fields-layout-grid">
        <div>
          <mat-form-field class="neomp-full-width">
            <mat-label>{{ 'PAGE.LEADS.LEAD.TEXT' | translate }}</mat-label>
            <input matInput [(ngModel)]="taskSearchText" />
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="neomp-full-width">
            <mat-label>{{ 'PAGE.LEADS.LEAD.FROM' | translate }}</mat-label>
            <input [matDatepicker]="from" matInput [(ngModel)]="dateFrom" />
            <mat-datepicker-toggle matSuffix [for]="from"></mat-datepicker-toggle>
            <mat-datepicker #from></mat-datepicker>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="neomp-full-width">
            <mat-label>{{ 'PAGE.LEADS.LEAD.TO' | translate }}</mat-label>
            <input [matDatepicker]="to" matInput [(ngModel)]="dateTo" />
            <mat-datepicker-toggle matSuffix [for]="to"></mat-datepicker-toggle>
            <mat-datepicker #to></mat-datepicker>
          </mat-form-field>
        </div>
        <div *ngIf="isHighRole">
          <mat-form-field class="neomp-full-width">
            <mat-label id="berater-label">{{ 'PAGE.LEADS.LEAD.ADVISOR' | translate }}</mat-label>
            <mat-select [formControl]="agentSelectedControl">
              <mat-option [value]="">
                {{ 'PAGE.LEADS.LEAD.ALL' | translate }}
              </mat-option>
              <mat-option *ngFor="let agent of agents" [value]="agent.uuid">
                {{agent.fullName && agent.fullName.split("Neosana Sales AG")[1] ? agent.fullName.split("Neosana Sales AG")[1] : agent.fullName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

        <!-- buttons -->
        <div class="search-fields-layout-grid margin-top-15">
          <div class="neo-padding-top-10">
            <button mat-raised-button color="primary" class="neomp-full-width button-text" (click)="search()">
              <span class="material-icons">
                search
              </span>{{ 'PAGE.LEADS.LEAD.SEARCH' | translate }}
            </button>
          </div>
          <div class="neo-padding-top-10">
            <button mat-raised-button class="clear-button neomp-full-width button-text" (click)="clearSearch()">
              <span class="material-icons">clear</span>{{ 'PAGE.LEADS.LEAD.CLEAR' | translate }}
            </button>
          </div>
        </div>

    </div>
  </mat-card>
</div>

<table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort>

  <ng-container matColumnDef="icon">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <div class="div-status" [ngClass]="getClassFromStatus(element.effectiveDate, element.statusName)"></div>
    </td>
  </ng-container>

  <ng-container matColumnDef="customerName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="customerName"> {{ 'PAGE.CONTAINER.CUSTOMERS.CONTAINER.NAME' |
      translate }} </th>
    <td mat-cell *matCellDef="let element">{{element.customerName }} </td>
  </ng-container>

  <ng-container matColumnDef="customerPLZ">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="customerPLZ"> {{ 'PAGE.CONTAINER.CUSTOMERS.CONTAINER.ZIPCODE'
      | translate }} </th>
    <td mat-cell *matCellDef="let element">{{element?.customerPLZ }} </td>
  </ng-container>

  <ng-container matColumnDef="category">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="category"> {{ 'PAGE.CONTAINER.CUSTOMERS.CONTAINER.CATEGORY' |
      translate }} </th>
    <td mat-cell *matCellDef="let element"> {{element?.category }} </td>
  </ng-container>

  <ng-container matColumnDef="statusName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="statusName"> {{ 'PAGE.CONTAINER.CUSTOMERS.CONTAINER.STATUS' |
      translate }} </th>
    <td mat-cell *matCellDef="let element"> {{element?.statusName }} </td>
  </ng-container>
  <ng-container matColumnDef="statusLead">
    <th *matHeaderCellDef mat-header-cell mat-sort-header="statusLead">{{ 'PAGE.LEADS.LEAD.STATUS-LEAD' | translate }}
    </th>
    <td mat-cell *matCellDef="let element"> {{element.statusLead !== null ? element.statusLead : ''}}</td>
  </ng-container>

  <ng-container matColumnDef="substatusLead">
    <th *matHeaderCellDef mat-header-cell mat-sort-header="substatusLead">{{ 'PAGE.LEADS.LEAD.SUBSTATUS' | translate }}
    </th>
    <td mat-cell *matCellDef="let element"> {{element.substatusLead !== null ? element.substatusLead : ''}}</td>
  </ng-container>

  <!-- BT effectiveDate -->

  <ng-container matColumnDef="effectiveDate">

    <th mat-header-cell *matHeaderCellDef mat-sort-header="effectiveDate"> {{
      'PAGE.CONTAINER.COMMISSIONS.TABLES.PROVISIONSPENDINGMATTER.EFFECTIVDATE' | translate }} </th>
    <td mat-cell *matCellDef="let element">
      <span *ngIf="element.effectiveDate !== 'Invalid date'"> {{ element.effectiveDate | date: 'dd.MM.yyyy':"UTC"
        }}</span>
    </td>
  </ng-container>
  <ng-container *ngIf="isHighRole" matColumnDef="agentName">
    <th *matHeaderCellDef mat-header-cell mat-sort-header="agentName">{{
      'PAGE.CONTAINER.LEADS.TABLES.APPOINTMENTS.ADVISOR' | translate }}</th>
    <td mat-cell *matCellDef="let element"> {{element.agentName && element.agentName.split("Neosana Sales AG")[1] ?
      element.agentName.split("Neosana Sales AG")[1] : element.agentName }}</td>
  </ng-container>
  <ng-container matColumnDef="filter">
    <th *matHeaderCellDef mat-header-cell (click)="changeFilterView()"
      class="mat-header-cell mat-column-filter {{isFilterActive ? 'mat-column-filter-active':''}}">
      <div class="neomp-table-column-filter-element  mdi {{isFilterActive ? 'mdi-filter':'mdi-filter-outline'}}"></div>
    </th>
    <td mat-cell *matCellDef="let element" class="mat-column-filter-action">
      <div class="neomp-table-column-filter-element mdi mdi-plus" [disabled]="isDialogOpen"
        (click)="isDialogOpen ? null : openActionDialog($event, element)"></div>
    </td>
  </ng-container>

  <!-- Filter row definition -->
  <ng-container matColumnDef="iconFilter">
    <th mat-header-cell *matHeaderCellDef>
    </th>
  </ng-container>
  <ng-container matColumnDef="nameFilter">
    <th mat-header-cell *matHeaderCellDef>
      <mat-form-field class="filter" floatLabel="never">
        <input matInput [formControl]="nameFilter" placeholder="{{ 'TABLES.FILTERPLACEHOLDER.NAME' | translate }}">
      </mat-form-field>
    </th>
  </ng-container>
  <ng-container matColumnDef="plzFilter">
    <th mat-header-cell *matHeaderCellDef>
      <mat-form-field class="filter" floatLabel="never">
        <input matInput [formControl]="plzFilter" placeholder="{{ 'TABLES.FILTERPLACEHOLDER.POSTALCODE' | translate }}">
      </mat-form-field>
    </th>
  </ng-container>
  <ng-container matColumnDef="categoryFilter">
    <th mat-header-cell *matHeaderCellDef>
      <mat-form-field class="filter" floatLabel="never">
        <input matInput [formControl]="categoryFilter"
          placeholder="{{ 'TABLES.FILTERPLACEHOLDER.CATEGORY' | translate }}">
      </mat-form-field>
    </th>
  </ng-container>
  <ng-container matColumnDef="statusNameFilter">
    <th mat-header-cell *matHeaderCellDef>
      <mat-form-field class="filter" floatLabel="never">
        <input matInput [formControl]="statusNameFilter"
          placeholder="{{ 'TABLES.FILTERPLACEHOLDER.STATUS' | translate }}">
      </mat-form-field>
    </th>
  </ng-container>
  <ng-container matColumnDef="statusLeadFilter">
    <th mat-header-cell *matHeaderCellDef>
      <mat-form-field class="filter" floatLabel="never">
        <input matInput [formControl]="statusLeadFilter"
          placeholder="{{ 'TABLES.FILTERPLACEHOLDER.STATUS' | translate }}">
      </mat-form-field>
    </th>
  </ng-container>
  <ng-container matColumnDef="substatusLeadFilter">
    <th mat-header-cell *matHeaderCellDef>
      <mat-form-field class="filter" floatLabel="never">
        <input matInput [formControl]="substatusLeadFilter"
          placeholder="{{ 'TABLES.FILTERPLACEHOLDER.STATUS' | translate }}">
      </mat-form-field>
    </th>
  </ng-container>
  <ng-container matColumnDef="effectiveDateFilter">
    <th mat-header-cell *matHeaderCellDef>
      <mat-form-field floatLabel="never">
        <input [matDatepicker]="date" matInput [formControl]="effectiveDateFilter"
          placeholder="{{ 'TABLES.FILTERPLACEHOLDER.TODO'| translate  }}">
        <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
        <mat-datepicker #date></mat-datepicker>
      </mat-form-field>
    </th>
  </ng-container>
  <ng-container *ngIf="isHighRole" matColumnDef="advisorFilter">
    <th mat-header-cell *matHeaderCellDef>
      <mat-form-field floatLabel="never">
        <input matInput [formControl]="advisorFilter"
          placeholder="{{ 'TABLES.FILTERPLACEHOLDER.ADVISOR' | translate }}">
      </mat-form-field>
    </th>
  </ng-container>
  <ng-container matColumnDef="filterRemove">
    <th mat-header-cell *matHeaderCellDef (click)="changeFilterView()">
      <div class="neomp-table-column-filter-element mdi mdi-close"></div>
    </th>
  </ng-container>
  <!--end of  Filter row definition-->
  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container matColumnDef="expandedDetail">
    <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length"
      class="{{element === expandedElement ? 'element-row-detail-content-expanded':''}}">
      <div class="element-row-expanded-element-without-flex"
        [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">

        <div class="padding-search-fields">
          <div class="search-fields-layout-grid">
            <div class="msg-helper-margin-bottom-10">
              <mat-form-field class="neomp-full-width">
                <mat-label>{{ 'PAGE.LEADS.LEAD.TEXT' | translate }}</mat-label>
                <input matInput [(ngModel)]="searchText" />
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="padding-search-fields">
          <div class="search-fields-layout-grid margin-top-15">
            <button class="msg-helper-width-100 button-text" color="primary" (click)="filterProductsTable()" mat-raised-button>
              <span class="material-icons">
                search
              </span>
              {{ 'PAGE.LEADS.LEAD.SEARCH' | translate }}
            </button>
            <button mat-raised-button class="clear-button msg-helper-width-100 button-text"
              (click)="clearFilterProductsTable()">
              <span class="material-icons">clear</span>{{ 'PAGE.LEADS.LEAD.CLEAR' | translate }}
            </button>
          </div>
        </div>

        <div class="padding-search-fields">
          <div class="mdc-layout-grid__inner msg-helper-grid-gap-horizontal">
            <div
              class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-3-tablet mdc-layout-grid__cell--span-12-phone neo-padding-top-10">
              <div class="msg-helper-margin-bottom-10">
                <button [disabled]="selection.selected.length > 0 || isSupplier" class="msg-helper-width-100" color="primary"
                  (click)="toLead(expandedElement)" mat-raised-button>{{ 'TABLES.BUTTONS.TO_LEAD' | translate
                  }}</button>
              </div>
            </div>
            <div
              class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-3-tablet mdc-layout-grid__cell--span-12-phone neo-padding-top-10">
              <div class="msg-helper-margin-bottom-10">
                <button [disabled]="selection.selected.length === 0 || isSupplier" class="msg-helper-width-100" color="primary"
                  (click)="linkLead(expandedElement)" mat-raised-button>{{ 'TABLES.BUTTONS.LINK_LEAD' | translate
                  }}</button>
              </div>
            </div>
            <div *ngIf="!completedTasksVisible"
              class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-3-tablet mdc-layout-grid__cell--span-12-phone neo-padding-top-10">
              <div class="msg-helper-margin-bottom-10">
                <button [disabled]="selection.selected.length > 0 || isSupplier" class="msg-helper-width-100" color="primary"
                  (click)="markTaskAsCompleted(expandedElement)" mat-raised-button>{{ 'TABLES.BUTTONS.CLOSE_TASK' |
                  translate }}</button>
              </div>
            </div>
          </div>
        </div>

        <!-- PRODUCTS TABLE -->
        <div class="mat-elevation-z8 products-table" [ngClass]="{'table-container': productsList.data.length > 0}">
          <table mat-table [dataSource]="productsList">
  
            <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
  
              <th mat-header-cell *matHeaderCellDef class="check-filter-w">
                <mat-checkbox class="all-checkbox" (change)="$event ? toggleAllRows() : null"
                  [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                  [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                </mat-checkbox>
              </td>
  
            </ng-container>
  
            <!-- Uuid Column -->
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef> Id </th>
              <td mat-cell *matCellDef="let element"> {{element.uuid}} </td>
            </ng-container>
  
            <!-- Customer Column -->
            <ng-container matColumnDef="customer">
              <th mat-header-cell *matHeaderCellDef> Kunde </th>
              <td mat-cell *matCellDef="let element"> {{element.customerFullName}} </td>
            </ng-container>
  
            <!-- Birthdate Column -->
            <ng-container matColumnDef="birthdate">
              <th mat-header-cell *matHeaderCellDef> Birthdate </th>
              <td mat-cell *matCellDef="let element"> {{element.birthdate | date: 'dd.MM.yyyy' }} </td>
            </ng-container>
  
            <!-- Gender Column -->
            <ng-container matColumnDef="gender">
              <th mat-header-cell *matHeaderCellDef> Gender </th>
              <td mat-cell *matCellDef="let element"> {{element.gender}} </td>
            </ng-container>
  
            <!-- Art Column -->
            <ng-container matColumnDef="art">
              <th mat-header-cell *matHeaderCellDef> Art </th>
              <td mat-cell *matCellDef="let element"> {{element.art}} </td>
            </ng-container>
  
            <!-- Status -->
            <ng-container matColumnDef="pbstatus">
              <th mat-header-cell *matHeaderCellDef> Status </th>
              <td mat-cell *matCellDef="let element"> {{element.pbstatus}} </td>
            </ng-container>
  
            <tr class="task-product-table-header" mat-header-row *matHeaderRowDef="pBDisplayColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: pBDisplayColumns;" (click)="selection.toggle(row)">
            </tr>
          </table>
        </div>

      </div>
    </td>
  </ng-container>
  <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
  <tr *matHeaderRowDef="displayedColumnFilters"
    class="neomp-table-row-filter {{showFilter? '': 'neomp-table-row-filter-hide'}}" mat-header-row></tr>
  <tr mat-row *matRowDef="let elementRow; columns: displayedColumns, let i = dataIndex;"
    class="element-row container-table-row" [class.element-row-expanded]="expandedElement === elementRow"
    [class.neomp-table-row-selected]="expandedElement !== elementRow" (click)="elementRowClick(elementRow)"> test </tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="element-row-detail-content"></tr>
</table>
<neomp-paginator [length]="totalTasks" [pageSize]="pageSize" [pageIndex]="pageIndex" [pageSizeOptions]="pageSizeOptions"
  (paginatorChange)="taskTablePaginatorValueChanged($event)">
</neomp-paginator>