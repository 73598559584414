import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {CoreModule} from "../core/core.module";
import {MessagesPageComponent} from "./pages/messages-page.component";

@NgModule({
    declarations: [MessagesPageComponent],
    imports: [
        CommonModule,
        CoreModule
    ],
    exports: [MessagesPageComponent]
})
export class MessagesModule {
}
