<div class="mdc-layout-grid msg-helper-padding-none">
  <div class="mdc-layout-grid__inner msg-helper-grid-gap-horizontal">
    <div
      class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">

      <div class="mdc-layout-grid__cell
      mdc-layout-grid__cell--span-12-desktop
       mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-4-phone" *ngIf="managementView">
        <neomp-container [isLightContainer]="false" cssClass="msg-mp-container-frameless" headlineText="{{ 'MENU.ENTRIES.TERMINMANAGEMENT' | translate }}">
          <neomp-loading-data #loading></neomp-loading-data>
        </neomp-container>
      </div>

      <mat-card>
        <div (keyup.enter)="search()"
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-9-tablet mdc-layout-grid__cell--span-12-phone">
          <div class="search-fields-layout-grid">
            <div>
              <mat-form-field class="neomp-full-width">
                <mat-label>{{ 'PAGE.LEADS.LEAD.TEXT' | translate }}</mat-label>
                <input matInput [(ngModel)]="searchText" />
              </mat-form-field>
            </div>
            <div>
              <mat-form-field class="neomp-full-width">
                <mat-label>{{ 'PAGE.LEADS.TERMIN.FROM' | translate }}</mat-label>
                <input [matDatepicker]="From" matInput [(ngModel)]="dateFrom" />
                <mat-datepicker-toggle matSuffix [for]="From"></mat-datepicker-toggle>
                <mat-datepicker #From></mat-datepicker>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field class="neomp-full-width">
                <mat-label>{{ 'PAGE.LEADS.TERMIN.TO' | translate }}</mat-label>
                <input [matDatepicker]="To" matInput [(ngModel)]="dateTo" />
                <mat-datepicker-toggle matSuffix [for]="To"></mat-datepicker-toggle>
                <mat-datepicker #To></mat-datepicker>
              </mat-form-field>
            </div>
            <div *ngIf="isHighRole && selectedTabIndex === 1">
              <mat-form-field class="neomp-full-width">
                <mat-label id="berater-label">{{ 'PAGE.LEADS.LEAD.ADVISOR' | translate }}</mat-label>
                <mat-select [formControl]="agentSelectedControl">
                  <mat-option [value]="">
                    {{ 'PAGE.LEADS.LEAD.ALL' | translate }}
                  </mat-option>
                  <mat-option *ngFor="let agent of agents" [value]="agent.uuid">
                    {{agent.fullName && agent.fullName.split("Neosana Sales AG")[1] ? agent.fullName.split("Neosana Sales AG")[1] : agent.fullName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <!-- Hiding the search field when the tab is  "NICHT ZUGEWIESENE TERMINE" -->
            <div *ngIf="isHighRole && selectedTabIndex !== 0">
              <mat-form-field class="neomp-full-width">
                <mat-label>StatusList</mat-label>
                <mat-select [formControl]="statusSelected" (selectionChange)="statusesSelected($event)" multiple>
                  <mat-option value="Neu">Neu</mat-option>
                  <mat-option *ngFor="let status of statusesList" [value]="status">{{status}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            
            <!-- Hiding the search field when the tab is  "NICHT ZUGEWIESENE TERMINE" -->
            <div *ngIf="isHighRole && selectedTabIndex !== 0">
              <mat-form-field class="neomp-full-width">
                <mat-label>Substatus List</mat-label>
                <mat-select [formControl]="substatusSelected" multiple>
                  <mat-optgroup *ngFor="let status of substatusesList" [label]="status.title" [disabled]="status.disabled">
                    <mat-option *ngFor="let substatus of status.value" [value]="substatus">{{substatus}}</mat-option>
                  </mat-optgroup>
                </mat-select>
              </mat-form-field>
            </div>
            
            <!-- Categories -->
            <div>
              <mat-form-field class="neomp-full-width">
                <mat-label>Categories</mat-label>
                <mat-select [(ngModel)]="lead_terminCategory" multiple>
                    <mat-option *ngFor="let category of unassignedLeadsCategories" [value]="category">{{category}}
                    </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <!-- Types -->
            <div>
              <mat-form-field class="neomp-full-width">
                <mat-label>Types</mat-label>
                <mat-select [(ngModel)]="lead_terminType" multiple>
                    <mat-option *ngFor="let type of unassignedLeadsTypes" [value]="type">{{type}}
                    </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <!-- Buttons -->
          <div class="search-fields-layout-grid margin-top-15">
            <div class="neo-padding-top-10">
              <button mat-raised-button class="neomp-full-width button-text" color="primary" (click)="search()">
                <span class="material-icons">
                  search
                </span>{{ 'PAGE.LEADS.TERMIN.SEARCH' | translate }}
              </button>
            </div>
            
            <div class="neo-padding-top-10">
              <button mat-raised-button class="clear-button neomp-full-width button-text" (click)="clearSearch()">
                <span class="material-icons">clear</span>{{ 'PAGE.LEADS.LEAD.CLEAR' | translate }}
              </button>
            </div>
          </div>

        </div>
      </mat-card>
    </div>
    <br />
    <div
      class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone neo-margin-top"
      *ngIf="managementView">
      <mat-card>
        <div
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-9-tablet mdc-layout-grid__cell--span-12-phone">
          <div>
            <h1>{{ 'PAGE.LEADS.TERMIN.MULTIASSIGNTITLE' | translate }}</h1>
          </div>
          <div class="mdc-layout-grid__inner">
            <div *ngIf="isHighRole" class="mdc-layout-grid__cell--span-3">
              <mat-form-field class="neomp-full-width">
                <mat-label id="berater-label">{{ 'PAGE.LEADS.LEAD.ADVISOR' | translate }}</mat-label>
                <mat-select [formControl]="agentAssignSelectedControl">
                  <mat-option *ngFor="let agent of agents" [value]="agent">
                    {{agent.fullName && agent.fullName.split("Neosana Sales AG")[1] ?
                    agent.fullName.split("Neosana Sales AG")[1] : agent.fullName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="mdc-layout-grid__cell--span-2">
              <div class="neo-padding-top-10">
                <button mat-raised-button [disabled]="
              selectedLeads.length === 0 
              ||
              agentAssignSelectedControl.invalid
              ||
              isLoading
              " color="primary" (click)="assignLeadsToAgent()">
                  {{ 'DIALOG.BUTTON.SAVE' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </mat-card>
    </div>

    <br />
    <div
      class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
      <neomp-container cssClass="msg-mp-container-frameless" headlineText="{{
        'MENU.LEADS-NAV.APPOINTMENT' | translate 
      }}" *ngIf="!managementView">
      </neomp-container>
        <mat-tab-group class="neo-border-none neo-label neo-lead-tab" [ngClass]="{'neo-bg-white' : !managementView, 'neo-lead-tab' : !managementView}" [selectedIndex]="selectedTabIndex"
          (selectedIndexChange)="terminTabChanged($event)">
          <mat-tab>
            <ng-template mat-tab-label>
           {{ 'PAGE.LEADS.TERMIN.UNASSIGNED-APPOINTMENT' | translate }}
           <span class="appointment-management-badge" *ngIf="totalCountUnassignedAppointments != 0"> 
            {{ totalCountUnassignedAppointments }} 
          </span>
            </ng-template>
          <!-- <mat-tab *ngIf="managementView" label="{{ 'PAGE.LEADS.TERMIN.UNASSIGNED-APPOINTMENT' | translate }}"> -->
            <!-- NEED TO ADD TRANSLATION FOR UNASSIGNED TERMINES -->
            <div class="mdc-layout-grid msg-helper-padding-none " [ngClass]="{'msg-helper-margin-top-10' : managementView}">
              <div class="mdc-layout-grid__inner">
                <div
                  class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">

                  <neomp-termine-table [tableEntries]="unassignedAppointments" (resetData)="resetFunction($event)"
                    [leads]="unassignedAppointments" [agents]="agents" [managementView]="managementView"
                    (selectedRows)="selectedRows($event)"></neomp-termine-table>
                  <neomp-loading-data #loadingFamilyTable></neomp-loading-data>
                  <neomp-paginator [length]="totalCountUnassignedAppointments" [pageSize]="pageSize" [pageIndex]="pageIndex"
                    [pageSizeOptions]="pageSizeOptions" (paginatorChange)="paginatorValueChanged($event)">
                  </neomp-paginator>
                </div>
              </div>
            </div>
          </mat-tab>

          <mat-tab label="{{ 'PAGE.LEADS.TERMIN.APPOINTMENT' | translate }}" class="termine-tab">
            <div class="mdc-layout-grid msg-helper-padding-none" [ngClass]="{'msg-helper-margin-top-10' : managementView}">
              <div class="mdc-layout-grid__inner">
                <div
                  class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
                  <neomp-termine-table [tableEntries]="appointments" (resetData)="resetFunction($event)" [leads]="appointments"
                    [agents]="agents" [managementView]="managementView"
                    (selectedRows)="selectedRows($event)"></neomp-termine-table>
                  <neomp-loading-data #loadingFamilyTable></neomp-loading-data>
                  <neomp-paginator [length]="totalCountAppointments" [pageSize]="pageSize" [pageIndex]="pageIndex"
                    [pageSizeOptions]="pageSizeOptions" (paginatorChange)="paginatorValueChanged($event)">
                  </neomp-paginator>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab *ngIf="managementView" label="{{ 'PAGE.LEADS.TERMIN.RETURNED-TERMIN' | translate }}" class="termine-tab">
            <div class="mdc-layout-grid msg-helper-padding-none" [ngClass]="{'msg-helper-margin-top-10' : managementView}">
              <div class="mdc-layout-grid__inner">
                <div
                  class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
                  <neomp-termine-table [tableEntries]="returnedAppointments" (resetData)="resetFunction($event)"
                    [leads]="returnedAppointments" [agents]="agents" [managementView]="managementView"
                    (selectedRows)="selectedRows($event)"></neomp-termine-table>
                  <neomp-loading-data #loadingFamilyTable></neomp-loading-data>
                  <neomp-paginator [length]="totalCountReturnedAppointments" [pageSize]="pageSize" [pageIndex]="pageIndex"
                    [pageSizeOptions]="pageSizeOptions" (paginatorChange)="paginatorValueChanged($event)">
                  </neomp-paginator>
                </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
    </div>
  </div>
</div>