import {
  AfterContentInit,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { IMessage } from '../../../interfaces/Imessage';
import { ActivatedRoute, Router } from '@angular/router';
import { IAgent } from '../../../interfaces/Iagent';
import { IRoom } from '../../../interfaces/Iroom';
import { LivechatService } from '../../livechat.service';
import { BehaviorSubject } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'app-agent-chat',
  templateUrl: './agent-chat.component.html',
  styleUrls: ['./agent-chat.component.scss'],
})
export class AgentChatComponent implements OnInit, OnDestroy, AfterContentInit {
  sentImg = 'assets/images/read1arrow.svg';
  deliveredImg = 'assets/images/delivered.svg';
  readImg = 'assets/images/read.svg';
  archiveNotHover = 'assets/images/Admin view pages/archive blue.svg';
  archiveHover = 'assets/images/Admin view pages/archive blue hover.svg';
  deliveredStatusImg: string | null = null;
  archiveImg = this.archiveNotHover;
  _isActivating = false;

  dropdownValue!: any;

  message: string = '';
  messages: IMessage[] = new Array();
  agent: IAgent | null = null;
  roomId = new BehaviorSubject<string>('null');
  listOfAgents!: any[];

  room$ = new BehaviorSubject<IRoom | null>(null);
  room!: IRoom;
  archivable: boolean = true;

  @ViewChild('chatBody') chatBody!: ElementRef;

  @HostListener('document:visibilitychange', ['$event'])
  visibilitychange() { this.changeDocumentView(); }

  constructor(
    private supportChat: LivechatService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.params.subscribe((id) => {
      this.roomId.next(id['id']);
      this.room$.next(this.supportChat.getRoom(this.roomId.value)!);
      if (this.supportChat.connected$.value) this.supportChat.emit('reading', { roomId: this.roomId.value });
    });
  }
  ngOnInit() {
    if(this.chatBody) this.chatBody.nativeElement.scrollTop = this.chatBody.nativeElement.scrollHeight;

    this.archiveImg = this.archiveNotHover;

    this.supportChat.archivable.subscribe((value) => {
      this.archivable = value;
    });

    this.supportChat.agent$.subscribe((value) => {
      this.agent = value;
    });

    this.supportChat.getListOfAgents().subscribe((value: any) => {
      this.listOfAgents = value.agents;
      this.dropdownValue = this.listOfAgents[0];
    })

    this.room$.subscribe((value: any) => {
      this.room = value;
      if(this.room) this.room.status ? this.router.navigate(['livechat/chats', this.room._id]) : this.router.navigate(['livechat/archives', this.room._id]);
    })

    this.supportChat.connected$.subscribe((valid) => {
      if (valid) {
        if(!this.supportChat.hasListener('message-reading')) {
          this.supportChat.listen('message-reading').subscribe((payout: any) => {
            if (!document.hidden && this.room._id === payout.roomId) {
              this.supportChat.noNewMessages(this.room._id);
              this.supportChat.emit('reading', { roomId: payout.roomId });
            }
          });
        }
      }
    });
  }

  ngAfterContentInit(): void {
    this.supportChat.rooms$.subscribe((value) => {
      if (!value) return
      this.room = this.supportChat.getRoom(this.roomId.value)!;
      setTimeout(() => {
        if(this.chatBody){
          this.chatBody.nativeElement.scrollTop =
          this.chatBody.nativeElement.scrollHeight;
        }
      }, 10);
    });
    this.roomId.subscribe((id) => { this.room = this.supportChat.getRoom(id)!; });
  }

  sendMessage(message: string) {
    if (message !== '') this.supportChat.sendMessage(message, this.room._id);
    this.message = '';
  }

  archiveChat(room: IRoom) {
    this.supportChat.updateRoomStatus(room, !this.archivable).subscribe((payout: any) => {
      this.supportChat.emit('room-status', { room: payout.room._id});
      this.archivable ? this.router.navigate(['livechat/chats']) : this.router.navigate(['livechat/chats', room._id]);
      this.supportChat.archivable.next(true);
    })
  }

  messageDateDecider(message: IMessage, index: number) {
    let messageTime = moment(message.date).format('HH:mm');
    if (this.room.messages[index + 1] && JSON.stringify(message.sentBy) === JSON.stringify(this.room.messages[index + 1].sentBy)) {
      let nextMessageTime = moment(this.room.messages[index + 1].date).format('HH:mm');
      if (nextMessageTime !== messageTime) return true;
      else return false;
    } else return true;
  }

  setDeliveredStatusImg() {
    this.deliveredStatusImg = this.readImg;
    return this.deliveredStatusImg;
  }

  changeDocumentView() {
    if (!document.hidden && this.room) {
      this.supportChat.noNewMessages(this.room._id);
      this.supportChat.emit('reading', { roomId: this.room._id });
    }
  }

  activateChat() {
    this._isActivating = true;
    this.supportChat
      .activateChat(this.roomId.value, this.dropdownValue)
      .subscribe((value: any) => {
        this.supportChat.emit('chat-activated', { agentId: this.dropdownValue.agentId, roomId: value.room._id, clientEmail: value.room.clientEmail });
        if (this.agent) this.supportChat.getAgentRoomsObs(this.agent).subscribe((payout: any) => {
          this.supportChat.rooms$.next(payout.rooms);
          this._isActivating = false;
        });
      });
  }

  ngOnDestroy() {
    if(this.supportChat.connected$.value) this.supportChat.removeListener('message-reading');
  }
}
