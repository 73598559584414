<div class="mdc-layout-grid msg-helper-padding-10">
  <div class="mdc-layout-grid__inner msg-helper-grid-gap-horizontal">
    <div
      class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
      <form [formGroup]="leadForm">
        <div class="mdc-layout-grid__inner">
          <div
            class="mdc-layout-grid__cell--span-10 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-12-tablet">
            <section class="neo-color neo-padding-top-10">
              <div class="mdc-layout-grid__cell--span-12 mdc-layout-grid__cell--span-12-phone">
                <div class="mdc-layout-grid__inner">
                  <div
                    class="mdc-layout-grid__cell--span-3 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                    <!-- Source autocomplete -->
                    <mat-form-field appearance="fill" class="neo-leads-input-full-width">
                      <mat-label>{{'PAGE.LEADS.LEAD.LEAD-EDIT.SOURCE' | translate}}</mat-label>
                      <input type="text" matInput formControlName="lead_typ" [matAutocomplete]="sourceAuto" />
                      <mat-autocomplete autoActiveFirstOption #sourceAuto="matAutocomplete">
                        <mat-option *ngFor="let s of filteredSources | async" [value]="s.name">
                          {{ s.name }}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>

                  <!-- Category autocomplete -->

                  <div
                    class="mdc-layout-grid__cell--span-3 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                    <mat-form-field appearance="fill" class="neo-leads-input-full-width">
                      <mat-label> {{'PAGE.LEADS.LEAD.LEAD-EDIT.CATEGORY' | translate}} </mat-label>
                      <input type="text" matInput formControlName="lead_kategory" [matAutocomplete]="categoryAuto" />
                      <mat-autocomplete autoActiveFirstOption #categoryAuto="matAutocomplete">
                        <mat-option *ngFor="let c of filteredCategories | async" [value]="c.name">
                          {{ c.name }}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>

                  <!-- Status -->

                  <div class="mdc-layout-grid__cell--span-3 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                  
                    <mat-form-field appearance="fill" class="neo-leads-input-full-width">
                      <mat-label>{{'PAGE.LEADS.LEAD.LEAD-EDIT.STATUS' | translate}}</mat-label>
                    <input class="font-family" matInput type="text" formControlName="lead_status" />

                    </mat-form-field>
                  
                  </div>
                  <div
                  class="mdc-layout-grid__cell--span-3 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet msg-helper-padding-none msg-helper-margin-top-15">
                  <button color="primary" appearance="fill" class="neo-leads-input-full-width" mat-raised-button
                    (click)="setStatusDialog()"> {{'PAGE.LEADS.LEAD.LEAD-EDIT.SAVE-STATUS' | translate}} </button>
                  </div>
                  <!-- A3N Removed meeting 20.05.2022 
                  https://i-solve.atlassian.net/browse/NEOP-30?atlOrigin=eyJpIjoiN2EzZmM3MTllMjZiNDkyNTgxOGY1NWM0NTUyMmNlOTMiLCJwIjoiamlyYS1zbGFjay1pbnQifQ  -->

                  <!-- Vertmittler autocomplete -->
                  <!-- <div
                    class="mdc-layout-grid__cell--span-3 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet"
                  >
                    <mat-form-field
                      appearance="fill"
                      class="neo-leads-input-full-width"
                    >
                      <mat-label> Vertmittler </mat-label>
                      <input
                        type="text"
                        matInput
                        formControlName="agent"
                        [matAutocomplete]="agentAuto"
                      />
                      <mat-autocomplete
                        autoActiveFirstOption
                        #agentAuto="matAutocomplete"
                      >
                        <mat-option
                          *ngFor="let a of filteredAgents | async"
                          [disabled]="a.id === -1"
                          [value]="a.name"
                        >
                          {{ a.name }}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div> -->
                </div>
              </div>
            </section>
          </div>
        </div>

        <div class="mdc-layout-grid__cell--span-2 mdc-layout-grid__cell--span-2-phone"></div>
        <br />
        <neomp-container cssClass="msg-mp-container-frameless" headlineText="{{'PAGE.LEADS.LEAD.LEAD-EDIT.LEAD-INFO' | translate}}">
          <div
            class="mdc-layout-grid mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone msg-helper-padding-none neo-color neo-space-padding">
            <section>
              <div class="mdc-layout-grid__inner">
                <div
                  class="mdc-layout-grid__cell--span-3 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                  <mat-form-field appearance="fill" class="neo-leads-input-full-width">
                    <mat-label>{{'PAGE.LEADS.LEAD.LEAD-EDIT.ANREDE' | translate}}</mat-label>
                    <mat-select formControlName="salutation">
                      <mat-option *ngFor="let s of leadConfigs.SALUTATION" [value]="s.key">
                        {{ s.value }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div
                  class="mdc-layout-grid__cell--span-3 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                  <mat-form-field appearance="fill" class="neo-leads-input-full-width">
                    <mat-label>{{'PAGE.LEADS.LEAD.LEAD-EDIT.LANGUAGE' | translate}}</mat-label>
                    <mat-select formControlName="language">
                      <mat-option *ngFor="let lang of leadConfigs.LANGUAGE" [value]="lang.value">
                        {{ lang.value }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div
                  class="mdc-layout-grid__cell--span-3 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                  <mat-form-field class="neo-leads-input-full-width">
                    <mat-label>{{'PAGE.LEADS.LEAD.LEAD-EDIT.PERSON' | translate}} </mat-label>
                    <input class="font-family" matInput type="text" formControlName="lead_personen" />
                  </mat-form-field>
                </div>

                <!-- A3N Removed meeting 20.05.2022 
                  https://i-solve.atlassian.net/browse/NEOP-30?atlOrigin=eyJpIjoiN2EzZmM3MTllMjZiNDkyNTgxOGY1NWM0NTUyMmNlOTMiLCJwIjoiamlyYS1zbGFjay1pbnQifQ  -->
                <!--  <div
                  class="mdc-layout-grid__cell--span-3 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet"
                >
              
                 <div
                    class="mdc-layout-grid__cell--span-2 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                    <mat-form-field appearance="fill" class="neo-leads-input-full-width">
                      <mat-label>Aktuelle KK </mat-label>
                      <mat-select formControlName="aktuelle_kk">
                        <mat-option *ngFor="let k of data?.Response?.insurance_company" [value]="k.id"> {{ k.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div> 
                </div> -->
              </div>
            </section>

            <section>
              <div class="mdc-layout-grid__inner">
                <!-- NAME -->
                <div
                  class="mdc-layout-grid__cell--span-4 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                  <mat-form-field class="neo-leads-input-full-width">
                    <mat-label>{{'PAGE.LEADS.LEAD.LEAD-EDIT.NAME' | translate}} </mat-label>
                    <input class="font-family" matInput type="text" formControlName="name" />
                  </mat-form-field>
                </div>

                <!-- SURNAME -->
                <div
                  class="mdc-layout-grid__cell--span-4 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                  <mat-form-field class="neo-leads-input-full-width">
                    <mat-label>{{'PAGE.LEADS.LEAD.LEAD-EDIT.SURNAME' | translate}} </mat-label>
                    <input class="font-family" matInput type="text" formControlName="firstName" />
                  </mat-form-field>
                </div>


                <!-- BIRTHDATE -->
                <div
                  class="mdc-layout-grid__cell--span-4 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                  <mat-form-field class="neo-leads-input-full-width">
                    <mat-label>{{'PAGE.LEADS.LEAD.LEAD-EDIT.BIRTHDATE' | translate}}</mat-label>
                    <input [matDatepicker]="birthdate" matInput formControlName="birthday" />
                    <mat-datepicker-toggle matSuffix [for]="birthdate"></mat-datepicker-toggle>
                    <mat-datepicker #birthdate></mat-datepicker>
                  </mat-form-field>
                </div>



              </div>
            </section>



            <section>
              <div class="mdc-layout-grid__inner">

                <!-- TELEFON Business -->
                <div
                  class="mdc-layout-grid__cell--span-3 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                  <mat-form-field class="neo-leads-input-full-width">
                    <mat-label>{{'PAGE.LEADS.LEAD.LEAD-EDIT.PHONE-BUSINESS' | translate}}</mat-label>
                    <input class="font-family" matInput type="text" formControlName="phone1" />
                  </mat-form-field>
                </div>

                <!-- TELEFON Private -->
                <div
                  class="mdc-layout-grid__cell--span-3 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                  <mat-form-field class="neo-leads-input-full-width">
                    <mat-label>{{'PAGE.LEADS.LEAD.LEAD-EDIT.PHONE-PRIVATE' | translate}}</mat-label>
                    <input class="font-family" matInput type="text" formControlName="phone2" />
                  </mat-form-field>
                </div>

                <!-- EMAIL Business  -->
                <div
                  class="mdc-layout-grid__cell--span-3 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                  <mat-form-field class="neo-leads-input-full-width">
                    <mat-label>{{'PAGE.LEADS.LEAD.LEAD-EDIT.EMAIL-BUSINESS' | translate}} </mat-label>
                    <input class="font-family" matInput type="text" formControlName="email1" />
                  </mat-form-field>
                </div>
                <!-- EMAIL Private -->
                <div
                  class="mdc-layout-grid__cell--span-3 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                  <mat-form-field class="neo-leads-input-full-width">
                    <mat-label>{{'PAGE.LEADS.LEAD.LEAD-EDIT.EMAIL-PRIVATE' | translate}}</mat-label>
                    <input class="font-family" matInput type="text" formControlName="email2" />
                  </mat-form-field>
                </div>
              </div>
            </section>



            <section>
              <div class="mdc-layout-grid__inner">
                <!-- STREET -->
                <div
                  class="mdc-layout-grid__cell--span-3 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                  <mat-form-field class="neo-leads-input-full-width">
                    <mat-label>{{'PAGE.LEADS.LEAD.LEAD-EDIT.STR' | translate}}</mat-label>
                    <input class="font-family" matInput type="text" formControlName="address" />
                  </mat-form-field>
                </div>

                <!-- POSTAL CODE -->
                <div
                  class="mdc-layout-grid__cell--span-3 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                  <mat-form-field class="neo-leads-input-full-width">
                    <mat-label>{{'PAGE.LEADS.LEAD.LEAD-EDIT.ZIP' | translate}}</mat-label>
                    <input class="font-family" matInput type="text" formControlName="zip" />
                  </mat-form-field>

                  <!-- A3N AUTOCOMPLETE DEPRECATED -->
                  <!-- <mat-form-field appearance="fill" class="neo-leads-input-full-width">
                    <mat-label> PLZ </mat-label>
                    <input type="text"
                           matInput
                           formControlName="zip"
                           [matAutocomplete]="regionAuto">
                    <mat-autocomplete autoActiveFirstOption #regionAuto="matAutocomplete"
                    (optionSelected)="onRegionChange($event)"
                    [displayWith]="getOptionText">
                      <mat-option 
                      *ngFor="let r of filteredRegions | async"
                      [value]="r">
                        {{ r.PLZ + ' ' + r.Ort  }} 
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field> -->
                </div>

                <!-- ORT -->
                <div
                  class="mdc-layout-grid__cell--span-3 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                  <mat-form-field class="neo-leads-input-full-width">
                    <mat-label>{{'PAGE.LEADS.LEAD.LEAD-EDIT.CITY' | translate}}</mat-label>
                    <input class="font-family" matInput type="text" formControlName="ort" />
                  </mat-form-field>
                </div>

                <!-- KANTON -->
                <div
                  class="mdc-layout-grid__cell--span-3 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                  <mat-form-field class="neo-leads-input-full-width">
                    <mat-label>{{'PAGE.LEADS.LEAD.LEAD-EDIT.KANTON' | translate}}</mat-label>
                    <input class="font-family" matInput type="text" formControlName="kanton" />
                  </mat-form-field>
                </div>

              </div>
            </section>
            <section>
              <div class="mdc-layout-grid__inner">
                <div class="mdc-layout-grid__cell--span-10">
                  <mat-form-field class="neo-leads-input-full-width">
                    <mat-label>{{'PAGE.LEADS.LEAD.LEAD-EDIT.COMMENT' | translate}}</mat-label>
                    <textarea matInput formControlName="lead_extradata"></textarea>
                  </mat-form-field>
                </div>
              </div>
            </section>
          </div>
        </neomp-container>
      </form>
      <br />
      <div class="mdc-layout-grid__inner">
        <div
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone neo-padding-top-10">
          <neomp-container cssClass="msg-mp-container-frameless" headlineText="{{'PAGE.LEADS.LEAD.LEAD-EDIT.REMINDER' | translate}}">
            <div
              class="mdc-layout-grid mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone msg-helper-padding-none neo-color neo-space-padding">
              <div class="mdc-layout-grid__inner">
                <div
                  class="mdc-layout-grid__cell--span-10 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-12-tablet">
                  <section [formGroup]="reminderForm">
                    <div class="mdc-layout-grid__cell--span-12">
                      <div class="mdc-layout-grid__inner">
                        <div
                          class="mdc-layout-grid__cell--span-3 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">

                          <mat-form-field class="neo-leads-input-full-width">
                            <!-- TODO translate -->
                            <mat-label>{{ 'Kategorie' | translate }}</mat-label>
                            <mat-select formControlName="category">
                              <mat-option *ngFor="let option of reminderCategories" [value]="option">
                                {{ option.value }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>

                        </div>
                        <div
                          class="mdc-layout-grid__cell--span-3 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet"
                          *ngIf="displayReminderInputs.showDateInput">
                          <mat-form-field class="neo-leads-input-full-width">
                            <mat-label>{{'PAGE.LEADS.LEAD.LEAD-EDIT.APPOINTMENT-DATE' | translate}}</mat-label>
                            <input [matDatepicker]="termindatum" matInput formControlName="date" />
                            <mat-datepicker-toggle matSuffix [for]="termindatum"></mat-datepicker-toggle>
                            <mat-datepicker #termindatum></mat-datepicker>
                          </mat-form-field>
                        </div>
                        <div
                          class="mdc-layout-grid__cell--span-2 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet"
                          *ngIf="displayReminderInputs.showTimeInput">
                          <mat-form-field class="neo-leads-input-full-width">
                            <mat-label>{{'PAGE.LEADS.LEAD.LEAD-EDIT.TIME' | translate}} </mat-label>
                            <input class="font-family" matInput type="time" formControlName="time" />
                          </mat-form-field>
                        </div>
                        <div
                          class="mdc-layout-grid__cell--span-4 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                          <mat-form-field class="neo-leads-input-full-width">
                            <mat-label>{{'PAGE.LEADS.LEAD.LEAD-EDIT.COMMENT' | translate}} </mat-label>
                            <input class="font-family" matInput type="text" formControlName="description" />
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
                <div
                  class="mdc-layout-grid__cell--span-2 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-12-tablet">
                  <div class="neo-padding-top-10">
                    <button [disabled]="reminderForm.invalid" class="neo-float-right" mat-raised-button color="primary"
                      (click)="addReminder()">
                      + {{'PAGE.LEADS.LEAD.LEAD-EDIT.REMINDER' | translate}}
                    </button>
                  </div>
                </div>
                <div
                  class="mdc-layout-grid__cell--span-12 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-12-tablet"
                  *ngIf="remindersList.length > 0">
                  <h3> {{'PAGE.LEADS.LEAD.LEAD-EDIT.REMINDER' | translate}}: </h3>
                  <ul class="reminders" type="none">
                    <li *ngFor="let r of remindersList"> {{ r.agentName }}: {{r.description}}
                      <span class="datetime"> {{ r.date | date: 'dd.MM.yyyy' }} {{ r.time }}</span>
                    </li>
                  </ul>
                </div>

                <div
                  class="mdc-layout-grid__cell--span-12 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-12-tablet"
                  *ngIf="terminLeadsReminder.length > 0">
                  <h3>  {{'PAGE.LEADS.REMINDER.APPOINTMENT-LEADS-REMINDERS' | translate}}: </h3>
                  <ul class="reminders" type="none">
                    <li *ngFor="let t of terminLeadsReminder"> {{ t.agentName }}: {{t.description}}
                      <span class="datetime"> {{ t.date | date: 'dd.MM.yyyy' }} {{ t.time }}</span>
                    </li>
                  </ul>
                </div>

                <div
                  class="mdc-layout-grid__cell--span-12 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-12-tablet"
                  *ngIf="notesList.length > 0">
                  <h3> {{'PAGE.LEADS.LEAD.LEAD-EDIT.NOTES' | translate }}: </h3>
                  <ul class="reminders" type="none">
                    <li *ngFor="let n of notesList"> {{ n.agentName }}: {{n.description}} </li>
                  </ul>
                </div>


              </div>
            </div>
          </neomp-container>
        </div>
      </div>

      <!-- A3N Removed meeting 20.05.2022 
                  https://i-solve.atlassian.net/browse/NEOP-30?atlOrigin=eyJpIjoiN2EzZmM3MTllMjZiNDkyNTgxOGY1NWM0NTUyMmNlOTMiLCJwIjoiamlyYS1zbGFjay1pbnQifQ  -->
      <!-- <br />
      <section>
        <mat-form-field class="neo-leads-input-full-width">
          <mat-label>{{
            "PAGE.NEO-OFFER.OFFER.REMARKS" | translate
          }}</mat-label>
          <textarea matInput></textarea>
        </mat-form-field>
        <br />
        <mat-form-field class="neo-leads-input-full-width">
          <mat-label>{{
            "PAGE.NEO-OFFER.OFFER.REMARKS" | translate
          }}</mat-label>
          <textarea matInput></textarea>
        </mat-form-field>
      </section> -->


      <!-- <div class="neo-padding-top-30">
        <button class="neo-float-left" mat-raised-button color="primary">
          SENDEN
        </button>
        <div class="neo-padding-top-10">
          <button (click)="submitLeadForm()" class="neo-float-right" mat-raised-button color="lead">
            SPEICHERN
          </button>
        </div>
      </div> -->
    </div>
  </div>
</div>