<mat-tab-group>
    <mat-tab label="{{ 'PAGE.CONTAINER.OFFERS.TAB.OFFER' | translate}}">
    <div class="mdc-layout-grid msg-helper-padding-none msg-helper-margin-top-10">
        <div class="mdc-layout-grid__inner">
            <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop
                            mdc-layout-grid__cell--span-12-tablet
                            mdc-layout-grid__cell--span-12-phone">
                    <neomp-offer-wizard-old></neomp-offer-wizard-old>
            </div>
        </div>
    </div>
    </mat-tab>
    <mat-tab label="{{ 'PAGE.CONTAINER.OFFERS.TAB.REQUEST' | translate}}">
        <div class="mdc-layout-grid msg-helper-padding-none msg-helper-margin-top-10">
            <div class="mdc-layout-grid__inner">
                <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop
                            mdc-layout-grid__cell--span-12-tablet
                            mdc-layout-grid__cell--span-12-phone">
                    <neomp-created-requests-old></neomp-created-requests-old>
                </div>
            </div>
        </div>
    </mat-tab>
    <mat-tab label="{{ 'PAGE.CONTAINER.OFFERS.TAB.GMREQUEST' | translate}}">
        <div class="mdc-layout-grid msg-helper-padding-none msg-helper-margin-top-10">
            <div class="mdc-layout-grid__inner">
                <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop
                            mdc-layout-grid__cell--span-12-tablet
                            mdc-layout-grid__cell--span-12-phone">
                    <neomp-gm-created-offers-old></neomp-gm-created-offers-old>
                </div>
            </div>
        </div>
    </mat-tab>
</mat-tab-group>
