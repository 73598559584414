import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { JournalProvider } from "../../core/providers/journal.provider";
import { JournalList } from "../../core/model/journal-list";
import { LoadingDataComponent } from "../../core/components/loading-data.component";
import { Journal } from "../../core/model/journal";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { log } from "../../core/providers/logger.provider";
import { ActionData } from "../../core/model/action-data";
import { ActionDataProvider } from "../../core/providers/action-data.provider";
import { DocumentProvider } from "../../core/providers/document.provider";
import { Reference } from "../../core/model/reference";
import { SDAKey } from "../../core/model/sdakey";
import { TranslateService } from "@ngx-translate/core";
import { BusinessTransactionHelper } from "../../core/static/bt-helper";
import { UserCommitState } from "../../core/model/user-commit-state";
import { LoadingService } from "../../core/providers/loading.component";
import { SlideRangeFilterValue } from "../../core/model/slide-range-filter-value";
import { Observable, Subscription } from "rxjs";
import { JournalHelper } from "../../core/static/journal-helper";
import * as moment from "moment";
import { ActionDialogData } from "../../core/components/action.dialog.component";
import { ActionDialogService } from "../../core/providers/action-dialog.service";
import { PaginationConfigs } from "app/modules/constants/PaginationConstants";
import { MatDialog } from "@angular/material/dialog";
@Component({
    selector: "neomp-created-requests",
    templateUrl: "./created-requests.component.html",
    styleUrls: ["./created-requests.component.scss"]
})
export class CreatedRequestsComponent implements OnInit, AfterViewInit {
    private readonly TAG = this.constructor.name;
    applications: JournalList[] = [];
    @ViewChild("applicationsTable") applicationsTableLoadingComponent: LoadingDataComponent;
    @ViewChild("journalTable") journalTableLoadingComponent: LoadingDataComponent;
    selectedJournalRowIndex;
    selectedSpecialJournalRowIndex;
    dataSource: MatTableDataSource<JournalList>;
    specialJournalDataSource: MatTableDataSource<Journal>;
    @ViewChild(MatSort) sort: MatSort;
    sendData: Journal[] = [];
    headline;
    pageSize = PaginationConfigs.pageSize;
    pageSizeOptions = PaginationConfigs.pageSizeOptions;
    pageIndex = PaginationConfigs.pageIndex;
    pageSizeJournal = PaginationConfigs.pageSize;
    pageSizeOptionsJournal = PaginationConfigs.pageSizeOptions;
    pageIndexJournal = PaginationConfigs.pageIndex;
    totalCount = 0;
    journalData: JournalList[]= [];
    journalTotalCount: number;
    subscriptions:Subscription[] = [];

    private readonly CLASS_NAME = this.constructor.name;

    filterValue: SlideRangeFilterValue = new SlideRangeFilterValue();
    isInitialized: boolean;
    isDataLoading: boolean;
    isDialogOpen = false;
    processID: string;
    processIDs: string;
    constructor(public journalProvider: JournalProvider,
        public actionDataProvider: ActionDataProvider,
        public documentProvider: DocumentProvider,
        public translateService: TranslateService,
        public snackBar: MatSnackBar,
        public loadService: LoadingService,
        public dialogService: ActionDialogService,
        public dialog: MatDialog,
        public matSnackBar: MatSnackBar) {
        this.dataSource = new MatTableDataSource(this.applications);
        this.specialJournalDataSource = new MatTableDataSource([]);
    }

    ngOnInit(): void {
        this.translateService.get("COMMONS.LOADINGSTATUS.NODATA")
        .subscribe(value => {
            this.headline = value;
        });
        this.filterValue.isActive = true;
        this.filterValue.dateRange.end = moment();
        this.filterValue.dateRange.begin = moment().subtract(60, "days");

        this.getElements(this.filterValue);
    }

    ngAfterViewInit(): void {
        this.isInitialized = true;
    }

    isSendData(specialJournal: Journal) {
        return this.sendData.findIndex(value => value.uuid === specialJournal.uuid) !== -1;
    }

    getElements(filterValue) {

        this.dataLoading = true;
        if (this.journalData.length > 0) {
            this.journalData = [];
        }
        const sort = { creationDate: -1 };
        const journalsObservable: Observable<{ data: JournalList[], totalCount: number }> = filterValue.isActive ?
            this.journalProvider.getJournalsReferencedByDateRangeWithPagination(this.applicationsTableLoadingComponent,
                [BusinessTransactionHelper.typeId.offer.from, BusinessTransactionHelper.typeId.offerCreate.from],
                [BusinessTransactionHelper.typeId.offer.to, BusinessTransactionHelper.typeId.offerCreate.to],
                filterValue.dateRange,
                this.pageIndex,
                this.pageSize,
                sort) :
            this.journalProvider.getJournalsReferencedWithPagination(this.applicationsTableLoadingComponent,
                [BusinessTransactionHelper.typeId.offer.from, BusinessTransactionHelper.typeId.offerCreate.from],
                [BusinessTransactionHelper.typeId.offer.to, BusinessTransactionHelper.typeId.offerCreate.to],
                this.pageIndex,
                this.pageSize,
                sort);

        this.headline = this.translateService.instant("COMMONS.LOADINGSTATUS.NODATA");
        journalsObservable.subscribe(journals => {
            this.applications = journals.data.reverse();
            this.totalCount = journals.totalCount;
            this.dataSource.data = this.applications;
            this.specialJournalDataSource.data = [];
            this.selectedJournalRowIndex = null;
            this.dataLoading = false;
        }, error => {
            this.dataLoading = false;
        });
    }

    getElementsJournal(filterValue) {
        this.dataLoading = true;
        const sort = { creationDate: -1 };
        const journalsObservable: Observable<{ data: JournalList[], totalCount: number }> = filterValue.isActive ?
            this.journalProvider.getJournalsReferencedByDateRangeWithPagination(this.journalTableLoadingComponent,
                BusinessTransactionHelper.typeId.offerCreated.from,
                BusinessTransactionHelper.typeId.offerCreated.to,
                filterValue.dateRange,
                this.pageIndexJournal,
                this.pageSizeJournal,
                sort,
                null,
                this.processID,
                this.processIDs) :
            this.journalProvider.getJournalsReferencedWithPagination(this.journalTableLoadingComponent,
                BusinessTransactionHelper.typeId.offerCreated.from,
                BusinessTransactionHelper.typeId.offerCreated.to,
                this.pageIndexJournal,
                this.pageSizeJournal,
                sort,
                null,
                this.processID,
                this.processIDs);

        journalsObservable.subscribe(journals => {
            this.journalData = journals.data.reverse();
            this.journalTotalCount = journals.totalCount;
            this.selectedJournalRowIndex = null;
            this.dataLoading = false;
        }, error => {
            this.dataLoading = false;
        });

    }

    set dataLoading(dataLoading: boolean) {
        if (this.isInitialized) {
            this.isDataLoading = dataLoading;
        }
    }

    journalRowClick(journalRow: JournalList) {
        this.sendData = [];
        this.selectedJournalRowIndex = journalRow.transaction.uuid;

        if (this.processID) {
            this.processID = null;
        }

        const processIDs = journalRow.transactions.map(transaction => transaction.sorKeys.ProcessID);
        this.processIDs = processIDs.join(',');

        const selectedApplication = this.applications.find(
            value => value.transaction.uuid === this.selectedJournalRowIndex
        );

        if (selectedApplication && selectedApplication.transactions.length > 0) {
            this.specialJournalDataSource.data = selectedApplication.transactions.sort((a, b) => {
                return new Date(b.effectiveDate).getTime() - new Date(a.effectiveDate).getTime();
            });
        } else {
            this.specialJournalDataSource.data = [];
        }

        if (this.processIDs) {
            this.getElementsJournal(this.filterValue);
        } else {
            this.journalData = [];
            this.journalTotalCount = null;
        }

        this.headline = journalRow.transaction.reasonForChange || 'No data available';
    }

    existsDocument(journalRow: Journal): boolean {
        const result = JournalHelper.getSingleReferenceFromJournal(journalRow, "050");
        return result !== undefined && result !== null;
    }

    downloadDocument(event: Event, journalRow: Journal) {
        event.stopPropagation();
        const id = JournalHelper.getSingleReferenceFromJournal(journalRow, "050").id;
        if(id){
            this.documentProvider.downloadDocument(id);
        }
        else{
            const translatedMessage = this.translateService.instant('PAGE.CONTAINER.MESSAGES.ERROR-DOCUMENT');
            this.matSnackBar.open(translatedMessage, 'X', UserCommitState.configCheckData);
        }

    }



    sendOffers() {
        if (this.sendData.length > 0) {
            this.loadService.display(true);
            const actionData: ActionData = BusinessTransactionHelper.createQuotation();
            this.sendData.forEach(value => {
                const reference = new Reference();
                reference.id = value.uuid;
                reference.type = new SDAKey();
                reference.type.key = "050";
                reference.type.value = "Dokument";
                actionData.transaction.references.push(reference);
            });
            this.actionDataProvider.sendActionData(actionData)
                .toPromise()
                .then(value => {
                    log.debug(`${this.CLASS_NAME}: Response on sendActionData ${value}`);
                    this.loadService.display(false);
                    this.translateService.get("COMMONS.USERCOMMIT.SUCCESS")
                        .subscribe(result => {
                            this.snackBar.open(result, "x", UserCommitState.configSuccess);
                        });
                })
                .catch(reason => {
                    log.debug(`${this.CLASS_NAME}: Response on sendActionData [${reason}]`);
                    this.loadService.display(false);
                    this.translateService.get("COMMONS.USERCOMMIT.ERROR")
                        .subscribe(result => {
                            this.snackBar.open(result, "x", UserCommitState.configError);
                        });
                });
        } else {
            this.translateService.get("COMMONS.USERCOMMIT.NOSELECTION")
                .subscribe(result => {
                    this.snackBar.open(result, "x", UserCommitState.configCheckData);
                });
        }
    }

    openActionDialog(event: Event, element: Journal) {
        this.isDialogOpen = true;
        event.stopPropagation();
        const journalListEntry = this.applications.find(application =>
            application.transactions.some(trans => trans.uuid === element.uuid));
        const policy = journalListEntry.policies.find(p => journalListEntry.policyId === p.uuid);
        const dialogData: ActionDialogData = {
            transTypeId: BusinessTransactionHelper.typeId.offerCreated.from,
            transName: "OfferCreated",
            transSubject: "OfferCreated",
            transTitle: "OfferCreated",
            transMessage: "OfferCreated",
            operationalIdExternal: element.sorKeys["ProcessID"],
            keyValues: [],
            person: null,
            policy: policy
        };
        this.dialogService.openByType(dialogData,
            {
                defaultTypeId: BusinessTransactionHelper.typeId.offerCreated.from
            }, this.TAG
        );
        this.dialog.afterAllClosed.subscribe(() => {
            this.isDialogOpen = false;
        });
        this.subscriptions.push(
            this.dialogService.isSaved.subscribe(data =>{
                if(data){
                    this.getElementsJournal(this.filterValue);
                }
                this.subscriptions.forEach(subscription=> subscription.unsubscribe());
            })
        );
    }

    private changeCheckbox(specialJournal: Journal) {
        if (this.processIDs) {
            this.processIDs = null;
        }

        this.processID = specialJournal.sorKeys.ProcessID;
        this.getElementsJournal(this.filterValue);

        const journalIndex = this.sendData.findIndex(value => value.uuid === specialJournal.uuid);
        if (journalIndex === -1) {
            this.sendData.push(specialJournal);
        } else {
            this.sendData.splice(journalIndex, 1);
        }
    }
    paginatorValueChanged(event) {
        if (event.pageIndex !== this.pageIndex || event.pageSize !== this.pageSize) {
            this.pageIndex = event.pageIndex;
            this.pageSize = event.pageSize;
            this.getElements(this.filterValue);
        }
    }

    paginatorValueChangedJournal(event) {
        if (event.pageIndex !== this.pageIndexJournal || event.pageSize !== this.pageSizeJournal) {
            this.pageIndexJournal = event.pageIndex;
            this.pageSizeJournal = event.pageSize;
            this.getElementsJournal(this.filterValue);
        }
    }
}
