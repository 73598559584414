import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
@Component({
    selector: "neomp-container",
    templateUrl: "./container.component.html",
    styleUrls: ["./container.component.scss"]
})
export class ContainerComponent implements OnInit, OnChanges {
    @Input()
    headlineText: string;

    @Input()
    cssClass: string;

    @Input()
    isLightContainer = false;

    @Input()
    showHeadline = true;

    @Input()
    showActionIcon = false;

    @Input()
    editMode = false;

    @Input()
    editSection: SectionType;

    @Input() 
    formChanged: boolean = false; 

    @Output()
    edit: EventEmitter<{ editMode: boolean, editSection: SectionType }> = new EventEmitter();

    @Output()
    cancel: EventEmitter<{ editMode: boolean, editSection: SectionType }> = new EventEmitter();

    @Output() 
    navigateToEdit: EventEmitter<{ editMode: boolean, editSection: SectionType }> = new EventEmitter();

    @Output() 
    cancelDialogEvent: EventEmitter<void> = new EventEmitter<void>();

    constructor(public translate: TranslateService) {
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('editMode' in changes) {
            this.edit.emit(changes.editMode.currentValue);
        }
        if ('formChanged' in changes) {
            this.formChanged = changes.formChanged.currentValue ?  true : false;
        }
    }

    toggleEditMode(): void {
        if (this.editSection === 'CalendarAppointment') {
            this.editMode = true;
            this.edit.emit({ editMode: this.editMode, editSection: this.editSection });
        } else {
            this.editMode = !this.editMode;
            this.edit.emit({ editMode: this.editMode, editSection: this.editSection });
        }
    }

    cancelEditMode(): void {
        this.editMode = !this.editMode;
        this.cancel.emit({ editMode: this.editMode, editSection: this.editSection });
    }

    onNavigateToEdit() {
        this.navigateToEdit.emit();
    }

    cancelDialog(): void {
        this.cancelDialogEvent.emit();
    }

    onFormChanged(changed: boolean) {
        this.formChanged = changed;
    }
}

export type SectionType = 'PersonalData' | 'Appointment' | 'FamilyTable' | 'CalendarAppointment';