import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {CoreModule} from "../core/core.module";
import {SearchResultPageComponent} from "./pages/search-result-page.component";
import {ResultTableCustomerComponent} from "./components/result-table-customer.component";
import { ResultTableProductsComponent } from "./components/result-table-products.component";

@NgModule({
    declarations: [
        SearchResultPageComponent,
        ResultTableCustomerComponent,
        ResultTableProductsComponent
    ],
    imports: [
        CommonModule,
        CoreModule,
    ],
    exports: [
        SearchResultPageComponent,
        ResultTableCustomerComponent
    ]
})
export class SearchResultModule {
}
