import { Component } from "@angular/core";
import { FilterTableComponent } from "../../../core/components/tables/filter-table-component";
import { JournalList } from "../../../core/model/journal-list";
import { FormControl } from "@angular/forms";
import { Helper } from "../../../core/static/helper";
import { log } from "../../../core/providers/logger.provider";
import { KeyValuePair } from "../../../core/model/key-value-pair";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { RestProviderActionsDummy } from "../../../core/providers/rest.provider";
import { HouseholdProvider } from "../../../core/providers/household.provider";
import { RegisterProvider } from "../../../core/providers/register.provider";
import { Router } from "@angular/router";
import { ConfigProvider } from "../../../core/providers/config.provider";
import { JournalListJson } from "../../../core/json/journal-list.json";
import { BusinessTransactionHelper } from "../../../core/static/bt-helper";
import { ActionDialogData } from "../../../core/components/action.dialog.component";
import { ActionDialogService } from "../../../core/providers/action-dialog.service";
import { SlideRangeFilterValue } from "app/modules/core/model/slide-range-filter-value";
import { MatDialog } from "@angular/material/dialog";

@Component({
    selector: "neomp-provision-pending-matter-table",
    templateUrl: "./provision-pending-matter-table.component.html",
    styleUrls: ["./provision-pending-matter-table.component.scss"],
    animations: [
        trigger("detailExpand", [
            state("collapsed, void", style({ height: "0px", minHeight: "0", display: "none" })),
            state("expanded", style({ height: "*" })),
            transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")),
            transition("expanded <=> void", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)"))
        ]),
    ]
})
export class ProvisionPendingMatterTableComponent extends FilterTableComponent<JournalList> {

    private readonly TAG = this.constructor.name;

    selectedOpenIssuesRowIndex;
    isDialogOpen = false;

    expandedElement: JournalList | null;
    statusFilter = new FormControl ("");
    policyIdFilter = new FormControl("");
    inputChannelFilter = new FormControl("");
    statusNameFilter = new FormControl("");
    riskCarrierFilter = new FormControl("");
    sparteFilter = new FormControl("");
    customerNameFilter = new FormControl("");
    customerBirthdayFilter = new FormControl("");
    effectiveDateFilter = new FormControl("");
    reasonForChangeFilter = new FormControl("");

    filterValues: JournalList = new JournalList();

    formControlFilters: FormControl[] = [
        this.statusFilter,
        this.policyIdFilter,
        this.inputChannelFilter,
        this.statusNameFilter,
        this.riskCarrierFilter,
        this.sparteFilter,
        this.customerNameFilter,
        this.customerBirthdayFilter,
        this.reasonForChangeFilter,
        this.effectiveDateFilter
    ];
    displayedColumns: string[] = [
        "status",
        "policyId",
        "inputChannel",
        "statusName",
        "riskCarrier",
        "sparte",
        "customerName",
        "customerBirthday",
        "reasonForChange",
        "effectiveDate",
        "filter"
    ];
    displayedColumnFilters: string[] = [
        "statusFilter",
        "policyIdFilter",
        "inputChannelFilter",
        "statusNameFilter",
        "riskCarrierFilter",
        "sparteFilter",
        "customerNameFilter",
        "customerBirthdayFilter",
        "reasonForChangeFilter",
        "effectiveDateFilter",
        "filterRemove"
    ];

    selActions: KeyValuePair[] = [];
    selSubjects: KeyValuePair[] = [];

    configKeyValues: KeyValuePair[] = [];

    constructor(public configProvider: ConfigProvider,
        public households: HouseholdProvider,
        public registerProvider: RegisterProvider,
        public router: Router,
        public dialogService: ActionDialogService,
        public dialog: MatDialog) {
        super();
        this.configProvider.getConfigValue({ defaultTypeId: BusinessTransactionHelper.typeId.provisionPendingMatter.from })
            .subscribe(value => {
                this.selActions = value.data;
                if (this.selActions.length === 0) {
                    this.selActions.push(new KeyValuePair("empty", "empty"));
                }
            });
        // todo: change values
        //     this.configProvider.getConfigValue(BusinessTransactionHelper.typeId.provisionPendingMatter.from)
        //         .subscribe(value => {
        //             this.selSubjects = value;
        //             if (this.selSubjects.length === 0) {
        //                 this.selSubjects.push(new KeyValuePair("empty", "empty"));
        //             }
        //         });
    }

    // getClassFromStatus(effectiveDate): string | undefined {
    //     const days = Helper.getdifferenceInDaysfromToday(effectiveDate);
    //     if (days <= 1) {
    //         return "inactive-status";
    //     }
    //     if (days <= 5) {
    //         return "warning-status";
    //     }
    //     return "active-status";
    // }

    customerBirthdayFilterChanged(event: SlideRangeFilterValue): void {
        this.customerBirthdayFilter.setValue(event.dateRange);
    }

    effectiveDateFilterChanged(event: SlideRangeFilterValue): void {
        this.effectiveDateFilter.setValue(event.dateRange);
    }

    getClassFromStatus(effectivDate, status): string | undefined {
        if (effectivDate === 'Invalid date' && status != 'Abgeschlossen') {
            return 'inactive-status';
        }
        const days = Helper.getdifferenceInDaysfromToday(effectivDate);
        if (status == 'Abgeschlossen') {
            return "completed-status"
        } else {
            if (days <= 1 && status != 'Abgeschlossen') {
                return "inactive-status";
            } else if (days <= 5 && status != 'Abgeschlossen') {
                return "warning-status";
            }
            return "active-status"
        }

    }

    openIssuesRowClick(openIssuesRow: JournalList, i) {
        if (this.expandedElement === openIssuesRow) {
            this.selectedOpenIssuesRowIndex = -1;
            this.expandedElement = null;
            return;
        }
        this.selectedOpenIssuesRowIndex = i;
        this.expandedElement = openIssuesRow;
    }

    createFilter() {
        return function (data: JournalList, filter: string): boolean {
            const filterJson: JournalListJson = JSON.parse(filter);
            const searchTerms: JournalList = JournalList.fromJson(filterJson);
            return (Helper.checkStringContains(data.policyId, searchTerms.policyId) &&
                Helper.checkStringContains(data.inputChannel, searchTerms.inputChannel) &&
                Helper.checkStringContains(data.statusName, searchTerms.statusName) &&
                Helper.checkStringContains(data.riskCarrier, searchTerms.riskCarrier) &&
                Helper.checkStringContains(data.sparte, searchTerms.sparte) &&
                Helper.checkStringContains(data.customerName, searchTerms.customerName) &&
                Helper.checkStringContains(data.reasonForChange, searchTerms.reasonForChange) &&
                Helper.checkDateRange(new Date(data.customerBirthday), Helper.stringToDatePickerFormat(searchTerms.customerBirthday)) &&
                Helper.checkDateRange(new Date(data.effectiveDate), Helper.stringToDatePickerFormat(searchTerms.effectiveDate)));
        };
    }

    resetFilter() {
        this.filterValues = new JournalList();
        super.resetFilter();
    }

    isProductButtonDisabled(element: JournalList) {
        return element ? (element.policyId === "" || element.customerId === null) : true;
    }

    toProduct(element: JournalList) {
        if (element.customerId !== null) {
            const personUi = element.customerId;
            const representativUUID = element.persons[0].uuid;
            if (element.policyId !== "") {
                this.households.getCompleteHouseholdByUuid(representativUUID, new RestProviderActionsDummy()).subscribe(data => {
                    if (data !== null) {
                        const baseUrl = `${this.registerProvider.getPath("MyCustomersDetail")}/${data.representant.uuid}/${personUi}`;
                        const url = `${baseUrl}/policy/${element.policyId}`;
                        this.router.navigate([url]);
                    } else {
                        log.error(`${this.TAG}: toProduct -> has not found person with ${personUi} in households`);
                    }
                });
            } else {
                log.debug(`${this.TAG}: toProduct -> row has no policyId set`);
            }
        }
        log.debug(`${this.TAG}: toProduct -> row has no customerId set`);
    }

    private getUrl(routerName: string) {
        return `${this.registerProvider.getPath(routerName)}`;
    }

    formatDate(date) {
        return Helper.formatDate(date);
    }

    openActionDialog(event: Event, element: JournalList) {
        this.isDialogOpen = true;
        event.stopPropagation();
        const policy = element.policies.find(p => p.uuid === element.policyId);
        const dialogData: ActionDialogData = {
            transTypeId: BusinessTransactionHelper.typeId.provisionPendingMatter.from,
            transName: element.transaction.transactionName,
            transSubject: element.transaction.transactionName,
            transTitle: element.reasonForChange,
            transMessage: element.descriptionField,
            operationalIdExternal: element.transaction.sorKeys["ProcessID"],
            keyValues: [],
            person: element.persons[0],
            policy: policy,
            completed: true
        };
        this.dialogService.openByType(dialogData,
            { defaultTypeId: BusinessTransactionHelper.typeId.provisionPendingMatter.from },
            this.TAG
        );
        this.dialog.afterAllClosed.subscribe(() => {
            this.isDialogOpen = false;
        });
    }
}
