import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'neomp-confirmation-dialog',
  template: `
    <div mat-dialog-title>{{ 'DIALOG.CONFIRMATION.UNSAVED' | translate }}</div>
    <div mat-dialog-content>
    {{ 'DIALOG.CONFIRMATION.QUESTION' | translate }}
    </div>
    <div mat-dialog-actions>
      <button mat-button (click)="onClickCancel()">{{ 'DIALOG.CONFIRMATION.STAY' | translate }}</button>
      <button mat-raised-button class="confirm-button" color="warn" (click)="onClickConfirm()">{{ 'DIALOG.CONFIRMATION.LEAVE' | translate }}</button>
    </div>
  `,
})
export class ConfirmationComponent {

  constructor(
    private readonly dialogRef: MatDialogRef<ConfirmationComponent, boolean>,
  ) {
  }

  onClickCancel() {
    this.dialogRef.close(false);
  }

  onClickConfirm() {
    this.dialogRef.close(true);
  }

}
