import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { JournalJson } from '../../json/journal.json';
import { DocumentResourceResult } from '../../model/document-resource-result';
import { MessageType } from '../../model/enum-messagetype';
import { Journal } from '../../model/journal';
import { KeyValuePair } from '../../model/key-value-pair';
import { SlideRangeFilterValue } from '../../model/slide-range-filter-value';
import { ActionDialogService } from '../../providers/action-dialog.service';
import { CommunicationService } from '../../providers/communication.service';
import { ConfigProvider } from '../../providers/config.provider';
import { DocumentProvider } from '../../providers/document.provider';
import { MessagesProvider } from '../../providers/messages.provider';
import { RestProviderActionsDummy } from '../../providers/rest.provider';
import { ServiceTimer } from '../../providers/service-timer';
import { BusinessTransactionHelper } from '../../static/bt-helper';
import { Helper } from '../../static/helper';
import { ActionDialogData } from '../action.dialog.component';
import { FilterTableComponent } from './filter-table-component';
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { UserCommitState } from "app/modules/core/model/user-commit-state";

@Component({
  selector: 'neomp-related-messages-table',
  templateUrl: './related-messages-table.component.html',
  styleUrls: ['./related-messages-table.component.scss'],
  animations: [
    trigger("detailExpand", [
      state("collapsed, void", style({ height: "0px", minHeight: "0", display: "none" })),
      state("expanded", style({ height: "*" })),
      transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")),
      transition("expanded <=> void", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)"))
    ]),
  ]
})
export class RelatedMessagesTableComponent extends FilterTableComponent<Journal> implements OnDestroy {
  private readonly TAG = this.constructor.name;
  expandedElement: Journal | null;
  selectedJournalRowIndex;
  iconFilter = new FormControl("");
  creationDateFilter = new FormControl("");
  typeNameFilter = new FormControl("");
  reasonForChangeFilter = new FormControl("");

  filterValues: Journal = new Journal();

  formControlFilters: FormControl[] = [
    this.iconFilter,
    this.creationDateFilter,
    this.typeNameFilter,
    this.reasonForChangeFilter,
    null,
    null
  ];

  displayedColumns = [
    "icon",
    "creationDate",
    "typeName",
    "reasonForChange",
    "document",
    "msgKind",
    "filter"
  ];
  displayedColumnFilters = [
    "iconFilter",
    "creationDateFilter",
    "typeNameFilter",
    "reasonForChangeFilter",
    "documentFilter",
    "filterRemove"
  ];

  timer: ServiceTimer;
  serviceTimerSubscription: Subscription;
  callback;

  @Input()
  journalData: Map<string, DocumentResourceResult[]> = new Map<string, DocumentResourceResult[]>();

  configKeyValues: KeyValuePair[] = [];

  constructor(public configProvider: ConfigProvider,
    public messagesProvider: MessagesProvider,
    public communicationService: CommunicationService,
    public dialogService: ActionDialogService,
    public documentProvider: DocumentProvider,
    public matSnackBar: MatSnackBar,
    private translateService: TranslateService) {
    super();

    this.configProvider.getConfigValue({ defaultTypeId: "uiConfig" })
      .subscribe(value => {
        const readMessageDelay = value.data !== null ? value.data.find(value => value.key === "readMessageDelay") : null;
        let messageDelay: number;
        if (!readMessageDelay) {
          messageDelay = 100;
        } else {
          messageDelay = +readMessageDelay.value;
        }

        this.timer = new ServiceTimer(messageDelay, -1);
        this.serviceTimerSubscription = this.timer.tick().subscribe(() => {
          if (this.callback) {
            this.callback();
          }
        });
        this.timer.stop();
      });
  }


  ngOnDestroy(): void { }

  download(id: string) {
    if(id){
      this.documentProvider.downloadDocument(id);
    }
    else{
      const translatedMessage = this.translateService.instant('PAGE.CONTAINER.MESSAGES.ERROR-DOCUMENT');
      this.matSnackBar.open(translatedMessage, 'X', UserCommitState.configCheckData);
  }
  }

  downloadFromMyNeo(id: string) {
    if(id){
      this.documentProvider.downloadDocument(id, true);
    }
    else{
      const translatedMessage = this.translateService.instant('PAGE.CONTAINER.MESSAGES.ERROR-DOCUMENT');
      this.matSnackBar.open(translatedMessage, 'X', UserCommitState.configCheckData);
  }
}

  creationDateFilterChanged(event: SlideRangeFilterValue): void {
    this.creationDateFilter.setValue(event.dateRange);
  }

  createFilter(): (data: Journal, filter: string) => boolean {

    return function (data, filter): boolean {
      const filterJsonAny = JSON.parse(filter);
      const filterJson: JournalJson = JSON.parse(filter);
      const searchTerms: Journal = Journal.fromJson(filterJson);

      return Helper.checkDateRange(data.getCreationDate(), Helper.stringToDatePickerFormat(searchTerms.creationDate)) &&
        Helper.checkStringContains(data.typeName, searchTerms.typeName) &&
        Helper.checkStringContains(data.reasonForChange, searchTerms.reasonForChange) &&
        Helper.checkStringContains(data.descriptionField, searchTerms.descriptionField) &&
        (!filterJsonAny.icon || (filterJsonAny.icon && !data.status.completed));
    };
  }

  resetFilter() {
    this.filterValues = new Journal();
    super.resetFilter();
  }
  openActionDialog(event: Event, element: Journal) {
    event.stopPropagation();
    const dialogData: ActionDialogData = {
      transTypeId: element.typeId,
      transName: "Nachricht",
      transSubject: "Nachricht",
      transTitle: "Nachricht",
      transMessage: "Nachricht",
      operationalIdExternal: element.sorKeys["ProcessID"],
      keyValues: [],
      person: null
    };
    this.dialogService.openByType(dialogData,
      {
        defaultTypeId: BusinessTransactionHelper.typeId.message.from,
        typeId: element.typeId
      },
      this.TAG);
  }
  getDocuments(journal: Journal): DocumentResourceResult[] {
    if (this.journalData.has(journal.uuid)) {
      return this.journalData.get(journal.uuid);
    }
    return [];
  }

  journalRowClick(journalRow: Journal) {
    if (this.expandedElement === journalRow) {
      this.selectedJournalRowIndex = -1;
      this.expandedElement = null;
      this.timer.stop();
      return;
    }
    this.selectedJournalRowIndex = journalRow.uuid;
    this.expandedElement = journalRow;

    if (!journalRow.status.completed) {
      this.timer.stop();
      this.callback = () => {
        return this.markMessagesAsRead(journalRow);
      }
      this.timer.start();
    }
  }

  markMessagesAsRead(journal: Journal) {
    this.messagesProvider.markMessagesAsRead(new RestProviderActionsDummy(), [journal.uuid])
      .subscribe(result => {
        journal.status.completed = true;
        this.communicationService.sendMessage("readMessage", journal.sorKeys.groupId || "");
      });
  }
  public get msgType(): typeof MessageType {
    return MessageType;
  }
}
