import {Injectable, OnInit} from "@angular/core";
import {Router, CanActivate} from "@angular/router";
import { KeycloakService } from "keycloak-angular";
import {environment} from "../../../../environments/environment";
import { AgenturType } from "../model/enum-agenturtype";
import { Person } from "../model/person";

@Injectable()
export class AuthGuard implements CanActivate {
    agents: Person[] = [];

    agenturType: string = "";
    agenturName: string = "";
    constructor(public router: Router,  private keycloak: KeycloakService) {}
  
    
        async canActivate(): Promise<boolean> {
            await this.keycloak.loadUserProfile().then((res: any) => { 
                this.agenturType = res.attributes.agenturType[0];
                this.agenturName = res.attributes.agenturName[0];                
            });
            
            if(this.agenturType === AgenturType.SalesManager || this.agenturType === AgenturType.GeneralAgent || this.agenturType === AgenturType.TeamLead){
                return true;
            }else{
                
              return false
            }
            //return !environment.settings.production;
        }
}
