<table [dataSource]="dataSource"
       class="msg-helper-width-100"
       mat-table
       matSort
       matSortActive="effectiveDate"
       matSortDirection="desc">
    <!-- columns -->
    <ng-container matColumnDef="reasonForChange">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>
            {{ "PAGE.OFFER.FORMS.NAME" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.reasonForChange }}
        </td>
    </ng-container>
    <ng-container matColumnDef="documentTitle">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>
            {{ "PAGE.OFFER.FORMS.LINE_OF_BUSINESS" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.documentTitle}}
        </td>
    </ng-container>
    <ng-container matColumnDef="effectiveDate">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>
            {{ "PAGE.OFFER.FORMS.DATE" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
            {{formatDate(element.effectiveDate)}}
        </td>
    </ng-container>
    <ng-container matColumnDef="filter">
        <th *matHeaderCellDef mat-header-cell (click)="changeFilterView()"
            class="mat-header-cell mat-column-filter {{isFilterActive ? 'mat-column-filter-active':''}}">
            <div
                class="neomp-table-column-filter-element  mdi {{isFilterActive ? 'mdi-filter':'mdi-filter-outline'}}"></div>
        </th>
        <td *matCellDef="let element" mat-cell class="mat-column-filter-action">
            <div *ngIf="existsDocument(element)" class="neomp-table-column-filter-element mdi mdi-download"
                 (click)="downloadDocument($event, element)"></div>
        </td>
    </ng-container>

    <!-- filters -->
    <ng-container matColumnDef="reasonForChangeFilter">
        <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter" floatLabel="never">
                <input matInput
                       [formControl]="reasonForChangeFilter"
                       placeholder="{{ 'TABLES.FILTERPLACEHOLDER.SECTION' | translate }}">
            </mat-form-field>
        </th>
    </ng-container>
    <ng-container matColumnDef="documentTitleFilter">
        <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter" floatLabel="never">
                <input
                    matInput [formControl]="documentTitleFilter"
                    placeholder="{{ 'TABLES.FILTERPLACEHOLDER.TITLE' | translate }}">
            </mat-form-field>
        </th>
    </ng-container>
    <ng-container matColumnDef="effectiveDateFilter">
        <th mat-header-cell *matHeaderCellDef>
            <neomp-date-picker [setDefaultValues]="false" (filterValue)="effectiveDateFilterChanged($event)"></neomp-date-picker>
        </th>
    </ng-container>
    <ng-container matColumnDef="filterRemove">
        <th mat-header-cell *matHeaderCellDef (click)="resetFilter()">
            <div class="neomp-table-column-filter-element mdi mdi-close"></div>
        </th>
    </ng-container>

    <tr *matHeaderRowDef="displayedColumns"
        mat-header-row></tr>
    <tr *matHeaderRowDef="displayedColumnFilters"
        class="neomp-table-row-filter {{showFilter? '': 'neomp-table-row-filter-hide'}}"
        mat-header-row></tr>
    <tr *matRowDef="let journalRow; columns: displayedColumns"
        class="neomp-table-row-selected container-table-row" mat-row></tr>
</table>
