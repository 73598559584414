<div class="mdc-layout-grid msg-helper-padding-none">
    <div class="mdc-layout-grid__inner msg-helper-grid-gap-horizontal">
        <div
            class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
            <div
                class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-9-tablet mdc-layout-grid__cell--span-12-phone mdc-layout-grid--align-left">
                <div class="mdc-layout-grid msg-helper-padding-none msg-helper-margin-top-10">
                    <div class="mdc-layout-grid__inner">
                        <div
                            class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
                            <neomp-termin [managementView]="true"></neomp-termin>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>