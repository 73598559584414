import {interval, Observable, Subject, Subscription, timer} from "rxjs";
import {map, repeatWhen, takeUntil} from "rxjs/operators";

export class ServiceTimer {

    private readonly _timerSubscription: Observable<any>;
    private readonly _start = new Subject<void>();
    private readonly _stop = new Subject<void>();

    constructor(initialDelay: number, delay: number) {

        if (delay < 0) {
            this._timerSubscription = timer(initialDelay)
                .pipe(
                    map(() => {}),
                    takeUntil(this._stop),
                    repeatWhen(() => this._start)
                );
        } else {
            this._timerSubscription = timer(initialDelay, delay)
                .pipe(
                    map(() => {}),
                    takeUntil(this._stop),
                    repeatWhen(() => this._start)
                );
        }
    }

    start(): void {
        this._start.next();
    }

    stop(): void {
        this._stop.next();
    }

    tick(): Observable<any> {
        return this._timerSubscription;
    }
}
