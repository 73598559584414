import {Component, Input, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {HouseholdProvider} from "../../core/providers/household.provider";
import {SelectedPersonEmitter} from "../../core/model/selectedPersonEmitter";
import {ActivatedRoute, Router} from "@angular/router";
import {RegisterProvider} from "../../core/providers/register.provider";
import {LoadingDataComponent} from "../../core/components/loading-data.component";
import {FilterTableComponent} from "../../core/components/tables/filter-table-component";
import {FormControl} from "@angular/forms";
import {Helper} from "../../core/static/helper";
import {CustomersCache} from "../../core/model/customers-cache";
import {CustomersCacheProvider} from "../../core/providers/customers-cache.provider";
import {RestProviderActionsDummy} from "app/modules/core/providers/rest.provider";
import {Policy} from "../../core/model/policy";
import {log} from "../../core/providers/logger.provider";
import {PolicyJson} from "../../core/json/policy.json";
import {PersonJson} from "../../core/json/person.json";
import {Person} from "../../core/model/person";
import {Household} from "../../core/model/household";
import {HouseholdJson} from "../../core/json/household.json";
import {ContextSearchService} from "../../core/services/context-search.service";
import { SlideRangeFilterValue } from "app/modules/core/model/slide-range-filter-value";

@Component({
    selector: "neomp-search-result-table-customer",
    templateUrl: "./result-table-customer.component.html",
    styleUrls: ["./result-table-customer.component.scss"]
})
export class ResultTableCustomerComponent extends FilterTableComponent<Person> implements OnInit, OnDestroy {
    private readonly TAG: string = this.constructor.name;

    uuidFilter = new FormControl("");
    firstNameFilter = new FormControl("");
    nameFilter = new FormControl("");
    birthdateFilter = new FormControl("");
    nestedAddressPostalCodeFilter = new FormControl("");
    nestedAddressCityFilter = new FormControl("");
    updatedFilter = new FormControl("");
    filterValues: Person = new Person();

    displayedColumns = [
        "uuid",
        "firstName",
        "name",
        "birthdate",
        "nestedAddressPostalCode",
        "nestedAddressCity",
        "updated",
        "filter"
    ];
    displayedColumnFilters = [
        "uuidFilter",
        "firstNameFilter",
        "nameFilter",
        "birthdateFilter",
        "nestedAddressPostalCodeFilter",
        "nestedAddressCityFilter",
        "updatedFilter",
        "filterRemove"
    ];
    formControlFilters: FormControl[] = [
        this.uuidFilter,
        this.firstNameFilter,
        this.nameFilter,
        this.birthdateFilter,
        this.nestedAddressPostalCodeFilter,
        this.nestedAddressCityFilter,
        this.updatedFilter,
        null
    ];

    selectedApplicationsRowIndex;

    constructor(public router: Router,
                public registerProvider: RegisterProvider,
                public activatedRoute: ActivatedRoute,
                public searchService: ContextSearchService) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
    }

    ngOnDestroy() {
    }

    customerBirthdayFilterChanged(event: SlideRangeFilterValue): void {
        this.birthdateFilter.setValue(event.dateRange);
    }

    updatedFilterChanged(event: SlideRangeFilterValue): void {
        this.updatedFilter.setValue(event.dateRange);
    }

    rowClick(element: Person) {
        this.selectedApplicationsRowIndex = element.uuid;
        this.searchService.reset();
        this.openRow(element);
    }

    openRow(element: Person) {
        log.debug(`${this.TAG}: openRow() -> person=${element.uuid} clicked`);
        const url = `${this.registerProvider.getPath("MyCustomersDetail")}/${element.uuid}`;
        this.router.navigateByUrl(url);
    }

    resetFilter() {
        this.filterValues = new Person();
        super.resetFilter();
    }

    createFilter(): (data: Person, filter: string) => boolean {
        return function (innerData, innerFilter): boolean {
            const searchTerms: Person = Person.fromJson(JSON.parse(innerFilter) as PersonJson);
            return (
                Helper.checkStringContains(innerData.uuid, searchTerms.uuid) &&
                Helper.checkStringContains(innerData.firstName, searchTerms.firstName) &&
                Helper.checkStringContains(innerData.name, searchTerms.name) &&
                Helper.checkDateRange(new Date(innerData.birthdate), Helper.stringToDatePickerFormat(searchTerms.birthdate)) &&
                Helper.checkDateRange(new Date(innerData.updated), Helper.stringToDatePickerFormat(searchTerms.updated)) &&
                Helper.checkStringContains(innerData.nestedAddressPostalCode, searchTerms.nestedAddressPostalCode) &&
                Helper.checkStringContains(innerData.nestedAddressCity, searchTerms.nestedAddressCity)
            )
        }
    }

    formatDate(date) {
        return Helper.formatDate(date);
    }
}
