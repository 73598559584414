<div class="mdc-layout-grid msg-helper-padding-none neo-padding-top-10 dark-theme calendar-wrapper">
  <div class="mdc-layout-grid__inner msg-helper-grid-gap-horizontal">
    <br>
    <div
      class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
      <div class="mdc-layout-grid__inner flex-col">

        <div class="mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone neo-padding-top-10" *ngIf="isHighRole">
          <div class="mdc-layout-grid__inner">

            <div class="mdc-layout-grid__cell--span-12">
              <!-- TODO translate -->
              <div class="berater-header">
                <mat-label class="neo-title-list" matTooltip="Tooltip text for Berater">Berater</mat-label>
                <div class="reminder-checkbox">
                <mat-label class="mat-checkbox-label">{{ 'MENU.LEADS-NAV.APPOINTMENT' | translate }}</mat-label>              
                <mat-slide-toggle (change)="searchByReminderOrAppointment()" [(ngModel)]="reminderChecked">
                </mat-slide-toggle>
                <mat-label class="mat-checkbox-label">{{ 'MENU.LEADS-NAV.REMINDER' | translate }}</mat-label>              
                </div>   
              </div>
              
              <div>
                <mat-card class="scrollable-content" class="d-block" >
                  <mat-select #select multiple [placeholder]="'PAGE.CONTAINER.CALENDARS.CONTAINER.SELECT_ALL' | translate" [(value)]="defaultSelected">
                    <mat-option class="multiple-select" #allOption (click)="toggleAllSelection()" [value]="'selectAll'">
                      {{ 'PAGE.CONTAINER.CALENDARS.CONTAINER.SELECT_ALL' | translate }}
                    </mat-option>
                    <mat-option class="multiple-select" *ngFor="let agent of agentData" [value]="agent" (click)="toggleOptionSelection(agent)">
                      <span [ngStyle]="{'color': agent.color}">
                        {{ agent.fullName && agent.fullName.split("Neosana Sales AG")[1] ? agent.fullName.split("Neosana Sales AG")[1] : agent.fullName }} ({{ agent.events.length }})
                      </span>
                    </mat-option>
                  </mat-select>
                </mat-card>
              </div>
            </div>
            <div
              class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone neo-margin-top"
              *ngIf="managementView && !reminderChecked">
                <mat-card>
                  <div
                    class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone neo-margin-top">
                    <div class="mdc-layout-grid__inner">

                      <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
                        <div>
                          <h1>{{ 'PAGE.LEADS.TERMIN.MULTIASSIGNTITLE' | translate }}</h1>
                        </div>
                        <div class="mdc-layout-grid__inner">
                            <div *ngIf="isHighRole" class="mdc-layout-grid__cell--span-8 mdc-layout-grid__cell--span-4-tablet mdc-layout-grid__cell--span-12-phone agent-select">
                              <mat-form-field class="neomp-full-width">
                                <mat-label id="berater-label">{{ 'PAGE.LEADS.LEAD.ADVISOR' | translate }}</mat-label>
                                <mat-select [formControl]="agentAssignSelectedControl">
                                  <mat-option *ngFor="let agent of agents" [value]="agent">
                                    {{agent.fullName && agent.fullName.split("Neosana Sales AG")[1] ?
                                    agent.fullName.split("Neosana Sales AG")[1] : agent.fullName }}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                            <div class="mdc-layout-grid__cell--span-4 mdc-layout-grid__cell--span-2-tablet mdc-layout-grid__cell--span-12-phone cal-toggle">
                              <div class="neo-padding-top-10 save-btn">
                                <button mat-raised-button [disabled]="
                                  selectedLeads.length === 0 
                                  ||
                                  agentAssignSelectedControl.invalid
                                  ||
                                  isLoading
                                  " color="primary" (click)="assignLeadsToAgent()">
                                  {{ 'DIALOG.BUTTON.SAVE' | translate }}
                                </button>
                              </div>
                            </div>
                          
                         
                        </div>
                      </div>
                      <div class="mdc-layout-grid__cell--span-12 mdc-layout-grid__cell--span-10-tablet mdc-layout-grid__cell--span-12-phone">
                        <neomp-termine-table [tableEntries]="tableAppointments" (resetData)="resetFunction($event)"
                          [leads]="tableAppointments" [agents]="agents" [managementView]="managementView" [calendarView]="calendarView"
                          (selectedRows)="selectedRows($event)" (appointmentId)="appointmentId($event)"></neomp-termine-table>
                        <neomp-loading-data #loadingFamilyTable></neomp-loading-data>
                        <neomp-paginator [length]="totalCountAppointments" [pageSize]="pageSize" [pageIndex]="pageIndex"
                          [pageSizeOptions]="pageSizeOptions" (paginatorChange)="paginatorValueChanged($event)">
                        </neomp-paginator>
                      </div>

                    </div>
                  </div>
                </mat-card>
            </div>
            
            <!-- TERMIN EDIT -->
            <!-- <div
              class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone no-padding">
              <neomp-container cssClass="msg-mp-container-frameless"
                headlineText="{{ 'PAGE.LEADS.TERMIN.APPOINTMENT' | translate }}" (edit)="edit($event)" (cancel)="cancel($event)"
                [showActionIcon]="lead" [editSection]="'Appointment'">
                <form [formGroup]="terminForm">
                  <div
                    class="mdc-layout-grid mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone msg-helper-grid-gap-none msg-helper-margin-none msg-helper-padding-10 neo-space-padding">
                    <section>
                      <div class="mdc-layout-grid__inner">
                        <div
                          class="mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                          <mat-form-field class="neomp-full-width">
                            <mat-label class="mat-label-color">{{ 'PAGE.LEADS.LEAD.TERMINABLATT.CALENDAR' | translate}}</mat-label>
                            <mat-select formControlName="calendar_lang" [readonly]="true">
                              <mat-option *ngFor="let lang of leadConfigs.LANGUAGE" [value]="lang.value">
                                {{ lang.value }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div
                          class="mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                          <mat-form-field class="neomp-full-width">
                            <mat-label class="mat-label-color">{{ 'PAGE.LEADS.LEAD.TERMINABLATT.APPOINTMENT-DATE' | translate}}
                            </mat-label>
                            <input [matDatepicker]="termin_date" matInput formControlName="termin_date" />
                            <mat-datepicker-toggle matSuffix [for]="termin_date"></mat-datepicker-toggle>
                            <mat-datepicker #termin_date></mat-datepicker>
                            <mat-error *ngIf="terminForm.controls['termin_date'].hasError('required')">{{
                              'DIALOG.INPUT.REQUIREDERROR' | translate }}</mat-error>
                          </mat-form-field>
                        </div>
                      </div>
                    </section>
                    <section>
                      <div class="mdc-layout-grid__inner">
                        <div
                          class="mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                          <mat-form-field class="neomp-full-width">
                            <mat-label class="mat-label-color">{{ 'PAGE.LEADS.LEAD.TERMINABLATT.TIME' | translate}} </mat-label>
                            <input class="font-family" matInput type="time" formControlName="termin_time" />
                            <mat-error *ngIf="terminForm.controls['termin_time'].hasError('required')">{{
                              'DIALOG.INPUT.REQUIREDERROR' | translate }}</mat-error>
                          </mat-form-field>
                        </div>
                        <div
                          class="mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                          <mat-form-field class="neomp-full-width">
                            <mat-label class="mat-label-color">{{ 'PAGE.LEADS.LEAD.TERMINABLATT.VERMITTLER' | translate}}</mat-label>
                            <mat-select  (selectionChange)="setValue('termin_agent', $event)" formControlName="termin_agent">
                              <mat-option *ngFor="let agent of agents" [value]="agent">
                                {{agent.fullName && agent.fullName.split("Neosana Sales AG")[1] ?
                                agent.fullName.split("Neosana Sales AG")[1] : agent.fullName }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                    </section>
                  </div>
                </form>
              </neomp-container>
            </div> -->
          </div>
        </div>
        <div class="mdc-layout-grid__cell-8" [ngClass]="isHighRole ? 'mdc-layout-grid__cell--span-8-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone grid-change' : 'mdc-layout-grid__cell--span-12'">
          <div class="berater-header"  *ngIf="!isHighRole">
            <div class="reminder-checkbox">
            <mat-label class="mat-checkbox-label">{{ 'MENU.LEADS-NAV.APPOINTMENT' | translate }}</mat-label>              
            <mat-slide-toggle (change)="searchByReminderOrAppointment()" [(ngModel)]="reminderChecked">
            </mat-slide-toggle>
            <mat-label class="mat-checkbox-label">{{ 'MENU.LEADS-NAV.REMINDER' | translate }}</mat-label>              
            </div>   
          </div>

          <div class='calendar-container' *ngIf="isMounted">
            <full-calendar [options]='calendarOptions' #calendar>
              <ng-template #eventContent let-arg>
                <b *ngIf="arg.event.title !== '-1'">{{ arg.timeText }}</b>
                <ng-container *ngIf="arg.event.title === '-1'">
                  <b class="unassigned-appointment">{{ 'PAGE.LEADS.TERMIN.UNASSIGNED-APPOINTMENT' | translate }}</b>
                </ng-container>
                <ng-container>
                  <!-- <div>{{ arg.event.title }}</div> -->
                  <div class="props"  *ngIf="arg.view.type === 'dayGridMonth' || arg.view.type === 'timeGridWeek'">{{ arg.event.extendedProps.fullName }}</div>
                  <div class="props" *ngIf="(arg.view.type === 'listWeek' || arg.view.type === 'timeGridDay')  && arg.event.title !== '-1' ">{{ arg.event.extendedProps.agentName }}</div>
                  <div class="props" *ngIf="arg.view.type === 'listWeek'">{{ arg.event.extendedProps.fullName }}</div>
                  <div class="props" *ngIf="arg.view.type === 'listWeek'">{{ arg.event.extendedProps.postalCode }}</div>
                  <div class="props" *ngIf="arg.view.type === 'listWeek'">{{ arg.event.extendedProps.city }}</div>
                  <div class="props" *ngIf="arg.view.type === 'timeGridDay'"> {{ arg.event.extendedProps.fullName }}, {{ arg.event.extendedProps.postalCode }}, {{ arg.event.extendedProps.city }}</div>
                </ng-container>
              </ng-template>
            </full-calendar>
          </div>

          <!-- <termin-utils-calendar-header (mwlClick)="getDateView(viewDate, view)" [(view)]="view" [(viewDate)]="viewDate" [locale]="locale">
          </termin-utils-calendar-header>

          <ng-template #customCellTemplate let-day="day" let-locale="locale">
            <div class="cal-cell-top">
              <span *ngIf="day.badgeTotal >10000">{{ day.badgeTotal }}</span>
              <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
            </div>
            <div class="cell-totals">
              <span *ngFor="let group of day.eventGroups" [style.background-color]="group[0]" class="badge">
                {{ group[1].length }}
              </span>
            </div>
          </ng-template>

          <div [ngSwitch]="view">
            <mwl-calendar-month-view 
              *ngSwitchCase="'month'" 
              [viewDate]="viewDate" 
              [events]="selectedAgentsEvents1"
              [cellTemplate]="customCellTemplate" 
              (beforeViewRender)="beforeMonthViewRender($event)"
              (dayClicked)="changeDay($event.day.date)" 
              [locale]="locale">
            </mwl-calendar-month-view>

            <mwl-calendar-week-view 
              *ngSwitchCase="'week'" 
              precision="minutes" 
              [viewDate]="viewDate"
              [events]="selectedAgentsEvents1" 
              [locale]="locale"
            
              (eventClicked)="redirectToTermin($event)">

            </mwl-calendar-week-view>

            <mwl-calendar-day-view 
              *ngSwitchCase="'day'" 
              [viewDate]="viewDate" 
              [events]="selectedAgentsEvents1"
              [locale]="locale" 
              (eventClicked)="redirectToTermin($event)">
            </mwl-calendar-day-view>
          </div> -->

        </div>
      </div>
    </div>
  </div>
</div>