import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgentChatComponent } from './chats/agent-chat/agent-chat.component';
import { AgentNavComponent } from './agent-nav/agent-nav.component';
import { AgentPageComponent } from './agent-page/agent-page.component';
import { ChatsComponent } from './chats/chats.component';
import { LivechatService } from './livechat.service';
import { RouterModule } from '@angular/router';
import { LivechatRoutingModule } from './livechat-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    LivechatRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    MatSidenavModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  declarations: [
    AgentChatComponent,
    AgentNavComponent,
    AgentPageComponent,
    ChatsComponent,
  ],
  providers: [LivechatService],
})
export class LivechatModule {}
