import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Location } from "@angular/common";
import { Household } from "../../core/model/household";
import { HouseholdProvider } from "../../core/providers/household.provider";
import { ActivatedRoute, Router } from "@angular/router";
import { RegisterProvider } from "../../core/providers/register.provider";
import { SelectedPersonEmitter } from "../../core/model/selectedPersonEmitter";
import { Policy } from "../../core/model/policy";
import { PolicyProvider } from "../../core/providers/policy.provider";
import { MatDialog } from "@angular/material/dialog";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Person } from "../../core/model/person";
import { JournalProvider } from "../../core/providers/journal.provider";
import { Journal } from "../../core/model/journal";
import { LoadingDataComponent } from "../../core/components/loading-data.component";
import { Breadcrumb } from "../../core/model/breadcrumb";
import { TranslateService } from "@ngx-translate/core";
import { forkJoin, Observable, of } from "rxjs";
import { ActionDialogService } from "../../core/providers/action-dialog.service";
import { ActionDialogData } from "../../core/components/action.dialog.component";
import { BusinessTransactionHelper } from "../../core/static/bt-helper";
import { Title } from "@angular/platform-browser";
import { Helper } from "../../core/static/helper";
import { RestProviderActionsDummy } from "../../core/providers/rest.provider";
import { map } from "rxjs/operators";
import { DocumentResourceResult } from "../../core/model/document-resource-result";
import { DocumentProvider } from "../../core/providers/document.provider";
import { GMOfferDialogComponent } from "app/modules/shared/components/gmoffer-dialog/gmoffer-dialog.component";
import { LoadingService } from "app/modules/core/providers/loading.component";
import { AdvisorService } from "app/modules/core/providers/advisor.service";
import { KeycloakService } from "keycloak-angular";
import { AgenturType } from "app/modules/core/model/enum-agenturtype";

@Component({
    selector: "neomp-my-customers-detail-page",
    templateUrl: "./my-customers-detail-page.component.html",
    styleUrls: ["./my-customers-detail-page.component.scss"]
})
export class MyCustomersDetailPageComponent implements OnInit, OnDestroy {
    private readonly TAG = this.constructor.name;

    @Input()
    representativeUuid: string;
    selectedPersonUuid: string;
    houseHold: Household;
    policies: Policy[] = [];
    journals: Journal[] = [];
    person: Person;
    dataSource: MatTableDataSource<Policy>;
    selectedPolicyRowIndex;
    offerURL: string;
    agentNumber = "0";
    isDialogOpen = false;

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild("familyTableDetail") familyTableLoadingComponent: LoadingDataComponent;
    @ViewChild("policyTable") policyTableLoadingComponent: LoadingDataComponent;
    @ViewChild("journalTable") journalTableLoadingComponent: LoadingDataComponent;

    journalData: Map<string, DocumentResourceResult[]> = new Map<string, DocumentResourceResult[]>();

    breadcrumbs: Breadcrumb[] = [];

    agenturType: string = "";
    isClient: Boolean = false;

    constructor(
        private loadService: LoadingService,
        public householdProvider: HouseholdProvider,
        public journalProvider: JournalProvider,
        public policyProvider: PolicyProvider,
        public keycloak: KeycloakService,
        public advisorService: AdvisorService,
        public route: ActivatedRoute,
        public router: Router,
        public location: Location,
        public registerProvider: RegisterProvider,
        public translateService: TranslateService,
        public dialogService: ActionDialogService,
        public documentProvider: DocumentProvider,
        public titleService: Title,
        public dialog: MatDialog) {
        this.representativeUuid = "";
        this.dataSource = new MatTableDataSource(this.policies);
    }

    async ngOnInit() {
        let res = await this.keycloak.loadUserProfile() as any;
        this.agenturType = res.attributes.agenturType[0];
        this.isClient = this.agenturType === AgenturType.Client;  
        
        if (!this.isClient) {
            this.advisorService.getAgentNumber().then((agentNumber: string) => {
                this.agentNumber = agentNumber;
            });
        }

        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (item, property) => {
            if (property) {
                if (property.includes(".")) {
                    return property.split(".").reduce((o, i) => o[i], item);
                }
                return item[property];
            }
        };
        this.loadService.dialogClosed.subscribe((val: boolean) => {
            if (val === true) {
                this.getJournalsExclusiveProvisions();
            }
        });

        const paramNames = this.registerProvider.getPathParamForKey("MyCustomersDetail");
        this.representativeUuid = this.route.snapshot.paramMap.get(paramNames[0]);
        this.selectedPersonUuid = this.route.snapshot.paramMap.get(paramNames[1]) || this.representativeUuid;

        if (!this.representativeUuid) {
            this.router.navigateByUrl(this.registerProvider.getPath("MyCustomers")).then();
        }
        this.householdProvider.getCompleteHouseholdByUuid(this.representativeUuid, this.familyTableLoadingComponent)
            .subscribe(household => {
                this.houseHold = household;
                this.breadcrumbs
                    .push({ label: this.isClient ? "PAGE.CONTAINER.CUSTOMERS.CONTAINER.CLIENTHEADLINE" : "PAGE.CONTAINER.CUSTOMERS.CONTAINER.HEADLINE", url: "/" + this.registerProvider.getPath("MyCustomers") });

                this.breadcrumbs.push({ label: household.representant.fullName });
                this.person = this.houseHold.persons.find(person => person.uuid === this.selectedPersonUuid);

                this.getJournalsExclusiveProvisions();
                this.getPolicy();
                this.offerURL = this.getUrl("Offers");
            });
    }

    getJournalsExclusiveProvisions() {
        this.journals = [];
        const sort = { creationDate: -1 };

        // As desired by stakeholder, we must exclude transactions which are defined as provisions.
        this.journalProvider.getJournalsByPartnerId(
            this.selectedPersonUuid,
            this.journalTableLoadingComponent,
            BusinessTransactionHelper.typeId.customer.from,
            '5099',
            sort
        ).subscribe(journals => {
            this.journals = journals ? journals : [];

            this.journals = this.journals.filter(j => {
                // using unary operator for parsing string to number; in case of NaN this will return 0
                const minProvisions = +BusinessTransactionHelper.typeId.provisionPendingMatter.from;
                const maxProvisions = +BusinessTransactionHelper.typeId.provisionPendingMatter.to;
                const minOfferCreate = +BusinessTransactionHelper.typeId.offerCreate.from;
                const maxOfferCreate = +BusinessTransactionHelper.typeId.offerCreate.to;
                // const minCustomer = +BusinessTransactionHelper.typeId.customer.from;
                const maxCustomer = +BusinessTransactionHelper.typeId.customer.to;
                const v = +j.typeId;

                if (v > maxCustomer && v < minProvisions) {
                    return false;
                }

                if (v > maxProvisions && v < 5000) {
                    // allow offer bts
                    if (v >= minOfferCreate && v <= maxOfferCreate) {
                        return true;
                    }
                    return false;
                }

                return true
            });

            let journalData: Map<string, any> = new Map<string, any>();
            let subscriptions: Observable<any>[] = [];
            for (let journal of this.journals) {
                let references = journal.references.filter(reference => reference.type.key === "050");
                for (let reference of references) {
                    const subscription = this.documentProvider.getDocumentById(
                        reference.id,
                        new RestProviderActionsDummy())
                        .pipe(
                            map(result => ({ id: journal.uuid, document: result }))
                        );
                    subscriptions.push(subscription);
                }

                // type "MyNeoDocument" refers to a temporary document
                let tempDocumentReferences = journal.references.filter(reference => reference.type.key === "MyNeoDocument");
                for (let tempDocumentReference of tempDocumentReferences) {
                    let documentResource = new DocumentResourceResult()
                    documentResource.id = tempDocumentReference.id;
                    documentResource.fromMyNeo = true;
                    
                    subscriptions.push(of({id: journal.uuid, document: documentResource }));
                }
            }

            forkJoin(subscriptions).subscribe(results => {
                let journalData: Map<string, DocumentResourceResult[]> = new Map<string, DocumentResourceResult[]>();
                for (let result of results) {
                    if (!journalData.has(result.id)) {
                        journalData.set(result.id, [result.document]);
                    } else {
                        journalData.get(result.id).push(result.document);
                    }
                }
                this.journalData = journalData;
            });
        });
    }

    getPolicy() {
        this.dataSource = new MatTableDataSource(this.policies);
        this.policyProvider.getPolicyByPartnerId(this.selectedPersonUuid, this.policyTableLoadingComponent)
            .subscribe(policy => {
                this.policies = policy.data;
                if (this.policies) {
                    this.policies.forEach(p => {
                        if (this.houseHold.representant.approvals) {
                            const approval = this.houseHold.representant.approvals.find(x =>
                                x.reference === p.uuid);
                            if (approval) {
                                p.mandateGivenAndValid = approval.approved;
                            }
                        }
                    });
                }
                this.dataSource.data = this.policies;
            }, error => {
                this.policies = null;
                this.dataSource.data = this.policies;
            });
    }

    selectedPersonClick(value: SelectedPersonEmitter) {
        if (this.selectedPersonUuid === value.selectedPersonUuid) {
            return;
        }
        this.person = this.houseHold.persons.find(
            person => person.uuid === value.selectedPersonUuid
        );

        this.selectedPersonUuid = value.selectedPersonUuid;
        const url = this.getUrl("MyCustomersDetail");
        this.location.replaceState(url);
        this.offerURL = this.getUrl("Offers");
        this.getPolicy();
        this.getJournalsExclusiveProvisions();
    }

    ngOnDestroy() {
        if (this.houseHold != null) {
            this.householdProvider.sendExpandedHousehold(this.houseHold.representant.uuid);
        }
    }

    policyRowClick(policy: Policy) {
        const url = `${this.getUrl("MyCustomersDetail")}/policy/${policy.uuid}`;
        this.router.navigate([url]).then();
        this.selectedPolicyRowIndex = policy.uuid;
    }

    openOfferPage() {
        this.router.navigate([this.offerURL]).then();
    }

    private getUrl(routerName: string) {
        return `/${this.registerProvider.getPath(routerName)}/` +
            `${this.representativeUuid}/` +
            `${this.representativeUuid === this.selectedPersonUuid ? "" : this.selectedPersonUuid}`;
    }

    openActionDialog(event: Event) {
        this.isDialogOpen = true;
        event.stopPropagation();
        const dialogData: ActionDialogData = {
            transTypeId: BusinessTransactionHelper.typeId.customer.from,
            transName: this.titleService.getTitle(),
            transSubject: "",
            transTitle: this.titleService.getTitle(),
            transMessage: "",
            keyValues: [],
            person: this.person
        };
        this.dialogService.openByType(dialogData,
            {
                defaultTypeId: BusinessTransactionHelper.typeId.customer.from
            }, this.TAG
        );
        this.dialog.afterAllClosed.subscribe(() => {
            this.isDialogOpen = false;
        });
    }

    actionDataCallback(actionDialogData: ActionDialogData) {
        actionDialogData.person = this.person;
    }

    formatDate(date) {
        return Helper.formatDate(date);
    }

    openGmOfferDialog(): void {
        let persons = this.houseHold.persons;
        let representant = this.houseHold.representant;

        if (this.houseHold.representant.uuid !== this.selectedPersonUuid) {
            persons = this.houseHold.persons.filter(x => x.uuid === this.selectedPersonUuid);
            representant = this.houseHold.persons.find(x => x.uuid === this.selectedPersonUuid);
        }
        this.dialog.open(GMOfferDialogComponent, {
            width: '80%',
            minHeight: '80%',
            maxHeight: '90vh',
            panelClass: 'neomp-dialog-padding-none',
            data: {
                agentNumber: this.agentNumber,
                representant: representant,
                persons: persons
            }
        });
    }

}
