import { Component, OnInit } from '@angular/core';
import { PaginationConfigs } from 'app/modules/constants/PaginationConstants';
import { LoadingService } from 'app/modules/core/providers/loading.component';
import { LeadsService } from 'app/modules/leads/services/leads.service';

@Component({
  selector: 'neomp-termin-management',
  templateUrl: './termin-management.component.html',
  styleUrls: ['./termin-management.component.scss']
})
export class TerminManagementComponent implements OnInit {

  constructor(
    public loadService: LoadingService,
    private leadService: LeadsService
  ) { }

  ngOnInit(): void {
   
  }
 
}
