import {CustomersCache} from "../model/customers-cache";
import {Injectable} from "@angular/core";

@Injectable()
export class CustomersCacheProvider {
    private customerCache: CustomersCache;

    public getCache(): CustomersCache {
        return this.customerCache;
    }
    public setCache(customerCache: CustomersCache){
        this.customerCache = customerCache;
    }

}
