<h3 class="neo-leads-name">
  Max Mustermann <span class="leads-name">(Lead Name)</span>
</h3>
<mat-tab-group class="neo-border-none">
  <mat-tab label="Termin">
    <div class="mdc-layout-grid msg-helper-padding-none msg-helper-margin-top-10">
      <div class="mdc-layout-grid__inner">
        <div
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
        </div>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>

<div class="mdc-layout-grid msg-helper-padding-none leads-edit">
  <div class="mdc-layout-grid__inner msg-helper-grid-gap-horizontal">
    <div
      class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
      <div class="mdc-layout-grid__inner">
        <div
          class="mdc-layout-grid__cell--span-12 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-12-tablet">
          <section class="neo-color neo-padding-top-10">
            <div class="mdc-layout-grid__cell--span-12 mdc-layout-grid__cell--span-12-phone">
              <div class="mdc-layout-grid__inner">
                <div
                  class="mdc-layout-grid__cell--span-3 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                  <mat-form-field class="neo-leads-input-full-width">
                    <mat-label>Call Datum </mat-label>
                    <input class="font-family" matInput type="text" required />
                  </mat-form-field>
                </div>
                <div
                  class="mdc-layout-grid__cell--span-3 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet ">

                </div>

                <div
                  class="mdc-layout-grid__cell--span-3 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet ">
                  <mat-form-field class="neo-leads-input-full-width">
                    <mat-label>ID </mat-label>
                    <input class="font-family" matInput type="text" required />
                  </mat-form-field>
                </div>
                <div
                  class="mdc-layout-grid__cell--span-3 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet ">
                  <mat-form-field class="neo-leads-input-full-width">
                    <mat-label>Termin Wurde Bestaetigt</mat-label>
                    <mat-select placeholder="Design" required>
                      <mat-option>
                        test1
                      </mat-option>
                      <mat-option>
                        test2
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <br />
      <neomp-container cssClass="msg-mp-container-frameless" headlineText="Personalien">
        <div
          class="mdc-layout-grid mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone msg-helper-padding-none neo-color neo-space-padding">
          <section>
            <div class="mdc-layout-grid__inner">
              <div
                class="mdc-layout-grid__cell--span-3 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                <mat-form-field class="neo-leads-input-full-width">
                  <mat-label>Anrede </mat-label>
                  <input class="font-family" matInput type="text" required />
                </mat-form-field>
              </div>
              <div
                class="mdc-layout-grid__cell--span-3 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                <mat-form-field class="neo-leads-input-full-width">
                  <mat-label>Sprache </mat-label>
                  <input class="font-family" matInput type="text" required />
                </mat-form-field>
              </div>

              <div
                class="mdc-layout-grid__cell--span-3 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                <mat-form-field class="neo-leads-input-full-width">
                  <mat-label>Personen</mat-label>
                  <input class="font-family" matInput type="text" required />
                </mat-form-field>
              </div>
            </div>
          </section>
          <section>
            <div class="mdc-layout-grid__inner">
              <div
                class="mdc-layout-grid__cell--span-3 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                <mat-form-field class="neo-leads-input-full-width">
                  <mat-label>Name </mat-label>
                  <input class="font-family" matInput type="text" required />
                </mat-form-field>
              </div>
              <div
                class="mdc-layout-grid__cell--span-3 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                <mat-form-field class="neo-leads-input-full-width">
                  <mat-label>Vorname </mat-label>
                  <input class="font-family" matInput type="text" required />
                </mat-form-field>
              </div>
              <div
                class="mdc-layout-grid__cell--span-3 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                <mat-form-field class="neo-leads-input-full-width">
                  <mat-label>Telefon Natel </mat-label>
                  <input class="font-family" matInput type="text" required />
                </mat-form-field>
              </div>
              <div
                class="mdc-layout-grid__cell--span-3 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                <mat-form-field class="neo-leads-input-full-width">
                  <mat-label>Telefon P </mat-label>
                  <input class="font-family" matInput type="text" required />
                </mat-form-field>
              </div>
            </div>
          </section>
          <section>
            <div class="mdc-layout-grid__inner">
              <div
                class="mdc-layout-grid__cell--span-3 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                <mat-form-field class="neo-leads-input-full-width">
                  <mat-label>Strasse NR. </mat-label>
                  <input class="font-family" matInput type="text" required />
                </mat-form-field>
              </div>
              <div
                class="mdc-layout-grid__inner mdc-layout-grid__cell--span-3 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                <div class="mdc-layout-grid__cell--span-6">
                  <mat-form-field class="neo-leads-input-full-width">
                    <mat-label>PLZ </mat-label>
                    <input class="font-family" matInput type="text" required />
                  </mat-form-field>
                </div>
                <div class="mdc-layout-grid__cell--span-6">
                  <mat-form-field class="neo-leads-input-full-width">
                    <mat-label>Ort </mat-label>
                    <input class="font-family" matInput type="text" required />
                  </mat-form-field>
                </div>
              </div>
              <div
                class="mdc-layout-grid__cell--span-3 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                <mat-form-field class="neo-leads-input-full-width">
                  <mat-label>Kanton</mat-label>
                  <input class="font-family" matInput type="text" required />
                </mat-form-field>
              </div>
            </div>
          </section>
        </div>
      </neomp-container>
      <br />
      <div class="mdc-layout-grid__inner">
        <div
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">

          <div class="mdc-layout-grid__inner">
            <div
              class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone neo-padding-top-10">

              <neomp-container cssClass="msg-mp-container-frameless" headlineText="Termin">
                <div
                  class="mdc-layout-grid mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone msg-helper-grid-gap-none msg-helper-margin-none msg-helper-padding-10 neo-space-padding">
                  <section>
                    <div class="mdc-layout-grid__inner">
                      <div
                        class="mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                        <mat-form-field class="neo-leads-input-full-width">
                          <mat-label>Calendar </mat-label>
                          <input class="font-family" matInput type="text" required />
                        </mat-form-field>
                      </div>
                      <div
                        class="mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                        <mat-form-field class="neo-leads-input-full-width">
                          <mat-label>Termindatum </mat-label>
                          <input class="font-family" matInput type="text" required />
                        </mat-form-field>
                      </div>
                    </div>
                  </section>
                  <section>
                    <div class="mdc-layout-grid__inner">
                      <div
                        class="mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                        <mat-form-field class="neo-leads-input-full-width">
                          <mat-label>Zeit </mat-label>
                          <input class="font-family" matInput type="text" required />
                        </mat-form-field>
                      </div>
                      <div
                        class="mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                        <mat-form-field class="neo-leads-input-full-width">
                          <mat-label>Vermittler </mat-label>
                          <input class="font-family" matInput type="text" required />
                        </mat-form-field>
                      </div>
                    </div>
                  </section>
                </div>
              </neomp-container>
            </div>
            <div
              class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone neo-padding-top-10">
              <div
                class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
                <neomp-container cssClass="msg-mp-container-frameless"
                  headlineText="{{ 'PAGE.OFFER.TITLE' | translate }}">

                  <div
                    class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
                    <neomp-upload-file [isNested]="true" [showHeadline]="false">
                    </neomp-upload-file>
                  </div>

                </neomp-container>
              </div>
            </div>
          </div>
          <br />
          <div class="mdc-layout-grid__inner">
            <div
              class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone neo-padding-top-10">

              <neomp-container cssClass="msg-mp-container-frameless" headlineText="Erinnerungen">
                <div
                  class="mdc-layout-grid mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone msg-helper-padding-none neo-color neo-space-padding">
                  <div class="mdc-layout-grid__inner">
                    <div
                      class="mdc-layout-grid__cell--span-10 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-12-tablet">
                      <section>
                        <div class="mdc-layout-grid__cell--span-12">
                          <div class="mdc-layout-grid__inner">
                            <div
                              class="mdc-layout-grid__cell--span-3 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                              <mat-form-field class="neo-leads-input-full-width">
                                <mat-label>Kategorie </mat-label>
                                <input class="font-family" matInput type="text" required />
                              </mat-form-field>
                            </div>
                            <div
                              class="mdc-layout-grid__cell--span-3 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                              <mat-form-field class="neo-leads-input-full-width">
                                <mat-label>Termindatum</mat-label>
                                <input [matDatepicker]="termindatum" matInput required />
                                <mat-datepicker-toggle matSuffix [for]="termindatum"></mat-datepicker-toggle>
                                <mat-datepicker #termindatum></mat-datepicker>
                              </mat-form-field>
                            </div>
                            <div
                              class="mdc-layout-grid__cell--span-2 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                              <mat-form-field class="neo-leads-input-full-width">
                                <mat-label>Time </mat-label>
                                <input class="font-family" matInput type="text" required />
                              </mat-form-field>
                            </div>
                            <div
                              class="mdc-layout-grid__cell--span-4 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                              <mat-form-field class="neo-leads-input-full-width">
                                <mat-label>Bemerkung </mat-label>
                                <input class="font-family" matInput type="text" required />
                              </mat-form-field>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                    <div
                      class="mdc-layout-grid__cell--span-2 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-12-tablet">
                      <div class="neo-padding-top-10">
                        <button class="neo-float-right" mat-raised-button color="primary">
                          + + ERINNERUNG
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </neomp-container>
            </div>
          </div>
          <br />
          <section>
            <mat-form-field class="neo-leads-input-full-width">
              <mat-label>{{
                "PAGE.NEO-OFFER.OFFER.REMARKS" | translate
                }}</mat-label>
              <textarea matInput></textarea>
            </mat-form-field>
            <br />
            <mat-form-field class="neo-leads-input-full-width">
              <mat-label>{{
                "PAGE.NEO-OFFER.OFFER.REMARKS" | translate
                }}</mat-label>
              <textarea matInput></textarea>
            </mat-form-field>
          </section>
          <div>
            <button class="neo-float-left" mat-raised-button color="primary">
              SENDEN
            </button>
          </div>

        </div>
      </div>
      <div
        class="mdc-layout-grid mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone msg-helper-padding-none neo-color">
        <section class="checkbox-section">
          <div class="mdc-layout-grid__cell--span-12 mdc-layout-grid__cell--span-12-phone neo-padding-top-10">
            <div class="mdc-layout-grid__inner">
              <div
                class="mdc-layout-grid__cell--span-2 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                <mat-checkbox>Nicht besucht</mat-checkbox>
              </div>
              <div
                class="mdc-layout-grid__cell--span-2 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                <mat-checkbox>Nicht zu Hause</mat-checkbox>
              </div>
              <div
                class="mdc-layout-grid__cell--span-2 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                <mat-checkbox>Stattgefunden</mat-checkbox>
              </div>
              <div
                class="mdc-layout-grid__cell--span-2 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                <mat-checkbox>Offen</mat-checkbox>
              </div>
              <div
                class="mdc-layout-grid__cell--span-2 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                <mat-checkbox>Positiv</mat-checkbox>
              </div>
              <div
                class="mdc-layout-grid__cell--span-2 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
              </div>
            </div>
          </div>
        </section>
        <section class="checkbox-section">
          <div class="mdc-layout-grid__cell--span-12 mdc-layout-grid__cell--span-12-phone neo-padding-top-10">
            <div class="mdc-layout-grid__inner">
              <div
                class="mdc-layout-grid__cell--span-2 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                <mat-checkbox>Wollte kein Termin</mat-checkbox>
              </div>
              <div
                class="mdc-layout-grid__cell--span-2 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                <mat-checkbox>Behandlung</mat-checkbox>
              </div>
              <div
                class="mdc-layout-grid__cell--span-2 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                <mat-checkbox>MJV</mat-checkbox>
              </div>
              <div
                class="mdc-layout-grid__cell--span-2 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                <mat-checkbox>Negativ</mat-checkbox>
              </div>
              <div
                class="mdc-layout-grid__cell--span-2 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                <div class="neo-status-box mauve">
                  <div class="neo-status-text">KK-Abschlusse</div>
                  <input class="input-number" type="number" min="1" max="9" value="1">
                </div>
              </div>
              <div
                class="mdc-layout-grid__cell--span-2 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
              </div>
            </div>
          </div>
        </section>
      </div>
      <div
        class="mdc-layout-grid mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone msg-helper-padding-none neo-color">
        <div class="neo-h1-bold-heading neo-padding-top-10">Andere Abschlusse</div>
        <section class="neo-padding-top-10">
          <div class="mdc-layout-grid__cell--span-12 mdc-layout-grid__cell--span-12-phone neo-padding-top-10">
            <div class="mdc-layout-grid__inner">
              <div
                class="mdc-layout-grid__cell--span-2 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                <div class="neo-status-box nude">
                  <div class="neo-status-text">Lebensversicherung</div>
                  <input class="input-number" type="number" min="1" max="9" value="1">
                </div>
              </div>
              <div
                class="mdc-layout-grid__cell--span-2 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                <div class="neo-status-box green">
                  <div class="neo-status-text">Autoversicherung</div>
                  <input class="input-number" type="number" min="1" max="9" value="1">
                </div>
              </div>
              <div
                class="mdc-layout-grid__cell--span-2 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                <div class="neo-status-box yellow">
                  <div class="neo-status-text">Hausrat</div>
                  <input class="input-number" type="number" min="1" max="9" value="1">
                </div>
              </div>
              <div
                class="mdc-layout-grid__cell--span-2 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                <div class="neo-status-box lightblue">
                  <div class="neo-status-text">Haftpflicht</div>
                  <input class="input-number" type="number" min="1" max="9" value="1">
                </div>
              </div>
              <div
                class="mdc-layout-grid__cell--span-2 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                <div class="neo-status-box purple">
                  <div class="neo-status-text">Rechtsschultz</div>
                  <input class="input-number" type="number" min="1" max="9" value="1">
                </div>
              </div>
              <div
                class="mdc-layout-grid__cell--span-2 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                <div class="neo-status-box orange">
                  <div class="neo-status-text">Mandate</div>
                  <input class="input-number" type="number" min="1" max="9" value="1">
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="neo-padding-top-10">
          <div class="mdc-layout-grid__cell--span-12 mdc-layout-grid__cell--span-12-phone neo-padding-top-10">
            <div class="mdc-layout-grid__inner">
              <div
                class="mdc-layout-grid__cell--span-2 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                <div class="neo-status-box blue">
                  <div class="neo-status-text">KMU</div>
                  <input class="input-number" type="number" min="1" max="9" value="1">
                </div>
              </div>
              <div
                class="mdc-layout-grid__cell--span-2 mdc-layout-grid__cell--span-12-phone mdc-layout-grid__cell--span-6-tablet">
                <div class="neo-status-box brown">
                  <div class="neo-status-text">Steurerklarung</div>
                  <input class="input-number" type="number" min="1" max="9" value="1">
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <br />
    <div
      class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone neo-padding-top-10">
      <neomp-container cssClass="msg-mp-container-frameless" headlineText="Familienmitglieder"> 
        <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort class="shadow">
          <ng-container class="edit-termin-table-column-width" matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef class="column-w-20"> {{ "PAGE.NEO-OFFER.COMMON.NAME" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              <mat-form-field appearance="none">
                <input type="text" matInput [value]="element.name" placeholder="{{
                    'PAGE.CUSTOMERDETAIL.CONTACT.NAME' | translate
                  }}" required />
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="geschiecht">
            <th mat-header-cell *matHeaderCellDef class="column-w-20"> {{ "PAGE.NEO-OFFER.OFFER.GENDER" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              <mat-form-field appearance="none">
                <input type="text" matInput [value]="element.geschiecht" placeholder="{{
                    'PAGE.CUSTOMERDETAIL.CONTACT.GENDER' | translate 
                  }}" required />
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="geburtsdatum">
            <th mat-header-cell *matHeaderCellDef class="column-w-20"> {{ "PAGE.CUSTOMERDETAIL.CONTACT.BIRTH" |
              translate }} </th>
            <td mat-cell *matCellDef="let element">
              <mat-form-field appearance="none">
                <input type="text" matInput [value]="element.geburtsdatum" placeholder="{{
                    'PAGE.CUSTOMERDETAIL.CONTACT.BIRTH' | translate  
                  }}" required />
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="nationalitat">
            <th mat-header-cell *matHeaderCellDef class="column-w-20"> {{ "PAGE.CUSTOMERDETAIL.CONTACT.NATIONALITY" |
              translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              <mat-form-field appearance="none">
                <input type="text" matInput [value]="element.nationalitat" placeholder="{{
                    'PAGE.CUSTOMERDETAIL.CONTACT.NATIONALITY' | translate 
                  }}" required />
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef class="column-w-20">
              {{ "PAGE.NEO-OFFER.OFFER.ACTION" | translate }}
            </th>
            <td mat-cell *matCellDef="let person">
              <button mat-raised-button color="warn" (click)="deletePerson(person)">
                {{ "PAGE.NEO-OFFER.OFFER.REMOVE-PERSON" | translate }}
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </neomp-container>
      
      <div class="demo-button-container">
        <div class="neo-margin-bottom">
          <button (click)="addData()" mat-raised-button color="primary">
            {{ "PAGE.NEO-OFFER.OFFER.ADD-DATA" | translate }}
          </button><br>
        </div>
        <div>
          <button mat-raised-button color="lead">
            SPEICHERN
          </button>
        </div>
      </div>
    </div>

  </div>
</div>