import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DateAdapter } from "@angular/material/core";
import { log } from "../providers/logger.provider";
import { DEFAULT_LANGUAGE, LANGUAGES } from "environments/environment";

@Component({
    selector: "neomp-language-change",
    templateUrl: "./language-change.component.html",
    styleUrls: ["./language-change.component.scss"]
})
export class LanguageChangeComponent implements OnInit {
    private readonly TAG: string = this.constructor.name;
    selectedLanguage = localStorage.getItem("Language") || DEFAULT_LANGUAGE;

    static tryGetLanguage(language: string, defaultLanguage: string = DEFAULT_LANGUAGE,
        matcher: { [Symbol.match](string: string) } = /de|en|fr|it/): string {
        return language.match(matcher) ? language : defaultLanguage;
    }

    constructor(public translate: TranslateService, public dateAdapter: DateAdapter<any>) { }

    ngOnInit() {
        log.debug(`${this.TAG} ngOnInit() -> setting default language to ${this.selectedLanguage}`);
        this.translate.addLangs(LANGUAGES);
        this.translate.setDefaultLang(DEFAULT_LANGUAGE);
        this.changeLanguage(this.selectedLanguage);
    }

    changeLanguage(language: string) {
        log.debug(`${this.TAG} changeLanguage() -> language to apply ${language}; by default "${DEFAULT_LANGUAGE}"`);
        this.selectedLanguage = LanguageChangeComponent.tryGetLanguage(language); // anguage.match(/de/) ? language : "de";
        this.translate.use(this.selectedLanguage);
        this.dateAdapter.setLocale(this.selectedLanguage);
        localStorage.setItem("Language", language)
    }

}
