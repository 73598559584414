import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import * as io from 'socket.io-client';
import { Socket } from 'socket.io-client';
import { IAgent } from '../interfaces/Iagent';
import { IMessage } from '../interfaces/Imessage';
import { IRoom } from '../interfaces/Iroom';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable()
export class LivechatService {
  socket!: Socket;
  archivable = new BehaviorSubject<boolean>(true);

  rooms$ = new BehaviorSubject<IRoom[] | null>(null);
  agent$ = new BehaviorSubject<IAgent | null>(null);
  newMessages$ = new BehaviorSubject<number>(0);
  onlineRooms$ = new BehaviorSubject<number>(0);

  connected$ = new BehaviorSubject<boolean>(false);

  listOfAgents = new BehaviorSubject<IAgent[]>([]);

  agent: IAgent | null = null;
  // rooms$!: any;
  rooms!: IRoom[];

  constructor(private http: HttpClient, private router: Router, private keycloak: KeycloakService) {}

  connectSocket() {
    this.socket = io.connect(environment.settings.LIVECHAT_URL);
    this.socket.on('connect', () => this.connected$.next(true));
    this.socket.on('disconnect', () => this.connected$.next(false));
  }

  // Join new Room
  join(room: string | string[]) {
    this.connected$.subscribe((connected) => {
      if (connected) {
        this.socket.emit('join', { room });
      }
    });
  }

  // Disconnects Socket
  disconnect() {
    this.socket.disconnect();
    this.connected$.next(false);
  }

  // Emit Event
  emit(event: string, data?: any) {
    this.socket.emit(event, data);
  }
  // Listen to Event
  listen(event: string): Observable<any> {
    return new Observable((observer) => {
      this.socket.on(event, (data) => {
        observer.next(data);
      });
      return () => this.socket.off(event);
    });
  }

  hasListener(event: string) {
    return this.socket.hasListeners(event);
  }

  removeListener(event: string){
    return this.socket.removeListener(event);
  }

  onAgentLogout() {
    this.agent$.next(null);
    this.keycloak.logout();
    this.router.navigate(['support']);
    this.disconnect();
    localStorage.removeItem('loggedAgent');
  }

  getAgentRooms(agent: IAgent) {
      this.http.get(`${environment.settings.LIVECHAT_URL}/api/agent/rooms/${agent.id}`)
        .subscribe((payout: any) => { this.rooms$.next(payout.rooms); });
  }

  getAgentRoomsObs(agent: IAgent) {
    return this.http.get<IRoom[]>(
      `${environment.settings.LIVECHAT_URL}/api/agent/rooms/${agent?.id}`
    );
  }

  getRole(role: string){
    return this.http.get(`${environment.settings.LIVECHAT_URL}/api/agent/role/${role}`);
  }

  newMessage(message: IMessage, roomId: string) {
    let filteredRoom = this.rooms$.value?.filter(
      (fRoom) => fRoom._id === roomId
    );
    if (filteredRoom !== undefined) {
      filteredRoom[0].newMessages!++;
      filteredRoom[0].messages.push(message);
      let roomsTemp = this.rooms$.value;
      roomsTemp?.sort((a, b) => new Date(b?.messages[b.messages?.length-1]?.date).getTime() - new Date(a?.messages[a.messages?.length-1]?.date).getTime());
      this.updateRooms(this.rooms$.value);
    }
  }

  addNewRoom(room: IRoom) {
    this.rooms$.value?.unshift(room);
    this.rooms$.next(this.rooms$.value);
  }

  updateRooms(rooms: IRoom[] | null) {
    this.rooms$.next(rooms);
  }

  updateRoom(room: IRoom | null) {
    try {
      let filteredRooms = this.rooms$.value?.filter(
        (fRoom) => fRoom._id === room!._id
      );
      if (filteredRooms !== undefined) {
        filteredRooms[0] = room!;
        this.updateRooms(this.rooms$.value);
      }
    } catch (error) {  }
  }

  clientStatusUpdate(room: IRoom) {
    let filteredRoom = this.rooms$.value?.filter(
      (fRoom) => fRoom._id === room!._id
    );
    if (filteredRoom !== undefined) {
      filteredRoom[0].clientStatus = room.clientStatus;
      this.rooms$.next(this.rooms$.value);
    }
  }

  getRoom(id: string) {
    if (this.rooms$.value !== null) {
      let room = this.rooms$.value?.find(
        (room) => room._id === id || room.roomId === id
      );
      return room;
    } else {
      let room;
      if(this.agent$.value) this.getAgentRoomsObs(this.agent$.value)
      .pipe(map((value: any) => {
          this.rooms$.next(value.rooms);
          let rooms = value.rooms;
          let room = rooms.find((room: any) => room._id === id || room.roomId === id);
          return room;
        })
      )
      .subscribe((value) => {
        room = value;
      });
      return room;
    }
  }

  sendMessage(message: string, roomId: string) {
    let newMessage: IMessage = { message: message, sentBy: this.agent$.value!, date: new Date() };
    this.emit('message', { message: newMessage, roomId: roomId });
    let room = this.rooms$.value?.filter((room) => room._id === roomId);
    if (room !== undefined) {
      room[0].messages.push(newMessage);
      let roomsTemp = this.rooms$.value;
      roomsTemp?.sort((a, b) => {

        return  new Date(b?.messages[b.messages?.length-1]?.date).getTime() - new Date(a?.messages[a.messages?.length-1]?.date).getTime()

      } );
      this.rooms$.next(this.rooms$.value);
    }
  }

  archiveChat(roomV: IRoom) {
    let fRoom = this.rooms$.value?.filter((room) => room._id === roomV._id);
    if (fRoom !== undefined && fRoom[0]) {
      fRoom[0].status = roomV.status;
      let roomsTemp = this.rooms$.value;
      roomsTemp?.sort((a, b) => new Date(b?.messages[b.messages?.length-1]?.date).getTime() - new Date(a?.messages[a.messages?.length-1]?.date).getTime());
      this.rooms$.next(this.rooms$.value);
    }
  }

  updateRoomStatus(room: IRoom, status: boolean){
    return this.http.post(`${environment.settings.LIVECHAT_URL}/api/agent/update-room-status`, { room, status });
  }

  reinitChat(roomV: IRoom) {
    let fRoom = this.rooms$.value?.filter((room) => room._id === roomV._id);
    if (fRoom !== undefined) {
      fRoom[0].clientStatus = true;
      fRoom[0].status = true;
      let roomsTemp = this.rooms$.value;
      roomsTemp?.sort((a, b) => new Date(b?.messages[b.messages?.length-1]?.date).getTime() - new Date(a?.messages[a.messages?.length-1]?.date).getTime());
      this.rooms$.next(this.rooms$.value);
    }
  }

  changeMessageStatus(status: string, roomId: string){
    let fRoom = this.rooms$.value?.find((room) => room._id === roomId)
    if(fRoom !== undefined){
      fRoom.messages.forEach(message => {
        if(status === 'delivered'){
          if(message.deliveredStatus !== 'read'){
            message.deliveredStatus = status;
          }
        } else if (status === 'sent'){
          if(message.deliveredStatus !== 'read' && message.deliveredStatus !== 'delivered'){
            message.deliveredStatus = status;
          }
        } else if (status === 'read'){
          message.deliveredStatus = status;
        }
      });
    }
  }

  noNewMessages(roomId: string){
    setTimeout(()=>{

    let fRoom = this.rooms$.value?.filter(room => room._id === roomId);
    if(fRoom !== undefined){
        fRoom[0].newMessages = 0;
        let roomsTemp = this.rooms$.value;
        roomsTemp?.sort((a, b) => new Date(b?.messages[b.messages?.length-1]?.date).getTime() - new Date(a?.messages[a.messages?.length-1]?.date).getTime());
        this.rooms$.next(this.rooms$.value);
      }
    },1)
  }

  activateChat(roomId: string, agent: IAgent){
    return this.http.post(`${environment.settings.LIVECHAT_URL}/api/admin/activate-chat`, {roomId, agent});
  }

  registerAgent(agent: any, agentOriginal: any){
    return this.http.post(`${environment.settings.LIVECHAT_URL}/api/agent/register-agent`, {agent, agentOriginal});
  }

  getListOfAgents(){
    return this.http.get(`${environment.settings.LIVECHAT_URL}/api/admin/get-all-agents`);
  }


  livechatInit(listenerSubscriptions: Subscription[]) {
    this.keycloak.loadUserProfile().then((res: any) => {
      this
        .getRole(res.attributes.livechatRole[0])
        .subscribe((payout: any) => {
          let role = payout.role;
          let agent: IAgent = {
            name: res.firstName,
            lastName: res.lastName,
            email: res.email,
            id: res.attributes.advisorid[0],
            categoryId: res.attributes.categoryId[0],
            role: role,
          };
          // this.router.navigate(['/livechat/chats']);
          this
            .registerAgent(agent, res)
            .subscribe((payout) => {});
          this.agent$.next(agent);
          if(this.agent) this.getAgentRooms(this.agent);
          if(!this.connected$.value) {
            this.connectSocket();
            this.emit('agent-logged-on', {
              agentId: res.attributes.advisorid[0],
            });
          }
        });
    });

    listenerSubscriptions.forEach((s) => s.unsubscribe());
    this.agent$.subscribe((value) => { this.agent = value; });

    if(this.agent) this.getAgentRooms(this.agent);

    this.rooms$.subscribe((value) => {
      if (value !== null) {
        let roomIds = [];
        for (let room of value) {
          roomIds.push(room.roomId ? room.roomId : room._id);
        }
        this.join(roomIds);
      }
    });

    this.connected$.subscribe((valid) => {
      if (valid) {
        if (!this.hasListener('room-created')) {
          listenerSubscriptions.push(
            this
              .listen('room-created')
              .subscribe((payout: { room: IRoom }) => {
                if (
                  payout.room.agentId === this.agent?.id ||
                  this.agent?.role.globalPerms
                ) { this.addNewRoom(payout.room); }
              })
          );
        }

        if (!this.hasListener('client-reinited-chat')) {
          listenerSubscriptions.push(
            this
              .listen('client-reinited-chat')
              .subscribe((payout: { room: IRoom }) => {
                this.reinitChat(payout.room);
              })
          );
        }

        if (!this.hasListener('message-2nd')) {
          listenerSubscriptions.push(
            this
              .listen('message-2nd')
              .subscribe((payout: { message: IMessage; roomId: string }) => {
                this.emit('deliver', {
                  message: payout.message,
                  roomId: payout.roomId,
                });
                this.newMessage(payout.message, payout.roomId);
              })
          );
        }

        if (!this.hasListener('delivered')) {
          listenerSubscriptions.push(
            this
              .listen('delivered')
              .subscribe((payout: { roomId: string }) => {
                this.changeMessageStatus(
                  'delivered',
                  payout.roomId
                );
              })
          );
        }

        if (!this.hasListener('read')) {
          listenerSubscriptions.push(
            this
              .listen('read')
              .subscribe((payout: { roomId: string }) => {
                this.changeMessageStatus('read', payout.roomId);
              })
          );
        }

        if (!this.hasListener('room-updated')) {
          listenerSubscriptions.push(
            this
              .listen('room-updated')
              .subscribe((payout: { room: IRoom }) => {
                this.clientStatusUpdate(payout.room);
              })
          );
        }

        if (!this.hasListener('room-status-updated')) {
          listenerSubscriptions.push(
            this
              .listen('room-status-updated')
              .subscribe((payout: { room: IRoom }) => {
                this.archiveChat(payout.room);
              })
          );
        }

        if (!this.hasListener('room-archived')) {
          listenerSubscriptions.push(
            this
              .listen('room-archived')
              .subscribe((payout: { room: IRoom }) => {
                this.archiveChat(payout.room);
              })
          );
        }

        if (!this.hasListener('ticket-assigned')) {
          listenerSubscriptions.push(
            this
              .listen('ticket-assigned')
              .subscribe((payout: any) => {
                if (this.agent && this.agent?.id === payout.agentId) {
                  this.getAgentRooms(this.agent);
                }
              })
          );
        }

        if (!this.hasListener('ticket-created')) {
          listenerSubscriptions.push(
            this
              .listen('ticket-created')
              .subscribe((payout: any) => {
                if (this.agent && this.agent?.id === payout.agentId) {
                  this.getAgentRooms(this.agent);
                }
              })
          );
        }
      } else {
        listenerSubscriptions.forEach((s: Subscription) => { s.unsubscribe(); });
      }
    });
}

  
}
