<mat-tab-group (selectedTabChange)="tabChanged($event)">
    <mat-tab label="{{ 'PAGE.CONTAINER.OFFERS.TAB.OFFER' | translate}}">
        <div class="mdc-layout-grid msg-helper-padding-none msg-helper-margin-top-10">
            <div class="mdc-layout-grid__inner">
                <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop
                            mdc-layout-grid__cell--span-12-tablet
                            mdc-layout-grid__cell--span-12-phone">
                    <!-- <neomp-offer-wizard></neomp-offer-wizard> -->
                    <lib-neo-offer></lib-neo-offer>
                </div>
            </div>
        </div>
    </mat-tab>
    <mat-tab label="{{ 'PAGE.CONTAINER.OFFERS.TAB.REQUEST' | translate}}">
        <div class="mdc-layout-grid msg-helper-padding-none msg-helper-margin-top-10">
            <div class="mdc-layout-grid__inner">
                <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop
                            mdc-layout-grid__cell--span-12-tablet
                            mdc-layout-grid__cell--span-12-phone">
                    <neomp-created-requests *ngIf="selectedTabIndex === 1"></neomp-created-requests>
                </div>
            </div>
        </div>
    </mat-tab>
    <mat-tab label="{{ 'PAGE.CONTAINER.OFFERS.TAB.GMREQUEST' | translate}}">
        <div class="mdc-layout-grid msg-helper-padding-none msg-helper-margin-top-10">
            <div class="mdc-layout-grid__inner">
                <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop
                            mdc-layout-grid__cell--span-12-tablet
                            mdc-layout-grid__cell--span-12-phone">
                    <neomp-gm-created-offers *ngIf="selectedTabIndex === 2"></neomp-gm-created-offers>
                </div>
            </div>
        </div>
    </mat-tab>

</mat-tab-group>