import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { SelectedPersonEmitter } from "../../core/model/selectedPersonEmitter";
import { Household } from "../../core/model/household";
import { TranslateService } from "@ngx-translate/core";
import { KeycloakService } from "keycloak-angular";
import { environment } from "environments/environment";
import { HttpParams } from "@angular/common/http";
import { compressToEncodedURIComponent, decompressFromEncodedURIComponent } from "lz-string";


@Component({
    selector: "neomp-family-table-expand-detail",
    templateUrl: "./family-table-expand-detail.component.html",
    styleUrls: ["./family-table-expand-detail.component.scss"]
})
export class FamilyTableExpandDetailComponent implements OnInit {
    @Input()
    dataSourceExpandDetail: Household;
    @Input()
    selectedPerson;
    @Input()
    showStatus = false;
    @Output()
    selectedPersonEmitter = new EventEmitter<SelectedPersonEmitter>();
    selectedRowIndex;
    advisorId: string;
    queryParams: any;
    compressedData: string;
    displayedColumns = [];
    copyText: string;
    referralUrl: string;
    matTooltipHideDelay = 0;
    age: number;
    constructor(
        public translate: TranslateService,
        public keycloak: KeycloakService,
    ) {
    }

    ngOnInit() {
        this.keycloak.loadUserProfile().then((res: any) =>
            this.advisorId = res.attributes.advisorid[0]
        )
        this.copyText = this.translate.instant('HEADER.COPY');
        this.selectedRowIndex = this.selectedPerson
        if (this.showStatus) {
            this.displayedColumns.push("status");
        }
        this.displayedColumns.push("name", "birth", "gender", "appStatus");
    }
    checkAge(val: any) {
        var timeDiff = Math.abs(Date.now() - new Date(val).getTime());
        this.age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
        if (this.age < 18) {
            return false;
        }
        return true
    }

    compressData(data: any): string {
        return compressToEncodedURIComponent(JSON.stringify(data));
    }


    copyMessage(val: string) {
        this.queryParams = {
            advId: this.advisorId,
            uuid: val,
        }

        this.compressedData = this.compressData(this.queryParams);
        this.referralUrl = environment.settings.REGISTER_MYNEO + "?query=" + this.compressedData;
        this.matTooltipHideDelay = 500;
        this.copyText = this.translate.instant('HEADER.COPIED');
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = this.referralUrl;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        setTimeout(() => {
            this.copyText = this.translate.instant('HEADER.COPY');
        }, 1000);
        this.matTooltipHideDelay = 0;

    }

    personRowClick(detailrow) {
        this.selectedRowIndex = detailrow.uuid;
        this.selectedPersonEmitter.emit(
            {
                representativeUuid: this.dataSourceExpandDetail.representant.uuid,
                selectedPersonUuid: detailrow.uuid
            });
    }

}
