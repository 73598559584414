import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";

@Injectable({
    providedIn: "root"
})
export class CommunicationService {

    private subject = new Subject<any>();

    constructor() {
    }

    sendMessage(type: string, message: string = "") {
        this.subject.next({
            type: type,
            text: message
        });
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }
}
