import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {CoreModule} from "../core/core.module";
import {ApplicationsPageComponent} from "./pages/applications-page.component";
import { CancellationManagementComponent } from './cancellation-management/cancellation-management.component';
import { ActionDataProvider } from "../core/providers/action-data.provider";

@NgModule({
    declarations: [
        ApplicationsPageComponent,
        CancellationManagementComponent
    ],
    imports: [
        CommonModule,
        CoreModule
    ],
    exports: [
        ApplicationsPageComponent,
        CancellationManagementComponent
        
    ],
    providers: [ActionDataProvider]
})
export class ApplicationsModule {
}
