
<div *ngIf="neoLabel && neoLabel.length > 0" class="neo-vgr-input-margin ">
  {{neoLabel}}
  <span *ngIf="neoRequired === true && neoShowRequired === true" class="neo-contact-form-required">
      *
  </span>
</div>
<mat-form-field class="neo-input-autocompleter-component" style="width: 100%">
  <input matInput type="text"
         ngClass="{{ showErrorCss ? 'neo-message-error-input' : '' }}"
         placeholder="{{neoPlaceholder}}"
         [formControl]="myControl"
         [matAutocomplete]="auto">
  <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let option of filteredOptions | async"
                  [value]="option"
                  class="neo-vgr-input-field-text">
          {{ option.value }}
      </mat-option>
  </mat-autocomplete>
</mat-form-field>
