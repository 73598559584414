import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Journal } from '../model/journal';

@Component({
  selector: 'neomp-messages-dialog',
  templateUrl: './messages-dialog.component.html',
  styleUrls: ['./messages-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MessagesDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {  }
}
export interface DialogData {
  relatedMessages: Journal[];
  relatedMsgJournalData: Journal | null;
}