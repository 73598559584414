import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DateRange } from "@angular/material/datepicker";
import * as moment from "moment";
import { SlideRangeFilterValue } from "../../model/slide-range-filter-value";

@Component({
    selector: "neomp-date-picker",
    templateUrl: "./date-picker.component.html",
    styleUrls: ["./date-picker.component.scss"]
})

export class DatePickerComponent implements OnChanges {
    @Input() disabled = false;
    @Input() isFilterActive = false;
    @Input() setDefaultValues = true;

    @Output() filterValue: EventEmitter<SlideRangeFilterValue> = new EventEmitter<SlideRangeFilterValue>();

  
    dateForm = new FormGroup({
        begin: new FormControl(moment().subtract(60, "days"), [Validators.required]),
        end: new FormControl(moment(), [Validators.required])
    });

    ngOnChanges(changes: SimpleChanges): void {
        if ('setDefaultValues' in changes) {
            if (changes.setDefaultValues.currentValue) {
                this.dateForm.patchValue({
                    begin: moment().subtract(60, "days"),
                    end: moment()
                });
            } else {
                this.dateForm.patchValue({
                    begin: "",
                    end: ""
                });
            }
        }
    }
    pickerClosed(): void {
        if (this.dateForm.valid) {
            this.filterValue.emit({
                isActive: this.isFilterActive,
                dateRange: {
                    begin: this.dateForm.value.begin,
                    end: this.dateForm.value.end
                }
            })
        } else {
            this.filterValue.emit({
                isActive: this.isFilterActive,
                dateRange: null
            })
        }
    }
}
