import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { PaginationConfigs } from 'app/modules/constants/PaginationConstants';

@Component({
  selector: 'neomp-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnChanges {
  @Input() pageSize = PaginationConfigs.pageSize;
  @Input() pageIndex = PaginationConfigs.pageIndex;
  @Input() length = 0;
  @Output() paginatorChange: EventEmitter<PageEvent> = new EventEmitter();


  @Input() pageSizeOptions = PaginationConfigs.pageSizeOptions;
  pageNumbers: number[];


  ngOnChanges(changes: SimpleChanges): void {
    if ('length' in changes) {
      this.createPagesList();
    }

    if ('pageIndex' in changes) {
      this.pageIndex = Number(changes.pageIndex.currentValue);
    }

    if ('pageSize' in changes) {
      this.createPagesList();
    }
  }

  createPagesList() {
    this.pageNumbers = [];
    for (let i = 0; i < Math.ceil(this.length / this.pageSize); i++) {
      this.pageNumbers.push(i);
    }
    if (this.pageNumbers.indexOf(this.pageIndex) == -1) {
      this.resetPageIndex();
    }
  }

  resetPageIndex(): void {
    this.pageIndex = PaginationConfigs.pageIndex;
    this.paginatorChange.next(
      {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      } as PageEvent
    );
  }

  emitPageEvent(pageEvent: PageEvent): void {
    this.paginatorChange.next(pageEvent);
  }

}
