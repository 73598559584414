export enum AgenturType {
    GeneralAgent = "01",
    TeamLead = "02",
    Agent = "03",
    SalesManager = "05",
    Admin = "07",
    Client = "100",
    Supplier = "200",
    ServiceAgent  = "20"
}
