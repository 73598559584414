import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaginationConfigs } from 'app/modules/constants/PaginationConstants';
import { LeadQueryParams } from 'app/modules/core/json/leadQueryParams.json';
import { distinctUntilChanged } from 'rxjs/operators';
import { format } from "date-fns";
import { FormControl } from '@angular/forms';
import { MatTabGroup } from '@angular/material/tabs';
import { LoadingDataComponent } from 'app/modules/core/components/loading-data.component';
import { RestProviderActions } from 'app/modules/core/providers/rest.provider';
import { Person } from 'app/modules/core/model/person';
import { Subscription } from 'rxjs';
import { LeadProvider } from 'app/modules/core/providers/lead.provider';
import { LeadsService } from '../../services/leads.service';
import { PageEvent } from '@angular/material/paginator';
import { KeyValue } from 'app/modules/core/model/key-value';
import { ConfigProvider } from "app/modules/core/providers/config.provider";
import { Config } from "app/modules/core/model/config";


@Component({
  selector: 'neomp-retention',
  templateUrl: './retention.component.html',
  styleUrls: ['./retention.component.scss']
})
export class RetentionComponent implements OnInit {


  @ViewChild("tabGroup") tabGroup: MatTabGroup;
  @ViewChild("loadingFamilyTable") loadingFamilyTable: LoadingDataComponent;

  subscriptions: Subscription[] = [];



  retentionLeads: Person[] = [];
  totalCountRetentionLeads: number = 0;
  leadsWithBT: Person[] = [];
  totalNeuLeads = 0;
  pageSize = PaginationConfigs.pageSize;
  pageSizeOptions = PaginationConfigs.pageSizeOptions;
  pageIndex = PaginationConfigs.pageIndex;
  allStatuses: KeyValue[] = [];
  statusesList: string[] = [];
  substatusesList: any[] = [];
  statusSelected = new FormControl("");
  substatusSelected = new FormControl("");
  searchText: string | null = null;
  dateFrom: any;
  dateTo: any;
  isRetention: boolean = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public leadProvider: LeadProvider,
    public leadService: LeadsService,
    private configProvider: ConfigProvider,


  ) { }

  ngOnInit(): void {
    this.getConfigs();
  }

  async ngAfterViewInit() {
    //In case the user comes back, remember the old search filters
    await this.route.queryParams
      .pipe(distinctUntilChanged((prev: any, curr: LeadQueryParams) => JSON.stringify(prev) === JSON.stringify(curr)))
      .subscribe((params: any) => {
        if (params.st) {

          this.searchFromParams(params);
          this.dateFrom = format(new Date(this.route.snapshot.queryParams.dateFrom.toString()), "YYYY-MM-DDT00:00:00.000")
          this.dateTo = format(new Date(this.route.snapshot.queryParams.dateTo.toString()), "YYYY-MM-DDT23:59:00.000")
        } else {
          const queryParamObj = {
            dateFrom: this.dateFrom == "Invalid Date" ? "" : this.dateFrom,
            dateTo: this.dateTo == "Invalid Date" ? "" : this.dateTo,
            status: this.statusSelected.value,
            text: this.searchText,
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
          };
          
          this.updateQueryParams('/retention', 'retention', queryParamObj)
        }
      });
  }

  updateQueryParams(path: string, st: string, queryParams: LeadQueryParams): void {

    this.router.navigate([path], {
      queryParams: {
        st,
        dateFrom: queryParams?.dateFrom || "",
        dateTo: queryParams?.dateTo || "",
        text: queryParams?.text || this.searchText || "",
        status: queryParams?.status,
        lead_substatusList: queryParams?.lead_substatusList,
        pageIndex: queryParams?.pageIndex,
        pageSize: queryParams?.pageSize
      }
    });
  }


  searchFromParams(params: LeadQueryParams) {
    this.dateFrom = params.dateFrom || undefined;
    this.dateTo = params.dateTo || undefined;
    this.searchText = params.text || "";
    let statusesList = params.status as any;
    if (typeof params.status === 'string') {
      statusesList = params.status.split(",");
    }
    this.statusSelected.setValue(statusesList);

    let leadSubstatusesList = params.lead_substatusList as any;
    if (typeof params.lead_substatusList === 'string') {
      leadSubstatusesList = params.lead_substatusList.split(",");
    }
    this.substatusSelected.setValue(leadSubstatusesList);
    
    // Get substatus list for selected status
    this.substatusesList = this.getSubstatuses(this.statusSelected.value);




    this.pageIndex = params.pageIndex || PaginationConfigs.pageIndex;
    this.pageSize = Number(params.pageSize) > 0 ? Number(params.pageSize) : PaginationConfigs.pageSize;
    // this.lead_leadCategory = params.lead_leadCategory?.split(",") || [];
    if (params.st == 'retention') {
      this.tabGroup.selectedIndex = 0;
      // "true" loads leads from db
      this.searchLeads(true);
    }



  }

  retentionLeadsPaginatorValueChanged(event: PageEvent) {
    if (event.pageIndex !== this.pageIndex || event.pageSize !== this.pageSize) {
      this.pageIndex = event.pageIndex;
      this.pageSize = event.pageSize;
      this.searchLeads();
    }
  }

  searchLeads(loadLeads = false) {
    var statusSelected = Array.isArray(this.statusSelected.value) ? this.statusSelected.value.join(',') : "";
    var substatusSelected = Array.isArray(this.substatusSelected.value) ? this.substatusSelected.value.join(',') : "";
    var category = "retention";
    const dateFrom = this.dateFrom == undefined || this.dateFrom == "Invalid Date" ? "" : format(new Date(this.dateFrom.toString()), "YYYY-MM-DDT00:00:00.000")
    const dateTo = this.dateTo == undefined || this.dateTo == "Invalid Date" ? "" : format(new Date(this.dateTo.toString()), "YYYY-MM-DDT23:59:00.000")
    let filter = `dateFrom=${dateFrom}&dateTo=${dateTo}&status=${statusSelected}&lead_substatusList=${substatusSelected}&lead_leadCategory=${category}&isRetention=${this.isRetention}`

    if (![null, undefined, ""].includes(this.searchText)) {
      filter += `&text=${this.searchText}`
    }

    // Load leads from db
    
      this.loadLeads(
        this.loadingFamilyTable,
        this.pageIndex,
        this.pageSize,
        filter
      );
    

    const queryParamObj = {
      dateFrom,
      dateTo,
      status: this.statusSelected.value,
      lead_substatusList: this.substatusSelected.value,
      text: this.searchText,
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,

    }

    this.updateQueryParams('/retention', this.route.snapshot.queryParams.st, queryParamObj);
  }

  clearSearch() {
    this.searchText = '';
    this.dateFrom = null;
    this.dateTo = null;
    this.statusSelected.reset();
    this.substatusSelected.reset();
    this.substatusesList = [];
    this.searchLeads();
  }

  loadLeads(
    restProviderActions: RestProviderActions,
    page: number,
    size: number,
    filter?: string) {
    this.retentionLeads = [];
    filter = filter != "" ? filter + '&isManagement=false' : 'isManagement=false';
    this.subscriptions.push(
      this.leadProvider
        .getLazyLeads(restProviderActions, page, size, filter)
        .subscribe((leads: { data: Person[]; totalCount: number }) => {
          this.totalCountRetentionLeads = leads.totalCount;
          this.leadsWithBT = [];
          /* if (this.agents.length > 0) {
            this.retentionLeads = leads.data.map((lead) =>
              this.assignAgentsToLeads(lead)
            );
            this.leadService.unassignedNeuLeadsCount.subscribe((res: any) => {
              this.totalNeuLeads = res;
            });
          } else { */
            this.retentionLeads = leads.data;
          /* } */

          this.leadService.updateListOfSwipeableLeads(this.retentionLeads, page, size, filter, leads.totalCount);

        }))
  }

  getConfigs() {
    this.configProvider.getConfig().subscribe((config: Config[]) => {
      this.allStatuses = config.filter(x =>
        Number(x.key) === Number("4120"))
        .flatMap(y => {
          return y.values.map(v => {
            return {
              id: y.key,
              key: v.key,
              value: v.value
            };
          })
        });
      this.allStatuses.sort((a, b) => {
        return Number(a.id) - Number(b.id)
      })
      this.statusesList = this.allStatuses.map(x => x.key);

      // Get substatus list for selected status
      this.substatusesList = this.getSubstatuses(this.statusSelected.value);
    
    })}


  statusesSelected(event) {
    this.substatusesList = [];

    event.value.forEach(status => {
      this.substatusesList.push(...this.getSubstatuses(status));
    });
    this.substatusSelected.reset();
  }

  getSubstatuses(status) {
    let substatuses = [];
    let statuses = Array.isArray(status) ? 
      this.allStatuses.filter(x => status.includes(x.key)) :
      this.allStatuses.filter(x => x.key === status);

    statuses.forEach(y => {
      var obj = {};
      obj['title'] = status;
      obj['value'] = y.value.split(',');
      substatuses.push(obj);
    });

    return substatuses;
  }

}
