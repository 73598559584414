<div class="mdc-layout-grid msg-helper-padding-none">
  <div class="mdc-layout-grid__inner msg-helper-grid-gap-horizontal">
      <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop
                          mdc-layout-grid__cell--span-12-tablet
                          mdc-layout-grid__cell--span-12-phone">
          <neomp-container cssClass="msg-mp-container-frameless"
                           headlineText="{{ 'PAGE.CONTAINER.APPLICATIONS.CONTAINER.HEADLINE' | translate }}">
              <neomp-applications-table [tableEntries]="policies"></neomp-applications-table>
              <neomp-loading-data #applicationsTable></neomp-loading-data>
          </neomp-container> 
      </div>
  </div>
</div>
