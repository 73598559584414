import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { JournalListJson } from 'app/modules/core/json/journal-list.json';
import { JournalJson } from 'app/modules/core/json/journal.json';
import { PolicyJson } from 'app/modules/core/json/policy.json';
import { ActionData } from 'app/modules/core/model/action-data';
import { Journal } from 'app/modules/core/model/journal';
import { JournalList } from 'app/modules/core/model/journal-list';
import { Person } from 'app/modules/core/model/person';
import { GMOfferConstants } from 'app/modules/shared/constants/gm-offer-constants';
import { NeoRegion } from 'app/modules/shared/models/NeoRegion';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, Observer, Subject } from 'rxjs';
import { formatDate } from "@angular/common";
import { map } from 'rxjs/operators';
import { RestProvider, RestProviderActions } from 'app/modules/core/providers/rest.provider';
import { PaginationConfigs } from 'app/modules/constants/PaginationConstants';
import { KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root'
})
export class LeadsService {
  public leadsWithBt: Observer<Person[]> = null;
  public unassignedLeadsCount = new BehaviorSubject<Number>(0);
  public unassignedAppointmentsCount = new BehaviorSubject<Number>(0);
  public unassignedLeadAndAppointmentsCount = new BehaviorSubject<Number>(0);
  public unassignedNeuLeadsCount = new BehaviorSubject<Number>(0);
  private dataTransferSubject = new Subject<string>();
  dataTransferObservable = this.dataTransferSubject.asObservable();
  public unassignedLeadPoolCount = new BehaviorSubject<Number>(0);

  /**
   * This object stores the information needed that when a lead is selected from the table in the leads page,
   * it stores the list of leads,
   * the filter applied to show that list
   * the page of the table
   * */
  leadsListToSwipe = {
  leadsIds:[],
  page: PaginationConfigs.pageIndex /*0*/, 
  size: PaginationConfigs.pageSize /*15*/,
  filter: "",
  pageNumbers: [],
  totalCount: 0
  }


  constructor(
    private http: HttpClient,
    @Inject(LOCALE_ID) private locale: string,
    public rest: RestProvider,
    private keycloak: KeycloakService,
  ) { }

  getDummyData(): Observable<any> {
    return this.http.get(`/assets/dummy/data.json`);
  }
  getAgentDetails(): Observable<any>{
    const actionUrl = `${environment.settings.BASE_URL}/api/advisor/details/agentur`;
    return this.http.get<any>(actionUrl)
  }
  loadRegions(): Observable<NeoRegion[]> {
    const actionUrl = `${environment.settings.VGR_URL}/${GMOfferConstants.URL_PART_REGIONS}`;
    return this.http.get<any>(actionUrl);
  }

  getLeadById(id: string): Observable<Person> {
    const actionUrl = `${environment.settings.BASE_URL}/api/lead/${id}`;
    return this.http.get<Person>(actionUrl);
  }

  public sendLeadAction(actionData: ActionData): Observable<string> {
    return this.http.post<string>(`${environment.settings.BASE_URL}/api/lead/action`, actionData);
  }

  public getUnassignedProducts(agentId: string, uuid: string): Observable<PolicyJson[]> {
    return this.http.get<PolicyJson[]>(`${environment.settings.BASE_URL}/api/lead/unassigned-products/${agentId}/${uuid}`);
  }

  public getJournalsByPartnerId(
    partnerId: string,
    typeIdFrom: string = null,
    typeIdTo: string = null,
    sort?: any
  ): Observable<Journal[]> {
    let queryParams = "";
    if (typeIdFrom !== null || typeIdTo !== null) {
      queryParams = `?filter={"type":"DefaultRange","key":"typeId","from":"${typeIdFrom}","to":"${typeIdTo}"}`;
    }

    if (sort) {
      queryParams += `&sort=${JSON.stringify(sort)}`;
    }

    return this.http
      .get<Journal[]>(
        `${environment.settings.BASE_URL}/api/lead/transactions/lead/${partnerId}${queryParams}`)
      .pipe(map((res) => {
        return Journal.fromJsons(res);
      }));
  }

  public getJournals(
    page: number,
    size: number,
    typeIdFrom: string = null,
    typeIdTo: string = null,
    sort?: any,
    extraFilters?: any
  ): Observable<{ data: Journal[], totalCount: number }> {
    let queryParams = `?page=${page}&size=${size}`;

    if (typeIdFrom !== null || typeIdTo !== null) {
      queryParams += `&filter={"type":"DefaultRange","key":"typeId","from":"${typeIdFrom}","to":"${typeIdTo}"}`;
    }

    if (sort) {
      queryParams += `&sort=${JSON.stringify(sort)}`;
    }

    if (extraFilters) {
      queryParams += `&${extraFilters}`;
    }

    return this.http
      .get<{businessTransactions: JournalJson[], totalCount: number}>(
        `${environment.settings.BASE_URL}/api/lead/transactions${queryParams}`).pipe(
        map((res) => {
        return { data: Journal.fromJsons(res.businessTransactions), totalCount: res.totalCount };
      }));
  }
  public getLeadLogs(
    restProviderActions: RestProviderActions,
    leadId: string,
    page: number,
    size: number
    ): Observable<{ businessTransactions: Journal[]; totalCount: number }> {
      let queryParams = `?page=${page}&size=${size}`;
    return this.rest
    .get<{ businessTransactions: JournalJson[]; totalCount: number }>(
      `${environment.settings.BASE_URL}/api/lead/${leadId}/logs${queryParams}`,  null, false, restProviderActions)
    .pipe(map((res) => {
      return {
        businessTransactions: Journal.fromJsons(res.businessTransactions),
        totalCount: res.totalCount}

    }));
  }

  public getFilteredJournalsReferencedWithPagination(
    typeIdFrom: string = null,
    typeIdTo: string = null,
    page: number,
    size: number,
    sort?: any,
    extraFilters?: string,
    agentId?: string,
    completed?: boolean,
    btAccess?: boolean,
    classification?:string,
    searchText?:string
  ): Observable<{ data: JournalList[]; totalCount: number }> {
    let queryParams = `?page=${page}&size=${size}&agentId=${agentId}`;
    // add completed filter
    queryParams += `&filter={"type":"FixedValue","key":"status.completed", "value": ${completed}}`;

    if (sort) {
      queryParams += `&sort=${JSON.stringify(sort)}`;
    }

    if (typeIdFrom !== null || typeIdTo !== null) {
      queryParams += `&filter={"type":"DefaultRange","key":"typeId","from":"${typeIdFrom}","to":"${typeIdTo}"}`;
    }
    if (extraFilters) {
      queryParams += `&${extraFilters}`;
    }
    if(btAccess === true) {
      queryParams += `&btAccess=${btAccess}`
    }

    if (classification){
      queryParams += `&filter={"type":"FixedValue","key":"classification.id","value":"${classification}"}&filter={"type":"FixedValue","key":"classification.type.key","value":"040"}`
    }

    if(searchText){
      queryParams += `&filter={"for":"Lead", "type":"SearchName", "key":"name", "value":"${searchText}"}`;
    }
    return this.http
      .get<{ data: JournalListJson[]; totalCount: number }>(`${environment.settings.BASE_URL}/api/lead/filteredReferencesTransactions${queryParams}`).pipe(
      map((res) => {
        return {
          data: JournalList.fromJsons(res.data),
          totalCount: res.totalCount,
        };
      }));
  }

  public getJournalsReferencedWithPagination(
    typeIdFrom: string = null,
    typeIdTo: string = null,
    page: number,
    size: number,
    sort?: any,
    extraFilters?: string,
    agentId?: string,
    completed?: boolean,
    btAccess?: boolean,
    classification?:string,
  ): Observable<{ data: JournalList[]; totalCount: number }> {
    let queryParams = `?page=${page}&size=${size}&agentId=${agentId}`;
    // add completed filter

    queryParams += `&filter={"type":"FixedValue","key":"status.completed", "value": ${completed}}`;


    if (sort) {
      queryParams += `&sort=${JSON.stringify(sort)}`;
    }

    if (typeIdFrom !== null || typeIdTo !== null) {
      queryParams += `&filter={"type":"DefaultRange","key":"typeId","from":"${typeIdFrom}","to":"${typeIdTo}"}`;
    }
    if (extraFilters) {
      queryParams += `&${extraFilters}`;
    }
    if(btAccess === true) {
      queryParams += `&btAccess=${btAccess}`
    }

    if (classification){
      queryParams += `&filter={"type":"FixedValue","key":"classification.id","value":"${classification}"}&filter={"type":"FixedValue","key":"classification.type.key","value":"040"}`
    }

    return this.http
      .get<{ data: JournalListJson[]; totalCount: number }>(`${environment.settings.BASE_URL}/api/lead/transactionsWithReference${queryParams}`).pipe(
      map((res) => {
        return {
          data: JournalList.fromJsons(res.data),
          totalCount: res.totalCount,
        };
      }));
  }

  public getJournalsReferencedWithPaginationForSupplier(
    typeIdFrom: string = null,
    typeIdTo: string = null,
    page: number,
    size: number,
    sort?: any,
    extraFilters?: string,
    agentId?: string,
    completed?: boolean,
    btAccess?: boolean,
    classification?:string,
  ): Observable<{ data: JournalList[]; totalCount: number }> {
    let queryParams = `?page=${page}&size=${size}&agentId=${agentId}`;
    // add completed filter

    queryParams += `&filter={"type":"FixedValue","key":"status.completed", "value": ${completed}}`;


    if (sort) {
      queryParams += `&sort=${JSON.stringify(sort)}`;
    }

    if (typeIdFrom !== null || typeIdTo !== null) {
      queryParams += `&filter={"type":"DefaultRange","key":"typeId","from":"${typeIdFrom}","to":"${typeIdTo}"}`;
    }
    if (extraFilters) {
      queryParams += `&${extraFilters}`;
    }
    if(btAccess === true) {
      queryParams += `&btAccess=${btAccess}`
    }
    
    if (classification){
      queryParams += `&filter={"type":"FixedValue","key":"classification.id","value":"${classification}"}&filter={"type":"FixedValue","key":"classification.type.key","value":"040"}`
    }

    return this.http
      .get<{ data: JournalListJson[]; totalCount: number }>(`${environment.settings.BASE_URL}/api/lead/supplierTransactionsWithReference${queryParams}`).pipe(
      map((res) => {
        return {
          data: JournalList.fromJsons(res.data),
          totalCount: res.totalCount,
        };
      }));
  }


  public getJournalsReferenced(
    typeIdFrom: string = null,
    typeIdTo: string = null,
    extraFilters?: string,
    sort?: any
  ): Observable<{ data: JournalList[]; totalCount: number }> {
    let queryParams = `?page=0&size=1000`;


    if (sort) {
      queryParams += `&sort=${JSON.stringify(sort)}`;
    }

    if (typeIdFrom !== null || typeIdTo !== null) {
      queryParams += `&filter={"type":"DefaultRange","key":"typeId","from":"${typeIdFrom}","to":"${typeIdTo}"}`;
    }
    if (extraFilters) {
      queryParams += `${extraFilters}`;
    }

    return this.http
      .get<{ data: JournalListJson[]; totalCount: number }>(`${environment.settings.BASE_URL}/api/lead/transactionsWithReference${queryParams}`)
      .pipe(map((res) => {
        return {
          data: JournalList.fromJsons(res.data),
          totalCount: res.totalCount,
        };
      }));
  }

  public getJournalsRemindersForCalendar(
    extraFilters?: string,
    sort?: any
  ): Observable<{ data: JournalList[]; totalCount: number }> {
    let queryParams = `?page=0&size=1000`;


    if (sort) {
      queryParams += `&sort=${JSON.stringify(sort)}`;
    }

    if (extraFilters) {
      queryParams += `${extraFilters}`;
    }

    return this.http
      .get<{ data: JournalListJson[]; totalCount: number }>(`${environment.settings.BASE_URL}/api/lead/transactionsRemindersForCalendar${queryParams}`)
      .pipe(map((res) => {
        return {
          data: JournalList.fromJsons(res.data),
          totalCount: res.totalCount,
        };
      }));
  }
 
  public getJournalsRemindersForCalendarForSupplier(
    extraFilters?: string,
    sort?: any
  ): Observable<{ data: JournalList[]; totalCount: number }> {
    let queryParams = `?page=0&size=1000`;


    if (sort) {
      queryParams += `&sort=${JSON.stringify(sort)}`;
    }

    if (extraFilters) {
      queryParams += `${extraFilters}`;
    }

    return this.http
      .get<{ data: JournalListJson[]; totalCount: number }>(`${environment.settings.BASE_URL}/api/lead/supplierTransactionsRemindersForCalendar${queryParams}`)
      .pipe(map((res) => {
        return {
          data: JournalList.fromJsons(res.data),
          totalCount: res.totalCount,
        };
      }));
  }

  getDateFilterQuery(from: Date, to: Date): string {
    const fromTemp = formatDate(from, "yyyy-MM-dd'T00:00:00.000'", this.locale);
    const toTemp = formatDate(to, "yyyy-MM-dd'T23:59:59.999'", this.locale);
    return `filter={"type":"DateTime","key":"creationDate","from":"${fromTemp}","to":"${toTemp}"}`;
  }

  getLeadStats(): void {
    this.http.get<{ leadCount: number, appointmentCount: number }>(`${environment.settings.BASE_URL}/api/lead/getleadstats`)
      .subscribe((res: any) => {
        this.unassignedLeadsCount.next(res.leadCount);
        this.unassignedAppointmentsCount.next(res.appointmentCount);
        this.unassignedLeadAndAppointmentsCount.next(res.leadCount + res.appointmentCount);
        this.unassignedNeuLeadsCount.next(res.neuLeadCount);
      })
  }

  setUnassignedLeadsCount(count: number): void {
    this.unassignedLeadsCount.next(count);
  }

  setUnassignedAppointmentsCount(count: number): void {
    this.unassignedAppointmentsCount.next(count);
  }

  setUnassignedLeadAndAppointmentsCount(count: number): void {
    this.unassignedLeadAndAppointmentsCount.next(count);
  }

  setUnassignedNeuLeadsCount(count: number): void {
    this.unassignedNeuLeadsCount.next(count);
  }

  
  /**
   * The method update the variable leadsListToSwipe in the leads service
   * That variable is used when the user clicks on a specific lead in the table
   * and then when clicks on teh Next/Previous buttons
   * it should go through a list of filtered leads that will respect the filters and page defined
   */
  updateListOfSwipeableLeads(leads: Person[], page: number = PaginationConfigs.pageIndex, size: number = PaginationConfigs.pageSize, filter: string = "", totalCount: number){
    const filteredLeads = leads.filter(lead=>{
      const termin = lead.lead.termin_berater_shortcut;
      const servicecenter = lead.lead.edit_servicecenter;
      /**
       * The condition below is taken from the method redirectToEdit, located in leads-table.component.ts 
       * Is taken from there because there are leads that can't be opened,
       * so we shouldn't allow show leads to be in the list of leads to be showed when clicking the Next/Previous buttons located in the leads-edit-termin.component.html
       */
      if (!["", "0", "-1"].includes(termin) && termin != this.getAgenturId() && !lead.uuid.startsWith('myneolead-') && !servicecenter) {
        return true;
      }
      return false;
    });

    const idsFromFilteredLeads = filteredLeads.map(lead=>lead.uuid);

    this.leadsListToSwipe = {
      leadsIds: idsFromFilteredLeads,
      page: page, 
      size: size,
      filter: filter,
      pageNumbers: this.createPagesList(totalCount),
      totalCount: totalCount
    }
  }

  createPagesList(filteredLeads) {
    let pageNumbers = [];
    for (let i = 0; i < Math.ceil(filteredLeads / this.leadsListToSwipe.size); i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  }

  getAgenturId(){
    let agenturId = "-1";
    this.keycloak.loadUserProfile().then((res: any) => {
      agenturId = res.attributes.agenturID[0];
    });
    return agenturId.toString();

  }
  

}
