


import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { UploadFileComponent } from "app/modules/core/components/upload-file.component";
import { ActionData } from "app/modules/core/model/action-data";
import { FileUpload } from "app/modules/core/model/file-upload";
import { KeyValue } from "app/modules/core/model/key-value";
import { KeyValuePair } from "app/modules/core/model/key-value-pair";
import { UserCommitState } from "app/modules/core/model/user-commit-state";
import { log } from "app/modules/core/providers/logger.provider";
import { BusinessTransactionHelper } from "app/modules/core/static/bt-helper";
import { SubStatuses } from "app/modules/leads/enums/SubStatusEnums";
import { KeyValuePairJson } from "app/modules/shared/models/KeyValuePair";
import { KeycloakService } from "keycloak-angular";
import { Subject } from "rxjs";

@Component({
  selector: 'neomp-lead-status-dialog',
  templateUrl: './lead-status-dialog.component.html',
  styleUrls: ['./lead-status-dialog.component.scss']
})

export class LeadStatusDialogComponent implements OnInit {
    @ViewChild(UploadFileComponent) uploadFile: UploadFileComponent;

    
    // observable for file upload callback
    private readonly filesSubject: Subject<FileUpload[]> = new Subject<FileUpload[]>();
    
    formGroupInputs: FormGroup;
    readonly fcnActions = "actions";
    readonly fcnSubActions = "subAction"
    readonly fcnTitle = "title";
    readonly fcnMessage = "message";
    currentAction: KeyValue;
    currentMessage = "";
    currentActionSelected = "";
    currentSubjectSelected = "";
    dataTimeInputsHidden: boolean = false;
    subActions: string[] = [];
    subStatus: string;
    data: {actionData: ActionData, dialogData: { keyValues: KeyValue[]}} = null;
    keyValuesActionSubjects: { [key: string]: string[] } = {};
    user: any = null;

    dialogHeader: string = null;
    dialogInputTitleText: string = null;
    selectedFiles: FileUpload[] = [];

    constructor(
        public titleService: Title,
        public snackBar: MatSnackBar,
        public formBuilder: FormBuilder,
        public translationService: TranslateService,
        public dialogRef: MatDialogRef<any>,
        private keycloak: KeycloakService,
        @Inject(MAT_DIALOG_DATA) data: {actionData: ActionData, dialogData: { keyValues: KeyValue[], typeId: string}}
    ) {
        this.data = data;
      this.data.dialogData.keyValues.sort((a,b)=> {
        return Number(a.id) - Number(b.id)
      })
        this.applyDialogValues(this.data, this.titleService.getTitle());
    }

    ngOnInit(): void {
        this.keycloak.loadUserProfile().then(res => {
            this.user = res;
        });
        this.initFormValidation();
    }

    cancelDialog() {
        this.closeDialog(null);
    }

    exitDialogAndBuildActionData() {
        if (this.formGroupInputs.invalid) {
            this.validateForm(this.formGroupInputs);
            return;
        }
        const sorKeys = {
            advisorId: this.data.actionData.transaction.sorKeys.advisorId,
            agentName: this.data.actionData.transaction.sorKeys.agentName,
            leadId:this.data.actionData.transaction.sorKeys.leadId,
            time: this.formGroupInputs.value.time || "",
            date: this.formGroupInputs.controls['date'].value || ""
          };
    
        this.filesSubject.asObservable().subscribe(files => {
            this.selectedFiles = files;
          this.data.actionData.transaction.typeId = this.currentActionSelected;
            this.data.actionData.transaction.descriptionField = this.currentMessage;
            this.data.actionData.transaction.reasonForChange = this.currentMessage;
            this.data.actionData.transaction.transactionName = this.currentAction.key;
            this.data.actionData.transaction.typeName = this.currentSubjectSelected;
            this.data.actionData.transaction.sorKeys = sorKeys;
            this.data.actionData.transaction.effectiveDate = sorKeys.date || "";
            const isTerminStatus = 
            Number(this.currentAction.id) >= Number(BusinessTransactionHelper.typeId.terminStatus.from) && 
            Number(this.currentAction.id) <= Number(BusinessTransactionHelper.typeId.terminStatus.to);
            this.data.actionData.person.lead.termin_source_agency = this.formGroupInputs.value.date ? this.user.attributes.agenturID[0] : "0";
            this.data.actionData.person.lead[isTerminStatus ? 'termin_status' : 'lead_status'] = this.currentAction.key;
            this.data.actionData.person.lead[isTerminStatus ? 'termin_substatus' : 'lead_substatus'] = this.currentSubjectSelected;
            this.data.actionData.documents = this.selectedFiles;
            this.closeDialog(this.data.actionData);
        });
        this.uploadFile.readFiles();
    }

    closeDialog(value?: any) {
        this.dialogRef.close(value);
    }

    uploadedFiles(uploadFiles: FileUpload[]) {
        this.filesSubject.next(uploadFiles);
    }

    private applyDialogValues(data: {actionData: ActionData, dialogData: { keyValues: KeyValuePair[]}}, contextTitle: string) {
        this.translationService.get(
            "DIALOG.ACTION.HEADER",
            {
                context: contextTitle, transactionId: data.actionData.transaction.typeId, 
                transactionName: data.actionData.transaction.transactionName 
            })
            .subscribe(result => {
                this.dialogHeader = result;
            });

        this.translationService.get(
            "DIALOG.ACTION.INPUT.TEXT.TITLE",
            {transactionTitle: data.actionData.transaction.transactionName })
            .subscribe(result => {
                this.dialogInputTitleText = result;
            });

        this.data.dialogData.keyValues.forEach(v => {
            this.keyValuesActionSubjects[v.key] = v.value.split(",");
        });

        if (this.data.dialogData.keyValues.length === 0) {
            this.translationService.get("COMMONS.LOADINGSTATUS.NODATA")
                .subscribe(result => {
                    this.snackBar.open(result, "x", UserCommitState.configCheckData);
                });
        }
    }

    private initFormValidation() {
        this.formGroupInputs = new FormGroup({
            title: new FormControl(this.fcnTitle, {
                validators: [Validators.required, Validators.maxLength(50)],
                updateOn: "blur"
            }),
            message: new FormControl("", {
                validators: [Validators.required, emptyValidator],
                updateOn: "blur"
            }),
            actions: new FormControl("", {validators: [Validators.required], updateOn: "blur"}),
            subAction: new FormControl("", {validators: [Validators.required], updateOn: "blur"}),
            date: new FormControl(""),
            time: new FormControl(""),
        });
    }

    private validateForm(fg: FormGroup) {
        Object.keys(fg.controls).forEach(field => {
            const control = this.formGroupInputs.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({onlySelf: true});
            } else if (control instanceof FormGroup) {
                this.validateForm(control);
            }
        });
    }

    public static getTaskConfigWithData(actionData: ActionData, dialogData: { keyValues: KeyValuePair[], typeId: string}): 
    MatDialogConfig<{actionData: ActionData, dialogData: { keyValues: KeyValuePair[], typeId: string}}> {
        const cfg = new MatDialogConfig<{actionData: ActionData, dialogData: { keyValues: KeyValuePair[], typeId: string}}>();
        cfg.autoFocus = true;
        cfg.hasBackdrop = true;
        cfg.panelClass = "neomp-dialog-padding-none";
        cfg.closeOnNavigation = true;
        cfg.data = {actionData, dialogData};
        return cfg;
    }


    public onSelectAction(event) {
        log.debug(`onSelectAction() -> eventSourceValue=${event.source.value}`);
        this.currentSubjectSelected = '';
        this.formGroupInputs.controls['subAction'].reset();
        if (event.isUserInput) {
            this.currentActionSelected = event.source.value.id;
            this.currentAction = event.source.value;
            const currentActionKey = event.source.value.key;
            this.subActions = this.keyValuesActionSubjects[currentActionKey];
            if (this.subActions.length === 0) {
                this.translationService.get("COMMONS.LOADINGSTATUS.NODATA")
                    .subscribe(result => {
                        this.snackBar.open(result, "x", UserCommitState.configCheckData);
                    });
                return;
            }
            // The code 4113 is for the option Terminiert
            if(this.currentActionSelected === "4113"){
                // Show the date and time
                this.dataTimeInputsHidden = true;
                // Add validators
                this.formGroupInputs.controls['date'].setValidators([Validators.required]);  
                this.formGroupInputs.controls['time'].setValidators([Validators.required]); 
            } else{
                // Don't show the date and time
                this.dataTimeInputsHidden = false;
                // Remove validators
                this.formGroupInputs.get('date').clearValidators();
                this.formGroupInputs.get('date').updateValueAndValidity();
                this.formGroupInputs.get('time').clearValidators();
                this.formGroupInputs.get('time').updateValueAndValidity();
            }

            this.translationService.get(
                "DIALOG.ACTION.HEADER",
                {
                    context: this.titleService.getTitle(), transactionId: this.currentActionSelected,
                    transactionName: this.data.actionData.transaction.transactionName 
                })
                .subscribe(result => {
                    this.dialogHeader = result;
                });

        }
    }

    public onSelectSubAction(event) {

        log.debug(`onSelectAction() -> eventSourceValue=${event.source.value}`);
        
        if (event.isUserInput) {
            this.currentSubjectSelected = event.source.value;
            switch(this.currentSubjectSelected){
                case SubStatuses.KONTAKTVERSUCH:
                case SubStatuses.CALL_BACK:
                case SubStatuses.OFFERTEN:
                case SubStatuses.OFFEN:
                case SubStatuses.MJV:
                case SubStatuses.VOR_ORT: // It's a subaction of the action Terminiert
                case SubStatuses.ONLINE: // It's a subaction of the action Terminiert
                case SubStatuses.TELEFONISCH: // It's a subaction of the action Terminiert
                    this.dataTimeInputsHidden = true;   
                    this.formGroupInputs.controls['date'].setValidators([Validators.required]);  
                    this.formGroupInputs.controls['time'].setValidators([Validators.required]); 
                break;
                case SubStatuses.IN_BEHANDLUNG:
                    this.dataTimeInputsHidden = true; 
                    this.formGroupInputs.get('date').clearValidators();
                    this.formGroupInputs.get('date').updateValueAndValidity();
                    this.formGroupInputs.get('time').clearValidators();
                    this.formGroupInputs.get('time').updateValueAndValidity();
                break;
                default: 
                    this.dataTimeInputsHidden = false;
                    this.formGroupInputs.get('date').clearValidators();
                    this.formGroupInputs.get('date').updateValueAndValidity();
                    this.formGroupInputs.get('time').clearValidators();
                    this.formGroupInputs.get('time').updateValueAndValidity();
                break;


            }
        }
    }
}

function emptyValidator(control: AbstractControl) {
    if (control.value.toString().trim().length === 0) {
        return {isEmpty: true};
    }
    return null;
}
