

import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { UploadFileComponent } from "app/modules/core/components/upload-file.component";
import { ActionData } from "app/modules/core/model/action-data";
import { FileUpload } from "app/modules/core/model/file-upload";
import { KeyValuePair } from "app/modules/core/model/key-value-pair";
import { UserCommitState } from "app/modules/core/model/user-commit-state";
import { log } from "app/modules/core/providers/logger.provider";
import { BusinessTransactionHelper } from "app/modules/core/static/bt-helper";
import { KeyValuePairJson } from "app/modules/shared/models/KeyValuePair";
import { Subject } from "rxjs";

@Component({
  selector: 'neomp-task-complete',
  templateUrl: './task-complete.component.html',
  styleUrls: ['./task-complete.component.scss']
})

export class TaskCompleteComponent implements OnInit {
    @ViewChild(UploadFileComponent) uploadFile: UploadFileComponent;

    
    // observable for file upload callback
    private readonly filesSubject: Subject<FileUpload[]> = new Subject<FileUpload[]>();
    
    formGroupInputs: FormGroup;
    readonly fcnActions = "actions";
    readonly fcnTitle = "title";
    readonly fcnMessage = "message";
    currentAction: KeyValuePairJson<string, string[]>;
    currentMessage = "";

    data: {actionData: ActionData, dialogData: { keyValues: KeyValuePair[]}} = null;
    keyValuesActionSubjects: { [key: string]: string[] } = {};

    dialogHeader: string = null;
    dialogInputTitleText: string = null;
    selectedFiles: FileUpload[] = [];

    constructor(
        public titleService: Title,
        public snackBar: MatSnackBar,
        public formBuilder: FormBuilder,
        public translationService: TranslateService,
        public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) data: {actionData: ActionData, dialogData: { keyValues: KeyValuePair[], typeId: string}}
    ) {
        this.data = data;
        this.applyDialogValues(this.data, this.titleService.getTitle());
    }

    ngOnInit(): void {
        this.initFormValidation();
    }

    cancelDialog() {
        this.closeDialog(null);
    }

    exitDialogAndBuildActionData() {
        if (this.formGroupInputs.invalid) {
            this.validateForm(this.formGroupInputs);
            return;
        }
        this.filesSubject.asObservable().subscribe(files => {
            this.selectedFiles = files;
          
            if(this.data.actionData.transaction.typeId != "4503"){  
                this.data.actionData.transaction.typeId = this.currentAction.key;
                this.data.actionData.transaction.descriptionField = this.currentMessage;
                this.data.actionData.transaction.reasonForChange = this.currentMessage;
                this.data.actionData.transaction.transactionName = this.currentAction.value[0];
                this.data.actionData.transaction.typeName = this.currentAction.value[0];
            }
            const isTerminStatus = 
            Number(this.currentAction.key) >= Number(BusinessTransactionHelper.typeId.terminStatus.from) && 
            Number(this.currentAction.key) <= Number(BusinessTransactionHelper.typeId.terminStatus.to);

            this.data.actionData.person.lead[isTerminStatus ? 'termin_status' : 'lead_status'] = this.currentAction.value[0];
            this.data.actionData.documents = this.selectedFiles;
            this.closeDialog(this.data.actionData);
        });
        this.uploadFile.readFiles();
    }

    closeDialog(value?: any) {
        this.dialogRef.close(value);
    }

    uploadedFiles(uploadFiles: FileUpload[]) {
        this.filesSubject.next(uploadFiles);
    }

    private applyDialogValues(data: {actionData: ActionData, dialogData: { keyValues: KeyValuePair[]}}, contextTitle: string) {
        this.translationService.get(
            "DIALOG.ACTION.HEADER",
            {
                context: contextTitle, transactionId: data.actionData.transaction.typeId, 
                transactionName: data.actionData.transaction.transactionName 
            })
            .subscribe(result => {
                this.dialogHeader = result;
            });

        this.translationService.get(
            "DIALOG.ACTION.INPUT.TEXT.TITLE",
            {transactionTitle: data.actionData.transaction.transactionName })
            .subscribe(result => {
                this.dialogInputTitleText = result;
            });

        this.data.dialogData.keyValues.forEach(v => {
            this.keyValuesActionSubjects[v.key] = v.value.split(",");
        });

        if (this.data.dialogData.keyValues.length === 0) {
            this.translationService.get("COMMONS.LOADINGSTATUS.NODATA")
                .subscribe(result => {
                    this.snackBar.open(result, "x", UserCommitState.configCheckData);
                });
        }
    }

    private initFormValidation() {
        this.formGroupInputs = new FormGroup({
            title: new FormControl(this.fcnTitle, {
                validators: [Validators.required, Validators.maxLength(50)],
                updateOn: "blur"
            }),
            message: new FormControl("", {
                validators: [Validators.required, emptyValidator],
                updateOn: "blur"
            }),
            actions: new FormControl("", {validators: [Validators.required], updateOn: "blur"})
        });
    }

    private validateForm(fg: FormGroup) {
        Object.keys(fg.controls).forEach(field => {
            const control = this.formGroupInputs.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({onlySelf: true});
            } else if (control instanceof FormGroup) {
                this.validateForm(control);
            }
        });
    }

    public static getTaskConfigWithData(actionData: ActionData, dialogData: { keyValues: KeyValuePair[], typeId: string}): 
    MatDialogConfig<{actionData: ActionData, dialogData: { keyValues: KeyValuePair[], typeId: string}}> {
        const cfg = new MatDialogConfig<{actionData: ActionData, dialogData: { keyValues: KeyValuePair[], typeId: string}}>();
        cfg.autoFocus = true;
        cfg.hasBackdrop = true;
        cfg.panelClass = "neomp-dialog-padding-none";
        cfg.closeOnNavigation = true;
        cfg.data = {actionData, dialogData};
        return cfg;
    }


    public onSelectAction(event) {
        log.debug(`onSelectAction() -> eventSourceValue=${event.source.value}`);
        if (event.isUserInput) {
            this.currentAction = event.source.value;
            this.translationService.get(
                "DIALOG.ACTION.HEADER",
                {
                    context: this.titleService.getTitle(), transactionId: this.currentAction.key,
                    transactionName: this.data.actionData.transaction.transactionName 
                })
                .subscribe(result => {
                    this.dialogHeader = result;
                });

        }
    }

}

function emptyValidator(control: AbstractControl) {
    if (control.value.toString().trim().length === 0) {
        return {isEmpty: true};
    }
    return null;
}
