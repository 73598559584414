import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FilterTableComponent } from 'app/modules/core/components/tables/filter-table-component';
import { Journal } from 'app/modules/core/model/journal';
import { Helper } from 'app/modules/core/static/helper';
import moment from 'moment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'neomp-leads-log',
  templateUrl: './leads-log.component.html',
  styleUrls: ['./leads-log.component.scss']
})
export class LeadsLogComponent extends FilterTableComponent<Journal> {

  subscriptions: Subscription[] = [];
  displayedColumns: string[] = ['date', 'user', 'action', 'description', 'filter'];

  dateFilter = new FormControl("");
  userFilter = new FormControl("");
  actionFilter = new FormControl("");
  descriptionFilter = new FormControl("");
  filterValues: Journal = new Journal();

  formControlFilters: FormControl[] = [
    this.dateFilter,
    this.userFilter,
    this.actionFilter,
    this.descriptionFilter
  ];

  displayedColumnFilters = [
    "dateFilter",
    "userFilter",
    "actionFilter",
    "descriptionFilter",
    "filterRemove"
  ];

  constructor() {
    super();
  }

  createFilter(): (data: Journal, filter: string) => boolean {
    return function (innerData, innerFilter): boolean {
      const searchTerms = JSON.parse(innerFilter);

      if (searchTerms.date == undefined) {
        searchTerms.date = "";
      } else {
        searchTerms.date = moment(new Date(searchTerms.date)).format('YYYY-MM-DD');
      }

      return (
        // Filter with date
        (searchTerms.date !== null
          ? Helper.checkStringContains(
            innerData.creationDate,
            searchTerms.date
          )
          : true) &&
        // Filter with user name
        (searchTerms.user !== null
          ? Helper.checkStringContains(
            innerData.ordererName,
            searchTerms.name)
          : true) &&
        // Filter with action
        (searchTerms.action !== null
          ? Helper.checkStringContains(
            innerData.transactionName,
            searchTerms.action)
          : true) &&
        // Filter with description
        (searchTerms.description !== null
          ? Helper.checkStringContains(
            innerData.descriptionField,
            searchTerms.description)
          : true)
      );
    };
  }

  resetFilter() {
    this.filterValues = new Journal();
    super.resetFilter();
  }
}