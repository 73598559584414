<div class="mdc-layout-grid msg-helper-padding-none padding-top-50-small-size">
    <div class="mdc-layout-grid__inner">
        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop
                            mdc-layout-grid__cell--span-12-tablet
                            mdc-layout-grid__cell--span-12-phone">
            <neomp-breadcrumb [breadcrumbs]="breadcrumbs"></neomp-breadcrumb>
        </div>
        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop
                            mdc-layout-grid__cell--span-6-tablet
                            mdc-layout-grid__cell--span-12-phone">
            <neomp-container headlineText="{{ 'PAGE.CUSTOMERDETAIL.POLICY.DETAILS.GENERALINFO.NAME' | translate }}">
                <div class="mdc-layout-grid msg-helper-padding-none msg-helper-margin-side-10">
                    <div class="mdc-layout-grid__inner msg-helper-grid-gap-none" *ngIf="!!this.policy">
                        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-3-desktop
                                    mdc-layout-grid__cell--span-12-tablet
                                    mdc-layout-grid__cell--span-12-phone msg-helper-margin-top-10">
                            <mat-checkbox class="example-margin msg-data-label" [(ngModel)]="this.statusActive"
                                [disabled]="true">{{ 'PAGE.CUSTOMERDETAIL.POLICY.DETAILS.GENERALINFO.STATUS_ACTIVE' |
                                translate }}</mat-checkbox>
                        </div>
                        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-3-desktop
                                    mdc-layout-grid__cell--span-12-tablet
                                    mdc-layout-grid__cell--span-12-phone msg-helper-margin-top-10">
                            <mat-checkbox class="example-margin msg-data-label" [(ngModel)]="this.mandateGiven"
                                [indeterminate]="this.mandateDissolved" [disabled]="true">
                                {{ 'PAGE.CUSTOMERDETAIL.POLICY.DETAILS.GENERALINFO.MANDATE' | translate }}
                            </mat-checkbox>
                        </div>
                        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop
                                    mdc-layout-grid__cell--span-12-tablet
                                    mdc-layout-grid__cell--span-12-phone msg-helper-margin-top-10">
                            &nbsp;
                        </div>

                        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop
                                    mdc-layout-grid__cell--span-12-tablet
                                    mdc-layout-grid__cell--span-12-phone msg-helper-margin-top-10 font-family">
                            <span class="data-label msg-data-label">{{
                                'PAGE.CUSTOMERDETAIL.POLICY.DETAILS.GENERALINFO.COMPANY' | translate }}:</span>
                            {{this.policy.products[0].riskCarrier}}
                        </div>
                        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop
                                    mdc-layout-grid__cell--span-12-tablet
                                    mdc-layout-grid__cell--span-12-phone msg-helper-margin-top-10 font-family">
                            <span class="data-label msg-data-label">{{
                                'PAGE.CUSTOMERDETAIL.POLICY.DETAILS.GENERALINFO.POLNAME' | translate }}:</span>
                            {{this.policy.name}}
                        </div>
                        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop
                                    mdc-layout-grid__cell--span-12-tablet
                                    mdc-layout-grid__cell--span-12-phone msg-helper-margin-top-10 font-family ">
                            <span class="data-label msg-data-label">{{
                                'PAGE.CUSTOMERDETAIL.POLICY.DETAILS.GENERALINFO.POLICYNO' | translate }}:</span>
                            {{this.policy.policyNumber}}
                        </div>
                        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop
                                    mdc-layout-grid__cell--span-12-tablet
                                    mdc-layout-grid__cell--span-12-phone msg-helper-margin-top-10 font-family">
                            <span class="data-label msg-data-label">{{
                                'PAGE.CUSTOMERDETAIL.POLICY.DETAILS.GENERALINFO.POLICYID' | translate }}:</span>
                            {{this.policy.uuid}}
                        </div>

                        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop
                                    mdc-layout-grid__cell--span-12-tablet
                                    mdc-layout-grid__cell--span-12-phone msg-helper-margin-top-10 font-family"
                            [ngClass]="{'msg-helper-margin-bottom-10': !this.mandateGiven}">
                            <span class="data-label msg-data-label">{{
                                'PAGE.CUSTOMERDETAIL.POLICY.DETAILS.GENERALINFO.STATUS' | translate }}:</span>
                            {{this.policy.status.sorValue}}
                        </div>
                        <div
                            class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop
                                                                                    mdc-layout-grid__cell--span-12-tablet
                                                                                    mdc-layout-grid__cell--span-12-phone msg-helper-margin-top-10 font-family">
                            <span *ngIf="this.policy.kvgoldsocietyid" class="data-label msg-data-label">{{
                                'PAGE.CUSTOMERDETAIL.POLICY.DETAILS.GENERALINFO.KVGOLDCARRIER' | translate
                                }}:</span> {{this.policy.kvgoldsocietyid}} <br>
                            <span *ngIf="this.policy.oldsocietyid" class="data-label msg-data-label">{{
                                'PAGE.CUSTOMERDETAIL.POLICY.DETAILS.GENERALINFO.OLDCARRIER' | translate
                                }}:</span> {{this.policy.oldsocietyid}}
                        </div>

                        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop
                                    mdc-layout-grid__cell--span-12-tablet
                                    mdc-layout-grid__cell--span-12-phone msg-helper-margin-top-10 font-family msg-helper-margin-bottom-10"
                            *ngIf="this.mandateGiven">
                            <span class="data-label msg-data-label">{{
                                'PAGE.CUSTOMERDETAIL.POLICY.DETAILS.GENERALINFO.MANDATE_SUPPORTED_BY' | translate
                                }}:</span> {{this.policy.mandatResponsible}}
                        </div>

                    </div>
                </div>
            </neomp-container>
        </div>
        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop
                            mdc-layout-grid__cell--span-6-tablet
                            mdc-layout-grid__cell--span-12-phone font-family">
            <neomp-container headlineText="{{ 'PAGE.CUSTOMERDETAIL.POLICY.DETAILS.PRODUCTS.NAME' | translate }}">
                <div class="mdc-layout-grid msg-helper-padding-none msg-helper-margin-side-10" *ngIf="!!this.policy">
                    <div class="mdc-layout-grid__inner msg-helper-grid-gap-none"
                        *ngFor="let product of this.policy.products">
                        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop
                                    mdc-layout-grid__cell--span-12-tablet
                                    mdc-layout-grid__cell--span-12-phone msg-helper-margin-top-10 font-family">
                            <span class="data-label msg-data-label">{{
                                'PAGE.CUSTOMERDETAIL.POLICY.DETAILS.PRODUCTS.LABEL' | translate }}:
                            </span>{{product.line}}
                        </div>
                        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop
                                    mdc-layout-grid__cell--span-12-tablet
                                    mdc-layout-grid__cell--span-12-phone msg-helper-margin-top-10 font-family">
                            <span class="data-label msg-data-label">{{
                                'PAGE.CUSTOMERDETAIL.POLICY.DETAILS.PRODUCTS.PREMIUM' | translate }}:
                            </span>{{product.netPremium}}
                        </div>
                        <!--BasicProducts-->
                        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop
                                    mdc-layout-grid__cell--span-12-tablet
                                    mdc-layout-grid__cell--span-12-phone msg-helper-margin-top-10 font-family">
                            <span class="data-label msg-data-label">{{
                                'PAGE.CUSTOMERDETAIL.POLICY.DETAILS.PRODUCTS.STARTDATE' | translate }}: </span>
                            {{formatDate(product.dateOfStart) }}
                        </div>
                        <div
                            class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop
                                    mdc-layout-grid__cell--span-12-tablet
                                    mdc-layout-grid__cell--span-12-phone msg-helper-margin-top-10 font-family msg-helper-margin-bottom-10">
                            <span class="data-label msg-data-label">{{
                                'PAGE.CUSTOMERDETAIL.POLICY.DETAILS.PRODUCTS.TERMINATIONDATE' | translate }}:</span>
                            {{formatDate(product.dateOfTermination) }}
                        </div>
                        <div
                            class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop
                                    mdc-layout-grid__cell--span-12-tablet
                                    mdc-layout-grid__cell--span-12-phone msg-helper-margin-top-10 font-family msg-helper-margin-bottom-10">
                            <span class="data-label msg-data-label">{{
                                'PAGE.CUSTOMERDETAIL.POLICY.DETAILS.PRODUCTS.BASICPRODUCTS' | translate }}:</span>
                            <ul *ngFor="let basicp of product.basicProducts">
                                <li class="policy-list">{{basicp.name}}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </neomp-container>
        </div>
        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop
        mdc-layout-grid__cell--span-12-tablet
        mdc-layout-grid__cell--span-12-phone mdc-layout-grid--align-left msg-btn-row">

            <button color="primary" mat-raised-button disabled>{{ 'PAGE.CUSTOMERDETAIL.ACTIONS.SENDNEWS' | translate
                }}</button>
            <button color="primary" mat-raised-button (click)="openOfferPage()">{{
                'PAGE.CUSTOMERDETAIL.ACTIONS.ORDEROFFER' | translate }}</button>
            <button color="primary" mat-raised-button (click)="openActionDialog($event)" [disabled]="isDialogOpen">{{
                'PAGE.CUSTOMERDETAIL.ACTIONS.ACTION' | translate }} </button>

        </div>
        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop
                            mdc-layout-grid__cell--span-12-tablet
                            mdc-layout-grid__cell--span-12-phone">
            <neomp-container cssClass="msg-mp-container-frameless"
                headlineText="{{ 'PAGE.CUSTOMERDETAIL.POLICY.DETAILS.DOCUMENTS.NAME' | translate }}">
                <neomp-document-table [tableEntries]="documentResources"
                    (applyAdditionalActionDialogData)="actionDataCallback($event)"></neomp-document-table>
                <neomp-loading-data #documentTable></neomp-loading-data>
            </neomp-container>
        </div>
        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop
                            mdc-layout-grid__cell--span-12-tablet
                            mdc-layout-grid__cell--span-12-phone">
            <neomp-container cssClass="msg-mp-container-frameless"
                headlineText="{{ 'PAGE.CUSTOMERDETAIL.JOURNAL.TITLE' | translate }}">
                <neomp-journal-table [tableEntries]="journals" [journalData]="journalData"
                    (applyAdditionalActionDialogData)="actionDataCallback($event)"></neomp-journal-table>
                <neomp-loading-data #journalTable></neomp-loading-data>
            </neomp-container>
        </div>
    </div>
</div>