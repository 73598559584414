<table 
matSortActive="date"
matSortDirection="desc"
[dataSource]="dataSource" class="msg-helper-width-100" mat-table matSort>
    <ng-container matColumnDef="title">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'PAGE.CUSTOMERDETAIL.POLICY.DETAILS.DOCUMENTS.TITLE' | translate }}</th>
        <td *matCellDef="let element" mat-cell>{{element.title}}</td>
    </ng-container>
    <ng-container matColumnDef="category">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'PAGE.CUSTOMERDETAIL.POLICY.DETAILS.DOCUMENTS.CATEGORY' | translate }}</th>
        <td *matCellDef="let element" mat-cell>{{element.category}}</td>
    </ng-container>
    <ng-container matColumnDef="type">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'PAGE.CUSTOMERDETAIL.POLICY.DETAILS.DOCUMENTS.TYPE' | translate }}</th>
        <td *matCellDef="let element" mat-cell>{{element.type}}</td>
    </ng-container>
    <ng-container matColumnDef="date">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'PAGE.CUSTOMERDETAIL.POLICY.DETAILS.DOCUMENTS.DATE' | translate }}</th>
        <td *matCellDef="let element" mat-cell> {{element.getDate()}}</td>
    </ng-container>
    <ng-container matColumnDef="filter">
        <th *matHeaderCellDef mat-header-cell (click)="changeFilterView()" class="mat-header-cell mat-column-filter {{isFilterActive ? 'mat-column-filter-active':''}}" >
            <div class="neomp-table-column-filter-element  mdi {{isFilterActive ? 'mdi-filter':'mdi-filter-outline'}}"></div>
        </th>
        <td mat-cell *matCellDef="let element" class="mat-column-filter-action">
            <div class="neomp-table-column-filter-element mdi mdi-plus" [disabled]="isDialogOpen"
            (click)="isDialogOpen ? null : openActionDialog($event, element)"></div>
        </td>
    </ng-container>

    <!--Filter-->
    <ng-container matColumnDef="documentTitleFilter">
        <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter" floatLabel="never">
                <input matInput
                       [formControl]="documentTitleFilter"
                       placeholder="{{ 'TABLES.FILTERPLACEHOLDER.TITLE' | translate }}">
            </mat-form-field>
        </th>
    </ng-container>
    <ng-container matColumnDef="categoryFilter">
        <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter" floatLabel="never">
                <input matInput
                       [formControl]="categoryFilter"
                       placeholder="{{ 'TABLES.FILTERPLACEHOLDER.CATEGORY' | translate }}">
            </mat-form-field>
        </th>
    </ng-container>
    <ng-container matColumnDef="documentTypeFilter">
        <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter" floatLabel="never">
                <input matInput
                       [formControl]="documentTypeFilter"
                       placeholder="{{ 'TABLES.FILTERPLACEHOLDER.TYPE' | translate }}">
            </mat-form-field>
        </th>
    </ng-container>
    <ng-container matColumnDef="dateFilter">
        <th mat-header-cell *matHeaderCellDef>
        <neomp-date-picker [setDefaultValues]="false" (filterValue)="dateFilterChanged($event)"></neomp-date-picker>
    </ng-container>
    <ng-container matColumnDef="filterRemove">
        <th mat-header-cell *matHeaderCellDef (click)="resetFilter()">
            <div class="neomp-table-column-filter-element mdi mdi-close"></div>
        </th>
    </ng-container>

    <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
    <tr *matHeaderRowDef="displayedColumnFilters"
        class="neomp-table-row-filter {{showFilter? '': 'neomp-table-row-filter-hide'}}"
        mat-header-row></tr>
    <tr (click)="!documentResource.fromMyNeo ? downloadDocument(documentResource) : downloadDocumentFromMyNeo(documentResource)" *matRowDef="let documentResource; columns: displayedColumns"
        class="neomp-table-row-selected container-table-row {{documentResource.id === selectedDocumentId ? 'container-table-row-selected':''}}"
        mat-row></tr>
</table>
