// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
import { KeycloakConfig } from "keycloak-angular";

const keycloakConfig: KeycloakConfig = {
    url: 'https://auth.neo.swiss/auth',
    realm: 'neosana',
    clientId: 'angular-client'
};

const basicAuth: Object = {
    username: 'msg-neomp-sor',
    password: 'msg123'
}

export const environment = {
    name: "production",
    settings: {
        production: true,
        LOG_LEVEL: "WARNING",
        BASE_URL: "https://api.neo.swiss",
        BROKER_URL: "https://delta.neo.swiss",
        MEDIA_URL: "https://media.neo.swiss",
        VGR_URL: "https://vgr.neo.swiss",
        API_NATIONS: "https://restcountries.com/v3.1/all?fields=name",
        GM_OFFER_URL: "https://gm.neo.swiss/api",
        REGISTER_MYNEO: "https://register.my.neo.swiss/register",
        COMPANION_URL: "https://companion.neo.swiss",
        PORTAL_CMS_URL: "https://portal.cms.neo.swiss",
        VISITCARD_URL: "https://vc.neo.swiss",
        USE_GOOGLE_ANALYTICS: false,
        DASHBOARD: "https://analytics.neo.swiss",
        KNOWLEDGEBASE: "https://knowledgebase.neo.swiss",
        SERVICEDESK: "https://servicedesk.neo.swiss",
        LIVECHAT_URL: "https://chat.neo.swiss",
        APPOINTMENT_TOOL_URL: "https://meet.neo.swiss/#/",
        PORTAL_API_TOKEN: "ec4619caf44e5f9ff577f62124593a22eea87807d7973b15beed2fbdc0b779771f7305a7466d7fb44399e813e9dac5816e6106872f8381f60a5705afab86949a857340ed9e7835b8926afc1b60098a55f7d811a41fca6942021aaed19c12f026e221e7a72dbf5a9a3ef161d0ade34b478bd9fe25ef5a287ef480b60b78532d98",
    },
    keycloak: keycloakConfig,
    basicAuth: basicAuth
};
export const DEFAULT_LANGUAGE = "de";
export const LANGUAGES = ["de", "en", "fr", "it"];
