import {Component, Input, ViewChild} from "@angular/core";
import {LoadingDataComponent} from "../../core/components/loading-data.component";
import {Policy} from "../../core/model/policy";

@Component({
  selector: 'neomp-result-table-products',
  templateUrl: './result-table-products.component.html',
  styleUrls: ['./result-table-products.component.scss']
})

export class ResultTableProductsComponent {
    @Input() policies: Policy[] = [];

    isInitialized: boolean;
    isDataLoading: boolean;

    @ViewChild("applicationsTable") applicationsTableLoadingComponent: LoadingDataComponent;

    constructor() {
        this.isInitialized = false;
    }

    ngAfterViewInit(): void {
        this.isInitialized = true;
    }

    set dataLoading(dataLoading: boolean) {
        if (this.isInitialized) {
            this.isDataLoading = dataLoading;
        }
    }
}
