import {Injectable, OnDestroy} from "@angular/core";
import {BehaviorSubject, Subscription, Subject} from "rxjs";
import {SearchProvider} from "../providers/search.provider";
import {log} from "../providers/logger.provider";
import {SearchResult} from "../model/search-result";
import {HttpCancelService} from "../providers/HttpCancelService";
import { SearchType } from "../model/enum-searchtype";

@Injectable()
export class ContextSearchService implements OnDestroy {

    private readonly TAG: string = this.constructor.name;
    private readonly searchReset = new Subject();
    private readonly searchResult = new BehaviorSubject<SearchResult>(new SearchResult());
    private readonly searchInProgress = new BehaviorSubject<boolean>(false);
    private readonly searchMostRecentTerm = new BehaviorSubject<string>("");

    constructor(
        public restProvider: SearchProvider) {
    }

    ngOnDestroy(): void {
        this.searchInProgress.complete();
        this.searchResult.complete();
        this.searchReset.complete();
        this.searchMostRecentTerm.complete();
    }

    onReset() {
        log.debug(`${this.TAG} -> onReset() -> reset request as observable...`);
        return this.searchReset.asObservable();
    }

    onMostRecentTerm() {
        log.debug(`${this.TAG} -> onMostRecentTerm() -> as observable...`);
        return this.searchMostRecentTerm.asObservable();
    }

    reset() {
        log.debug(`${this.TAG} -> search() -> requesting reset of search...`);
        this.searchReset.next(null);
    }

    search(searchTerm: string, searchType: SearchType) {

        if (searchTerm && searchTerm.trim().length > 0) {
            log.debug(`${this.TAG} -> search() -> restful searching for term "${searchTerm}"...`);
            this.searchInProgress.next(true);
            this.searchMostRecentTerm.next(searchTerm);
            if (searchType === SearchType.All) {
                this.restProvider.doSearch(searchTerm)
                .subscribe(
                    r => {
                        r.searchType = searchType;
                        log.debug(`${this.TAG}\t...retrieved result=${JSON.stringify(r)}"`);
                        this.searchInProgress.next(false);
                        this.searchResult.next(r);
                    },
                    err => {
                        log.error(`${this.TAG}\t...error=${JSON.stringify(err)}"`);
                        this.searchInProgress.next(false);
                        this.searchMostRecentTerm.next(searchTerm)
                        this.searchResult.next(null);
                    });
            } else if (searchType === SearchType.Product) {
                this.restProvider.searchProducts(searchTerm)
                .subscribe(
                    r => {
                        r.searchType = searchType;
                        log.debug(`${this.TAG}\t...retrieved result=${JSON.stringify(r)}"`);
                        this.searchInProgress.next(false);
                        this.searchResult.next(r);
                    },
                    err => {
                        log.error(`${this.TAG}\t...error=${JSON.stringify(err)}"`);
                        this.searchInProgress.next(false);
                        this.searchMostRecentTerm.next(searchTerm)
                        this.searchResult.next(null);
                    });
            }
      
        } else {
            log.warn(`${this.TAG} -> search() -> empty term -> resetting...`);
            this.searchInProgress.next(false);
            this.searchResult.next(null);
        }
    }

    onSearchInProgress() {
        log.debug(`${this.TAG} -> onSearchInProgress() -> returning in-progress as observable...`);
        return this.searchInProgress.asObservable();
    }

    onResults() {
        log.debug(`${this.TAG} -> onResults() -> returning result as observable...`);
        return this.searchResult.asObservable();
    }
}
