<div class="neomp-paginator-container">
    <mat-paginator  
    [pageSize]="pageSize" 
    [pageSizeOptions]="pageSizeOptions"
    [pageIndex]="pageIndex" 
    [length]="length"
    (page)="emitPageEvent($event)">
    </mat-paginator>
    <div class="neomp-paginator-go-to-label">Go To: </div>
    <mat-form-field class="neomp-paginator-go-to-field">
        <mat-select [(ngModel)]="pageIndex" (selectionChange)="emitPageEvent({pageIndex, pageSize, pageSizeOptions, length})"
            >
            <mat-option *ngFor="let pageNumber of pageNumbers" [value]="pageNumber">{{pageNumber + 1 }}</mat-option>
        </mat-select>
    </mat-form-field>
</div>