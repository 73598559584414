import {ApprovalJson} from "../json/approval.json";

export class Approval {
    type: string = null;
    reference: string = null;
    approved = false;
    date: string = null;

    public static fromJsons(response: ApprovalJson[]): Approval[] | undefined {
        if (response) {
            return response.map(approval => new Approval(approval));
        }
    }

    constructor(data?: ApprovalJson) {
        if (data) {
            this.type = data.type ? data.type : null;
            this.date = data.date ? data.date : null;
            this.reference = data.reference ? data.reference : null;
            this.approved = data.approved;
        }
    }
}
