<div *ngIf="room">
  <div class="chat-header">
    <div class="img">
      <img
        src="assets/images/Group 4065.svg"
        width="50px"
        alt="profile"
      />
    </div>
    <div class="h-content">
      <div class="head">{{ room.clientName }}</div>
      <div class="email">{{ room.clientEmail }}</div>
      <div class="sub">
        <span
          [class.online]="room.clientStatus"
          [class.offline]="!room.clientStatus"
        >
          {{ room.clientStatus ? "Online" : "Offline" }}
        </span>
      </div>
    </div>
  </div>
  <div class="chat-body" #chatBody>
    <div class="message" *ngFor="let message of room.messages; let i = index">
      <div class="message-box" [class.user]="message.sentBy.role !== 'Client'">
        <div class="message-bubble">
          {{ message.message }}
        </div>
      </div>
      <div
        class="message-time"
        *ngIf="messageDateDecider(message, i)"
        [class.user]="message.sentBy.role !== 'Client'"
      >
        {{ message.date | date: "HH:mm" }}
        <img *ngIf="message.sentBy.role !== 'Client' && message.deliveredStatus === 'sent'" [src]="sentImg" alt="deliveredArrows"/>
        <img *ngIf="message.sentBy.role !== 'Client' && message.deliveredStatus === 'delivered'" class="delivered" [src]="deliveredImg" alt="deliveredArrows"/>
        <img *ngIf="message.sentBy.role !== 'Client' && message.deliveredStatus === 'read'" [src]="readImg" alt="deliveredArrows"/>
      </div>
    </div>
    <div class="ticket-chat" *ngIf="room.isTicket">
      <div class="info agent-assign">
        <div class="text">Pick Assignee:</div>
        <div class="assign-agent">
          <div class="btn-group">
            <button
              class="btn btn-assign-agent dropdown-toggle flex-all-center"
              type="button"
              id="triggerId"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              *ngIf="dropdownValue"
            >
              <div class="img-dropdown">
                <img
                  src="assets/images/Group 4065.svg"
                  width="16px"
                  alt="profile"
                />
              </div>
              {{ dropdownValue.name + " " + dropdownValue.lastName }}
            </button>
            <div
              class="dropdown-menu dropdown-menu-start"
              aria-labelledby="triggerId"
            >
              <div
                class="dropdown-item flex-all-center cursor-hover"
                (click)="dropdownValue = agent"
                *ngFor="let agent of listOfAgents"
              >
                <div class="img-dropdown">
                  <img
                    src="assets/images/Group 4065.svg"
                    width="16px"
                    alt="profile"
                  />
                </div>
                {{ agent.name + " " + agent.lastName }}
              </div>
            </div>
          </div>
        </div>
        <div class="activate-button">
          <button class="btn activate-btn" (click)="activateChat()" *ngIf="!_isActivating">
            Activate Chat
          </button>
          <img *ngIf="_isActivating" class="neo-animation" src="assets/images/neo_animated.svg" alt="neoAnimation">
        </div>
      </div>
    </div>
    <div
      class="left-chat"
      *ngIf="room.clientStatus === false && !room.isTicket"
    >
      <div class="info">
        <span>
          {{ room.clientName + " is currently offline. " }}
          <a
            (mouseover)="archiveImg = archiveHover"
            (mouseleave)="archiveImg = archiveNotHover"
            class="link"
            (click)="archiveChat(room)"
            *ngIf="archivable"
            >Archive chat
            <span
              ><img
                class="archive-img"
                [src]="archiveImg"
                alt="archive" /></span
          ></a>
          <a
            class="link"
            (click)="archiveChat(room)"
            *ngIf="!archivable && agent!.role.globalPerms"
            >Reactivate chat</a
          >
        </span>
      </div>
    </div>
  </div>
  <div class="chat-footer">
    <div class="type-messages">
      <input
        type="text"
        [(ngModel)]="message"
        (keydown.enter)="sendMessage(message)"
        #messageInput
        [disabled]="!archivable"
      />
      <button
        class="btn-send"
        (click)="sendMessage(message); messageInput.focus()"
        [disabled]="message == ''"
      >
        <img
          class="btn-img"
          src="assets/images/send-message.svg"
          alt="send-message"
        />
      </button>
    </div>
  </div>
</div>
