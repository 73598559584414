import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { EventsComponent } from './pages/events.component';
@NgModule({
    imports: [
        CommonModule,
        TranslateModule
    ],
    declarations: [EventsComponent],

})
export class EventsModule { }
