import { Component, OnDestroy, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { Subscription } from 'rxjs';
import { IAgent } from '../../interfaces/Iagent';
import { IMessage } from '../../interfaces/Imessage';
import { IRoom } from '../../interfaces/Iroom';
import { LivechatService } from '../livechat.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-agent-page',
  templateUrl: './agent-page.component.html',
  styleUrls: ['./agent-page.component.css'],
})
export class AgentPageComponent implements OnDestroy {
  agent: IAgent | null = null;
  rooms$!: any;
  rooms!: IRoom[];
  listenerSubscriptions: Subscription[] = [];

  constructor(
    private supportService: LivechatService,
    private keycloak: KeycloakService,
    private router: Router
  ) {}

  // livechatInit() {
  //   this.keycloak.loadUserProfile().then((res: any) => {
  //     this.supportService
  //       .getRole(res.attributes.livechatRole[0])
  //       .subscribe((payout: any) => {
  //         let role = payout.role;
  //         let agent: IAgent = {
  //           name: res.firstName,
  //           lastName: res.lastName,
  //           email: res.email,
  //           id: res.attributes.advisorid[0],
  //           categoryId: res.attributes.categoryId[0],
  //           role: role,
  //         };
  //         this.router.navigate(['/livechat/chats']);
  //         this.supportService
  //           .registerAgent(agent, res)
  //           .subscribe((payout) => {});
  //         this.supportService.agent$.next(agent);
  //         console.log(this.agent, 'first');
  //         if(this.agent) this.supportService.getAgentRooms(this.agent);
  //         if(!this.supportService.connected$.value) {
  //           this.supportService.connectSocket();
  //           this.supportService.emit('agent-logged-on', {
  //             agentId: res.attributes.advisorid[0],
  //           });
  //         }
  //       });
  //   });

  //   this.listenerSubscriptions.forEach((s) => s.unsubscribe());
  //   this.supportService.agent$.subscribe((value) => { this.agent = value; });
  //   console.log(this.agent, 'second');

  //   if(this.agent) this.supportService.getAgentRooms(this.agent);

  //   this.supportService.rooms$.subscribe((value) => {
  //     if (value !== null) {
  //       let roomIds = [];
  //       for (let room of value) {
  //         roomIds.push(room.roomId ? room.roomId : room._id);
  //       }
  //       this.supportService.join(roomIds);
  //     }
  //   });

  //   this.supportService.connected$.subscribe((valid) => {
  //     if (valid) {
  //       if (!this.supportService.hasListener('room-created')) {
  //         this.listenerSubscriptions.push(
  //           this.supportService
  //             .listen('room-created')
  //             .subscribe((payout: { room: IRoom }) => {
  //               console.log('room')
  //               if (
  //                 payout.room.agentId === this.agent?.id ||
  //                 this.agent?.role.globalPerms
  //               ) { this.supportService.addNewRoom(payout.room); }
  //             })
  //         );
  //       }

  //       if (!this.supportService.hasListener('client-reinited-chat')) {
  //         this.listenerSubscriptions.push(
  //           this.supportService
  //             .listen('client-reinited-chat')
  //             .subscribe((payout: { room: IRoom }) => {
  //               this.supportService.reinitChat(payout.room);
  //             })
  //         );
  //       }

  //       if (!this.supportService.hasListener('message-2nd')) {
  //         this.listenerSubscriptions.push(
  //           this.supportService
  //             .listen('message-2nd')
  //             .subscribe((payout: { message: IMessage; roomId: string }) => {
  //               this.supportService.emit('deliver', {
  //                 message: payout.message,
  //                 roomId: payout.roomId,
  //               });
  //               this.supportService.newMessage(payout.message, payout.roomId);
  //             })
  //         );
  //       }

  //       if (!this.supportService.hasListener('delivered')) {
  //         this.listenerSubscriptions.push(
  //           this.supportService
  //             .listen('delivered')
  //             .subscribe((payout: { roomId: string }) => {
  //               this.supportService.changeMessageStatus(
  //                 'delivered',
  //                 payout.roomId
  //               );
  //             })
  //         );
  //       }

  //       if (!this.supportService.hasListener('read')) {
  //         this.listenerSubscriptions.push(
  //           this.supportService
  //             .listen('read')
  //             .subscribe((payout: { roomId: string }) => {
  //               this.supportService.changeMessageStatus('read', payout.roomId);
  //             })
  //         );
  //       }

  //       if (!this.supportService.hasListener('room-updated')) {
  //         this.listenerSubscriptions.push(
  //           this.supportService
  //             .listen('room-updated')
  //             .subscribe((payout: { room: IRoom }) => {
  //               this.supportService.clientStatusUpdate(payout.room);
  //             })
  //         );
  //       }

  //       if (!this.supportService.hasListener('room-status-updated')) {
  //         this.listenerSubscriptions.push(
  //           this.supportService
  //             .listen('room-status-updated')
  //             .subscribe((payout: { room: IRoom }) => {
  //               this.supportService.archiveChat(payout.room);
  //             })
  //         );
  //       }

  //       if (!this.supportService.hasListener('room-archived')) {
  //         this.listenerSubscriptions.push(
  //           this.supportService
  //             .listen('room-archived')
  //             .subscribe((payout: { room: IRoom }) => {
  //               this.supportService.archiveChat(payout.room);
  //             })
  //         );
  //       }

  //       if (!this.supportService.hasListener('ticket-assigned')) {
  //         this.listenerSubscriptions.push(
  //           this.supportService
  //             .listen('ticket-assigned')
  //             .subscribe((payout: any) => {
  //               if (this.agent && this.agent?.id === payout.agentId) {
  //                 this.supportService.getAgentRooms(this.agent);
  //               }
  //             })
  //         );
  //       }

  //       if (!this.supportService.hasListener('ticket-created')) {
  //         this.listenerSubscriptions.push(
  //           this.supportService
  //             .listen('ticket-created')
  //             .subscribe((payout: any) => {
  //               if (this.agent && this.agent?.id === payout.agentId) {
  //                 this.supportService.getAgentRooms(this.agent);
  //               }
  //             })
  //         );
  //       }
  //     } else {
  //       this.listenerSubscriptions.forEach((s: Subscription) => { s.unsubscribe(); });
  //     }
  //   });
  // }

  ngOnDestroy(): void {
    this.listenerSubscriptions.forEach((s: Subscription) => { s.unsubscribe(); });
  }
}
