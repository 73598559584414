<div class="mdc-layout-grid msg-helper-padding-none padding-top-50-small-size">
    <div class="mdc-layout-grid__inner">
        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop
                            mdc-layout-grid__cell--span-12-tablet
                            mdc-layout-grid__cell--span-12-phone">
            <neomp-breadcrumb [breadcrumbs]="breadcrumbs"></neomp-breadcrumb>
        </div>
        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop
                            mdc-layout-grid__cell--span-6-tablet
                            mdc-layout-grid__cell--span-12-phone">

            <div class="mdc-layout-grid__inner">
                <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop
                            mdc-layout-grid__cell--span-12-tablet
                            mdc-layout-grid__cell--span-12-phone">
                    <neomp-container cssClass="msg-mp-container-frameless"
                                     headlineText="{{ 'PAGE.CUSTOMERDETAIL.CUSTOMER.TITLE' | translate }}">
                        <neomp-family-table-expand-detail
                            (selectedPersonEmitter)="selectedPersonClick($event)"
                            [dataSourceExpandDetail]="houseHold"
                            [selectedPerson]="selectedPersonUuid"
                            class="msg-helper-width-100">

                        </neomp-family-table-expand-detail>
                        <neomp-loading-data #familyTableDetail></neomp-loading-data>
                    </neomp-container>
                </div>
                <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop
                            mdc-layout-grid__cell--span-12-tablet
                            mdc-layout-grid__cell--span-12-phone">
                    <div class="mdc-layout-grid__cell">
                        <neomp-container headlineText="{{ 'PAGE.CUSTOMERDETAIL.CONTACT.TITLE' | translate }}">
                            <neomp-contact-data [person]="this.person"></neomp-contact-data>
                        </neomp-container>
                    </div>
                </div>

            </div>
        </div>

        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop
                            mdc-layout-grid__cell--span-6-tablet
                            mdc-layout-grid__cell--span-12-phone">
            <neomp-container cssClass="msg-mp-container-frameless"
                             headlineText="{{ 'PAGE.CUSTOMERDETAIL.POLICY.TITLE' | translate }}">
                <table [dataSource]="dataSource" class="msg-helper-width-100" mat-table matSort>
                    <ng-container matColumnDef="name">
                        <th *matHeaderCellDef mat-header-cell
                            mat-sort-header>{{ 'PAGE.CUSTOMERDETAIL.POLICY.TYPE' | translate }}</th>
                        <td *matCellDef="let element" mat-cell>{{element.name}}</td>
                    </ng-container>
                    <ng-container matColumnDef="insurer">
                        <th *matHeaderCellDef mat-header-cell
                            mat-sort-header>{{ 'PAGE.CUSTOMERDETAIL.POLICY.COMPANY' | translate }}</th>
                        <td *matCellDef="let element" mat-cell> {{element.insurer}}</td>
                    </ng-container>
                    <ng-container matColumnDef="products.dateOfTermination">
                        <th *matHeaderCellDef mat-header-cell
                            mat-sort-header>{{ 'PAGE.CUSTOMERDETAIL.POLICY.EXPIRATION' | translate }}</th>
                        <td *matCellDef="let element" mat-cell> {{formatDate(element.products[0].dateOfTermination)}}</td>
                    </ng-container>
                    <ng-container matColumnDef="approval">
                        <th *matHeaderCellDef mat-header-cell
                            mat-sort-header>{{ 'PAGE.CUSTOMERDETAIL.POLICY.APPROVAL' | translate }}</th>
                        <td *matCellDef="let element" mat-cell>
                            <mat-checkbox class="example-margin msg-data-label"
                                          [(ngModel)]="element.mandateGivenAndValid"
                                          [indeterminate]="element.getIsMandateDissolved()"
                                          [disabled]="true">
                            </mat-checkbox>
                        </td>
                    </ng-container>
                    <tr *matHeaderRowDef="['name', 'insurer', 'products.dateOfTermination', 'approval'];"
                        mat-header-row></tr>
                    <tr (click)="policyRowClick(product)"
                        *matRowDef="let product; columns: ['name', 'insurer', 'products.dateOfTermination', 'approval'];"
                        class="neomp-table-row-selected container-table-row"
                        mat-row>
                    </tr>
                </table>
                <neomp-loading-data #policyTable></neomp-loading-data>
            </neomp-container>
        </div>
        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop
        mdc-layout-grid__cell--span-12-tablet
        mdc-layout-grid__cell--span-12-phone mdc-layout-grid--align-right msg-btn-row">
    
        <button color="primary"
        class="gm-offer-button mat-raised-button" [disabled]="agentNumber === '0'"
        (click)="openGmOfferDialog()">{{ 'PAGE.CUSTOMERDETAIL.ACTIONS.GMOFFER' | translate }}</button>

        <button color="primary"
        mat-raised-button
        disabled>{{ 'PAGE.CUSTOMERDETAIL.ACTIONS.SENDNEWS' | translate }}</button>
        <button color="primary"
        mat-raised-button
        (click)="openOfferPage()">{{ 'PAGE.CUSTOMERDETAIL.ACTIONS.ORDEROFFER' | translate }}</button>
        <button color="primary"
        mat-raised-button
        (click)="openActionDialog($event)" [disabled]="isDialogOpen">{{ 'PAGE.CUSTOMERDETAIL.ACTIONS.ACTION' | translate }} </button>
        </div>

        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop
                            mdc-layout-grid__cell--span-12-tablet
                            mdc-layout-grid__cell--span-12-phone">
            <neomp-container cssClass="msg-mp-container-frameless"
                             headlineText="{{ 'PAGE.CUSTOMERDETAIL.JOURNAL.TITLE' | translate }}">
                <neomp-journal-table [tableEntries]="journals"
                                     [journalData]="journalData"
                                     (applyAdditionalActionDialogData)="actionDataCallback($event)"></neomp-journal-table>
                <neomp-loading-data #journalTable></neomp-loading-data>
            </neomp-container>
        </div>
    </div>
</div>
