import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LeadpoolblockeddialogComponent } from '../leadpoolblockeddialog/leadpoolblockeddialog.component';
import { AdvisorService } from '../../core/providers/advisor.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LeadpoolGuard implements CanActivate {
isBlocked: boolean;
  constructor(private dialog: MatDialog, private advisorService: AdvisorService, private translate: TranslateService){

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
    const itemPath = next.url[0]?.path; 

    return this.advisorService.getAdvisorData().then((res) => {
      const isBlocked = res.blocked;

      if (isBlocked && itemPath === 'leadpool') {
        // Display a dialog
        this.dialog.open(LeadpoolblockeddialogComponent, {
          width: '500px', 
          data: {
            message: `${this.translate.instant("LEADPOOL-BLOCKED-MODAL.text")}`
          }
        });
        return false; 
      }

      return true; 
    });
  }
}