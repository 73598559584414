import { Component, Input, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PaginationConfigs } from 'app/modules/constants/PaginationConstants';
import { LoadingDataComponent } from 'app/modules/core/components/loading-data.component';
import { FilterTableComponent } from 'app/modules/core/components/tables/filter-table-component';
import { JournalJson } from 'app/modules/core/json/journal.json';
import { Journal } from 'app/modules/core/model/journal';
import { SlideRangeFilterValue } from 'app/modules/core/model/slide-range-filter-value';
import { JournalProvider } from 'app/modules/core/providers/journal.provider';
import moment from 'moment';


const TYPE_ID = "2500";
const sort = { creationDate: -1 };

@Component({
  selector: 'neomp-info-visitcards-log',
  templateUrl: './info-visitcards-log.component.html',
  styleUrls: ['./info-visitcards-log.component.scss']
})
export class InfoLeadLogComponent extends FilterTableComponent<Journal> {

  ngOnInit(): void {
  }
  constructor(private journalProvider: JournalProvider) {
    super();
  }
  @Input() totalCount = 0;
  filterValues: Journal = new Journal();
  creationDateFilter = new FormControl("");
  ordererNameFilter = new FormControl("");
  sorKeysFilter = new FormControl("");
  pageSize = 15;
  pageIndex = 0;
  pageSizeOptions = PaginationConfigs.pageSizeOptions;

  formControlFilters: FormControl[] = [
    this.creationDateFilter,
    this.ordererNameFilter,
    this.sorKeysFilter
  ];

  displayedColumns: string[] = [
    "creationDate",
    "ordererName",
    "sorKeys",
    "filter"
  ];

  displayedColumnFilters: string[] = [
    "creationDateFilter",
    "ordererNameFilter",
    "sorKeysFilter",
    "statusFilter",
  ];
  @ViewChild("journalTable") journalTableLoadingComponent: LoadingDataComponent;

  createFilter(): (data: Journal, filter: string) => boolean {
    return function (data, filter): boolean {
      const columns: string[] = [
        "creationDate",
        "ordererName",
        "sorKeys",
      ];
      const filterJsonAny = JSON.parse(filter);
      const filterJson: JournalJson = JSON.parse(filter);
      const searchTerms: Journal = Journal.fromJson(filterJson);
      for (let value of columns) {
        switch (value) {
          case "creationDate":
            const beginDate = moment(JSON.parse(JSON.stringify(searchTerms.creationDate)).begin).format("YYYY-MM-DD");
            const endDate = moment(JSON.parse(JSON.stringify(searchTerms.creationDate)).end).format("YYYY-MM-DD");
            if (filterJsonAny[value].length === 0) continue; else if (moment(data[value]).isBetween(beginDate, endDate)) { continue } else return false;
          case "ordererName":
            if (filterJsonAny[value].length === 0) continue; else if (filterJsonAny[value].length > 0 && data[value].toString().toLowerCase().includes(filterJsonAny[value])) { continue } else return false;
          case "sorKeys":
            if (typeof filterJsonAny[value] === "object") continue; else if (filterJsonAny[value].length > 0 && data[value].quantity === filterJsonAny[value]) { continue } else return false;
          default:
            continue;
        }
      }
      return false
    };
  }

  creationDateFilterChanged(event: SlideRangeFilterValue): void {
    this.creationDateFilter.setValue(event.dateRange);
  }

  paginatorValueChanged(event) {
    if (Number(this.pageIndex) === Number(event.pageIndex) && Number(this.pageSize) === Number(event.pageSize)) return;
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getJournals();
  }

  getJournals() {
    this.journalProvider.getJournalsReferencedWithPagination(
      this.journalTableLoadingComponent,
      TYPE_ID,
      TYPE_ID,
      this.pageIndex,
      this.pageSize,
      sort
    ).subscribe(journals => {
      this.dataSource.data = journals.data.map(x => x.transaction).sort((a, b) => { return (a.creationDate < b.creationDate) ? 1 : -1 })
    })
  }
}
