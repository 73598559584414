import {BasicProductJson} from "../json/basic.product.json";

export class BasicProduct {
    name = "";
    shortName = "";
    status = "";
    dateOfStart = "";
    dateOfTermination = "";

    public constructor(value?: BasicProductJson) {
        if (value) {
            this.name = value.name || "";
            this.shortName = value.shortName || "";
            this.status = value.status || "";
            this.dateOfStart = value.dateOfStart || "";
            this.dateOfTermination = value.dateOfTermination || "";
        }
    }

    public static fromJson(response: BasicProductJson): BasicProduct {
        return new BasicProduct(response);
    }

    public static fromJsons(response: BasicProductJson[]): BasicProduct[] | undefined  {
        if (response) {
            return response.map(product=> new BasicProduct(product));
        }
    }
}
