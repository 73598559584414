import { SortOrder } from "../enums/SortOrder";
import { NeoRegion } from "./NeoRegion";
import { Persondata } from "./PersonData";


export class SearchParams {

    sortOrder: number;
    hauptPerson: Persondata;
    region: NeoRegion;
    weitereVersichertePersonen: Array<Persondata>;
    nurKVG: boolean;
    tarifJahr: number;
    tariftypen: Array<string>;
    selectedDoctor: any;

    constructor(representant?: Persondata, region?: NeoRegion, tarifTypen?: Array<string>, weitereVersichertePersonen?: Array<Persondata> ) {
        this.sortOrder = SortOrder.PREMIUM;
        this.hauptPerson = representant;
        this.region = region;
        this.weitereVersichertePersonen = weitereVersichertePersonen || [];
        this.nurKVG = true;
        this.tarifJahr = 2025;
        this.tariftypen = tarifTypen;
        this.selectedDoctor = null;
    }

}
