import { NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { CoreLibrariesModule } from "./core-libraries.module";
import { HttpClientModule } from "@angular/common/http";
import { ConfigProvider } from "./providers/config.provider";
import { NgxLanguageSelectorModule } from "ngx-language-selector";
import { SassHelperComponent } from "./providers/sass-helper.component";
import { WebStorageModule } from "ngx-store";
import { HeaderComponent } from "./components/header.component";
import { RegisterProvider } from "./providers/register.provider";
import { RestProvider } from "./providers/rest.provider";
import { Logger } from "./providers/logger.provider";
import { LanguageChangeComponent } from "./components/language-change.component";
import { SearchBoxComponent } from "./components/search-box.component";
import { ContainerComponent } from "./components/container.component";
import { HouseholdProvider } from "./providers/household.provider";
import { PolicyProvider } from "./providers/policy.provider";
import { LoadingDataComponent } from "./components/loading-data.component";
import { JournalTableComponent } from "./components/tables/journal-table.component";
import { JournalProvider } from "./providers/journal.provider";
import { ContactDataComponent } from "./components/contact-data.component";
import { UploadFileComponent } from "./components/upload-file.component";
import { ApplicationsTableComponent } from "./components/tables/applications-table.component";
import { DocumentTableComponent } from "./components/tables/document-table.component";
import { ActionDataProvider } from "./providers/action-data.provider";
import { ActionDialogComponent } from "./components/action.dialog.component";
import { VersionProvider } from "./providers/version.provider";
import { DatePickerComponent } from "./components/date-picker/date-picker.component";
import {
  MAT_MOMENT_DATE_FORMATS,
  MatMomentDateModule,
  MomentDateAdapter,
} from "@angular/material-moment-adapter";
import { BreadcrumbComponent } from "./components/breadcrumb.component";
import { CustomersCacheProvider } from "./providers/customers-cache.provider";
import { CreatedRequestsTableComponent } from "./components/tables/created-requests-table.component";
import { DocumentProvider } from "./providers/document.provider";
import { OpenIssuesTableComponent } from "./components/tables/open-issues-table.component";
import { SlideRangeFilterComponent } from "./components/slide-range-filter.component";
import { MessagesTableComponent } from "./components/tables/messages-table.component";
import { MessagesProvider } from "./providers/messages.provider";
import { HttpCancelService } from "./providers/HttpCancelService";
import { ContextSearchService } from "./services/context-search.service";
import { AdvisorDataService } from "./services/advisor-data.service";
import { SearchProvider } from "./providers/search.provider";
import { SquareIconComponent } from "./components/square-icon.component";
import { RelatedMessagesTableComponent } from "./components/tables/related-messages-table.component";
import { NeoToolTipDirective } from "app/neotooltip.directive";
import { MatTableModule } from "@angular/material/table";
import { TerminRemindersComponent } from "./components/tables/termin-reminders.component";
import { TerminLeadsRemindersComponent } from "./components/tables/termin-leads-reminders.component";
import { DeviceDetectorService } from "ngx-device-detector";
import { PaginatorComponent } from "./components/paginator/paginator.component";
import { UploadDocumentComponent } from "./components/upload-document.component";
import { ngfModule } from "angular-file"
import { RequestsJournalTableComponent } from "./components/tables/requests-journal-table.component";
import { OpenIssueRepliesTableComponent } from './components/tables/open-issue-replies-table/open-issue-replies-table.component';
import { AppointmentDialogComponent } from "./components/appointment-dialog/appointment-dialog.component";
import { CancellationManagementTableComponent } from './components/tables/cancellation-management-table/cancellation-management-table.component';
import { MortgageTableComponent } from './components/tables/mortgage-table/mortgage-table.component';
import { CommaSeparatorDirective } from "./directives/commaSeperator.directive";

@NgModule({
  declarations: [
    SassHelperComponent,
    HeaderComponent,
    LanguageChangeComponent,
    SearchBoxComponent,
    ContainerComponent,
    LoadingDataComponent,
    JournalTableComponent,
    MessagesTableComponent,
    MessagesTableComponent,
    RelatedMessagesTableComponent,
    UploadFileComponent,
    UploadDocumentComponent,
    ContactDataComponent,
    ActionDialogComponent,
    TerminRemindersComponent,
    TerminLeadsRemindersComponent,
    ApplicationsTableComponent,
    CreatedRequestsTableComponent,
    DocumentTableComponent,
    DatePickerComponent,
    BreadcrumbComponent,
    OpenIssuesTableComponent,
    SlideRangeFilterComponent,
    SquareIconComponent,
    NeoToolTipDirective,
    PaginatorComponent,
    RequestsJournalTableComponent,
    OpenIssueRepliesTableComponent,
    AppointmentDialogComponent,
    CancellationManagementTableComponent,
    MortgageTableComponent,
    CommaSeparatorDirective
  ],
  imports: [
    CoreLibrariesModule,
    HttpClientModule,
    NgxLanguageSelectorModule,
    WebStorageModule,
    WebStorageModule,
    ngfModule,
    MatMomentDateModule
  ],
  exports: [
    CoreLibrariesModule,
    SassHelperComponent,
    HeaderComponent,
    LanguageChangeComponent,
    SearchBoxComponent,
    ContainerComponent,
    JournalTableComponent,
    MessagesTableComponent,
    LoadingDataComponent,
    UploadFileComponent,
    UploadDocumentComponent,
    ContactDataComponent,
    ApplicationsTableComponent,
    CreatedRequestsTableComponent,
    DocumentTableComponent,
    ActionDialogComponent,
    DatePickerComponent,
    TerminRemindersComponent,
    TerminLeadsRemindersComponent,
    BreadcrumbComponent,
    OpenIssuesTableComponent,
    SlideRangeFilterComponent,
    SquareIconComponent,
    NeoToolTipDirective,
    RelatedMessagesTableComponent,
    PaginatorComponent,
    RequestsJournalTableComponent,
    AppointmentDialogComponent,
    CancellationManagementTableComponent,
    MortgageTableComponent,
    CommaSeparatorDirective
  ],
  providers: [
    ConfigProvider,
    VersionProvider,
    RegisterProvider,
    RestProvider,
    HouseholdProvider,
    PolicyProvider,
    DeviceDetectorService,
    JournalProvider,
    MessagesProvider,
    DocumentProvider,
    ActionDataProvider,
    Logger,
    CustomersCacheProvider,
    HttpCancelService,
    SearchProvider,
    ContextSearchService,
    AdvisorDataService,
  ],
  schemas: [NO_ERRORS_SCHEMA],
})
export class CoreModule {}
