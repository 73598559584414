<table [dataSource]="dataSource" class="msg-helper-width-100"
       mat-table
       matSort>
    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
            <div class="div-status" [ngClass]="getClassFromStatus(element.status)"></div>
        </td>
    </ng-container>
    <ng-container matColumnDef="creationDate">
        <th *matHeaderCellDef mat-header-cell
            mat-sort-header>{{ 'PAGE.CUSTOMERDETAIL.JOURNAL.DATE' | translate }}
        </th>
        <td mat-cell *matCellDef="let element"> <div class="neo-max-width-250" neoTooltip>{{element.getCreationDateOnly()}}</div></td>
    </ng-container>
    <ng-container matColumnDef="typeName">
        <th *matHeaderCellDef mat-header-cell
            mat-sort-header>{{ 'PAGE.CUSTOMERDETAIL.JOURNAL.TYPE' | translate }}</th>
        <td mat-cell *matCellDef="let element"><div class="neo-max-width-250" neoTooltip>{{element.typeName}}</div></td>
    </ng-container>
    <ng-container matColumnDef="reasonForChange">
        <th *matHeaderCellDef mat-header-cell
            mat-sort-header>{{ 'PAGE.CUSTOMERDETAIL.JOURNAL.DESCRIPTION' | translate }}</th>
        <td mat-cell *matCellDef="let element"><div class="neo-max-width-250" neoTooltip>{{element.reasonForChange}}</div></td>
    </ng-container>
    <ng-container matColumnDef="descriptionField">
        <th *matHeaderCellDef mat-header-cell
            mat-sort-header>{{ 'PAGE.CUSTOMERDETAIL.JOURNAL.LONGDESCRIPTION' | translate }}</th>
        <td  mat-cell *matCellDef="let element"><div class="neo-max-width-250" neoTooltip>
            {{element.descriptionField}}
    </div> </td>
    </ng-container>
    <ng-container *ngIf="isGMOffer === true" matColumnDef="customer">
        <th *matHeaderCellDef mat-header-cell
            mat-sort-header>{{ 'PAGE.CUSTOMERDETAIL.CUSTOMER.TITLE' | translate }}</th>
        <td mat-cell *matCellDef="let element"><div class="neo-max-width-250" neoTooltip> {{getCustomerName(element)}}</div></td>
    </ng-container>
    <ng-container matColumnDef="document">
        <th *matHeaderCellDef mat-header-cell
            mat-sort-header>{{ 'PAGE.CUSTOMERDETAIL.JOURNAL.DOCUMENT' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
            <div  *ngIf="checkBTType(element) === false; then thenBlock else elseBlock"></div>
            <ng-template #thenBlock>
            <span *ngFor="let resource of getDocuments(element)" (click)="$event.stopPropagation()">
                  <button mat-icon-button  (click)="!resource.fromMyNeo ? download(resource.id) : downloadFromMyNeo(resource.id)" [title]="resource.title">
                    <mat-icon>get_app</mat-icon>
                </button>
            </span>
            </ng-template>
            <ng-template #elseBlock>
            <span>                
                    <button mat-icon-button (click)="downloadGMDocument(getOfferId(element), getCustomerName(element) + ' - GM Offer.pdf')">
                        <mat-icon>get_app</mat-icon>
                    </button>     
            </span>               
            </ng-template>
        </td>
    </ng-container>
    <ng-container matColumnDef="filter">
        <th *matHeaderCellDef mat-header-cell (click)="changeFilterView()" class="mat-header-cell mat-column-filter {{isFilterActive ? 'mat-column-filter-active':''}}" >
            <div class="neomp-table-column-filter-element  mdi {{isFilterActive ? 'mdi-filter':'mdi-filter-outline'}}"></div>
        </th>
        <td mat-cell *matCellDef="let element"  class="mat-column-filter-action">
            <div class="neomp-table-column-filter-element mdi mdi-plus" [disabled]="isDialogOpen"
            (click)="isDialogOpen ? null : openActionDialog($event, element)"></div>
        </td>
    </ng-container>

    <!-- Filter row definition -->
    <ng-container matColumnDef="statusFilter">
        <th mat-header-cell *matHeaderCellDef>
        </th>
    </ng-container>
    <ng-container matColumnDef="creationDateFilter">
        <th mat-header-cell *matHeaderCellDef>
           <neomp-date-picker [setDefaultValues]="false" (filterValue)="creationDateFilterChanged($event)"></neomp-date-picker>
        </th>
    </ng-container>
    <ng-container matColumnDef="typeNameFilter">
        <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter" floatLabel="never">
                <input matInput
                       [formControl]="typeNameFilter"
                       placeholder="{{ 'TABLES.FILTERPLACEHOLDER.TYPE' | translate }}">
            </mat-form-field>
        </th>
    </ng-container>
    <ng-container matColumnDef="reasonForChangeFilter">
        <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter" floatLabel="never">
                <input matInput
                       [formControl]="reasonForChangeFilter"
                       placeholder="{{ 'TABLES.FILTERPLACEHOLDER.SUBJECT' | translate }}">
            </mat-form-field>
        </th>
    </ng-container>
    <ng-container matColumnDef="descriptionFieldFilter">
        <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter" floatLabel="never">
                <input matInput
                       [formControl]="descriptionFieldFilter"
                       placeholder="{{ 'TABLES.FILTERPLACEHOLDER.ANNOTATION' | translate }}">
            </mat-form-field>
        </th>
    </ng-container>
    <ng-container  *ngIf="isGMOffer === true" matColumnDef="customerFilter">
        <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter" floatLabel="never">
                <input matInput
                       [formControl]="customerFilter"
                       placeholder="{{ 'TABLES.FILTERPLACEHOLDER.CUSTOMER' | translate }}">
            </mat-form-field>
        </th>
    </ng-container>
    <ng-container matColumnDef="documentFilter">
        <th mat-header-cell *matHeaderCellDef>
        </th>
    </ng-container>
    <ng-container matColumnDef="filterRemove">
        <th mat-header-cell *matHeaderCellDef (click)="resetFilter()">
            <div class="neomp-table-column-filter-element mdi mdi-close"></div>
        </th>
    </ng-container>
  <!--end of  Filter row definition-->


    <!--Row definitions -->
    <tr *matHeaderRowDef=" isGMOffer === true ? displayedGMColumns : displayedColumns"
        mat-header-row></tr>
    <tr *matHeaderRowDef=" isGMOffer === true ? displayedGMColumnFilters : displayedColumnFilters"
        class="neomp-table-row-filter {{showFilter? '': 'neomp-table-row-filter-hide'}}"
        mat-header-row></tr>
    <tr (click)="journalRowClick(journalRow)"
        *matRowDef="let journalRow; columns: isGMOffer === true ? displayedGMColumns : displayedColumns"
        class="neomp-table-row-selected container-table-row {{journalRow.uuid === selectedJournalRowIndex ? 'container-table-row-selected':''}}"
        mat-row
    ></tr>
</table>
<div class="overlay" *ngIf="isLoading">
    <div class="center">
        <mat-progress-spinner class="mat-progress-spinner-overlay" class="example-margin" mode="indeterminate">
        </mat-progress-spinner>
    </div>
</div>
