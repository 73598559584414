<div class="mdc-layout-grid msg-helper-padding-none">
    <div class="mdc-layout-grid__inner msg-helper-grid-gap-horizontal">
      <div
        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone"
      >
        <div class="mdc-layout-grid__inner">
          <div class="mdc-layout-grid__cell--span-3">
            <div class="btn-group">
                <button
                  class="neo-buttons-step  btn-previous-next"
                  mwlCalendarPreviousView
                  [view]="view"
                  [(viewDate)]="viewDate"
                  (viewDateChange)="viewDateChange.next(viewDate)"
                  
                >
                {{
                  'PAGE.CONTAINER.CALENDARS.CONTAINER.PREVIOUS' | translate
                }}
                </button>
                <button
                  class="neo-buttons-step btn-today"
                  mwlCalendarToday
                  [(viewDate)]="viewDate"
                  (viewDateChange)="viewDateChange.next(viewDate)"
                >
                {{
                  'PAGE.CONTAINER.CALENDARS.CONTAINER.TODAY' | translate
                }}
                </button>
                <button
                  class="neo-buttons-step btn-next"
                  mwlCalendarNextView
                  [view]="view"
                  [(viewDate)]="viewDate"
                  (viewDateChange)="viewDateChange.next(viewDate)"
                >
                {{
                  'PAGE.CONTAINER.CALENDARS.CONTAINER.NEXT' | translate
                }}
                </button>
          </div>
        </div>
        <div class="mdc-layout-grid__cell--span-6">
            <h3 class="view-date">{{ viewDate | calendarDate: view + 'ViewTitle':locale }}</h3>
        </div>
        <div class="mdc-layout-grid__cell--span-3">
            <div class="btn-group">
                <button
                  class="neo-buttons-date btn-month"
                  (click)="viewChange.emit('month')"
                  [class.active]="view === 'month'"
                >
                {{
                  'PAGE.CONTAINER.CALENDARS.CONTAINER.MONTH' | translate
                }}
                </button>
                <button
                  class="neo-buttons-date btn-week"
                  (click)="viewChange.emit('week')"
                  [class.active]="view === 'week'"
                >
                {{
                  'PAGE.CONTAINER.CALENDARS.CONTAINER.WEEK' | translate
                }}
                </button>
                <button
                  class="neo-buttons-date btn-today"
                  (click)="viewChange.emit('day')"
                  [class.active]="view === 'day'"
                >
                {{
                  'PAGE.CONTAINER.CALENDARS.CONTAINER.DAY' | translate
                }}
                </button>
        </div>
     </div>
    </div>
</div>
<br>