import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {from, Observable, of} from "rxjs";
import {log} from "./logger.provider";
import {CachedObject} from "../model/cached-object";
import {DocumentPayloadJson} from "../json/document-payload.json";
import {DocumentPayload} from "../model/document-payload";
import { map } from "rxjs/operators";

export type ValidationCallback = (data: any) => boolean;

export interface RestProviderActions {
    onStart(): void;

    onError(error: any): void;

    onSuccess(): void;

    onNoData(): void;

    additionalValidation(data: any): boolean;

    applyValidationCallback(validationCallback: ValidationCallback): void;
}

export class RestProviderActionsDummy implements RestProviderActions {

    onError(error: any): void {
        log.info(`RestProviderActions -> called onError()=${error}`);
    }

    onNoData(): void {
        log.info(`RestProviderActions -> called noData()`);
    }

    onStart(): void {
        log.info(`RestProviderActions -> called onStart()`);
    }

    onSuccess(): void {
        log.info(`RestProviderActions -> called onSuccess()`);
    }

    additionalValidation(data: any): boolean {
        log.info(`RestProviderActions -> called additionalValidation() -> default is true`);
        return true;
    }

    applyValidationCallback(validationCallback: ValidationCallback): void {
        log.info(`RestProviderActions -> called applyValidationCallback() -> ignored by default`);
    }
}

@Injectable()
export class RestProvider {
    // cache time in seconds
    cacheTime = 10;
    localCache: { [key: string]: CachedObject } = {};
    private readonly CLASS_NAME = this.constructor.name;

    constructor(public http: HttpClient) {};

    public clearCache() {
        this.localCache = {};
    }

    public handleError(error) {
        let errorMessage = "";
        if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
        } else {
            // server-side error
            errorMessage = `Error Code: ${error.status}; Message: ${error.message}`;
        }
        log.error(`ERROR on ${this.CLASS_NAME}: Message ${errorMessage}`, error);
    }

    public get<T>(url: string, options: HttpHeaders, useCache: boolean, restProviderActions?: RestProviderActions): Observable<T> {
        if (restProviderActions) {
            restProviderActions.onStart();
        } 
        log.debug(`${this.CLASS_NAME}: GET ${url}`);
        const cacheData = this.getFromCache(url);
        if (useCache && cacheData) {
            if (restProviderActions) {
                this.checkNoDataOnSuccess(cacheData, restProviderActions);
            }
            return of(JSON.parse(cacheData));
        }
        const httpOptions = {
            headers: options
        };
        return from(new Promise<T>((resolve, reject) => {
            this.http.get<T>(url, httpOptions)
                .toPromise()
                .then(
                    response => { // Success
                        log.debug(`Response of ${this.CLASS_NAME}: GET ${url}`, response)
                        if (useCache) {
                            log.debug(`${this.CLASS_NAME}: caching data for ${url}`, response)
                            this.cacheData(url, JSON.stringify(response));
                        }
                        if (restProviderActions) {
                            this.checkNoDataOnSuccess(response, restProviderActions);
                        }
                        resolve(response);
                    },
                    error => { // Error
                        log.error(`Response of ${this.CLASS_NAME}: GET ${url}`, error)
                        if (restProviderActions) {
                            restProviderActions.onError(error);
                        }
                        this.handleError(error);
                        reject(error);
                    }
                );
        }));
    }

    public postBlob(url: string, body: any): Observable<ArrayBuffer> {
        log.debug(`${this.CLASS_NAME}: POST ${url}`, body);
        return this.http.post(url, body, 
            {
            responseType: 'arraybuffer'
            }).pipe(map(response => response));
    }
    public post(url: string, body: any): Observable<string> {
        log.debug(`${this.CLASS_NAME}: POST ${url}`, body);
        return this.http.post(url, body, { responseType: "text" }).pipe(map(response => response));
    }

    public postBasicAuth(url: string, body: any, authData: any): Observable<string> {
        log.debug(`${this.CLASS_NAME}: POST ${url}`, body);
        let headers = new HttpHeaders({ 'Authorization': `Basic ${btoa(`${authData.username}:${authData.password}`)}`});
        return this.http.post(url, body, { responseType: "text", headers: headers }).pipe(map(response => response));
    }

    public put<T>(url: string, body: any): Observable<T> {
        log.debug(`${this.CLASS_NAME}: PUT ${url}`, body);
        return this.http.put<T>(url, body).pipe(map(response => response));
    }

    private cacheData(key: string, value: string) {
        const current = (new Date()).getTime();
        const object = new CachedObject(current, value);
        this.localCache[key] = object;
    }

    private getFromCache(key: string): string {
        log.debug(`${this.CLASS_NAME}: getting from cache for ${key}`)
        const object = this.localCache[key];
        const current = (new Date()).getTime();
        if (object && object.time >= (current - this.cacheTime * 1000)) {
            return object.body;
        } else if (object && object.time < (current - this.cacheTime * 1000)) {
            log.debug(`${this.CLASS_NAME}: cache time ran out; TTL: ${this.cacheTime}s, Object Age: ${(current - object.time) / 1000}s`)
            delete this.localCache[key];
        }
        return null;
    }

    private checkNoDataOnSuccess(data: any, restProviderActions: RestProviderActions) {
        restProviderActions.onSuccess();
        if (data && data !== "null") {
            // check on array
            if (data instanceof Array && data.length === 0) {
                restProviderActions.onNoData();
            }
            // additional validations
            if (!restProviderActions.additionalValidation(data)) {
                log.debug(`additional validation -> false`);
                restProviderActions.onNoData();
            } else {
                log.debug(`additional validation -> true`);
            }
        } else {
            restProviderActions.onNoData();
        }
    }

    public download(url: string): Observable<DocumentPayload> {
        return this.get<DocumentPayloadJson>(url,null,false, new RestProviderActionsDummy())
            .pipe(map((res) => {
                return DocumentPayload.fromJson(res);
            }));
    }
    
    public gmDownload(url: string, offerId: string): Observable<any> {
        return this.http.post(url, {offerId: offerId})
            .pipe(map((res) => {
                return res;
            }));
    }
}
