import {CRC32 as crc32} from "crc_32_ts";

export class FileUpload {
    name: string;
    data: string;
    size: number;
    hash: string;

    constructor(name: string, data: string, size: number) {
        this.name = name;
        this.data = data;
        this.size = size;
        this.hash = this.generateHash();
    }

    generateHash(): string{
        return String(crc32.str(this.data));
    }
}
