import { Component, OnInit } from '@angular/core';
import { IAgent } from '../../interfaces/Iagent';
import { LivechatService } from '../livechat.service';

@Component({
  selector: 'app-agent-nav',
  templateUrl: './agent-nav.component.html',
  styleUrls: ['./agent-nav.component.scss']
})
export class AgentNavComponent implements OnInit {
  helpImgLink = 'assets/images/Admin view pages/user.svg';
  logoutLink = 'assets/images/Admin view pages/log out.svg';

  agent!: IAgent | null;

  constructor(private supportService: LivechatService) { }

  ngOnInit() {
    this.supportService.agent$.subscribe(value => { this.agent = value; })
  }

  onChats(value: boolean){
    this.supportService.archivable.next(value);
  }

  logout(){
    this.supportService.onAgentLogout();
  }
}
