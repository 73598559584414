import {Person} from "./person";
import {Journal} from "./journal";
import {Policy} from "./policy";
import {DocumentResourceResult} from "./document-resource-result";
import {JournalListJson} from "../json/journal-list.json";
import {Status} from "./status";
import {Product} from "./product";
import {Helper} from "../static/helper";
import { PostalAddress } from "./postal-address";

export class JournalList {
    transaction: Journal = null;
    transactions: Journal[] = [];
    persons: Person[] = [];
    policies: Policy[] = [];
    documentResources: DocumentResourceResult[] = [];

    public constructor(value?: JournalListJson) {
        if (value) {
            this.transaction = Journal.fromJson(value.transaction);
            this.transactions = Journal.fromJsons(value.transactions);
            this.persons = Person.fromJsons((value.persons));
            this.policies = Policy.fromJsons((value.policies));
            this.documentResources = DocumentResourceResult.fromJsons((value.documentResources));
        } else {
            this.transaction = new Journal();
        }

    }
    get policyNumber(): string {
        return this.policies[0] ? this.policies[0].policyNumber : "";
    }

    set policyNumber(val: string) {
        if (this.policies.length === 0) {
            this.policies.push(new Policy());
        }
        this.policies[0].policyNumber = val;
    }
    get policyId(): string {
        return this.policies[0] ? this.policies[0].uuid : "";
    }

    set policyId(val: string) {
        if (this.policies.length === 0) {
            this.policies.push(new Policy());
        }
        this.policies[0].uuid = val;
    }

    get inputChannel(): string {
        return this.transaction.inputChannel;
    }

    set inputChannel(val: string) {
        this.transaction.inputChannel = val;
    }

    get riskCarrier(): string {
        if (this.policies[0] && this.policies[0].products[0]) {
            return this.policies[0].products[0].riskCarrier;
        }
        return "";
    }

    set riskCarrier(val: string) {
        if (this.policies.length === 0) {
            this.policies.push(new Policy());
        }
        if (this.policies[0].products.length === 0) {
            this.policies[0].products.push(new Product());
        }
        this.policies[0].products[0].riskCarrier = val;
    }

    get sparte(): string {
        if (this.policies[0] && this.policies[0].products[0]) {
            return this.policies[0].products[0].lineOfBusiness
        }
        return "";
    }

    set sparte(val: string) {
        if (this.policies.length === 0) {
            this.policies.push(new Policy());
        }
        if (this.policies[0].products.length === 0) {
            this.policies[0].products.push(new Product());
        }
        this.policies[0].products[0].name = val;
    }

    get uuid(): string {
        return this.transaction.uuid;
    }

    set uuid(val: string) {
        this.transaction.uuid = val;
    }

    get statusName(): string {
        return this.transaction && this.transaction.status ? this.transaction.status.name : "";
    }

    set statusName(val: string) {
        if (!this.transaction.status) {
            this.transaction.status = new Status();
        }
        this.transaction.status.name = val;
    }
    get statusLead(): string {
        return this.persons[0] ? this.persons[0].lead.lead_status : "";
    }

    set statusLead(val: string) {
        if (this.persons.length === 0) {
            this.persons.push(new Person());
        }
        this.persons[0].lead.lead_status = val;
    }

    get substatusLead(): string {
        return this.persons[0] ? this.persons[0].lead.lead_substatus : "";
    }

    set substatusLead(val: string) {
        if (this.persons.length === 0) {
            this.persons.push(new Person());
        }
        this.persons[0].lead.lead_substatus = val;
    }


    get completed(): boolean {
        return this.transaction.status.completed;
    }

    set completed(val: boolean) {
        if (!this.transaction.status) {
            this.transaction.status = new Status();
        }
        this.transaction.status.completed = val;
    }

    get transactionId(): string {
        return this.transaction.transactionId;
    }

    set transactionId(val: string) {
        this.transaction.transactionId = val;
    }

    get effectiveDate(): string {
        return this.transaction.effectiveDate;
    }

    set effectiveDate(val: string) {
        this.transaction.effectiveDate = val;
    }

    get creationDate(): string {
        return this.transaction.creationDate;
    }

    set creationDate(val: string) {
        this.transaction.creationDate = val;
    }

    get descriptionField(): string {
        return this.transaction.descriptionField;
    }

    set descriptionField(val: string) {
        this.transaction.descriptionField = val;
    }

    get reasonForChange(): string {
        return this.transaction.reasonForChange;
    }

    set reasonForChange(val: string) {
        this.transaction.reasonForChange = val;
    }

    get customerId(): string {
        return this.persons[0] ? this.persons[0].uuid : null;
    }

    get customerName(): string {
        return this.persons[0] ? this.persons[0].fullName : null;
    }

    set customerName(val: string) {
        if (this.persons.length === 0) {
            this.persons.push(new Person());
        }
        this.persons[0].name = val;
    }

    get customerBirthday(): string {
        return this.persons[0] ? this.persons[0].birthdate : "";
    }

    set customerBirthday(val: string) {
        if (this.persons.length === 0) {
            this.persons.push(new Person());
        }
        this.persons[0].birthdate = val;
    }

    get typeName(): string {
        return this.transaction.typeName;
    }

    set typeName(typeName: string) {
        this.transaction.typeName = typeName;
    }

    get documentTitle() {
        return this.documentResources[0] ? this.documentResources[0].title : "";

    }

    set documentTitle(val: string) {
        if (this.documentResources.length === 0) {
            this.documentResources.push(new DocumentResourceResult());
        }
        this.documentResources[0].title = val;
    }

    get agentName() {
        return this.transaction.sorKeys['agent'] || '';
    }

    set agentName(val: string) {
         this.transaction.sorKeys['agent'] = val;
    }

    get customerPLZ() {
        return this.persons[0] && this.persons[0].address ? this.persons[0].address.postalCode : '';
    }
    
    set customerPLZ(val: string) {
        if (this.persons.length === 0) {
            this.persons.push(new Person());
        }
       if ( !this.persons[0].address ) {
            this.persons[0].address = new PostalAddress();
        }
        this.persons[0].address.postalCode = val;
    }

    get category() {
        return this.persons[0] ? this.persons[0].lead.lead_kategory : "";
    }

    set category(val: string) {
        if (this.persons.length === 0) {
            this.persons.push(new Person());
        }
        this.persons[0].lead.lead_kategory = val;
    }

    public static fromJson(response: JournalListJson): JournalList {
        const result = new JournalList();
        result.transaction = Journal.fromJson(response.transaction);
        result.transactions = Journal.fromJsons(response.transactions);
        result.persons = Person.fromJsons(response.persons);
        result.policies = Policy.fromJsons((response.policies));
        result.documentResources = DocumentResourceResult.fromJsons((response.documentResources));
        return result;
    }

    public static fromJsons(response: JournalListJson[]): JournalList[] | undefined {
        if (response) {
            return response.map(journal => new JournalList(journal));
        }
    }

    getEffectivDateOnly() {
        return Helper.formatDate(new Date(this.effectiveDate));
    }

}
