import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CoreModule } from "../core/core.module";
import { MyCustomersPageComponent } from "./pages/my-customers-page.component";
import { FamilyTableComponent } from "./components/family-table.component";
import { LeadsTableComponent } from "./components/leads-table.component";
import { MyCustomersDetailPageComponent } from "./pages/my-customers-detail-page.component";
import { FamilyTableExpandDetailComponent } from "./components/family-table-expand-detail.component";
import { PolicyDetailPageComponent } from "./pages/policy-detail-page.component";
import { SharedModule } from "../shared/shared.module";

@NgModule({
  imports: [CommonModule, CoreModule, SharedModule],
  declarations: [
    MyCustomersPageComponent,
    FamilyTableComponent,
    MyCustomersDetailPageComponent,
    FamilyTableExpandDetailComponent,
    PolicyDetailPageComponent
  ],
  exports: [
    MyCustomersPageComponent,
    FamilyTableComponent,
    MyCustomersDetailPageComponent,
    FamilyTableExpandDetailComponent,
    PolicyDetailPageComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CustomersModule {}
