import {AfterViewInit, Component, OnInit, ViewChild} from "@angular/core";
import {JournalProvider} from "../../core/providers/journal.provider";
import {LoadingDataComponent} from "../../core/components/loading-data.component";
import {BusinessTransactionHelper} from "../../core/static/bt-helper";
import {JournalList} from "../../core/model/journal-list";
import {SlideRangeFilterValue} from "../../core/model/slide-range-filter-value";
import * as moment from "moment";
import {Observable} from "rxjs";
import { PaginationConfigs } from "app/modules/constants/PaginationConstants";

@Component({
    selector: "neomp-provision-accounting",
    templateUrl: "./provision-accounting.component.html",
    styleUrls: ["./provision-accounting.component.scss"]
})
export class ProvisionAccountingComponent implements OnInit, AfterViewInit {

    @ViewChild("loadingDataComponent")
    loadingDataComponent: LoadingDataComponent;
    journalLists: JournalList[] = [];
    filterValue: SlideRangeFilterValue = new SlideRangeFilterValue();

    isInitialized: boolean;
    isDataLoading: boolean;
    pageSize = PaginationConfigs.pageSize;
    pageSizeOptions = PaginationConfigs.pageSizeOptions;
    pageIndex = PaginationConfigs.pageIndex;
    totalCount = 0;
    constructor(public journalProvider: JournalProvider) {
    }

    ngOnInit() {
        this.filterValue.isActive = true;
        this.filterValue.dateRange.end = moment();
        this.filterValue.dateRange.begin = moment().subtract(60, "days");
        
        this.getElements(this.filterValue);
    }

    ngAfterViewInit(): void {
        this.isInitialized = true;
    }

    getElements(filterValue) {

        this.dataLoading = true;
        const sort = { creationDate: -1 };

        const journalObservable: Observable<{data: JournalList[], totalCount: number}> = filterValue.isActive ?
            this.journalProvider.getJournalsReferencedByDateRangeWithPagination(
                this.loadingDataComponent,
                BusinessTransactionHelper.typeId.provisionAccounting.from,
                BusinessTransactionHelper.typeId.provisionAccounting.to,
                filterValue.dateRange,
                this.pageIndex,
                this.pageSize,
                sort) :
            this.journalProvider.getJournalsReferencedWithPagination(
                this.loadingDataComponent,
                BusinessTransactionHelper.typeId.provisionAccounting.from,
                BusinessTransactionHelper.typeId.provisionAccounting.to,
                this.pageIndex,
                this.pageSize,
                sort);

        journalObservable.subscribe(journalLists => {
            this.journalLists = journalLists.data.reverse();
            this.totalCount = journalLists.totalCount;
            this.dataLoading = false;
        }, error => {
            this.dataLoading = false;
        });
    }

    set dataLoading(dataLoading: boolean) {
        if (this.isInitialized) {
            this.isDataLoading = dataLoading;
        }
    }
    paginatorValueChanged(event) {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
        this.getElements(this.filterValue);
      }
}
