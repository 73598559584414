import {Injectable} from "@angular/core";
import {from, Observable} from "rxjs";
// import "rxjs-compat/add/observable/of";
import {RestProvider, RestProviderActions, RestProviderActionsDummy} from "./rest.provider";
import {environment} from "../../../../environments/environment";
import {SearchResult} from "../model/search-result";
import {SearchResultJson} from "../json/search-result.json";

@Injectable()
export class SearchProvider {
    private readonly CLASS_NAME = this.constructor.name;
    private searchResult: SearchResult = null;

    constructor(public rest: RestProvider) {
    }

    public doSearch(searchTerm: string): Observable<SearchResult> {
        searchTerm = encodeURIComponent(searchTerm.replace("/[^0-9a-zA-Z_\\- ]/g", ""));
        return from(new Promise<SearchResult>((resolve, reject) => {
            this.rest.get<SearchResultJson>(`${environment.settings.BASE_URL}/api/search/${searchTerm}`,
                null, true, new RestProviderActionsDummy())
                .toPromise()
                .then(response => {
                        this.searchResult = SearchResult.fromJson(response);
                        resolve(this.searchResult);
                    },
                    error => {
                        reject(error);
                    });
        }));
    }

    public searchProducts(searchTerm: string): Observable<SearchResult> {
        searchTerm = encodeURIComponent(searchTerm.replace("/[^0-9a-zA-Z_\\- ]/g", ""));
        return from(new Promise<SearchResult>((resolve, reject) => {
            this.rest.get<SearchResultJson>(`${environment.settings.BASE_URL}/api/search/products/${searchTerm}`,
                null, true, new RestProviderActionsDummy())
                .toPromise()
                .then(response => {
                        this.searchResult = SearchResult.fromJson(response);
                        resolve(this.searchResult);
                    },
                    error => {
                        reject(error);
                    });
        }));
    }
}
