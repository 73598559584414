import { GMAddress } from "./GMAddress"
import { GMCollective } from "./GMCollective";
import { GMProduct } from "./GMProduct";

export class GMRiskObject {
    address: GMAddress;
    birthDate: string;
    collective: GMCollective;
    contractOfferGroup: boolean;
    crossBorder: boolean;
    firstName: string;
    gender: string;
    lastName: string;
    products: Array<GMProduct>; 

    constructor(
        address: GMAddress,
        birthDate: string,
        // collective: GMCollective,
        firstName: string,
        gender: string,
        lastName: string,
        products: Array<GMProduct>
        ){
            this.address = address;
            this.birthDate = birthDate;
            // this.collective = collective;
            this.firstName = firstName;
            this.gender = gender === "weiblich" ? "F" : "M"; // S3t: check if unborn is an option?
            this.lastName = lastName;
            this.products = products;
            this.contractOfferGroup = false;
            this.crossBorder = false;
    }
}