<table matSortActive="creationDate" matSortDirection="desc" [dataSource]="dataSource" mat-table multiTemplateDataRows
  matSort>


  <ng-container matColumnDef="date">
    <th mat-header-cell mat-sort-header="creationDate" *matHeaderCellDef> {{'PAGE.LEADS.LEAD.LOG.DATE' | translate}}
    </th>
    <td mat-cell *matCellDef="let element">{{element.creationDate | date: 'dd.MM.yyyy HH:mm'}}</td>
  </ng-container>

  <ng-container matColumnDef="user">
    <th mat-header-cell mat-sort-header="ordererName" *matHeaderCellDef> {{'PAGE.LEADS.LEAD.LOG.USER' | translate}}
    </th>
    <td mat-cell *matCellDef="let element">{{element.ordererName}} </td>
  </ng-container>

  <ng-container matColumnDef="action">
    <th mat-header-cell mat-sort-header="transactionName" *matHeaderCellDef> {{'PAGE.LEADS.LEAD.LOG.VERLAUF' |
      translate}} </th>
    <td mat-cell *matCellDef="let element"> {{element.transactionName}} {{element.typeName}}</td>
  </ng-container>

  <ng-container matColumnDef="description" >
    <th mat-header-cell mat-sort-header="reasonForChange" *matHeaderCellDef> {{'PAGE.LEADS.LEAD.LOG.DETAIL' |
      translate}} </th>
    <td mat-cell *matCellDef="let element">  
      <ng-container *ngIf="element.transactionName == 'TRANSFER'"> 
        {{element.reasonForChange}}
      </ng-container>
      <ng-container *ngIf="element.sorKeys.time && element.effectiveDate">
       {{'PAGE.CUSTOMERDETAIL.JOURNAL.DATE' | translate }}: {{element.effectiveDate | date: 'dd.MM.yyyy' }},
       {{ 'PAGE.LEADS.LEAD.LEAD-EDIT.TIME'| translate}}: {{element.sorKeys.time}},</ng-container> 
       <ng-container *ngIf="element.descriptionField">
       {{ 'PAGE.CUSTOMERDETAIL.JOURNAL.LONGDESCRIPTION'| translate}}: {{element.descriptionField}}</ng-container>
    </td>
  </ng-container>

  <!-- Filterrow elements-->

  <!-- Date filter -->
  <ng-container matColumnDef="dateFilter">

    <th mat-header-cell *matHeaderCellDef>
      <mat-form-field floatLabel="never">
        <input [matDatepicker]="date" matInput [formControl]="dateFilter"
          placeholder="{{ 'TABLES.FILTERPLACEHOLDER.DATE'  }}">
        <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
        <mat-datepicker #date></mat-datepicker>
      </mat-form-field>
    </th>
  </ng-container>

  <ng-container matColumnDef="userFilter">
    <th mat-header-cell *matHeaderCellDef>
      <mat-form-field floatLabel="never">
        <input matInput [formControl]="userFilter" placeholder="{{ 'TABLES.FILTERPLACEHOLDER.NAME' | translate }}">
      </mat-form-field>
    </th>
  </ng-container>

  <ng-container matColumnDef="actionFilter">
    <th mat-header-cell *matHeaderCellDef>
      <mat-form-field floatLabel="never">
        <input matInput [formControl]="actionFilter" placeholder="{{ 'TABLES.FILTERPLACEHOLDER.STATUS' | translate }}">
      </mat-form-field>
    </th>
  </ng-container>

  <ng-container matColumnDef="descriptionFilter">
    <th mat-header-cell *matHeaderCellDef>
      <mat-form-field floatLabel="never">
        <input matInput [formControl]="descriptionFilter"
          placeholder="{{ 'TABLES.FILTERPLACEHOLDER.DESCRIPTION' | translate }}">
      </mat-form-field>
    </th>
  </ng-container>

  <ng-container matColumnDef="filter">
    <th *matHeaderCellDef mat-header-cell (click)="changeFilterView()"
      class="mat-header-cell mat-column-filter {{isFilterActive ? 'mat-column-filter-active':''}}">
      <div class="neomp-table-column-filter-element  mdi {{isFilterActive ? 'mdi-filter':'mdi-filter-outline'}}"></div>
    </th>
    <td mat-cell *matCellDef="let element">
    </td>
  </ng-container>
  <ng-container matColumnDef="filterRemove">
    <th mat-header-cell *matHeaderCellDef (click)="resetFilter()">
      <div class="neomp-table-column-filter-element mdi mdi-close"></div>
    </th>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr *matHeaderRowDef="displayedColumnFilters"
    class="neomp-table-row-filter {{showFilter? '': 'neomp-table-row-filter-hide'}}" mat-header-row></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>