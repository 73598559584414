import {Component, EventEmitter, Output} from "@angular/core";
import {FilterTableComponent} from "./filter-table-component";
import {DocumentResourceResult} from "../../model/document-resource-result";
import {FormControl} from "@angular/forms";
import {Helper} from "../../static/helper";
import {DocumentResourceResultJson} from "../../json/document.resource.result.json";
import {DocumentProvider} from "../../providers/document.provider";
import {Journal} from "../../model/journal";
import {ActionDialogData} from "../action.dialog.component";
import {ActionDialogService} from "../../providers/action-dialog.service";
import {BusinessTransactionHelper} from "../../static/bt-helper";
import { SlideRangeFilterValue } from "../../model/slide-range-filter-value";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { UserCommitState } from "app/modules/core/model/user-commit-state";

@Component({
    selector: "neomp-document-table",
    templateUrl: "./document-table.component.html",
    styleUrls: ["./document-table.component.scss"]
})
export class DocumentTableComponent extends FilterTableComponent<DocumentResourceResult> {
    private readonly TAG = this.constructor.name;

    @Output() applyAdditionalActionDialogData = new EventEmitter<ActionDialogData>();

    selectedDocumentId;
    isDialogOpen = false;

    documentTitleFilter = new FormControl("");
    categoryFilter = new FormControl("");
    documentTypeFilter = new FormControl("");
    dateFilter = new FormControl("");
    filterValues: DocumentResourceResult = new DocumentResourceResult();

    formControlFilters: FormControl[] = [
        null,
        this.documentTitleFilter,
        this.categoryFilter,
        this.documentTypeFilter,
        this.dateFilter,
        null
    ];
    displayedColumns = [
        "title",
        "category",
        "type",
        "date",
        "filter"
    ];
    displayedColumnFilters = [
        "documentTitleFilter",
        "categoryFilter",
        "documentTypeFilter",
        "dateFilter",
        "filterRemove"
    ];

    constructor(public documentProvider: DocumentProvider,
                public dialogService: ActionDialogService,
                public dialog: MatDialog,
                public matSnackBar: MatSnackBar,
                private translateService: TranslateService) {
        super();
    }
    
    dateFilterChanged(event: SlideRangeFilterValue): void {
        this.dateFilter.setValue(event.dateRange);
    }

    resetFilter() {
        this.filterValues = new DocumentResourceResult();
        super.resetFilter();
    }

    createFilter(): (data: DocumentResourceResult, filter: string) => boolean {
        return function (data, filter): boolean {
            const filterJson: DocumentResourceResultJson = JSON.parse(filter);
            const searchTerms: DocumentResourceResult = DocumentResourceResult.fromJson(filterJson);

            return Helper.checkStringContains(data.title, searchTerms.title) &&
                Helper.checkStringContains(data.category, searchTerms.category) &&
                Helper.checkStringContains(data.type, searchTerms.type) &&
                Helper.checkDateRange(data.getDateType(), Helper.stringToDatePickerFormat(searchTerms.getDate()));
        }
    }

    downloadDocument(documentResourceResult: DocumentResourceResult) {
        this.selectedDocumentId = documentResourceResult.id;
        if(this.selectedDocumentId){
            this.documentProvider.downloadDocument(documentResourceResult.id);
        }
        else{
            const translatedMessage = this.translateService.instant('PAGE.CONTAINER.MESSAGES.ERROR-DOCUMENT');
            this.matSnackBar.open(translatedMessage, 'X', UserCommitState.configCheckData);
        }
    }

    downloadDocumentFromMyNeo(documentResourceResult: DocumentResourceResult) {
        this.selectedDocumentId = documentResourceResult.id;
        if(this.selectedDocumentId){
            this.documentProvider.downloadDocument(documentResourceResult.id, true);
        }
        else{
            const translatedMessage = this.translateService.instant('PAGE.CONTAINER.MESSAGES.ERROR-DOCUMENT');
            this.matSnackBar.open(translatedMessage, 'X', UserCommitState.configCheckData);
        }
    }

    openActionDialog(event: Event, element: DocumentResourceResult) {
        this.isDialogOpen = true;
        event.stopPropagation();
        const dialogData: ActionDialogData = {
            transTypeId: BusinessTransactionHelper.typeId.documents.from,
            transName: "Document",
            transSubject: element.category,
            transTitle: element.title,
            transMessage: "",
            keyValues: [],
            person: null,
        }
        if (this.applyAdditionalActionDialogData) {
            this.applyAdditionalActionDialogData.emit(dialogData);
        }
        this.dialogService.openByType(dialogData, 
            {
                defaultTypeId: BusinessTransactionHelper.typeId.documents.from 
            },
            this.TAG
        );
        this.dialog.afterAllClosed.subscribe(() => {
            this.isDialogOpen = false;
        });
    }
}
