import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { CommunicationAddress } from 'app/modules/core/model/communication-address';
import { CommAddrTypes } from 'app/modules/core/model/enum-commaddrtype';
import { LeadLog } from 'app/modules/core/model/LeadLog.enum';
import { Person } from 'app/modules/core/model/person';
import { SDAKey } from 'app/modules/core/model/sdakey';
import { BusinessTransactionHelper } from 'app/modules/core/static/bt-helper';
import { KeycloakService } from 'keycloak-angular';
import { LEADS_CONFIGS } from '../../configs/lead-configs';
import { LeadDetails } from '../../Interfaces/LeadDetails';
import { LeadsService } from '../../services/leads.service';
import { FamilyMember } from '../leads/leads-edit-termin.component';
import * as uuid from "uuid";
import { Reference } from 'app/modules/core/model/reference';
import { Observable, Subscription } from 'rxjs';
import { UserCommitState } from 'app/modules/core/model/user-commit-state';
import { log } from 'app/modules/core/providers/logger.provider';
import moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTable } from '@angular/material/table';
import { MatSelect } from '@angular/material/select';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { debounceTime, startWith, map } from 'rxjs/operators';
import { Region } from 'app/modules/shared/interfaces/Region';

const MIN_REGION_COMPLETER_LENGTH = 3;

@Component({
  selector: 'neomp-create-lead',
  templateUrl: './create-lead.component.html',
  styleUrls: ['./create-lead.component.scss']
})
export class CreateLeadComponent implements OnInit {

  @ViewChild(MatTable)
  table: MatTable<FamilyMember>;
  subscriptions: Subscription[] = [];

  familyMembers: FamilyMember[] = [];
  clearTable = [];
  newLeadType: string[] = ['Steuerkunde', 'Eigentermin', 'Adressvermittler', 'Weiterempfehlung'];
  displayedColumns: string[] = ['gender', 'name', 'birthdate', 'nationality', 'action'];
  genderData: string[] = ["Man", "Frau"];
  leadConfigs = LEADS_CONFIGS;
  lead: Person = new Person();
  user: any = null;
  familyMembersContentChanged = false;
  options: Region[] = [];
  filteredOptions: Observable<Region[]>;
  regionFC: FormControl = new FormControl("", [Validators.required, forbiddenObjectValidator()]);

  optionsRegionen: Region[] = [];
  filteredOptionsRegionen: Observable<Region[]>;
  private readonly MIN_REGION_COMPLETER_LENGTH = 3;

  constructor(
    private keycloak: KeycloakService,
    private leadsService: LeadsService,
    public snackBar: MatSnackBar,
    private http: HttpClient,) { }

  createLeadForm: FormGroup = new FormGroup({
    salutation: new FormControl('', [Validators.required]),
    preferedLanguage: new FormControl('', [Validators.required]),
    lead_personen: new FormControl(''),
    name: new FormControl('', [Validators.required]),
    firstName: new FormControl('', [Validators.required]),
    phone2: new FormControl('', [Validators.required, Validators.pattern('[0-9]{10,}')]),
    email2: new FormControl('', [Validators.required, Validators.email]),
    address: new FormControl('', [Validators.required]),
    ort: new FormControl('', [Validators.required]),
    zip: new FormControl('', [Validators.required]),
    kanton: new FormControl(''),//Not mandatory
    birthday: new FormControl('', [Validators.required, this.isValidDate()]),
    lead_extradata: new FormControl(''),//Not mandatory
    lead_source: new FormControl('', [Validators.required]),
    lead_typ: new FormControl('', [Validators.required]),
    appointment: new FormControl(false, [Validators.required]),
    termin_time: new FormControl('',),
    termin_date: new FormControl(''),
    termin_place: new FormControl(''),
  });

  isValidDate(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      const today: Date = new Date();

      if (!value) {
        return null;
      }

      const isOlderThan18Years = (Number(moment(today).format("YYYYY")) - Number(moment(value).format("YYYYY"))) < 18;

      if (new Date(control.value) > today || isOlderThan18Years || Number(moment(value).format("YYYYY")) < 1900)
        return { "LessThanToday": true };

      return null;
    }
  }

  rowNumberControl = new FormControl('');
 
  async ngOnInit(): Promise<void> {
    this.user = await this.keycloak.loadUserProfile();

    this.dynamicValidator();
    this.zipToCity();

    this.http.get<Region[]>(`${environment.settings.VGR_URL}/regionen`).subscribe((res: Region[]) => {
      this.optionsRegionen = res;
    })

    this.filteredOptionsRegionen = this.createLeadForm.controls["zip"].valueChanges.pipe(
      map((value: string) => {
        return this._filterRegions(value || '')}),
    )
    this.filteredOptionsRegionen.subscribe(f=>{})
  }

  private _filterRegions(value: string): Region[] {
    const filterValue = value.toString();
    if (value !== "" && filterValue.length >= this.MIN_REGION_COMPLETER_LENGTH){
      return this.optionsRegionen.filter(option => option.PLZ.toString().toLowerCase().includes(filterValue));
    }
    else return [];
  }
  
  selectedRegion(ev: any): any {   
    this.createLeadForm.controls.zip.setValue(ev.option.value.PLZ)
  }

  displayRegionFn(region: string) {
    if (!region) return '';
    return `${region}`;
  }

  dynamicValidator() {
    const appointment = this.createLeadForm.get('appointment');

    appointment.valueChanges.subscribe(value => {
      this.createLeadForm.controls["termin_time"].addValidators(value ? [Validators.required] : [])
      this.createLeadForm.controls["termin_date"].addValidators(value ? [Validators.required] : [])
      this.createLeadForm.controls["termin_place"].addValidators(value ? [Validators.required] : [])
    })
  }

  zipToCity() {

    const zip = this.createLeadForm.get('zip');

    this.http.get<Region[]>(`${environment.settings.VGR_URL}/regionen`).subscribe((res: Region[]) => {
      this.options = res;
    })
    this.filteredOptions = zip.valueChanges.pipe(
      debounceTime(200),
      startWith(''),
      map((value: string) => this._filter(value || '')),
    )

    this.filteredOptions.subscribe(f => {
      if (!f.length) return
      this.createLeadForm.controls.ort.setValue(f[0].Ort)
    })
  }

  checkboxValueChange() {
    if (this.createLeadForm.controls.appointment.value === true) {
      this.createLeadForm.controls.termin_time.setValidators([Validators.required]);
      this.createLeadForm.controls.termin_date.setValidators([Validators.required]);
      this.createLeadForm.controls.termin_place.setValidators([Validators.required]);
    } else {
      this.createLeadForm.controls.termin_time.setValidators([Validators.nullValidator]);
      this.createLeadForm.controls.termin_date.setValidators([Validators.nullValidator]);
      this.createLeadForm.controls.termin_place.setValidators([Validators.nullValidator]);
    }
    this.createLeadForm.controls.termin_time.updateValueAndValidity();
    this.createLeadForm.controls.termin_date.updateValueAndValidity();
    this.createLeadForm.controls.termin_place.updateValueAndValidity();
  }

  addFamilyMember(numRows: number) {
    for (let i = 0; i < numRows; i++) {
      const newRow: FamilyMember = { name: '', gender: '', birthdate: '', nationality: '', action: '' }
      this.familyMembers.push(newRow);
    }
    const currentNumRows = this.familyMembers.length;

    if (currentNumRows > numRows) {
      this.familyMembers.splice(numRows, currentNumRows - numRows);
    }
    this.table.renderRows();
  }

  focusOutFunction(event) {
    const numRows = event.target.value;
    this.addFamilyMember(numRows);
    this.rowNumberControl.setValue('');
  }

  s1(sel: MatSelect) {
    sel.placeholder = '';
  }

  s2(sel: MatSelect) {
    if (sel.value === undefined) {
      sel.placeholder = '';
    }
  }

  deleteFamilyMember(person: FamilyMember): void {
    this.familyMembersContentChanged = true;
    this.familyMembers.splice(this.familyMembers.indexOf(person), 1);
    this.table.renderRows();
  }

  createLead() {
    const leadForm: LeadDetails = this.createLeadForm.value;
    this.fillCommunicationAddresses(leadForm);
    this.lead.created = moment().format('YYYY-MM-DDTHH:mm:ss.SSSZZ');
    this.lead.updated = moment().format('YYYY-MM-DDTHH:mm:ss.SSSZZ');
    this.lead.address.street = leadForm.address;
    this.lead.address.city = leadForm.ort;
    this.lead.address.postalCode = leadForm.zip;
    this.lead.address.countryIndicator = leadForm.kanton;
    this.lead.salutation = leadForm.salutation;
    this.lead.name = leadForm.name;
    this.lead.firstName = leadForm.firstName;
    this.lead.birthdate = leadForm.birthday;
    this.lead.preferedLanguage = leadForm.preferedLanguage;
    this.lead.uuid = uuid.v4();
    this.lead.lead.lead_extradata = leadForm.lead_extradata;
    this.lead.lead.lead_creadate = moment().format('YYYY-MM-DDTHH:mm:ss.SSSZZ');
    this.lead.lead.lead_agency = this.user.attributes.agenturID[0];
    this.lead.lead.termin_family_members = JSON.stringify(this.familyMembers);
    this.lead.lead.lead_personen = leadForm.lead_personen;
    this.lead.lead.leadpool = leadForm.leadpool;
    this.lead.lead.termin_berater_shortcut = this.user.attributes.advisorid[0];
    this.lead.lead.lead_source = leadForm.lead_source;
    switch (leadForm.lead_typ) {
      case 'Steuerkunde':
          this.lead.lead.lead_typ = 'Projekt NeoTax';
          break;
      case 'Eigentermin':
          this.lead.lead.lead_typ = 'Eigentermin';
          break;
      case 'Adressvermittler':
          this.lead.lead.lead_typ = 'Adressvermittler';
          break;
      case 'Weiterempfehlung':
          this.lead.lead.lead_typ = 'Weiterempfehlung';
          break;
      default:
          break;
  }
    this.lead.lead.termin_time = leadForm.termin_time;
    this.lead.lead.termin_date = leadForm.termin_date;
    this.lead.lead.termin_place = leadForm.termin_place;
    this.lead.lead.termin_source_agency = this.createLeadForm.controls.appointment.value === true ? this.user.attributes.agenturID[0] : "0";

    this.sendCreateLead()
  }
  fillCommunicationAddresses(form: LeadDetails): void {
    const indexes = {
      PHONE_MOBILE_BUSINESS: 0,
      PHONE_MOBILE_PRIVATE: 1,
      EMAIL_BUSINESS: 2,
      EMAIL_PRIVATE: 3
    };

    const PHONE_MOBILE_BUSINESS_INDEX = this.lead.communicationAddresses.findIndex(x => x.commAddrType.key === CommAddrTypes.PHONE_MOBILE_BUSINESS)

    if (PHONE_MOBILE_BUSINESS_INDEX > -1) {
      this.lead.communicationAddresses[PHONE_MOBILE_BUSINESS_INDEX].address = form.phone1;
      this.arrayMove(this.lead.communicationAddresses, PHONE_MOBILE_BUSINESS_INDEX, indexes.PHONE_MOBILE_BUSINESS);
    } else {
      this.insert(this.lead.communicationAddresses, indexes.PHONE_MOBILE_BUSINESS, new CommunicationAddress({
        commAddrType: new SDAKey({
          key: CommAddrTypes.PHONE_MOBILE_BUSINESS,
          sdaValue: 'MOBILE_BUSINESS',
          sorValue: 'MOBILE_BUSINESS'
        }),
        address: form.phone1,
        info: null
      }));

    }

    const PHONE_MOBILE_PRIVATE_INDEX = this.lead.communicationAddresses.findIndex(x => x.commAddrType.key === CommAddrTypes.PHONE_MOBILE_PRIVATE)

    if (PHONE_MOBILE_PRIVATE_INDEX > -1) {
      this.lead.communicationAddresses[PHONE_MOBILE_PRIVATE_INDEX].address = form.phone2;
      this.arrayMove(this.lead.communicationAddresses, PHONE_MOBILE_PRIVATE_INDEX, indexes.PHONE_MOBILE_PRIVATE);
    } else {
      this.insert(this.lead.communicationAddresses, indexes.PHONE_MOBILE_PRIVATE, new CommunicationAddress({
        commAddrType: new SDAKey({
          key: CommAddrTypes.PHONE_MOBILE_PRIVATE,
          sdaValue: 'MOBILE_PRIVATE',
          sorValue: 'MOBILE_PRIVATE'
        }),
        address: form.phone2,
        info: null
      }));
    }

    const EMAIL_BUSINESS_INDEX = this.lead.communicationAddresses.findIndex(x => x.commAddrType.key === CommAddrTypes.EMAIL_BUSINESS)

    if (EMAIL_BUSINESS_INDEX > -1) {
      this.lead.communicationAddresses[EMAIL_BUSINESS_INDEX].address = form.email1;
      this.arrayMove(this.lead.communicationAddresses, EMAIL_BUSINESS_INDEX, indexes.EMAIL_BUSINESS);

    } else {

      this.insert(this.lead.communicationAddresses, indexes.EMAIL_BUSINESS, new CommunicationAddress({
        commAddrType: new SDAKey({
          key: CommAddrTypes.EMAIL_BUSINESS,
          sdaValue: 'EMAIL_BUSINESS',
          sorValue: 'EMAIL_BUSINESS'
        }),
        address: form.email1,
        info: null
      }))
    }

    const EMAIL_PRIVATE_INDEX = this.lead.communicationAddresses.findIndex(x => x.commAddrType.key === CommAddrTypes.EMAIL_PRIVATE)

    if (EMAIL_PRIVATE_INDEX > -1) {
      this.lead.communicationAddresses[EMAIL_PRIVATE_INDEX].address = form.email2;
      this.arrayMove(this.lead.communicationAddresses, EMAIL_PRIVATE_INDEX, indexes.EMAIL_PRIVATE);

    } else {
      this.insert(this.lead.communicationAddresses, indexes.EMAIL_PRIVATE, new CommunicationAddress({
        commAddrType: new SDAKey({
          key: CommAddrTypes.EMAIL_PRIVATE,
          sdaValue: 'EMAIL_PRIVATE',
          sorValue: 'EMAIL_PRIVATE'
        }),
        address: form.email2,
        info: null
      }));
    }
  }

  sendCreateLead() {

    let typeId = "";

    switch (this.createLeadForm.value.lead_typ) {
        case 'Steuerkunde':
            typeId = BusinessTransactionHelper.typeId.newCreateLead.steuerkunde;
            break;
        case 'Eigentermin':
            typeId = BusinessTransactionHelper.typeId.newCreateLead.eigentermin;
            break;
        case 'Adressvermittler':
            typeId = BusinessTransactionHelper.typeId.newCreateLead.adressvermittler;
            break;
        case 'Weiterempfehlung':
            typeId = BusinessTransactionHelper.typeId.newCreateLead.weiterempfehlung;
            break;
        default:
            break;
    }

    const sorKeys = {
      'advisorId': this.user.attributes.advisorid[0],
      'agenturId': this.user.attributes.agenturID[0]
      
    }

    const referenceAgentur = new Reference();
    referenceAgentur.id = this.user.attributes.agenturID[0]
    referenceAgentur.type = new SDAKey();
    referenceAgentur.type.key = "090";
    referenceAgentur.type.value = "Agentur";

    const referencesBerater = new Reference();
    referencesBerater.id = this.user.attributes.advisorid[0]
    referencesBerater.type = new SDAKey();
    referencesBerater.type.key = "080";
    referencesBerater.type.value = "Berater";

    const classification = new Reference();
    classification.id = this.lead.uuid;
    classification.type = new SDAKey();
    classification.type.key = "040";
    classification.type.value = "Partner";

    const creationDate = moment().format('YYYY-MM-DDTHH:mm:ss.SSSZZ');

    const actionData = BusinessTransactionHelper.createLeadBTActionObject(
      LeadLog.CREATE_LEAD,
      typeId,
      '#MKP Create Lead',
      '#MKP Create Lead',
      '',
      this.user.attributes.advisorid[0],
      [],
      this.lead,
      '',
      sorKeys,
      this.user.firstName
    );

    actionData.transaction.references.push(referenceAgentur, referencesBerater);
    actionData.transaction.creationDate = creationDate;
    actionData.transaction.classification = classification;

    this.subscriptions.push(
      this.leadsService.sendLeadAction(actionData)
        .subscribe(() => {
          this.createLeadForm.reset();
          this.createLeadForm.get('appointment').clearValidators();
          this.createLeadForm.get('appointment').updateValueAndValidity();
          this.familyMembers = this.clearTable;
          this.snackBar.open("Lead created successfully set", "X", UserCommitState.configSuccess);
        }, err => {
          log.error(`Something went wrong while calling POST-> lead/action `, err);
          this.snackBar.open("Something went wrong!", "X", UserCommitState.configError)
        })
    );
  }

  insert(arr: any, index: number, newItem: any): void {
    index > arr.length - 1 ?
      arr.push(newItem) : arr.splice(index, 0, newItem);
  }

  arrayMove(arr: any, fromIndex: number, toIndex: number): void {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }

  private _filter(value: string): Region[] {
    const filterValue = value.toString();
    if (value !== "" && filterValue.length >= MIN_REGION_COMPLETER_LENGTH)
      return this.options.filter(option => option.PLZ.toString().includes(filterValue));
    else return [];
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
  }
}

export function forbiddenObjectValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return typeof control.value !== 'object' || control.value === null ? { forbiddenObject: true } : null;
  }
}
