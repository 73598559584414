export enum CommAddrTypes {
    PHONE_PRIVATE = "10",
    PHONE_BUSINESS = "20",
    PHONE = "21",
    PHONE_MOBILE = "30",
    PHONE_MOBILE_PRIVATE = "31",
    PHONE_MOBILE_BUSINESS = "32",
    FAX = "40",
    FAX_PRIVATE = "41",
    FAX_BUSINESS = "42",
    EMAIL = "50",
    EMAIL_PRIVATE = "51",
    EMAIL_BUSINESS = "52",
    EMAIL_SECURE = "53",
    EMAIL_SECURE_PRIVATE = "54",
    EMAIL_SECURE_BUSINESS = "55",
    WEB = "60",
    WEB_PRIVATE = "61",
    WEB_BUSINESS = "62"
}
