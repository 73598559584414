<div class="container">
  <div class="icon">
    <img class="exclamation-mark" src="../../assets/icons/icons8-medium-risk-50.png">
  </div>
  <div class="close-button" mat-dialog-actions>
    <span class="close" mat-button [mat-dialog-close]="'close'">
      <img src="../../assets/icons/close_FILL0_wght400_GRAD0_opsz24.svg" alt="close">
    </span>
  </div>
</div>

<div mat-dialog-content>
  <p [ngStyle]="{'color': fontColor}">{{ text }}</p>
</div>