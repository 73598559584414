import {PostalAddressJson} from "../json/postal.address.json";

export class PostalAddress {
    principalResidence: boolean;
    countryIndicator = "";
    country = "";
    postalCode = "";
    city = "";
    state = "";
    street = "";
    houseNumber = "";
    addressSupplement = "";
    info = "";
    pOBox = "";

    constructor(data?: PostalAddressJson) {
        if (data) {
            this.principalResidence = data.principalResidence;
            this.countryIndicator = data.countryIndicator || "";
            this.country = data.country || "";
            this.postalCode = data.postalCode || "";
            this.city = data.city || "";
            this.street = data.street || "";
            this.houseNumber = data.houseNumber || "";
            this.addressSupplement = data.addressSupplement || "";
            this.info = data.info || "";
            this.pOBox = data.pOBox || "";
        }
    }
}
