<table [dataSource]="dataSource" class="msg-helper-width-100"
       mat-table
       matSort
       matSortDirection="desc">

       <ng-container matColumnDef="customerName">
           <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'PAGE.CANCELLATION_MANAGEMENT.TABLE.CUSTOMER' | translate }}</th>
           <td *matCellDef="let element"
               mat-cell>{{element.customerName != null ? element.customerName : 'PAGE.APPLICATIONS.TABLE.NOT_SET' | translate}}</td>
       </ng-container>
    <ng-container matColumnDef="cancellationStatus">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'PAGE.CANCELLATION_MANAGEMENT.TABLE.CANCELLATION_STATUS' | translate }}</th>
        <td *matCellDef="let element" mat-cell> {{element.cancellationStatus}}</td>
    </ng-container>
    <ng-container matColumnDef="cancellationBetreff">
        <th *matHeaderCellDef mat-header-cell
            mat-sort-header>{{ 'PAGE.CANCELLATION_MANAGEMENT.TABLE.SUBJECT' | translate }}</th>
        <td *matCellDef="let element" mat-cell> {{element.cancellationBetreff}}</td>
    </ng-container>


    <ng-container matColumnDef="cancellationDocumentKVG">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>
            {{ 'PAGE.CANCELLATION_MANAGEMENT.TABLE.CANCELLATION_DOCUMENT_KVG' | translate }}
        </th>
        <td *matCellDef="let element" mat-cell class="td-center">
            <span *ngFor="let resource of getCancellationDocumentData(element, 'KVG')" (click)="$event.stopPropagation()">
                <button mat-icon-button (click)="!resource.fromMyNeo ? download(resource.id) : downloadFromMyNeo(resource.id)" [title]="resource.title">
                    <div class="neomp-table-column-icon-element mdi {{ documentProvider.getIconByName(resource.title) }}"></div>
                </button>
            </span>
        </td>
    </ng-container>
    <ng-container matColumnDef="cancellationDocumentVVG">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>
            {{ 'PAGE.CANCELLATION_MANAGEMENT.TABLE.CANCELLATION_DOCUMENT_VVG' | translate }}
        </th>
        <td *matCellDef="let element" mat-cell class="td-center">
            <span *ngFor="let resource of getCancellationDocumentData(element, 'VVG')" (click)="$event.stopPropagation()">
                <button mat-icon-button (click)="!resource.fromMyNeo ? download(resource.id) : downloadFromMyNeo(resource.id)" [title]="resource.title">
                    <div class="neomp-table-column-icon-element mdi {{ documentProvider.getIconByName(resource.title) }}"></div>
                </button>
            </span>
        </td>
    </ng-container>
    
    <ng-container matColumnDef="cancellationDocumentKVG_VVG">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>
            {{ 'PAGE.CANCELLATION_MANAGEMENT.TABLE.CANCELLATION_DOCUMENT_KVG_VVG' | translate }}
        </th>
        <td *matCellDef="let element" mat-cell class="td-center">
            <span *ngFor="let resource of getCancellationDocumentData(element, 'KVG_VVG')" (click)="$event.stopPropagation()">
                <button mat-icon-button (click)="!resource.fromMyNeo ? download(resource.id) : downloadFromMyNeo(resource.id)" [title]="resource.title">
                    <div class="neomp-table-column-icon-element mdi {{ documentProvider.getIconByName(resource.title) }}"></div>
                </button>
            </span>
        </td>
    </ng-container>

    <ng-container matColumnDef="release">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>
            {{ 'PAGE.CANCELLATION_MANAGEMENT.TABLE.RELEASE' | translate }}
        </th>
        <td *matCellDef="let element" mat-cell>
            <mat-checkbox [(ngModel)]="element.cancellations.cancellationApproval"
                          [checked]="element.cancellations.cancellationApproval"
                          (click)="$event.stopPropagation()"
                          (change)="cancellationApprovalToggle(element)">
            </mat-checkbox>
        </td>
    </ng-container>
    

    <ng-container matColumnDef="filter">
        <th (click)="changeFilterView()" *matHeaderCellDef
            class="mat-header-cell mat-column-filter {{isFilterActive ? 'mat-column-filter-active':''}}"
            mat-header-cell>
            <div
                class="neomp-table-column-filter-element  mdi {{isFilterActive ? 'mdi-filter':'mdi-filter-outline'}}"></div>
        </th>
        <td *matCellDef="let element" mat-cell class="mat-column-filter-action">
            <div class="neomp-table-column-filter-element mdi mdi-plus" [disabled]="isDialogOpen"
            (click)="isDialogOpen ? null : openActionDialog($event, element)"></div>
        </td>
    </ng-container>

    <!-- Filter row definition -->
    <ng-container matColumnDef="customerNameFilter">
        <th *matHeaderCellDef mat-header-cell>
            <mat-form-field class="filter" floatLabel="never">
                <input
                    [formControl]="customerNameFilter"
                    placeholder="{{ 'TABLES.FILTERPLACEHOLDER.NAME' | translate }}"
                    matInput>
            </mat-form-field>
        </th>
    </ng-container>

    <ng-container matColumnDef="cancellationStatusFilter">
        <th *matHeaderCellDef mat-header-cell>
            <mat-form-field class="filter" floatLabel="never">
                <input
                    [formControl]="cancellationStatusFilter"
                    placeholder="{{ 'TABLES.FILTERPLACEHOLDER.CANCELLATION_STATUS' | translate }}"
                    matInput>
            </mat-form-field>
        </th>
    </ng-container>
    <ng-container matColumnDef="cancellationBetreffFilter">
        <th *matHeaderCellDef mat-header-cell>
            <mat-form-field class="filter" floatLabel="never">
                <input
                    [formControl]="cancellationBetreffFilter"
                    placeholder="{{ 'TABLES.FILTERPLACEHOLDER.SUBJECT' | translate }}"
                    matInput>
            </mat-form-field>
        </th>
    </ng-container>
    <ng-container matColumnDef="cancellationDocumentKVGFilter">
        <th mat-header-cell *matHeaderCellDef>
        </th>
    </ng-container>
    <ng-container matColumnDef="cancellationDocumentVVGFilter">
        <th mat-header-cell *matHeaderCellDef>
        </th>
    </ng-container>
    <ng-container matColumnDef="cancellationDocumentKVG_VVGFilter">
        <th mat-header-cell *matHeaderCellDef>
        </th>
    </ng-container>
    <ng-container matColumnDef="releaseFilter">
        <th mat-header-cell *matHeaderCellDef>
        </th>
    </ng-container>
 
    <ng-container matColumnDef="filterRemove">
        <th (click)="resetFilter()" *matHeaderCellDef mat-header-cell>
            <div class="neomp-table-column-filter-element mdi mdi-close"></div>
        </th>
    </ng-container>
    <!--end of  Filter row definition-->


    <tr *matHeaderRowDef="displayedColumns"
        mat-header-row></tr>

    <tr *matHeaderRowDef="displayedColumnFilters"
        class="neomp-table-row-filter {{showFilter? '': 'neomp-table-row-filter-hide'}}"
        mat-header-row></tr>
    <tr (click)="applicationsRowClick(applicationRow)"
        *matRowDef="let applicationRow; columns: displayedColumns"
        class="neomp-table-row-selected container-table-row {{applicationRow.uuid === selectedCancellationRowIndex ? 'container-table-row-selected':''}}"
        mat-row></tr>
</table>
