import {Person} from "./person";
import {HouseholdJson} from "../json/household.json";

export class Household {
    representant: Person = new Person();
    persons: Person[] = [];

    public constructor(value?: HouseholdJson) {
        if (value) {
            this.representant = Person.fromJson(value.representant);
            this.persons = Person.fromJsons(value.persons);
        }
    }

    get householdName(): string {
        return this.representant ? this.representant.fullName : "";
    }

    set householdName(val: string) {
        if (!this.representant) {
            this.representant = new Person();
        }
        this.representant.name = val;
    }

    get householdAddress(): string {
        let address = "";
        const person: Person = this.representant;
        if (person && person.address) {
            address = (person.address.postalCode.length > 0 ? person.address.postalCode + " " : "") + person.address.city;
        }
        return address;
    }

    set householdAddress(val: string) {
        if (!this.representant) {
            this.representant = new Person();
        }
        this.representant.address.city = val;
    }
    get householdAppStatus(): any {
        return  this.representant.neoAppInfo.active;
    }

    set householdAppStatus(val: any) {
        this.representant.neoAppInfo.active = val;
    }

    get householdAppStatusLoggedIn(): any {
        return  this.representant.neoAppInfo.online;
    }

    set householdAppStatusLoggedIn(val: any) {
        this.representant.neoAppInfo.online = val;
    }
    
    public static fromJson(response: HouseholdJson): Household {
        const result = new Household();
        result.representant = Person.fromJson(response.representant);
        result.persons = Person.fromJsons(response.persons);

        return result;
    }

    public static fromJsons(response: HouseholdJson[]): Household[] | undefined {
        if (response) {
            return response.map(household => new Household(household));
        }
    }
    public getPartnerById( partnerUuid: string):Person{
     return this.persons.find(
            person => person.uuid === partnerUuid);
    }
}
