import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
// import "rxjs-compat/add/observable/of";
import {RestProvider, RestProviderActions} from "./rest.provider";
import {environment} from "../../../../environments/environment";
import {Household} from "../model/household";
import {HouseholdJson} from "../json/household.json";
import { map } from "rxjs/operators";

@Injectable()
export class HouseholdProvider {
    private readonly CLASS_NAME = this.constructor.name;
    private expandedHousholdId: string;

    constructor(public rest: RestProvider) {
    }

    public getLazyHouseholds(
        restProviderActions: RestProviderActions,
        useCache = true,
        mandatesOnly = false,
        page: number,
        size: number): Observable<{data: HouseholdJson[], totalCount: number}> {
        let queryParams = `?mandateOnly=${mandatesOnly}&page=${page}&size=${size}`;
return this.rest.get<{data: HouseholdJson[], totalCount: number}>(`${environment.settings.BASE_URL}/api/advisor/households${queryParams}`,
            null, useCache, restProviderActions)
            .pipe(map((res) => {
                return {
                    data: Household.fromJsons(res.data),
                    totalCount: res.totalCount
                };
            }));
    }

    public getHouseholds(restProviderActions: RestProviderActions, useCache = true, mandatesOnly = false): Observable<Household[]> {
        let queryParams = `?mandateOnly=${mandatesOnly}`;
return this.rest.get<{data: HouseholdJson[], totalCount: number}>(`${environment.settings.BASE_URL}/api/advisor/households${queryParams}`,
            null, useCache, restProviderActions)
            .pipe(map((res) => {
                const households  = Household.fromJsons(res.data);
                return households;
            }));
    }

    public getHouseholdForPersonUuid(personUuid: string, restProviderActions: RestProviderActions): Observable<Household> {
        return this.getHouseholds(restProviderActions).pipe(map((res) => {
            const households = res.filter(h => {
                let foundPerson = false;
                h.persons.forEach((person) => {
                    if (person.uuid === personUuid) {
                        foundPerson = true;
                    }
                });
                return foundPerson;
            });
            if (households.length > 0) {
                return households[0];
            }
            return null;
        }));
    }

    public getCompleteHouseholdByUuid(representativeUuid: string, restProviderActions: RestProviderActions): Observable<Household> {
        return this.rest.get<HouseholdJson>(`${environment.settings.BASE_URL}/api/advisor/household/${representativeUuid}`, null, true, restProviderActions)
            .pipe(map((res) => {
                    return Household.fromJson(res);
                }
                , error => {
                    return error;
                }
            ));
    }

    public getHouseholdExpanded(): string {
        return this.expandedHousholdId;
    }

    public sendExpandedHousehold(household: string): void {
        this.expandedHousholdId = household;
    }
}
