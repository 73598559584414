import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Person } from 'app/modules/core/model/person';
import { GMOfferDialogData } from '../../interfaces/GMOfferDialogData';
import { ConfigData } from '../../models/ConfigData';
import { LeistungFilter } from '../../models/LeistungFilter';
import { NeoInputCompleterItem } from '../../models/NeoInputCompleterItem';
import { NeoRegion } from '../../models/NeoRegion';
import { Persondata } from '../../models/PersonData';
import { SearchParams } from '../../models/SearchParams';
import { GmofferService } from '../../services/gmoffer.service';
import { SearchMaskService } from '../../services/search-mask.service';
import { GMOfferConstants } from '../../constants/gm-offer-constants';
import { ITarifResponse } from '../../interfaces/ITarifResponse';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { GMOfferData } from '../../models/GMOfferData';
import { GMRiskObject } from '../../models/GMRiskObject';
import { ActionDataProvider } from 'app/modules/core/providers/action-data.provider';
import { TranslateService } from "@ngx-translate/core";
import { forkJoin, Subscription } from 'rxjs';
import { FileUpload } from 'app/modules/core/model/file-upload';

@Component({
  selector: 'neomp-gmoffer-dialog',
  templateUrl: './gmoffer-dialog.component.html',
  styleUrls: ['./gmoffer-dialog.component.scss']
})
export class GMOfferDialogComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  isDataLoading = false;
  wholeFamilyChecked = false;
  tarifTypes = [GMOfferConstants.TARIFF_TYPE_BASE, GMOfferConstants.TARIFF_TYPE_HMO, GMOfferConstants.TARIFF_TYPE_HAM, GMOfferConstants.TARIFF_TYPE_DIV];
  productsForm = new FormGroup({
    vgrProducts: new FormArray([]),
    gmProducts: new FormArray([])
  });

  regions: NeoInputCompleterItem[] = [];
  searchData: SearchParams = new SearchParams();
  franchises: Number[] = [];

  selectedPerson: Person | null = null;
  selectedFranchise: number;
  selectedRegion: NeoInputCompleterItem | null = null;
  selectedTarif = '';

  vgrTarifResponseData: ITarifResponse[] = [];

  selectedLang = '';

  selectedIndex = 0;
  // TODO create interface
  GMProducts = [];
  matchedProds = [];

  constructor(
    private cdr: ChangeDetectorRef,
    private gmOfferService: GmofferService,
    private translate: TranslateService,
    public translateService: TranslateService,
    public actionDataProvider: ActionDataProvider,
    @Inject(MAT_DIALOG_DATA) public data: GMOfferDialogData) {
    this.regions = gmOfferService.regions;
  }
 
  get gmProducts() {
    return this.productsForm.controls.gmProducts as FormArray;
  }

  get vgrProducts() {
    return this.productsForm.controls.vgrProducts as FormArray;
  }

  ngOnInit() {
    this.isDataLoading = true;
    this.selectedLang = this.translateService.currentLang;

    this.selectedPerson = this.data.representant;
    this.getMatchedProds();
    if (Object.keys(this.gmOfferService.franchiseData).length > 0 && this.regions.length > 0) {
      this.autoFillValues();
    }

    // GET regions
    if (!this.regions || this.regions.length <= 0) {
      this.gmOfferService.loadRegions().then(res => {
        this.regions = res;
        this.autoFillValues();
      });
    }

    // GET configs
    if (Object.keys(this.gmOfferService.franchiseData).length === 0) {
      this.loadConfigData();
    }
    this.buildForm();


  }

  getMatchedProds(): void {
    this.gmOfferService.getGmProductsAndVariants(this.data.persons).subscribe(res => {
      this.matchedProds = res;
      this.checkMatchedProds();
    });
  }
  checkMatchedProds(): void {
    if (this.matchedProds && this.matchedProds.length > 0) {
      for (let i = 0; i < this.data.persons.length; i++) {
        this.matchedProds[i].map((matchedProd: any) => {
          const prodIndex = this.gmProducts.controls[i]['controls'].findIndex(y => y.controls.long.value === matchedProd.long);
          if (prodIndex > -1) {
            this.gmProducts.controls[i]['controls'][prodIndex].patchValue({
              category: true,
              selectedVariant: matchedProd.levelCode
            });
          }
        });
        // Move checked products on top
        if (this.gmProducts.controls) {
          const form = this.gmProducts.controls[i]['controls'];
          form.sort((x: any, y: any) => {
            return y.value.category - x.value.category;
          });

        }

        // A3N: fix Expression has changed after it was checked error.
        this.cdr.detectChanges();
      }
    }
    this.filterProducts();
  }

  // initially we build form with all gm products ( first filter is person age )
  buildForm(): void {
    const requests = [];

    this.data.persons.map((p: Person) => {

      // create formControls for VGR products
      this.vgrProducts.push(new FormGroup({
        selectedProduct: new FormControl(),
        options: new FormControl([])
      }));

      requests.push(this.gmOfferService.getGMProductsForList(p));
    });

    forkJoin(requests).subscribe((personMatchedProducts) => {
      this.GMProducts = personMatchedProducts;
      this.isDataLoading = false;
      personMatchedProducts.map((products: any) => {
        const forms = [];
        products.map((x: any) => {
          forms.push(
            new FormGroup(
              {
                long: new FormControl(x.long),
                categoryName: new FormControl(x.description[this.selectedLang]),
                category: new FormControl({ value: false, disabled: false }),
                allVariants: new FormControl(x.variants),
                selectedVariant: new FormControl(),
                insurerCode: new FormControl(x.court)
              })
          );
        });
        this.gmProducts.push(new FormArray(forms));
      });
    });
  }

  loadConfigData(): void {
    this.gmOfferService.loadConfigData().then((res) => {
      const configDaten: ConfigData = res;
      // Franchise-Combobox-Inhalte parsen
      configDaten.franchiseData.forEach(v => {
        const values: Array<Number> = [];
        v.Franchise.forEach(f => values.push(f));
        this.gmOfferService.franchiseData[v.AK] = values;
      });

      // Standardbelegung speichern
      configDaten.standardBelegung.forEach(v => {
        this.gmOfferService.standardBelegungData[v.alter] = v;
      });
      if (this.data.persons.length === 1) {
        this.autoFillValues();
      }
    });

  }

  onSelectedPersonChange(uuidPerson): void {
    this.selectedIndex = this.data.persons.findIndex(x => x.uuid === uuidPerson);
    this.selectedPerson = this.data.persons[this.selectedIndex];
    this.autoFillValues();
    this.filterProducts();
    if (!this.wholeFamilyChecked && this.selectedPerson !== null &&
      this.selectedFranchise !== -1 &&
      this.selectedTarif !== '') {
      this.findTarife();
    }

    // A3N: fix Expression has changed after it was checked error.
    this.cdr.detectChanges();
  }

  onFranchiseChange(franchise: number): void {
    this.selectedFranchise = franchise;
    if (this.selectedIndex === 0) {
      this.searchData.hauptPerson.franchise = franchise;
    } else {
      if (this.wholeFamilyChecked) {
        // [this.selectedIndex - 1] bcs first element of persons is representant and is included in "hauptPerson"
        this.searchData.weitereVersichertePersonen[this.selectedIndex - 1].franchise = this.selectedFranchise;
      } else {
        this.searchData.hauptPerson.franchise = this.selectedFranchise;
      }
    }

    if (this.selectedPerson !== null &&
      this.selectedRegion !== undefined &&
      this.selectedTarif !== '') {
      this.findTarife();
    }
  }

  onFamilySlideChange(): void {
    if (this.wholeFamilyChecked) {
      this.selectedIndex = 0;
      this.selectedPerson = this.data.representant;
      this.addFamilyMembers();
      this.autoFillValues();

      if (this.selectedPerson !== null &&
        this.selectedFranchise !== -1 &&
        this.selectedRegion !== undefined
        && this.selectedTarif !== ''
      ) {
        this.findTarife();
      }
    } else {
      this.searchData = new SearchParams();
      this.clearData();
    }
  }

  onTarifChange(tarif: string): void {
    this.selectedTarif = tarif;
    this.searchData.tariftypen = [this.selectedTarif]

    if (this.selectedPerson !== null &&
      this.selectedFranchise !== -1 &&
      this.selectedRegion !== undefined) {
      this.findTarife();
    }
  }

  changeRegion(data: NeoRegion) {
    this.searchData.region = data;
    if (this.selectedPerson !== null &&
      this.selectedFranchise !== -1 &&
      this.selectedTarif !== '') {
      this.findTarife();
    }
  }

  addFamilyMembers(): void {
    const familyMembers = [];
    for (let p of this.data.persons) {
      const leistungFilter = this.getLeistungFilter(p.birthdate);
      const franchise = this.getPersonFranchiseOrDefault(p.birthdate, true)
      if (p.uuid == this.data.representant.uuid) {
        this.searchData.hauptPerson = new Persondata(p, franchise, leistungFilter);
      } else {
        familyMembers.push(new Persondata(p, franchise, leistungFilter))
      }
    }
    this.searchData.weitereVersichertePersonen = familyMembers;
  }

  getFranchise(birthdate: string): number {
    const birthDate = new Date(birthdate).getFullYear();
    return this.gmOfferService.standardBelegungData[SearchMaskService.getAgeForYear(birthDate)].franchise;
  }

  getLeistungFilter(birthDate: string): LeistungFilter[] {
    const date = new Date(birthDate).getFullYear();
    return this.gmOfferService.standardBelegungData[SearchMaskService.getAgeForYear(date)].leistungen.map(x => new LeistungFilter({ key: x.key, wertung: x.value }));
  }

  findTarife(): void {
    this.isDataLoading = true;
    this.subscriptions.push(
      this.gmOfferService.findTarife(this.searchData)
        .subscribe((data: ITarifResponse[]) => {
          this.vgrTarifResponseData = data;
          const formGroup = this.vgrProducts.controls[this.getFormSelectedIndex()].value;
          let val = formGroup.selectedProduct ? formGroup.selectedProduct : this.vgrProducts.controls[0].value.selectedProduct;

          this.vgrProducts.controls[this.getFormSelectedIndex()].setValue(
            {
              selectedProduct: val,
              options: this.vgrTarifResponseData
            }
          );

          // if whole Family is checked same products are for whole family, only prices are different for each person ( based on age and other leistungen criteria )
          if (this.wholeFamilyChecked) {
            const options = this.vgrProducts.value[0].options;

            this.data.persons.map((p, index) => {
              this.vgrProducts.controls[index].patchValue({
                options
              });
            });
          }

          this.isDataLoading = false;
        }, err => {
          this.isDataLoading = false;
          console.log(err);
        }));
  }


  autoFillValues(): void {
    const leistungFilter = this.getLeistungFilter(this.selectedPerson.birthdate);
    this.franchises = this.getFranchisesForPerson(this.selectedPerson.birthdate);
    this.selectedFranchise = this.getPersonFranchiseOrDefault(this.selectedPerson.birthdate, !this.wholeFamilyChecked);
    this.selectedRegion = this.regions.find(x => x.value.indexOf(this.selectedPerson.address.postalCode) !== -1);

    if (!this.wholeFamilyChecked) {
      this.searchData.hauptPerson = new Persondata(this.selectedPerson, this.selectedFranchise, leistungFilter);
    }
  }

  clearData(): void {
    this.selectedPerson = null;
    this.selectedFranchise = -1;
    this.selectedRegion = this.regions.find(x => x.value.indexOf(null) !== -1);
    this.selectedTarif = '';
    this.wholeFamilyChecked = false;
    this.vgrTarifResponseData = [];
    this.vgrProducts.reset();
  }

  // send offer to Group Muttuel by calling neo-gm-service
  sendOffer(): void {

    const selectedVgrProducts = this.productsForm.controls.vgrProducts.value;

    this.isDataLoading = true;
    let gmRiskObject: Array<GMRiskObject> = [];
    if (this.wholeFamilyChecked) {

      for (let i = 0; i < this.data.persons.length; i++) {
        let personVgrproductSelected;

        if (selectedVgrProducts[i].selectedProduct && selectedVgrProducts[i].selectedProduct.products[i]) {
          personVgrproductSelected = selectedVgrProducts[i].selectedProduct.products[i].product;
        } else {
          // 0 is representant so we set representant's product as default checked product
          personVgrproductSelected = selectedVgrProducts[0].selectedProduct.products[0].product;
        }
        gmRiskObject.push(this.gmOfferService.prepareRiskObject(this.data.persons[i], personVgrproductSelected, this.selectedRegion as NeoRegion));
      }

    } else {
      const product = selectedVgrProducts[this.getFormSelectedIndex()].selectedProduct.products[0].product;
      gmRiskObject.push(this.gmOfferService.prepareRiskObject(this.selectedPerson, product, this.selectedRegion as NeoRegion));
    }

    // agent number ?
    const gmOfferData = new GMOfferData(this.data.agentNumber, gmRiskObject);
    gmOfferData.language = this.translate.currentLang.toUpperCase();

    // A3N: gmProducts format [ [ { long: string; insurerCode: string; selectedVariant: string } ]]
    let gmProducts = this.wholeFamilyChecked ? this.gmProducts.value : [this.gmProducts.controls[this.getFormSelectedIndex()].value];
    // remove unused data from gmProducts object
    gmProducts = gmProducts.map(personProducts => {
      return personProducts.map(x => {
        return {
          long: x.long,
          category: x.category || false,
          selectedVariant: x.selectedVariant || null,
          insurerCode: x.insurerCode
        }
      })
    });
    this.subscriptions.push(
      this.gmOfferService.sendGMOffer(gmOfferData, gmProducts).subscribe((offerResp: any) => {

        // snackbar with offer id
        // this.snackBar.open('Offer created: ' + offerResp.offerId, 'x', UserCommitState.configCheckData);
        const uploadFiles = new Array<FileUpload>();
        uploadFiles.push(new FileUpload(
          this.selectedPerson.fullName + "-GMOffer-" + offerResp.offer.offerId + ".pdf",  // test only, ask for naming structure
          offerResp.document,
          (offerResp.document.length * (3 / 4)) - 1  // getting the file size from base64 string        
        ));
        this.gmOfferService.createActionData(this.selectedPerson, uploadFiles, offerResp.offer.offerId);
        this.isDataLoading = false;
        // this.clearData();
      }));
  }

  // event called on tab change
  tabChanged(event: any): void {
    this.isDataLoading = true;
    this.selectedIndex = event.index;
    this.selectedPerson = this.data.persons[this.selectedIndex];
    this.franchises = this.getFranchisesForPerson(this.selectedPerson.birthdate);
    this.filterProducts();
    this.selectedFranchise = this.getPersonFranchiseOrDefault(this.selectedPerson.birthdate, !this.wholeFamilyChecked);
    this.selectedRegion = this.regions.find(x => x.value.indexOf(this.selectedPerson.address.postalCode) !== -1);

    this.autoFillValues();
    if (!this.wholeFamilyChecked && this.selectedPerson !== null &&
      this.selectedFranchise !== -1 &&
      this.selectedTarif !== '') {
      this.findTarife();
    }
    setTimeout(() => {
      this.isDataLoading = false;
    }, 1000);

    // A3N: fix Expression has changed after it was checked error.
    this.cdr.detectChanges();
  }

  // set default franchise in dropdown
  getPersonFranchiseOrDefault(birthdate: string, forceDefaultValue: boolean = false): number {
    const defaultFranchise = this.getFranchise(birthdate);
    if (!forceDefaultValue) {
      if (this.selectedIndex === 0) {
        if (this.searchData.hauptPerson) {
          return this.searchData.hauptPerson.franchise;
        }
        return defaultFranchise;
      } else {
        if (this.searchData.weitereVersichertePersonen[this.selectedIndex - 1]) {
          return this.searchData.weitereVersichertePersonen[this.selectedIndex - 1].franchise;
        }
        return defaultFranchise;
      }
    } else {
      return defaultFranchise;
    }

  }

  // get Franchises for person and fill dropdown list
  getFranchisesForPerson(birthdate: string): Number[] {
    return this.gmOfferService.franchiseData[
      SearchMaskService
        .getAltersklasseForYear(new Date(birthdate)
          .getFullYear())
    ];
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s: Subscription) => { s.unsubscribe(); });
  }

  // filter incompatible products called on each form change
  filterProducts(index?: number) {
    // index used to update values of form
    if (index >= 0) {
      if (!this.gmProducts.controls[this.getFormSelectedIndex()]['controls'][index].controls.category.value) {
        this.gmProducts.controls[this.getFormSelectedIndex()]['controls'][index].controls.selectedVariant.setValue('');
      }
    }
    this.isDataLoading = false;
    this.gmOfferService.filterProducts(this.GMProducts[this.getFormSelectedIndex()], this.gmProducts.controls[this.getFormSelectedIndex()].value).subscribe(res => {
      this.GMProducts[this.getFormSelectedIndex()] = res;
      this.disableIncompatibleProducts();
    })
  }

  // disables incompatible products used on form change
  disableIncompatibleProducts(): void {

    this.isDataLoading = true;
    this.GMProducts[this.getFormSelectedIndex()].map((x) => {
      const fCIndex: number = this.gmProducts.controls[this.getFormSelectedIndex()].value.findIndex(y => y.long === x.long);
      if (fCIndex > -1) {
        if (x.enabled) {
          this.gmProducts.controls[this.getFormSelectedIndex()]['controls'][fCIndex].controls.category.enable();
          this.gmProducts.controls[this.getFormSelectedIndex()]['controls'][fCIndex].controls.selectedVariant.enable();
        } else {
          this.gmProducts.controls[this.getFormSelectedIndex()]['controls'][fCIndex].controls.category.disable();
          this.gmProducts.controls[this.getFormSelectedIndex()]['controls'][fCIndex].controls.selectedVariant.disable();
        }
      }
    });

    setTimeout(() => {
      this.isDataLoading = false;
    }, 1000);
  }

  // used in front-end to format price in CH format
  formatProduct(data: ITarifResponse): string {
    if (this.wholeFamilyChecked) {
      return `${data.products[this.selectedIndex].product.versichererName} (${data.products[this.selectedIndex].product.tariff}) - (${data.products[this.selectedIndex].person.produkte[0].praemie.toLocaleString('de-CH', { style: 'currency', currency: 'CHF' })})`;
    }
    return `${data.products[0].product.versichererName} (${data.products[0].product.tariff}) - (${data.products[0].person.produkte[0].praemie.toLocaleString('de-CH', { style: 'currency', currency: 'CHF' })})`;
  }

  // front-end variant changed
  variantChanged(index: number): void {
    if (this.gmProducts.controls[this.getFormSelectedIndex()]['controls'][index].controls.selectedVariant.value) {
      this.gmProducts.controls[this.getFormSelectedIndex()]['controls'][index].controls.category.setValue(true);
    }
    this.filterProducts();
  }

  // index of selected form
  getFormSelectedIndex(): number {
    return this.selectedIndex > -1 ? this.selectedIndex : 0;
  }

  isVgrProdChecked(currentOption, optionName): boolean {
    if (currentOption.value && currentOption.value.name) {
      return currentOption.value.name === optionName;
    } else {
      // set Default product from representant
      const selectedProduct = this.vgrProducts.controls[0].value.selectedProduct;
      if (selectedProduct && selectedProduct.name) {
        if (selectedProduct.name === optionName) {
          this.vgrProducts.controls[this.getFormSelectedIndex()]['controls'].selectedProduct.setValue(selectedProduct);
          return true;
        }
      } else {
        return false;
      }
    }
  }

  sendOfferDisabled(): boolean {
    const val = this.vgrProducts.value;
    if (!this.wholeFamilyChecked) {
      if (
        val &&
        val[this.getFormSelectedIndex()] &&
        val[this.getFormSelectedIndex()].selectedProduct &&
        val[this.getFormSelectedIndex()].selectedProduct &&
        val[this.getFormSelectedIndex()].selectedProduct.products[0] &&
        val[this.getFormSelectedIndex()].selectedProduct.products[0].product) {
        return false;
      } else if (
        val &&
        val[0] &&
        val[0].selectedProduct &&
        val[0].selectedProduct.products[0] &&
        val[0].selectedProduct.products[0].product) {
        return false;
      } else {
        return true;
      }
    } else {
      if (
        val &&
        val[0] &&
        val[0].selectedProduct &&
        val[0].selectedProduct.products[0] &&
        val[0].selectedProduct.products[0].product) {
        return false;
      } else {
        return true;
      }
    }

  }

}

