<table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort>


  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef> ID </th>
    <td mat-cell *matCellDef="let element" >{{element.id}} </td>
  </ng-container>

  <ng-container matColumnDef="task">
    <th mat-header-cell *matHeaderCellDef> Task </th>
    <td mat-cell  *matCellDef="let element">{{element.task}} </td>
  </ng-container>

  <ng-container matColumnDef="comment">
    <th mat-header-cell *matHeaderCellDef> Comment </th>
    <td mat-cell *matCellDef="let element" > {{element.comment}} </td>
  </ng-container>

  <ng-container matColumnDef="benutzer">
    <th mat-header-cell *matHeaderCellDef> Benutzer </th>
    <td mat-cell *matCellDef="let element"> {{element.benutzer}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>