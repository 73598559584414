import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CoreModule } from "../core/core.module";
import { GMOfferDialogComponent } from "./components/gmoffer-dialog/gmoffer-dialog.component";
import { NeoInputCompleterComponent } from './components/neo-input-completer/neo-input-completer.component';

@NgModule({
    declarations: [NeoInputCompleterComponent, GMOfferDialogComponent],
    imports: [
      CommonModule,
      CoreModule
    ],
    exports: [NeoInputCompleterComponent, GMOfferDialogComponent],
    entryComponents: [GMOfferDialogComponent]
})

export class SharedModule { }
