<div class="mdc-layout-grid msg-helper-padding-none">
    <div class="mdc-layout-grid__inner msg-helper-grid-gap-horizontal">
      <div
        class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone">
        <div
          class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-9-tablet mdc-layout-grid__cell--span-12-phone mdc-layout-grid--align-left">
          <mat-tab-group class="neo-border-none neo-label" (selectedTabChange)="tabChanged($event)"
          [selectedIndex]="tabIndex">
            <mat-tab label="Open">
                <div class="mdc-layout-grid msg-helper-padding-none">
                    <div class="mdc-layout-grid__inner msg-helper-grid-gap-horizontal">
                        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop
                                            mdc-layout-grid__cell--span-12-tablet
                                            mdc-layout-grid__cell--span-12-phone
                                            mdc-layout-grid--align-right">
                            <neomp-slide-range-filter
                                [disabled]="isDataLoading"
                                [filterValue]="filterValueForIssuesOpen"
                                (filterChangeEmitter)="getOpenIssues($event)">
                            </neomp-slide-range-filter>
                        </div>
                        <div
                            class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone neomp-pb-10">
                            <mat-card>
                                <div class="search-fields-layout-grid" (keyup.enter)="searchFilter()">
                                    <mat-form-field class="neomp-full-width">
                                        <mat-label>{{ 'PAGE.LEADS.LEAD.TEXT' | translate }}</mat-label>
                                        <input matInput [(ngModel)]="searchText" />
                                    </mat-form-field>
                        
                                    <mat-form-field class="neomp-full-width">
                                        <mat-label>{{ 'PAGE.CONTAINER.COMMISSIONS.TABLES.PROVISIONS.COMPANY' | translate }}</mat-label>
                                        <mat-select multiple [(ngModel)]="selectedCompany" (selectionChange)="onCompanySelected($event)">
                                            <mat-option *ngFor="let company of companies" [value]="company">{{ company }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                        
                                    <mat-form-field class="neomp-full-width">
                                        <mat-label>{{ 'PAGE.CONTAINER.COMMISSIONS.TABLES.PROVISIONS.SPARTE' | translate }}</mat-label>
                                        <mat-select multiple [(ngModel)]="selectedInsurance" (selectionChange)="onInsuranceSelected($event)">
                                            <mat-option *ngFor="let insurance of insuranceTypes" [value]="insurance">{{ insurance
                                                }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="search-fields-layout-grid neo-padding-top-10 margin-top-15">
                                    <button mat-raised-button class="neomp-full-width button-text" color="primary" (click)="searchFilter()">
                                        <span class="material-icons">
                                            search
                                        </span>{{ 'PAGE.LEADS.TERMIN.SEARCH' | translate }}
                                    </button>
                                    <button mat-raised-button class="clear-button neomp-full-width button-text" (click)="clearSearch()">
                                        <span class="material-icons">clear</span>{{ 'PAGE.LEADS.LEAD.CLEAR' | translate }}
                                    </button>
                                </div>
                            </mat-card>
                        </div>
                        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop
                                            mdc-layout-grid__cell--span-12-tablet
                                            mdc-layout-grid__cell--span-12-phone">
                            <neomp-container cssClass="msg-mp-container-frameless"
                                            headlineText="{{ 'PAGE.CONTAINER.JOURNALS.CONTAINER.HEADLINE' | translate }}">
                                <neomp-open-issues-table class="datePickerHide"
                                    [tableEntries]="openissues"
                                ></neomp-open-issues-table>
                                <neomp-loading-data #openIssuesTable></neomp-loading-data>
                            <neomp-paginator
                            [length]="totalCountOpenIssues" 
                            [pageSize]="pageSize" 
                            [pageIndex]="pageIndex"
                            [pageSizeOptions]="pageSizeOptions" 
                            (paginatorChange)="paginatorOpenIssuesValueChanged($event)"
                            >  
                            </neomp-paginator>
                            </neomp-container>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Done">
                <div class="mdc-layout-grid msg-helper-padding-none">
                    <div class="mdc-layout-grid__inner msg-helper-grid-gap-horizontal">
                        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop
                                            mdc-layout-grid__cell--span-12-tablet
                                            mdc-layout-grid__cell--span-12-phone
                                            mdc-layout-grid--align-right">
                            <neomp-slide-range-filter
                                [disabled]="isDataLoading"
                                [filterValue]="filterValueForIssuesClosed"
                                (filterChangeEmitter)="getClosedIssues($event)">
                            </neomp-slide-range-filter>
                        </div>
                        <div
                            class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-12-phone neomp-pb-10">
                            <mat-card>
                                <div class="search-fields-layout-grid" (keyup.enter)="searchFilter()">
                                    <mat-form-field class="neomp-full-width">
                                        <mat-label>{{ 'PAGE.LEADS.LEAD.TEXT' | translate }}</mat-label>
                                        <input matInput [(ngModel)]="searchText" />
                                    </mat-form-field>
                                
                                    <mat-form-field class="neomp-full-width">
                                        <mat-label>{{ 'PAGE.CONTAINER.COMMISSIONS.TABLES.PROVISIONS.COMPANY' | translate }}</mat-label>
                                        <mat-select multiple [(ngModel)]="selectedCompany" (selectionChange)="onCompanySelected($event)">
                                            <mat-option *ngFor="let company of companies" [value]="company">{{ company }}</mat-option>
                                        </mat-select> 
                                    </mat-form-field>
                                
                                    <mat-form-field class="neomp-full-width">
                                        <mat-label>{{ 'PAGE.CONTAINER.COMMISSIONS.TABLES.PROVISIONS.SPARTE' | translate }}</mat-label>
                                        <mat-select multiple [(ngModel)]="selectedInsurance" (selectionChange)="onInsuranceSelected($event)">
                                            <mat-option *ngFor="let insurance of insuranceTypes" [value]="insurance">{{ insurance }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="search-fields-layout-grid neo-padding-top-10 margin-top-15">
                                    <button mat-raised-button class="neomp-full-width button-text" color="primary" (click)="searchFilter()">
                                        <span class="material-icons">
                                            search
                                        </span>{{ 'PAGE.LEADS.TERMIN.SEARCH' | translate }}
                                    </button>
                                    <button mat-raised-button class="clear-button neomp-full-width button-text" (click)="clearSearch()">
                                        <span class="material-icons">clear</span>{{ 'PAGE.LEADS.LEAD.CLEAR' | translate }}
                                    </button>
                                </div>
                            </mat-card>
                        </div>
                        <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-desktop
                                            mdc-layout-grid__cell--span-12-tablet
                                            mdc-layout-grid__cell--span-12-phone">
                            <neomp-container cssClass="msg-mp-container-frameless"
                                            headlineText="{{ 'PAGE.CONTAINER.JOURNALS.CONTAINER.HEADLINE' | translate }}">
                                <neomp-open-issues-table class="datePickerHide"
                                    [tableEntries]="closedissues"
                                ></neomp-open-issues-table>
                                <neomp-loading-data #closedIssuesTable></neomp-loading-data>
                            <neomp-paginator
                            [length]="totalCountClosedIssues" 
                            [pageSize]="pageSize" 
                            [pageIndex]="pageIndex"
                            [pageSizeOptions]="pageSizeOptions" 
                            (paginatorChange)="paginatorClosedIssuesValueChanged($event)"
                            >  
                            </neomp-paginator>
                            </neomp-container>
                        </div>
                    </div>
                </div>
            </mat-tab>
          </mat-tab-group>
        </div>
    </div>
</div>
