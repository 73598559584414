import {AfterViewInit, Component, OnInit, ViewChild} from "@angular/core";
import {JournalProvider} from "../../core/providers/journal.provider";
import {LoadingDataComponent} from "../../core/components/loading-data.component";
import {BusinessTransactionHelper} from "../../core/static/bt-helper";
import {JournalList} from "../../core/model/journal-list";
import {SlideRangeFilterValue} from "../../core/model/slide-range-filter-value";
import * as moment from "moment";
import {Observable} from "rxjs";
import { PaginationConfigs } from "app/modules/constants/PaginationConstants";
import { FormControl } from "@angular/forms";

@Component({
    selector: "neomp-provision",
    templateUrl: "./provision.component.html",
    styleUrls: ["./provision.component.scss"]
})
export class ProvisionComponent implements OnInit, AfterViewInit {

    @ViewChild("loadingDataComponent")
    loadingDataComponent: LoadingDataComponent;
    journalLists: JournalList[] = [];
    filterValue: SlideRangeFilterValue = new SlideRangeFilterValue();

    isInitialized: boolean;
    isDataLoading: boolean;
    pageSize = PaginationConfigs.pageSize;
    pageSizeOptions = PaginationConfigs.pageSizeOptions;
    pageIndex = PaginationConfigs.pageIndex;
    totalCount = 0;
    allTransactionsName = [];
    transactionsNameSelected = new FormControl ([]);
    searchText: string = '';
    searchDescription: string = '';
    isSearchButtonClicked: boolean = false;
    constructor(public journalProvider: JournalProvider) {
    }

    ngOnInit() {
        this.filterValue.isActive = true;
        this.filterValue.dateRange.end = moment();
        this.filterValue.dateRange.begin = moment().subtract(60, "days");
        this.getElements(this.filterValue);

        this.getTransactionTypeName();
    }

    ngAfterViewInit(): void {
        this.isInitialized = true;
    }

    getElements(filterValue) {        
        const sort = { creationDate: -1 };
        var typeNameSelected = Array.isArray(this.transactionsNameSelected.value) ? this.transactionsNameSelected.value.join('|')  : "";

        if((this.searchText || typeNameSelected || this.searchDescription) && this.isSearchButtonClicked){
            this.dataLoading = true;
            const journalObservable: Observable<{data: JournalList[], totalCount: number}> = filterValue.isActive ?
            this.journalProvider.getFilteredJournalsReferencedByDateRangeWithPagination(
                this.loadingDataComponent,
                BusinessTransactionHelper.typeId.provision.from,
                BusinessTransactionHelper.typeId.provision.to,
                filterValue.dateRange,
                this.pageIndex,
                this.pageSize,
                sort,
                null,
                this.searchText,
                typeNameSelected,
                this.searchDescription) :
            this.journalProvider.getFilteredJournalsReferencedWithPagination(
                this.loadingDataComponent,
                BusinessTransactionHelper.typeId.provision.from,
                BusinessTransactionHelper.typeId.provision.to,
                this.pageIndex,
                this.pageSize,
                sort,
                null,
                this.searchText,
                typeNameSelected,
                this.searchDescription);
        journalObservable.subscribe(journalLists => {
            this.journalLists = journalLists.data;
            this.totalCount = journalLists.totalCount;
            this.dataLoading = false;
        }, error => {
            this.dataLoading = false;
        });
        }
        else{
            this.dataLoading = true;
            const journalObservable: Observable<{data: JournalList[], totalCount: number}> = filterValue.isActive ?
                this.journalProvider.getJournalsReferencedByDateRangeWithPagination(
                    this.loadingDataComponent,
                    BusinessTransactionHelper.typeId.provision.from,
                    BusinessTransactionHelper.typeId.provision.to,
                    filterValue.dateRange,
                    this.pageIndex,
                    this.pageSize,
                    sort,
                    null) :
                this.journalProvider.getJournalsReferencedWithPagination(
                    this.loadingDataComponent,
                    BusinessTransactionHelper.typeId.provision.from,
                    BusinessTransactionHelper.typeId.provision.to,
                    this.pageIndex,
                    this.pageSize,
                    sort,
                    null);
            journalObservable.subscribe(journalLists => {
                this.journalLists = journalLists.data;
                this.totalCount = journalLists.totalCount;
                this.dataLoading = false;
            }, error => {
                this.dataLoading = false;
            });
        }
    }
  
    set dataLoading(dataLoading: boolean) {
        if (this.isInitialized) {
            this.isDataLoading = dataLoading;
        }
    }
    
    paginatorValueChanged(event) {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
        this.getElements(this.filterValue);
      }

    getTransactionTypeName() {
        this.journalProvider.getTransactionTypeName().subscribe({
            next: (res: any) => {
              this.allTransactionsName = res.distinctTypeNames;
            },
            error: (error) => {
              console.error(error);
            }
          });
    }
    
    search() {
        this.isSearchButtonClicked=true;
        this.getElements(this.filterValue)
    }

    clearSearch(){
        this.isSearchButtonClicked=false;
        this.searchText='';
        this.transactionsNameSelected.reset();
        this.searchDescription='';
        this.getElements(this.filterValue);
    }

}
